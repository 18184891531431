
.@{mBtnClsPrefix} {
  outline: none;
  display: block;
  width: 100%;
  position: relative;
  text-align: center;
  white-space: nowrap;
  background-image: none;
  color: var(--m-btn-font-color);
  font-size: var(--m-btn-font-size);
  font-weight: var(--font-weight-base);
  font-family: var(--regular-ff);
  border: var(--m-btn-border);
  border-radius: var(--m-btn-radius);
  padding: var(--m-btn-padding-y) var(--m-btn-padding-x);

  &.@{mBtnClsPrefix}-radius {
    border-radius: var(--btn-circle-radius);
  }

  &.@{mBtnClsPrefix}-primary,
  &.@{mBtnClsPrefix}-danger {
    color: var(--m-btn-font-color);
    &:active, &.active,
    &:focus, &.focus {
      // opacity: var(--m-btn-active-opacity);
      opacity: 0.8;
    }
    &.disabled, &:disabled {
      opacity: var(--m-btn-disabled-opacity);
    }
  }

  &.@{mBtnClsPrefix}-default {
    color: var(--regular-fc);
    background-color: var(--bg-white);
    border-color: var(--btn-border-color);
    &:active, &.active {
      background-color: var(--m-btn-default-active-bc);
    }
    &.disabled, &:disabled {
      background-color: var(--bg-base);
      opacity: var(--m-btn-disabled-opacity);
    }
  }

  &.@{mBtnClsPrefix}-primary {
    background-color: var(--primary);
    // &:focus, &.focus {
    //   background-color: var(--m-btn-primary-active-bc);
    // }
    &.disabled, &:disabled {
      background-color: var(--m-btn-primary-disabled-bc);
    }
  }

  &.@{mBtnClsPrefix}-danger {
    background-color: var(--danger);
    &:focus, &.focus {
      background-color: var(--m-btn-danger-active-bc);
    }
    &.disabled, &:disabled {
      background-color: var(--m-btn-danger-disabled-bc);
    }
  }

  &-large {
    font-size: var(--m-btn-lg-font-size);
    padding: var(--m-btn-lg-padding-y) var(--m-btn-lg-padding-x);
  }

  &-small {
    font-size: var(--m-btn-sm-font-size);
    padding: var(--m-btn-sm-padding-y) var(--m-btn-sm-padding-x);
  }

  &-inline {
    display: inline-block;
    width: auto;
  }

  &.@{mBtnClsPrefix}-icon {
    color: var(--primary);
    background-color: var(--bg-white);
    &:focus, &.focus {
      background-color: var(--hover-bc);
    }
    &.disabled, &:disabled {
      background-color: var(--bg-white);
      opacity: var(--m-btn-disabled-opacity);
    }
  }
}
