.@{commentClsPrefix}-operation {
  &-title {
    padding-right: calc(10 * var(--hd));
  }

  &-content {
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    line-height: calc(18 * var(--hd));
    padding: calc(3 * var(--hd)) 0;
  }

  &-file {
    color: var(--primary);
    margin-right: calc(3 * var(--hd));
    display: inline-block;
  }
}