.@{pullToRefreshClsPrefix} {
  &-content {
    transform-origin: left top 0px;
  }

  &-transition {
    transition: transform 0.3s;
  }

  &-indicator {
    color: grey;
    text-align: center;
    height: 25px;
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-down .@{pullToRefreshClsPrefix}-indicator {
    margin-top: -25px;
  }

  &-up .@{pullToRefreshClsPrefix}-indicator {
    margin-bottom: -25px;
  }
}
