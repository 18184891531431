.@{popoverClsPrefix} {
  position: relative;
  display: inline-block;

  &-left,
  &-leftTop,
  &-leftBottom {
    padding-right: var(--popover-arrow-width);
  }

  &-right,
  &-rightTop,
  &-rightBottom {
    padding-left: var(--popover-arrow-width);
  }

  &-content {
    border-radius: var(--border-radius-xs);
    background-color: var(--popover-background);
    padding: var(--v-spacing-md) var(--h-spacing-md);
    min-width: calc(10 * var(--hd));
    word-wrap: break-word;
    &-bottomRight {
      margin-top: var(--popover-arrow-width);
    }

    &-topRight {
      margin-bottom: var(--popover-arrow-width);
    }

    &-leftTop,
    &-rightBottom {
      margin-top: 0;
    }

    &.border {
      border: var(--border-solid);
    }
  }

  &-arrow,
  &-arrow-border {
    width: 0;
    height: 0;
    border-left: var(--popover-arrow-width) solid transparent;
    border-right: var(--popover-arrow-width) solid transparent;
    border-bottom: var(--popover-arrow-width) solid var(--popover-background);
    margin-left: var(--h-spacing-md);

    &-bottom {
      margin: 0 auto;
    }

    &-bottomRight,
    &-topRight {
      position: absolute;
      top: calc(-1 * var(--popover-arrow-width));
      left: auto;
      right: var(--h-spacing-md);
    }

    &-bottomRight {
      border-top: 0;
      top: calc(1 * var(--hd));
      border-left: var(--popover-arrow-width) solid transparent;
      border-right: var(--popover-arrow-width) solid transparent;
    }

    &-top,
    &-topLeft,
    &-topRight {
      border-top: var(--popover-arrow-width) solid var(--popover-background);
      border-bottom: 0;
    }

    &-top {
      margin: 0 auto;
    }

    &-topRight {
      bottom: var(--hd);
      top: auto;
    }

    &-topLeft,
    &-top {
      margin-top: calc(-1 * var(--hd));
    }

    &-left,
    &-leftTop,
    &-leftBottom {
      position: absolute;
      top: var(--popover-arrow-top-position);
      left: auto;
      right: var(--popover-arrow-right-position);
      border-top: var(--popover-arrow-width) solid transparent;
      border-bottom: var(--popover-arrow-width) solid transparent;
      border-left: var(--popover-arrow-width) solid var(--popover-background);
    }

    &-leftTop {
      top: var(--v-spacing-sm);
    }

    &-leftBottom {
      top: auto;
      bottom: var(--v-spacing-sm);
    }

    &-right,
    &-rightTop,
    &-rightBottom {
      position: absolute;
      top: var(--popover-arrow-top-position);
      left: var(--popover-arrow-left-position);
      border-top: var(--popover-arrow-width) solid transparent;
      border-bottom: var(--popover-arrow-width) solid transparent;
      border-right: var(--popover-arrow-width) solid var(--popover-background);
    }

    &-rightBottom {
      top: auto;
      bottom: var(--v-spacing-sm);
    }

    &-rightTop {
      top: var(--v-spacing-sm);
    }
  }

  &-tooltip {
    font-size: var(--font-size-12);
    .@{popoverClsPrefix}-content {
      background-color: var(--popover-tooltip-background);
      color: var(--base-white);
    }

    .@{popoverClsPrefix}-arrow {
      border-bottom: var(--popover-arrow-width) solid var(--popover-tooltip-background);

      &-top,
      &-topLeft,
      &-topRight {
        border-top: var(--popover-arrow-width) solid var(--popover-tooltip-background);
        border-bottom: 0;
      }

      &-left,
      &-leftTop,
      &-leftBottom {
        border-bottom: var(--popover-arrow-width) solid transparent;
        border-left: var(--popover-arrow-width) solid var(--popover-tooltip-background);
      }

      &-right,
      &-rightTop,
      &-rightBottom {
        border-bottom: var(--popover-arrow-width) solid transparent;
        border-right: var(--popover-arrow-width) solid var(--popover-tooltip-background);
      }
    }
  }

  &-tooltip:not(.@{popoverClsPrefix}-hasSetWidth){
    max-width: calc(var(--hd)*200);
  }

  &-popoverType {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    background-color: var(--popover-background);
    text-align: center;
    width: 100%;
    transition: all 0.3s ease-in-out 0s;

    &-hidden {
      bottom: -100%;
    }
  }

  &-showBorder {
    .@{popoverClsPrefix}-arrow-border {

      &-bottom,
      &-bottomLeft,
      &-bottomRight {
        top: 0;
        border-bottom: var(--popover-arrow-width) solid var(--border-color);
      }

      &-top,
      &-topLeft,
      &-topRight {
        border-top: var(--popover-arrow-width) solid var(--border-color);
        border-bottom: 0;
      }

      &-topRight {
        top: calc(100% - var(--popover-arrow-width));
      }

      &-left,
      &-leftTop,
      &-leftBottom {
        border-left: var(--popover-arrow-width) solid var(--border-color);
      }

      &-right,
      &-rightTop,
      &-rightBottom {
        border-right: var(--popover-arrow-width) solid var(--border-color);
      }
    }

    .@{popoverClsPrefix}-arrow {

      &-bottomRight,
      &-bottomLeft,
      &-bottom {
        position: absolute;
        top: calc(1 * var(--hd));
      }

      &-bottom {
        left: 50%;
        margin-left: calc(-1 * var(--popover-arrow-width));
      }

      &-top,
      &-topLeft {
        position: absolute;
        top: calc(100% - var(--popover-arrow-width));
      }

      &-topRight {
        position: absolute;
        top: calc(100% - var(--popover-arrow-width) - calc(1 * var(--hd)));
      }

      &-top {
        left: 50%;
        margin-left: calc(-1 * var(--popover-arrow-width));
      }

      &-left,
      &-leftTop,
      &-leftBottom {
        right: calc(-1 * var(--popover-arrow-width) + calc(2 * var(--hd)));
      }

      &-right,
      &-rightTop,
      &-rightBottom {
        left: calc(-2 * var(--popover-arrow-width));
      }
    }
  }
}

.@{popoverClsPrefix}-hasSetWidth {
  width: 100%;
}

.@{popoverClsPrefix}-noMask {
  .@{popoverClsPrefix}-content {
    box-shadow: var(--box-shadow);
  }
}