.@{mQuickMenuClsPrefix} {
  &-dialog-menu {
    .ui-m-dialog-content {
      border-radius: calc(19 * var(--hd)) calc(19 * var(--hd)) 0 0;
    }

    .ui-m-dialog-top {
      overflow: visible;
    }
  }

  &-dialog-menu-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: calc(20 * var(--hd));
    position: relative;
  }

  &-dialog-menu-closeBtn {
    width: calc(48 * var(--hd));
    height: calc(48 * var(--hd));
    position: absolute;
    top: calc(-34 * var(--hd));
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50%;
    background-color: var(--base-white);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--regular-fc);

    svg {
      width: calc(26 * var(--hd));
      height: calc(26 * var(--hd));
    }
  }

  &-dialog-carousel {

    .ui-m-carousel-swiper-backgroundFill {
      background: unset;
    }

    .ui-m-carousel-pagination .swiper-pagination-bullet-carousel {
      background: #7E7F81;
    }

    .ui-m-carousel-swiper-horizontal .ui-m-carousel-pagination .swiper-pagination-bullet-carousel {
      width: calc(9 * var(--hd));
      height: calc(9 * var(--hd));
      border-radius: 50%;
    }

    &-item {
      width: 100%;
      display: flex;
      flex-flow: wrap;
      padding-bottom: calc(20 * var(--hd));
    }
  }

  &-dialog-menu-item {
    flex: 1;
    min-height: calc(100 * var(--hd));
    margin-bottom: calc(6 * var(--hd));
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    padding: 0 calc(5 * var(--hd));
    overflow: hidden;

    &-icon {
      width: calc(40 * var(--hd));
      height: calc(40 * var(--hd));
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: var(--base-white);
      max-width: 100%;

      svg {
        width: calc(26 * var(--hd));
        height: calc(26 * var(--hd));
      }
    }
  }

  &-dialog-menu-item-notFlex {
    flex: unset;
    width: 25%;
  }

  &-dialog-menu-text {
    color: var(--m-readonly-text);
    font-size: var(--font-size-12);
    margin-top: calc(6 * var(--hd));
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    // 换行替换
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
}