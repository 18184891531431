/**
 * 注意：
 * 本文件禁止修改，如果要调整变量，就调整 weapp-base-css-vars 里的变量 ！！！
 */

/* --- 公共变量 --- */
:root {
  /* 基本单位 */
  --hd: 1px;

  /* --- Color 色彩 --- */

  /* Theme color 主题色 */
  --primary: #5d9cec;
  /* 主题色 */

  /* 聊天气泡背景*/
  --bubble-color: #dbefff;

  /* Auxiliary color 辅助色 */
  --success: #52c41a;
  /* 成功色 */
  --warning: #faad14;
  /* 普通警告色 */
  --danger: #ff4d4f;
  /* 强烈警告色 */
  --info: #68a5ff;
  /* 提示色 */
  --invalid: #bbbbbb;
  /* 失效状态色 */
  --disable: var(--placeholder-fc);
  /* 禁用色 */

  /* Neutral color 中性色 */
  --base-white: #ffffff;
  /* 基础白色 */
  --base-black: #000000;
  /* 基础黑色 */
  --transparent: transparent;
  /* 透明色 */

  --main-fc: #111111;
  /* 主要文字色 */
  --regular-fc: #666666;
  /* 常规文字色 */
  --secondary-fc: #999999;
  /* 次要文字色 */
  --invalid-fc: var(--invalid);
  /* 失效文字色 */
  --placeholder-fc: #cccccc;
  /* 占位文字色 */
  --link-fc: var(--primary);
  /* 链接文字色 */
  --white-fc: var(--base-white);
  /* 纯白字体色 */
  --black-fc: var(--base-black);
  /* 纯黑字体色 */

  --storke-color: var(--primary);
  /* 描边色 */
  --border-color: #e5e5e5;
  /* 边框色 */
  --diviling-line-color: #f2f2f2;
  /* 间隔线色 */
  --box-shadow-color: rgba(0, 0, 0, 0.12);
  /* 投影色 */
  --border-color-active: var(--primary);
  /* 高亮显示边框色 */
  --light-border-color: #ebedf0;
  /* 浅色边框色 */

  --m-readonly-text: #333333;
  /* h5 只读文字颜色 */
  --m-bg-base: #f7f7f7;
  /* h5 背景颜色 */
  --bg-base: #F0F1F4;
  /* 用于背景、输入框、标签以及一些需要加背景颜色的地方 */
  --bg-weak: #f1f1f1;
  /* 比浅背景色 */
  --bg-tap: #ebeff5;
  /* 按下背景色 */
  --bg-white: var(--base-white);
  /* 纯白背景色 */
  --bg-gray: #f7f9fb;
  /* 淡灰背景色 */
  --bg-transparent: var(--transparent);
  /* 透明背景色 */
  --label-bc: #f5f5f5;
  /* 标签/背景色 */
  --label-bc-active: #e9f7ff;
  /* 标签/选中背景色 */
  --mask-bc: rgba(0, 0, 0, 0.4);
  /* 蒙层背景色 */
  --tip-bc: rgba(0, 0, 0, 0.4);
  /* 轻提示背景色 */
  --hover-bc: #ecf5ff;
  /* 鼠标划过背景色 */
  --modal-bc: var(--bg-base);
  /* 弹框背景色 */
  --modal-top-bc: var(--bg-base);
  /* 弹框顶部背景色 */
  --invalid-bc: var(--invalid);
  /* 禁用背景色 */

  --collection-color: #f9c300;
  /* 选中收藏色 */

  --highlight-color: #FE1E1E;
  --highlight-bg: #FFE06A;
  /* 高亮颜色和背景色 */

  /* --- Typegraphy 字体 --- */

  /* Font family 字体 */

  --regular-ff: Arial, PingFangSC-Light, Hiragino Sans GB, Microsoft YaHei, sans-serif;
  /* 常规体 */
  --medium-ff: PingFangSC-Medium, Microsoft YaHei, Helvetica Neue, Helvetica,
    PingFang SC, Hiragino Sans GB, Arial, sans-serif;
  /* 中黑体 */
  --bold-ff: PingFang-SC-Bold, Microsoft YaHei, Helvetica Neue, Helvetica,
    PingFang SC, Hiragino Sans GB, Arial, sans-serif;
  /* 粗体 */

  /* Font spacing 间距（都以8的倍数计算） */
  --spacing-sm: calc(var(--hd) * 8 * 1);
  /* 字体小间距 */
  --spacing-md: calc(var(--hd) * 8 * 2);
  /* 字体中间距 */
  --spacing-lg: calc(var(--hd) * 8 * 3);
  /* 字体大间距 */

  /* Font Size 字号 */
  --font-size-10: calc(10 * var(--hd));
  /* 10字号 */
  --font-size-12: calc(12 * var(--hd));
  /* 12字号 */
  --font-size-14: calc(14 * var(--hd));
  /* 14字号 */
  --font-size-15: calc(15 * var(--hd));
  /* 15字号 */
  --font-size-16: calc(16 * var(--hd));
  /* 16字号 */
  --font-size-17: calc(17 * var(--hd));
  /* 17字号 */
  --font-size-18: calc(18 * var(--hd));
  /* 18字号 */

  --font-size-sm: var(--font-size-12);
  /* 小号字体 */
  --font-size-md: var(--font-size-14);
  /* 中号字体 */
  --font-size-lg: var(--font-size-16);
  /* 大号字体 */

  --font-size-icontext: var(--font-size-10);
  /* 图标字号 */
  --font-size-caption-sm: var(--font-size-12);
  /* 小标题字号 */
  --font-size-base: var(--font-size-14);
  /* 正文字号 */
  --font-size-subhead: var(--font-size-15);
  /* 自标题字号 */
  --font-size-caption: var(--font-size-16);
  /* 标题字号 */
  --font-size-heading: var(--font-size-17);
  /* 头部字号 */

  /* Line height 行高 */
  --line-height-base: calc(40 * var(--hd));
  /* 列表字体14px的时候 */
  --line-height-sm: calc(35 * var(--hd));
  /* 列表字体12px的时候 */

  /* Font weight 字体粗细 */
  --font-weight-base: 400;
  --font-weight-thin: 200;
  --font-weight-bold: 600;

  /* --- Spacing 间距 --- */

  /* Horizontal spacing 水平间距 */
  --h-spacing-xs: calc(4 * var(--hd));
  --h-spacing-sm: calc(6 * var(--hd));
  --h-spacing-md: calc(8 * var(--hd));
  --h-spacing-lg: calc(16 * var(--hd));
  --h-spacing-xl: calc(24 * var(--hd));

  /* Vertical spacing 垂直间距 */
  --v-spacing-xs: calc(4 * var(--hd));
  --v-spacing-sm: calc(6 * var(--hd));
  --v-spacing-md: calc(8 * var(--hd));
  --v-spacing-lg: calc(16 * var(--hd));
  --v-spacing-xl: calc(24 * var(--hd));

  /* --- Border 边框 --- */

  /* Border 边框 */
  --border-width: calc(1 * var(--hd));
  /* 边框宽度 */
  --border-width-lg: calc(2 * var(--hd));
  /* 边框宽度 加粗 */
  --border-solid: var(--border-width) solid var(--border-color);
  /* 实线边框 */
  --border-dotted: var(--border-width-lg) dotted var(--border-color);
  /* 虚线边框 */

  /* --- Diviling line 间隔线 --- */

  /* 间隔线宽度 */
  --diviling-line-width: calc(1 * var(--hd));
  /* 间隔线 */
  --diviling-line-solid: var(--diviling-line-width) solid var(--diviling-line-color);

  /* Border radius 圆角 */
  --border-radius-none: 0;
  /* 无圆角 */
  --border-radius-xs: calc(3 * var(--hd));
  /* 小圆角 */
  --border-radius-sm: calc(6 * var(--hd));
  /* 中圆角 */
  --border-radius-md: calc(8 * var(--hd));
  /* 大圆角 */
  --border-radius-lg: calc(15 * var(--hd));
  /* 圆形圆角 */

  /* Box shadow 投影 */
  --box-shadow-x: 0;
  /* 水平投影 */
  --box-shadow-y: calc(3 * var(--hd));
  /* 垂直投影 */
  --box-shadow-blur: calc(12 * var(--hd));
  /* 投影扩散 */
  --box-shadow-spread: calc(1 * var(--hd));
  /* 投影描边 */
  --box-shadow: var(--box-shadow-x) var(--box-shadow-y) var(--box-shadow-blur) var(--box-shadow-color);
  /* 无描边投影 */
  --box-shadow-storke: var(--box-shadow-x) var(--box-shadow-y) var(--box-shadow-blur) var(--box-shadow-spread) var(--box-shadow-color);
  /* 有描边投影 */

  /* z-index */
  --progress-zindex: 2000;
  --popover-zindex: 1999;
  --toast-zindex: 1999;
  --action-sheet-zindex: 1000;
  /* actonsheet 会放到 popup / modal 中*/
  --picker-zindex: 1000;
  --popup-zindex: 999;
  --dialog-draw-zindex: 900;
  /* modal.alert 应该最大，其他应该较小*/
  --modal-zindex: 999;
  /* modal.alert 应该最大，其他应该较小*/
  --tabs-pagination-zindex: 999;
  --dialog-route-layout-zindex: 998;
  /* 移动端路由层*/
  --dialog-confirm-zindex: 2999;
  /* confirm 层级*/
  --dialog-message-zindex: 2999;
  /* message 层级*/
  --map-zindex: 9;
  /* 地图的层级*/
  --map-wrap-zindex: 10;
  /* 地图内搜索框 switch框的层级*/
  --map-search-zindex: 11;
  /* 地图内搜索框 search框的层级*/
  --image-cropper-mask-zIndex: 10;
  /* 图片编辑蒙层层级 */
  --comment-search-zIndex: 10;
  /* 评论高级搜索层级 */
  --comment-search-panel-zIndex: 11;
  /* 评论高级搜索弹出层层级 */
  --richtext-video-zIndex: 9996;
  /* 富文本上传视频弹框层级: 主要是要比最大化的层级深一点 */
  --quick-menu-zIndex: 998;
  /* 快捷菜单按钮层级, 不能高于弹出框，否则会导致路由层样式异常 */

  /* transition 动画 */
  --transition-du: 0.3s;
  --transition-tf: cubic-bezier(0.645, 0.045, 0.355, 1);
  --transition-all: all var(--transition-du) var(--transition-tf);
  --transition-color: color var(--transition-du) var(--transition-tf);
  --transition-bc: background-color var(--transition-du) var(--transition-tf);
  --transition-w: width var(--transition-du) var(--transition-tf);
  --transition-h: height var(--transition-du) var(--transition-tf);

  /* 表单只读模式特殊处理 */
  --form-pc-readonly-line-height: 1.5;
  --form-pc-readonly-padding: calc(var(--hd) * 6) 0;
  --form-pc-readonly-padding-x: 0;
  --form-pc-readonly-padding-y: calc(var(--hd) * 6);
  --form-mobile-readonly-line-height: 1.67;
  --form-mobile-readonly-padding: calc(var(--hd) * 10) 0;
  --form-mobile-readonly-padding-x: 0;
  --form-mobile-readonly-padding-y: calc(var(--hd) * 10);
}