@import './menus_trigger.less';
@import './menus_trigger_dialog.less';
@import './menus_dialog.less';
@import './menus_popover.less';

.@{mQuickMenuClsPrefix} {

  &-drag,
  &-start {
    &-btn {
      width: calc(44 * var(--hd));
      height: calc(44 * var(--hd));
      position: fixed;
      right: calc(44 * var(--hd));
      bottom: calc(100 * var(--hd));
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: var(--primary);

      -webkit-touch-callout: none;
      user-select: none;
      z-index: var(--quick-menu-zIndex);

      .@{iconClsPrefix}-svg {
        color: var(--base-white);
        width: calc(30 * var(--hd));
        height: calc(30 * var(--hd));
      }
    }
  }

  &-drag {
    &-btn {
      // -webkit-touch-callout: none;
      // user-select: none;
      // z-index: var(--quick-menu-zIndex);
      // cursor: move;

      .ui-icon {
        transition: all 0.5s;
      }

      &-active-popover_mode {
        .ui-icon {
          transform: rotate(45deg);
          overflow: hidden;
        }
      }

      &-active-trigger_mode,
      &-active-dialog_mode {
        z-index: -1;
        opacity: 0;
      }
    }

    &-btn-icon {
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s;
    }
  }

  &-start {
    &-btn {
      .ui-icon {
        transition: all 0.5s;
      }

      &-active-popover_mode {
        .ui-icon {
          transform: rotate(45deg);
          overflow: hidden;
        }
      }

      &-active-trigger_mode,
      &-active-dialog_mode {
        z-index: -1;
        opacity: 0;
      }
    }
  }
}

.@{mQuickMenuClsPrefix}-counts {
  position: absolute;
  color: var(--base-white);
  font-size: var(--font-size-12);
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--base-white);
}

.@{mQuickMenuClsPrefix}-popoverText {
  .@{popoverClsPrefix}-content {
    word-wrap: break-word;
  }
}

// 四周展开模式下的button占位符号
.@{mQuickMenuClsPrefix}-triggerPlacementBtn {

  .ui-icon {
    transition: all 0.5s;
  }

  width: calc(44 * var(--hd));
  height: calc(44 * var(--hd));
  position: fixed;
  right: calc(44 * var(--hd));
  bottom: calc(100 * var(--hd));
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  z-index: calc(var(--popup-zindex) + 1);
  border-radius: 50%;
  transform: rotate(45deg);

  .@{iconClsPrefix}-svg {
    color: var(--base-white);
    width: calc(30 * var(--hd));
    height: calc(30 * var(--hd));
  }
}