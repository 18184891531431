.@{rateClsPrefix} {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: var(--rate-option-size);
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-style: normal;
  outline: none;
  cursor: pointer;

  &-rtl {
    direction: rtl;
  }

  &-option-box {
    display: flex;
    align-items: center;
  }

  // 默认状态
  &-option {
    margin: 0;
    padding: 0;
    display: inline-block;
    margin: 0 var(--rate-option-margin-right);
    // margin-right: var(--rate-option-margin-right);
    position: relative;
    transition: all 0.3s;
    color: var(--rate-default-color);
    cursor: pointer;
    line-height: 1.5;

    .@{rateClsPrefix}-rtl & {
      // margin-right: 0;
      // margin-left: var(--rate-option-margin-right);
      float: right;
    }

    &-inner {
      outline: none;
      position: relative;
      outline: none;

      .ui-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &-half {
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 100%;
        overflow: hidden;
        opacity: 0;

        &.need-mirror-RTL {
          transform: scaleX(1) !important;
        }

        .@{rateClsPrefix}-rtl & {
          right: 0;
          left: auto;
        }
      }
    }
  }

  // 选中（全选）
  &-option-full {
    .@{rateClsPrefix}-option-inner {
      &-full {
        color: var(--rate-active-color);
      }
    }
  }

  // 选中（半选）
  &-option-half {
    .@{rateClsPrefix}-option-inner {
      &-half {
        color: var(--rate-active-color);
        opacity: 1;
      }
    }
  }

  // 禁用状态
  &-disabled {
    cursor: not-allowed;

    // opacity: 0.5;
    .@{rateClsPrefix}-option-half-disabled {
      .@{rateClsPrefix}-option-inner-half-disabled {
        color: var(--rate-disabled-color);
      }
    }

    .@{rateClsPrefix}-option-full-disabled {
      .@{rateClsPrefix}-option-inner-full-disabled {
        color: var(--rate-disabled-color);
      }
    }
  }

  &-option-disabled {
    cursor: not-allowed;
  }

  // 只读状态
  &-readOnly {
    cursor: default;
    // opacity: 0.5;
  }

  &-option-readOnly {
    cursor: default;
  }
}