.@{titleClsPrefix} {
  padding: var(--v-spacing-md) var(--h-spacing-lg);
  background-color: var(--modal-top-bc);
  border-bottom: var(--border-solid);
  display: flex;
  flex-direction: row;
  &-col {
    white-space: nowrap;
  }
  &-hasMenu {
    padding: 0;
    border-bottom: 0;
    position: relative;
    .@{titleClsPrefix}-left {
      padding-left: var(--h-spacing-lg);
    }
    .@{titleClsPrefix}-right {
      padding-right: var(--h-spacing-lg);
    }
    .@{menuClsPrefix}-tab-top {
      padding: 0;
      min-height: auto;
    }
    .@{titleClsPrefix}-col:before {
      content: '';
      border-bottom: var(--border-solid);
      position: absolute;
      width: 100%;
      bottom: 0;
    }
  }
  &-noIcon {
    .@{titleClsPrefix}-title-top {
      padding-left: 0;
      line-height: calc(30 * var(--hd));
      height: calc(30 * var(--hd));
      vertical-align: middle;
    }
    .@{titleClsPrefix}-title {
      width: 100%;
    }
    .@{titleClsPrefix}-left-tabs {
      padding-left: 0;
    }
  }
  &-noTitle {
    .@{titleClsPrefix}-left-tabs {
      padding-top: calc(21 * var(--hd));
    }
  }
  &-hasMenu {
    .@{titleClsPrefix}-title-top {
      line-height: unset;
      height: auto;
    }
  }
  &-left-tabs { // 带页签模式
    padding-top: var(--v-spacing-md);
    .@{titleClsPrefix}-icon {
      margin: 0;
      vertical-align: top;
      margin-top: calc(3 * var(--hd));
      margin-bottom: calc(11 * var(--hd));
    }
    .@{titleClsPrefix}-title {
      padding-left: var(--h-spacing-md);
    }
    .@{titleClsPrefix}-title-top {
      padding-left: 0;
    }
    .@{titleClsPrefix}-title-title {
      margin-bottom: calc(-3 * var(--hd));;
    }
    .@{menuClsPrefix} {
      &-list-item, &-list-subitem-title { // item subItem
        height: calc(25 * var(--hd));
        line-height: calc(25 * var(--hd));
        color: var(--regular-fc);
      }
      &-list-item-active {
        color: var(--primary);
      }
    }
    .@{menuClsPrefix}-bar, .@{menuClsPrefix}-nav-container {
      height: calc(25 * var(--hd));
      bottom: auto;
    }
    .@{menuClsPrefix}-line-top {
      border-bottom-width: 0;
    }
  }
  &-title {
    display: inline-block;
    width: calc(100% - 44 * var(--hd));
    // width: calc(100% - var(--title-icon-width) - 2 * var(--v-spacing-md));
    vertical-align: middle;
  }
  .@{layoutClsPrefix}-col-24 {
    .@{titleClsPrefix}-noIcon .@{titleClsPrefix}-title {
      width: 100%;
    }
  }
  &-title-top {
    padding-left: var(--h-spacing-md);
    font-weight: var(--font-weight-bold);
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    color: var(--regular-fc);
    font-size: var(--font-size-12);
  }
  &-icon {
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    border: var(--title-icon-border);
    border-radius: calc(2 * var(--hd));
    width: calc(24 * var(--hd));
    height: calc(24 * var(--hd));
    margin: calc(3 * var(--hd)) 0;
    &-border {
      vertical-align: middle;
      width: 100%;
      height: 100%;
      display: table;
      vertical-align: center;
      justify-content: center;
      .@{iconClsPrefix}-wrapper {
        display: table-cell;
      }
      .ui-icon {
        color: var(--primary);
      }
    }
  }
  &-left{
    height: 100%;
    display: flex;
    align-items: center;
  }
  &-right {
    text-align: right;
    vertical-align: middle;
    height: 100%;
    display: table;
    width: 100%;
    position: relative;
    white-space: nowrap;
    &-container {
      display: table-cell;
      vertical-align: middle;
      height: 100%;//兼容ie11 ie11 table-cell不给予高度会有以自身高度优先计算的bug
      .@{menuClsPrefix}-select-btn{
        margin-left: var(--h-spacing-md);
        > button{
          margin-left: 0;
        }
      }
      & button {
        margin-left: var(--h-spacing-md);
      }
    }
  }
  .@{menuClsPrefix}-tab {
    border-bottom: 0;
    height: calc(25 * var(--hd));
    .@{menuClsPrefix}-tab-top-container {
      height: 100%;
      bottom: 0;
    }
  }
  &-inDialog {
    padding: calc(10 * var(--hd)) var(--h-spacing-lg) calc(10 * var(--hd)) 0;
    position: relative;
    .@{titleClsPrefix}-left {
      padding-left: var(--h-spacing-lg);
      &-tabs {
        padding-top: calc(10 * var(--hd));
      }
    }
    .@{titleClsPrefix}-title-top {
      font-size: var(--font-size-14);
      color: var(--main-fc);
    }
    .@{titleClsPrefix}-icon {
      width: calc(30 * var(--hd));
      height: calc(30 * var(--hd));
      margin: 0;
    }
    &-hasMenu {
      padding: 0;
      .@{titleClsPrefix}-icon {
        margin-bottom: calc(10 * var(--hd));
        margin-top: 0;
      }
    }
  }
  // &-normal {
  //   display: flex;
  //   .@{titleClsPrefix}-col {
  //     flex: 1;
  //   }
  // }
  // &-hasRight {
  //   // display: block;
  //   justify-content: space-between;
  //   .@{titleClsPrefix}-title {
  //     width: auto;
  //   }
  //   .@{titleClsPrefix}-col {
  //     // flex: none;
  //   }
  // }
  &-hideUnderLine {
    border-bottom: 0;
    .@{titleClsPrefix}-col:before {
      border-bottom: 0;
    }
  }
  // .@{sliderClsPrefix}-track{
  //   background-color: red;
  // }
}

/* 新版本效果 */
.@{titleClsPrefix}-noEllipsis {
  display: block;
  &.hasRight {
    position: relative;
  }
  &-right {
    position: absolute;
    top: 0;
    right: var(--h-spacing-lg);
    height: 100%;
    // background-color: var(--modal-top-bc);
    background-color: transparent;
    max-width: 54%;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    --ms-overflow-style: none;
    .@{titleClsPrefix}-right {
      display: inline-flex;
      align-items: center;
      padding-right: 0;
      .@{titleClsPrefix}-right-container{
        display: inline-flex;
        align-items: center;
      }
    }
    &-custom{
      display: inline-flex;
      align-items: center;
    }
  }
}
.@{titleClsPrefix}-noEllipsis.needProgress {
  .@{titleClsPrefix}-noEllipsis-right {
    top: calc(14 * var(--hd));
    height: calc(100% - 14 * var(--hd));
  }
}
.@{titleClsPrefix}-title-top-tooltip .@{popoverClsPrefix}-tooltip {
  max-width: calc(600 * var(--hd));
}
.@{titleClsPrefix}-title-top.scroll-title {
  text-overflow: unset;
  &:hover {
    @keyframes scroll {
      0% {
          transform: 0;
      }
    
      100% {
          transform: translateX(-100%);
      }
    }
    animation: scroll 10s linear infinite;
  }
}
.@{titleClsPrefix}-title-top.scroll {
  text-overflow: unset;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  --ms-overflow-style: none;
}

// 镜像
.@{titleClsPrefix}.needRTL {
  &:not(.@{titleClsPrefix}-noIcon) .@{titleClsPrefix}-title-top {
    padding-right: var(--h-spacing-md);
    padding-left: 0;
  }
}
.@{titleClsPrefix}-inDialog-hasMenu.needRTL {
  .@{titleClsPrefix}-title-top {
    padding-left: var(--h-spacing-md);
    padding-right: 0;
  }
}
.weapp-ie11 .@{titleClsPrefix}-right-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
// 特殊浏览器兼容性
.@{titleClsPrefix}.needLineHeight {
  .@{titleClsPrefix}-title-top {
    line-height: 1.5;
  }
}