.@{dialogClsPrefix} {
  &-wrap {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: var(--dialog-route-layout-zindex);

    &-hasMask {
      // overflow-y: auto;
    }

    &-middle {
      height: 100%;
    }

    &-middle-center {
      display: flex;
      align-items: center;
    }

    &-top {
      bottom: auto;
      z-index: var(--dialog-draw-zindex);
    }

    &-bottom {
      z-index: var(--dialog-draw-zindex);
      top: auto;
    }

    &-left {
      z-index: var(--dialog-draw-zindex);
      right: auto;
    }

    &-right {
      z-index: var(--dialog-draw-zindex);
      left: auto;
    }
  }

  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: var(--dialog-route-layout-zindex);
    background-color: var(--mask-bc);

    &-left,
    &-right,
    &-top,
    &-bottom {
      z-index: var(--dialog-draw-zindex);
    }

    &-hide {
      display: none;
    }
  }

  &-content {
    position: relative;
    margin: 0 auto;
    background-color: var(--modal-bc);
    // top: calc(100 * var(--hd));
    box-shadow: var(--dialog-box-shadow);
    animation-duration: var(--dialog-animation-time);
    -webkit-animation-duration: var(--dialog-animation-time);
    -o-animation-duration: var(--dialog-animation-time);
    -moz-animation-duration: var(--dialog-animation-time);
    transition: var(--dialog-scale-transition);

    &-closeAnimation {
      animation: none;
      transition: none;
    }

    &-top {
      top: 0;

      &-fadeIn {
        animation-name: DialogTopFadeIn;
        -webkit-animation-name: DialogTopFadeIn;
        -o-animation-name: DialogTopFadeIn;
        -moz-animation-name: DialogTopFadeIn;
      }

      &-fadeOut {
        animation-name: DialogTopFadeOut;
        -webkit-animation-name: DialogTopFadeOut;
        -o-animation-name: DialogTopFadeOut;
        -moz-animation-name: DialogTopFadeOut;
        top: -100%;
      }
    }

    &-bottom {
      top: 0;

      &-fadeIn {
        animation-name: DialogBottomFadeIn;
        -webkit-animation-name: DialogBottomFadeIn;
        -o-animation-name: DialogBottomFadeIn;
        -moz-animation-name: DialogBottomFadeIn;
      }

      &-fadeOut {
        animation-name: DialogBottomFadeOut;
        -webkit-animation-name: DialogBottomFadeOut;
        -o-animation-name: DialogBottomFadeOut;
        -moz-animation-name: DialogBottomFadeOut;
        bottom: -100%;
      }
    }

    &-left {
      top: 0;

      &-fadeIn {
        animation-name: DialogLeftFadeIn;
        -webkit-animation-name: DialogLeftFadeIn;
        -o-animation-name: DialogLeftFadeIn;
        -moz-animation-name: DialogLeftFadeIn;
      }

      &-fadeOut {
        animation-name: DialogLeftFadeOut;
        -webkit-animation-name: DialogLeftFadeOut;
        -o-animation-name: DialogLeftFadeOut;
        -moz-animation-name: DialogLeftFadeOut;
        left: -100%;
      }
    }

    &-right {
      top: 0;
      box-shadow: var(--dialog-box-shadow);

      &-fadeIn {
        animation-name: DialogRightFadeIn;
        -webkit-animation-name: DialogRightFadeIn;
        -o-animation-name: DialogRightFadeIn;
        -moz-animation-name: DialogRightFadeIn;
      }

      &-fadeOut {
        animation-name: DialogRightFadeOut;
        -webkit-animation-name: DialogRightFadeOut;
        -o-animation-name: DialogRightFadeOut;
        -moz-animation-name: DialogRightFadeOut;
        right: -100%;
      }
    }

    &-middle {
      border-radius: var(--dialog-border-radius);

      .@{titleClsPrefix} {
        border-top-left-radius: var(--dialog-border-radius);
        border-top-right-radius: var(--dialog-border-radius);
      }

      &-message {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &-fadeIn {
        animation-name: DialogMiddleFadeIn;
        -webkit-animation-name: DialogMiddleFadeIn;
        -o-animation-name: DialogMiddleFadeIn;
        -moz-animation-name: DialogMiddleFadeIn;
      }

      &-fadeOut {
        animation-name: DialogMiddleFadeOut;
        -webkit-animation-name: DialogMiddleFadeOut;
        -o-animation-name: DialogMiddleFadeOut;
        -moz-animation-name: DialogMiddleFadeOut;
        // top: -100%;
        opacity: 0;
        bottom: auto;
        visibility: hidden;
      }
    }

    &-scale {
      transition: var(--dialog-scale-transition);

      &-closeAnimation {
        transition: none;
      }

      &-transition {
        .@{dialogClsPrefix}-body-container {
          transition: var(--dialog-scale-height-transition);
        }
      }
    }
  }

  &-content-hasMask {
    box-shadow: none;
  }

  &-header {
    position: relative;
    border-bottom: var(--border-solid);
    padding: var(--v-spacing-lg) var(--h-spacing-lg);

    &-draggable {
      cursor: move;
      user-select: none;

      &-fullscreen {
        cursor: default;
      }

      // .ui-title-title{
      //   width: unset;
      //   max-width: calc(100% - 44 * var(--hd));
      // }
      &-title-top {
        .ui-title-title-top {
          max-width: 100%;
          display: inline-block;
          vertical-align: bottom;
          cursor: initial;
          user-select: text;
        }
      }
    }

    &-hasRightIcon {
      .@{titleClsPrefix}-inDialog {
        padding-right: calc(11 * var(--hd));
      }

      .@{titleClsPrefix}-hasMenu {
        padding-right: 0;

        .@{titleClsPrefix}-right {
          padding-right: calc(11 * var(--hd));
        }
      }
    }
  }

  &-body {
    padding: var(--v-spacing-lg) var(--h-spacing-lg);

    .weapp-profile-signature-share-dialog-empty {
      background-color: var(--bg-white);
    }

    &-container {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      height: 100%;
      background-color: var(--modal-top-bc);
    }
  }

  &-scale {
    display: inline-block;
    font-size: var(--font-size-md);
    cursor: pointer;
    vertical-align: middle;
    line-height: var(--line-height-base);
  }

  &-close {
    display: inline-block;
    font-size: var(--font-size-md);
    vertical-align: middle;
    line-height: var(--line-height-base);
  }

  &-icon {
    display: inline-block;
    cursor: pointer;
    top: calc(3 * var(--hd));
    vertical-align: middle;
    color: var(--secondary-fc);
    padding: calc(6 * var(--hd));
    margin-left: var(--h-spacing-md);

    .@{iconClsPrefix} {
      color: var(--secondary-fc);
    }

    &:hover {
      background-color: var(--dialog-icon-background);
      border-radius: var(--border-radius-xs);
    }
  }

  &-tool {
    display: inline-block;

    &-scale {
      display: inline-block;
      padding: calc(6 * var(--hd));
      margin-left: var(--h-spacing-md);
      cursor: pointer;

      .@{iconClsPrefix} {
        color: var(--secondary-fc);
      }

      &:hover {
        background-color: var(--dialog-icon-background);
        border-radius: var(--border-radius-xs);
      }
    }
  }

  &-menureset {

    .ui-menu-list,
    .ui-menu-list-item-active,
    .ui-menu-list-item-active .ui-icon {
      color: var(--secondary-fc);
    }

    .ui-menu-list-item:hover {
      color: var(--primary);

      .ui-icon {
        color: var(--primary)
      }
    }
  }

  &-footer {
    padding: calc(10 * var(--hd)) var(--h-spacing-lg);
    background-color: var(--modal-top-bc);
    text-align: right;

    &-middle {
      border-bottom-left-radius: var(--dialog-border-radius);
      border-bottom-right-radius: var(--dialog-border-radius);
    }

    & button {
      margin-right: calc(10 * var(--hd));
      display: inline-flex;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-resize {
    top: 0;
    left: 0;
    height: 100%;
    position: absolute;
    width: calc(4 * var(--hd));
    z-index: var(--dialog-route-layout-zindex);
    // cursor: col-resize;
    cursor: w-resize;

    &-left {
      left: auto;
      cursor: e-resize;
      right: 0;
    }

    &-top {
      top: auto;
      bottom: 0;
      width: 100%;
      height: calc(4 * var(--hd));
      cursor: s-resize;
    }

    &-bottom {
      cursor: n-resize;
      width: 100%;
      height: calc(4 * var(--hd));
    }

    // &:hover {
    // 	background-color: var(--bg-transparent);
    // }
    &-right.needRTL {
      cursor: e-resize;
    }

    &-left.needRTL {
      cursor: w-resize;
    }
  }

  &-layout {
    height: 100%;

    &-content {
      height: 100%;
    }

    .@{layoutClsPrefix}-header {
      display: ruby-base;
    }
  }
}

.@{dialogClsPrefix}-container.rtlSafari {

  .@{dialogClsPrefix}-mask,
  .@{dialogClsPrefix}-wrap {
    transform: translateZ(0);
  }
}

@keyframes DialogMiddleFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(.8);
    transform: scale(.8);
    // -webkit-transform-origin:  0 100%;
    // transform-origin: 0 100%;
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    // -webkit-transform-origin:  0 100%;
    // transform-origin: 0 100%;
  }
}

@keyframes DialogMiddleFadeOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.8);
    transform: scale(.8);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
  }
}

@keyframes DialogRightFadeIn {
  0% {
    right: -100%;
    opacity: 0;
  }

  100% {
    opacity: 1;
    right: 0;
  }
}

@keyframes DialogRightFadeOut {
  0% {
    opacity: 1;
    right: 0;
  }

  100% {
    right: -100%;
    opacity: 0;
  }
}


@keyframes DialogLeftFadeIn {
  0% {
    left: -100%;
    opacity: 0;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes DialogLeftFadeOut {
  0% {
    opacity: 1;
    left: 0;
  }

  100% {
    left: -100%;
    opacity: 0;
  }
}

@keyframes DialogTopFadeIn {
  0% {
    top: -100%;
    opacity: 0;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes DialogTopFadeOut {
  0% {
    top: 0;
    opacity: 1;
  }

  100% {
    opacity: 0;
    top: -100%;
  }
}

@keyframes DialogBottomFadeIn {
  0% {
    top: 100%;
    opacity: 0;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes DialogBottomFadeOut {
  0% {
    opacity: 1;
    top: 0;
  }

  100% {
    top: 100%;
    opacity: 0;
  }
}