.mcascader-check-list-item {
  &-extra {
    font-size: 16px;
    color: var(--primary);
    &.mcascader-needRTL{
      transform: scaleX(-1);
    }
  }

  &-readonly {
    cursor: unset;
  }
}