.@{formClsPrefix} {
  .@{collapseClsPrefix} {
    overflow: unset;
  }
  .@{collapseClsPrefix}-panel__content {
    margin: 0;
  }
  /* ----------------------------------------- 分组图标 -------------------------------------------- */
  &-groupIcon {
    &-show, &-hide {
      transition: transform 160ms cubic-bezier(0.5, 0, 0.5, 0.1);
    }
    &-show {
      transform: rotate(90deg);
    }
  }
  /* ----------------------------------------- 添加其他搜索条件 -------------------------------------------- */
  &-layout-add {
    color: var(--primary);
    font-size: var(--font-size-12);
    padding: calc(11 * var(--hd)) 0;
    vertical-align: middle;
    display: inline-block;
    padding-left: calc(26 * var(--hd));
    span {
      cursor: pointer;
      vertical-align: middle;
    }
    &-container {
      display: flex;
      align-items: center;
    }
  }
  /* ----------------------------------------- 表单模板样式 -------------------------------------------- */
  &-module {
    .@{formClsPrefix}-row {
      border-bottom: var(--form-item-border-module);
      &:empty {
        border-bottom: 0;
      }
      &-first {
        border-top: var(--border-solid);
      }
      .@{formClsPrefix}-col {
        border-right: var(--form-item-border-module);
        &:first-child {
          border-left: var(--border-solid);
        }
        &:last-child {
          border-right: var(--border-solid);
        }
      }
      &:last-child {
        border-bottom: var(--border-solid);
      }
    }
    .@{formClsPrefix}-row-clear {
      border-bottom: 0;
    }
    .@{formClsPrefix}-col {
      background-color: var(--bg-white);
    }
    .@{collapseClsPrefix}-panel__title {
      font-size: var(--font-size-12);
      position: relative;
      padding: var(--v-spacing-lg) 0 var(--v-spacing-sm) 0;
     .@{collapseClsPrefix}-panel__arrow {
       top: calc(var(--v-spacing-lg) - var(--collapse-panel-title-padding-v));
     }
      .@{formClsPrefix}-groupIcon-container {
        position: absolute;
        right: var(--h-spacing-md);
      }
      .@{iconClsPrefix} .Icon-up-arrow04 {
        color: #979797;
      }
    }
    .@{collapseClsPrefix}-panel__content-box, .@{collapseClsPrefix}-group .@{collapseClsPrefix}-panel__content-box {
      background-color: var(--bg-white);
      padding: 0;
    }
  }
  /* ----------------------------------------- 移动端表单模板样式 -------------------------------------------- */
  &-mobile {
    font-size: var(--font-size-md);
    .@{formClsPrefix}-searchGroup-mobile {
      padding-top: 0;
      background-color: var(--bg-base);
      &-body {
        padding-left: var(--h-spacing-lg);
        background-color: var(--bg-white);
        .@{formItemClsPrefix}-label {
          background: var(--bg-transparent);
          padding-left: 0;
        }
        .@{formItemClsPrefix}-wrapper {
          padding-left: 0;
        }
        .@{formClsPrefix}-row {
          border-bottom: 0;
          .border-solid-then;
          &:empty {
            &::after {
              background-color: var(--bg-transparent);
            }
          }
          &:last-child {
            &::after {
              background-color: var(--bg-transparent);
            }
          }
        }
      }
      &-body-custom {
        .@{formItemClsPrefix}-label {
          background: var(--bg-transparent);
          padding: 0;
          &-readOnly {
            padding: calc(13 * var(--hd)) 0;
          }
        }
        .@{formItemClsPrefix}-wrapper {
          background: var(--bg-transparent);
          padding: 0;
        }
        .@{formItemClsPrefix}-mobile {
          background-color: var(--bg-white);
          border-radius: var(--border-radius-md);
        }
        .@{formClsPrefix}-row {
          margin-bottom: calc(22 * var(--hd));
          &-allHide {
            margin-bottom: 0;
          }
        }
        .@{formItemClsPrefix} {
          padding: 0 var(--v-spacing-lg);
        }
      }
      &-title {
        color: var(--regular-fc);
        padding: calc(21 * var(--hd)) 0 calc(9 * var(--hd)) var(--h-spacing-lg);
        &-hide {
          padding: 0;
          padding-top: calc(9 * var(--hd));
        }
      }
    }
    .@{formClsPrefix}-layout-add {
      width: 100%;
      text-align: center;
      font-size: var(--font-size-14);
      display: inline-block;
      padding: 0;
      // background-color: var(--m-searchAdvanced-container-bg);
      &-container {
        border-radius: var(--border-radius-md);
        background-color: var(--bg-white);
        padding: calc(13 * var(--hd)) 0;
        display: inline-block;
        width: 100%;
      }
      .@{iconClsPrefix} {
        padding-right: var(--h-spacing-md);
      }
    }
    .@{formClsPrefix}-row {
      border-bottom: 0;
      .border-solid-then;
      &:empty {
        &::after {
          background-color: var(--bg-transparent);
        }
      }
      &:last-child {
        &::after {
          background-color: var(--bg-transparent);
        }
      }
    }
    .@{formItemClsPrefix} {
      position: relative;
      &-label-required:after {
        position: relative;
        vertical-align: middle;
        padding-left: calc(4 * var(--hd));
      }
      &-wrapper {
        padding-left: var(--h-spacing-lg);
        padding-right: var(--h-spacing-lg);
        .@{mListClsPrefix}-item.@{mListClsPrefix}-item {
          padding-left: 0;
          padding-right: 0;
        }
      }
      &-item-underline {
        border: 0;
      }
    }
    .@{formClsPrefix}Item-delete {
      background-color: var(--secondary-fc);
      color: var(--base-white);
      top: calc(-12 * var(--hd));
      padding: calc(4 * var(--hd));
      right: 0;
      /* ----------------------------------------- 自定义分组删除按钮样式 -------------------------------------------- */
      .@{iconClsPrefix}{
        color: var(--base-white);
      }
    }
    &-noLine {
      .@{formClsPrefix}-row {
        border-bottom: none;
        &::after {
          background-color: var(--bg-transparent);
        }
      }
    }
    &-card {
      .@{formClsPrefix}-searchGroup-mobile {
        padding: 0 calc(10 * var(--hd));
        padding-bottom: calc(17 * var(--hd));
        &-title {
          padding-left: calc(calc(21 * var(--hd)) - var(--h-spacing-lg));
        }
        &-body {
          padding: var(--v-spacing-lg) var(--h-spacing-lg);
          border-radius: var(--border-radius-lg);
        }
      }
      .@{formItemClsPrefix}-wrapper {
        padding-right: 0;
      }
    }
    .@{formItemClsPrefix}-noLabel {
      .@{formSwitchClsPrefix} {
        padding: calc(13 * var(--hd)) 0;
        &-UPLOAD {
          padding: 0;
        }
      }
    }

    .@{formItemClsPrefix}-noPadding {
      &-top {
        .@{formSwitchClsPrefix} {
          padding-top: 0;
        }
      }
      &-bottom {
        .@{formSwitchClsPrefix} {
          padding-bottom: 0;
        }
      }
      &-left {
        .@{formSwitchClsPrefix} {
          padding-left: 0;
        }
      }
      &-right {
        .@{formSwitchClsPrefix} {
          padding-right: 0;
        }
      }
      &-all {
        .@{formSwitchClsPrefix} {
          padding: 0;
        }
      }
    }
    /* ----------------- 移动端Form 统一去除Checkbox、Radio分割线 (非卡片模式且整行布局保留分割线) ----------------- */
    .@{mCcheckboxClsPrefix}-group .@{mCcheckboxClsPrefix}-label::after,
    .@{mRadioClsPrefix}-group .@{mRadioClsPrefix}-label::after {
      content: none;
    }
    &:not(&-card) .@{formItemClsPrefix}-wrapper-col-wholeLine {
      .@{mCcheckboxClsPrefix}-group .@{mCcheckboxClsPrefix}-label::after,
      .@{mRadioClsPrefix}-group .@{mRadioClsPrefix}-label::after {
        content: ' ';
      }
    }
    &:not(&-card) .@{formClsPrefix}-searchGroup-mobile-body {
      .@{mUploadClsPrefix}-detail-select, .@{mUploadClsPrefix} .@{mListClsPrefix}-content, .@{mUploadClsPrefix}-listB {
        padding-right: var(--h-spacing-lg);
      }
    }
    /* 2022/07/05 评审提出：非卡片模式且整行布局保留分割线, 且当Checkbox、Radio设置了isHoldRight，右侧图标加上分割线 */
    &:not(&-card) .@{formItemClsPrefix}-wrapper-col-wholeLine {
      .@{mCcheckboxClsPrefix}-group .@{mCcheckboxClsPrefix}-wrapper-hold-right:not(:last-child) ,
          .@{mRadioClsPrefix}-group .@{mRadioClsPrefix}-wrapper-hold-right:not(:last-child) {
        .border-solid-then;
      }
      .@{mCcheckboxClsPrefix}-group .@{mCcheckboxClsPrefix}-wrapper-hold-right:not(:last-child) .@{mCcheckboxClsPrefix}-label,
          .@{mRadioClsPrefix}-group .@{mRadioClsPrefix}-wrapper-hold-right:not(:last-child) .@{mRadioClsPrefix}-label {
        content: none;
      }
    }

    /* 移动的折叠面板 箭头样式 */
    .ui-collapse-panel--inactive .ui-collapse-panel__arrow {
      background-color: var(--bg-transparent);
    }

    &.needRTL {
      .@{mListClsPrefix}-item .@{mInputClsPrefix}-control .fake-input-container-left {
        .fake-input-placeholder, .fake-input {
          text-align: right;
        }
      }
    }
  }
  &-searchGroup-hideTitle {
    .@{collapseClsPrefix}-panel__title {
      display: none; // 需和ui确定，可能右边有按钮，不能隐藏
    }
    .@{formClsPrefix}-row-first {
      border-top: 0;
    }
  }
  /* ---  表单字段靠右对齐样式 ---- */
  &-isHoldRight {
    .@{formItemClsPrefix}-wrapper {
      text-align: right;

      .@{mListClsPrefix}-item .@{mInputClsPrefix}-control input,
      .@{mListClsPrefix}-item .@{mTextareaClsPrefix}-control textarea,
      .@{mScopeClsPrefix} .@{mScopeClsPrefix}-wrap .@{mScopeClsPrefix}-input,
      .@{mScopeClsPrefix} .@{mScopeClsPrefix}-wrap .@{mScopeClsPrefix}-line {
        text-align: right;
      }
      .@{mSwitchClsPrefix} {
        text-align: right;
        display: inline-block;
        button {
          margin-right: 0;
        }
      }
      .@{mSelectClsPrefix} .@{mInputClsPrefix}-control input {
        height: 100%;
      }
      .@{formSwitchClsPrefix}-encry .@{mListClsPrefix}-line {
        justify-content: end;
      }
    }
    &.needRTL .@{formItemClsPrefix}-wrapper {
      text-align: left;

      .@{mListClsPrefix}-item .@{mInputClsPrefix}-control input,
      .@{mListClsPrefix}-item .@{mTextareaClsPrefix}-control textarea,
      .@{mScopeClsPrefix} .@{mScopeClsPrefix}-wrap .@{mScopeClsPrefix}-input,
      .@{mScopeClsPrefix} .@{mScopeClsPrefix}-wrap .@{mScopeClsPrefix}-line {
        text-align: left;
      }

      .@{mSwitchClsPrefix} {
        text-align: left;
      }

      .@{formSwitchClsPrefix}-encry .@{mListClsPrefix}-line {
        justify-content: start;
      }
      .@{mListClsPrefix}-item .@{mInputClsPrefix}-control.is-readonly span {
        justify-content: end;
      }
      .@{mBrowserClsPrefix}.append-extra-ele .@{mBrowserClsPrefix}-suffix-btn {
        text-align: right;
      }

      .@{formSwitchClsPrefix}-INPUTNUMBER {
        text-align: right;
      }
    }
    &.needRTL {
      .@{formItemClsPrefix}-mobile .@{formItemClsPrefix}-label-noWholeLine {
        padding-left: 0;
        padding-right: var(--h-spacing-lg);
      }
    }
  }
  /* --- 强制隐藏必填图标 ---*/
  .hideRequiredIcon {
    .@{formItemClsPrefix}-requiredmark, .@{formSwitchClsPrefix}-showError, .@{formSwitchClsPrefix}-requiredMark, .@{formSwitchClsPrefix}-error-mobile-content {
      display: none;
    }
  }
}

.@{formClsPrefix}-skeleton-container {
  overflow: hidden;
  transition: height 0.5s ease-in-out 0s;
}

@import "./line.less";

/* 镜像 */
.@{formClsPrefix}-mobile-card.needRTL {
  .@{formItemClsPrefix}-mobile .@{formItemClsPrefix}-label-noWholeLine {
    padding-right: 0;
  }
}