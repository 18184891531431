.@{mColorPickerClsPrefix} {
  &-header {
    height: calc(60 * var(--hd));
    text-align: center;
    line-height: calc(60 * var(--hd));
    font-weight: 500;
    color: var(--black-fc);
    background-color: var(--bg-transparent);
  }

  &-panel {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: calc(20 * var(--hd)) 0;

    &-color {
      display: inline-block;
      width: calc(29 * var(--hd));
      height: calc(29 * var(--hd));
      cursor: pointer;
    }
  }

  &-alpha {
    width: calc(348 * var(--hd));
    margin: 0 auto;
    margin-bottom: calc(25 * var(--hd));

    &-span {
      display: inline-block;
      color: #999999;
      margin: calc(10 * var(--hd)) 0;
    }

    &-slider-container {
      background-image: url("data:image/png;base64,R0lGODdhCgAKAPAAAOXl5f///ywAAAAACgAKAEACEIQdqXt9GxyETrI279OIgwIAOw==");
      border-radius: calc(15 * var(--hd));
      overflow: hidden;
    }

    &-slider {
      width: calc(250 * var(--hd));
      height: calc(30 * var(--hd));
      position: relative;
      z-index: 1;
      margin-left: calc(-2 * var(--hd));

      &-btn {
        position: absolute;
        width: calc(28 * var(--hd));
        height: calc(28 * var(--hd));
        border: calc(2 * var(--hd)) solid var(--base-white);
        border-radius: 50%;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &-slider-rtl {
      transform: scaleX(-1);
    }

    &-setting {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: calc(5 * var(--hd)) 0 0;

      &>input {
        width: calc(70 * var(--hd));
        height: calc(26 * var(--hd));
        border-radius: calc(6 * var(--hd));
        border: 0;
        text-align: center;
      }
    }
  }

  &-footer {
    width: calc(348 * var(--hd));
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: calc(25 * var(--hd));
    padding-bottom: calc(25 * var(--hd));
    border-top: calc(1 * var(--hd)) solid var(--border-color);

    &-col-panel {
      width: calc(60 * var(--hd));
      height: calc(60 * var(--hd));
      border-radius: calc(6 * var(--hd));
      background-color: var(--base-black);
    }

    &-col-list {
      height: calc(60 * var(--hd));
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &>div {
        display: flex;
        justify-content: space-around;
      }

      &-item {
        display: inline-block;
        width: calc(24 * var(--hd));
        height: calc(24 * var(--hd));
        border-radius: 50%;
        cursor: pointer;

        &-selected {
          border: calc(1 * var(--hd)) solid;
          position: relative;

          &::after {
            content: "";
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: calc(22 * var(--hd));
            height: calc(22 * var(--hd));
            display: block;
            border: calc(3 * var(--hd)) solid var(--base-white);
            border-radius: 50%;
          }
        }
      }
    }
  }

  &-route-layout-warp {
    overflow-y: scroll;
    padding: calc(10 * var(--hd));
  }

  &-form-item {
    .ui-formItem-item-label {
      .@{mColorPickerClsPrefix}-form-item-color-block {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          display: inline-block;
          width: calc(24 * var(--hd));
          height: calc(24 * var(--hd));
        }

        &-transparent {
          position: relative;
          border: calc(1 * var(--hd)) solid var(--base-black);
          overflow: hidden;
          &:before {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            border-bottom: calc(23 * var(--hd)) solid var(--danger);
            border-left: calc(23 * var(--hd)) solid transparent;
            content: "";
          }
      
          &:after {
            position: absolute;
            left: calc(1 * var(--hd));
            right: 0;
            top: calc(0 * var(--hd));
            bottom: 0;
            border-bottom: calc(23 * var(--hd)) solid var(--base-white);
            border-left: calc(23 * var(--hd)) solid transparent;
            content: "";
          }
        }
      }
    }

    &-readOnly {
      .ui-formItem-item-placeholder {
        //color: #bbb;
        //visibility: hidden;
      }

      .Icon-Right-arrow01 {
        visibility: hidden;
      }
    }

    &-disabled {
      .ui-formItem-item-placeholder {
        color: var(--invalid);
        visibility: hidden;
      }

      .Icon-Right-arrow01 {
        visibility: hidden;
      }
    }
  }
}