.@{mMattersAssociatedClsPrefix} {
  &-item {
    display: flex;
    margin-bottom: calc(8 * var(--hd));
    &-title {
      display: inline-block;
      padding-right: calc(5 * var(--hd));
      font-size: var(--font-size-14);
      vertical-align: top;
      flex: none;      
      color: var(--secondary-fc);
      line-height: calc(14 * var(--hd));
      &::after {
        content: ":";
        padding: 0 calc(5 * var(--hd)) 0 calc(2 * var(--hd));
      }
    }
    &-browser {
      flex: auto;
      overflow: hidden;
      .@{mBrowserClsPrefix}-associative.is-associative .@{mBrowserClsPrefix}-associative-selected {
        padding: 0;
      }
      .@{mBrowserClsPrefix}-associative.is-single .@{mBrowserClsPrefix}-associative-selected-item {
        max-width: 100%;
        & > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .@{mBrowserClsPrefix}-associative {
        &-selected {
          line-height: initial;
          width: 100%;
          &-item {
            max-width: 100%;
            width: 100%;
          }
          &-list {
            vertical-align: top;
            & > .@{mListClsPrefix}-scrollview-content {
              position: relative;
            }
          }
          .@{mBrowserClsPrefix}-associative-selected-list.is-associative, .@{mBrowserClsPrefix}-associative-selected-list {
            .@{mListClsPrefix}-item {
              &-col .@{mListClsPrefix}-item-row {
                font-size: var(--font-size-12);
              }
            }
            .@{mListClsPrefix}-body {
              padding-top: 0;
              .@{mListClsPrefix}-item {
                display: flex;
                min-height: initial;
                margin-right: 0;
                margin-top: 0;
                margin-left: 0;
                line-height: calc(16 * var(--hd));
                &::before {
                  display: none;
                }
                .@{mListClsPrefix}-content {
                  display: inline;
                  max-width: inherit;
                  min-height: initial;
                  border: 0;
                  margin-left: 0;
                  font-size: var(--font-size-12);
                }
              }
            }
          }
        }
      }
      .@{mListClsPrefix}-item:not(:last-child) {
        margin-bottom: calc(8 * var(--hd));
      }
      .@{mBrowserClsPrefix}-associative-search-input {
        display: none;
      }
    }
  }
}