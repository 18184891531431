.@{hrmCardClsPrefix}-column {

  /* ------------------------通用--------------------------------------    */
  &-popup {
    animation: hrmCardSlideUpIn 0.5s;
    position: absolute;
    z-index: var(--popover-zindex);

    .@{hrmCardClsPrefix}-panel {
      z-index: var(--popover-zindex);

      &-container {
        min-height: calc(200 * var(--hd));
      }

      &-top {
        width: 100%;
        line-height: var(--line-height-base);
        height: var(--line-height-base);
        top: 0;
        padding-top: 0;
        padding-bottom: 0;
        z-index: var(--popover-zindex);

        &-close {
          top: calc(-1 * var(--hd));
        }
      }
    }
  }

  &-popup-rtl {
    left: unset !important;
  }

  &-layout {
    &-content {
      height: 100%;

      .@{spinClsPrefix}-container,
      .@{scrollerClsPrefix} {
        height: 100%;
      }
    }
  }

  &-loading-panel {
    height: 100%;
  }

  &-header-draggable {
    cursor: move;
    position: absolute;
    width: 100%;
    height: calc(30 * var(--hd));
    z-index: 9998;
  }

  &-loading-panel {
    // min-height: calc(200 * var(--hd));
    min-height: calc(270 * var(--hd));
  }

  &-panel {
    width: var(--hrm-card-panel-width);
    border-radius: var(--border-radius-sm);
    background-color: var(--bg-white);
    margin: var(--v-spacing-sm) 0;
    min-height: calc(200 * var(--hd));
    border: 1px solid #bebebe50;
    box-shadow: 0 0 calc(4 * var(--hd)) 0 rgba(109, 109, 109, 0.2);
  }

  &-panel-hide {
    display: none;
  }

  &-panel-empty {
    height: calc(200 * var(--hd));
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-panel-top {
    color: var(--main-fc);
    font-size: var(--font-size-12);
    background-color: var(--modal-top-bc);
    padding: 0 var(--h-spacing-lg);
    line-height: var(--line-height-base);
    height: var(--line-height-base);
    border-bottom: var(--border-solid);
    border-top-left-radius: var(--border-radius-sm);
    border-top-right-radius: var(--border-radius-sm);
    position: relative;
    display: flex;

    &-title {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: inline-block;
      padding-right: calc(12 * var(--hd) + var(--icon-size-xs));

      &-link {
        cursor: pointer;
      }
    }

    &-close {
      position: absolute;
      top: 0;
      right: calc(10 * var(--hd));
      cursor: pointer;
      color: var(--secondary-fc);

      .@{iconClsPrefix} {
        padding: calc(6 * var(--hd));

        &:hover {
          background-color: var(--dialog-icon-background);
          border-radius: var(--border-radius-xs);
        }
      }
    }
  }

  /* ------------------------内部联系人--------------------------------------    */
  &-panel {
    width: calc(490 * var(--hd));
  }

  &-panel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: calc(320 * var(--hd));
  }

  //关闭按钮bar
  &-panel-close {
    position: absolute;
    color: var(--secondary-fc);
    top: calc(5 * var(--hd));
    right: calc(10 * var(--hd));
    cursor: pointer;
    z-index: 9999;
  }

  //删除人员卡片
  &-panel-delete_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-text {
      margin-top: calc(5 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--secondary-fc);
    }
  }

  //左侧-内部人员卡片
  &-panel-info_card {
    width: calc(220 * var(--hd));
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: calc(20 * var(--hd));
    padding-bottom: var(--h-spacing-md);
    font-size: var(--font-size-12);
    // background: var(--primary);
    // background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.37) 100%);
    // background-image: url(./components/hrm-card/static/background.jpg);
    background-size: 100% 100%;
    background-image: var(--hrm-card-bg-image);

    border-radius: calc(6 * var(--hd)) 0 0 calc(6 * var(--hd));

    &-loading,
    &-empty {
      background: #E8E8E8;
    }

    &-img {
      color: var(--success);
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &-avatarWrap {
        width: calc(172 * var(--hd));
        height: calc(227 * var(--hd));
        border-radius: 6px;
        border: calc(3 * var(--hd)) solid rgba(255, 255, 255, 0.4);
        position: relative;
        // border: 1px solid pink;
        // background: linear-gradient(-135deg, transparent 50px, #2baaca 0);

        &-unknow-iconWrap {
          width: 100%;
          height: 100%;
          background: #F4F7FA;
          padding-bottom: 6px;
          display: flex;
          align-items: center;
          justify-content: center;

          .@{iconClsPrefix} {
            width: 70%;
            height: 70%;
          }
        }

        &-unknow-loading {
          background: #F6F6F6;
          border: 3px solid #F6F6F6;
        }

        &-qrcodeIcon {
          position: absolute;
          top: calc(-15 * var(--hd));
          right: calc(-14 * var(--hd));
          z-index: 12;
          width: 30px;
          height: 30px;
          transform: rotate(-45deg);
          display: flex;
          overflow: hidden;

          &-icon {
            position: absolute;
            transform-origin: 0px 0px;
            transform: rotate(45deg);
            color: var(--primary);
            background-color: var(--bg-white);
            border-radius: 0 3px 0 0;

            &:hover {
              cursor: pointer;
            }
          }
        }

        &-qrcodeContent {
          width: 100%;
          height: 100%;
          background-color: var(--bg-white);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: absolute;
          z-index: 12;
          border-radius: 4px;

          &-text {
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--black-fc);
          }

          &-returnIcon {
            position: absolute;
            top: 3px;
            right: 3px;
            color: var(--primary);

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      &-avatar {
        width: 100%;
        height: 100%;
        background: #ffffff;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;

        .@{avatarClsPrefix}-lg {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .@{avatarClsPrefix}-img {
          width: unset !important;
          height: unset !important;
        }

        img {
          max-width: 100%;
          max-height: 100%;
          flex: 1; // ie 11 max-width 失效，使用 flex 撑满
          object-fit: contain;
          border-radius: 4px;
        }
      }

      &-mask {
        top: calc(15 * var(--hd));

        &-175 {
          height: calc(175 * var(--hd));
        }

        &-195 {
          height: calc(195 * var(--hd));
        }

        &-225 {
          height: calc(225 * var(--hd));
        }

        width: calc(172 * var(--hd));
        border-radius: 6px;
        background-color: var(--bg-transparent);
        color: transparent;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 11;
        cursor: pointer;

        &-hidden {
          display: none;
        }

        &-content {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          color: var(--base-white);
          display: flex;
          align-items: center;
          justify-content: center;
          display: none;
          cursor: pointer;
        }

        &:hover {
          .@{hrmCardClsPrefix}-column-panel-info_card-img-mask-content {
            display: flex;
            background-color: rgba(0, 0, 0, 0.60);
            color: var(--base-white);
          }
        }

        &-icon {
          color: var(--base-white);
        }
      }

      &-name {
        width: calc(166 * var(--hd));
        height: calc(227 * var(--hd));
        padding-bottom: 3px;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        color: var(--base-white);
        left: calc(var(--hd) * ((220 - 172)/2 + 3)); //(左侧宽度宽度220px - 图片宽度172px)/2 + 图片border3px
        top: calc(15 * var(--hd));

        &-content {
          display: flex;
          flex-direction: column;
          z-index: 12;
          color: var(--base-white);
          background: rgba(0, 0, 0, 0.30);
          border-radius: 0 0 4px 4px;

          &:hover {
            .@{hrmCardClsPrefix}-column-panel-info_card-img-name-content-name-label-isWeaver {
              border-bottom: 2px solid var(--base-white);
            }
          }

          &-name,
          &-workcode {
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &-name {
            height: calc(25 * var(--hd));
            ;

            &-label {
              font-size: var(--font-size-14);
              font-weight: var(--font-weight-bold);
              color: var(--base-white);
              vertical-align: middle;
              margin: 0 var(--h-spacing-md);
              max-width: calc(130 * var(--hd));
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              &-isWeaver {
                cursor: pointer;
              }
            }

            &-sex {
              &-m {
                color: var(--hrm-card-hrm-sex-bg-m);
              }

              &-f {
                color: var(--hrm-card-hrm-sex-bg-f);
                transform: rotate(-45deg);
              }
            }
          }

          &-workcode {
            height: calc(20 * var(--hd));

            &-label {
              font-size: var(--font-size-12);
              vertical-align: middle;
              max-width: calc(150 * var(--hd));
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    &-emptyButtons {
      width: calc(28 * var(--hd));
      height: calc(28 * var(--hd));
      border-radius: 50%;
      background: var(--bg-white);
      margin: 0 calc(5 * var(--hd));
      ;
    }

    &-buttons {
      height: calc(35 * var(--hd));
      color: var(--base-white);
      display: flex;
      align-items: center;
      justify-content: center;

      &-iconWrap {
        height: 100%;
        border-radius: 50%;
        width: calc(28 * var(--hd));
        height: calc(28 * var(--hd));
        background: rgba(0, 0, 0, 0.40);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 calc(5 * var(--hd));
        cursor: pointer;

        .@{iconClsPrefix} {
          color: rgba(255, 255, 255, 0.80)
        }

        &:hover {
          // background: var(--bg-white);
          background: rgba(0, 0, 0, 0.60);

          .@{iconClsPrefix} {
            // color: var(--primary);
            color: var(--base-white);
          }
        }
      }
    }

    &-follow {
      cursor: pointer;
      text-align: center;
      height: calc(28 * var(--hd));
      position: absolute;
      top: 0;
      left: calc(16 * var(--hd));
      z-index: 9999;

      &-willFollow,
      &-didFollow,
      &-unapprovedFollow {
        height: 100%;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
        border-radius: calc(14 * var(--hd));
        font-size: var(--font-size-12);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
      }

      //已申请关注
      &-unapprovedFollow-approved {
        .@{hrmCardClsPrefix}-column-panel-info_card-unapprovedFollow-show {
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(28 * var(--hd));
          height: calc(28 * var(--hd));
          border-radius: 50%;

          .@{iconClsPrefix} {
            color: #5D9CEC;
          }
        }

        .@{hrmCardClsPrefix}-column-panel-info_card-unapprovedFollow-hover {
          display: none;
          color: #5D9CEC;
          padding: 0 calc(8 * var(--hd));

          .@{iconClsPrefix} {
            color: #5D9CEC;
          }
        }

        &:hover {
          background: #F3F8FF;

          .@{hrmCardClsPrefix}-column-panel-info_card-unapprovedFollow-show {
            display: none;
          }

          .@{hrmCardClsPrefix}-column-panel-info_card-unapprovedFollow-hover {
            display: flex;
          }
        }
      }

      //未关注以及hover效果
      &-willFollow-approved {
        .@{hrmCardClsPrefix}-column-panel-info_card-willFollow-show {
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(28 * var(--hd));
          height: calc(28 * var(--hd));
          border-radius: 50%;
          color: #989898;

          .@{iconClsPrefix} {
            color: #989898;
          }
        }

        .@{hrmCardClsPrefix}-column-panel-info_card-willFollow-hover {
          display: none;
          color: #00B00B;
          padding: 0 calc(8 * var(--hd));

          .@{iconClsPrefix} {
            color: #00B00B;
          }
        }

        &:hover {
          background: #F6FFF7;

          .@{hrmCardClsPrefix}-column-panel-info_card-willFollow-show {
            display: none;
          }

          .@{hrmCardClsPrefix}-column-panel-info_card-willFollow-hover {
            display: flex;
          }
        }
      }

      //已经关注以及hover效果
      &-didFollow-approved {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 calc(10 * var(--hd));
        background: #FFFFFF;

        .@{hrmCardClsPrefix}-column-panel-info_card-didFollow-show {
          display: flex;
          color: #00B00B;

          .@{iconClsPrefix} {
            color: #00B00B;
          }
        }

        .@{hrmCardClsPrefix}-column-panel-info_card-didFollow-hover {
          display: none;
          color: #989898;

          .@{iconClsPrefix} {
            color: #989898;
          }
        }

        &:hover {
          background: #EEEEEE;

          .@{hrmCardClsPrefix}-column-panel-info_card-didFollow-show {
            display: none;
          }

          .@{hrmCardClsPrefix}-column-panel-info_card-didFollow-hover {
            display: flex;
          }
        }
      }
    }
  }

  //左侧-外部人员卡片
  &-panel-info_card {
    &-externalTip {
      position: absolute;
      top: 0;
      left: calc(53 * var(--hd));
      display: flex;

      &-left {
        width: 20px;
        height: 20px;
        /* 宽高 */
        background-color: #00000020;
        ;
        /* 颜色 */
        border-radius: 0 0 0 5px;
        /* 设置圆角 */
        transform: perspective(6px) scale(1.1, 2) rotateX(-16deg);
        /* 镜头距离元素表面的位置为2.2px，x轴为1倍y轴为2倍，绕x轴旋转-5度 */
        transform-origin: top right;

      }

      &-right {
        width: 20px;
        height: 20px;
        /* 宽高 */
        background-color: #00000020;
        ;
        /* 颜色 */
        border-radius: 0 0 5px 0;
        /* 设置圆角 */
        transform: perspective(6px)scale(1.1, 2) rotateX(-16deg);
        /* 镜头距离元素表面的位置为2.2px，x轴为1倍y轴为2倍，绕x轴旋转-5度 */
        transform-origin: top left;
      }

      &-span {
        width: 70px;
        height: calc(20 * var(--hd));
        line-height: calc(20 * var(--hd));
        background: #00000020;
        color: var(--base-white);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  //左侧-离职人员卡片蒙层
  &-panel-info_card-not_on_job_mask {
    width: calc(220 * var(--hd));
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--bg-white);
    opacity: 0.3;
  }

  &-panel-info_card-external {
    background: #AFC0CC;
    background-image: none;
    // background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.37) 100%);
  }

  //右侧-详情卡片
  &-panel-detail_card {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    //flex:1 = flex:1 1 0 但ie11会有兼容问题拆开写先
    height: 100%;
    // padding-top: calc(32 * var(--hd));
    // padding-bottom: calc(32 * var(--hd));
    padding-top: calc(25 * var(--hd));
    padding-bottom: calc(25 * var(--hd));
    overflow: visible;

    // >div{
    //   overflow: visible;
    // }
    &-empty {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-workstatus {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: calc(30 * var(--hd));

      &-hide,
      &-noData {
        display: none;
      }

      .@{workStatusClsPrefix}-cover-un-edit {
        .@{workStatusClsPrefix}-un-edit {
          padding: 0 calc(14 * var(--hd));
        }
      }

      .@{workStatusClsPrefix}-un-edit {
        min-height: calc(16 * var(--hd));
        padding: 0 calc(14 * var(--hd));
        height: calc(20 * var(--hd));
      }

      .@{workStatusClsPrefix}-emoji {
        height: calc(18 * var(--hd));
        line-height: calc(18 * var(--hd));
      }

      .@{workStatusClsPrefix}-content {
        max-width: calc(200 * var(--hd));
        color: var(--main-fc);
        height: calc(18 * var(--hd));
        line-height: calc(18 * var(--hd));
      }

      .@{workStatusClsPrefix}-edit-input-main {
        .@{iconClsPrefix} {
          color: var(--secondary-fc);
        }
      }

      .@{workStatusClsPrefix}-edit-input-buttons {
        display: inline-flex;
        justify-content: center;
        margin-left: calc(6 * var(--hd));
        // min-width: calc(110 * var(--hd));
      }

      .ui-work-status-edit-history-content-list-item {
        max-width: 230px;
      }
    }

    &-workstatus-others {
      cursor: default;
    }

    .@{spinClsPrefix}-container {
      border-top: var(--hrm-card-border);
    }

    &-detail {
      &-single {
        width: 100%;
        overflow: hidden;
        word-break: break-word;
        display: inline-flex;
        align-items: center;
        height: calc(30 * var(--hd));
        padding: 0 calc(20 * var(--hd));

        &-fourWords {
          .@{hrmCardClsPrefix}-column-panel-detail_card-item-label {
            max-width: calc(90 * var(--hd));
            flex: 0 0 auto;
          }

          .@{hrmCardClsPrefix}-column-panel-detail_card-item-wrapper {
            min-width: calc(100% - calc(90 * var(--hd)));
            flex: 1 1 auto;
          }
        }

        &__link {
          .@{hrmCardClsPrefix}-column-panel-detail_card-item-wrapper-content {
            cursor: pointer;
          }
        }
      }

      &-bottom {
        padding-bottom: calc(16 * var(--hd));
      }
    }

    &-item {
      font-size: var(--font-size-12);
      display: flex;
      align-items: center;
      width: 100%;

      &-main {
        .@{hrmCardClsPrefix}-panel-detail_card-column-item-label {
          min-width: calc(40 * var(--hd));
          flex: 0 0 auto;
          padding: 0;
        }
      }

      &-label {
        vertical-align: text-top;
        color: var(--secondary-fc);
        padding-right: var(--h-spacing-sm);

        &-content {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          word-wrap: break-word;
          word-break: break-word;
          white-space: nowrap;
        }
      }

      &-wrapper {
        vertical-align: text-top;
        color: var(--main-fc);
        width: 0;
        flex-grow: 1;

        &-content {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &-link {
            &:hover {
              color: var(--primary);
            }
          }
        }
      }
    }

    &-attend {
      display: flex;
      align-items: center;
      padding: 0 calc(18 * var(--hd));
      font-size: var(--font-size-12);

      &-position {
        height: calc(30 * var(--hd));
        line-height: calc(30 * var(--hd));
        flex: 2;
        display: flex;
        align-items: center;
        height: 100%;
        color: var(--primary);
        .ellipsis();

        &-icon {
          color: var(--primary);
          margin-right: var(--h-spacing-sm);
        }

        &-span {
          cursor: pointer;
          .ellipsis();
        }
      }

      &-ip {
        height: calc(30 * var(--hd));
        line-height: calc(30 * var(--hd));
        height: 100%;
        flex: 3;
        display: flex;
        align-items: center;
        height: 100%;
        color: var(--secondary-fc);
        .ellipsis();

        &-icon {
          color: var(--primary);
          background: #40D848;
          height: calc(5 * var(--hd));
          width: calc(5 * var(--hd));
          border-radius: 50%;
          margin: 0 calc(6 * var(--hd));

          &-offline {
            background: var(--invalid);
          }
        }

        &-span {
          .ellipsis();
        }
      }
    }

    &-more {
      width: calc(40 * var(--hd));
      height: calc(25 * var(--hd));
      right: 15px;
      bottom: 10px;
      border-radius: 14px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--font-size-12);
      color: var(--secondary-fc);
      cursor: pointer;
      background-color: #00000010;
      z-index: 10;

      &:hover {
        background-color: #00000020;
      }

      &-hidden {
        display: none;
      }
    }

    //statistics部分
    &-statistics_info {
      padding-top: calc(16 * var(--hd));
      padding-right: calc(8 * var(--hd));
      display: flex;
      flex-wrap: wrap;

      &-minHeight {
        min-height: calc(80 * var(--hd));
      }

      &-single {
        width: 25%;
        font-size: var(--font-size-12);
        color: var(--secondary-fc);
        cursor: pointer;
        text-align: center;
        min-height: calc(52 * var(--hd));

        &-number {
          font-size: var(--font-size-14);
          font-weight: 500;
          padding-bottom: var(--v-spacing-sm);
        }

        &-title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-bottom: calc(14 * var(--hd));

          &:hover {
            color: var(--primary);
          }
        }
      }
    }
  }
}