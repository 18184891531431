.@{mSwitchClsPrefix} {
  position: relative;
  line-height: 1;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--m-chk-default-bc);
  box-sizing: border-box;
  vertical-align: middle;

  &-hold-right {
    justify-content: space-between;
  }

  &-loading {
    position: relative;
    top: calc(-2 * var(--hd));
  }

  &-btn {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    vertical-align: middle;
    border-radius: var(--switch-border-radius);
    border: calc(1 * var(--hd)) solid transparent;
    background-color: var(--switch-default-bc);
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    margin-right: 16px;

    &-hold-right {
      margin: 0;
    }
  }

  &-title-label {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #333333;
    font-weight: 400;

    &-disabled {
      opacity: 0.5;
    }
  }

  &-sm {
    min-width: var(--m-switch-with-sm);
    height: var(--m-switch-height-sm);
    line-height: var(--m-switch-height-sm);
    top: calc(-1 * var(--hd));
    ;
  }

  &-md {
    min-width: var(--m-switch-with-md);
    height: var(--m-switch-height-md);
    line-height: var(--m-switch-height-md);
  }

  &-lg {
    min-width: var(--m-switch-with-lg);
    height: var(--m-switch-height-lg);
    line-height: var(--m-switch-height-lg);
  }

  &-inner {
    position: absolute;
    left: var(--switch-inner-left);
    top: 0;
    color: var(--switch-inner-color);
    font-size: var(--switch-inner-font-size);
    width: var(--switch-inner-size-lg);
    height: var(--switch-inner-size-lg);
    border-radius: 50% 50%;
    background-color: var(--switch-inner-color);
    cursor: pointer;
    transform: scale(1);
    transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    animation-duration: 0.2s;
    // animation-name: switchNoHover;

    &-sm {
      width: var(--m-switch-inner-size-sm);
      height: var(--m-switch-inner-size-sm);
    }

    &-md {
      width: var(--m-switch-inner-size-md);
      height: var(--m-switch-inner-size-md);
    }

    &-lg {
      width: var(--m-switch-inner-size-lg);
      height: var(--m-switch-inner-size-lg);
    }
  }

  &:focus {
    // box-shadow: 0 0 0 2px tint(#2db7f5, 80%);
    outline: none;
  }

  &-label {
    display: inline-block;
    color: var(--m-switch-inner-color);
    font-size: var(--m-switch-inner-font-size);
    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    user-select: text;

    &-sm {
      line-height: var(--m-switch-height-sm);
      margin-left: var(--m-switch-label-margin-inner-sm);
      margin-right: var(--m-switch-label-margin-sm);
    }

    &-md {
      line-height: var(--m-switch-height-md);
      margin-left: var(--m-switch-label-margin-inner-md);
      margin-right: var(--m-switch-label-margin-md);
    }

    &-lg {
      line-height: var(--m-switch-height-lg);
      margin-left: var(--m-switch-label-margin-inner-lg);
      margin-right: var(--m-switch-label-margin-lg);
    }
  }

  // 选中状态
  &-checked {
    background-color: var(--m-switch-checked-bc);

    .@{mSwitchClsPrefix}-inner {
      &-sm {
        left: var(--m-switch-inner-checked-left-sm);
      }

      &-md {
        left: var(--m-switch-inner-checked-left-md);
      }

      &-lg {
        left: var(--m-switch-inner-checked-left-lg);
      }
    }

    .@{mSwitchClsPrefix}-label {
      &-sm {
        margin-right: var(--m-switch-label-margin-inner-sm);
        margin-left: var(--m-switch-label-margin-sm);
      }

      &-md {
        margin-right: var(--m-switch-label-margin-inner-md);
        margin-left: var(--m-switch-label-margin-md);
      }

      &-lg {
        margin-right: var(--m-switch-label-margin-inner-lg);
        margin-left: var(--m-switch-label-margin-lg);
      }
    }

    .@{mSwitchClsPrefix}-handle {
      color: var(--m-switch-checked-bc);
    }
  }


  // 禁用状态
  &-btn-disabled {
    opacity: 0.5;

    .@{mSwitchClsPrefix}-inner {
      animation-name: none;
    }
  }


  // 只读状态
  &-readOnly {
    opacity: 0.5;

    .@{mSwitchClsPrefix}-inner {
      animation-name: none;
    }

    &:hover {
      .@{mSwitchClsPrefix}-inner {
        transform: scale(1);
        animation-name: none;
      }
    }
  }

  &-handle {
    color: var(--switch-default-bc);
  }

  &-handle {
    color: var(--m-switch-default-bc);
  }
}



// @keyframes switchHover {
//   0% {
//     transform: scale(1);
//   }
//   100% {
//     transform: scale(1);
//   }
// }

// @keyframes switchNoHover {
//   0% {
//     transform: scale(1);
//   }
//   100% {
//     transform: scale(1);
//   }
// }