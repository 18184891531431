.@{mattersAssociatedClsPrefix} {
  &-item {
    display: flex;
    &-title {
      display: inline-block;
      padding-right: calc(5 * var(--hd));
      padding-bottom: calc(10 * var(--hd));
      vertical-align: top;
      flex: none;
      color: var(--secondary-fc);
      line-height: 1.15;
      &::after {
        content: "\ff1a";
        padding: 0 calc(5 * var(--hd)) 0 calc(2 * var(--hd));
      }
    }
    &-browser {
      flex: auto;
      overflow: hidden;
      &&-readonly {
        .@{browserClsPrefix}-associative-selected-item {
          .ui-icon.item-delete-icon {
            display: none;
          }
        }
      }
      .@{browserClsPrefix}-associative-selected {
        // width: 100%;
        &-btn {
          display: none;
        }
        &-item {
          justify-content: flex-start;
          flex: 0;
          -ms-flex: auto; // ie下单独调整flex样式
          align-items: center;
          min-height: calc(14 * var(--hd));
          &:hover {
            background-color: var(--bg-base);
          }
          .item-delete-icon {
            flex: none;
          }
        }
        &-list {
          max-height: inherit;
        }
        .hide-more &-btn {
          display: initial;
        }
        .@{listClsPrefix}-item:hover {
          background-color: initial;
        }
        .@{listClsPrefix}-column .@{listClsPrefix}-body .@{listClsPrefix}-item {
          display: block;
          min-height: initial;
          margin-bottom: calc(8 * var(--hd));
          .@{listClsPrefix}-content {
            min-height: initial;
          }
          .@{listClsPrefix}-item-col {            
            width: 100%;
            // flex: 0 1;
          }
          .@{listClsPrefix}-item-row {            
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            width: 100%;
          }
        }
        .@{listClsPrefix}-scrollview-wrap {
          max-height: inherit;
        }
      }
    }
  }
}