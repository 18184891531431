/******************************** checkname dialog  ********************************/
.@{uploadClsPrefix}-checkname-dialog {
  // .@{uploadClsPrefix}-box{
  //   position: relative;
  // }
  // .@{uploadClsPrefix}-spin-box {

  // }
  .@{uploadClsPrefix}-checkname-item-folderInfo {
    min-height: calc(40 * var(--hd));
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    // padding: var(--form-item-padding-module);
    span {
      color: var(--primary);
      margin: 0 calc(10 * var(--hd));
    }
  }
  .@{uploadClsPrefix}-checkname-item-desc {
    color: var(--regular-fc);
    span {
      color: var(--danger);
      margin: 0 calc(10 * var(--hd));
    }
  }
  .@{uploadClsPrefix}-checkname-item-box {
    background-color: var(--bg-white);
  }
  .@{uploadClsPrefix}-checkname-item {
    border-right: var(--border-solid);
    border-left: var(--border-solid);
    border-bottom: var(--form-item-border-module);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: var(--form-item-padding-module);
    min-height: calc(40 * var(--hd));
    &:last-child {
      border-bottom: var(--border-solid);
    }
    &:first-child {
      border-top: var(--border-solid);
    }
    &-icon {
      display: flex;
      align-items: center;
      margin-right: calc(6 * var(--hd));
    }
    &-filebox {
      max-width: 100%;
      overflow: hidden;
      margin-right: calc(6 * var(--hd));
      font-size: var(--font-size-12);
    }
    &-filename {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-filesize {
      margin-top: calc(2 * var(--hd));
      color: var(--regular-fc);
    }
    &-radio {
      flex-wrap: nowrap;
      margin-left: auto;
    }
    &-input {
      min-width: calc(200 * var(--hd));
      width: calc(200 * var(--hd));
      &-error {
        border-color: var(--danger);
        &:focus {
          box-shadow: 0 0 calc(3 * var(--hd)) var(--danger);
        }
      }
      &-hidden {
        visibility: hidden;
      }
    }
  }
}
