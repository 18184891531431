/* 事项关联按钮样式 */
.@{browserClsPrefix}-types-associative {
  &-selected {
    &-item {
      display: flex;
      align-items: flex-start;
      &-label {
        width: calc(80 * var(--hd));
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: calc(30 * var(--hd));
        padding-right: var(--h-spacing-md);
        margin-right: var(--h-spacing-md);
        font-size: var(--font-size-sm);
        &::after {
          content: ":";
        }
      }
      &-content {
        &-free-tip {
          color: #fb6e52;
          font-size: var(--font-size-12);
          font-style: italic;
          margin-bottom: var(--v-spacing-xs);
        }
      }
    }
  }
  .@{browserClsPrefix}-types {
    &-browser-type {
      &-wrap {
        margin-right: var(--h-spacing-md);
      }
      .@{browserTypeClsPrefix}-input {
        min-width: calc(100 * var(--hd));
        max-width: calc(250 * var(--hd));
        min-height: calc(30 * var(--hd));
        .@{browserTypeClsPrefix}-search-input {
          width: calc(80 * var(--hd));
        }
      }
    }
  }
  /* 已选数据框内展示 */
  &.box-selection {
    /* 限定多选样式调整 */
    // // .is-multiple {
    // .@{browserClsPrefix}-associative {
    //   &-search {
    //     &:not(.is-empty) {
    //       &.display-input {
    //         width: calc(100 * var(--hd));
    //         &.with-input-addon {
    //           width: calc(132 * var(--hd));
    //         }
    //       }
    //     }
    //   }
    // }
    // // }
    .@{browserClsPrefix}-types-associative {
      &-selected-wrap:not(.is-readOnly) {
        display: block;
        min-height: calc(30 * var(--hd));
        overflow: hidden;
        border: var(--border-solid);
        border-radius: var(--input-border-radius);
        background-color: var(--input-bg);
        padding: 0 var(--input-padding-x);
        &:not(.show-input) {
          .@{browserClsPrefix}-types {
            &-associative {
              &-inner {
                display: none;
              }
            }
          }
        }
        .@{browserClsPrefix}-types {
          &-associative {
            &-selected {
              min-height: calc(30 * var(--hd));
              .ui-list-column {
                display: block;
              }
            }
            &-inner {
              border: none;
              min-height: calc(30 * var(--hd));
              .@{browserClsPrefix} {
                width: auto;
              }
            }
          }
        }
      }
      &-selected-box {
        // display: flex;
      }
      // 操作按钮样式调整
      &-selected-fun-btn {
        flex: 0 0 auto;
        .fun-btn:first-child {
          margin-left: 0;
        }
      }
    }
    .@{browserClsPrefix}-associative {
      &-search-input {
        border: none;
        box-shadow: none;
        padding-right: 0;
        .@{inputClsPrefix}-suffix {
          // width: calc(20 * var(--hd));
        }
      }
      &-search {
        &.with-input-addon {
          .@{browserClsPrefix}-associative-search-input {
            padding-right: calc(30 * var(--hd));
            &-addon {
              width: calc(24 * var(--hd));
              height: calc(24 * var(--hd));
              border-left: none;
              border-radius: calc(4 * var(--hd));
              top: calc(2 * var(--hd));
              .@{browserClsPrefix}-extra-btn-icon {
                margin-top: calc(-1 * var(--hd));
              }
            }
          }
        }
      }
    }
    .@{browserClsPrefix}-types-select {
      .@{selectClsPrefix}-input {
        border: none;
        box-shadow: none;
        padding-left: 0;
      }
    }
    .@{browserClsPrefix}-types-browser-type {
      .@{browserTypeClsPrefix}-input {
        border: none;
        box-shadow: none;
        padding-left: 0;
      }
    }
  }
}

.@{browserClsPrefix}-types-browser-item-prev {
  margin-right: var(--h-spacing-md);
}

.@{browserClsPrefix}-types-select-dropdown .@{selectClsPrefix}-option-list .@{selectClsPrefix}-option-list-empty {
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: var(--h-spacing-lg);
  padding-right: var(--h-spacing-lg);
}

.@{browserClsPrefix}-types {
  &-select-dropdown {
    .@{selectClsPrefix}-option {
      &.is-group-title {
        min-height: auto;
        color: var(--regular-fc);
      }
      &.has-seperator {
        margin-bottom: calc(16 * var(--hd));
        &::after {
          bottom: calc(-8 * var(--hd));
          border-color: var(--diviling-line-color);
        }
      }
    }
  }
}

.@{browserClsPrefix}-types-associative-dialog-content {
  .@{browserClsPrefix}-dialog-content-menu {
    &-item {
      &-content {
        position: relative;
        max-width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: calc(3 * var(--hd));
      }
      &-baner {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: calc(6 * var(--hd));
        height: calc(6 * var(--hd));
        border-radius: calc(6 * var(--hd));
        background-color: var(--primary);
      }
    }
  }
}

/** AssociativeBrowser 样式调整 */
.@{browserClsPrefix}-types-associative {
  .@{browserClsPrefix} {
    &-associative-search.display-input {
      width: calc(var(--hd) * 100);
      transition: var(--transition-w);
      &.with-input-addon {
        width: calc(var(--hd) * 132);
      }
    }
  }
}
