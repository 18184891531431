.@{mFilterButtonsClsPrefix} {
  display: flex;
  flex-wrap: wrap;
  padding: 0 var(--m-filter-buttons-padding);

  .@{mFilterButtonsClsPrefix}-filter-btn-wrap {
    padding: var(--m-filter-buttons-padding);

    .@{mFilterButtonsClsPrefix}-filter-btn {

      //button样式
      .@{mFilterButtonsClsPrefix}-btn{
        color: var(--regular-fc);
        padding: calc(2 * var(--hd));
        height: var(--m-filter-buttons-height);
        text-align: center;
        width: 100%;
        font-size: var(--font-size-14);
        cursor: pointer;
        border: calc(1* var(--hd)) solid var(--border-color);
        border-radius: var(--btn-radius);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    //选中样式
    .@{mFilterButtonsClsPrefix}-filter-btn-checked {
      .@{mFilterButtonsClsPrefix}-btn {
        color: var(--primary);
        border-color: var(--primary);
      }
    }

    //字数超出换行
    .@{mFilterButtonsClsPrefix}-filter-font-wrap {
      .@{mFilterButtonsClsPrefix}-btn {
        white-space: pre-wrap;
        word-break: break-word;
        line-height: 1.1;
        font-size: var(--font-size-12);
        letter-spacing: calc(0.5* var(--hd));
        display: flex;
        align-items: center;
       font-family: var(--medium-ff);
        button {
          word-wrap: break-word;
        }
      }
    }

  }
}