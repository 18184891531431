@easing-in-out: cubic-bezier(0.35, 0, 0.25, 1);

@effect-duration: 0.3s;

.@{mMenuClsPrefix}-mask {
  content: " ";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--mask-bc);
  z-index: 9;
}

.@{mMenuClsPrefix}-list {
  .@{mMenuClsPrefix}-mask {
    top: unset;
  }
}

.@{mMenuClsPrefix}-wrap {
  width: 100%;
  background: var(--bg-white);
  // position: absolute;
  // top: 0;
  // right: 0;
  // left: 0;

  &-active {
    z-index: 10;

    .@{mMenuClsPrefix}-top-active {
      .@{mMenuClsPrefix}-top-left {
        color: var(--primary);
      }
    }
  }

  .@{mMenuClsPrefix}-contentwrap {
    width: 100%;
    background-color: var(--bg-white);
  }
}

// 公共样式
.@{mMenuClsPrefix} {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: var(--m-menu-top-height);
  font-size: var(--font-size-14);
  color: var(--main-fc);
  background: var(--bg-white);

  &-mask {
    flex: 1;
  }

  &-top {
    position: relative;
    display: flex;
    flex-direction: row;
    height: var(--m-menu-top-height);
    line-height: var(--m-menu-top-height);
    box-sizing: border-box;

    &-text {
      float: left;
      height: 100%;
      max-width: 80%;
      width: auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-icon {
      float: left;
    }

    &-all {
      display: flex;
      flex: none;
      align-items: center;
      padding: calc(12 * var(--hd));
      color: var(--secondary-fc);
      &-active {
        color: var(--primary);
      }
    }

    &-extra {
      display: flex;
      flex: none;
      align-items: center;
    }

    .@{mMenuClsPrefix}-list {
      height: 100%;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
      width: 100%;
      transition: transform 0.3s @easing-in-out;

      &-item,
      &-subitem {
        position: relative;
        display: flex;
        height: 100%;
        flex-shrink: 0;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        float: left;
      }

      &-item-content {
        box-sizing: border-box;
        height: 100%;
        border-bottom: var(--border-width-lg) solid transparent;
      }

      &-subitem {
        .@{mMenuClsPrefix}-list-item-active {
          .@{mMenuClsPrefix}-list-item-content {
            border-color: transparent;
          }
        }

        &-title {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &-icon,
    .@{mMenuClsPrefix}-list-item-righticon {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .Icon {
        color: var(--secondary-fc);
        width: calc(16 * var(--hd));
        height: calc(16 * var(--hd));
        vertical-align: middle;
      }
    }
  }

  &-list {
    &-item {
      box-sizing: border-box;

      &-content {
        max-width: 100%;
        overflow: auto hidden;
        white-space: nowrap;
      }

      &-active {
        color: var(--primary);
      }

      &-disabled {
        color: var(--invalid);
      }

      &-lefticon {
        margin-right: calc(5 * var(--hd));
      }
    }
  }
 
  &-header {
    display: flex;
    align-items: center;
    padding: calc(12 * var(--hd)) var(--h-spacing-lg);
    .@{iconClsPrefix} {
      color: var(--secondary-fc);
      padding: 0 calc(5 * var(--hd));
    }
    .menu-header-item {
      display: inline-flex;
      align-items: center;
    }
  }
  &-content {
    display: flex;
    width: 100%;
    background: var(--bg-white);
    max-height: calc(400 * var(--hd));
    overflow: hidden;

    .@{mMenuClsPrefix}-list {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      float: left;
      width: 100%;
      padding-left: var(--v-spacing-lg);
      box-sizing: border-box;

      .list-main {
        flex: 1;
        overflow-y: auto;
        .@{mMenuClsPrefix}-list-item-content {
          padding-left: calc(2 * var(--v-spacing-lg));
        }
      }

      &-item {
        position: relative;
        height: var(--m-menu-item-height);
        box-sizing: border-box;
        padding-right: var(--v-spacing-lg);

        &-content {
          position: relative;
          display: flex;
          width: 80%;
          line-height: var(--m-menu-item-lineheight);
          top: 50%;
          transform: translateY(-50%);
          box-sizing: border-box;
          overflow: hidden;
          &-lefticon {
            margin-right: var(--v-spacing-xs);
            line-height: 0;
            padding-top: calc(2 * var(--hd))
          }
        }

        &-checkicon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: var(--v-spacing-lg);
        }
      }

      &-level1,
      &-level2,
      &-level3,
      &-lastlevel {
        padding-left: 0;

        .@{mMenuClsPrefix}-list-item {
          border-bottom: 0;
          padding-right: 0;

          &-content {
            padding-left: calc(var(--v-spacing-lg) - var(--m-menu-item-line-width));
            border-left: var(--m-menu-item-line-width) solid transparent;
          }
        }
      }

      &-level1 {
        background: var(--bg-white);

        .@{mMenuClsPrefix}-list-item-active {
          .@{mMenuClsPrefix}-list-item-content {
            border-color: var(--primary);
          }

          background: var(--bg-base);
        }
      }

      &-level2 {
        background: var(--bg-base);

        .@{mMenuClsPrefix}-list-item-active {
          .@{mMenuClsPrefix}-list-item-content {
            border-color: var(--primary);
          }

          background: var(--bg-weak);
        }
      }

      &-level3 {
        background: var(--bg-weak);
      }

      &-lastlevel {
        .@{mMenuClsPrefix}-list-item-content {
          width: calc(100% - 38 * var(--hd));
        }

        .@{mMenuClsPrefix}-list-item-active {
          .@{mMenuClsPrefix}-list-item-content {
            border-color: transparent;
          }

          background: var(--bg-transparent);
        }
      }
    }
  }

  &-extra {
    float: left;
    display: flex;
    align-items: center;
    height: 100%;
  }
}

// tab | menu 下拉面板样式: 支持图标、超长换行
.@{mMenuClsPrefix}-content-break {
  .@{mMenuClsPrefix}-list {
    &-item {
      display: flex;
      height: auto;
      padding: calc(11 * var(--hd)) 0;
      &-content {
        display: flex;
        top: unset;
        transform: none;
        white-space: normal;
        word-break: break-word;
      }
      &-checkicon {
        top: unset;
        transform: none;
      }
    }

    &-level1,
    &-level2,
    &-level3,
    &-lastlevel {
      .@{mMenuClsPrefix}-list-item-content {
        padding-left: var(--v-spacing-lg);
        border-left-width: 0;
      }
    }
    &-level1,
    &-level2 {
      .@{mMenuClsPrefix}-list-item-active .@{mMenuClsPrefix}-list-item-content::after {
        position: absolute;
        left: 0;
        top: 0;
        width: var(--m-menu-item-line-width);
        height: var(--m-menu-item-lineheight);
        background-color: var(--primary);
        content: '';
      }
    }
    &-lastlevel {
      .@{mMenuClsPrefix}-list-item-active .@{mMenuClsPrefix}-list-item-content::after {
        background-color: var(--bg-transparent);
      }
    }
  }
}

// tab navagation 公共样式
.@{mMenuClsPrefix}-tab, .@{mMenuClsPrefix}-navigation {
  .@{mMenuClsPrefix}-top {
    position: relative;
    width: 100%;
    &-left {
      position: relative;
      flex: auto;
      width: 100%;
      overflow: hidden;
    }
    &-header-mask {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;
      width: calc(10 * var(--hd));
      height: 100%;
      pointer-events: none;
      &-left {
        left: 0;
        background: linear-gradient(90deg,#fff,hsla(0,30%,100%,0));
      }
      &-right {
        right: 0;
        background: linear-gradient(270deg,#fff,hsla(0,30%,100%,0));
      }
    }
    &-header-underline {
      position: absolute;
      // bottom: 0;
      bottom: calc(0.3 * var(--hd));
      background-color: var(--primary);
      width: calc(26 * var(--hd));
      height: calc(2 * var(--hd));
      border-radius: var(--border-width-md);
    }
    .@{mMenuClsPrefix}-list {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      overflow-x: auto;
      overflow-y: hidden;
    }
    .@{mMenuClsPrefix}-list-item-wrap {
      flex: auto;
      padding: 0 calc(12 * var(--hd));
      .@{mMenuClsPrefix}-list-item, .@{mMenuClsPrefix}-list-subitem {
        width: 100%;
      }
      .@{mMenuClsPrefix}-list-item-content {
        border-bottom: 0;
      }
    }
  }
}

// tab (新ui)
.@{mMenuClsPrefix}-tab {
  .@{mMenuClsPrefix}-top-left {
    .@{mMenuClsPrefix}-list-item,
    .@{mMenuClsPrefix}-list-subitem {
      .@{mMenuClsPrefix}-list-item-righticon .Icon-Down-arrow04,
      .@{mMenuClsPrefix}-list-item-righticon .Icon-up-arrow04 {
        color: var(--secondary-fc);
      }
    }
  }
}

// tab横版-浮动布局
.@{mMenuClsPrefix}-tab-horizontal {
  .@{mMenuClsPrefix}-floating {
    .@{mMenuClsPrefix}-list-item-wrap {
      flex: none;
    }
    .@{mMenuClsPrefix}-list-item-wrap .@{mMenuClsPrefix}-list-item {
      width: auto;
      &-active {
        .@{mMenuClsPrefix}-list-item-content {
          position: relative;
          &::after {
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: calc(-14 * var(--hd));
            content: '';
            height: var(--border-width-lg);
            width: calc(28 * var(--hd));
            background-color: var(--primary);
          }
        }
      }
    }
    .menu-item-sortable {
      float: left;
    }
    .@{mMenuClsPrefix}-list-item-editicon {
      margin-bottom: var(--border-width-lg);
      margin-left: calc(6 * var(--hd));
      color: var(--secondary-fc);
      .ui-icon-svg {
        width: calc(16 * var(--hd));
        height: calc(16 * var(--hd));
      }
    }
  }
  .@{mMenuClsPrefix}-hideActiveLine .@{mMenuClsPrefix}-list-item-editicon {
    margin-bottom: 0
  }
}

// tab竖版-浮动布局
.@{mMenuClsPrefix}-tab-vertical {
  .@{mMenuClsPrefix}-list-item-wrap {
    position: relative;
    padding: calc(10 * var(--hd)) 0;
    padding-right: calc(6 * var(--hd)) ;
  }
  .@{mMenuClsPrefix}-list-sortwrap {
    position: relative;
    display: flex;
    align-items: flex-start;
    .@{mMenuClsPrefix}-list-item {
      flex: 1;
    }
    &-active::after {
      position: absolute;
      content: '';
      top: calc(-4 * var(--hd)); // 始终第一行居中
      left: 0;
      width: var(--border-width-lg);
      height: calc(24 * var(--hd));
      background-color: var(--primary);
    }
  }
  .@{mMenuClsPrefix}-list-item {
    display: flex;
    align-items: flex-start;
  }
  .@{mMenuClsPrefix}-list-item-content {
    white-space: pre-wrap;
    flex: 1;
  }
  .@{mMenuClsPrefix}-list-item-editicon {
    display: flex;
    padding: 0 var(--v-spacing-xs);
    margin-left: calc(5 * var(--hd));
    color: var(--regular-fc);
    svg {
      width: calc(16 * var(--hd));
      height: calc(16 * var(--hd));
    }
  }
  .menu-item-sortable {
    display: flex;
    padding: 0 var(--h-spacing-xs);
    color: var(--regular-fc);
    svg {
      width: calc(16 * var(--hd));
      height: calc(16 * var(--hd));
    }
  }
}

// 影藏底部蓝色线条
.@{mMenuClsPrefix}-hideActiveLine {
  .@{mMenuClsPrefix}-list-item-editicon {
    margin-bottom: 0;
  }
}

// menu
.@{mMenuClsPrefix}-menu {
  .@{mMenuClsPrefix}-top {
    padding: 0 var(--v-spacing-lg);

    &-left {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      height: 100%;
    }

    &-left-content {
      width: 100%;
      height: 100%;
    }

    &-content {
      height: 100%;
      width: 100%;
    }

    &-icon {
      width: calc(24 * var(--hd));
      text-align: center;

      .Icon-Down-arrow01,
      .Icon-up-arrow01 {
        color: var(--secondary-fc);
      }
    }
  }

  //单独处理searchbar的遮罩样式
  .@{mMenuClsPrefix}-wrap-insearch {
    .@{mMenuClsPrefix}-content {
      z-index: 10;
    }

    .@{mMenuClsPrefix}-mask {
      top: auto;
      margin-top: var(--v-spacing-md);
    }
  }

  // .@{mMenuClsPrefix}-wrap-active {
  //   .@{mMenuClsPrefix}-top-left {
  //     color: var(--primary);
  //   }

  //   .@{mMenuClsPrefix}-top-icon {
  //     .Icon-Down-arrow01,
  //     .Icon-up-arrow01 {
  //       color: var(--primary);
  //     }
  //   }
  // }
}

// navigation
.@{mMenuClsPrefix}-navigation {
  height: var(--m-menu-navigation-height);
  font-size: var(--font-size-10);

  .@{mMenuClsPrefix}-top {
    height: 100%;
    line-height: unset;
    border: 0;
    color: var(--secondary-fc);

    &::after {
      background-color: var(--bg-transparent);
    }
  }

  .@{mMenuClsPrefix}-list-item-content {
    border: 0;
    font-size: var(--font-size-12);
  }

  .@{mMenuClsPrefix}-list-item-lefticon {
    .@{iconClsPrefix}-svg {
      width: var(--icon-size-lg);
      height: var(--icon-size-lg);
    }
  }
}

.@{mMenuClsPrefix}-navigation-vertical {
  .@{mMenuClsPrefix}-top .@{mMenuClsPrefix}-list-item {
    display: inline-block;
    text-align: center;
    padding: var(--v-spacing-sm) 0;
  }

  .@{mMenuClsPrefix}-list-item-lefticon {
    display: block;
    margin-right: 0;
    margin-bottom: calc(3 * var(--hd));
  }

  .@{mMenuClsPrefix}-list-item-content {
    display: block;
    height: unset;
    line-height: var(--font-size-14);
  }
}

.@{mMenuClsPrefix}-navigation-horizontal {
  .@{mMenuClsPrefix}-list-item-content {
    line-height: var(--m-menu-navigation-height);
  }
}


// MMenu 镜像
.@{mMenuClsPrefix}-rtl {
  .@{mMenuClsPrefix}-content-break .@{mMenuClsPrefix}-list-level1 .@{mMenuClsPrefix}-list-item-content,
  .@{mMenuClsPrefix}-content-break .@{mMenuClsPrefix}-list-level2 .@{mMenuClsPrefix}-list-item-content,
  .@{mMenuClsPrefix}-content-break .@{mMenuClsPrefix}-list-level3 .@{mMenuClsPrefix}-list-item-content,
  .@{mMenuClsPrefix}-content-break .@{mMenuClsPrefix}-list-lastlevel .@{mMenuClsPrefix}-list-item-content {
    padding-right: var(--v-spacing-lg);
    padding-left: 0;
  }
  .@{mMenuClsPrefix}-content-break .@{mMenuClsPrefix}-list-level1 .@{mMenuClsPrefix}-list-item-active .@{mMenuClsPrefix}-list-item-content::after,
  .@{mMenuClsPrefix}-content-break .@{mMenuClsPrefix}-list-level2 .@{mMenuClsPrefix}-list-item-active .@{mMenuClsPrefix}-list-item-content::after {
    right: 0;
  }
  .@{mMenuClsPrefix}-content .@{mMenuClsPrefix}-list-item-content-lefticon {
    margin-right: 0;
    margin-left: var(--v-spacing-xs);
  }
}