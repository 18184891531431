.@{workStatusClsPrefix} {

  &-warp {
    position: relative;
    cursor: pointer;
    width: 100%;
    display: inline-flex;
    margin: 0 5px;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    font-size: var(--font-size-12);

    &-readonly {
      cursor: text;
    }

    &-popover-mode {
      .@{emoticionClsPrefix} {
        .ui-popover {
          margin-top: 15px;
          margin-left: 10px;

          .ui-popover-arrow {
            margin-left: calc(9 * var(--hd));
          }

          .ui-popover-arrow-border {
            margin-left: calc(9 * var(--hd));
          }
        }
      }
    }
  }

  &-un-edit {
    width: 100%;
    background: #fff;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-empty {
    display: flex;
    align-items: center;
    color: #CCCCCC;

    .ui-icon {
      margin-left: 5px;
      font-size: var(--font-size-12);
    }
  }

  &-emoji {
    display: inline-block;
    vertical-align: middle;
    >img{
      //margin-bottom: 3px;
    }
  }

  &-content {
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 60px);
    font-size: var(--font-size-12);
    display: inline-block;
    vertical-align: text-bottom;
  }

  &-cover {
    width: 100%;
    //border: var(--border-solid);
    //border-radius: var(--border-radius-sm);
    //box-shadow: var(--box-shadow);

    &-container {
      border: var(--border-solid);
      border-radius: 3px;
      box-shadow: var(--box-shadow);
    }
  }

  &-edit {
    width: 100%;
    background: #fff;

    &-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;

      &-main {
        display: flex;
        width: 59%;
        position: relative;
        vertical-align: middle;
        align-items: center;
        border: calc(1 * 1px) solid #e5e5e5;
        border-radius: var(--input-border-radius);
        box-shadow: var(--input-shadow);

        >span {
          padding: 2px 6px 2px 6px;
          border-right: 1px solid #e5e5e5;
        }

        .ui-input-wrap {
          border: 0;
          border-radius: 0;
          box-shadow: none;
          border-color: transparent;

          :active {
            border: 0;
            border-radius: 0;
            border-color: transparent;
          }
        }

        .ui-popover {
          margin-left: -1px;

          .ui-popover-arrow {
            //margin-left: calc(9 * var(--hd));
          }

          .ui-popover-arrow-border {
            margin-left: calc(9 * var(--hd));
          }

          .ui-popover-content {
            .@{emoticionClsPrefix}-content {
              .@{emoticionClsPrefix}-content-scroll {
                width: calc(192 * var(--hd));
                .@{emoticionClsPrefix}-content-inner {
                  width: calc(192 * var(--hd));
                }
              }
            }
            .@{emoticionClsPrefix}-content-item {
              width: 20%;
            }
          }
        }
      }

      &-buttons {
        display: inline-block;
        margin-left: 12px;
        vertical-align: middle;
      }
    }

    &-history {
      width: 100%;
      background: #fff;
      padding: 10px;
      z-index: 10;
      padding-right: 0;

      &-content {
        h3 {
          font-size: var(--font-size-13);
          line-height: 28px;
          color: #888;
          margin: 0;
        }

        &-list {
          &-item {
            cursor: pointer;
            transition: all .15s ease-in-out;
            line-height: 30px;
            padding-left: 7px;
            overflow: initial;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            align-items: center;

            span {
              margin-left: 4px;
              color: #888;
              width: calc(100% - 16px);
              overflow: hidden;
              text-overflow: ellipsis;
              display: inline-block;
              vertical-align: middle;
              font-size: var(--font-size-12);
            }

            img {
              height: 18px;
              width: 18px;
              margin-bottom: 2px;
            }

            &:hover {
              background: #f0f0f0;
            }
          }
        }
      }
    }
  }

}

.@{workStatusClsPrefix}-emoticon-wrapper-rtl {
  .ui-popover-content {
    .ui-emoticon-content {
      .ui-emoticon-content-scroll {
        width: calc(192 * var(--hd));
        .ui-emoticon-content-inner {
          width: calc(192 * var(--hd));
          >div {
            .ui-emoticon-content-group:last-child img {
              border-bottom: var(--border-solid);
            }
          }
          >div:last-child {
            .ui-emoticon-content-group:last-child img {
              border-bottom: none
            }
          }
        }
      }
    }
  }
}