@import "../auto-complete/style/index.less";
@import "../types-browser/style/index.less";
/** 详情列表样式 */
@import "../share-browser/components/style/index.less";
@import "../types-browser/components/style/index.less";
@import "../components/style/index.less";

.@{browserClsPrefix} {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}

/* 浏览按钮弹框 */
.@{browserClsPrefix}-dialog {
  &-title {
    display: flex;
    align-items: center;
    .@{titleClsPrefix}-title-top {
      overflow: visible;
    }
    &-tips {
      margin-left: calc(20 * var(--hd));
      display: flex;
      align-items: center;
      overflow: hidden;
      a {
        text-decoration: none;
      }
      .text {
        margin-left: calc(5 * var(--hd));
        color: var(--secondary-fc) ;
        font-size: var(--font-size-12);
        line-height: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .@{dialogClsPrefix}-body {
    padding: 0;
    // height: 100%;
  }
  &-content {
    display: flex;
    &-menu-wrap {
      flex: 0 0 auto;
      flex-basis: var(--browser-dialog-content-width);
      width: var(--browser-dialog-content-width);
      border-right: var(--border-solid);
    }
    &-menu {
      &-item {
        position: relative;
        display: flex;
        align-items: center;
        &-count {
          // position: absolute;
          // right: calc(5 * var(--hd));
          // top: 50%;
          // transform: translateY(-50%);
          flex: 0 0 auto;
          display: inline-block;
          text-align: center;
          padding: var(--h-spacing-xs);
          background: var(--primary);
          min-width: calc(20 * var(--hd));
          height: calc(20 * var(--hd));
          color: white;
          border-radius: calc(5 * var(--hd));
          margin-left: calc(3 * var(--hd));
          margin-right: calc(6 * var(--hd));
        }
      }
    }
    &-panel-free {
      height: calc(550 * var(--hd));
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .@{menuClsPrefix}.@{browserClsPrefix}-types-menu {
      padding-top: var(--h-spacing-lg);
      padding-bottom: var(--h-spacing-lg);
      padding-right: var(--h-spacing-md);
      overflow: hidden;
      width: 100%;
      .@{menuClsPrefix}-list {
        &-subitem {
          width: 100%;
        }
        &-item,
        &-subitem-title {
          flex: 1 1 auto;
          height: calc(40 * var(--hd));
          // line-height: calc(20 * var(--hd));
          color: var(--regular-fc);
          font-size: var(--font-size-md);
          padding: var(--v-spacing-lg) 0 var(--v-spacing-lg) var(--h-spacing-md);
          // margin: var(--v-spacing-lg) 0;
          border-left-width: var(--menu-border-width);
          border-left-style: solid;
          border-left-color: var(--transparent);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover {
            color: var(--primary);
          }
          &-content {
            max-width: 100%;
            text-indent: 0;
          }
          &-active {
            color: var(--primary);
            border-left-color: var(--primary);
          }
        }
      }
    }
    &-panel {
      &.is-hidden {
        display: none;
      }
      flex: 1 1 auto;
      overflow: hidden;
      border: var(--border-solid);
      margin: var(--v-spacing-lg) var(--h-spacing-lg);
      .@{spinClsPrefix}-blur {
        opacity: 1;
      }
      &-empty {
        padding-top: calc(120 * var(--hd));
      }
      // .@{emptyClsPrefix} {
      //   width: 100%;
      //   margin: 0;
      //   padding-top: calc(120 * var(--hd));
      //   background-color: var(--bg-white);
      //   // border: var(--border-solid);
      // }
    }
  }
}

/* 浏览按钮弹框面板 */
.@{mBrowserClsPrefix}-panel,
.@{browserClsPrefix}-panel {
  overflow-y: hidden;
  border-radius: var(--border-radius-xs);
  background-color: var(--bg-white);
  .@{checkboxClsPrefix} {
    &-left {
      width: var(--font-size-lg);
      height: var(--font-size-lg);
    }
    &-inner {
      border-radius: 50%;
      width: var(--font-size-lg);
      height: var(--font-size-lg);
    }
  }
  /* 面板顶部 */
  &-header {
    transition: var(--transition-h);
    .@{browserClsPrefix}-menu-select {
      padding-left: 0;
      margin-right: var(--h-spacing-md);
      max-width: calc(100 * var(--hd));
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .@{menuClsPrefix}-select-title {
        padding-left: 0;
        height: calc(42 * var(--hd));
        align-items: center;
      }
    }
    &-multiple-change {
      cursor: pointer;
      color: var(--secondary-fc);
      &.is-multiple {
        color: var(--primary);
      }
    }
  }
  /* 面板顶部工具栏 */
  &-header-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: calc(30 * var(--hd));
    padding-left: var(--h-spacing-lg);
    padding-right: var(--h-spacing-md);
    // padding-top: var(--h-spacing-md);
    .left-coms {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1 1 auto;
    }
    .right-coms {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 0 0 auto;
    }
    &-left-label {
      font-size: var(--font-size-sm);
      color: var(--secondary-fc);
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &.is-IE {
        white-space: nowrap;
      }
    }
    &-select {
      padding-left: var(--select-input-padding);
      padding-right: var(--select-input-padding);
      &:first-of-type {
        padding-left: 0;
      }
      .@{selectClsPrefix}-input {
        border: none;
        min-width: calc(35 * var(--hd));
        max-width: calc(120 * var(--hd));
        padding-left: 0;
        padding-right: 0;
        &.is-focus,
        &:focus {
          box-shadow: none;
        }
      }
      .@{inputClsPrefix}-suffix {
        margin-left: var(--h-spacing-sm);
      }
      &-dropdown {
        text-align: left;
      }
    }
    &-btn {
      cursor: pointer;
      display: inline-block;
      color: var(--invalid-fc);
      margin-right: var(--h-spacing-md);
      &:hover {
        color: var(--secondary-fc);
      }
      &.is-active {
        color: var(--primary);
      }
    }
  }
  /* 面板左侧 */
  &-left {
    height: 100%;
    overflow-y: auto;
    border-right: var(--border-solid);
    &-header-bar {
      &.has-padding-top {
        padding-top: calc(5 * var(--hd));
      }
    }
    &-search {
      padding: var(--h-spacing-md);
      text-align: left;
      padding-left: var(--h-spacing-lg);
      &-input {
        transition: var(--transition-all);
        &.is-leave {
          width: calc(30 * var(--hd));
          border-radius: 50%;
          padding-left: calc(6 * var(--hd));
          input {
            transition: var(--transition-all);
            opacity: 0;
          }
        }
        &.is-enter {
          width: 100%;
          input {
            opacity: 1;
          }
        }
      }
    }
    &-content-inner {
      height: 100%;
      overflow: auto;
    }
    &-inner {
      .@{browserClsPrefix}-panel-list {
        &-item {
          padding-right: var(--h-spacing-md);
        }
      }
    }
  }
  /* 面板内容容器 */
  &-content-box {
    height: 100%;
  }
  &-content {
    display: flex;
    height: 100%;
    .content-left,
    .content-main {
      height: 100%;
      display: flex;
      flex-direction: column;
      border-right: var(--border-solid);
      box-sizing: border-box;
    }
    &-inner {
      height: 100%;
      overflow: auto;
    }
    .content-main {
      flex: 1 1 auto;
      .@{mBrowserClsPrefix}-panel-content-spinner
        .@{browserClsPrefix}-panel-content-spinner {
        flex: 1 1 auto;
        height: 100%;
      }
    }
    .content-left {
      .@{browserClsPrefix}-panel {
        &-left-search {
          flex: 0 0 auto;
        }
        &-header-bar {
          flex: 0 0 auto;
        }
        &-tree-wrapper {
          flex: 1 1 auto;
          overflow: hidden;
        }
      }
    }
    &.is-hidden {
      display: none;
    }
    .content-empty-box {
      position: relative;
      height: 100%;
      min-height: calc(130 * var(--hd));
    }
    .content-loading,
    .content-empty {
      flex: 1 1 auto;
      margin: 0;
      width: 100%;
      text-align: center;
      box-sizing: border-box;
      // padding-top: calc(120 * var(--hd));
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &-spinner {
      width: 100%;
      overflow: hidden;
    }
  }
  &-left-spinner,
  &-content-spinner {
    text-align: center;
    .@{spinClsPrefix}-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  &-left-spinner {
    //flex: 0 0 auto;
    height: 100%;
    //flex-basis: var(--browser-dialog-left-panel-width);
    //max-width: var(--browser-dialog-left-panel-width);

    .content-empty-box {
      position: relative;
      height: 100%;
      min-height: calc(130 * var(--hd));
    }
    .content-empty {
      flex: 1 1 auto;
      margin: 0;
      width: 100%;
      text-align: center;
      box-sizing: border-box;
      // padding-top: calc(120 * var(--hd));
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .@{mListClsPrefix}-scrollview-wrap,
  .@{listClsPrefix}-scrollview-wrap {
    height: 100% !important;
    overflow: auto;
    .@{scrollerClsPrefix}__wrap {
      // margin-right: 0 !important;
    }
  }
  /* 面板右侧 */
  &-right-side {
    height: 100%;
  }
  /* 面板底部 */
  &-footer {
    height: calc(50 * var(--hd));
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    background-color: var(--bg-white);
    padding-left: var(--h-spacing-lg);
    padding-right: var(--h-spacing-lg);
    // box-shadow: calc(-5 * var(--hd)) calc(-3 * var(--hd)) calc(15 * var(--hd)) calc(1 * var(--hd)) var(--border-color);
    // transition: var(--transition-h);
    &.is-empty {
      height: 0;
    }
    &.has-left-data {
      justify-content: flex-end;
    }
    &-btns > .@{btnClsPrefix} {
      margin-left: var(--h-spacing-md);
    }
    &-more-btn {
      display: flex;
      margin-left: var(--h-spacing-md);
    }
  }
  &-wrap-spinner {
    .@{emptyClsPrefix} {
      margin-top: calc(120 * var(--hd));
    }
  }
  .@{treeClsPrefix}-warp {
    height: 100%;
    overflow: hidden;
  }
}
.@{browserClsPrefix}-panel {
  &-header-bar {
    // 选择交互
    .list-check-all {
      .check-btn {
        margin: 0 calc(5 * var(--hd));
        color: var(--secondary-fc);
        cursor: not-allowed;
        &.active {
          cursor: pointer;
          color: var(--primary);
        }
        &.hidden {
          display: none;
        }
      }
    }
    // .list-check-all {
    //   display: inline-block;
    //   min-width: calc(35 * var(--hd));
    //   // margin-right: var(--h-spacing-lg);
    //   cursor: pointer;
    //   font-size: var(--font-size-sm);
    //   color: var(--primary);
    //   &.is-index-list {
    //     margin-right: calc(
    //       40 * var(--hd) + var(--h-spacing-md) - var(--h-spacing-lg)
    //     );
    //   }
    // }
    // .list-check-all-data {
    //   .ui-menu-select-title-hover {
    //     .ui-icon {
    //       transform: rotate(90deg);
    //     }
    //   }
    // }
  }
  &-header-tab-bar {
    display: none;
    min-height: calc(35 * var(--hd));
    &.has-tab,
    &.has-extra {
      &.header-tab-extra{
        display: flex;
        .header-tab-extra{
          display: flex;
          align-items: center;
          padding-left: var(--h-spacing-lg);
          border-bottom: var(--border-width) solid var(--border-color);
        }
      }
      &.has-dropdown{
        display: flex;
        .ui-select{
          display: flex;
          align-items: center;
          padding: 0 calc(8 * var(--hd));
          border-bottom: var(--border-width) solid var(--border-color);
        }
      }
      display: block;
      .@{browserClsPrefix}-panel-header-tab-bar-extra {
        justify-content: flex-end;
      }
    }
    &-extra {
      height: calc(35 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: var(--h-spacing-md);
      border-bottom: var(--border-solid);
      .@{btnClsPrefix} {
        padding-left: var(--h-spacing-lg);
        padding-right: var(--h-spacing-lg);
      }
      &.header-extra {
    }
    }
    &.has-tab {
      .@{browserClsPrefix}-panel-header-bar-extra {
        border-bottom: none;
      }
    }
  }
  &-content {
    &-layout-box,
    &-spinner {
      height: 100%;
    }
  }

  &-left {
    &-side,
    &-spinner {
      height: 100%;
    }
  }
}

/* 搜索栏 */
.@{mBrowserClsPrefix}-search-bar,
.@{browserClsPrefix}-search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  // padding-left: var(--h-spacing-lg);
  // padding-right: var(--h-spacing-lg);
  &-left {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    height: calc(42 * var(--hd));
    padding-left: var(--h-spacing-lg);
  }
  &-right {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    height: calc(42 * var(--hd));
    padding-right: var(--h-spacing-lg);
  }
  .@{mBrowserClsPrefix}-search-advance,
  .@{browserClsPrefix}-search-advance {
    flex: 1 1 auto;
    .@{mSearchAdvancedClsPrefix} {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .@{browserClsPrefix}-search-advance {
    .@{browserClsPrefix}-search-browser {
      display: grid;
    }
  }
  .@{browserClsPrefix}-search-advance-outer-btns {
    .@{browserClsPrefix}-search-outer-btn {
      margin-left: var(--h-spacing-md);
      cursor: pointer;
      color: var(--secondary-fc);
      &.is-active {
        color: var(--primary);
      }
    }
  }
  &.has-left {
    border-bottom: var(--border-solid);
  }
  /* 搜索栏 状态按钮 */
  .@{browserClsPrefix}-panel-header-bar-btn {
    height: calc(42 * var(--hd));
    line-height: calc(40 * var(--hd));
    margin-left: var(--h-spacing-md);
    margin-right: 0;
  }
  /* 搜索栏 状态按钮菜单 */
  /* &.only-has-status-menu .@{browserClsPrefix}-search-bar-right {
    padding-right: var(--h-spacing-md);
  } */
  /* 搜索栏 可排序列表专用 */
  &.is-sortable {
    justify-content: flex-end;
    .@{mBrowserClsPrefix}-search-bar,
    .@{browserClsPrefix}-search-bar {
      &-sortable-icon {
        flex: 0 0 auto;
        color: var(--secondary-fc);
        -webkit-tap-highlight-color: transparent; // 去除ios点击时的灰色背景
        .@{iconClsPrefix} {
          &.is-active {
            color: var(--primary);
          }
        }
      }
    }
    .@{mBrowserClsPrefix}-search-bar-sortable-icon {
      padding-left: var(--h-spacing-lg);
    }
  }
  &-sortable-list {
    .@{selectClsPrefix}-dropdown {
      margin-top: calc(12 * var(--hd));
    }
    .@{selectClsPrefix}-option {
      padding: 0;
      cursor: pointer;
    }
    &-item {
      display: flex;
      align-items: center;
      min-height: var(--select-option-height);
      &:hover {
        cursor: pointer;
        color: var(--primary);
        background-color: var(--select-option-highligh-bc);
      }
      &.is-active {
        color: var(--primary);
        .check-icon,
        .sort-icon {
          visibility: visible;
        }
      }
      .check-icon,
      .sort-icon {
        visibility: hidden;
        margin-left: var(--h-spacing-sm);
        margin-right: var(--h-spacing-sm);
      }
      &-content {
        display: inline-block;
        min-width: calc(40 * var(--hd));
        line-height: var(--font-size-base);
      }
    }
  }
  /* 搜索栏 isMultCheckbox模式下专用 */
  &.is-check-only {
    .@{browserClsPrefix}-search-advance {
      flex: 0 0 auto;
    }
    .@{browserClsPrefix}-search-bar-checkbox {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      transition: var(--transition-bc), var(--transition-color);
      border: var(--border-solid);
      height: calc(25 * var(--hd));
      font-size: var(--font-size-sm);
      color: var(--invalid-fc);
      line-height: calc(25 * var(--hd));
      border-radius: var(--border-radius-xs);
      // margin-left: var(--h-spacing-md);
      padding: var(--v-spacing-xs) var(--h-spacing-md);
      margin: var(--v-spacing-sm) 0;
      &:hover {
        color: var(--secondary-fc);
      }
      &.is-checked {
        background: var(--primary);
        color: var(--base-white);
      }
      .@{checkboxClsPrefix} {
        margin-right: var(--h-spacing-sm);
        &-inner {
          border-radius: var(--checkbox-border-radius);
        }
      }
      &-icon {
        margin-right: var(--h-spacing-sm);
        margin-top: var(--hd);
      }
    }
  }
}
.@{browserClsPrefix}-search-bar {
  &-left {
    padding-right: var(--h-spacing-md);
  }
  &-right {
    padding-right: var(--h-spacing-md);
  }
  &-sortable-icon {
    padding-left: 0;
  }
}
.@{mBrowserClsPrefix}-search-bar {
  &-left {
    padding-left: var(--h-spacing-md);
  }
  &-right {
    padding-right: var(--h-spacing-md);
  }
}

/* 高级搜索 */
.@{browserClsPrefix}-search-advance {
  position: relative;
  .@{searchAdvancedClsPrefix} {
    &-wrap-allLine {
      padding: var(--v-spacing-sm) 0;
    }
    &-top {
      padding: var(--spacing-sm);
    }
    &-input {
      width: 100%;
      max-width: 100%;
    }
    &-condition-container {
      width: 100%;
      z-index: var(--popup-zindex);
      position: absolute;
      box-sizing: border-box;
      box-shadow: var(--box-shadow-storke);
      // padding: 0 var(--h-spacing-md);
      background-color: var(--bg-white);
      border-radius: var(--border-radius-xs);
      background: var(--bg-transparent);
      .@{searchGroupClsPrefix}-LargeSpacing {
        width: 100%;
        margin-left: 0;
        .@{formItemClsPrefix}-wrapper {
          padding-right: calc(34 * var(--hd));
          .@{formItemClsPrefix}-delete {
            right: 0;
          }
        }
      }
    }
    &-condition {
      max-height: var(--browser-search-panel-max-height);
      border-top-left-radius: var(--border-radius-xs);
      border-top-right-radius: var(--border-radius-xs);
      overflow-y: auto;
    }
    &-footer {
      border-bottom-left-radius: var(--border-radius-xs);
      border-bottom-right-radius: var(--border-radius-xs);
    }
    &-layout {
      .@{formClsPrefix} .@{formItemClsPrefix} {
        padding-left: 0;
        padding-right: 0;
      }
      .@{searchGroupClsPrefix}-body {
        padding: 0;
      }
      .@{formItemClsPrefix}-label {
        display: flex;
        justify-content: flex-end;
        padding-left: var(--h-spacing-sm);
        &::after {
          position: static;
          padding-left: 0;
          padding-right: var(--h-spacing-xs);
        }
      }
    }
  }
  .@{triggerClsPrefix}-popupInner,
  .@{searchAdvancedClsPrefix}-quikSearchPanel {
    width: 100%;
    padding-top: var(--v-spacing-sm);
  }
}

/* 外置高级搜索 */
.@{browserClsPrefix}-search-outer-bar {
  border-left: none;
  border-right: none;
  .@{browserClsPrefix}-search-advance-outer {
    max-height: calc(133 * var(--hd));
    margin: 0;
    overflow: auto;
  }
  .@{browserClsPrefix}-search-outer-form-footer {
    text-align: center;
    padding: var(--h-spacing-md) 0;
    .@{btnClsPrefix} {
      margin: 0 var(--h-spacing-md);
    }
  }
  .@{browserClsPrefix}-search-advance-outer {
    .ui-searchGroup.ui-form-searchGroup {
      &:not(:first-of-type) {
        display: none;
      }
      .ui-searchGroup-top {
        display: none;
      }
    }
    .ui-form-col:not(.default-show-outer) {
      display: none;
    }
    &.ui-searchAdvanced-layout .ui-form-row {
      .ui-layout-col-12:nth-child(2n) .ui-formItem,
      .ui-layout-col-12:nth-child(odd) .ui-formItem {
        padding-left: var(--h-spacing-lg);
        padding-right: var(--h-spacing-lg);
      }
    }
  }
  .ui-searchGroup .ui-searchGroup-body {
    padding-bottom: 0;
  }
}

.@{browserClsPrefix}-search-bar,
.@{browserClsPrefix}-search-outer-bar {
  .@{searchAdvancedClsPrefix} {
    &-layout {
      .ui-layout-col.ui-layout-col-24.ui-formItem-label-col .ui-formItem-label {
        &:not(.ui-formItem-label-custom) {
          line-height: calc(16 * var(--hd));
          height: calc(16 * var(--hd));
          justify-content: flex-start;
        }
        margin-bottom: var(--v-spacing-xs);
      }
    }
  }
}

/* 高级搜索弹框面板 */
.@{browserClsPrefix}-search-advance-panel-trigger {
  position: absolute;
  top: calc(42 * var(--hd));
  left: 0;
  width: 100%;
  padding: 0 var(--h-spacing-lg);
  z-index: 2;
}
.@{browserClsPrefix}-search-advance-panel-wrap {
  &:not(.no-absolute) {
    position: absolute;
    top: calc(42 * var(--hd));
    left: 0;
    width: 100%;
    padding: 0 var(--h-spacing-lg);
    z-index: 2;
  }
  .@{searchAdvancedClsPrefix} {
    &-condition-popup {
      position: static;
    }
    &-condition {
      max-height: var(--browser-search-panel-max-height);
      border-top-left-radius: var(--border-radius-xs);
      border-top-right-radius: var(--border-radius-xs);
      overflow-y: auto;
    }
    &-footer {
      border-bottom-left-radius: var(--border-radius-xs);
      border-bottom-right-radius: var(--border-radius-xs);
    }
    &-layout {
      .ui-form-row {
        .ui-layout-col-12:nth-child(2n) .ui-formItem,
        .ui-layout-col-12:nth-child(odd) .ui-formItem {
          padding-left: var(--h-spacing-lg);
          padding-right: var(--h-spacing-lg);
        }
      }
      .@{formClsPrefix} .@{formItemClsPrefix} {
        padding-left: var(--h-spacing-lg);
        padding-right: var(--h-spacing-lg);
      }
      .@{searchGroupClsPrefix}-body {
        padding: 0;
      }
      .@{formItemClsPrefix}-label {
        display: flex;
        justify-content: flex-end;
        // padding-left: var(--h-spacing-sm);
        &::after {
          position: static;
          padding-left: 0;
          padding-right: var(--h-spacing-xs);
        }
      }
    }
  }
}

/* 已选列表面板 */
.@{browserClsPrefix}-selected-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  border-left: var(--border-solid);
  &-search {
    display: flex;
    padding: var(--v-spacing-sm) var(--h-spacing-md);
    &-icon {
      color: var(--secondary-fc);
    }
    &-btns {
      flex: 0 0 auto;
      color: var(--secondary-fc);
    }
    &-btn {
      padding: var(--v-spacing-md);
      padding-right: 0;
      cursor: pointer;
    }
  }
  &-list-wrap {
    height: 100%;
    overflow: hidden;
    user-select: none;
    transition: var(--transition-h);
    .@{listClsPrefix}-item {
      // padding-left: 0 !important;
      padding-right: 0;
    }
  }
  .@{listClsPrefix}-scrollview {
    transition: var(--transition-h);
  }
  &-list-empty {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--invalid-fc);
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    .@{emptyClsPrefix}-title,
    .@{emptyClsPrefix}-description {
      color: var(--invalid-fc);
      font-size: var(--font-size-sm);
    }
  }
  .@{emptyClsPrefix}{
    margin: 0;
    padding: 0 calc(var(--hd)*8);
  }
  &-list-item {
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    // padding-left: var(--h-spacing-lg);
    padding-right: var(--h-spacing-md);
    transition: var(--transition-bc);
    &:hover {
      .item-delete-icon,
      .item-split-icon {
        visibility: visible;
      }
    }
    &.disabled-delete {
      // cursor: not-allowed;
      .item-delete-icon {
        visibility: hidden !important;
      }
    }
    .item-delete-icon,
    .item-split-icon {
      color: var(--invalid-fc);
      // padding-left: var(--h-spacing-xs);
      padding-left: calc(10 * var(--hd));
      visibility: hidden;
      :hover {
        color: var(--regular-fc);
      }
    }
    &.is-checked {
      background-color: var(--bg-base);
    }
  }
  &-deleted {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    height: 0;
    line-height: calc(40 * var(--hd));
    font-size: var(--font-size-sm);
    color: var(--secondary-fc);
    padding-left: var(--h-spacing-lg);
    padding-right: var(--h-spacing-lg);
    transition: var(--transition-h);
    &.is-show {
      height: calc(40 * var(--hd));
    }
    .@{btnClsPrefix}-link {
      line-height: calc(40 * var(--hd));
    }
    &.show-btns {
      justify-content: flex-end;
    }
  }
  &-btns {
    display: flex;
    justify-content: flex-end;
    padding: var(--v-spacing-md);
    padding-left: 0;
    text-align: right;
    .@{btnClsPrefix} {
      margin-left: var(--h-spacing-md);
      padding-left: var(--h-spacing-md);
      padding-right: var(--h-spacing-md);
    }
  }
  &-more-btn {
    display: flex;
  }
  // 底部附件内容
  &-extra {
    padding: var(--v-spacing-md);
    padding-left: 0;
  }
}

/* 浏览列表 */
.@{mBrowserClsPrefix}-panel-list,
.@{browserClsPrefix}-panel-list,
.@{mBrowserClsPrefix}-selected-panel-list,
.@{browserClsPrefix}-selected-panel-list,
.@{mBrowserClsPrefix}-panel-index-list,
.@{browserClsPrefix}-panel-index-list {
  /* 处理列表的下边线 */
  .@{mListClsPrefix}-body,
  .@{listClsPrefix}-body {
    .@{mListClsPrefix}-item:not(:last-child) .@{mListClsPrefix}-content,
    .@{listClsPrefix}-item:not(:last-child) .@{listClsPrefix}-content {
      border-bottom: none;
    }
    .@{mListClsPrefix}-item .@{mListClsPrefix}-content,
    .@{listClsPrefix}-item .@{listClsPrefix}-content {
      .@{mBrowserClsPrefix}-selected-panel-list-item,
      .@{mBrowserClsPrefix}-panel-list-item,
      .@{browserClsPrefix}-selected-panel-list-item,
      .@{browserClsPrefix}-panel-list-item,
      .@{mBrowserClsPrefix}-panel-index-list-item,
      .@{browserClsPrefix}-panel-index-list-item {
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          border-bottom: var(--diviling-line-solid);
          width: 100%;
        }
        &.has-icon {
          &::after {
            width: calc(100% - var(--icon-size-xs) - var(--h-spacing-md));
          }
        }
        &.has-avatar {
          &::after {
            width: calc(100% - var(--avatar-size-md) - var(--h-spacing-md));
          }
        }
      }
      .@{mBrowserClsPrefix}-selected-panel-list-item,
      .@{mBrowserClsPrefix}-panel-list-item,
      .@{mBrowserClsPrefix}-panel-index-list-item {
        &::after {
          border-bottom: none;
          height: var(--hd);
          background-color: var(--border-color);
          transform: scaleY(0.3);
        }
      }
    }
  }

  // 加密列表
  &-template-encry-wrapper{
    position: relative;
    z-index: 2;
    display: flex;
  }
}

/* 浏览列表 */
.@{mBrowserClsPrefix}-panel-list,
.@{browserClsPrefix}-panel-list,
.@{mBrowserClsPrefix}-selected-panel-list,
.@{browserClsPrefix}-selected-panel-list {
  overflow-y: auto;
  box-sizing: border-box;
  height: 100%;
  transition: var(--transition-h);
  &-item {
    flex: 1 1 auto;
    display: flex;
    max-width: 100%;
    overflow: hidden;
    align-items: center;
    padding-top: var(--v-spacing-md);
    padding-bottom: var(--v-spacing-md);
    &-icon {
      width: calc(30 * var(--hd));
      display: flex;
      justify-content: center;
      margin-right: var(--h-spacing-md);
      flex: 0 0 auto;
      // padding-right: var(--h-spacing-md);
      margin-right: var(--h-spacing-md);
      color: var(--secondary-fc);
    }
    &-avatar {
      flex: 0 0 auto;
      margin-right: var(--h-spacing-md);
    }
    &-select-all-icon {
      // background: var(--bg-white);
      border-radius: 50%;
      color: #8e8e8e;
      line-height: 0;
      margin-right: var(--h-spacing-xs);
      &:hover {
        color: var(--primary);
      }
      &.checked {
        display: block;
        color: var(--base-white);
        background-color: #5d9cec;
        border-radius: 25%;
        .ui-icon-svg {
          padding: calc(2 * var(--hd));
        }
      }
    }
  }
  .@{listClsPrefix}-item {
    cursor: pointer;
    min-height: calc(44 * var(--hd));
    background-color: var(--bg-white);
    transition: background-color var(--transition-du) ease-out;
    padding-left: var(
      --h-spacing-lg
    ) !important; /* 为了覆盖ListItem组件内部的行内样式 */
    &.@{listClsPrefix}-item-checked {
      /* 已选数据无需高亮 */
      background-color: var(--bg-base);
    }
    &:hover {
      background-color: var(--bg-base);
    }
    &.@{listClsPrefix}-item-disabled {
      cursor: not-allowed;
    }
    .@{listClsPrefix}-item-row {
      font-size: var(--font-size-sm);
      color: var(--main-fc);
    }
    &-row {
      line-height: 1.3;
      // line-height: 1;
      &-span {
        font-size: var(--font-size-sm);
        color: var(--secondary-fc);
        float: inherit !important;
        &:not(:last-of-type) {
          margin-right: var(--h-spacing-lg);
        }
        &.item-clickable {
          color: var(--primary);
        }
      }
      .@{listClsPrefix}-item-row-span-text-highlight {
        font-weight: normal;
        color: var(--main-fc);
        &.item-clickable {
          color: var(--primary);
        }
      }
      &:only-of-type {
        .@{listClsPrefix}-item-row-span {
          color: var(--main-fc);
        }
      }
      &.is-group-data {
        display: flex;
        flex-wrap: nowrap;
        .@{listClsPrefix}-item-row-span-text-highlight {
          margin-right: 0;
        }
      }
    }
  }
  &.@{mListClsPrefix}-column,
  &.@{listClsPrefix}-column  {
    .@{mListClsPrefix}-item,
    .@{listClsPrefix}-item {
      &-row {
        &-void {
          display: none;
        }
        &-span {
          &-void {
            display: none;
          }
        }
      }
    }
  }
  .@{mListClsPrefix}-item,
  .@{listClsPrefix}-item {
    &-col {
      flex: 1 1 auto;
      max-width: 100%;
      overflow: hidden;
      &.col1_row2,
      &.col1_row3,
      &.col2_row2 {
        .@{mListClsPrefix}-item-row,
        .@{listClsPrefix}-item-row {
          display: flex;
          align-items: center;
          flex-wrap: wrap; /* 当两列布局中某一项内容过长时可以换行显示 */
          // justify-content: space-between;
          &-void {
            display: none;
          }
          &.is-group-data {
            display: flex;
            flex-wrap: nowrap;
          }
        }
      }
    }
    &-row {
      color: var(--main-fc);
      overflow: hidden;
      text-overflow: ellipsis;
      padding: calc(2 * var(--hd)) 0;
      &:last-of-type {
        padding-bottom: 0;
      }
      &-span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &-template-string,
    &-template-string-encry {
      overflow: hidden;
    }
  }
}
.@{browserClsPrefix}-panel-list,
.@{browserClsPrefix}-selected-panel-list {
  &.ui-list-rtl {
    .@{listClsPrefix}-item {
      /* 为了覆盖ListItem组件内部的行内样式 */
      padding-left: 0 !important;
      padding-right: var(
        --h-spacing-lg
      ) !important;
    }
  }
}


.@{mBrowserClsPrefix}-panel-list,
.@{browserClsPrefix}-panel-list {
  .@{listClsPrefix}-item {
    &-checkbox-right {
      flex: 0 0 auto;
      position: static;
      transform: none;
      text-align: left;
      margin-right: var(--h-spacing-md);
      width: calc(25 * var(--hd));
    }
     // 待选区数据自动折行
    &-row {
      &-span.@{listClsPrefix}-item-row-span-text-highlight {
        white-space: normal;
      }
    }
    .@{browserClsPrefix}-panel-list-item {
      padding-right: var(--h-spacing-lg);
    }
  }
}


.@{browserClsPrefix}-panel-list {
  .@{listClsPrefix}-item {
    &.@{listClsPrefix}-item-checked {
      /* 已选数据无需高亮 */
      background-color: var(--bg-white);
    }
    &:hover {
      background-color: var(--bg-base);
    }
    &.isActive {
      /* 键盘操作选项高亮 */
      background-color: var(--bg-base);
    }
  }
  &.hide-check {
    .@{listClsPrefix}-item {
      &.@{listClsPrefix}-item-checked {
        /* 无check框时，已选需要显示高亮 */
        background-color: var(--bg-base);
      }
    }
  }
}
/* 浏览列表/树形 - hover 显示 添加全部 图标 */
.@{browserClsPrefix}-panel {
  // 列表
  &-list {
    &-item-select-all-icon:not(.checked) {
      display: none;
    }
    .@{listClsPrefix}-item {
      &:hover {
        .@{browserClsPrefix}-panel-list-item-select-all-icon {
          display: block;
        }
      }
    }
  }
  // 树形
  &-tree {
    &-node-select-all-icon {
      display: none;
    }
    .@{treeClsPrefix}-bar {
      &:hover {
        .@{browserClsPrefix}-panel-tree-node-select-all-icon {
          display: block;
        }
      }
    }
  }
}

/* 滚动阴影效果 */
.@{mBrowserClsPrefix}-with-scrolling,
.@{browserClsPrefix}-with-scrolling {
  &.@{browserClsPrefix}-panel-table .@{tableClsPrefix}-grid-body-outer,
  &.@{scrollerClsPrefix},
  .@{scrollerClsPrefix} {
    // &::before,
    &::after {
      content: '';
      transition: all 0.3s;
      opacity: 0;
      position: absolute;
      pointer-events: none;
      height: calc(6 * var(--hd));
      width: 100%;
      left: 0;
      background: linear-gradient(to top, var(--box-shadow-color) 0%, rgba(0, 0, 0, 0.01) 100%);
    }
    // &::before {
    //   top: 0;
    //   z-index: 1;
    //   background: linear-gradient(to bottom, var(--box-shadow-color) 0%, rgba(0, 0, 0, 0.01) 100%);
    // }
    &::after {
      bottom: 0;
    }
  }
  &.is-scrolling {
    &.@{browserClsPrefix}-panel-table .@{tableClsPrefix}-grid-body-outer,
    &.@{scrollerClsPrefix},
    .@{scrollerClsPrefix} {
      &::before,
      &::after {
        opacity: 1;
      }
    }
  }
}

/* 浏览卡片 */
.@{browserClsPrefix}-panel-card-list {
  width: 100%;
  .@{listClsPrefix}-content {
    overflow: visible;
    height: 100%;
  }
  &.@{listClsPrefix}-row {
    .@{listClsPrefix}-body {
      width: 100%;
      display: inline-flex;
      flex-wrap: wrap;
      padding: 0 var(--h-spacing-md);
    }
    .@{listClsPrefix}-item {
      width: 33.3%;
      position: relative;
      flex: 0 0 33.3%;
      // 在chrome、Firefox等浏览器下，会默认成flex:1 1 auto
      // 在IE 10-11中会默认成flex:1 1 0，需要加上 flex-basis: auto
      flex-basis: auto;
      padding: var(--v-spacing-md) var(--h-spacing-md) !important;
    }
    .@{listClsPrefix}-check {
      z-index: 1;
      position: absolute;
      top: calc(var(--h-spacing-md) + var(--v-spacing-md));
      left: calc(var(--h-spacing-md) + var(--h-spacing-md));
    }
  }
  &-item {
    height: 100%;
    width: 100%;
    min-height: calc(100 * var(--hd));
    border: var(--border-solid);
    border-radius: var(--border-radius-xs);
    cursor: pointer;
    &:hover {
      border-color: var(--primary);
      box-shadow: 0 0 3px 0 var(--primary);
      .@{browserClsPrefix}-panel-card-list-item-img {
        &-alt {
          opacity: 0.5;
        }
        &-enlarge {
          display: block;
        }
      }
    }
    &-img {
      min-height: calc(80 * var(--hd));
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 140px;
      }
      &-alt {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        opacity: 0;
        white-space: normal;
        color: var(--base-white);
        background-color: var(--base-black);
        transition: var(--transition-all);
        padding: var(--v-spacing-md) var(--h-spacing-md);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &-enlarge {
        display: none;
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
        -ms-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -webkit-transform: translate(-50%);
        -o-transform: translate(-50%);
        background-color: var(--base-black);
        color: var(--base-white);
        border-radius: 50%;
        line-height: calc(18 * var(--hd));
        width: calc(20 * var(--hd));
        height: calc(20 * var(--hd));
        text-align: center;
        opacity: 0.2;
        transition: var(--transition-all);
        &:hover {
          // background-color: var(--primary);
          opacity: 0.5;
          box-shadow: 0 0 3px var(--primary);
        }
      }
    }
    &-content {
      padding: var(--v-spacing-md) var(--h-spacing-md);
    }
    &-tag .@{tagClsPrefix}{
      max-width: calc(110 * var(--hd));
    }
    &-main {
      color: var(--main-fc);
      margin-bottom: var(--v-spacing-sm);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-seco {
      color: var(--secondary-fc);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-tag {
      position: absolute;
      top: var(--h-spacing-md);
      right: var(--h-spacing-md);
    }
  }
}

/* 浏览标签 */
.@{browserClsPrefix}-panel-tag-list {
  width: 100%;
  padding: var(--v-spacing-md) var(--h-spacing-lg);
  &.@{listClsPrefix}-row {
    .@{listClsPrefix}-body {
      width: 100%;
      display: inline-flex;
      flex-wrap: wrap;
    }
    .@{listClsPrefix}-item {
      flex: 0 0 auto;
    }
  }
  &-item {
    cursor: pointer;
    background: var(--label-bc);
    border: var(--border-solid);
    border-color: var(--transparent);
    border-radius: var(--border-radius-xs);
    margin: var(--v-spacing-sm) var(--h-spacing-sm);
    padding: var(--v-spacing-sm) var(--h-spacing-md);
    transition: var(--transition-all);
    &:hover {
      box-shadow: 0 0 3px 1px var(--box-shadow-color);
      .@{browserClsPrefix}-panel-tag-list-item-checked {
        color: var(--secondary-fc);
      }
    }
    &-checked {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      color: var(--invalid-fc);
      background-color: var(--bg-white);
      border-radius: 50%;
    }
  }
  .@{listClsPrefix}-item-disabled {
    .@{browserClsPrefix}-panel-tag-list-item {
      cursor: not-allowed;
    }
  }
}

/* 浏览索引列表 */
.@{mBrowserClsPrefix}-panel-index-list-container,
.@{browserClsPrefix}-panel-index-list-container {
  height: 100%;
  display: flex;
  overflow: auto;
  .@{mListClsPrefix}-quick-search-bar,
  .@{listClsPrefix}-quick-search-bar {
    color: var(--regular-fc);
    right: calc(5 * var(--hd));
    &-item {
      &:hover {
        color: var(--main-fc);
      }
      &.active {
        color: var(--primary);
      }
    }
  }
  .@{mListClsPrefix}-quick-search-bar {
    z-index: 2;
    right: calc(4 * var(--hd));
  }
  .@{listClsPrefix}-quick-search-bar {
    margin-top: var(--h-spacing-md);
    margin-bottom: var(--h-spacing-md);
    // transform: translateX(calc(-14 * var(--hd)));
    &-item {
      transform: scale(0.9);
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.@{mBrowserClsPrefix}-panel-index-list,
.@{browserClsPrefix}-panel-index-list {
  height: 100% !important;
  flex: 1 1 auto;
  .@{listClsPrefix}-item-checkbox-right {
    width: calc(40 * var(--hd));
  }
  .@{listClsPrefix}-group {
    .@{listClsPrefix}-header {
      min-height: calc(30 * var(--hd));
      .@{listClsPrefix}-content {
        font-size: var(--font-size-md);
        color: var(--main-fc);
      }
    }
  }
  .@{mListClsPrefix}-group {
    .@{mListClsPrefix}-header {
      .@{mListClsPrefix}-content {
        min-height: calc(25 * var(--hd));
        color: var(--secondary-fc);
      }
    }
  }
}

.@{browserClsPrefix}-panel-index-list {
  .@{listClsPrefix}-item:not(.@{listClsPrefix}-item-check) {
    .@{browserClsPrefix}-panel-list-item {
      padding-right: calc(30 * var(--hd));
    }
  }
}

/* 条件列表 */
.@{browserClsPrefix}-panel-condition-list {
  &.@{listClsPrefix}-column {
    .@{listClsPrefix}-item {
      min-height: calc(35 * var(--hd));
      .@{listClsPrefix}-none,
      .@{listClsPrefix}-grid-expandable-btn {
        display: none;
      }
    }
  }
  .@{browserClsPrefix}-panel-list-item {
    height: 100%;
    &::after {
      display: none;
    }
    &-switcher {
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
      user-select: none;
      color: var(--secondary-fc);
      padding-right: var(--h-spacing-md);
      .@{iconClsPrefix} {
        transition: var(--transition-all);
      }
      &.is-expand {
        .@{iconClsPrefix} {
          transform: rotate(90deg);
        }
      }
    }
    &-conditions {
      .@{browserClsPrefix}-panel-list-condition {
        &-title {
          cursor: pointer;
          display: flex;
          align-items: center;
          min-height: calc(35 * var(--hd));
          text-align: left;
          font-size: var(--font-size-sm);
          padding-left: calc(var(--h-spacing-lg) + 22 * var(--hd));
          &:hover {
            background-color: var(--bg-base);
          }
        }
        &-content {
          &-list {
            .@{listClsPrefix}-item {
              .@{listClsPrefix}-content {
                color: var(--main-fc);
                padding-left: calc(55 * var(--hd));
                border-bottom: none;
                justify-content: space-between;
              }
            }
            &-empty {
              color: var(--invalid-fc);
              font-size: var(--font-size-sm);
              padding-top: var(--h-spacing-sm);
              padding-bottom: var(--h-spacing-sm);
            }
          }
        }
      }
    }
  }
}

/* 浏览树形 */
.@{browserClsPrefix}-panel-tree {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-y: auto;
  box-sizing: border-box;
  // padding-top: var(--v-spacing-md);
  font-size: var(--font-size-sm);
  &:not(.is-virtualMode) {
    padding-bottom: var(--h-spacing-md);
  }
  &.is-single {
    .@{treeClsPrefix}-node .content {
      width: 98%;
    }
  }
  .@{treeClsPrefix}-bar {
    min-height: calc(35 * var(--hd));
    padding-right: var(--h-spacing-lg);
    &.isSelected,
    // &.expandHidden,
    &:hover {
      background-color: var(--bg-base);
    }
    &.expandHidden {
      .@{browserClsPrefix}-panel-tree-node-content {
        color: var(--secondary-fc);
      }
    }
  }
  .@{treeClsPrefix}-node {
    overflow: hidden;
    > .@{iconClsPrefix} {
      display: none;
    }
    &.@{treeClsPrefix}-node-right {
      margin-left: 0;
      flex: 1 1 auto;
      overflow: hidden;
      align-items: center;
      .tree-node-loading-wrapper {
        height: calc(10 * var(--hd));
        line-height: calc(10 * var(--hd));
      }
      .@{treeClsPrefix}-node-checkbox {
        display: flex;
        align-items: center;
      }
    }
    &-content {
      padding: 0;
      border: none;
      flex: 1 1 auto;
      // width: calc(100% - calc(16 * var(--hd)));
    }
    .content {
      width: 93%;
      box-sizing: border-box;
    }
  }
  &-node {
    width: 100%;
    display: flex;
    padding-left: var(--h-spacing-xs);
    padding-right: var(--h-spacing-xs);
    align-items: center;
    &.is-custom-check {
      padding-right: 0;
    }
    &-icon {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      margin-right: var(--h-spacing-sm);
      color: var(--secondary-fc);
      &.stop-department {
        color: var(--danger);
      }
    }
    &-ebdcoms-icon {
      margin-right: var(--h-spacing-sm);
    }
    &-avatar {
      flex: 0 0 auto;
      margin-right: var(--h-spacing-sm);
    }
    &-select-all-icon {
      // background: var(--bg-white);
      border-radius: 50%;
      color: #8e8e8e;
      line-height: 0;
      height: var(--icon-size-xs);
      margin-right: var(--h-spacing-xs);
      &:hover {
        color: var(--primary);
      }
      &.checked {
        display: block;
        color: var(--base-white);
        background-color: #5d9cec;
        border-radius: 25%;
        .ui-icon-svg {
          padding: calc(2 * var(--hd));
        }
      }
    }
    &-content {
      flex: 1 1 auto;
      text-align: left;
      color: var(--main-fc);
      overflow: hidden;
      text-overflow: ellipsis;
      &.ui-rtl-inline {
        text-align: right;
      }
      &-main {
        margin-right: 8px;
      }
      &-sub {
        color: var(--secondary-fc);
      }
      &-template-item {
        // cursor: pointer;
        // min-height: calc(44 * var(--hd));
        // transition: background-color var(--transition-du) ease-out;
        // padding-left: var(--h-spacing-lg) !important; /* 为了覆盖ListItem组件内部的行内样式 */
        // &.@{listClsPrefix}-item-checked {
        //   background-color: var(--bg-base);
        // }
        // &.@{listClsPrefix}-item-disabled {
        //   cursor: not-allowed;
        // }
        &-row {
          font-size: var(--font-size-sm);
          color: var(--main-fc);
          line-height: 1.15;
          // line-height: 1;
          color: var(--main-fc);
          overflow: hidden;
          text-overflow: ellipsis;
          padding-top: calc(2 * var(--hd));
          // padding: calc(2 * var(--hd)) 0;
          // &:last-of-type {
          //   padding-bottom: 0;
          // }
          &:only-of-type {
            .@{browserClsPrefix}-panel-tree-item-content-template-item-row-span {
              color: var(--main-fc);
            }
          }
          &-span {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: var(--font-size-sm);
            color: var(--secondary-fc);
            float: inherit !important;
            &:not(:last-of-type) {
              margin-right: var(--h-spacing-lg);
            }
            &.item-clickable {
              color: var(--primary);
            }
            &-text-highlight {
              font-weight: normal;
              color: var(--main-fc);
              &.item-clickable {
                color: var(--primary);
              }
            }
            &-void {
              display: none;
            }
          }
          &-void {
            display: none;
          }
        }
        &-col {
          flex: 1 1 auto;
          max-width: 100%;
          overflow: hidden;
          &.col1_row2,
          &.col1_row3,
          &.col2_row2 {
            .@{browserClsPrefix}-panel-tree-item-content-template-item-row {
              display: flex;
              align-items: center;
              flex-wrap: wrap; /* 当两列布局中某一项内容过长时可以换行显示 */
              // justify-content: space-between;
              &-void {
                display: none;
              }
            }
          }
        }
        &-template-string,
        &-template-string-encry {
          overflow: hidden;
        }
      }
    }
    &-count {
      flex: 0 0 auto;
      padding-right: var(--h-spacing-md);
    }
    &-checkbox {
      &.@{checkboxClsPrefix}-wrapper {
        margin-right: 0;
        display: none;
        &.show-all-branch {
          display: inline-flex;
        }
      }
    }
    &-check {
      flex: 0 0 auto;
      color: var(--transparent);
      &:hover {
        text-decoration: underline;
      }
    }
    &:hover {
      .@{browserClsPrefix}-panel-tree-node-check {
        color: var(--primary);
      }
    }
  }
}
/** 浏览树形 IE11 样式调整 */
.@{browserClsPrefix}-panel-tree.is-IE11 {
  .@{treeClsPrefix}-node {
    &.@{treeClsPrefix}-node-right {
      .@{treeClsPrefix}-node-checkbox {
        margin-right: var(--hd);
      }
    }
  }
}

/** 浏览按钮树形 - 左侧面板 */
.@{browserClsPrefix}-panel-left-content-inner {
  .@{browserClsPrefix}-panel-tree {
    .@{treeClsPrefix}-bar {
      padding-right: 0;
    }
    &-node-conten {
      padding-right: var(--h-spacing-lg);
    }
  }
}

/* 浏览表格 */
.@{browserClsPrefix}-panel-table {
  // .@{tableClsPrefix}-grid-th:first-child,
  // .@{tableClsPrefix}-grid-td:first-child {
  //   padding-left: var(--h-spacing-lg);
  // }
  &.@{tableClsPrefix}-grid {
    border: none;
    .@{tableClsPrefix}-grid-td {
      .item-clickable {
        cursor: pointer;
        color: var(--primary);
      }
    }
  }
  &.@{tableClsPrefix}-grid,
  &.@{tableClsPrefix}-grid > .@{spinClsPrefix}-nested-loading {
    overflow: hidden;
    height: 100%;
  }
  .@{tableClsPrefix}-grid-body-outer {
    height: 100%;
    overflow-y: auto;
    .@{tableClsPrefix}-grid-body {
      max-height: 100% !important;
      margin-right: 0 !important;
    }
  }
  .@{checkboxClsPrefix}-inner {
    border-radius: var(--checkbox-border-radius);
  }
  // 禁止选择
  &.@{tableClsPrefix}-grid {
    .@{tableClsPrefix}-grid-tr__disable-check {
      background: #f5f5f5;
    }
  }
}

/* 浏览多类型展示 */
.@{mBrowserClsPrefix}-panel-mult-type,
.@{browserClsPrefix}-panel-mult-type {
  height: 100%;
  overflow: auto;
  &-item {
    &.content-empty {
      display: none;
    }
    margin-bottom: var(--v-spacing-md);
    &:only-of-type {
      &.content-empty {
        display: block;
      }
    }
    &-title {
      text-align: left;
      color: var(--secondary-fc);
      font-size: var(--font-size-sm);
      height: calc(28 * var(--hd));
      line-height: calc(28 * var(--hd));
      padding-left: var(--h-spacing-lg);
    }
    &-content {
      &-more {
        color: var(--secondary-fc);
        font-size: var(--font-size-sm);
        height: calc(28 * var(--hd));
        line-height: calc(28 * var(--hd));
        padding-left: var(--h-spacing-lg);
        cursor: pointer;
        :hover {
          background: var(--bg-base);
          color: var(--regular-fc);
        }
      }
    }
  }
}

.@{browserClsPrefix}-associative-panel {
  &-header-go-back-btn {
    display: inline-flex;
    padding-left: var(--h-spacing-md);
    font-size: var(--h-spacing-md);
    color: var(--regular-fc);
    cursor: pointer;
    &:hover {
      color: var(--primary);
    }
    &-icon {
      margin-right: var(--h-spacing-xs);
    }
    &-content {
      line-height: var(--icon-size-xs);
    }
  }
}

/* 多类型浏览按钮 */
.@{browserClsPrefix}-types,
.@{browserClsPrefix}-share {
  .@{browserClsPrefix}-associative {
    &-custom {
      cursor: pointer;
    }
    &-search.display-input {
      width: calc(100 * var(--hd));
    }
  }
    /** 数据选择区域 */
  &-associative-inner {
    border:var(--hd) solid transparent;
    border-radius: var(--border-radius-xs);
    display: inline-flex;
    // flex-wrap: wrap;
    align-items: center;
    vertical-align: middle;
    // 折行样式
    &.fold-line {
      // display: inline-block;
      display: inline-flex;
      flex-wrap: wrap;
      .@{browserClsPrefix} {
        display: block;
      }
    }
    // 没有数据的时候显示的按钮（加号）
    &-add {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(30 * var(--hd));
      height: calc(30 * var(--hd));
      transform: scale(0.8);
      border-radius: 50%;
      border: var(--border-solid);
      color: var(--secondary-fc);
    }
  }
  // 类型选择
  &-select-wrap {
    display: inline-block;
    margin-right: var(--h-spacing-md);
  }
}

/* 共享浏览按钮特定样式 */
.@{browserClsPrefix}-share {
  /* 查看详情按钮 */
  &-btn {
    white-space: nowrap;
    font-size: var(--font-size-sm);
    color: var(--primary);
    cursor: pointer;
  }
}

/* 非弹框型浏览按钮 */
.@{browserClsPrefix}-window {
  display: block;
  border: var(--border-solid);
  border-radius: var(--border-radius-xs);
  background-color: var(--bg-white);
  &-selected {
    display: flex;
    // flex-direction: column;
    align-items: center;
    min-height: calc(44 * var(--hd));
    border-bottom: var(--border-solid);
    font-size: var(--font-size-sm);
    &-label {
      flex: 0 0 17%;
      padding-left: var(--h-spacing-lg);
      &.need-rtl {
        padding-left: 0;
        padding-right: var(--h-spacing-lg);
      }
    }
    &-placeholder {
      color: var(--secondary-fc);
    }
    .@{browserClsPrefix}-associative {
      flex: 1 1 83%;
    }
  }
  &-selected::after {
    content: "";
    min-height: calc(44 * var(--hd));
    display: inline-block;
  }
}

/* 外置面板 */
.@{browserClsPrefix}-output-panel {
  display: block;
  border: var(--border-solid);
  border-radius: var(--border-radius-xs);
  background-color: var(--bg-white);
  &-spin {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.@{browserClsPrefix}-associative-operation-btn {
  display: flex;
  align-items: center;
}

/** 浏览按钮 input 宽度：100% */
.@{browserClsPrefix}.spread-input-width {
  width: 100%;
  .@{browserClsPrefix}-associative-search {
    &.display-input {
      max-width: 100%;
    }
  }
}
.@{browserClsPrefix}.spread-input-width.is-single {
  .@{browserClsPrefix}-associative-search {
    &.display-input {
      width: 100%;
    }
  }
}

/** 已选数据在框内、宽度 100% */
.@{browserClsPrefix}.box-selection.is-multiple.spread-input-width {
  .@{browserClsPrefix}-associative-search {
    &.display-input {
      width: 100%;
    }
  }
}

/** 群组名称显示折行 */
.@{browserClsPrefix}-panel-list {
  &-item.title-wrap {
    .@{listClsPrefix}-item-row {
      .@{listClsPrefix}-item-row-span-text-highlight {
        white-space: pre-wrap;
      }
    }
  }
}
