.@{scrollerClsPrefix} {
  overflow: hidden;
  position: relative;
}
.@{scrollerClsPrefix}:active>.@{scrollerClsPrefix}__bar,.@{scrollerClsPrefix}:focus>.@{scrollerClsPrefix}__bar,.@{scrollerClsPrefix}:hover>.@{scrollerClsPrefix}__bar {
  opacity: 1;
  transition: opacity .34s ease-out;
}

.@{scrollerClsPrefix}__wrap {
  overflow: scroll;
  height: 100%;
  .@{scrollerClsPrefix}__view {
    //padding-bottom: 8px;
    //padding-right: 8px;
  }
}
.@{scrollerClsPrefix}__wrap--hidden-default {
  //scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.@{scrollerClsPrefix}__thumb {
  position: relative;
  display: block;
  width: 0;
  height: 0;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(144,147,153,.3);
  transition: background-color .3s;
  &:hover {
    background-color: rgba(144,147,153,.5);
  }
}
.@{scrollerClsPrefix}__bar {
  position: absolute;
  right: 0.5px;
  bottom: 0.5px;
  z-index: 1;
  border-radius: 4px;
  opacity: 0;
  transition: opacity .12s ease-out;
  &.is-vertical {
    width: 8px;
    top: 2px;
    transition: width 0.3s;

    & > div {
      width: 100%;
    }

    &:hover {
      width: 12px;
    }
  }
  &.is-horizontal {
    height: 8px;
    left: 2px;
    transition: height 0.3s;

    & > div {
      height: 100%;
    }

    &:hover {
      height: 12px;
    }
  }
}
