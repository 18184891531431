.@{commentClsPrefix}-rich {
  border: var(--border-solid);
  &-at {
    &-item {
      display: flex;
      align-items: center;
      padding: 0 calc(16 * var(--hd));
      &.target-member {
        position: relative;
        color: var(--primary);
        &::before {
          content: "";
          height: var(--hd);
          position: absolute;
          background-color: #e5e5e5;
          left: calc(16 * var(--hd));
          right: calc(16 * var(--hd));
          top: 0;
        }
      }
    }
    &-name {
      flex: none;
      .comment-ellipsis();
    }
    &-popup {
      &.cke_autocomplete_panel {
        max-height: inherit;
        padding-top: 0;
      }
      & > li.@{commentClsPrefix}-rich-at-li {
        color: var(--black-fc);
        padding: 0;
        &:hover {
          color: var(--black-fc);
        }
        &[data-id="99"] {
          margin-top: calc(3 * var(--hd));
        }
      }
    }
    &-avatar {
      margin-right: var(--v-spacing-md);
    }
    &-tooltip {
      & > span {
        &:not(:last-child) {
          &::after {
            content: "";
            border-left: var(--hd) solid var(--base-white);
            padding-left: calc(5 * var(--hd));
            margin-left: calc(5 * var(--hd));
          }
        }
      }
    }
  }
  &-highlight {    
    .cke_top {
      border: 0;
    }
  }
  .cke {
    border: 0;
  }
  & &-quote {
    border: 0;
    border-bottom: var(--border-solid);
    background-color: #F0FBFF;
    border-radius: 0;
    .@{commentClsPrefix}-item:hover {      
      background-color: #F0FBFF;
    }
  }
}