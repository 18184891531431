
.@{textareaClsPrefix} {
  resize: none;
  height: auto;
  min-height: var(--input-height);
  /* 文本框补充文字颜色 */
  color: var(--input-text-color);
  
  &.resizable {
    resize: both;
  }
  &.is-disabled,
  &.is-readonly {
    /* 让html浏览器显示空白空格符 */
    white-space: pre-wrap;
    /* 纯英文文本自动换行 */
    word-break: break-word;
  }

  &.is-readonly-link {
    display: inline-block;
  }
  
  &.auto-height {
    overflow: hidden;
  }

  &.is-high-light + div.required-fc {
    padding: var(--input-padding-y) var(--input-padding-x);
  }

  &.is-high-light {
    &.is-readonly {
      display: inline-block;
    }
    &.is-high-light-required {
      border: calc(1 * var(--hd)) solid var(--form-item-required-new);
    }
    &.is-high-light-required:focus {
      box-shadow: 0 0 var(--input-focus-shadow-blur) var(--form-item-required-new);
    }
  }
}

.@{textareaClsPrefix}-wrap {
  display: inline-block;
  position: relative;
  &.has-clear {
    .@{textareaClsPrefix} {
      padding-right: var(--h-spacing-lg);
    }
  }
  &:hover {
    .@{textareaClsPrefix}-clear {
      display: block;
      line-height: 0;
    }
  }
  .@{textareaClsPrefix}-clear {
    position: absolute;
    color: var(--invalid-fc);
    display: flex;
    right: var(--h-spacing-sm);
    top: var(--h-spacing-sm);
    &:hover {
      cursor: pointer;
      color: var(--secondary-fc);
    }
    //&.is-firefox {
    //  right: calc(20 * var(--hd));
    //}
  }
  &.is-high-light .@{textareaClsPrefix}.is-readonly {
    display: inline-block;
  }
  &.is-high-light + div.required-fc {
    padding: var(--input-padding-y) var(--input-padding-x);
  }
  &.is-high-light-required textarea {
    border: calc(1 * var(--hd)) solid var(--form-item-required-new);
    &:focus {
      box-shadow: 0 0 var(--input-focus-shadow-blur) var(--form-item-required-new);
    }
  }
}

.@{textareaClsPrefix}-limit {
  text-align: right;
  color: var(--invalid-fc);
  font-size: var(--font-size-sm);
}

@media screen and ( max-width: 1280px  ) {
  .@{textareaClsPrefix} {
    max-width: calc(350 * var(--hd));
  }
}

@media screen and ( min-width: 1280px  ) {
  .@{textareaClsPrefix} {
    max-width: calc(500 * var(--hd));
  }
}
