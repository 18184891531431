.@{dateTimePickerClsPrefix}-dateTimePanel_flat {
  font-size: var(--font-size-12);
  display: inline-flex;
  flex-direction: column;
  outline: none;
  z-index: 999;
  overflow: visible;
  background: var(--bg-white);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius-xs);
  box-shadow: var(--box-shadow);
  margin-top: calc(5 * var(--hd));

  &-panel {
    display: flex;
    flex-direction: row;
  }

  &-datePanel {
    width: calc(280 * var(--hd));

    .@{dateTimePickerClsPrefix}-dayPanel-content {
      padding: 0 calc(10 * var(--hd));
      height: calc(210 * var(--hd));
    }
  }

  &-timePanel {
    border-left: calc(1 * var(--hd)) solid var(--diviling-line-color);

    .@{dateTimePickerClsPrefix}-dateTimePanel_flat-timeText {
      height: calc(40 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: calc(1 * var(--hd)) solid var(--diviling-line-color);
      font-size: var(--font-size-14);
      color: var(--black-fc);
      font-weight: var(--font-weight-bold);
    }

    .@{dateTimePickerClsPrefix}-dateTimePanel_flat-timePicker {
      margin-top: unset;
      width: unset;    
      box-shadow: unset;
      border: unset;
      &.isIE{
        margin-top: 0;
        width: auto;    // ie11 不支持unset
        box-shadow: 0 0 0 0;
        border: 0;
      }
    }



    .@{pickerViewClsPrefix}-scroller {
      width: 45px;
    }
  }

  &-timePanel-HH {
    // .@{dateTimePickerClsPrefix}-dateTimePanel_flat-timePicker-HH {
    //   width: calc(90 * var(--hd));
    // }

    .@{pickerViewClsPrefix}-scroller {
      width: 90px;
    }
  }
}