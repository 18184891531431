/* 全局处理box-sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* 处理组件focus会有外边框问题 */
*:focus {
  outline: none;
}

/* 设置全局背景色 */
body,
#root {
  background-color: var(--bg-base) !important;
}

/* 字体 */
html,
body,
input,
textarea {
  font-family: var(--regular-ff);
}

input,
textarea {
  /* 处理文本框中光标的颜色 */
  caret-color: var(--primary);
  /* 在IOS下，input 和textarea表单默认会有个内阴影，一定程度上影响视觉一致 */
  -webkit-appearance: none;
  appearance: none;
}

/* 去除：在IE10 及以上input框会加上默认的X号，密码框会加上小眼睛 */
input::-ms-clear,
::-ms-reveal {
  display: none;
}

/* 在搜狗浏览器下会给密码框加上小键盘图标，去掉的方法（只兼容急速模式） */
input::-webkit-input-safebox-button {
  display: none;
}

/* 处理文本框自动填充后的背景色 */
input:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--input-bg) inset !important;
}

.border-solid-then {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: var(--hd);
    width: 100%;
    background-color: var(--border-color);
    transform: scaleY(0.3);
    pointer-events: none;
  }
}

.border-top-solid-then {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: calc(-0.3 * var(--hd));
    left: 0;
    height: var(--hd);
    width: 100%;
    background-color: var(--border-color);
    transform: scaleY(0.3);
  }
}

/* 处理图标点击导致ie11页面卡死问题 */
// svg,
svg use {
  pointer-events: none;
}

/* 系统链接颜色 */
a:hover,
a:active {
  color: var(--primary);
}

// webkit内核滚动条全局样式处理
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--bg-transparent);
  transition: background-color 0.3s;
  border-radius: 4px;
  cursor: pointer;
}

*::-webkit-scrollbar-corner {
  background-color: var(--bg-transparent);
}

*:hover::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.3);
  transition: background-color 0.3s;
}

// 移动端去除
.in_mobile {

  *::-webkit-scrollbar,
  *::-webkit-scrollbar {
    display: none;
  }
}

.ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//表单只读统一api -> 换行处理
.selectedWrap-readyOnly {
  border: none;
  background: inherit;
  display: inline-block;
  box-shadow: none;
  word-break: break-word;
}

//表单只读统一api -> 超出省略
.selectedWrap-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* 高亮统一样式：背景色 */
.highlight-bg {
  background-color: var(--highlight-bg);
}

/* 高亮统一样式：选中文字颜色 */
.highlight-fc {
  color: var(--highlight-color);
}

/* 镜像class */
.ui-rtl {
  transform: scaleX(-1);
  direction: rtl;

  /* span 、a 标签单独处理 */
  &-inline {
    display: inline-block;
  }
}

[data-rtl='true'] {
  transform: scaleX(-1);
  direction: rtl;

  &>[data-rtl='true'] {
    transform: scaleX(1) !important;
    direction: ltr !important;
  }
}

/* 镜像翻转 - 重置 */
.mirror-text-re {
  transform: scaleX(1) !important;
  direction: ltr !important;
}

/* 镜像翻转 */
.mirror-text {
  transform: scaleX(-1);
  direction: rtl;
}

.required-border {

  .@{inputClsPrefix},
  .@{textareaClsPrefix},
  .@{inputClsPrefix}-wrap {
    border: calc(1 * var(--hd)) solid var(--form-item-required-new);
  }

  .@{inputClsPrefix}-wrap .@{inputClsPrefix} {
    border: none;
  }

  .@{browserClsPrefix}:not(.show-input) .@{inputClsPrefix}-wrap {
    border: var(--border-solid);
  }

  .@{richTextClsPrefix} .cke,
  .@{signaturePrefix} {
    border-color: var(--form-item-required-new);
  }

  .@{uploadClsPrefix}-select-drag {
    border-style: solid;
    border-color: var(--form-item-required-new);
  }

  .@{uploadClsPrefix}-select-image {
    outline-style: solid;
    outline-color: var(--form-item-required-new);
  }

  .@{inputClsPrefix}.is-focus,
  .@{textareaClsPrefix}.is-focus,
  .@{inputClsPrefix}-wrap.is-focus,
  .@{inputClsPrefix}:focus,
  .@{textareaClsPrefix}:focus,
  .@{inputClsPrefix}-wrap:focus {
    box-shadow: 0 0 var(--input-focus-shadow-blur) var(--form-item-required-new);
  }

  .@{inputClsPrefix}-wrap .@{inputClsPrefix} {
    box-shadow: none;
  }
}

/* 新版校验提示效果 */

.required-fc,
.required-fc-m {
  color: var(--form-item-required-new);
}

.required-fc {
  font-size: var(--font-size-12);
}

.form-pc-readOnlyLineHeightStandard {
  height: unset;
  line-height: var(--form-pc-readonly-line-height);
  padding-top: var(--form-pc-readonly-padding-y);
  padding-bottom: var(--form-pc-readonly-padding-y);
  // border: 1px solid blue;
}

.form-mobile-readOnlyLineHeightStandard {
  height: unset;
  line-height: var(--form-mobile-readonly-line-height);
  padding-top: var(--form-mobile-readonly-padding-y);
  padding-bottom: var(--form-mobile-readonly-padding-y);
}