.@{associateListClsPrefix} {
  &-title {
    display: flex;
    .@{appIconClsPrefix}, .@{iconClsPrefix}-wrapper {
      display: flex;
      align-items: center;
      margin-right: calc(5 * var(--hd));
    }
    .@{uploadClsPrefix}-listT {
      margin-top: 0;
      .ui-upload-list{
        display: flex;
        .ui-list-body{
          width: 100%;
        }
      }
      .@{uploadClsPrefix}-listT-item-icon {
        display: none;
      }
      &-item {
        padding: 0;
        &-span {
          .@{iconClsPrefix}-svg {
            display: none;
          }
        }
        &-oparate {
          display: none;
        }
      }
    }
  }
  &-menu {
    padding: calc(5 * var(--hd)) calc(16 * var(--hd));
    color: var(--secondary-fc);
  }
  &-gray {
    color: var(--secondary-fc);
  }
  &-file {
    &-title {
      
    }
  }
  &-operate {
    opacity: 0;
    transition: all 0.3s;
  }
  .@{tableClsPrefix}-grid-tr {
    &:hover {
      .@{associateListClsPrefix}-operate {
        opacity: 1;
      }
    }
  }
  &-click {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: var(--primary);
    }
  }
}