.@{commonPhraseClsPrefix} {
  display: inline-block;
  &-content {
    width: calc(300 * var(--hd));
    border: var(--hd) solid #e5e5e5;
    background: var(--bg-white);
    border-radius: calc(3 * var(--hd));
    box-shadow: var(--box-shadow);

    &-spin{
      height: calc(326 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-phrase-spin{
      height: calc(250 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-phrase-spin-nogroup{
      height: calc(284 * var(--hd));
    }

    &-search {
      padding: calc(10 * var(--hd));
      padding-bottom: 0;

      .@{inputClsPrefix}-prefix{
        .@{iconClsPrefix}{
          color: var(--secondary-fc);
        }
      }
    }

    &-list {
      height: calc(200 * var(--hd));
      margin-top: calc(8 * var(--hd));
      .@{listClsPrefix}-item {
        height: calc(30 * var(--hd));
        align-items: center;
        display: flex;
        cursor: pointer;
        &:hover {
          background: var(--label-bc);
        }
        &:hover .@{commonPhraseClsPrefix}-content-list-custom-item-icons {
          display: inline;
        }
      }
    }

    &-list-nogroup{
      height: calc(235 * var(--hd));

      .ui-list-item{
        height: auto;
      }
    }

    &-tabs{
     .icon-more{
      cursor: pointer;
      color: var(--phrase-link-color);
     }
     
     .ui-menu-extra{
      margin: 0;
     }
    }

    &-list-custom-item {
      padding: 0 calc(16 * var(--hd));
      width: 100%;
      height: calc(30 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-name{
        width: 100%;
        overflow: hidden;
        
        .groupname{
          margin-top: calc(2 * var(--hd));
          color: var(--secondary-fc);
        }

        .phrasename{
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &-icons {
        display: none;
        .@{iconClsPrefix} {
          margin-left: calc(10 * var(--hd));
          color: var(--secondary-fc);
        }
      }
    }
    &-add {
      margin: calc(8 * var(--hd)) 0 calc(10 * var(--hd)) 0;
      display: flex;
      justify-content: center;
      &-con{
        display: flex;
        align-items: center;
        color: var(--phrase-link-color) ;
      }
      &-button {
        display: flex;
        align-items: center;
      }
      &-text{
        font-size:var(--font-size-12);
        margin-left: calc(5 * var(--hd));
      }
    }
    &-empty{
      margin: calc(66 * var(--hd)) 0 calc(67 * var(--hd)) 0;
    }
  }
  &-edit {
    &-title {
      display: flex;
      align-items: center;
    }
    &-icon-required {
      .Icon-Required {
        width: calc(6 * var(--hd));
        height: calc(6 * var(--hd));
      }
      margin: 0 calc(5 * var(--hd));
    }
    &-form {
      .@{collapseClsPrefix}-panel:first-child{
        .@{collapseClsPrefix}-panel__title{
          padding-top: 8px;
        }
      }
      .@{collapseClsPrefix}-panel:last-child{
        .@{collapseClsPrefix}-panel__content{
          .@{layoutClsPrefix}-row,.@{layoutClsPrefix}-col.@{formClsPrefix}-col{
          border: none;
          background: var(--modal-bc);
          }
          .@{formClsPrefix}-row{
            padding-right: calc(10 * var(--hd));
          }
         }
        .@{formItemClsPrefix}-noLabel {
          padding: 0;
        }
      }
      &-group-title {
        font-weight: normal;
        color: var(--regular-fc);
        display: flex;
        align-items: center;
      }
      &-group-icon {
        margin: 0 calc(5 * var(--hd));
        .Icon-Required {
          width: calc(6 * var(--hd));
          height: calc(6 * var(--hd));
        }
      }
    }
    &-formitem{
      width: 100%;
      font-weight: normal;
      padding: 0;
      height: calc(25 * var(--hd));
      >.ui-formItem-label-col{
        flex: none;
      }
      .ui-formItem-wrapper-col, .ui-formItem-label-col{
        display: flex;
        align-items: center;
      }
    }
  }
  &-children {
    cursor: pointer;
  }
}

.@{commonPhraseClsPrefix}-trigger {
  margin: calc(10 * var(--hd)) 0;
}
.@{commonPhraseClsPrefix}-edit-dialog{
  .@{dialogClsPrefix}-body{
    padding-top: 0;
    padding-bottom: calc(38 * var(--hd));
    
    &-container{
      overflow-y: hidden;
    }
  }
}

.@{commonPhraseClsPrefix}-content-list-item-popover {
  
  .@{popoverClsPrefix}-content {
    line-height: 1;
    white-space: normal;
    padding-right: calc(5 * var(--hd));
  }

  &-html {
    white-space: nowrap;
    max-height: calc(600 * var(--hd));
    overflow-y: auto;
    padding-right: calc(5 * var(--hd));

    img, table{
      max-width: 100%;
    }

    img{
      margin: calc(5px) 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: inherit;
      font-weight: normal;
      margin: 0;
    }
    
    strong{
      font-weight: normal;
    }
    
    a{
      color: var(--base-white);
      text-decoration-color: var(--base-white);
    }

    a:hover, a:active {
      color: var(--base-white);
      text-decoration-color: var(--base-white);
    }

    p{
      white-space: normal;
      margin-block-end: calc(5 * var(--hd));
      margin-block-start: calc(5 * var(--hd));
      margin: 0;
      line-height: 1.3;
    }

    img[pluginname='emoticon'] {
      width: calc(22 * var(--hd));
    }
  }

  .@{popoverClsPrefix} {
    max-width: calc(350 * var(--hd));
    :hover::-webkit-scrollbar-thumb {
      background: rgb(122, 124, 131);
    }

    ::-webkit-scrollbar {
      width: calc(6 * var(--hd));
    }
  }
}

// 镜像样式
.@{commonPhraseClsPrefix}-edit-dialog-rtl {
  .@{commonPhraseClsPrefix}-edit-formitem .ui-formItem-label-span {
    .mirror-text-re;
  }
}

.@{commonPhraseClsPrefix}-content-custom-select-dropdown{
  padding-bottom: calc(16 * var(--hd));
  background: var(--bg-white);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow);
  border: var(--border-solid);

  .ui-select-dropdown {
    border: 0;
    box-shadow: unset;
    padding-bottom: 0;
  }

  .link-wrap{
    margin-left: calc(12 * var(--hd));
    display: flex;
    align-content: center;

    .ui-icon{
      color:var(--phrase-link-color);
      margin-right: calc(3 * var(--hd));
    }
  }

  .ui-select-option-list-empty{
    margin-bottom: calc(8 * var(--hd));
  }
  
}



