.@{colorPickerClsPrefix} {
  max-width: calc(120 * var(--hd));
  min-width: calc(100 * var(--hd));

  &:hover {
    background-color: white;
  }

  .ui-input,
  .ui-textarea,
  .ui-input-wrap {
    background-color: var(--bg-transparent);
  }

  .is-disabled,
  .is-readonly {
    background-color: var(--label-bc);
    color: var(--secondary-fc);

    .@{colorPickerClsPrefix}-btn {
      cursor: not-allowed;
    }
  }

  .is-readonly {
    .ui-input-suffix {
      //display: none;
    }
  }

  &-inp {
    padding-right: calc(1 * var(--hd));

    &__is-readonly {
      cursor: default !important;

      .ui-input {
        cursor: text;
      }

      .ui-input-suffix {
        .ui-colorpicker-btn {
          cursor: default;
        }
      }
    }
  }

  &-btn {
    width: calc(24 * var(--hd));
    height: calc(24 * var(--hd));
    background-color: var(--bg-white);
    cursor: pointer;
    border: calc(1 * var(--hd)) solid var(--border-color);
  }

  &-btn-transparent {
    position: relative;
    border: calc(1 * var(--hd)) solid var(--base-black);
    overflow: hidden;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-bottom: calc(23 * var(--hd)) solid var(--danger);
      border-left: calc(23 * var(--hd)) solid transparent;
      content: "";
    }

    &:after {
      position: absolute;
      left: calc(1 * var(--hd));
      right: 0;
      top: calc(0 * var(--hd));
      bottom: 0;
      border-bottom: calc(23 * var(--hd)) solid var(--base-white);
      border-left: calc(23 * var(--hd)) solid transparent;
      content: "";
    }
  }
}

.@{colorPickerClsPrefix}-popover {
  background-color: var(--bg-transparent);

  .ui-popover-content {
    border-radius: calc(2 * var(--hd));
  }
}

.@{colorPickerClsPrefix}-cont {
  width: calc(229 * var(--hd));

  &-colors {
    display: flex;
    height: calc(136 * var(--hd));
    justify-content: space-between;
  }

  &-footer {
    display: flex;
    align-items: center;
    margin-top: calc(6 * var(--hd));

    &-val {
      width: calc(40 * var(--hd));
      height: calc(26 * var(--hd));
      border: calc(1 * var(--hd)) solid var(--border-color);
    }

    &-val-transparent {
      position: relative;
      border: calc(1 * var(--hd)) solid var(--base-black);
      overflow: hidden;

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-bottom: calc(23 * var(--hd)) solid var(--danger);
        border-left: calc(23 * var(--hd)) solid transparent;
        content: "";
      }

      &:after {
        position: absolute;
        left: calc(1 * var(--hd));
        right: 0;
        top: calc(1 * var(--hd));
        bottom: 0;
        border-bottom: calc(23 * var(--hd)) solid var(--base-white);
        border-left: calc(23 * var(--hd)) solid transparent;
        content: "";
      }
    }

    &-val-inp {
      width: calc(84 * var(--hd));
      height: calc(24 * var(--hd));
      margin: 0 calc(4 * var(--hd));
      padding: calc(3 * var(--hd)) calc(6 * var(--hd));
      color: var(--main-fc);
    }

    &-sure-btn {
      width: calc(75 * var(--hd));
      height: calc(26 * var(--hd));
      padding: calc(4 * var(--hd)) calc(6 * var(--hd));
    }
  }

  &-preset-colors-list {
    display: flex;
    flex-wrap: wrap;
    border-top: calc(1 * var(--hd)) solid var(--base-black);
    border-left: calc(1 * var(--hd)) solid var(--base-black);
    margin-bottom: 10px;

    &-span {
      display: inline-block;
      width: calc(12 * var(--hd));
      height: calc(12 * var(--hd));
      border-right: calc(1 * var(--hd)) solid var(--base-black);
      border-bottom: calc(1 * var(--hd)) solid var(--base-black);
      cursor: pointer;
    }

    &-span-transparent {
      position: relative;
      display: inline-block;
      overflow: hidden;

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-bottom: calc(12 * var(--hd)) solid var(--danger);
        border-left: calc(12 * var(--hd)) solid transparent;
        content: "";
      }

      &:after {
        position: absolute;
        left: calc(1 * var(--hd));
        right: 0;
        top: calc(1 * var(--hd));
        bottom: 0;
        border-bottom: calc(11 * var(--hd)) solid var(--base-white);
        border-left: calc(11 * var(--hd)) solid transparent;
        content: "";
      }
    }
  }

  &-recent-colors {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: calc(10 * var(--hd)) 0;

    &-tip {
      color: var(--secondary-fc);
      font-size: calc(10 * var(--hd));
    }

    &-color {
      //height: calc(13 * var(--hd));
      //border-left: calc(1 * var(--hd)) solid var(--base-black);
    }

    &-span {
      display: inline-block;
      width: calc(12 * var(--hd));
      height: calc(12 * var(--hd));
      border-top: calc(1 * var(--hd)) solid var(--base-black);
      border-right: calc(1 * var(--hd)) solid var(--base-black);
      border-bottom: calc(1 * var(--hd)) solid var(--base-black);
      cursor: pointer;

      &:first-child {
        border-left: calc(1 * var(--hd)) solid var(--base-black);
      }
    }

    &-span-transparent {
      position: relative;
      overflow: hidden;

      &:before {
        position: absolute;
        top: -1px;
        right: 0;
        left: 0;
        bottom: 0;
        border-bottom: calc(12 * var(--hd)) solid var(--danger);
        border-left: calc(12 * var(--hd)) solid transparent;
        content: "";
      }

      &:after {
        position: absolute;
        left: calc(1 * var(--hd));
        right: 0;
        top: calc(0 * var(--hd));
        bottom: 0;
        border-bottom: calc(11 * var(--hd)) solid var(--base-white);
        border-left: calc(11 * var(--hd)) solid transparent;
        content: "";
      }
    }
  }

  &-hue {
    width: calc(18 * var(--hd));
    height: 100%;
    background: linear-gradient(to bottom, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .2);
    position: relative;

    &-pointer {
      width: 100%;
      height: 100%;
      position: relative;
    }

    &-slider {
      width: calc(18 * var(--hd));
      height: calc(2 * var(--hd));
      box-shadow: 0 0 1px var(--box-shadow);
      //border: calc(2 * var(--hd)) solid var(--base-white);
      position: absolute;
      transform: translate(-2px, -2px);
      cursor: pointer;
    }
  }

  &-saturation {
    width: calc(200 * var(--hd));
    height: 100%;
    position: relative;
    overflow: hidden;

    &-gridient {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &-pointer {
      position: absolute;
      cursor: hand;
    }

    &-circle {
      width: calc(8 * var(--hd));
      border-radius: calc(8 * var(--hd));
      height: calc(8 * var(--hd));
      box-shadow: 0 0 calc(1 * var(--hd)) var(--box-shadow-color);
      border: calc(2 * var(--hd)) solid var(--base-white);
    }

    &-white {
      background: -webkit-linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
      background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    }

    &-black {
      background: -webkit-linear-gradient(to top, #000, rgba(0, 0, 0, 0));
      background: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
    }
  }

  &-saturation-rtl {
    transform: scaleX(-1);
  }

  &-alpha {
    &-header {
      font-size: var(--font-size-12);
      color: var(--main-fc);
      margin: calc(10 * var(--hd)) 0 calc(4 * var(--hd));
    }

    &-cont {
      display: flex;
      align-items: center;
    }

    &-slider {
      margin-right: calc(12 * var(--hd));
    }

    &-inp {
      width: calc(42 * var(--hd));
      height: calc(24 * var(--hd));
      min-height: calc(24 * var(--hd));
      margin-right: calc(4 * var(--hd));
      padding: calc(3 * var(--hd)) calc(5 * var(--hd));
      color: var(--main-fc);
    }

    &-symbol {
      font-size: var(--font-size-10);
      color: var(--main-fc);
    }
  }
}