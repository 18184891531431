.@{mBrowserClsPrefix}-dialog-browser {
  &-list {
    &-group {
      &-title {
        line-height: calc(30 * var(--hd));
        padding-left: var(--h-spacing-lg);
        font-size: var(--font-size-base);
        color: var(--secondary-fc);
      }
    }
  }
  &-list-item {
    &.is-free {
      &::after {
        display: none;
      }
    }
    .@{mBrowserClsPrefix}-associative-inner {
      .@{formItemClsPrefix}-item {
        align-items: center;
      }
      .@{formItemClsPrefix}-item-label {
        color: var(--main-fc);
        font-size: var(--font-size-base);
      }
    }
  }
  &-list-item-free-tip {
    position: relative;
    color: #fb6e52;
    font-size: var(--font-size-12);
    font-style: italic;
    padding-bottom: var(--v-spacing-xs);
    padding-left: var(--h-spacing-lg);
    background-color: var(--bg-white);
    &::after {
      content: "";
      border-bottom: none;
      height: var(--hd);
      background-color: var(--border-color);
      transform: scaleY(0.3);
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.@{mBrowserClsPrefix}-types-associative {
  &.show-associative-selected {
    background-color: var(--m-bg-base);
  }
  &-selected {
    &-item {
      margin-bottom: var(--v-spacing-md);
      &:last-of-type {
        margin-bottom: 0;
      }
      &.ui-formItem-mobile .ui-formSwitch {
        padding-top: var(--v-spacing-sm);
        padding-bottom: var(--v-spacing-sm);
      }
      &.is-free {
        margin-bottom: 0;
      }
      .@{mBrowserClsPrefix}-associative-inner {
        .@{formItemClsPrefix}-item {
          align-items: center;
        }
        .@{formItemClsPrefix}-item-label {
          color: var(--main-fc);
          font-size: var(--font-size-base);
        }
      }
    }
  }
  .@{mBrowserClsPrefix}-dialog-browser-list-item-free-tip {
    margin-bottom: var(--v-spacing-md);
    &::after {
      display: none;
    }
  }
}

.@{mBrowserClsPrefix} {
  &-selected-panel-list,
  &-panel-list {
    &-item {
      .@{browserClsPrefix}-panel-list-template-content-wrapper {
        white-space: pre-wrap;
      }
    }
    &.ui-m-list-column .ui-m-list-content {
      padding-right: var(--h-spacing-lg);
    }
  }
}
// .@{mBrowserClsPrefix}-types {
//   &-selected-panel {
//     &-list-item {
//       .@{browserClsPrefix}-panel-list-template-content-wrapper {
//         white-space: pre-wrap;
//       }
//     }
//     .@{mBrowserClsPrefix}-selected-panel-list {
//       &.ui-m-list-column .ui-m-list-content {
//         padding-right: var(--h-spacing-lg);
//       }
//     }
//   }
// }
.@{mBrowserClsPrefix}-panel-header {
  &-bar {
    .cancel-btn {
      color: var(--primary);
    }
  }
  &-batch-btn {
    color: var(--secondary-fc);
  }
}

.@{mBrowserClsPrefix}-panel-dialog-footer {
  &-selectAll {
    display: flex;
    align-items: center;
    text-align: left;
    height: 100%;
    .ui-m-checkbox-left {
      padding: 0;
    }
  }
  &-btns {
    .is-batch-delete-btn {
      
    }
  }
}
