.@{browserClsPrefix}-share-detail {
  border: var(--hd) solid var(--border-color);
  .ui-table-grid-thead .ui-table-grid-th-order:hover {
    background-color: var(--label-bc);
  }
  // title 样式
  .@{browserClsPrefix}-share-detail-title {
    border: 0;
    // border: var(--hd) solid var(--share-title-border-color);
    // border-bottom: 0;
    height: calc(50 * var(--hd));
    .@{titleClsPrefix}-title-top {
      font-size: var(--font-size-14);
      color: var(--main-fc);
    }
    &-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      &-options-icon {
        color: var(--primary);
        margin-right: calc(var(--hd) * 10);
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &-disabled {
          color: var(--secondary-fc);
          cursor: not-allowed;
        }
      }
      &-search-bar {
        margin-left: calc(20 * var(--hd));
      }
    }
  }
  // table 样式
  .@{browserClsPrefix}-share-detail-table {
    border: 0;
    border-top: var(--hd) solid var(--diviling-line-color);
    tr {
      .@{browserClsPrefix}-share-detail-table-operate {
        .ui-menu {
          .ui-menu-select-iconOverlay {
            .ui-icon-md {
              visibility: hidden;
              transition: all 0.1s;
            }
          }
        }
      }
    }
    tr:hover {
      .@{browserClsPrefix}-share-detail-table-operate {
        .ui-menu {
          .ui-menu-select-iconOverlay {
            .ui-icon-md {
              visibility: visible;
            }
          }
        }
      }
    }
  }

  &-dialog {
    .ui-dialog-body-container {
      padding-bottom: calc(20 * var(--hd));
    }
    .ui-dialog-body {
      height: 100%;
    }
  }

  // &-obj_id {
  //   a {
  //     color: var(--primary);
  //     &:hover {
  //       text-decoration: underline;
  //     }
  //   }
  // }
}
