/* menu 菜单效果，用于左侧菜单 */
.@{menuClsPrefix}-menu {
  width: var(--menu-inline-wdith);

  &-inline-collapsed {
    width: calc(54 * var(--hd));
  }

  .@{menuClsPrefix}-list {
    display: inline-block;
    width: 100%;
    overflow: hidden;
  }

  .@{menuClsPrefix}-list-item,
  .@{menuClsPrefix}-list-subitem-title {
    padding-left: var(--h-spacing-lg);
    padding-right: calc(10 * var(--hd));
    border-left: var(--menu-border-width) solid transparent;
  }

  .@{menuClsPrefix}-list-item:hover {
    background: var(--menu-item-hover-bc);
  }

  .@{menuClsPrefix}-list-subitem-title:hover {
    background: var(--menu-item-hover-bc);
  }

  .@{menuClsPrefix}-list-item-active {
    background: var(--menu-item-hover-bc);
    border-color: var(--primary);
  }

  .hidden {
    display: none;
  }

  .slide {
    transition: all 0.3s cubic-bezier(0, 1, 0.5, 1);
    transform: translateY(0);
    overflow-y: hidden;
  }

  .slide-up {
    transform: translateY(-100%);
    display: none;
  }
}

/* menu 菜单新版效果，主要用在后端配置页面 */
.@{menuClsPrefix}-menu-special {
  // 一级菜单样式
  // .@{menuClsPrefix}-list-item, .@{menuClsPrefix}-list-subitem-title {
  //   font-weight: 600;
  //   color: var(--main-fc);
  //   .@{menuClsPrefix}-list-item-lefticon .@{iconClsPrefix} {
  //     color: var(--main-fc);
  //   }
  //   &:hover {
  //     color: var(--primary);
  //     .@{menuClsPrefix}-list-item-lefticon .@{iconClsPrefix} {
  //       color: var(--primary);
  //     }
  //   }
  // }
  // .@{menuClsPrefix}-list-item-active {
  //     color: var(--primary);
  //    .@{menuClsPrefix}-list-item-lefticon .@{iconClsPrefix} {
  //     color: var(--primary);
  //   }
  // }
  // // 二级菜单样式
  // .@{menuClsPrefix}-list-subitem .@{menuClsPrefix}-list {
  //   .@{menuClsPrefix}-list-item, .@{menuClsPrefix}-list-subitem-title {
  //     font-weight: normal;
  //   }
  // }
  
  // 三级菜单样式调整
  .@{menuClsPrefix}-list-subitem .@{menuClsPrefix}-list-subitem {
    .@{menuClsPrefix}-list-item {
      font-weight: normal;
    }
    .@{menuClsPrefix}-list-item, .@{menuClsPrefix}-list-subitem-title {
      .@{menuClsPrefix}-list-item-lefticon .@{iconClsPrefix} {
        color: var(--main-fc);
      }
    }
    .@{menuClsPrefix}-list {
      .@{menuClsPrefix}-list-item {
        border: 0;
        color: var(--menu-item-new-color);
        &:hover {
          background-color: var(--bg-transparent);
          color: var(--primary);
        }
      }
      .@{menuClsPrefix}-list-item-content {
        position: relative;
        height: 100%;
        line-height: var(--menu-item-height);
        margin-left: var(--h-spacing-md);
        &::before {
          position: absolute;
          top: 0;
          left: calc(1 * var(--hd));
          bottom: 0;
          content: '';
          width: calc(1 * var(--hd));
          background-color: var(--border-color);
        }
      }
      .@{menuClsPrefix}-list-item:first-child .@{menuClsPrefix}-list-item-content::before {
        top: calc(6 * var(--hd));
      }
      .@{menuClsPrefix}-list-item:last-child .@{menuClsPrefix}-list-item-content::before {
        bottom: calc(6 * var(--hd));
      }

      .@{menuClsPrefix}-list-item-active { // active样式
        color: var(--primary);
        background-color: var(--bg-transparent);
        .@{menuClsPrefix}-list-item-lefticon .@{iconClsPrefix} {
          color: var(--primary);
        }
        .@{menuClsPrefix}-list-item-content::after {
          position: absolute;
          top: calc(6 * var(--hd));
          bottom: calc(6 * var(--hd));
          left: 0;
          content: '';
          width: calc(3 * var(--hd));
          background-color: var(--primary);
        }
      }
    }
  }
}

/* 水平菜单 mode-inline */
.@{menuClsPrefix}-mode-inline {
  .@{menuClsPrefix}-list-item {
    &-lefticon {
      margin-right: var(--h-spacing-md)
    }
  }
}

/* 垂直菜单 mode-vertical */
.@{menuClsPrefix}-mode-vertical {
  width: var(--menu-vertical-width);

  .@{menuClsPrefix}-list-item,
  .@{menuClsPrefix}-list-subitem {
    width: var(--menu-vertical-width);
    text-align: center;
  }

  .@{menuClsPrefix}-list-item-lefticon {
    margin: 0;
  }

  .@{menuClsPrefix}-list-item-active {
    border-left: 0;

    .@{iconClsPrefix}-wrapper {
      margin-left: var(--menu-border-width);
    }

  }
}