.@{mSecretSelectClsPrefix} {
  width: 100%;
  background-color: var(--bg-white);
  .ui-formSwitch {
    width: 100%;
  }
  &-label {
    // display: flex;
    // align-items: center;
    line-height: 1;
    .@{formItemClsPrefix}-requiredmark {
      margin-left: calc(4 * var(--hd));
    }
  }
  // 保密等级
  &-secretlevel {
    position: relative;
  }
  // 保密期限
  &-secretvalidity {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: var(--hd);
      width: 100%;
      background-color: var(--border-color);
      transform: scaleY(0.3);
      pointer-events: none;
    }
    &-input {
      .ui-m-input-readonly {
        text-align: left;
      }
      // // input 高度
      // &.ui-m-list-item.ui-m-input-item {
      //   height: calc(30 * 1px);
      //   .ui-m-input-extra {
      //     height: calc(30 * 1px);
      //   }
      //   .ui-m-list-line .ui-m-input-control input {
      //     height: calc(30 * 1px);
      //   }
      // }
      // 提示信息
      &-help-tip {
        font-size: var(--font-size-12);
        color: var(--secondary-fc);
        line-height: calc(20 * 1px);
      }
    }
  }

  /****************************** label 模式 ******************************/
  &-label {
    line-height: unset;
    padding: calc(13 * var(--hd)) 0;
    color: var(--regular-fc);
    text-align: left;
    &-span {
      vertical-align: middle;
      overflow: unset;
      white-space: unset;
      text-overflow: unset;
      word-break: break-word;
    }
    // 如果没有宽度，直接隐藏
    &.ui-layout-col-0 {
      display: none;
    }
    .ui-formItem-requiredmark.ebcoms-assets-icon.s {
      height: calc(20 * var(--hd));
    }
  }
  /****************************** 只读 ******************************/
  &-readOnly {
    display: flex;
    .@{mSecretSelectClsPrefix} {
      &-text-wrap {
        width: 100%;
      }
      &-secretlevel,
      &-secretvalidity,
      &-symbol {
        display: inline-block;
        color: var(--main-fc);
        height: var(--line-height-base);
        line-height: var(--line-height-base);
        font-size: var(--font-size-base);
      }
      &-secretvalidity {
        &::before {
          display: none;
        }
      }
      &-symbol {
        margin: 0 calc(4 * var(--hd));
      }
    }
    // 特殊样式，正对 withLabel 特殊处理
    &.@{mSecretSelectClsPrefix}-withLabel {
      .@{mSecretSelectClsPrefix} {
        &-row {
          width: 100%;
        }
        &-comItem {
          display: flex;
          align-items: center;
          line-height: 1;
        }
      }
    }
  }

  /****************************** form 中特殊样式处理 ******************************/
  // 必填标记显示位置为字段右侧时，隐藏 form 表单必填标记，由组件进行展示
  + .ui-formSwitch-requiredMark {
    display: none;
  }
}

/****************************** 弹框模式 ******************************/
.@{mSecretSelectClsPrefix}-dialog {
  .@{mDialogClsPrefix}-top {
    padding-left: calc(16 * var(--hd));
    padding-right: calc(16 * var(--hd));
  }
  // 密级组件
  .@{mSecretSelectClsPrefix} {
    margin-top: calc(20 * var(--hd));
    &-label {
      display: inline-flex;
      &-span {
        line-height: calc(20 * var(--hd));
        white-space: nowrap;
      }
    }
    &-secretvalidity {
      &-formSwitch {
        text-align: left;
      }
      &-input-help-tip {
        text-align: left;
      }
    }
  }
}
