.@{mListClsPrefix}-item {
  .@{mInputClsPrefix}-control {
    .fake-input-container {
      height: calc(30 * var(--hd));
      line-height: calc(30 * var(--hd));
      margin-top: var(--v-spacing-md);
      position: relative;

      .fake-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin-right: calc(5 * var(--hd));
        text-decoration: rtl;
        text-align: right;
        color: #000;
        // font-size: 17 * @hd;
        font-size: calc(14 * var(--hd));
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.fake-input-disabled {
          color: var(--color-text-disabled);
        }

        &.focus {
          transition: color .2s;

          &:after {
            content: "";
            position: absolute;
            right: 0;
            // top: 10%;
            height: 80%;
            border-right: var(--border-width) solid var(--primary);
            animation: keyboard-cursor infinite 1s step-start;
          }
        }
      }

      .fake-input-placeholder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: var(--color-text-placeholder);
        text-align: right;
      }
      &-left {
        .fake-input {
          text-align: left;
          &.focus:after {
            position: relative;
          }
        }
        .fake-input-placeholder {
          text-align: left;
        }
      }
    }
  }
}

.ui-m-input-number-keyboard-wrapper {
  .ui-m-input-number-keyboard-wrapper-hide {
    bottom: calc(-500 * var(--hd));
  }
}

.@{mKeyboardClsPrefix}-wrapper {
  &.@{mKeyboardClsPrefix}-wrapper-hide {
    bottom: calc(-500 * var(--hd));
  }
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(200 * var(--hd));
  z-index: 10000;
  background-color: #f6f6f7;
  transition-duration: var(--transition-du);
  transition-property: transform display;
  transform: translateZ(0);

  table {
    width: 100%;
    padding: 0;
    margin: 0;
    border-collapse: collapse;
    // .hairline('top');
    border-top: var(--border-solid);

    tr {
      width: 100%;
      padding: 0;
      margin: 0;

      .@{mKeyboardClsPrefix}-item {
        width: 25%;
        padding: 0;
        margin: 0;
        height: calc(50 * var(--hd));
        text-align: center;
        transition: background-color var(--transition-du) var(--transition-tf);
        &:active {
          opacity: 0.7;
          background-color: var(--bg-weak);
        }
        &:not(.keyboard-confirm) {
          // .hairline('left');
          border-left: var(--border-solid);
          // .hairline('bottom');
          border-bottom: var(--border-solid);
        }
        font-size: calc(25.5 * var(--hd));
        color: #2a2b2c;
        position: relative;

        &.@{mKeyboardClsPrefix}-item-active {
          background-color: var(--bg-tap);
        }

        &.keyboard-confirm {
          color: #fff;
          font-size: calc(21 * var(--hd));
          background-color: var(--primary);
          // .hairline('bottom');
          border-bottom: var(--border-solid);
          &.@{mKeyboardClsPrefix}-item-active {
            background-color: var(--primary);
          }

          &.@{mKeyboardClsPrefix}-item-disabled {
            background-color: var(--invalid);
          }
        }

        &.keyboard-delete {
          // .encoded-svg-background('input_item_kb_backspace');
          background-size: calc(25.5 * var(--hd)) calc(18.5 * var(--hd));
          background-position: 50% 50%;
          background-repeat: no-repeat;
          .@{iconClsPrefix} {
            width: calc(25.5 * var(--hd));
            height: calc(25.5 * var(--hd));
          }
        }

        &.keyboard-hide {
          // .encoded-svg-background('input_item_kb_hide');
          background-size: calc(32.5 * var(--hd)) calc(23.5 * var(--hd));
          background-position: 50% 50%;
          background-repeat: no-repeat;
          .@{iconClsPrefix} {
            width: calc(25.5 * var(--hd));
            height: calc(25.5 * var(--hd));
          }
        }
      }
    }
  }
}

@keyframes keyboard-cursor {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
