.@{searchAdvancedClsPrefix} {
  font-size: var(--font-size-12);
  color: var(--main-fc);
  position: relative;
  .@{triggerClsPrefix} {
    display: block;
  }
  &-wrap {
    text-align: right;
    &-simple {
      text-align: left;
      &-triger-container {
        height: calc(var(--input-height) + calc(var(--input-border-width) * 2));
        display: flex;
        align-items: center;
      }
    }
    &-allLine {
      padding: var(--v-spacing-md) 0;
    }
    &-onlyShowIcon {
      display: inline-block;
      .@{formSwitchClsPrefix}-error-bottom {
        .@{formSwitchClsPrefix}-showError {
          padding-top: 0;
          left: calc(-1 * var(--h-spacing-md) + var(--hd));
          .@{formSwitchClsPrefix}-arrow-before, .@{formSwitchClsPrefix}-arrow {
            margin-left: var(--h-spacing-md);
          }
        }
      }
    }
  }
  &-input {
    background: var(--bg-transparent);
    input {
      background: var(--bg-transparent);
    }
    &:hover {
      background: var(--bg-white);
      input {
        background: var(--bg-white);
      }
    }
    &-suffix {
      .@{iconClsPrefix} {
        position: relative;
        display: inline-flex;
        align-items: center;
        &:last-child {
          padding-left: var(--h-spacing-md);
          border-left: var(--border-solid);
        }
      }
      &-enter {
        color: var(--searchAdvanced-input-stuffix-color);
        margin-right: var(--h-spacing-sm);
        margin-left: var(--h-spacing-sm);
      }
      &.hide {
        padding-left: var(--h-spacing-md);
      }
    }
    &-icon {
      cursor: pointer;
      color: var(--searchAdvanced-input-stuffix-color);
      &-active {
        color: var(--primary);
      }
    }
  }
  // 文字高亮处理
  .@{sensitiveWordScanClsPrefix}-highlight-origin {
    .@{searchAdvancedClsPrefix}-input:hover input {
      background: var(--bg-transparent);
    }
    .@{inputClsPrefix}-suffix {
      background: var(--bg-white);
    }
  }
  .@{sensitiveWordScanClsPrefix}-highlight-mock {
    .@{sensitiveWordScanClsPrefix}-highlight-input-inner {
      text-align: left;
      .rich-text-highlight-active {
        background: var(--input-focus-border-color);
        padding: calc(2 * var(--hd)) 0;
        color: var(--base-white);
        // white-space: nowrap;
      }
    }
  }
  &-condition {
    // border: var(--searchAdvanced-border);
    border: var(--border-solid);
    border-bottom-color: var(--diviling-line-color);
    background: var(--bg-white);
    user-select: none;
    font-size: var(--font-size-12);
    color: var(--main-fc);
    &-popup {
      position: absolute;
      width: 100%;
      box-shadow: var(--box-shadow);
      z-index: 1;
    }
    &-top {
      border-bottom: var(--searchAdvanced-border);
      padding: calc(7 * var(--hd)) var(--h-spacing-lg);
      &-select {
        min-width: calc(74 * var(--hd));
        padding: 0 var(--h-spacing-sm);
      }
      &-title {
        line-height: var(--line-height-sm);
        height: var(--line-height-sm);
        vertical-align: middle;
      }
    }
    &-hide {
      display: none;
    }
    .@{selectClsPrefix} {
      .@{inputClsPrefix}-wrap {
        min-width: auto;
      }
    }
    .@{searchGroupClsPrefix}-body {
      padding-bottom: calc(10 * var(--hd));
    }
    .@{formClsPrefix}-searchGroup-custom .@{searchGroupClsPrefix}-top-hide {
      border-bottom: 0;
    }
    .@{formClsPrefix}-searchGroup-custom .@{searchGroupClsPrefix}-body {
      padding-bottom: 0;
    }
  }
  &-layout {
    border-bottom: var(--diviling-line-solid);
    margin: 0 var(--v-spacing-lg);
    &-noCommonFilter {
      border-bottom: 0;
    }
    &-noGroup {
      padding-bottom: calc(10 * var(--hd));
    }
    // 高级搜索字段样式
    .@{formItemClsPrefix} {
      // padding: calc(10 * var(--hd)) 10% 0 10%;
      // padding-top: calc(10 * var(--hd));
      // padding-right: calc(35 * var(--hd));
      // padding-left: calc(35 * var(--hd));
      &-label {
        position: relative;
        padding-right: calc(2 * var(--h-spacing-xs));
        .@{formItemClsPrefix}-label-span {
          // padding-left: var(--h-spacing-md);
        }
        &:after {
          content: "\003a";
          color: var(--main-fc);
          top: 0;
          padding-left: var(--h-spacing-xs);
          padding-right: var(--h-spacing-xs);
          display: inline-flex;
          align-items: center;
          height: 100%;
        }
        &.isCn:after {
          content: "\ff1a";
        }
      }
      &-wrapper {
        position: relative;
        padding-right: 0;
        /* 高级搜索内部对齐方式处理特殊处理（修复高级搜索下拉框、浏览框未对齐问题(顶部对齐)（PC端））*/
        align-items: flex-start;
        .@{formSwitchClsPrefix}-CHECKBOX-PC,
        .@{formSwitchClsPrefix}-RADIO-PC,
        .@{formSwitchClsPrefix}-RATE-PC,
        .@{formSwitchClsPrefix}-SCOPE-PC,
        .@{formSwitchClsPrefix}-SWITCH-PC {
          align-self: center; // checkbox、radio、rate、switch特殊处理
        }
        .@{formSwitchClsPrefix}-SELECT-PC {
          &.ui-formItem-condition{
            &.is-notCn{
              width: calc(130 * var(--hd));
            }
          }
        }
        .@{formSwitchClsPrefix}-help {
          // help提示信息居中对齐
          align-self: center;
        }
        /* -----------------------------------------------------------------  */
        &-condition {
          padding-left: calc(77 * var(--hd));
          &.is-notCn{
            padding-left: calc(134 * var(--hd));
          }
        }
        .@{formItemClsPrefix}-condition {
          margin-right: 0;
          position: absolute;
          left: 0;
          width: calc(73 * var(--hd));
          .@{selectClsPrefix}-input > span:first-child {
            display: inline-block;
            overflow: hidden;
            word-break: keep-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .@{formItemClsPrefix}-condition
          ~ div.@{formSwitchClsPrefix}-BROWSER-PC {
          width: calc(100% - calc(73 * var(--hd)));
        }
        .@{formItemClsPrefix}-line {
          &.@{formSwitchClsPrefix}-SCOPE-PC {
            align-self: center;
          }
          // width: 100%;
          & > .@{selectClsPrefix},
          & > .@{inputClsPrefix}-number,
          & > .@{timePickerClsPrefix},
          & > .@{timePickerClsPrefix} .pickerWrapper,
          & > .@{datePickerClsPrefix},
          & > .@{datePickerClsPrefix} .rangePickerWrapper,
          & > .@{colorPickerClsPrefix},
          & > .@{inputClsPrefix},
          & > .@{dateTimePickerClsPrefix},
          & > .@{dateTimePickerClsPrefix} .pickerWrapper,
          & > .@{browserClsPrefix}.is-empty {
            width: 100%;
            max-width: 100%;
            min-width: auto;
          }
          & > .@{browserClsPrefix}.is-empty {
            .@{browserClsPrefix}-associative-search.display-input {
              width: 100%;
              .@{browserClsPrefix}-associative-search-input {
                width: 100%;
              }
            }
          }
          & > .@{browserClsPrefix},
          &
            > .@{browserClsPrefix}
            .@{browserClsPrefix}-associative-search-input,
          & > .@{browserClsPrefix} .@{browserClsPrefix}-associative-search {
            max-width: 100%;
            min-width: auto;
          }
          & > .@{datePickerClsPrefix} .rangePickerWrapper .inputWrap {
            width: 50%;
          }
          & > .@{scopeClsPrefix},
          .@{inputClsPrefix}-wrap {
            max-width: 100%;
          }
          & > .@{colorPickerClsPrefix} {
            width: 100%;
          }
        }
        .@{formItemClsPrefix}-singleItem {
          margin-right: var(--h-spacing-sm);
          flex: 1;
          & > .@{selectClsPrefix},
          & > .@{inputClsPrefix}-number,
          & > .@{timePickerClsPrefix},
          & > .@{timePickerClsPrefix} .pickerWrapper,
          & > .@{datePickerClsPrefix},
          & > .@{datePickerClsPrefix} .rangePickerWrapper,
          & > .@{inputClsPrefix},
          & > .@{colorPickerClsPrefix},
          & > .@{dateTimePickerClsPrefix},
          & > .@{dateTimePickerClsPrefix} .pickerWrapper,
          & > .@{browserClsPrefix}.is-empty {
            width: 100%;
            max-width: 100%;
            min-width: auto;
          }
          & > .@{browserClsPrefix}.is-empty {
            .@{browserClsPrefix}-associative-search.display-input {
              width: 100%;
              .@{browserClsPrefix}-associative-search-input {
                width: 100%;
              }
            }
          }
          & > .@{browserClsPrefix},
          &
            > .@{browserClsPrefix}
            .@{browserClsPrefix}-associative-search-input,
          & > .@{browserClsPrefix} .@{browserClsPrefix}-associative-search {
            max-width: 100%;
            min-width: auto;
          }
          & > .@{datePickerClsPrefix} .rangePickerWrapper .inputWrap {
            width: 50%;
          }
          & > .@{scopeClsPrefix},
          .@{inputClsPrefix}-wrap {
            max-width: 100%;
          }
          & > .@{colorPickerClsPrefix} {
            width: 100%;
          }

          &:last-child {
            margin-right: 0;
          }

          &-last {
            margin-right: 0;
          }
        }
      }
      &-singleItem {
        padding: 0;
      }
    }
    .@{formClsPrefix} {
      .@{formItemClsPrefix} {
        padding-right: calc(54 * var(--hd));
        padding-left: calc(26 * var(--hd));
      }
      &-row .ui-layout-col-12:nth-child(odd) {
        .@{formItemClsPrefix} {
          padding-right: calc(92 * var(--hd));
        }
      }
      &-row .ui-layout-col-12:nth-child(even) {
        .@{formItemClsPrefix} {
          padding-left: calc(64 * var(--hd));
        }
      }
    }
  }
  &-footer {
    // border: var(--searchAdvanced-border);
    border: var(--border-solid);
    border-top: 0;
    text-align: center;
    padding: var(--h-spacing-md) 0;
    background: var(--bg-white);
    .@{btnClsPrefix} {
      margin: 0 calc(var(--h-spacing-md) / 2);
    }
  }
  &-setting {
    display: inline-block;
    padding: 0 var(--h-spacing-md);
    color: var(--searchAdvanced-form-item-icon-color);
    cursor: pointer;
    &-hsSetting {
      display: none;
    }
    &-search {
      width: calc(160 * var(--hd));
      .Icon-search {
        color: var(--searchAdvanced-input-stuffix-color);
      }
      &:after {
        content: "";
        clear: both;
      }
    }
    &-list {
      cursor: pointer;
      border-top: var(--hd) solid var(--diviling-line-color);
      &:first-child {
        border-top: 0;
      }
      height: var(--line-height-sm);
      align-items: center;
      .@{iconClsPrefix} {
        color: var(--secondary-fc);
        vertical-align: middle;
      }
      &-title {
        vertical-align: middle;
        margin-left: var(--v-spacing-lg);
        vertical-align: middle;
        font-size: var(--font-size-12);
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      &-content {
        padding-left: var(--h-spacing-lg);
        justify-content: flex-start !important;
        .@{iconClsPrefix} {
          color: var(--secondary-fc);
        }
      }
      &-switch {
        text-align: right;
        padding-right: var(--h-spacing-lg);
        justify-content: flex-end !important;
      }
      &-container {
        max-height: calc(352 * var(--hd));
      }
    }
    &-dialog {
      .@{dialogClsPrefix}-body,
      .@{dialogClsPrefix}-footer {
        background-color: var(--bg-white);
      }
      .@{dialogClsPrefix}-body {
        height: 100%;
        padding: var(--h-spacing-md) var(--h-spacing-lg) 0 var(--h-spacing-lg);
      }
      .@{dialogClsPrefix}-footer {
        border-top: 0;
      }
      .@{listClsPrefix} {
        border: var(--border-solid);
        margin-top: calc(8 * var(--hd));
      }
      .content-empty .Icon-empty-file {
        width: calc(80 * var(--hd));
        height: calc(80 * var(--hd));
      }
    }
    &-tip {
      display: inline-block;
      font-size: var(--font-size-12);
      color: var(--main-fc);
      line-height: var(--input-height);
      height: var(--input-height);
    }
    &-container {
      display: flex;
      justify-content: space-between;
    }
  }
  &-commonSelect {
    padding: calc(10 * var(--hd)) var(--h-spacing-lg);
    &-resList {
      display: inline-block;
      vertical-align: middle;
    }
    .@{searchAdvancedClsPrefix}-resList-single {
      border: var(--border-width) solid transparent;
      &-content-public {
        color: var(--primary);
      }
      &-active {
        border: calc(1 * var(--hd)) solid var(--primary);
        color: var(--primary);
        .@{searchAdvancedClsPrefix}-resList-single-content {
          &::before {
            content: "";
            transition: var(--transition-all);
            height: 100%;
            opacity: var(--btn-default-hover-opacity);
            background: var(--primary);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border: 0;
            margin: auto;
            border-radius: var(--border-radius-lg);
          }
        }
      }
    }
    .ui-spin-nested-loading > div > .ui-spin .ui-spin-dot {
      margin-top: calc(-7 * var(--hd));
    }
    .@{btnClsPrefix} {
      vertical-align: middle;
      margin-right: calc(10 * var(--hd));
    }
  }
  &-commonFilter {
    .@{searchAdvancedClsPrefix}-resList-single {
      border: var(--border-width) solid transparent;
      &-content-public {
        color: var(--primary);
      }
      &-active {
        border: calc(1 * var(--hd)) solid var(--primary);
        color: var(--primary);
        .@{searchAdvancedClsPrefix}-resList-single-content {
          &::before {
            content: "";
            transition: var(--transition-all);
            height: 100%;
            opacity: var(--btn-default-hover-opacity);
            background: var(--primary);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border: 0;
            margin: auto;
            border-radius: var(--border-radius-lg);
          }
        }
      }
    }
  }
  &-quikSearchPanel {
    background: var(--bg-white);
    border: var(--border-solid);
    box-shadow: var(box-shadow);
    padding: var(--v-spacing-lg) calc(25 * var(--hd));
    border-radius: var(--border-radius-xs);
    color: var(--secondary-fc);
    font-size: var(--font-size-sm);
    width: var(--searchAdvanced-quikSearchPanel-width);
    text-align: left;
    line-height: var(--searchAdvanced-quikSearchPanel-line-height);
    &-btn {
      color: var(--primary);
      cursor: pointer;
      font-size: var(--font-size-sm);
      padding-left: calc(13 * var(--hd));
    }
    &-fields {
      padding-bottom: calc(13 * var(--hd));
      white-space: normal;
    }
    &.needRTL {
      .@{searchAdvancedClsPrefix}-quikSearchPanel-fields {
        text-align: right;
      }
      .@{searchAdvancedClsPrefix}-quikSearchPanel-btn.mirror-text-re{
        padding-right: calc(13 * var(--hd));
        padding-left: 0;
      }
      .@{searchAdvancedClsPrefix}-historyPanel-title {
        text-align: right;
      }
    }
  }
  &-historyPanel {
    padding-bottom: var(--v-spacing-lg);
    &-top {
      position: relative;
    }
    &-title {
      color: var(--main-fc);
      font-size: var(--font-size-14);
      padding-bottom: var(--v-spacing-md);
    }
    &-icon {
      cursor: pointer;
      color: var(--searchAdvanced-form-item-icon-color);
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &-commonFilter-top {
    // padding-top: var(--v-spacing-lg);
  }
  &-commonFilter-icon {
    padding-top: var(--v-spacing-lg);
  }
  &-resList {
    white-space: normal;
    vertical-align: middle;
    &-single {
      cursor: pointer;
      display: inline-block;
      background: var(--label-bc);
      color: var(--regular-fc);
      margin-right: var(--h-spacing-md);
      border-radius: var(--border-radius-lg);
      position: relative;
      user-select: none;
      margin-top: calc(var(--v-spacing-sm) / 2);
      margin-bottom: calc(var(--v-spacing-sm) / 2);
      &-content {
        padding: var(--v-spacing-xs) var(--h-spacing-lg);
        max-width: calc(160 * var(--hd));
        display: flex;
        align-items: baseline;
        &-name {
          line-height: 1.5;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &:not(.disabled):hover {
        .@{searchAdvancedClsPrefix}-resList-single-close {
          display: inline-block;
        }
      }
      &-close {
        display: none;
        color: var(--searchAdvanced-historySearch-icon-color);
        position: absolute;
        top: calc(-1 * var(--icon-size-sm) / 2);
        right: calc(-1 * var(--icon-size-sm) / 2);
        cursor: pointer;
      }
    }
  }
  &-snaps-delInfo {
    .@{confirmClsPrefix}-body {
      word-break: break-word;
    }
  }
  &-snaps-confirm {
    &-label {
      font-size: var(--font-size-12);
      padding-right: var(--h-spacing-lg);
      text-align: right;
    }
    .@{titleClsPrefix}-inDialog {
      border-bottom: 0;
    }
    .@{dialogClsPrefix}-footer {
      border-top: 0;
    }
    .@{dialogClsPrefix}-body {
      // 底部给必填校验留位置
      padding: calc(2 * var(--hd)) var(--h-spacing-lg) calc(35 * var(--hd))
        var(--h-spacing-lg);
    }
    .@{confirmClsPrefix}-title {
      font-weight: 400;
    }
    .@{confirmClsPrefix}-footer {
      border-top: var(--border-solid);
    }
    .@{confirmClsPrefix}-body {
      min-height: auto;
      .@{formSwitchClsPrefix}-error {
        display: block;
        position: relative;
        width: 100%;
        &:after {
          position: absolute;
          content: "*";
          color: var(--form-item-required);
          padding-left: var(--h-spacing-sm);
          line-height: var(--input-line-height);
          padding-top: var(--input-padding-y);
          min-height: var(--input-height);
        }
      }
    }
    .@{titleClsPrefix}-title-top {
      font-weight: unset;
    }
  }
  &-sortable-drag {
    border: calc(1 * var(--hd)) solid
      var(--m-searchAdvanced-condition-active-border);
    color: var(--m-searchAdvanced-condition-active-border);
    background-color: var(--m-searchAdvanced-condition-active-bg);
  }
  &-simple {
    min-width: calc(550 * var(--hd));
    box-shadow: var(--box-shadow);
    .@{searchAdvancedClsPrefix}-layout .@{formItemClsPrefix} {
      padding: var(--v-spacing-xs) 0;
    }
    .@{searchAdvancedClsPrefix}-condition {
      border-bottom: 0;
      padding-top: var(--v-spacing-md);
    }
    .@{searchAdvancedClsPrefix}-footer {
      text-align: right;
      padding-right: var(--h-spacing-lg);
      .@{btnClsPrefix}:last-child {
        margin-right: 0;
      }
    }
    .@{formSwitchClsPrefix}-BROWSERWINDOW-PC {
      .ui-browser-search-advance .ui-searchAdvanced-condition {
        max-height: calc(250 * var(--hd));
      }
    }
  }
  /* -----------------------------  保存常用筛选，日期范围选择提示信息  -------------------------*/
  &-rangepickerfield {
    .@{confirmClsPrefix}-body {
      display: block;
      padding: var(--v-spacing-lg) var(--h-spacing-lg);
    }
    &-top {
      padding-bottom: calc(10 * var(--hd));
      font-size: var(--font-size-12);
      color: var(--main-fc);
    }
    &-table-operate {
      .@{checkboxClsPrefix} {
        margin-right: 0;
      }
      .@{checkboxClsPrefix}-left {
        align-self: baseline;
      }
      &-tip {
        color: var(--secondary-fc);
        font-size: var(--font-size-12);
        padding-top: var(--v-spacing-xs);
        text-overflow: ellipsis;
        word-break: break-word;
        white-space: break-spaces;
      }
    }
  }
  /* 必填提示 */
  &-errorTip .@{formSwitchClsPrefix}-showError {
    padding-top: calc(7 * var(--hd));
  }
}

@media screen and (max-width: 1440px) {
  .@{searchAdvancedClsPrefix}-layout {
    .@{formClsPrefix} {
      .@{formItemClsPrefix} {
        padding-right: calc(34 * var(--hd));
        padding-left: calc(26 * var(--hd));
      }
      &-row .ui-layout-col-12:nth-child(odd) {
        .@{formItemClsPrefix} {
          padding-right: calc(34 * var(--hd));
        }
      }
      &-row .ui-layout-col-12:nth-child(even) {
        .@{formItemClsPrefix} {
          padding-left: calc(26 * var(--hd));
        }
      }
    }
  }
}

// 展示默认条件，隐藏其他条件面板
.@{searchAdvancedClsPrefix}-condtion-showDefaultCondition {
  .@{formClsPrefix}-searchGroup-custom {
    display: none;
  }
  .@{searchAdvancedClsPrefix}-layout-noGroup {
    padding-bottom: 0;
    border: 0;
  }
}

.@{searchAdvancedClsPrefix}-browserConfig-wrapper {
  .@{formItemClsPrefix}-delete {
    display: none;
  }
}

/* 含下级 */
.@{searchAdvancedClsPrefix}-browser-including-sub {
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 100%;
  .@{checkboxClsPrefix} {
    // padding-top: calc(6 * var(--hd));
    min-height: calc(30 * var(--hd));
    .@{checkboxClsPrefix}-inner {
      border-radius: var(--checkbox-border-radius);
    }
  }
  .@{browserClsPrefix} {
    padding-right: var(--h-spacing-sm);
  }
}

/* 隐藏条件关系字段 */
.@{searchAdvancedClsPrefix}-condition-container.hideCondition {
  .@{searchAdvancedClsPrefix}-layout .@{formItemClsPrefix}-wrapper-condition {
    padding-left: 0;
  }
  .@{searchAdvancedClsPrefix}-layout .@{formItemClsPrefix}-wrapper .@{formItemClsPrefix}-condition {
    display: none;
  }
}
.@{searchAdvancedClsPrefix}-layout.hideCondition {
  .@{formItemClsPrefix}-wrapper-condition {
    padding-left: 0;
  }
  .@{formItemClsPrefix}-wrapper .@{formItemClsPrefix}-condition {
    display: none;
  }
}

/* 镜像 */
.@{searchAdvancedClsPrefix}-simple.needRTL {
  .@{browserClsPrefix}-window .@{browserClsPrefix}-search-advance-panel-wrap {
    .@{formClsPrefix}.needRTL .@{searchGroupClsPrefix}-left {
      text-align: right;
    }
  }
}