.@{selectClsPrefix} {
  display: inline-block;
  position: relative;
  outline: none;
  cursor: pointer;
  vertical-align: middle;

  input {

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &.is-disabled {
    cursor: not-allowed;
  }

  .@{triggerClsPrefix} {
    display: block;
  }

  &-readonly {
    font-size: var(--font-size-sm);
    color: var(--main-fc);
    cursor: default;

    .ui-tag {
      max-width: calc(100% - 4px);
      overflow: hidden;
    }

    .@{selectClsPrefix}-input-selected {
      display: block;
    }
  }

  &-readonly-readOnlyLineHeightStandard {
    .form-pc-readOnlyLineHeightStandard
  }

  &-readonly-readOnlyLineHeightStandard.ui-select-readonly {
    .ui-tag {
      height: auto;
    }

    .ui-tag .ui-tag-text{
      line-height: var(--form-pc-readonly-line-height);
    }

    // 兼容多选样式下的
    .ui-select-input-tags-group.is-multiple .ui-select-input-tag {
      height: auto;
      line-height: var(--form-pc-readonly-line-height); 
    }
  
  }

  &-readonly-selectedWrap {
    >span {
      white-space: unset;
    }

    .selectedWrap-readyOnly
  }

  &-readonly {

    &.@{selectClsPrefix}-readonly-showwrap {
      min-height: calc(30 * var(--hd));
      display: flex;
      align-items: center;
    }

    &.@{selectClsPrefix}-readonly-selectedWrap {

      .ui-tag {
        height: auto;

        .ui-tag-text {
          line-height: calc(20 * var(--hd));
          white-space: unset;
          text-align: left;
        }

        .ui-select-input-selected {
          white-space: unset;
        }
      }

      .ui-select-input-tags-group {
        &.is-multiple {
          .ui-select-input-tag {
            height: auto;

            span {
              white-space: unset;
            }
          }
        }
      }
    }

    &.@{selectClsPrefix}-readonly-selectedWrap.ui-select-readonly-readOnlyLineHeightStandard {
      .ui-tag {
        .ui-tag-text {
          line-height: var(--form-pc-readonly-line-height);
        }
      }
    }
  }

  &-readonly-ellipsis {
    .selectedWrap-ellipsis
  }

  &-search-input {
    flex: 1 1 auto;
    padding: 0;
    border: none;
    outline: none;
    overflow: hidden;
    background-color: var(--bg-white);
    font-size: var(--select-font-size);
    transition: var(--transition-all);
    height: calc(var(--input-height) - var(--select-input-padding) * 2);
    line-height: calc(var(--input-height) - var(--select-input-padding) * 2);

    &::placeholder {
      color: var(--input-hover-placeholder-color);
      font-size: var(--font-size-sm);
    }

  }

  &-input-arrow {
    display: inline;
    color: var(--secondary-fc);

    .@{iconClsPrefix}-wrapper {
      vertical-align: middle;
    }
  }

  &-input-selected {
    margin-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.ui-select-single-multiple-selected {
      margin-bottom: calc(4 * var(--hd));
    }
  }

  &-tooltip {
    // margin: var(--v-spacing-xs) var(--h-spacing-md);
    padding: var(--v-spacing-sm) var(--h-spacing-sm);
  }

  .ui-input-wrap .ui-tag {
    line-height: 1;
    overflow: hidden;
    margin-right: calc(4 * var(--hd));
  }

  &.is-multiple .@{selectClsPrefix}-input {
    padding-bottom: 0;

    &.is-empty {
      padding-bottom: var(--select-input-padding);
    }
  }

  &.is-should-expand {
    .@{selectClsPrefix}-input {
      max-width: 100%;
    }
  }

  &.is-focus-expand {
    transition: var(--transition-w);

    .@{selectClsPrefix}-input {
      transition: var(--transition-w);
    }
  }

  &.is-transitioning {
    &.is-position {
      position: absolute;
      z-index: 100;
      left: 0;
    }

    .@{selectClsPrefix}-input {
      max-width: 100%;
    }
  }

  // 单多选下划线
  &-single-underline {
    margin: calc(4 * var(--hd)) calc(16 * var(--hd));
    border-bottom: var(--border-width) solid var(--diviling-line-color);
  }

  &-input-selected {
    &[data-rtl='true'] {
      display: inline-block;
    }
    &-extra {
      &.need-ltr {
        transform: scaleX(1);
        direction: ltr;
      }
    }
  }
  &-extra {
    &.need-ltr {
      transform: scaleX(1);
      direction: ltr;
    }
  }
}

.@{selectClsPrefix}-input {
  flex: 1 1 auto;
  overflow: hidden;
  align-items: flex-start;
  padding-top: var(--select-input-padding);
  padding-bottom: var(--select-input-padding);
  min-width: calc(60 * var(--hd));
  max-width: calc(250 * var(--hd));

  .@{inputClsPrefix}-suffix-wrap .@{inputClsPrefix}-suffix {
    flex: 0 0 auto;
  }

  &:hover {
    cursor: pointer;

    &-placeholder {
      color: var(--input-hover-placeholder-color);
      font-size: var(--font-size-sm);
    }
  }

  &.is-disabled {
    cursor: not-allowed;
    color: var(--select-disabled-color);
    background-color: var(--select-disabled-bg);
    border-color: var(--select-disabled-border-color);
  }

  &-wrap {
    font-size: 0;
    /* 修复下拉选项内容为空时，行高问题 */

    &.is-allow-clear {

      .ui-input-clear {

        display: none;
      }

      &.is-has-value:hover {
        .ui-select-input-arrow {
          display: none;
        }

        .ui-input-clear {
          font-size: 0;
          padding: calc(2 * var(--hd)) 0;
          display: block;
        }
      }
    }
  }

  &-tags-group {
    flex: 1;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    max-height: calc(var(--input-height) + var(--select-tag-height) * 3 + 2 * var(--v-spacing-xs));

    .@{scrollerClsPrefix}__wrap {
      overflow: auto;
    }

    &.is-multiple {
      .@{selectClsPrefix}-input-tag {
        display: inline-flex;
        align-items: center;
        max-width: calc(100% - 5 * var(--hd));
        padding: 0 var(--h-spacing-xs) 0 var(--h-spacing-md);
        margin-bottom: var(--v-spacing-xs);
        margin-right: calc(var(--hd) * 5);
        border: var(--border-solid);
        border-radius: var(--border-radius-xs);
        background: var(--select-tag-bc);
        color: var(--select-tag-font-color);
        height: var(--select-tag-height);
        line-height: var(--select-tag-height);
        font-size: var(--select-tag-fs);

        >span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1 1 auto;
        }

        >.@{iconClsPrefix} {
          flex: 0 0 auto;
          color: var(--select-tag-color);
        }

        &.is-tag {
          border: none;
          color: white;

          .ui-icon-svg {
            color: white;
          }

          &.is-black {
            color: black;

            .ui-icon-svg {
              color: black;
            }
          }

          &.is-default {
            color: var(--tag-default-font-color);
            background-color: var(--tag-default-bg-color);

            .ui-icon-svg {
              color: var(--select-tag-color);
            }
          }
        }

        .@{iconClsPrefix} {
          margin-left: var(--h-spacing-sm);
          transform: scale(0.8);
        }
      }

      .ui-scroller__wrap {
        max-height: calc(var(--input-height) + var(--select-tag-height) * 3 + 2 * var(--v-spacing-xs));
      }
    }

    &.is-tag {
      padding: 0 var(--h-spacing-sm) 0 var(--h-spacing-md);
    }
  }

  &-tag {
    display: inline-block;
    box-sizing: border-box;
  }

  &-placeholder {
    flex: 1;
    min-height: calc(var(--input-height) - var(--select-input-padding) * 2);
    color: var(--select-placeholder-color);
    font-size: var(--font-size-sm);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &[data-rtl='true'] {
      display: inline-flex;
    }

    &::placeholder {
      color: var(--input-hover-placeholder-color);
      font-size: var(--font-size-sm);
    }
  }
}

.@{selectClsPrefix}-dropdown {
  margin: var(--v-spacing-sm) 0;
  padding-top: var(--v-spacing-md);
  padding-bottom: var(--v-spacing-md);
  background-color: var(--select-dropdown-bc);
  border: var(--border-solid);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow);
  z-index: 100;
  white-space: nowrap;

  // max-width: var(--select-dropdown-max-width);
  // min-width: var(--select-dropdown-min-width);
  &-search-input {
    margin: 0 var(--h-spacing-sm) var(--v-spacing-sm);
  }

  .@{inputClsPrefix} {
    width: 0;
    flex: 1 1 auto;
  }

  .@{inputClsPrefix}-suffix {
    flex: initial;
  }
}

.@{selectClsPrefix}-option-list {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: calc(10 * var(--select-option-height));
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .@{selectClsPrefix}-option-list-empty {
    font-size: var(--font-size-sm);
    color: var(--secondary-fc);
    // letter-spacing: -0.39px;
    line-height: calc(18 * var(--hd));
    padding-left: var(--h-spacing-lg);
    user-select: none;
    cursor: not-allowed;
  }

  .@{selectClsPrefix}-group-title {
    padding: var(--v-spacing-sm) var(--h-spacing-lg);
    font-size: var(--font-size-sm);
    color: var(--regular-fc);
  }

  .@{selectClsPrefix}-group-divide {
    height: 0;
    width: calc(100% - var(--h-spacing-lg) * 2);
    margin: var(--v-spacing-md) var(--h-spacing-lg);
    border-bottom: var(--border-width) solid var(--diviling-line-color);
  }

  .ui-scroller__wrap {
    max-height: calc(10 * var(--select-option-height));
  }
}


.@{selectClsPrefix}-is-xinchuang {
  .@{selectClsPrefix}-option-content {
    line-height: 1.2;
  }
}

.@{selectClsPrefix}-option {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: var(--select-option-height);
  box-sizing: border-box;
  white-space: normal;
  padding: var(--v-spacing-sm) var(--h-spacing-lg);
  font-size: var(--select-option-fs);
  color: var(--select-option-color);
  transition: var(--transition-color), var(--transition-bc), font-weight var(--transition-du) var(--transition-tf);

  &.is-active {
    color: var(--select-option-active-fc);
    font-weight: var(--font-weight-bold);
  }

  &.is-highlighted,
  &:hover {
    color: var(--primary);
    background-color: var(--select-option-highligh-bc);
  }

  &.is-disabled {
    color: var(--select-option-disabled-fc);
    pointer-events: none;
    cursor: not-allowed;
  }

  &-content {
    flex: 1 1 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.1;
    &.ui-rtl-inline {
      text-align: right;
    }
    &-extra {
      &[data-rtl='true'] {
        transform: scaleX(1);
        direction: ltr;
      }
    }
  }

  &-selectAll {
    .@{checkboxClsPrefix} {
      margin-right: 0;
    }
  }

  .@{selectClsPrefix}-checkbox {
    width: var(--font-size-base);
    height: var(--font-size-base);
    flex: 0 0 auto;
    margin-left: var(--h-spacing-sm);
    margin-right: 0;
  }

  /* 分隔线 */
  &.has-seperator {
    margin-bottom: calc(20 * var(--hd));

    &::after {
      content: '';
      position: absolute;
      bottom: calc(-10 * var(--hd));
      left: 0;
      width: calc(100% - var(--h-spacing-lg) * 2);
      border-bottom: var(--border-solid);
      margin: 0 var(--h-spacing-lg);
    }
  }

  .ui-tag {
    overflow: hidden;
  }
}

// @media screen and (max-width: 1280px) {
//   // .@{inputClsPrefix}-number .@{inputClsPrefix} {
//   //   min-width: calc(50 * var(--hd));
//   // }
//   .@{selectClsPrefix} {
//     max-width: calc(100 * var(--hd));
//   }
//   .@{textareaClsPrefix} {
//     max-width: calc(350 * var(--hd));
//   }
// }

// @media screen and (min-width: 1280px) {
//   // .@{inputClsPrefix}-number .@{inputClsPrefix} {
//   //   max-width: calc(100 * var(--hd));
//   // }
//   .@{selectClsPrefix} {
//     max-width: calc(250 * var(--hd));
//   }
//   .@{textareaClsPrefix} {
//     max-width: calc(500 * var(--hd));
//   }
// }

.weapp-ie11 {
  .ui-select {
    .ui-select-search-input {
      min-width: calc(1 * var(--hd));
    }
  }

  .ui-select-input-tags-group {
    flex: 1 1 auto;
  }
  .ui-select-input-tags-group.is-multiple .ui-select-input-tag {
    max-width: 90%;
  }

  .resize-triggers {
    width: 90%;
  }

  .ui-select-readonly {
    .ui-select-input-tags-group.is-multiple .ui-select-input-tag {
      margin-right: calc(20 * var(--hd));
      max-width: 100%;
    }

    .resize-triggers {
      width: 100%;
    }
  }
}    


.@{selectClsPrefix} {
  &-input-wrap {
    &.is-allow-clear {
      .ui-input-clear {
        .Icon-cancel {
          width: calc(14 * var(--hd));
          height: calc(14 * var(--hd));;
        }
      }
    }
  } 
}