/* --- 组件变量 --- */
:root {
  /* Layout 布局 */
  /* 栅格布局 列宽1-24格 */
  --col-1: 4.16%;
  --col-2: 8.33%;
  --col-3: 12.5%;
  --col-4: 16.66%;
  --col-5: 20.83%;
  --col-6: 25%;
  --col-7: 29.16%;
  --col-8: 33.33%;
  --col-9: 37.5%;
  --col-10: 41.66%;
  --col-11: 45.83%;
  --col-12: 50%;
  --col-13: 54.16%;
  --col-14: 58.33%;
  --col-15: 62.5%;
  --col-16: 66.66%;
  --col-17: 70.83%;
  --col-18: 75%;
  --col-19: 79.16%;
  --col-20: 83.33%;
  --col-21: 87.5%;
  --col-22: 91.66%;
  --col-23: 95.83%;
  --col-24: 100%;
  --show-btn-size: calc(10 * var(--hd));
  --show-btn-width: calc(14 * var(--hd));
  --show-btn-height: calc(36 * var(--hd));
  --show-btn-border-radius: calc(6 * var(--hd));

  /* --- Avatar 头像 --- */
  --avatar-size-lg: calc(36 * var(--hd));
  --avatar-size-md: calc(30 * var(--hd));
  --avatar-size-sm: calc(24 * var(--hd));
  --avatar-size-xs: calc(20 * var(--hd));

  /* --- Button 按钮 --- */
  --btn-font-size: var(--font-size-sm);
  /* 按钮字号 */
  --btn-font-family: var(--regular-ff);
  /* 按钮字体 */
  --btn-font-color: var(--white-fc);
  /* 按钮字体颜色 */
  --btn-font-default-color: var(--regular-fc);
  /* 默认按钮字体颜色 */
  --btn-line-height: calc(16 * var(--hd));
  /* 按钮行高 */
  --btn-padding-y: var(--v-spacing-sm);
  /* 按钮垂直内间距 */
  --btn-padding-x: var(--h-spacing-lg);
  /* 按钮水平内间距 */
  --btn-border-color: var(--border-color);
  /* 按钮边框颜色 */
  --btn-border: var(--border-width) solid var(--transparent);
  /* 按钮边框 */
  --btn-radius: var(--border-radius-xs);
  /* 通用按钮圆角 */
  --btn-circle-radius: var(--border-radius-lg);
  /* 圆角按钮 */
  --btn-hover-opacity: 0.85;
  /* 按钮鼠标划过透明度 */
  --btn-active-opacity: 1;
  /* 默认按钮划过透明度 */
  --btn-default-hover-opacity: 0.2;
  /* 按钮选中后透明度 */
  --btn-disabled-bg: var(--invalid-fc);
  /* 禁用按钮背景色 */

  --btn-lg-font-size: var(--font-size-lg);
  --btn-lg-padding-y: var(--v-spacing-md);
  --btn-lg-padding-x: var(--h-spacing-lg);

  --btn-sm-font-size: var(--font-size-sm);
  --btn-sm-padding-y: var(--v-spacing-xs);
  --btn-sm-padding-x: var(--h-spacing-sm);

  --btn-default-active-color: var(--primary);
  --btn-primary-active-bc: var(--primary);
  --btn-success-active-bc: var(--success);
  --btn-warning-active-bc: var(--warning);
  --btn-danger-active-bc: var(--danger);

  /* 按钮焦点背景色 */
  --btn-default-focus-bg: var(--primary);
  --btn-primary-focus-bg: var(--primary);
  --btn-success-focus-bg: var(--success);
  --btn-warning-focus-bg: var(--warning);
  --btn-danger-focus-bg: var(--danger);

  --btn-default-focus-color: var(--primary);

  /* 按钮悬浮背景色 */
  --btn-default-hover-bg: var(--primary);
  --btn-primary-hover-bg: var(--primary);
  --btn-success-hover-bg: var(--success);
  --btn-warning-hover-bg: var(--warning);
  --btn-danger-hover-bg: var(--danger);

  --btn-default-hover-color: var(--primary);

  /* 按钮禁用背景色 */
  --btn-default-disable-bg: var(--disable);
  --btn-primary-disable-bg: var(--disable);
  --btn-success-disable-bg: var(--disable);
  --btn-warning-disable-bg: var(--disable);
  --btn-danger-disable-bg: var(--disable);

  /* 按钮禁用文字、边框颜色 */
  --btn-default-disable-color: var(--base-white);
  --btn-default-disable-border-color: var(--transparent);

  --btn-link-fc: var(--primary);

  /* Input 文本框 */
  --input-height: calc(28 * var(--hd));
  --input-line-height: calc(var(--input-height) - var(--input-padding-y) * 2);
  --input-font-family: var(--regular-ff);
  --input-font-size: var(--font-size-sm);
  --input-font-weight: var(--font-weight-base);

  --input-bg: var(--bg-white);
  --input-disabled-color: var(--secondary-fc);
  --input-disabled-bg: var(--label-bc);
  --input-disabled-border-color: var(--border-color);

  --input-padding-x: var(--h-spacing-md);
  --input-padding-y: var(--v-spacing-xs);

  --input-color: var(--main-fc);
  --input-text-color: var(--black-fc);
  --input-border-color: var(--border-color);
  --input-border-width: var(--border-width);
  --input-border-radius: var(--border-radius-xs);
  --input-shadow: none;

  --input-hover-border-color: var(--invalid);
  --input-hover-placeholder-color: var(--secondary-fc);

  --input-focus-border-color: var(--primary);
  --input-focus-shadow-color: var(--primary);
  --input-focus-shadow-blur: calc(3 * var(--hd));
  --input-focus-shadow: 0 0 var(--input-focus-shadow-blur) var(--input-focus-shadow-color);

  --input-placeholder-color: var(--placeholder-fc);

  --input-group-addon-color: var(--input-color);
  --input-group-addon-bg: var(--label-bc);
  --input-group-addon-border-color: var(--input-border-color);

  --input-icon-width: calc(24 * var(--hd));

  --input-color-icon: var(--placeholder-fc);
  /* input clear icon 的背景色*/
  --input-color-icon-tap: var(--primary);

  --line-height-paragraph: 1.5;

  /* Select 下拉框 */
  /* base*/
  --select-font-size: var(--input-font-size);
  --select-font-bold: 600;
  /* input*/
  --select-input-padding: var(--v-spacing-xs);
  --select-disabled-bg: var(--input-disabled-bg);
  --select-disabled-color: var(--invalid-fc);
  --select-disabled-border-color: var(--input-disabled-border-color);
  /* placeholder*/
  --select-placeholder-color: var(--input-placeholder-color);
  /* dropdown*/
  --select-dropdown-bc: var(--bg-white);
  --select-dropdown-min-width: calc(120 * var(--hd));
  --select-dropdown-max-width: calc(300 * var(--hd));
  /* option*/
  --select-option-fs: var(--font-size-sm);
  --select-option-color: var(--main-fc);
  --select-option-height: calc(30 * var(--hd));
  --select-option-active-fc: var(--primary);
  --select-option-highligh-bc: var(--label-bc);
  --select-option-disabled-fc: var(--invalid-fc);
  /* tag*/
  --select-tag-height: var(--tag-size-sm);
  --select-tag-color: var(--secondary-fc);
  --select-tag-font-color: var(--main-fc);
  --select-tag-bc: var(--modal-top-bc);
  --select-tag-fs: var(--font-size-sm);

  /* --- checkbox --- */
  --checkbox-border-color: #bbbbbb;
  /* checkbox 边框颜色 */
  --checkbox-disabled-border-color: #999999;
  /* checkbox 禁用边框颜色 */
  --checkbox-disabled-bg-color: #e5e5e5;
  /* checkbox 禁用背景颜色 */

  --checkbox-default-background: var(--bg-white);
  /* checkbox 默认背景颜色 */
  --checkbox-line-height: calc(18 * var(--hd));
  /* checkbox 行高 */
  --checkbox-active-border-color: var(--primary);
  /* checkbox 鼠标选中边框色 */
  --checkbox-active-background-color: var(--primary);
  /* checkbox 鼠标选中背景色 */
  --checkbox-size-base: calc(14 * var(--hd));
  --checkbox-check-width: calc((var(--checkbox-size-base) / 14) * 5 * var(--hd));
  /* checkbox inner 宽度 */
  --checkbox-check-height: calc((var(--checkbox-size-base) / 14) * 8 * var(--hd));
  /* checkbox inner 高度 */
  --checkbox-cursor: pointer;
  /* checkbox 默认鼠标状态 */
  --checkbox-border-radius: calc(2 * var(--hd));

  --checkbox-inner-width: calc(4 * var(--hd));
  --checkbox-inner-height: calc(8 * var(--hd));
  --checkbox-indeterminate-inner-width: calc(10 * var(--hd));
  /* checkbox 半选宽度 */
  --checkbox-indeterminate-inner-height: calc(2 * var(--hd));
  /* checkbox 半选高度 */
  --checkbox-disabled-cursor: not-allowed;
  /* checkbox 禁用鼠标状态 */
  --checkbox-font-size: var(--font-size-sm);
  /* checkbox 字体大小 */
  --checkbox-font-color: var(--main-fc);
  /* checkbox 字体颜色 */
  --checkbox-disabled-font-color: var(--secondary-fc);
  /* checkbox 禁用字体颜色 */

  /* --- radio --- */
  --radio-border-color: var(--checkbox-border-color);
  --radio-disabled-border-color: var(--checkbox-disabled-border-color);
  /* radio 禁用边框颜色 */
  --radio-disabled-bg-color: var(--checkbox-disabled-bg-color);
  /* radio 禁用背景颜色 */

  --radio-cursor: pointer;
  /* radio 默认鼠标状态 */
  --radio-active-border-color: var(--primary);
  --radio-default-background: var(--bg-white);
  --radio-active-background-color: var(--bg-white);
  --radio-active-inner-background-color: var(--primary);
  --radio-size-base: calc(14 * var(--hd));
  --radio-check-width: calc(14 * var(--hd));
  --radio-check-height: calc(14 * var(--hd));
  --radio-disabled-font-color: var(--secondary-fc);
  /* radio 禁用字体颜色 */
  --radio-disabled-cursor: not-allowed;
  /* radio 禁用鼠标状态 */
  --radio-font-size: var(--font-size-sm);
  /* radio 字体大小 */
  --radio-font-color: var(--main-fc);
  /* radio 字体颜色 */

  /* Dialog 弹框 */

  /* 弹框zIndex*/
  --dialog-zIndex: 1050;

  /* 骨架屏 */
  --skeleton-inner-first-color: #f6f6f6;
  --skeleton-inner-second-color: #fafafa;

  /* 弹框边框*/
  --dialog-box-shadow: 0 0 4px 0 rgba(109, 109, 109, 0.5);
  --dialog-box-shadow-x: calc(-2 * var(--hd));
  --dialog-box-shadow-y: calc(4 * var(--hd));
  --dialog-box-shadow-right: -2px 0 4px 0 rgba(109, 109, 109, 0.5);

  /* Dialog放大动画*/
  --dialog-scale-transition: all 0.3s ease-in-out 0s;
  --dialog-scale-height-transition: height 0.3s ease-in-out 0s;
  --dialog-scale-width-transition: width 0.3s ease-in-out 0s;
  --dialog-icon-background: #ebebeb;
  /* Confirm*/
  --confirm-width: calc(368 * var(--hd));

  /* Message*/
  --message-info-icon: #faad14;
  --message-error-icon: #ff4d4f;
  --message-success-icon: #52c41a;

  /* radius*/

  --dialog-border-radius: calc(6 * var(--hd));
  --dialog-animation-time: 0.5s;
  --dialog-layout-animation-time: 0.3s;

  /* Title */
  --title-icon-border: calc(1 * var(--hd)) solid var(--primary);
  --title-icon-width: calc(28 * var(--hd));

  /* Trigger */
  --trigger-transtion: all 0.3s ease-in-out 0s;
  --trigger-transtion-height: height 0.3s ease-in-out 0s;
  --trigger-transtion-padding: padding 0.3s ease-in-out 0s;

  /* Icon 弹框 */
  --icon-default-size: calc(14 * var(--hd));
  --icon-image-default-width: calc(20 * var(--hd));
  --icon-image-default-height: calc(20 * var(--hd));

  /* Map 地图 */
  --map-border-color: #b2b2b2;
  /* Menu */
  --menu-item-new-color: #555;
  --menu-item-height: calc(35 * var(--hd));
  --menu-item-line-width: calc(4 * var(--hd));
  --menu-item-hover-bc: var(--label-bc);
  /* type为line竖向宽度*/
  --menu-tab-left-width: calc(120 * var(--hd));
  /* type为menu的宽度*/
  --menu-inline-wdith: calc(210 * var(--hd));
  /* type为menu 收起来时候的宽度*/
  --menu-vertical-width: calc(55 * var(--hd));
  /* type为menuborder宽度*/
  --menu-border-width: calc(4 * var(--hd));
  /* 内容最大宽度*/
  --menu-item-content-width: calc(96 * var(--hd));
  /* 弹层最小宽度*/
  --menu-trigger-width-min: calc(80 * var(--hd));
  /* 弹层最大宽度*/
  --menu-trigger-width-max: calc(200 * var(--hd));
  /* 下拉框最大高度*/
  --menu-select-height: calc(316 * var(--hd));
  /* 头部下拉框的高度*/
  --menu-select-top-height: calc(30 * var(--hd));
  /* 滚动长度*/
  --menu-scroll-wdith: calc(99999 * var(--hd));
  /* 前图标占位*/
  --menu-arrow: calc(32 * var(--hd));
  /* 后图标占位*/
  --menu-arrow-right: calc(32 * var(--hd) + (41 * var(--hd)));
  /* tab margin*/
  --menu-tab-item-margin-right: calc(24 * var(--hd));
  /* 前后图标间距*/
  --menu-arrow-margin-top: calc(-2 * var(--hd));
  /* 前后图标间距*/
  --menu-arrow-top: calc(2 * var(--hd));
  /* 右侧+按钮*/
  --menu-icon-height: calc(18 * var(--hd));
  /* x按钮*/
  --menu-close-width: calc(12 * var(--hd));
  /* 右侧内容padding-left*/
  --menu-item-extra-margin: calc(20 * var(--hd));
  --menu-secondtab-item-height: calc(16 * var(--hd));
  --menu-secondtab-new-item-height: calc(24 * var(--hd));
  --menu-trigger-max-height: calc(300 * var(--hd));

  /* at */
  --at-container-size: calc(215 * var(--hd));
  --at-item-size: calc(30 * var(--hd));

  /* RichText */
  --richtext-icon-width: calc(16 * var(--hd));
  --richtext-icon-color: #6a6f8a;
  /* 色号不随主题色更改*/
  --richtext-icon-disabled-color: #bbbbbb;
  /* 色号不随主题色更改*/
  --richtext-icon-bg-hover: #e6e6e6;
  --richtext-icon-bg-active: #e6e6e6;
  --richtext-separator-border-color: var(--diviling-line-color);
  --richtext-icon-padding: calc(4 * var(--hd));
  --richtext-icon-margin: calc(4 * var(--hd));
  --richtext-icon-margins: calc(2 * var(--hd));
  --richtext-icon-arrow-width: calc(10 * var(--hd));
  --richtext-separent-margin: calc(6 * var(--hd)) calc(4 * var(--hd)) calc(6 * var(--hd)) 0;
  /* 表情弹框层级 */
  --richtext-emoticon-zIndex: 9996;

  /* Slider */
  --slider-primary-color: var(--primary);
  --slider-height: calc(14 * var(--hd));
  --slider-bg: #e9e9e9;

  /* Popover */
  --popover-background: #fff;
  --popover-arrow-width: calc(6 * var(--hd));
  --popover-tooltip-background: #555;
  --popover-arrow-left-position: calc(-2 * 6 * var(--hd) - var(--hd));
  --popover-arrow-right-position: calc(-6 * var(--hd) + var(--hd));
  --popover-arrow-top-position: calc(50% - 6 * var(--hd));

  /* --- icon 图标--- */
  --icon-size-xss: calc(12 * var(--hd));
  --icon-size-xs: calc(14 * var(--hd));
  --icon-size-s: calc(16 * var(--hd));
  --icon-size-sm: calc(18 * var(--hd));
  --icon-size-md: calc(20 * var(--hd));
  --icon-size-lg: calc(22 * var(--hd));
  --icon-image-default-width: calc(20 * var(--hd));
  --icon-image-default-height: calc(20 * var(--hd));
  --icon-yellow: #ff8400;
  --icon-blue: #0067ff;
  --icon-indigo: #218af8;
  --icon-green: #00a643;
  --icon-red: #fa303d;

  /* --- switch --- */
  /* switch 的 height 和 width*/
  --switch-with-sm: calc(32 * var(--hd));
  /* 开关宽度-小 */
  --switch-height-sm: calc(16 * var(--hd));
  /* 开关高度-小 */
  --switch-with-md: calc(36 * var(--hd));
  /* 开关宽度-中 */
  --switch-height-md: calc(20 * var(--hd));
  /* 开关高度-中 */
  --switch-with-lg: calc(44 * var(--hd));
  /* 开关宽度-大 */
  --switch-height-lg: calc(24 * var(--hd));
  /* 开关高度-大 */
  /* inner 大小*/
  --switch-inner-size-sm: calc(14 * var(--hd));
  --switch-inner-size-md: calc(18 * var(--hd));
  --switch-inner-size-lg: calc(22 * var(--hd));
  /* 打开状态的定位(left)*/
  --switch-inner-checked-left-sm: calc(100% - 14 * var(--hd) - var(--switch-inner-left));
  --switch-inner-checked-left-md: calc(100% - 18 * var(--hd) - var(--switch-inner-left));
  --switch-inner-checked-left-lg: calc(100% - 22 * var(--hd) - var(--switch-inner-left));
  /* innner 相对于上/左平移数*/
  --switch-inner-left: var(--hd);
  --switch-inner-top: var(--hd);

  --switch-default-bc: #cccccc;
  /* switch 默认背景色 */
  --switch-default-border: var(--hd) var(--border-solid) var(--switch-default-bc);
  /* switch 边框样式 */

  --switch-checked-bc: var(--primary);
  /* switch 选中时背景色 */
  --switch-checked-border: var(--hd) var(--border-solid) var(--primary);
  /* switch 选中时边框样式 */

  --switch-inner-color: var(--base-white);
  /* inner 颜色 */
  --switch-inner-font-size: var(--font-size-sm);
  /* inner 颜色 */
  --switch-disable-opacity: 0.5;
  /* 禁用后透明度 */
  --switch-border-radius: calc(53 * var(--hd));
  /* 开关圆角 */

  /* switch 存在 checkedChildren 时的设置*/
  --switch-label-margin-sm: calc(8 * var(--hd));
  --switch-label-margin-inner-sm: calc(14 * var(--hd) + 8 * var(--hd) - (2 * var(--hd)));
  --switch-label-margin-md: calc(8 * var(--hd));
  --switch-label-margin-inner-md: calc(18 * var(--hd) + 8 * var(--hd) - (2 * var(--hd)));
  --switch-label-margin-lg: calc(8 * var(--hd));
  --switch-label-margin-inner-lg: calc(22 * var(--hd) + 8 * var(--hd) - (4 * var(--hd)));

  /* help */
  --help-font-default: calc(8 * var(--hd));
  --help-icon-size-default: calc(17 * var(--hd));
  --help-icon-margin-default: var(--h-spacing-sm);
  --help-line-height: calc(18 * var(--hd));
  --help-line-height1: calc(11 * var(--hd));
  /* 行高 */
  --help-color-light: #666;
  --help-background-color-light: #fff8c3;

  /* Pagination */
  --pagination-item-size: calc(22 * var(--hd));
  --pagination-item-lineheight: calc(20 * var(--hd));
  --pagination-icon-size: calc(16 * var(--hd));
  --pagination-input-size: calc(44 * var(--hd));
  /* --pagination-input-height: calc(22 * var(--hd));*/

  /* --- Tag 标签 --- */
  --tag-font-size: var(--font-size-sm);
  /* 小字号 */
  --tag-font-color: var(--regular-fc);
  /* 字体颜色 */
  --tag-font-family: var(--regular-ff);
  /* 字体 */
  --tag-border: var(--border-width) solid var(--transparent);
  /* 边框 */
  --tag-size-lg: calc(30 * var(--hd));
  --tag-size-md: calc(24 * var(--hd));
  --tag-size-sm: calc(20 * var(--hd));
  --tag-padding-y-xs: calc(1 * var(--hd));
  /* 1px 小垂直内间距 */
  --tag-padding-y-sm: calc(4 * var(--hd));
  /* 4px 垂直内间距 */
  --tag-padding-y-lg: calc(7 * var(--hd));
  /* 7px 垂直内间距 */
  --tag-padding-x: calc(10 * var(--hd));
  /* 10px 水平内间距 */
  --tag-disabled-bg: var(--invalid-fc);
  /* 禁用按钮背景色 */

  --tag-default-border-color: #e5e5e5;
  --tag-default-bg-color: #f5f5f5;
  --tag-default-font-color: #666666;

  --tag-primary-border-color: #b5dfff;
  --tag-primary-bg-color: #e9f7ff;
  --tag-primary-font-color: #5d9cec;

  --tag-success-bg-color: #ddfff5;
  --tag-success-border-color: #9cead4;
  --tag-success-font-color: #40d8ad;

  --tag-danger-bg-color: #ffdfe0;
  --tag-danger-border-color: #ffc1c3;
  --tag-danger-font-color: #ff666a;

  --tag-warning-bg-color: #fff5db;
  --tag-warning-border-color: #ffde8a;
  --tag-warning-font-color: #ffcd50;

  --tag-solid-font-color: #fff;
  --tag-disabled-opacity: 0.5;

  /* --- rate --- */
  --rate-option-size: calc(20 * var(--hd));
  /* rate option 默认字体大小 */
  --rate-active-color: #ff7023;
  /* 选中颜色大小 */
  --rate-default-color: #ebebeb;
  --rate-disabled-color: #d9d9d9;
  --rate-option-margin-right: calc(4 * var(--hd));
  /* rate option 间距 */

  /* --- form --- */
  --form-line-border: calc(1 * var(--hd)) solid #ebebeb;
  --form-line-container-border: calc(1 * var(--hd)) solid #dedede;
  --form-item-v-spacing: calc(8 * var(--hd));
  --form-item-condition-width: calc(73 * var(--hd));
  --form-item-icon-color: #cecece;
  --form-item-required: #ff666a;
  --form-item-required-new: var(--danger);
  --form-item-required-icon-size: calc(10 * var(--hd));
  --form-item-error-background: #ffdadc;
  --form-item-error-border: calc(1 * var(--hd)) solid var(--form-item-required);
  --form-item-zIndex: 1;
  --form-item-padding-module: calc(5 * var(--hd)) var(--h-spacing-lg);
  /* --form-item-padding-module: var(--v-spacing-md) var(--h-spacing-lg); */
  /* 表单模板字段间距*/
  --form-item-border-module: calc(1 * var(--hd)) solid var(--diviling-line-color);
  /* 表单模板字段间距*/
  --form-item-line-height: calc(30 * var(--hd));
  --form-item-label-fixedWidth: calc(116 * var(--hd));
  --form-item-delete-icon-size: calc(24 * var(--hd));

  /* --- searchAdvanced--- */
  --searchAdvanced-border: calc(1 * var(--hd)) solid var(--diviling-line-color);
  --searchAdvanced-form-item-icon-width: calc(30 * var(--hd));
  --searchAdvanced-form-item-icon-color: #979797;
  --searchAdvanced-quikSearchPanel-width: calc(392 * var(--hd));
  --searchAdvanced-quikSearchPanel-line-height: calc(18 * var(--hd));
  /* 按钮行高 */
  --searchAdvanced-historySearch-icon-color: #737373;
  --searchAdvanced-historySearch-icon-size: calc(16 * var(--hd));
  --searchAdvanced-historySearch-icon-position: calc(13 * var(--hd));
  --searchAdvanced-input-stuffix-color: #aaa;

  /* --- searchGroup --- */

  --searchGroup-border: calc(1 * var(--hd)) solid var(--diviling-line-color);
  --searchGroup-form-item-icon-color: #979797;

  /* --- List --- */
  --list-color: #868686;

  /* ---Upload --- */
  --upload-outline: var(--border-color) dashed calc(1 * var(--hd));
  --upload-outline-hover: var(--primary) dashed calc(1 * var(--hd));
  --upload-disabled-backgroud: var(--label-bc);
  --upload-outline-color: var(--border-color);
  --upload-signtip-color: #80ac71;
  --upload-list-item-height: calc(30 * var(--hd));
  --upload-errortip-color: #ff4d4f;
  --upload-image-bgcolor: #ddd;
  --upload-border-color: #c5c5c5;

  /* --- Board --- */
  --board-bg-color: var(--primary);
  --board-list-bg-color: #ebecf0;
  --board-list-width: calc(250 * var(--hd));
  --board-list-min-height: calc(40 * var(--hd));

  /* --- Browser --- */
  --browser-search-input-height: calc(30 * var(--hd));
  --browser-search-input-radius: calc(15 * var(--hd));
  --browser-dropdown-min-width: calc(150 * var(--hd));
  --browser-dropdown-max-height: calc(300 * var(--hd));
  --browser-selected-max-height: calc(var(--browser-search-input-height) * 5);
  --browser-dialog-left-panel-width: calc(220 * var(--hd));
  --browser-dialog-content-width: calc(130 * var(--hd));
  --browser-search-panel-max-height: calc(300 * var(--hd));

  /* ---Spin ---*/
  --spin-size--sm: calc(15 * var(--hd));
  --spin-size--md: calc(26 * var(--hd));
  --spin-size--lg: calc(30 * var(--hd));

  /* ---- HrmCard -----*/
  --hrm-card-panel-width: calc(451 * var(--hd));
  --hrm-card-hrm-sex-bg-f: #ef8bba;
  --hrm-card-hrm-sex-bg-m: #5d9cec;
  --hrm-card-workstatus-iconBgColor: #98a8b3;
  --hrm-card-buttons-iconBgColor: #979797;
  --hrm-card-border: calc(1 * var(--hd)) solid var(--diviling-line-color);
  --hrm-card-opacity: 0.6;
  --hrm-card-bg-image: url('../components/hrm-card/static/bg.jpg');

  /* ----- CardDetailForm ----- */
  --card-detail-form-title-avatar-size: calc(36 * var(--hd));
  --card-detail-form-title-input-bgColor: var(--bg-white);
  --card-detail-form-title-input-border: calc(1 * var(--hd)) solid #e5e5e5;
  --card-detail-form-title-input-placeholder-color: #bbbbbb;
  --card-detail-more-fc: #888888;

  /* ---Comment---- */
  --comment-margin: calc(10 * var(--hd));
  --comment-img-size: calc(150 * var(--hd));
  --comment-popup-shadow: 0 var(--hd) calc(6 * var(--hd)) 0 rgba(0, 0, 0, 0.12);
  --comment-like-height: calc(56 * var(--hd));
  --comment-padding: calc(16 * var(--hd));
  --comment-word-padding: calc(4 * var(--hd));
  --comment-emoji-size: calc(25 * var(--hd));
  --comment-menu-padding: calc(16 * var(--hd));
  --comment-menu-border: calc(1 * var(--hd)) solid var(--border-color);
  --comment-text-height: calc(18 * var(--hd));
  --comment-option-height: calc(33 * var(--hd));
  --comment-font-red: #ff0000;
  --comment-link-color: var(--primary);
  --comment--menu-card-bgcolor: #ffffff;
  --comment--upload-item-heigh: calc(18 * var(--hd));
  --comment-hover-bgcolor: #f5f5f5;
  --comment-grey-border-color: #ebebeb;
  --comment-grey-bg-color: var(--bg-base);
  --comment-grey-border: var(--border-width) solid #ebebeb;

  /* --- steps --- */
  --steps-icon-width: calc(30 * var(--hd));
  --steps-icon-height: calc(30 * var(--hd));
  --steps-icon-height-half: calc(15 * var(--hd));
  --steps-icon-width-half: calc(15 * var(--hd));
  --steps-margin: calc(10 * var(--hd));
  --steps-border-color: #e5e5e5;
  --steps-line-color: #ebebeb;
  --steps-border: var(--hd) solid var(--steps-border-color);
  --steps-title-bg: #f0f0f0;
  --steps-title-top: calc(15 * var(--hd));
  --steps-title-left: calc(108 * var(--hd));
  --steps-desc-color: rgba(0, 0, 0, 0.25);
  --steps-desc-bottom: calc(12 * var(--hd));
  --steps-icon-margin-left: calc(53 * var(--hd));
  --steps-item-content: calc(140 * var(--hd));
  --steps-verticle-custom-space: calc(8 * var(--hd));
  --steps-cotent-label-spacing: calc(5 * var(--hd));
  --steps-content-lable-top: calc(8 * var(--hd));

  /* --- iconSelection 自定义图标选择器 -- */
  --icon-selection-icon-selected-width: calc(16 * var(--hd));
  --icon-selection-selected-height: calc(32 * var(--hd));
  --icon-selection-selected-width: calc(2 * var(--icon-selection-selected-height));
  --icon-selection-icon-hover-color: var(--bg-weak);
  --icon-selection-padding: calc(10 * var(--hd));
  --icon-selection-border: var(--hd) solid var(--border-color);
  --icon-selection-ne-margin: calc(-1 * var(--hd));
  --icon-selection-contain-width: calc(514 * var(--hd));
  --icon-selection-contain-shadow: 0 calc(3 * var(--hd)) calc(12 * var(--hd)) 0 rgba(0, 0, 0, 0.05);

  /** Scope 区间 */
  --scope-line-padding: 0 calc(12 * var(--hd));

  /** Carousel 走马灯 */
  --carousel-bullet-height: calc(4 * var(--hd));
  --carousel-bullet-margin: calc(4 * var(--hd));
  --carousel-bullet-border-radius: calc(3 * var(--hd));
  --carousel-pagination-carousel-active-width: calc(36 * var(--hd));
  --carousel-pagination-carousel-default-width: calc(24 * var(--hd));
  --carousel-pagination-bottom: calc(10 * var(--hd));
  --carousel-pagination-padding: calc(5 * var(--hd));
  --carousel-pagination-horizontal-margin: calc(9 * var(--hd));
  --carousel-arrow-width: calc(25 * var(--hd));
  --carousel-arrow-height: calc(25 * var(--hd));

  /** 右键菜单 */
  --right-menu-min-width: calc(var(--menu-trigger-width-min) - 10 * var(--hd));
  --right-menu-icon-width: calc(12 * var(--hd));
  --right-menu-icon-margin-rigth: calc(4 * var(--hd));

  /* 列表 */
  --table-head-bg: #fbfbfb;
  --table-head-hover-bg: #e5e5e5;
  --table-border-color: var(--diviling-line-color);
  --table-light-border-color: var(--light-border-color);
  --table-hover-bg: #f5f5f5;
  --table-font-color: var(--regular-fc);

  /** 折叠面板 */
  --collapse-panel-title-padding-h: calc(10 * var(--hd));
  --collapse-panel-title-padding-v: calc(8 * var(--hd));
  --collapse-panel-title-padding: var(--collapse-panel-title-padding-v) var(--collapse-panel-title-padding-h);
  --collapse-panel-border-color: var(--light-border-color);
  --collapse-panel-arrow-bg-color: #ebebeb;

  /** 穿梭框 */
  --transfer-border-color: #e5e5e5;
  --transfer-max-height: calc(500 * var(--hd));
  --transfer-checkbox-size: calc(16 * var(--hd));
  --transfer-hover-color: #f5f5f5;
  --transfer-space-16: calc(16 * var(--hd));

  /** 常用短语 */
  --phrase-link-color: #5D9CEC;

  /** Share（共享） */
  --share-title-border-color: var(--light-border-color);

  /* SensitiveWordScan 敏感词检测 */
  --sensitive-word-scan-icon-bg: #c2c2c2;
  --sensitive-word-scan-icon-size: calc(28 * var(--hd));
  --sensitive-word-content-title-color: #333;
  --sensitive-word-content-total-color: #FF1C1C;
  --sensitive-word-content-expandRender-single-background-color: #f5f5f5;
  --sensitive-word-content-expandRender-single-active-background-color: #BFDBFF;
  --sensitive-word-content-loading-color: #333333;
  --sensitive-word-content-tip-empty: #1D9100;
}

/* 移动端组件库变量 */
:root {
  /* --- Button 按钮 --- */
  --m-btn-font-size: var(--font-size-base);
  /* 按钮字号 */
  --m-btn-font-family: var(--regular-ff);
  /* 按钮字体 */
  --m-btn-font-color: var(--base-white);
  /* 按钮字体颜色 */
  --m-btn-padding-y: var(--v-spacing-md);
  /* 按钮垂直内间距 */
  --m-btn-padding-x: var(--h-spacing-md);
  /* 按钮水平内间距 */
  --m-btn-border: var(--border-width) solid var(--transparent);
  /* 按钮边框 */
  --m-btn-radius: var(--border-radius-sm);
  /* 通用按钮圆角 */
  --m-btn-active-opacity: 1;
  /* 按钮选中后透明度 */
  --m-btn-disabled-opacity: 0.5;

  --m-btn-lg-font-size: var(--font-size-caption);
  --m-btn-lg-padding-y: var(--v-spacing-lg);
  --m-btn-lg-padding-x: var(--h-spacing-lg);

  --m-btn-sm-font-size: var(--font-size-caption-sm);
  --m-btn-sm-padding-y: var(--v-spacing-sm);
  --m-btn-sm-padding-x: var(--h-spacing-sm);

  --m-btn-default-border-color: #ddd;
  --m-btn-default-active-color: #4397d9;
  --m-btn-default-active-bc: #dadddf;
  --m-btn-default-disabled-bc: #f3f6f8;
  --m-btn-primary-active-bc: #4498da;
  --m-btn-primary-disabled-bc: #a1cbec;
  --m-btn-danger-active-bc: #e75c5e;
  --m-btn-danger-disabled-bc: #ffb1b3;
  --m-btn-link-fc: #5d9cec;

  /* Menu */
  --m-menu-top-height: calc(42 * var(--hd));
  --m-menu-item-height: calc(44 * var(--hd));
  --m-menu-item-line-width: calc(3 * var(--hd));
  --m-menu-item-lineheight: calc(22 * var(--hd));
  --m-menu-navigation-height: calc(50 * var(--hd));
  --m-menu-navigation-icon-height: calc(24 * var(--hd));

  /* MDialog 弹框 */
  --m-dialog-footer-bg: #ebebeb;
  --m-dialog-footer-btn-color: #333;
  --m-dialog-footer-btn-primary-color: #4ba9f2;
  --m-dialog-footer-btn-warning-color: #fa6e52;
  --m-dialog-footer-btn-v-spacing: calc(5 * var(--hd));
  --m-dialog-prompt-border: calc(1 * var(--hd)) solid #b2b2b2;
  --m-dialog-icon-size: calc(90 * var(--hd));
  --m-dialog-circle-radius: var(--border-radius-lg);
  /* 圆角按钮 */
  /* 弹层底部按钮*/
  --m-dialog-button-group-height: calc(57 * var(--hd));
  --m-dialog-button-group-line-color: #ededed;
  --m-dialog-button-color-primary: var(--primary);
  --m-dialog-button-color-warning: #faad14;
  --m-dialog-button-color-danger: #ff4d4f;
  --m-dialog-button-color-link: #ff666a;
  --m-dialog-button-color-success: #52c41a;

  /* Input */
  --m-input-label-width: var(--font-size-base);
  --m-input-height: calc(44 * var(--hd));
  --color-text-base: var(--base-white);
  --color-text-caption: var(--secondary-fc);
  --color-text-disabled: var(--invalid-fc);
  --color-text-placeholder: var(--secondary-fc);

  /* Select */
  --m-select-item-height: calc(44 * var(--hd));

  /** Checkbox */
  --m-chk-border-color: #b2b2b2;
  /* checkbox 边框颜色 */
  --m-chk-disabled-border-color: #ccc;
  /* checkbox 禁用边框颜色 */
  --m-chk-disabled-inner-border-color: #999999;
  /* checkbox 禁用 inner 颜色 */
  --m-chk-disabled-bg-color: #ddd;
  /* checkbox 禁用背景颜色 */
  /* default*/
  --m-chk-default-bc: var(--bg-white);
  /* checkbox 默认背景颜色*/
  --m-chk-size-base: calc(22 * var(--hd));
  /* checkbox inner 大小*/
  --m-chk-check-width: calc(5 * var(--hd));
  /* checkbox inner 宽度 */
  --m-chk-check-height: calc(10 * var(--hd));
  /* checkbox inner 高度 */
  /* active*/
  --m-chk-active-bc: var(--m-chk-disabled-bg-color);
  /* checkbox 按压背景色 */
  /* checked*/
  --m-chk-check-border-color: var(--primary);
  /* checkbox 鼠标选中边框色 */
  --m-chk-check-bc: var(--primary);
  /* checkbox 鼠标选中背景色 */
  /* indeterminate*/
  --m-chk-indeterminate-inner-width: 70%;
  /* checkbox 半选宽度 */
  --m-chk-indeterminate-inner-height: calc(1 * var(--hd));
  /* checkbox 半选高度 */
  /* disabled*/
  --m-chk-disabled-font-color: var(--secondary-fc);
  /* checkbox 禁用字体颜色 */

  /** Radio */
  /* default*/
  --m-radio-default-bc: var(--bg-white);
  /* radio 默认背景颜色*/
  --m-radio-size-base: calc(22 * var(--hd));
  /* radio inner 大小*/
  /* active*/
  --m-radio-active-bc: var(--m-chk-disabled-bg-color);
  /* radio 按压背景色 */
  --m-radio-check-width: calc(5 * var(--hd));
  /* radio inner 宽度 */
  --m-radio-check-height: calc(10 * var(--hd));
  /* radio inner 高度 */
  /* checked*/
  --m-radio-check-bc: var(--primary);
  /* radio 鼠标选中背景色 */
  /* disabled*/
  --m-radio-disabled-font-color: var(--secondary-fc);
  /* radio 禁用字体颜色 */
  --m-radio-disabled-border-color: var(--m-chk-disabled-inner-border-color);
  /* radio 禁用 inner 颜色 */

  /** switch */
  --m-switch-padding: calc(11 * var(--hd));
  /* switch 的 height 和 width*/
  --m-switch-with-sm: calc(32 * var(--hd));
  /* 开关宽度-小 */
  --m-switch-height-sm: calc(16 * var(--hd));
  /* 开关高度-小 */
  --m-switch-with-md: calc(36 * var(--hd));
  /* 开关宽度-中 */
  --m-switch-height-md: calc(20 * var(--hd));
  /* 开关高度-中 */
  --m-switch-with-lg: calc(40 * var(--hd));
  /* 开关宽度-大 */
  --m-switch-height-lg: calc(22 * var(--hd));
  /* 开关高度-大 */
  /* inner 大小*/
  --m-switch-inner-size-sm: calc(16 * var(--hd) - 2 * var(--hd));
  --m-switch-inner-size-md: calc(20 * var(--hd) - 2 * var(--hd));
  --m-switch-inner-size-lg: calc(22 * var(--hd) - 2 * var(--hd));
  /* 打开状态的定位(left)*/
  --m-switch-inner-checked-left-sm: calc(100% - 14 * var(--hd) - var(--m-switch-inner-left));
  --m-switch-inner-checked-left-md: calc(100% - 18 * var(--hd) - var(--m-switch-inner-left));
  --m-switch-inner-checked-left-lg: calc(100% - 20 * var(--hd) - var(--m-switch-inner-left));
  /* innner 相对于上/左平移数*/
  --m-switch-inner-left: var(--hd);
  --m-switch-inner-top: var(--hd);

  --m-switch-default-bc: #cccccc;
  /* m-switch 默认背景色 */
  --m-switch-default-border: var(--hd) var(--border-solid) var(--m-switch-default-bc);
  /* m-switch 边框样式 */

  --m-switch-checked-bc: var(--primary);
  /* m-switch 选中时背景色 */
  --m-switch-checked-border: var(--hd) var(--border-solid) var(--primary);
  /* m-switch 选中时边框样式 */

  --m-switch-inner-color: var(--base-white);
  /* inner 颜色 */
  --m-switch-inner-font-size: var(--font-size-sm);
  /* inner 颜色 */
  --m-switch-disable-opacity: 0.5;
  /* 禁用后透明度 */
  --m-switch-border-radius: calc(53 * var(--hd));
  /* 开关圆角 */

  /* m-switch 存在 checkedChildren 时的设置*/
  --m-switch-label-margin-sm: calc(8 * var(--hd));
  --m-switch-label-margin-inner-sm: calc(14 * var(--hd) + 8 * var(--hd) - (2 * var(--hd)));
  --m-switch-label-margin-md: calc(8 * var(--hd));
  --m-switch-label-margin-inner-md: calc(18 * var(--hd) + 8 * var(--hd) - (2 * var(--hd)));
  --m-switch-label-margin-lg: calc(8 * var(--hd));
  --m-switch-label-margin-inner-lg: calc(20 * var(--hd) + 8 * var(--hd) - (4 * var(--hd)));

  /* --- Tag 标签 --- */
  --m-tag-font-size: var(--font-size-md);
  /* 字号 */
  --m-tag-font-size-xs: var(--font-size-10);
  /* 字号 */
  --m-tag-font-color: var(--main-fc);
  /* 字体颜色 */
  --m-tag-font-family: var(--regular-ff);
  /* 字体 */
  --m-tag-border: var(--border-width) solid var(--transparent);
  /* 边框 */
  --m-tag-padding-y-xs: calc(0 * var(--hd));
  /* 0px 小垂直内间距 */
  --m-tag-padding-y-sm: calc(1.62 * var(--hd));
  /* 4px 垂直内间距 */
  --m-tag-padding-y-lg: var(--v-spacing-sm);
  /* 6px 垂直内间距 */
  --m-tag-padding-x-lg: calc(32 * var(--hd));
  /* 32px 水平内间距 */
  --m-tag-padding-x-sm: calc(6 * var(--hd));
  /* 6px 水平内间距 */
  --m-tag-border-radius: calc(12.5 * var(--hd));
  /* 12.5px 圆角 */
  --m-tag-default-active-bg: rgba(74, 169, 242, 0.15);
  --m-tag-default-active-border-color: #4aa9f2;
  --m-tag-disabled-opacity: 0.5;

  /* MUpload */
  --m-upload-delete-btn-backgroud: #a0a1a7;

  /* Form */
  --m-form-item-line-height: calc(44 * var(--hd));

  /* MSearchAdvanced */
  --m-searchAdvanced-condition-active-border: #4aa9f2;
  --m-searchAdvanced-condition-active-bg: #e6f2fc;
  --m-searchAdvanced-container-bg: #f2f2f2;
  --m-searchAdvanced-setting-list-lineHeight: calc(50 * var(--hd));
  --m-searchAdvanced-quikSearch-editBtn-color: #55b1f9;
  --m-searchAdvanced-historySearch-input-bg: #f4f5f6;

  /* MComment */
  --m-comment-img-size: calc(70 * var(--hd));
  --m-comment-bgcolor-white: var(--base-white);
  --m-comment-margin-size: calc(10 * var(--hd));
  --m-comment-padding-16: calc(16 * var(--hd));
  --m-comment-check-size: calc(14 * var(--hd));
  --m-comment-link-color: var(--primary);
  --m-comment-bgcolor: #f7f7f7;
  --m-comment-group-border-color: #ebebeb;
  --m-comment-group-header-height: calc(56 * var(--hd));
  --m-comment-group-header-line-height: calc(55 * var(--hd));
  --m-comment-audio-bgcolor: #f1f3f4;
  --m-comment-audio-height: calc(32 * var(--hd));
  --m-comment-audio-play-color: #98b6dc;
  --m-comment-audio-text-color: #868686;

  /* MActionSheet */
  --m-actionsheet-item-height: calc(50 * var(--hd));
  --m-actionsheet-item-font-size: calc(20 * var(--hd));
  --m-actionsheet-item-border-radius: calc(14 * var(--hd));

  --m-actionsheet-grid-main-padding: calc(25 * var(--hd)) calc(10 * var(--hd)) 0 calc(10 * var(--hd));
  --m-actionsheet-item-icon-width: calc(40 * var(--hd));
  --m-actionsheet-bg-color: #f7f7f7;

  /* IconSelection 自定义图标选择器 */
  --m-icon-selection-line-padding: calc(10 * var(--hd));
  --m-icon-selection-icon-width: calc(44 * var(--hd));
  --m-icon-selection-icon-gap: calc(16 * var(--hd));
  --m-icon-selection-border-radius: calc(6 * var(--hd));
  --m-icon-selection-dialog-bg: var(--bg-base);

  /** Scope 区间 */
  --m-scope-line-height: calc(44 * var(--hd));
  --m-scope-line-padding: 0 calc(12 * var(--hd));

  /** Steps 步骤条 */
  --m-steps-vertical-icon-wrap-size: calc(12 * var(--hd));
  --m-steps-vertical-icon-wrap-size-half: calc(var(--m-steps-vertical-icon-wrap-size) / 2);
  --m-steps-content-lable-top: calc(8 * var(--hd));
  /** rate 评分 */
  --m-rate-label-margin: calc(19 * var(--hd));

  /** Map 地图 */
  --m-map-border-color: #e5e5e5;
  --m-map-box-shadow: 0px 0px calc(6 * var(--hd)) rgba(0, 0, 0, 0.09);
  --m-map-bottom-radius: calc(16 * var(--hd)) calc(16 * var(--hd)) 0 0;

  /** FilterButtons 地图 */
  --m-filter-buttons-padding: calc(5 * var(--hd));
  --m-filter-buttons-height: calc(32 * var(--hd));

  /** SoundRecording 录音 */
  --m-sound-recording-button-color: #5d9cec;
  --m-sound-recording-button-mask-color: #000000;

  /** MIconDropdown 图标下拉 */
  --m-icon-dropdown-margin-h: calc(20 * var(--hd));
  --m-icon-dropdown-margin-v: 0;

  /** MBrowser 浏览按钮 */
  --m-browser-img-bg: #f2f2f2;

  /** MSearchbar 搜索栏 */
  --m-serach-bar-height: calc(40 * var(--hd));

  /** MVoiceToText 语音转文字 */
  --m-voice-to-text-selected-editable-bg: #733e92;
  --m-voice-to-text-selected-editable-border: calc(5 * var(--hd)) solid #d3d2fc;
  --m-voice-to-text-edit-icon-bg: #b6342d;
  --m-voice-to-text-body-bg: #F2F2FF;

  /** QuickMenu 悬浮按钮 */
  --quick-menu-dialog-bg-image: url('../components/quick-menu/static/bg.png');
}