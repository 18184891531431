// 默认模式下使用的样式
.ui-spin-size-sm {
  width: var(--spin-size--sm);
  height: var(--spin-size--sm);
}

.ui-spin-size-md {
  width: var(--spin-size--md);
  height: var(--spin-size--md);
}

.ui-spin-size-lg {
  width: var(--spin-size--lg);
  height: var(--spin-size--lg);
}

.@{spinClsPrefix} {
  position: relative;
  height: 100%;
  .curve {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      .ui-spin-size-md();
    }
  }

  .@{spinClsPrefix}-dot {
    position: relative;
    display: inline-block;

    .loading-bottom-layer {
      border: 2px solid #e7e9eb;
      position: relative;
      width: calc(1px * 26);
      height: calc(1px * 26);
      border-radius: 50%;
      box-sizing: border-box;
      transform: scale(1);
      transform-origin: -10px -10px;
    }

    .loading-left,
    .loading-right {
      width: calc(50% + 4px);
      height: calc(100% + 8px);
      position: absolute;
      overflow: hidden;
    }

    .loading-left {
      top: -4px;
      left: -3px;
    }

    .loading-right {
      top: -4px;
      left: 50%;
    }

    .loading-left-progress,
    .loading-right-progress {
      position: absolute;
      width: calc(1px * 22);
      height: calc(1px * 22);
      border-radius: 50%;
      border: 2px solid transparent;
      box-sizing: content-box;
    }

    .loading-left-progress {
      left: 1px;
      top: 2px;
      border-bottom: 2px solid #417ff9;
      border-left: 2px solid #417ff9;
      transform: rotate(405deg);
      animation: left 1.5s cubic-bezier(0, 0, 0, 0.9) infinite;

      .loading-ball-progress {
        left: -1px;
        top: 2px;
      }
    }

    .loading-right-progress {
      right: 2px;
      top: 2px;
      border-top: 2px solid #417ff9;
      border-right: 2px solid #417ff9;
      transform: rotate(405deg);
      animation: right 1.5s cubic-bezier(0.9, 0, 1, 1) infinite;

      .loading-ball-progress {
        right: 0px;
        bottom: 1px;
        animation: ball-right 1.5s cubic-bezier(0.9, 0, 1, 1) infinite;
      }
    }

    .loading-ball {
      position: absolute;
    }

    .loading-ball-progress {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #417ff9;
      position: absolute;
    }
  }

  .@{spinClsPrefix}-text {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    position: relative;
    top: 4px;
    left: 16px;
    width: auto;
    color: #999;
  }

  &-show-text-rtl {
    .@{spinClsPrefix}-text {
      transform: translate(-50%, -50%) scaleX(-1) !important;
    }
  }

  &-svg-rtl {
    transform: scaleX(-1);
  }
}

.@{spinClsPrefix}-sm {
  // .ui-spin-size-sm();

  .@{spinClsPrefix}-dot {
    width: calc(1px * 15);
    height: calc(1px * 15);

    .loading-bottom-layer {
      border: 1px solid #e7e9eb;
      position: relative;
      width: calc(1px * 15);
      height: calc(1px * 15);
    }

    .loading-left-progress,
    .loading-right-progress {
      position: absolute;
      width: calc(1px * 12);
      height: calc(1px * 12);
    }

    .loading-ball {
      width: calc(1px * 14);
      height: calc(1px * 14);
    }

    .loading-left-progress {
      .loading-ball-progress {
        left: -2px;
        top: 0px;
      }
    }

    .loading-right-progress {
      .loading-ball-progress {
        right: -2px;
      }
    }
  }

  .curve {
    svg {
      .ui-spin-size-sm();
    }
  }

  .@{spinClsPrefix}-dot {
    width: 15px;
    height: 15px;


    &-item {
      .ui-spin-size-sm();

      &.start-point {
        span {
          width: 4px;
          height: 4px;
          background: var(--primary);
        }
      }

      &:nth-child(5) {
        .ui-spin-size-sm();
      }
    }
  }
}

.@{spinClsPrefix}-lg {
  // .ui-spin-size-lg();

  .curve {
    svg {
      .ui-spin-size-lg();
    }
  }

  .@{spinClsPrefix}-dot {
    width: calc(1px * 30);
    height: calc(1px * 30);

    .loading-bottom-layer {
      border: 1px solid #e7e9eb;
      position: relative;
      width: calc(1px * 30);
      height: calc(1px * 30);
    }

    .loading-left-progress,
    .loading-right-progress {
      position: absolute;
      width: calc(1px * 29);
      height: calc(1px * 29);
    }

    .loading-ball {
      width: calc(1px * 30);
      height: calc(1px * 30);
    }

    .loading-left-progress {
      .loading-ball-progress {
        left: 1px;
      }
    }

    .loading-right-progress {
      .loading-ball-progress {
        right: 2px;
      }
    }
  }

}

.@{spinClsPrefix}-nested-loading {
  position: relative;

  >div>.@{spinClsPrefix} {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: block;
    width: 100%;
    height: 100%;

    .@{spinClsPrefix}-dot {
      position: absolute;
      margin-left: calc(-1*var(--spin-size--md)/2);
      margin-top: calc(-1*var(--spin-size--md)/2);
      top: 50%;
      left: 50%;
    }

    .@{spinClsPrefix}-text {
      text-align: center;
      position: absolute;
      margin-top: calc(var(--spin-size--md)*1.3 );
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      color: #999;
    }

  }

  .@{spinClsPrefix}-blur {
    opacity: 0.3;
  }

  &-global {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: .5;
    width: 100%;
    height: 100%;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

:root {
  --w: 1px;
  --p: calc(0px - 1px);
  --bw: calc(1px* 3);
}

@keyframes left {
  0% {
    transform: rotate(225deg);
    visibility: hidden;
  }

  50% {
    transform: rotate(225deg);
    visibility: hidden;
  }

  100% {
    transform: rotate(405deg);
    visibility: visible;
  }
}

@keyframes right {
  0% {
    visibility: visible;
    transform: rotate(225deg);
  }

  50% {
    visibility: visible;
    transform: rotate(405deg);
  }

  100% {
    visibility: hidden;
    transform: rotate(405deg);
  }
}

@keyframes ball {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes ball-right {
  0% {
    visibility: visible;
  }

  50% {
    visibility: hidden;
  }

  100% {
    visibility: hidden;
  }
}

// 开启svg模式所使用的样式

.@{spinClsPrefix}-svg {
  position: relative;

  .@{spinClsPrefix}-text {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    position: relative;
    top: 4px;
    left: 16px;
    width: auto;
    color: #999;
  }
}

.@{spinClsPrefix}-svg-sm {
  width: var(--spin-size--sm);
  height: var(--spin-size--sm);
}

.@{spinClsPrefix}-svg-md {
  width: var(--spin-size--md);
  height: var(--spin-size--md);
}

.@{spinClsPrefix}-svg-lg {
  width: var(--spin-size--lg);
  height: var(--spin-size--lg);
}

.@{spinClsPrefix}-svg-nested-loading {
  position: relative;

  >div>.@{spinClsPrefix} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 4;
    width: 100%;
    display: flex;
    justify-content: center;

    .@{spinClsPrefix}-svg-text {
      text-align: center;
      position: absolute;
      margin-top: calc(var(--spin-size--md)*1.3);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      color: #999;
    }

  }

  .@{spinClsPrefix}-svg-blur {
    opacity: 0.3;
  }

  &-global {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: .5;
    width: 100%;
    height: 100%;
  }
}

  // IE浏览器 暂不支持 svg-animateTransform  故用icon-name = Icon-Loading 替换
  .@{spinClsPrefix}-svg-nested-loading-global.ie-svg-icon{
    .ui-icon{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      animation-name: svg-global-spin; 
      animation-duration: 1.8s; 
      animation-iteration-count: infinite; 
      animation-timing-function: linear; 
    }
  }
  
  /* 定义关键帧 */
  @keyframes svg-global-spin {
    0% { transform: rotate(0deg); transform-origin: center; } 
    100% { transform: rotate(360deg); transform-origin: center; }
  }