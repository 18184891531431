// 图文识别面板
.@{photoViewClsPrefix} {
  .PhotoView {
    &_Graphic {
      position: absolute;
      right: 0;
      height: 100%;
      margin-top: 50px;
      background: #1D1F23;
      // opacity: 0.7;
      z-index: 21;
      box-shadow: inset 0px 1px 0px 0px rgba(44, 44, 44, 1);
      border-top: 1px solid rgba(255, 255, 255, 0.10);
    }

    &_Graphic_scrollerWrap {
      border-top: 1px solid #2c2c2c;
    }

    &_Graphic_scrollerView {
      margin-bottom: 50px;
    }

    &_Graphic_item {
      color: #ffffff;
      font-family: PingFangSC-Regular;
      font-size: var(--font-size-14);
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: justify;
      line-height: 30px;
      font-weight: 400;
      margin: 10px 0;
      padding: 0 22px;
      display: flex;
      align-items: center;
    }

    &_Graphic_rightMenu {
      padding: 4px 8px;
    }

    &_Graphic_item_text {
      word-break: break-word;
    }

    &_Graphic_item_textArea {
      color: var(--base-white);
      font-size: var(--font-size-14);
      border: 1px solid #1D1F23;
      background: #1D1F23;
      transition: unset !important;
      font-family: 'PingFangSC-Regular';
      line-height: 30px;
      word-break: break-word;
    }

    &_Graphic_item_text,
    &_Graphic_item_textArea {
      &::selection {
        color: var(--black-fc);
        background: var(--bg-white);
      }

      &::-moz-selection {
        color: var(--black-fc);
        background: var(--bg-white);
      }

      &::-webkit-selection {
        color: var(--black-fc);
        background: var(--bg-white);
      }
    }

    &_Graphic_item {
      .@{textareaClsPrefix}:focus {
        background-color: #ffffff15;
        border: none !important;
        box-shadow: none !important;
      }
    }

    &_Graphic_Footer {
      height: 50px;
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 1;
      display: flex;
      border-top: 1px solid #2c2c2c;

      &_button {
        flex: 1;
        background: #1D1F23;
        font-size: var(--font-size-14);
        border: none;
        color: var(--base-white);
        border-radius: 0%;
        padding: 0;
      }

      &_buttonText {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--base-white);
        width: 100%;

        &-left {
          border-right: 1px solid rgba(255, 255, 255, 0.10);
        }
      }

      &_buttonEdit {
        color: #ffffff;
        opacity: 0.8;

        &-icon {
          margin-right: 5px;
        }
      }
    }
  }
}

// 图文识别loading状态
.@{photoViewClsPrefix}-loadingWrap {
  width: 120px;
  height: 110px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3E3C3C;
  border-radius: 6px;
  flex-direction: column;
  font-size: 14px;
  color: var(--base-white);

  &-spin {
    height: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  &-span {
    height: 50%;
    display: flex;
    align-items: center;
  }
}

// 信息按钮popover
.@{photoViewClsPrefix}-popover {
  z-index: 2001;

  .@{popoverClsPrefix}-tooltip {
    max-width: unset;
  }

  &-infoButtonContent {
    display: flex;
    flex-direction: column;

    &-title {
      font-size: var(--font-size-14);
      margin: 3px 0;
      color: var(--base-white);
    }

    &-content {
      font-size: var(--font-size-12);
      margin: 3px 0;
      color: var(--base-white);
      opacity: 0.5;

      .item:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

}

// 加载失败
.@{photoViewClsPrefix}-broken {
  svg {
    width: 150px;
    height: 150px;
    color: #999999;
  }
}