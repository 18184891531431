.@{paginationClsPrefix} {
  font-size: var(--font-size-12);
  color: var(--secondary-fc);
  height: var(--pagination-item-size);
  line-height: var(--pagination-item-size);
  overflow: hidden;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  > div {
    display: inline-block;
    vertical-align: top;
    input {
      width: var(--pagination-input-size);
      height: var(--pagination-item-size);
      min-height: 0;
      text-align: center;
      border-radius: var(--border-radius-xs);
    }
  }
  &-total {
    margin-right: var(--v-spacing-md);
    .ui-spin-nested-loading > div > .ui-spin-sm .ui-spin-dot {
      margin-top: -8px;
    }
  }
  &-select {
    margin-right: var(--v-spacing-md);
    color: var(--main-fc);
    // select的图标文字对齐
    .@{selectClsPrefix} {
      display: flex;
      align-items: center;
    }
    .@{selectClsPrefix}-input {
      height: var(--pagination-item-size);
      min-width: calc(48 * var(--hd));
      min-height: 0;
      text-align: center;
      padding-top: 0;
      padding-bottom: 0;
    }
    .@{selectClsPrefix}-input-arrow {
      display: flex;
    }
  }
  &-collapsed {
    display: inline-block;
    margin-right: var(--v-spacing-md);
    cursor: pointer;
    .ui-icon-wrapper {
      vertical-align: middle;
      margin-top: calc(-2 * var(--hd));
      &-arrow {
        width: var(--pagination-item-size);
        height: var(--pagination-item-size);
        line-height: var(--pagination-item-size);
        .ui-icon-wrapper {
          vertical-align: -0.125em;
          .Icon-Down-arrow01 {
            width: var(--font-size-14);
            height: var(--font-size-14);
            color: var(--secondary-fc);
          }
        }
      }
    }
  }
  &-jump {
    margin-left: var(--v-spacing-md);
  }
  &-goto{
    display: flex;
    align-items: center;
  }
  &-pager {
    display: inline-block;
    height: 100%;
    color: var(--main-fc);
    &-item {
      padding: 0 var(--v-spacing-md);
      cursor: pointer;
      float: left;
      &:hover {
        color: var(--primary);
      }
    }
    &-prev, &-next, &-start, &-end {
      width: var(--pagination-item-size);
      height: var(--pagination-item-size);
      line-height: var(--pagination-item-lineheight);
      padding: 0;
      margin-right: calc(2 * var(--hd));
      text-align: center;
      box-sizing: border-box;
      color: var(--secondary-fc);
      cursor: pointer;
      border-radius: var(--border-radius-xs);
      border: var(--border-width) solid transparent;
      &:hover {
        border-color: var(--border-color);
      }
      .ui-icon {
        width: var(--pagination-icon-size);
        height: var(--pagination-icon-size);
      }
    }
    &-jumpprev, &-jumpnext {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--pagination-icon-size);
      height: 100%;
      margin: 0 var(--v-spacing-xs);
      color: var(--main-fc);
      .@{paginationClsPrefix}-pager-pager-item-arrow {
        display: none;
      }
      .@{paginationClsPrefix}-pager-pager-item-more {
        display: inline-block;
      }
    }
    &-jumpprev:hover, &-jumpnext:hover {
      .@{paginationClsPrefix}-pager-pager-item-arrow {
        display: inline-block;
        color: var(--primary);
      }
      .@{paginationClsPrefix}-pager-pager-item-more {
        display: none
      }
    }
    &-page {
      position: relative;
      float: left;
      margin-right: calc(2 * var(--hd));
      &-input {
        float: left;
        display: none;
      }
      &-text { // 把input框的样式给到
        display: inline-block;
        height: var(--pagination-item-size);
        line-height: var(--pagination-item-lineheight);
        text-align: center;
        border-radius: var(--border-radius-xs);
        padding: 0 var(--h-spacing-md);
        border: var(--border-width) solid transparent;
        cursor: pointer;
        color: var(--secondary-fc);
      }
      &-focus {
        .@{paginationClsPrefix}-pager-page-input {
          display: inline-block;
        }
        .@{paginationClsPrefix}-pager-page-text {
          display: none;
        }
      }
      &:hover {
        .@{paginationClsPrefix}-pager-page-text {
          border: var(--border-solid);
        }
      }
    }
    &-turnto {
      float: left;
      width: var(--pagination-input-size);
      height: var(--pagination-item-size);
      line-height: var(--pagination-item-size);
      border-radius: var(--border-radius-xs);
      background: var(--primary);
      margin-right: var(--v-spacing-md);
      text-align: center;
      color: var(--base-white);
      cursor: pointer;
    }
    &-active {
      color: var(--primary);
    }

    .@{paginationClsPrefix}-pager-item.@{paginationClsPrefix}-pager-active {
      background-color: var(--primary);
      color: var(--base-white);
      border-radius: var(--border-radius-xs);
    }

    &-disabled {
      color: var(--invalid) !important;
      cursor: not-allowed;
      &:hover {
        border-color: transparent
      }
    }
  }
}