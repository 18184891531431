.@{mRepeatFrequencyClsPrefix}-times_picker,
.@{mRepeatFrequencyClsPrefix} {
  .@{mDialogClsPrefix}-content {
    border-radius: calc(16 * var(--hd)) calc(16 * var(--hd)) 0 0;
  }

  .@{mDialogClsPrefix}-top-bottom {
    padding: 0;
  }
}

.@{mRepeatFrequencyClsPrefix}-times_picker,
.@{mRepeatFrequencyClsPrefix} {
  &-nav {
    height: calc(50 * var(--hd));
    line-height: calc(50 * var(--hd));
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 calc(24 * var(--hd));
    font-size: var(--font-size-14);
    .border-solid-then;
    &-middle {
      max-width: 40%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-left {
      color: var(--regular-fc);
    }

    &-right {
      color: var(--primary);
    }
  }
}

.@{mRepeatFrequencyClsPrefix} {
  &-grid_checkbox_wrap {
    padding: calc(8 * var(--hd));
  }
  &-item {
    width: 100%;
    display: flex;
    align-items: center;
    // padding-right: var(--h-spacing-lg);
    &-leftfix {
      width: calc(40 * var(--hd));
      height: calc(44 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        padding: calc(4 * var(--hd)) 0 !important;
      }
    }
    &-main {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      &-label {
        width: calc(60 * var(--hd));
        max-width: calc(60 * var(--hd));
        margin-right: calc(10 * var(--hd));
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        &-ellipsis {
          max-width: calc(40 * var(--hd));
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(100% - 80 * var(--hd));
        display: flex;
        align-items: center;
        &-ellipsis {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &-half {
        max-width: 45%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        &-ellipsis {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &-arrow {
        margin-left: calc(6 * var(--hd));
      }
    }
    &-main-single {
      display: flex;
      overflow: hidden;
    }
    &-arrow {
      margin-left: calc(6 * var(--hd));
    }
  }
  &-over-repeat-times {
    height: calc(44 * var(--hd));
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
  }
  &-footer {
    width: 100%;
    height: calc(70 * var(--hd));
    padding: calc(12 * var(--hd)) calc(8 * var(--hd));
    background-color: rgba(247, 247, 247, 1);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-router-dialog-content {
    height: calc(100% - calc(57 * var(--hd)));
    overflow-y: auto;
    background-color: rgb(249, 249, 249);
    &-definition {
      padding: 0 calc(16 * var(--hd));
      color: rgb(153, 153, 153);
      line-height: calc(20 * var(--hd));
      word-wrap: break-word;
    }
  }
}
