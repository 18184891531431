.@{scopeClsPrefix} {
  // width: calc(250 * var(--hd));
  color: var(--main-fc);
  font-size: var(--font-size-sm);

  &.@{scopeClsPrefix}-required .@{scopeClsPrefix}-required-mark {
    position: absolute;
    color: var(--danger);
    font-size: var(--font-size-12);
    left: calc(-10 * var(--hd));
    top: 50%;
    line-height: 0;
  }

  &.@{scopeClsPrefix}-column .@{scopeClsPrefix}-wrap {
    flex-direction: column;
    align-items: start;
  }

  .@{scopeClsPrefix}-wrap {
    display: flex;
    position: relative;
  }

  .@{scopeClsPrefix}-label {
    color: var(--main-fc);
    font-size: var(--font-size-base);
    height: var(--m-input-height);
    line-height: var(--line-height-base);
    box-sizing: border-box;
    margin-left: 0;
    margin-right: var(--h-spacing-sm);
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: calc(2 * var(--hd)) 0;

    &:empty {
      height: 0;
      padding: 0;
    }

    &.@{scopeClsPrefix}-label-5 {
      width: calc(5 * var(--m-input-label-width));
    }
  }

  &.@{scopeClsPrefix}-column .@{scopeClsPrefix}-label {
    width: 100%;
  }

  .@{scopeClsPrefix}-controls {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .@{scopeClsPrefix}-input {
    text-align: center;
    max-width: calc(120 * var(--hd));
    min-width: calc(50 * var(--hd));

    &.text-underline {
      text-decoration: underline;
    }

    // &.is-focus,
    // &:focus {
    //   border-color: var(--input-focus-border-color);
    //   box-shadow: var(--input-focus-shadow);
    //   outline: 0;
    // }
    // &:disabled {
    //   color: var(--input-disabled-color);
    //   background-color: var(--input-disabled-bg);
    //   border-color: var(--input-disabled-border-color);
    //   &::placeholder {
    //     color: var(--input-placeholder-color);
    //   }
    //   opacity: 1;
    //   cursor: not-allowed;
    // }
  }

  .@{scopeClsPrefix}-line {
    padding: var(--scope-line-padding);
    color: var(--main-fc);
    font-weight: var(--font-weight-base);
  }
}