.@{dateTimePickerClsPrefix}-dateTimePanel {
  width: calc(280 * var(--hd));
  font-size: var(--font-size-12);
  display: flex;
  flex-direction: column;
  outline: none;
  z-index: 999;
  overflow: visible;
  background: var(--bg-white);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius-xs);
  box-shadow: var(--box-shadow);
  margin-top: calc(5 * var(--hd));
}

.@{dateTimePickerClsPrefix}-timePanel.@{dateTimePickerClsPrefix}-timePanel-HH {
  width: calc(120 * var(--hd));
}

.@{dateTimePickerClsPrefix}-inputNav {
  height: calc(40 * var(--hd));
  padding: 0 calc(14 * var(--hd));
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: calc(1 * var(--hd)) solid var(--diviling-line-color);

  .dateInput,
  .timeInput,
  .@{timePickerClsPrefix} {
    width: calc(122 * var(--hd));
    height: calc(30 * var(--hd));
  }
}

.@{dateTimePickerClsPrefix}-buttonNav {
  height: calc(40 * var(--hd));
  padding: 0 calc(14 * var(--hd));
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: calc(1 * var(--hd)) solid var(--border-color);
}

.@{dateTimePickerClsPrefix}-confirm-footer {
  box-sizing: border-box;
  height: calc(40 * var(--hd));
  padding: 0 calc(4 * var(--hd));
  border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  .thisMoment,
  .clear,
  .cancel,
  .confirm {
    margin: 0 calc(10 * var(--hd));
    height: calc(40 * var(--hd));
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background: var(--bg-transparent);
    font-size: var(--font-size-12);
    outline: none;
    color: var(--primary);
    cursor: pointer;
  }

  .cancel {
    color: var(--regular-fc);
  }
}

.@{dateTimePickerClsPrefix}-btn-footer {
  height: calc(40 * var(--hd));
  padding: 0 calc(10 * var(--hd));
  border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
  display: flex;

  .item {
    flex: 1;
    border: 0;
    background: var(--bg-transparent);
    height: calc(40 * var(--hd));
    line-height: calc(40 * var(--hd));
    outline: none;
    font-size: var(--font-size-12);
    color: var(--main-fc);
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:hover:not(.isDisabled) {
      color: var(--primary);
    }
  }

  .isDisabled {
    cursor: not-allowed;
  }
}