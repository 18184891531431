.@{dateTimePickerClsPrefix} {
  * {
    box-sizing: border-box;
  }

  width: 100%;
  min-width: calc(60 * var(--hd));
  display: inline-block;
  box-sizing: border-box;
  background-color: var(--bg-white);
  color: var(--placeholder-fc);
  font-size: var(--font-size-12);

  &-maxWidth {
    max-width: calc(200 * var(--hd));
  }

  &-rangeMaxWidth {
    max-width: calc(400 * var(--hd));
  }

  &-disabled {

    .@{inputClsPrefix}-wrap,
    .@{dateTimePickerClsPrefix}-rangeWrap {
      background-color: var(--label-bc);
      cursor: not-allowed;
      color: var(--placeholder-fc);
      border-color: var(--border-color);

      &:hover {
        border-color: var(--border-color);
      }
    }
  }

  &-readOnly {
    color: var(--main-fc);
    display: inline-flex;
    align-items: center;
    background: none;
    height: calc(30 * var(--hd));
    min-width: unset;

    .date-span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-readOnly-readOnlyLineHeightStandard {
    .form-pc-readOnlyLineHeightStandard
  }
}

.@{dateTimePickerClsPrefix}-wrap {
  display: flex;
  height: calc(30 * var(--hd));
  border-radius: var(--border-radius-xs);

  input {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.@{dateTimePickerClsPrefix}-rangeWrap {
  display: flex;
  height: calc(30 * var(--hd));
  border: var(--border-solid);
  border-radius: var(--border-radius-xs);
  padding: 0 calc(8 * var(--hd));
  min-width: calc(100 * var(--hd));

  &:hover {
    border: calc(1 * var(--hd)) solid var(--invalid);
  }

  &-active {
    border: calc(1 * var(--hd)) solid var(--primary);
    box-shadow: 0 0 calc(3 * var(--hd)) 0 var(--primary);
  }

  &-input {
    flex: 1 1 auto;
    // flex: 1 1 auto;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    //flex:1 = flex:1 1 0 但ie11会有兼容问题拆开写先
    border: none;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }

  &-middle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-fc)
  }

  &-suffix {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--secondary-fc);
  }
}

//后缀suffix处理
.@{dateTimePickerClsPrefix} {
  &-icon {
    margin-left: 4px;
  }

  .@{dateTimePickerClsPrefix}-wrap,
  .@{dateTimePickerClsPrefix}-rangeWrap {
    .@{dateTimePickerClsPrefix}-clear {
      background: var(--bg-white);
      color: var(--invalid-fc);
      transition: color 0.3s ease, opacity 0.15s ease;

      &:hover {
        color: var(--secondary-fc);
      }

      &-show {
        cursor: pointer;
      }
    }
  }

  .@{dateTimePickerClsPrefix}-wrap {
    .@{dateTimePickerClsPrefix}-clear {
      display: none;
    }
  }

  .@{dateTimePickerClsPrefix}-rangeWrap {
    .@{dateTimePickerClsPrefix}-clear {
      opacity: 0;
    }
  }

  &:hover {
    .@{dateTimePickerClsPrefix}-wrap {
      .@{dateTimePickerClsPrefix}-clear-show {
        display: inline-block;
      }
    }

    .@{dateTimePickerClsPrefix}-rangeWrap {
      .@{dateTimePickerClsPrefix}-clear-show {
        opacity: 1;
      }
    }
  }
}