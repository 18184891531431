.@{mCommentClsPrefix}-rich {
  &-text {
    .cke_top {
      padding: 0;
      border-bottom: 0;
    }
    .cke {
      border: 0;
    }
  }
  &-container {
    border: 0;
  }
}