.@{mPickerClsPrefix}{
  .picker-nav{
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    display: flex;
    .border-solid-then;
    .left,.right{
     width: 20%;
     display: flex;
     justify-content: center;
     align-items: center;
    }
    .middle{
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
  }
}

.@{mPickerClsPrefix}{
  .picker-view{
    width: 100%;
  }
}

.@{mPickerClsPrefix}-dialog {
  .@{mDialogClsPrefix}-top-bottom {
    padding: 0;
  }
  .@{mDialogClsPrefix}-content{
    border-radius: calc(16 * var(--hd)) calc(16 * var(--hd)) 0 0;
  }
}
