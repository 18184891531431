@import url(./uploader.less); // 上传区域样式
@import url(./fileItem.less); // 已上传区域样式
@import url(./reNameDialog.less); // 重复名称校验弹框样式
@import url(./sensitiveWords.less); // 敏感词检测弹框样式
@import url(./dragTriggerPopup.less); // 拖拽优化弹框
@import url(./batchEdit.less); // 批量操作样式
@import url(./batchProgress.less); // 进度条组件
@import url(./secretValidityDialog.less); // 本地标密密级期限填写弹框组件
@import url(./secretSelectDialog.less); // 本地标密弹框组件
@import url(insideUpload.less); // inside list 样式
@import url(./tableUpload.less); // table upload 样式
// @import url(./copy.less);

.@{uploadClsPrefix} {
  width: 100%;
  max-width: calc(800 * var(--hd));
  padding: var(--hd) 0;
  position: relative;

  /** 批量签署按钮 **/
  &-batchSign {
    display: inline-block;
    font-size: var(--font-size-12);
    color: var(--link-fc);
    cursor: pointer;
    margin-left: calc(25 * var(--hd));
    margin-top: calc(5 * var(--hd));
  }

  /** 删除确认框样式 **/
  &-delete-confirm-content {
    .filename {
      word-break: break-word;
      word-wrap: break-word;
      overflow-wrap: break-word;
      width: 100%;
      color: var(--primary);
    }
  }

  /** 未知样式 **/
  &-select-option {
    .@{iconClsPrefix} {
      margin-right: calc(5 * var(--hd));
    }

    .@{selectClsPrefix}-option.is-active {
      color: var(--select-option-color);
      font-weight: var(--font-weight-base);
    }
  }
}

/*******************************  复写 list 样式 *******************************/
.@{uploadClsPrefix} {
  &-list {
    overflow: visible;
    width: 100%;

    // max-width: calc(800 * var(--hd));
    .@{listClsPrefix}-content {
      min-height: var(--upload-list-item-height);
    }

    .@{listClsPrefix}-row .@{listClsPrefix}-item {
      float: none;
    }
  }

  &-list .@{listClsPrefix}-scrollview {
    overflow: visible;
  }

  &-list.@{listClsPrefix}-column .@{listClsPrefix}-item {
    overflow: visible;
    background: var(--bg-transparent);
  }

  &-list.@{listClsPrefix}-column .@{listClsPrefix}-item .@{listClsPrefix}-content {
    overflow: visible;
  }

  &-list .@{sliderClsPrefix}-handle {
    display: none;
  }

  .@{titleClsPrefix}-icon {
    border: 0;

    .@{iconClsPrefix}-svg {
      width: var(--icon-size-lg);
      height: var(--icon-size-lg);
      color: var(--warning);
    }
  }

  .@{dialogClsPrefix}-body {
    padding: 0;
    margin: var(--v-spacing-lg) var(--h-spacing-lg);
    background: var(--bg-white);
  }
}

