/******************************* 本地标密上传 - 密级期限弹框 *******************************/
.@{mUploadClsPrefix}-secret-validity-dialog {
  background: var(--m-bg-base);
  .secret-validity-panel {
    height: 100%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    .tips {
      flex: 0;
      background: #e6edff;
      line-height: calc(20 * 1px);
      font-size: var(--font-size-12);
      padding: calc(14 * var(--hd)) calc(16 * var(--hd));
      background: #e6edff;
      .title {
        color: #5d9cec;
        line-height: calc(22 * var(--hd));
        font-size: var(--font-size-16);
      }
      .desc {
        color: #6aa6f3;
        text-align: justify;
        line-height: calc(22 * var(--hd));
        font-size: var(--font-size-14);
      }
    }
    .list {
      flex: 1;
      overflow: auto;
      height: 100%;
      padding-bottom: calc(52 * var(--hd));
    }
  }
  .secret-validity-item {
    border-bottom: var(--border-solid);
    padding: calc(10 * var(--hd)) calc(16 * var(--hd)) 0 calc(16 * var(--hd));
    width: 100%;
    .name {
      color: var(--main-fc);
      font-size: var(--font-size-14);
      white-space: pre-wrap;
      line-height: calc(22 * var(--hd));
    }
  }
  .@{mSecretSelectClsPrefix} {
    width: 100%;
    // margin-top: calc(10 * var(--hd));
    color: var(--main-fc);
    &-label-span {
      line-height: calc(20 * var(--hd));
    }
    // 保密等级
    &-secretlevel {
      display: none;
    }
    // 保密期限
    &-secretvalidity {
      &::before {
        display: none;
      }
      .ui-m-secret-select-secretvalidity {
        &-formSwitch {
          text-align: right;
        }
        &-label {
          display: flex;
          align-items: flex-start;
        }
        &-input {
          .ui-m-list-line {
            width: 100%;
          }
          input {
            text-align: right;
          }
          &-help-tip {
            display: none;
          }
        }
      }

      // .ui-formSwitch-help {
      //   display: none;
      // }
    }
  }
}
