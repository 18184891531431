@import './constants.less';
@import './log.m.less';
@import './upvote.m.less';
@import './rich.m.less';

.@{mCommentClsPrefix} {
  display: flex;
  flex-direction: column;

  &-text {
    line-height: 1.5;
  }

  &-search {
    display: none;
  }

  &-top {
    position: relative;
    background: var(--bg-base);
    padding-right: calc(70 * var(--hd));
    height: calc(40 * var(--hd));
    line-height: calc(40 * var(--hd));
    flex: none;

    &-options {
      position: absolute;
      right: 0;
      padding: 0 var(--comment-padding);
      top: 50%;
      transform: translateY(-50%);

      &>.@{iconClsPrefix} {
        cursor: pointer;

        &+.@{iconClsPrefix} {
          margin-left: var(--comment-margin);
        }
      }
    }

    &-title {
      padding: 0 calc(16 * var(--hd));
      font-weight: 400;
      color: var(--black-fc);
    }
  }

  &-content {
    flex: auto;
    overflow: hidden;
  }

  &-main {
    height: 100%;
  }

  &-body {
    padding-left: calc(45 * var(--hd));
    position: relative;

    &-not-avatar {
      padding-left: 0;
    }
  }

  &-item-box {
    margin-top: calc(8 * var(--hd));
  }

  &-edit {
    // padding: 0 var(--comment-padding);
    height: 100%;
    // background: #F7F7F7;

    &-text {
      height: 100%;
      overflow: auto;

      &:not(:last-child) {
        padding-bottom: calc(55 * var(--hd));
      }
    }
    
    &-mask {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      z-index: 99;
      transition: all 0.3s;
    }

    &-btns {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      pointer-events: initial;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: calc(-0.7 * var(--hd));
        height: var(--hd);
        transform: scaleY(0.3);
        background-color: var(--m-comment-group-border-color);
        width: 100%;
      }

      & &-submit.@{mBtnClsPrefix} {
        color: var(--primary);
      }

      .@{mBtnClsPrefix} {
        display: inline-block;
        font-size: var(--font-size-17);
        background: var(--bg-white);
        color: var(--regular-fc);
        border-radius: 0px;
        width: 100%;
        flex: auto;
        border: 0;
        padding: calc(16 * var(--hd));
        position: relative;

        &+.@{mBtnClsPrefix} {
          &::before {
            content: "";
            position: absolute;
            width: 0.5px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            height: calc(19 * var(--hd));
            background-color: #EBEBEB;
          }
        }

        &:focus {
          background: var(--bg-white);
        }

        &.disabled,
        &:disabled {
          background: var(--bg-white);
          opacity: 1;
        }
      }
    }
  }

  &-avatar {
    position: absolute;
    top: 0;
    left: 0;
  }

  &-list {
    font-size: var(--font-size-12);
    background-color: #F7F7F7;
    display: block;

    &-container {
      height: 100%;
    }

    &-empty {
      padding: calc(30 * var(--hd)) 0;
    }

    [data-rtl='true'].ui-m-list-footer-loading {
      transform: scaleX(1);
    }
  }

  &-option {
    padding: 12px 0;
    line-height: calc(var(--btn-line-height) + 12px);

    .@{iconClsPrefix} {
      cursor: pointer;
      color: var(--secondary-fc);
    }

    &>div {
      display: inline-block;
      line-height: calc(var(--btn-line-height) + 14px);
    }

    &::after {
      content: "";
      clear: both;
      display: block;
    }
  }

  &-username {
    font-size: var(--font-size-14);
    color: var(--secondary-fc);
    height: calc(18 * var(--hd));
    line-height: calc(18 * var(--hd));
  }

  &-to {
    padding: 0 calc(6 * var(--hd));
  }

  &-submit {
    float: right;
  }

  &-link {
    cursor: pointer;
    // &:hover {
    //   text-decoration: underline;
    // }
  }

  &-label {
    display: flex;

    &-title {
      vertical-align: top;
      flex: none;
      padding: calc(2 * var(--hd)) 0;
      color: var(--secondary-fc);
      font-size: var(--font-size-14);
      line-height: calc(17 * var(--hd));

      &::after {
        content: ":";
        padding: 0 10px 0 2px;
      }
    }

    &-children {
      flex: auto;
      overflow: hidden;
      .@{mUploadClsPrefix}{
        .@{mCommentClsPrefix}-custom-button{
          color: var(--regular-fc);
          padding: calc(12 * var(--hd)) 0;
          .@{mCommentClsPrefix}-detail-select-icon{
            color: var(--secondary-fc);
          }
          .@{mCommentClsPrefix}-arrow{
            margin-left: calc(12 * var(--hd));
          }
        }
      }
      .@{mCommentClsPrefix}-upload-hidden{
        height: 0;
        padding: 0;
        border: 0;
        visibility: hidden;
        .@{mUploadClsPrefix}-select{
          height: 0;
        }
      }
      .@{mCommentClsPrefix}-upload-visible{
        height: inherit;
        padding: calc(10 * var(--hd)) 0;
        border-top: calc(10 * var(--hd)) solid #f7f7f7;
        .@{mUploadClsPrefix}-select-label{
          padding: 0  calc(15 * var(--hd));
          font-size: calc(14 * var(--hd));
          &::after{
            height: 0;
          }
        }

      }
    }
  }
  &-associated-label{
    border-top:  calc(10 * var(--hd));
    border-color: #f7f7f7;
    border-style: solid;
    position: relative;
    &-count{
      position: absolute;
      right:  calc(60 * var(--hd));;
      // top: 20px;
      line-height:  calc(50 * var(--hd));;
      z-index: 1;
      color: #999999;
    }
      &::after{
        content: '';
        width: 100%;
        height: calc(10 * var(--hd));
        background-color: #F7F7F7;
      }
    .@{mCollapseClsPrefix}-panel{
      margin: 0;
      &__arrow{
        transform: rotate(-90deg);
      }
      &__content{
        padding: 0;
        margin-left: calc(13 * var(--hd));;
      }
    }
    .ui-m-comment-associated-item{
      &::before{
        margin-right: calc(16 * var(--hd));
        content: '';
        height: calc(5 * var(--hd));;
        width: calc(5 * var(--hd));;
        border-radius: 100%;
        background-color: #999999;
      }
    }
  }

  &-at {
    color: var(--m-comment-link-color);
  }

  [data-rtl='true']&-at{
    display: inline-block;
    direction: ltr;
  }

  &-address {
    display: inline-flex;
    color: var(--secondary-fc);
    align-items: center;
    font-size: var(--font-size-12);
    max-width: 100%;

    span.@{mCommentClsPrefix}-link {
      color: var(--secondary-fc);
      padding-right: calc(5 * var(--hd));
      flex: 0 1 100%;
    }

    .@{iconClsPrefix} {
      flex: none;
      position: relative;
      left: calc(-3 * var(--hd));
    }
  }

  &-attachments {
    display: flex;
    flex-wrap: wrap;

    &-imgbox {
      margin: 0 var(--comment-margin) var(--comment-margin) 0;
    }

    &-img {
      height: var(--m-comment-img-size);
      width: var(--m-comment-img-size);
      position: relative;
      overflow: hidden;
      border-radius: 3px;
      border: 1px solid var(--border-color);
      cursor: pointer;

      &>img {
        width: 100%;
        user-select: none;
        height: auto;
        max-width: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        max-height: inherit;
      }
    }
  }

  &-start-btn {
    width: calc(var(--hd) * 44);
    height: calc(var(--hd) * 44);
    line-height: calc(var(--hd) * 44 * 0.9);
    position: fixed;
    right: calc(var(--hd) * 45);
    bottom: calc(var(--hd) * 120);
    display: block;
    border-radius: 50%;
    background-color: var(--primary);
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;

    .@{iconClsPrefix} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    svg {
      width: calc(var(--hd) * 30);
      height: calc(var(--hd) * 30);
      line-height: calc(var(--hd) * 30 * 0.9);
      color: var(--base-white);
    }
  }

  &-signature {

    &-container {
      padding: calc(10 * var(--hd)) calc(12 * var(--hd)) calc(5 * var(--hd));

      & > * + * {
        margin-left: calc(10 * var(--hd));
      }
    }
  }

  &-item {
    display: inline-block;
    width: 100%;
    padding: 10px 16px;
    position: relative;
    background-color: #FFFFFF;

    &.@{commentClsPrefix}-quote-content {
      background-color: var(--bg-base);
    }

    &:not(:last-child) {
      margin-bottom: calc(10 * var(--hd));
    }

    &&-background {
      background: #F7FDFF;
    }

    &-avatar {}

    & &-associated {
      margin-bottom: 0;
      padding-top: calc(5 * var(--hd));
    }

    &-mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
    
    &-privy {
      display: block;
      float: right;
      background: #FFFFFF;
      border: 1px solid #E5E5E5;
      border-radius: calc(3 * var(--hd));
      padding: 0 calc(5 * var(--hd));
      position: relative;
      top: calc(-4 * var(--hd));
      height: calc(24 * var(--hd));
      line-height: calc(22 * var(--hd));
      color: var(--main-fc);
    }

    &-header {
      padding: var(--comment-margin) 0;
    }

    .ui-rtl {
      .@{mCommentClsPrefix}-item-header {
        transform: scaleX(-1);

        .@{mCommentClsPrefix}-link {
          display: inline !important;
        }
      }

      .@{mCommentClsPrefix}-item-content {
        .ui-m-matters-associated-item-title {
          transform: scaleX(1) !important;
        }

        .ui-m-matters-associated-item-browser {
          .ui-m-list-item {
            .ui-m-list-item-row {
              .ui-browser-panel-list-template-content-wrapper {
                > .ui-rtl-inline {
                  transform: scaleX(1) !important;
                }
              }
            }
          }
        }
      }

      .@{mCommentClsPrefix}-item-footer {
        .ui-m-comment-item-from {
          transform: scaleX(1) !important;
        }
      }
    }

    &-footer {
      &-set {
        &::after {
          content: "";
          clear: both;
          display: block;
        }
      }

      &-vote:not(&-vote-nodata) {
        .border-top-solid-then;
        margin-top: calc(14 * var(--hd));
        padding-top: calc(11 * var(--hd));
      }

      &-menu {

        &-icon {

          color: var(--regular-fc);

          .@{iconClsPrefix} {
            &-svg {
              width: calc(24 * var(--hd));
              height: calc(24 * var(--hd));
            }
          }
        }
      }

      &-info {
        min-height: calc(18 * var(--hd));
        display: flex;
        align-items: center;
        float: left;
      }

      &-btns {
        float: right;

        &>*+* {
          margin-left: calc(16 * var(--hd));
        }
      }
    }

    &-header,
    &-footer {
      color: var(--secondary-fc);
    }

    &-reply {
      &>div:first:hover {
        text-decoration: underline;
      }

      &>div:not(:first-child) {
        margin-top: var(--comment-margin);
      }

      &-header {
        display: inline-block;
      }

      .@{commentClsPrefix}-html {
        color: var(--secondary-fc);
      }
    }

    &-content {
      color: var(--regular-fc);
      margin-bottom: var(--comment-margin);
      white-space: pre-wrap;

      &>div {
        margin-bottom: var(--comment-margin);
        word-break: break-word;
        white-space: pre-wrap;
      }
    }

    &-text {
      font-size: var(--font-size-14);
      color: var(--main-fc);
      white-space: pre-wrap;
    }

    &-text-rich {
      white-space: unset;
    }

    &-from {
      padding-right: calc(4 * var(--hd));
    }

    &-other {
      width: 100%;
      height: 0px;
      transition: height 0.3s;
    }

    &-other&-active {
      height: auto;
      min-height: 80px;
      padding-top: 12px;
    }

    &.@{mListClsPrefix}-item .@{mListClsPrefix}-content {
      border-bottom: calc(10 * var(--hd)) solid var(--border-color);
    }

    .@{mListClsPrefix}-item {
      background-color: inherit;
    }
  }

  &-floor {
    margin-top: calc(11 * var(--hd));
    font-size: var(--font-size-14);

    .@{commentClsPrefix}-floor-change-btn, .@{commentClsPrefix}-floor-change-title {
      color: var(--primary);
    }

    .@{commentClsPrefix}-floor-change-btn {
      height: calc(44 * var(--hd));
    }

    .@{commentClsPrefix}-floor-content {
      margin: 0 calc(10 * var(--hd));
      border-top-style: dashed;
    }

    .@{commentClsPrefix}-item-text {
      font-size: var(--font-size-14);
    }

    .@{formItemClsPrefix}-item-placeholder {
      background-color: var(--bg-base);
    }

  }

  // &-textarea-filelist {
  //   .@{mUploadClsPrefix}-listB-listitem-cancel-icon {
  //     display: none;
  //   }
  // }

  &-item-filelist,
  &-textarea-filelist {
    .@{mListClsPrefix}-column .@{mListClsPrefix}-body .@{mListClsPrefix}-item {
      .@{mListClsPrefix}-content {
        border: none;
        min-height: auto;
      }
    }
  }

  &-upload {
    &-detail {
      height: calc(100% - (45 * var(--hd)));
      overflow: auto;
      padding: calc(16 * var(--hd));
    }

    &-list {
      padding: var(--m-comment-margin-size) calc(16 * var(--hd));
    }

    // &-button&-hidden {
    //   padding: 10px 15px;
    //   border-top: 10px solid #f9f9f9;
    //   background-color: red;
    //   // .ui-m-comment-custom-button{
    //   //   color: #666666;
    //   //   .ui-m-comment-detail-select-icon{
    //   //     color: #999999;
    //   //   }
    //   //   .ui-m-comment-arrow{
    //   //     margin-left: 12px;
    //   //   }
    //   // }
    //   // .ui-m-upload-select{
    //   //   display: none;
    //   // }
    // }
  }

  &-custom-upload {
    display: inline-block;
    position: relative;

    &-input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      opacity: 0;
    }
  }

  &-iconbtn {
    cursor: pointer;
    user-select: none;
    display: inline-block;

    &>div {
      display: inline-block;
      vertical-align: middle;
    }

    &-num {
      height: calc(16 * var(--hd));
      line-height: calc(16 * var(--hd));
    }
  }

  /* 评论文本框 */
  &-textarea {
    margin-bottom: var(--m-comment-margin-size);

    &>* {
      margin-bottom: var(--m-comment-margin-size);
    }

    &-header {
      margin-bottom: 0;
      background-color: var(--bg-white);

      &-tag {
        display: inline-block;
        margin: calc(14 * var(--hd)) calc(12 * var(--hd)) 0;
        height: calc(30 * var(--hd));
        border-radius: calc(15 * var(--hd));
        display: inline-flex;
        padding: calc(3 * var(--hd)) calc(13 * var(--hd));
        align-items: center;
        font-size: var(--font-size-14);
        background-color: var(--m-comment-bgcolor);

        .@{iconClsPrefix} {
          color: var(--secondary-fc);
          margin-left: calc(5 * var(--hd));
        }
      }

      .@{mcommonPhraseClsPrefix} {
        display: flex;
        align-items: center;
      }

    }

    &-content {
      &.@{mTextareaClsPrefix}-item {
        border-bottom: 0;
      }

      .@{mTextareaClsPrefix}-control {
        padding-left: calc(18 * var(--hd));
        padding-right: calc(18 * var(--hd));
      }

      .@{mTextareaClsPrefix}-count {
        span {
          color: var(--color-text-placeholder);
        }
      }
      &.border-solid-then::after {
        width: 100%;
      }

      textarea {
        font-family: var(--regular-ff);
      }
    }

    &-recording {
      padding: 0 calc(16 * var(--hd)) calc(12 * var(--hd));

      &>*:not(:last-child) {
        margin-bottom: calc(8 * var(--hd));
      }
    }

    &-body {
      background-color: var(--m-comment-bgcolor-white);

      textarea {
        font-size: var(--font-size-14);
      }
    }

    &-config {
      padding: var(--m-comment-margin-size);
      color: var(--secondary-fc);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-desensitization{
      color: var(--link-fc);
      font-size: var(--font-size-12);
      cursor: pointer;
    }
    &-setting {
      &>* {

        flex-basis: calc(100% / 6);
      
        display: flex !important;
        justify-content: center;
      }
      display: flex;
      width: 100%;

      .@{mUploadClsPrefix} {
        width: auto;
      }
      
      .@{iconClsPrefix} {
        .ui-icon-svg{
          width: 28px;
          height: 28px;
        }
      }
    }

    // &-filelist {
    //   padding: calc(8 * var(--hd)) calc(15 * var(--hd)) var(--m-comment-margin-size);
    //   background-color: #FFFFFF;
    //   // border-top: 10px;
    //   // border-color: #f9f9f9;
    //   // border-style: solid;
    // }

    &-associated {
      padding: 0;
      .ui-m-list-scrollview{
        overflow: inherit;
      }
    }

    &-config > *:not(&-private) {
      // margin-right: var(--m-comment-margin-size);
      flex: none;
    }

    &-private {
      flex: auto;
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      color: var(--secondary-fc);
      flex: none;
      margin-left: calc(16 * var(--hd));

      &&-focus {
        border-color: var(--primary);
        color: var(--primary);
      }
      
      .@{iconClsPrefix} {
        margin-right: calc(6 * var(--hd));
      }

      .@{mCcheckboxClsPrefix}-label, .@{mCcheckboxClsPrefix}-left {
        padding: 0;
      }

    }

    /* 表情 */
    &-expression {
      height: 0;
      overflow: hidden;
      transition: all 0.3s;
      padding: 0 var(--m-comment-padding-16);

      &-active {
        height: inherit;
      }
    }

    &-more {
      background-color: var(--m-comment-bgcolor-white);
      margin-top: var(--m-comment-margin-size);
    }

    &-address, &-private {
      background-color: var(--m-comment-bgcolor-white);
      padding: 0 calc(10 * var(--hd));
      height: calc(30 * var(--hd));
      line-height: calc(30 * var(--hd));
      border: var(--comment-grey-border);
      border-radius: calc(15 * var(--hd));
      font-size: var(--font-size-12);
    }

    &-address {
      overflow: hidden;

      &-wrapper{
        overflow: hidden;
      }
      .@{mCommentClsPrefix}-address {
        color: var(--primary);

        &-empty {
          color: var(--secondary-fc);
        }

        &-close {
          color: var(--secondary-fc);
        }

        .@{mCommentClsPrefix}-link {
          color: var(--primary);
        }
      }
    }

    &-info {
      padding: calc(8 * var(--hd)) calc(15 * var(--hd));
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-imglist{
      .@{mCommentClsPrefix}-imgupload-hidden{
        visibility: hidden;
        height: 0;
      }
      .@{mCommentClsPrefix}-imgupload-visible{
        visibility: inherit;
        height: inherit;
        .@{mCommentClsPrefix}-label-title{
          padding: 15px;
        }
      }
      .@{mCommentClsPrefix}-label-children{
        margin-bottom: 10px;
        .@{mUploadClsPrefix}-listT-item-imglist{
          // height: auto;
          // width: auto;
          // margin-right: 20px;
          // margin-bottom: 7px;
        }
      }
      .@{mUploadClsPrefix}-select{
        height: 0;
        visibility: hidden;
      }
    }

  }

  &-upload {
    &-button.@{mBtnClsPrefix} {
      position: fixed;
      bottom: 0;
      border-radius: 0;
      background-color: #FFFFFF;
      border: 0;
      height: calc(45 * var(--hd));
      border-top: var(--hd) solid var(--m-btn-default-border-color);
    }

  }

  &-associated {
    &-item {
      padding-top: calc(3 * var(--hd));
      padding-bottom: calc(5 * var(--hd));
      display: flex;
      align-items: center;
    }

    &-name {
      color: var(--m-comment-link-color);
      font-size: var(--font-size-12);
      flex: auto;
      line-height: calc(17 * var(--hd));
      .comment-ellipsis();
    }

    &-close {
      color: var(--secondary-fc);
      flex: none;
    }
  }

  &-btns {
    flex: none;
  }

  &-menubtns {
    padding: var(--m-comment-margin-size) 0;
  }

  &-reply {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: var(--m-bg-base);

    &-body {
      flex: auto;
      overflow-y: auto;
      margin-bottom: calc(55 * var(--hd));
    }

    &-text,
    &-number,
    &-address {
      background-color: var(--m-comment-bgcolor-white);
      margin-bottom: var(--m-comment-margin-size);
    }

    &-number {
      padding: calc(12 * var(--hd)) calc(16 * var(--hd));
      color: var(--main-fc);
      font-size: var(--font-size-14);

      .@{iconClsPrefix} {
        float: right;
      }
    }

    &-count {
      color: var(--m-comment-link-color);
      padding: 0 calc(4 * var(--hd));
    }

    &-quote {
      padding: calc(8 * var(--hd));
      background: #fff;
      margin-bottom: 0;

      &-content {
        border: 0;
      }

      .@{mCommentClsPrefix}-label-title, .@{mMattersAssociatedClsPrefix}-item-title {
        font-size: var(--font-size-14);
      }

    }
  }

  &-quote {
    font-size: var(--font-size-14);

    .@{formItemClsPrefix}-item-placeholder {
      background-color: var(--bg-base);
    }

  }
  &-more-Container {
    display: flex;
    flex-wrap: wrap;
    .ui-icon{
      border-radius: 6px;
      margin:0 12px;
      background-color: #fff;
      .ui-icon-svg{
        width: 44px;
        height: 44px;
        padding: 10px;
      }

    }
  }
}

.@{mCommentClsPrefix}-menu {
  display: flex;
  flex-direction: column;

  & &-header {
    .@{mMenuClsPrefix}-list-item.@{mMenuClsPrefix}-list-item-active .@{mMenuClsPrefix}-list-item-content {
      &::after {
        bottom: 0;
      }
    }
  }

  &-header {
    flex: none;

    .@{mMenuClsPrefix}-list-item {
      &-content {
        font-size: var(--font-size-15);
      }

      &-active {
        font-weight: 600;
        color: var(--main-fc);
      }
    }
  }

  &-content {
    flex: auto;
    overflow: hidden;
  }
}

/* MCommentGroup样式 */
.@{mCommentClsPrefix}-card {
  margin-top: calc(10 * var(--hd));

  &-top {
    background: #FFFFFF;
    color: var(--m-comment-link-color);
    font-weight: 400;
    padding: 0 calc(16 * var(--hd));
    height: var(--m-comment-group-header-height);
    line-height: var(--m-comment-group-header-line-height);
    border-bottom: var(--hd) solid #F2F2F2;
  }

  &-browser {
    .@{mBrowserClsPrefix}-associative-inner {
      line-height: calc(38 * var(--hd));
    }

    .@{mBrowserClsPrefix}-associative .@{mBrowserClsPrefix}-associative-selected {
      padding: 0;
      height: var(--m-comment-group-header-line-height);

      .@{mListClsPrefix}-body {
        text-align: left;
        padding: 0;

        .@{mListClsPrefix}-item {
          &::before {
            content: none;
          }

          & .@{mListClsPrefix}-item-row {
            font-size: var(--font-size-14);
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          .@{mListClsPrefix}-content {
            font-size: var(--font-size-14);
            margin: 0;
            height: var(--m-comment-group-header-line-height);
          }
        }
      }

      .@{mListClsPrefix}-item {
        width: 100%;
        margin: 0;
      }

      .@{mBrowserClsPrefix}-associative-selected-item {
        max-width: inherit;
      }
    }
  }
}

.@{mCommentClsPrefix}-group {
  max-height: 100%;
  overflow: auto;

  &-list {
    display: block;

    .@{mCommentClsPrefix}-card {
      margin: 0;

      &-list {
        border-bottom: var(--hd) solid #F2F2F2;
      }
    }
  }

  &-container {
    &-nodata {
      border-bottom: var(--hd) solid var(--m-comment-group-border-color);
    }
  }

  .@{mCollapseClsPrefix}-panel .@{mCollapseClsPrefix}-panel__title {
    margin: 0;
    padding: calc(10 * var(--hd)) calc(16 * var(--hd));
    border: 0;
    background: #F7F7F7;
  }

  .@{mCommentClsPrefix}-item:not(:last-child) {
    margin-bottom: 0;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      height: var(--hd);
      transform: scaleY(0.5);
      background-color: var(--m-comment-group-border-color);
      width: calc(100% - (60 * var(--hd)));
      // transform: scaleY(0.3);
    }
  }

  .@{mCommentClsPrefix}-card:not(:last-child) {
    border-bottom: calc(10 * var(--hd)) solid #F7F7F7;
  }

  .@{mCollapseClsPrefix}-panel {
    margin: 0;

    &--inactive {
      border-bottom: var(--hd) solid var(--m-comment-group-border-color);
    }

    &__content {
      padding: 0;

      &-box {
        margin: 0;
        line-height: initial;
      }
    }
  }

  &-item {
    flex: auto;
    display: flex;
    justify-content: space-between;
    padding-right: calc(14 * var(--hd));
  }

  &-title {
    font-size: var(--font-size-14);
  }

  &-total {
    color: var(--secondary-fc);
  }
}

.@{mCommentClsPrefix}-replylist {
  height: 100%;
}

.@{mCommentClsPrefix} {
  &-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.@{mCommentClsPrefix}-more-container{
  // .@{mDialogClsPrefix}-top {
  //   // background-color: red;
  //   border-radius: 0;
  //   padding: 24px 18px;
  // }
  .@{mActionSheetClsPrefix}-grid-list-main{
    background-color: var(--m-bg-base);
    &-content{
      background-color: var(--m-bg-base);
    }
  }
}
.@{mDialogClsPrefix}-routeLayout{
  .desensitizationConfig{
    .ui-form-col{
      box-sizing: content-box;
      // border-right: none;
      // border-left: none;
    }
  }
  .secondAuth-hidden{
    border-bottom:none !important;
  }
  .secondAuth-vis{
    border-bottom-width:1px;
  }
}
.@{mCommentClsPrefix}-associated-mbrowser{
  &-outer{
    border-top: 10px solid #F7F7F7;
  }
  &-inner{
    padding: calc(15 * var(--hd));
    font-size: calc(14 * var(--hd));
  }
}

