
.@{confirmClsPrefix} {
  z-index: var(--dialog-confirm-zindex);
  height: 100%;
  &-mask {
    z-index: var(--dialog-confirm-zindex);
  }
  &-content {
    width: var(--confirm-width);
    border-radius: var(--dialog-border-radius);
    margin: 0;
    &-center {
      margin: 0 auto;
    }
    &-fadeIn {
      animation-duration: var(--dialog-animation-time);
      -webkit-animation-duration:  var(--dialog-animation-time);
      -o-animation-duration:  var(--dialog-animation-time);
      -moz-animation-duration:  var(--dialog-animation-time);
      animation-name: ConfirmFadeIn;
      -webkit-animation-name: ConfirmFadeIn;
      -o-animation-name: ConfirmFadeIn;
      -moz-animation-name: ConfirmFadeIn;
    }
  }
  &-header {
		position: relative;
    border-bottom: var(--border-solid);
    padding: calc(14 * var(--hd)) calc(16 * var(--hd)) calc(12 * var(--hd)) calc(16 * var(--hd));
  }
  &-title {
    font-size: var(--font-size-base);
    color: var(--main-fc);
    font-weight: var(--font-weight-bold);
    display: inline-block;
    vertical-align: middle;
    padding-left: var(--h-spacing-md);
  }
  &-icon { // 等图标替换
    color: #FAAD14;
    width: calc(24 * var(--hd));
    height: calc(24 * var(--hd));
    .@{iconClsPrefix}-lg {
      width: 100%;
      height: 100%;
    }
  }
  &-closeIcon {
    position: absolute;
    right: calc(10 * var(--hd));
    top: calc(10 * var(--hd));
  }
  &-body {
    height: 100%;
    background-color: var(--bg-white);
    color: var(--main-fc);
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: calc(30 * var(--hd));
    font-size: var(--font-size-12);
    &-center {
      margin: 0 auto;
    }
    &-auto {
      min-height: calc(123 * var(--hd));
    }
  }
  &-middle {
    display: flex;
    align-items: center;
  }
  &-footer {
    padding: var(--v-spacing-md) var(--h-spacing-lg);
    background-color: var(--bg-white);
    border-radius: var(--dialog-border-radius);
    text-align: right;
    & button {
      margin-left: var(--h-spacing-md);
      display: inline-flex;
    }
  }
  &-layout {
    height: 100%;
  }
}

@keyframes ConfirmFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(.8);
    transform: scale(.8);
    -webkit-transform-origin:  0 100%;
    transform-origin: 0 100%;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin:  0 100%;
    transform-origin: 0 100%;
  }
}

// 镜像
.@{confirmClsPrefix}.needRTL {
  .@{confirmClsPrefix}-title {
    padding-right: var(--h-spacing-md);
    padding-left: 0;
  }
}