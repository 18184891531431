.@{mUploadClsPrefix}-batch {
    &-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &-left {
        
    }
    &-right {
        margin-left: calc(8 * var(--hd));
        display: flex;
        flex-direction: column;
    }
    
    &-name {
        font-size: var(--font-size-16);
        color: var(--main-fc);
        font-weight: 400;
        max-height: calc(60 * var(--hd));
        word-wrap: break-word; 
        word-break: break-word;
        padding: calc(5 * var(--hd)) 0;
        
        // overflow: hidden;
    }

    &-msg {
        padding: calc(5 * var(--hd)) 0;
        font-size: var(--font-size-12);
        color: var(--secondary-fc);
    }
    
}