// map m_map 公共样式
.@{mapClsPrefix},
.@{mMapClsPrefix} {
  // 只读不显示信息窗口的删除按钮
  &-baidu-readOnly {
    .BMap_bubble_pop .BMap_bubble_buttons {
      display: none;
    }
  }

  &-gaode-readOnly {
    .amap-info .amap-info-close {
      display: none;
    }
  }

  &-tencent-readOnly {
    img[src="https://mapapi.qq.com/jsapi_v2/2/4/142/theme/default/imgs/infowindow_close.png"]
    {
      display: none;
    }
  }

  &-google {
    .gm-style > div {
      // 去掉focus的时候的蓝色边框
      border: 0 !important;
    }
  }

  &-google-readOnly {
    .gm-ui-hover-effect {
      display: none !important;
    }
  }

  //联想搜索面板
  &-search-input-wrap {
  }

  // 切换弹框面板
  &-panel {
    background: var(--bg-white);

    &-title {
      padding-bottom: var(--avatar-size-lg);
    }

    &-list {
      display: flex;
      flex-direction: row;

      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        font-size: var(--font-size-12);
        cursor: pointer;

        &-active {
          .@{mapClsPrefix}-panel-list-item-img {
            border-color: var(--primary);
          }

          .@{mapClsPrefix}-panel-list-item-radio {
            color: var(--primary);
            border: 0;
          }

          .@{mMapClsPrefix}-panel-list-item-img {
            border-color: var(--primary);
          }

          .@{mMapClsPrefix}-panel-list-item-radio {
            color: var(--primary);
            border: 0;
          }
        }

        &-img {
          border: var(--hd) solid transparent;
          margin-bottom: calc(9 * var(--hd));
          border-radius: calc(7 * var(--hd));
          width: calc(42 * var(--hd));
          height: calc(42 * var(--hd));
          box-sizing: border-box;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &-title {
          margin-bottom: calc(5 * var(--hd));
        }

        &-radio {
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(16 * var(--hd));
          height: calc(16 * var(--hd));
          border-radius: 50%;
          border: var(--hd) solid var(--map-border-color);
          box-sizing: border-box;

          .ui-icon-svg {
            width: calc(18 * var(--hd));
            height: calc(18 * var(--hd));
          }
        }
      }
    }
  }

  // 信息窗口样式
  &-baidu-infowin,
  &-gaode-infowin,
  &-tencent-infowin,
  &-google-infowin {
    font-size: var(--font-size-14);
    color: var(--main-fc);
    font-weight: 600;
  }

  &-baidu-infowin {
    width: 100%;
    padding-left: var(--v-spacing-lg);
    padding-right: calc(24 * var(--hd));
  }

  &-gaode-infowin {
    max-width: calc(240 * var(--hd));
    padding: calc(14 * var(--hd));
    padding-right: var(--v-spacing-md);
  }

  &-tencent-infowin {
    max-width: calc(240 * var(--hd));
    min-height: calc(60 * var(--hd));
    padding: calc(14 * var(--hd)) var(--v-spacing-md);
  }

  &-google-infowin {
    max-width: calc(240 * var(--hd));
    min-height: calc(60 * var(--hd));
    padding: calc(14 * var(--hd)) var(--v-spacing-md);
  }

  // 腾讯地图样式复写: 不显示腾讯 地图|卫星 切换
  .smnoprint {
    display: none;
  }
}

.@{mapClsPrefix}-search-input,
.@{mMapClsPrefix}-search-input {
  &-suffix {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &-line {
    width: 0;
    height: 60%;
    border-right: var(--hd) solid var(--diviling-line-color);
    margin: 0 var(--v-spacing-sm);
  }

  &-text {
    color: var(--primary);
    cursor: pointer;
  }
}

.@{mapClsPrefix}-search-input-onmap,
.@{mMapClsPrefix}-search-input-onmap {
  box-sizing: border-box;
  z-index: var(--map-wrap-zindex);
}

.@{mapClsPrefix}-search-input-trigger {
  font-size: var(--font-size-12);
}

.@{mMapClsPrefix}-search-input-trigger {
  font-size: var(--font-size-14);
}

.@{mapClsPrefix}-search-input-trigger,
.@{mMapClsPrefix}-search-input-trigger {
  width: 100%;

  .@{mapClsPrefix}-search-input-list,
  .@{mMapClsPrefix}-search-input-list {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    background: var(--bg-white);
    cursor: pointer;
    box-sizing: border-box;
    margin-top: var(--v-spacing-xs);
    padding: var(--v-spacing-md) 0;
    width: calc(320 * var(--hd));
    .@{mapClsPrefix}-search-input-item,
    .@{mMapClsPrefix}-search-input-item {
      width: 100%;
      height: calc(30 * var(--hd));
      line-height: calc(30 * var(--hd));
      display: flex;
      align-items: center;
      word-break: keep-all;
      white-space: nowrap;
      flex-wrap: nowrap;
      overflow: hidden;

      .@{mapClsPrefix}-search-input-icon,
      .@{mMapClsPrefix}-search-input-icon {
        margin-right: var(--v-spacing-sm);
        color: var(--secondary-fc);
        margin-left: var(--v-spacing-md);
        line-height: 0;
      }

      .@{mapClsPrefix}-search-input-description,
      .@{mMapClsPrefix}-search-input-description {
        flex: 1;
        margin: 0 var(--v-spacing-md);
        color: var(--secondary-fc);
        overflow-x: hidden;
        text-overflow: ellipsis;
      }

      .@{mapClsPrefix}-search-input-name,
      .@{mMapClsPrefix}-search-input-name {
        color: var(--main-fc);
        overflow-x: hidden;
        text-overflow: ellipsis;

        .@{mapClsPrefix}-search-input-highlight,
        .@{mMapClsPrefix}-search-input-highlight {
          color: var(--primary);
        }
      }
    }
  }
}

// 信息窗口样式复写
.BMap_bubble_content {
  max-width: calc(300 * var(--hd));
  margin-top: calc(-12 * var(--hd));
}

.amap-info-close {
  right: calc(5 * var(--hd)) !important;
}

.amap-container .amap-icon img {
  max-width: 100% !important;
  max-height: 100% !important;
}