.@{cascaderInputClsPrefix} {
  &-menus {
    // position: absolute;
    z-index: var(--progress-zindex);
    font-size: var(--font-size-12);
    white-space: nowrap;
    background: var(--bg-white);
    border: var(--border-solid);
    box-shadow: var(--box-shadow-storke);
    border-radius: var(--border-radius-xs);

    ul,
    ol {
      margin: 0;
      list-style: none;
    }

    &>div {
      display: flex;
    }

    &-empty,
    &-hidden {
      display: none;
    }

    &.@{cascaderClsPrefix}-slide-up-enter.@{cascaderClsPrefix}-slide-up-enter-active&-placement-bottomLeft,
    &.@{cascaderClsPrefix}-slide-up-appear.@{cascaderClsPrefix}-slide-up-appear-active&-placement-bottomLeft {
      animation-name: weappSlideUpIn;
    }

    &.@{cascaderClsPrefix}-slide-up-enter.@{cascaderClsPrefix}-slide-up-enter-active&-placement-topLeft,
    &.@{cascaderClsPrefix}-slide-up-appear.@{cascaderClsPrefix}-slide-up-appear-active&-placement-topLeft {
      animation-name: weappSlideDownIn;
    }

    &.@{cascaderClsPrefix}-slide-up-leave.@{cascaderClsPrefix}-slide-up-leave-active&-placement-bottomLeft {
      animation-name: weappSlideUpOut;
    }

    &.@{cascaderClsPrefix}-slide-up-leave.@{cascaderClsPrefix}-slide-up-leave-active&-placement-topLeft {
      animation-name: weappSlideDownOut;
    }
  }

  &-menu {
    display: inline-block;
    min-width: calc(140 * var(--hd));
    // min-width: 111px;
    height: calc(196 * var(--hd));
    margin: 0;
    padding: calc(4 * var(--hd)) 0;
    overflow: auto;
    vertical-align: top;
    list-style: none;
    // border-right: var(--border-solid);
    border-left: var(--border-solid);
    // -ms-overflow-style: auto; // https://github.com/ant-design/ant-design/issues/11857
    -ms-overflow-style: auto;

    &::-webkit-scrollbar {
      width: 0;
    }


    &:first-child {
      border-radius: var(--border-radius-xs) 0 0 var(--border-radius-xs);
    }

    &:last-child {
      margin-right: calc(-1 * var(--hd));
      border-right-color: transparent;
      border-radius: 0 var(--border-radius-xs) var(--border-radius-xs) 0;
    }

    &:only-child {
      border-radius: var(--border-radius-xs);
    }
  }

  &-menu-item {
    padding: calc(5 * var(--hd)) calc(12 * var(--hd));
    overflow: hidden;
    line-height: calc(20 * var(--hd));
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: var(--label-bc);
    }

    &-disabled {
      color: var(--invalid-bc);
      cursor: not-allowed;

      &:hover {
        background: var(--bg-transparent);
      }
    }

    .@{cascaderClsPrefix}-menu-empty & {
      color: var(--invalid-bc);
      cursor: default;
      pointer-events: none;
    }

    &-active:not(&-disabled) {

      &,
      &:hover {
        background-color: var(--label-bc);
      }
    }

    &-expand {
      position: relative;
      padding-right: calc(24 * var(--hd));
    }

    &-expand &-expand-icon,
    &-loading-icon {
      position: absolute;
      right: calc(12 * var(--hd));
      color: var(--regular-fc);
      font-size: var(--font-size-10);

      .@{cascaderClsPrefix}-menu-item-disabled& {
        color: var(--invalid-bc);
      }
    }

    & &-keyword {
      color: #F5222D;
    }

    .@{checkboxClsPrefix}-wrapper {
      margin: var(--hd) calc(8 * var(--hd)) var(--hd) 0;
    }
  }

  &-dropdown {
    z-index: var(--progress-zindex);
  }
}