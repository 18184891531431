.@{swipeActionClsPrefix} {
  overflow: hidden;
  position: relative;
  height: 100%;
  &-content {
    position: relative;
    height: 100%;
    background-color: var(--bg-white);
  }

  &-cover {
    position: absolute;
    z-index: 2;
    background: var(--bg-transparent);
    height: 100%;
    width: 100%;
    top: 0;
    display: none;
  }

  & .@{swipeActionClsPrefix}-content,
  & .@{swipeActionClsPrefix}-actions {
    transition: all 250ms;
  }
  &-swiping {
    & .@{swipeActionClsPrefix}-content,
    & .@{swipeActionClsPrefix}-actions {
      transition: none;
    }
    .am-list-item-active {
      background-color: var(--bg-white);
    }
  }
  &-actions {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    overflow: hidden;
    white-space: nowrap;

    &-left {
      left: 0;
    }

    &-right {
      right: 0;
    }
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 0;
    padding: 0;
    border: none;
    &-text {
      padding: 0 calc(var(--hd) * 8);
      height: 100%;
      // width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.@{swipeActionClsPrefix}-wrap.need-mirror-RTL {
  .@{swipeActionClsPrefix} {
    transform: scaleX(-1);
    &-cover,
    &-actions,
    &-content {
      transform: scaleX(-1);
    }
  }
}
