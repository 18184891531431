.@{mGridCheckboxClsPrefix}-wrap {
  width: 100%;
  display: flex;
  flex-flow: wrap;
}

.@{mGridCheckboxClsPrefix}-wrap-rtl {
  transform: scaleX(-1);
}

.@{mGridCheckboxClsPrefix}-content {
  height: calc(30 * var(--hd));
  border-bottom: var(--border-solid);
  border-right: var(--border-solid);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &-left {
    border-left: var(--border-solid);
  }

  &-top {
    border-top: var(--border-solid);
  }

  &-span {
    color: var(--main-fc);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-span-rtl {
    transform: scaleX(1);
  }

  &-icon {
    position: absolute;
    bottom: calc(4 * var(--hd));
    right: calc(8 * var(--hd));
    color: var(--primary);
  }
}