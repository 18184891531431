.@{audioClsPrefix} {
  &-audio audio {
    width: 100%;
  }

  &-dialog {
    .@{dialogClsPrefix}-content {
      min-height: unset !important;
      min-width: unset !important;
    }
    .@{dialogClsPrefix}-body {
      padding: 0;
    }
    .@{dialogClsPrefix}-body-container {
      min-height: unset !important;
      min-width: unset !important;
    }
  }

  &-rtl {
    transform: scaleX(-1);
  }

  &-audio {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-audio-rtl {
    transform: scaleX(-1);
  }
}