
@import url(./uploader.m.less); // 上传区域样式
@import url(./fileItem.m.less); // 已上传区域样式
@import url(./reNameDialog.m.less); // 重复名称校验弹框样式
@import url(./sensitiveWords.m.less); // 敏感词检测弹框样式
@import url(./outerPanel.m.less); // 外层容器样式
@import url(./insidePanel.m.less); // 内层容器样式
@import url(./batchEdit.m.less); // 批量操作容器样式
@import url(./batchProgress.m.less); // 进度条样式
@import url(./secretValidityDialog.m.less); // 本地标密密级期限填写弹框组件
@import url(./secretSelectDialog.m.less); // 本地标密弹框组件


.@{mUploadClsPrefix}{
  width: 100%;
  position: relative;
  &-list .@{sliderClsPrefix}-handle {
    display: none;
  }
  .@{mListClsPrefix}-column .@{mListClsPrefix}-body .@{mListClsPrefix}-item .@{mListClsPrefix}-content::after {
    display: none;
  }
  &-img::after {
    content: '';
    display: block;
    clear: both;
  }
}


/** MUploadDetail 样式 **/
.@{mUploadClsPrefix}-detail {
  height: 100%;
  .@{mUploadClsPrefix}{
    height: 100%;
  }
}

.@{mUploadClsPrefix}-img.read-only-line-height-standard {
  margin-bottom: calc(10* var(--hd));
}