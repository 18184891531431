/*********** SensitiveWordsDialog  ***********/

.@{uploadClsPrefix}-sensitiveWords-table-words{ 
 color: var(--danger);
}
.@{uploadClsPrefix}-sensitiveWords-content {
  &-info {
    border: var(--hd) solid var(--warning);
    background: rgba(250, 173, 20, 0.2);
    padding: calc(10 * var(--hd));
    display: flex;
    align-items: center;
    margin-bottom: calc(20 * var(--hd));
    &-icon {
      color: var(--warning);
      margin-right: calc(10 * var(--hd));
    }
    &-words {
      color: var(--warning);
    }
  }
}