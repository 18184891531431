@import './common.less';

.@{mapClsPrefix} {
  position: relative;

  // 地图层级
  >div:first-child {
    z-index: var(--map-zindex);
  }

  //联想搜索面板
  &-search-input-wrap {
    .@{mapClsPrefix}-search-input-onmap {
      width: calc(320 * var(--hd));
      height: calc(32 * var(--hd));
    }

    .@{mapClsPrefix}-search-input-item {
      &:hover {
        background: var(--label-bc);
      }
    }
  }

  &-innermap {
    &-rtl {
      transform: scaleX(-1) !important;
    }
  }

  // 切换地图类型图标
  &-switch {
    position: absolute;
    top: calc(22 * var(--hd));
    right: var(--v-spacing-lg);
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50 * var(--hd));
    height: calc(50 * var(--hd));
    z-index: var(--map-wrap-zindex);
    background: var(--bg-white);
    border-radius: 50%;
    cursor: pointer;

    .Icon-switch {
      width: calc(26 * var(--hd));
      height: calc(26 * var(--hd));
    }
  }

  &-search-onmap {
    position: absolute;
    top: calc(22 * var(--hd));
    left: calc(10 * var(--hd));
  }

  // 弹框内样式
  &-dialog {
    font-size: var(--font-size-12);
    color: var(--main-fc);

    &-col {
      display: flex;
      flex-direction: row;
      height: calc(30 * var(--hd));
      line-height: calc(30 * var(--hd));
      padding-right: calc(20 * var(--hd));
      margin-bottom: calc(12 * var(--hd));

      &:last-child {
        padding-right: 0;
      }

      &-label {
        display: flex;
        flex: none;
        margin-right: var(--v-spacing-xl);
      }

      &-com {
        display: flex;
        flex: auto;
        max-width: 100%;

        .@{mapClsPrefix}-search-input-wrap {
          width: 100%;

          .@{mapClsPrefix}-search-input {
            width: 100%;
          }
        }
      }
    }
  }

  &-empty {
    padding-top: 15%
  }
}