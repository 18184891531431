/**
* 上传区域样式
*/
.startBtnSize(@pix) {
  width: calc(var(--hd) * @pix);
  height: calc(var(--hd) * @pix);
  line-height: calc(var(--hd) * @pix * 0.9);
}

.setPix(@pix) {
  @result: calc(var(--hd) * @pix);
}

.@{mUploadClsPrefix}-uploader {
  overflow: auto;
}

/*******************************  litType = 'list'  *******************************/
.@{mUploadClsPrefix} {
  /* 外层 button 显示 - 无附件 */
  &-select {
    .border-solid-then;
    background-color: var(--bg-white);
    // 按钮
    &-btn {
      // height: calc(44 * var(--hd));
      min-height: calc(44 * var(--hd));
      white-space: pre-line;
      border-radius: 0;
    }
    // 加号按钮
    &-icon {
      position: relative;
      top: calc(-2 * var(--hd));
      left: calc(-10 * var(--hd));
    }
    /* input type='file' 样式 */
    &-input {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      width: 100%;
      z-index: 0;
      input {
        display: none;
      }
    }
  }

  /* 外层 label 显示 - 无附件 */
  &-select-label {
    .border-solid-then;

    height: calc(44 * var(--hd));
    background-color: var(--bg-white);
    font-size: var(--font-size-14);
    // color: var(--main-fc);
    color: var(--regular-fc);
    // padding: 0 calc(20 * var(--hd));
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    
    // 行（文字、箭头）box
    .@{mUploadClsPrefix}-row {
      width: 100%;
      flex-wrap: nowrap;
      text-align: left;
    }
    // 箭头
    .@{mUploadClsPrefix}-arrow {
      // margin-left: calc(14 * var(--hd));

      margin-left: var(--h-spacing-sm);
    }
    &-icon {
      color: var(--secondary-fc);
    }
  }

  /* 外层 label 显示 - 有附件上传按钮 */
  &-detail {
    &-select {
      position: relative;
      height: calc(44 * var(--hd));
      background-color: var(--bg-white);
      font-size: var(--font-size-14);
      color: var(--main-fc);
      display: flex;
      justify-content: space-between;
      align-items: center;

      // 行（文字、箭头）box
      .@{mUploadClsPrefix}-row {
        width: 100%;
        flex-wrap: nowrap;
        text-align: left;
      }
      .@{mUploadClsPrefix}-arrow {
        margin-left: calc(14 * var(--hd));
        width: calc(24 * var(--hd));
        text-align: right;
      }
      &-icon {
        color: var(--secondary-fc);
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
      }
    }
  }
  
  /* 内层 button 显示 - detail 详情页面 */
  &-start-btn {
    .startBtnSize(44);
    position: fixed;
    right: .setPix(45)[@result];
    bottom: .setPix(120)[@result];
    display: block;
    border-radius: 50%;
    background-color: var(--primary);
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      opacity: var(--btn-hover-opacity);
    }

    svg {
      .startBtnSize(30);
      color: var(--base-white);
    }

    &.active {
      transform: rotate(45deg);
    }
    .@{iconClsPrefix} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &-tip {
    position: relative;
    z-index: 1;
    word-break: break-word;
    color: var(--regular-fc);
    font-size: var(--font-size-12);
    line-height: calc(16 * var(--hd));
    margin-top: calc(2 * var(--hd));
    &-help {
      min-width: calc(140 * var(--hd));
      padding: calc(2 * var(--hd)) calc(12 * var(--hd));
      text-align: center;
      word-break: break-word;
    }
    &-help-title {
      line-height: calc(24 * var(--hd));
    }
    &-noArrow {
      .@{popoverClsPrefix}-arrow {
        border-color: transparent;
      }
    }

    .ui-help{
      vertical-align: bottom;
    }
    // 扩大可选区域
    &-icon{
      padding: calc(8 * var(--hd));
      margin: calc(-8 * var(--hd));
      margin-left: calc(-5 * var(--hd));
    }
  }
}

/*******************************  litType = 'img'  *******************************/
.@{mUploadClsPrefix}-img .@{mUploadClsPrefix}-uploader-img {
  float: left;
}
.@{mUploadClsPrefix} .@{mUploadClsPrefix}-progress-box {
  clear: both;
}
.@{mUploadClsPrefix}{
  /** 外层容器 **/
  &-select-image {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: calc(70 * var(--hd));
    height: calc(70 * var(--hd));
    float: left;
    margin: calc(10 * var(--hd)) 0;
    border: var(--border-solid);
    text-align: center;
    position: relative;
    &-title {
    font-size: var(--font-size-12);
    color: var(--secondary-fc);
    justify-content: center;
    align-items: center;
    font-weight: var(--font-weight-base);
    position: relative;
    top: calc(-6 * var(--hd));
    text-align: center;
    }
    .@{iconClsPrefix}-wrapper {
      color: var(--placeholder-fc);
    }
    &.disabled {
      color: var(--invalid);
      background: var(--label-bc);
    }
  }
}

/*******************************  isListTShowImage  *******************************/
.@{mUploadClsPrefix}.isListTShowImage {
  .@{mUploadClsPrefix}-select-image {
    margin-bottom: 0;
  }
}

/******************************* 空数据展示  *******************************/
.@{mUploadClsPrefix} {
  .@{mUploadClsPrefix}-uploader {
    /** MUpload 无数据且只读/禁用样式 **/
    .@{mUploadClsPrefix}-empty {
      background-color: var(--bg-white);
      height: calc(44 * var(--hd));
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: var(--font-size-12);
      color: var(--secondary-fc);
      &.read-only-line-height-standard {
        line-height: var(--form-mobile-readonly-line-height);
        padding-top: var(--form-mobile-readonly-padding-y);
        padding-bottom: var(--form-mobile-readonly-padding-y);
        height: auto;
      }
    }
  }
}