.@{mworkStatusClsPrefix} {

  &-content {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 3px;

    >span:last-child {
      font-size: var(--font-size-14);
      margin-left: 5px;
      width: calc(100% - 25px);
      margin-top: 3px;
      text-align: left;
      word-break: break-word;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-content-rtl {
    >span:last-child {
      text-align: right;
    }
  }

  &-empty {
    display: flex;
    align-items: center;
    color: #CCCCCC;
    font-size: var(--font-size-14);

    .ui-icon {
      margin-left: 5px;
      font-size: var(--font-size-14);
    }
  }

  &-dialog {
    &-content {
      background: #F7F7F7;
      overflow-y: scroll;
      height: calc(100% - 50px);
      padding-bottom: 48px;

      &-item {
        font-size: var(--font-size-14);

        &-title {
          color: #999999;
          padding: 16px 16px 6px 16px;
        }

        &-list {
          background-color: #fff;
        }
      }

      &-edit {
        display: flex;
        align-items: center;
        background: #fff;
        min-height: 50px;
        padding: 0 13px;

        .ui-m-textarea-item {
          width: 100%;
          display: flex;
          align-items: center;

          .ui-m-textarea-label {
            align-self: center;
            width: unset;
            display: flex;
            align-items: center;
          }
        }

        .ui-m-textarea-control {
          //border: none;
          //box-shadow: none;
          //width: calc(100% - 25px);

          textarea {
            font-size: var(--font-size-17);
            letter-spacing: 1px;
          }

          textarea::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            font-size: var(--font-size-17);
          }
        }

        .ui-m-textarea-clear {
          display: block;
        }
      }

      &-edit-rtl {
        .ui-m-textarea-control {
          textarea {
            text-align: right;
          }
        }
      }
    }

    &-button {
      width: 100%;
      padding: 7px 24px;
      background-color: #fff;

      .ui-btn {
        width: 100%;
        font-size: var(--font-size-16);
        padding: 10px 16px;
      }
    }

    &-emoticon {
      // height: auto;
      height: 100%;
    }
  }

  &-status-list-item {
    display: flex;
    font-size: var(--font-size-17);
    color: #333333;
    width: 100%;

    >img {
      height: 24px;
      width: 24px;
      margin: 13px 0 18px 13px;
    }

    >span {
      margin-left: 10px;
      width: calc(100% - 35px);
      white-space: pre-wrap;
      padding: 16px 13px 16px 0;
      position: relative;
      word-break: break-word;
      letter-spacing: 1px;

      &:after {
        position: absolute;
        content: " ";
        height: calc(1 * var(--hd));
        width: 100%;
        left: 0;
        bottom: 0;
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        transform: scaleY(0.3);
        background-color: var(--diviling-line-color);
      }
    }
  }

  &-prompt {
    .@{mworkStatusClsPrefix}-content>:first-child {
      align-self: flex-start;
    }
    .@{mworkStatusClsPrefix}-content>:last-child {
      margin-top: 0;
    }

    .@{mworkStatusClsPrefix}-content {
      >span {
        line-height: 1.2;
        white-space: inherit;
        overflow: inherit;
        text-overflow: unset;

        >img {
          margin-right: 5px;
          margin-bottom: -4px;
        }
      }
    }
  }
}