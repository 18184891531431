.@{mCarouselPrefix} {
  height: 100%;
  width: 100%;
  position: relative;

  &-rtl{
    .swiper-container{
      transform: scaleX(-1);
    }

    .swiper-slide>*{
      transform: scaleX(-1);
    }
  }

  &-pagination {
    color: var(--base-white);

    .pagination-custom-vertical {
      display: flex;
      flex-direction: column;

      &>span {
        height: var(--carousel-pagination-carousel-default-width);
        width: var(--carousel-bullet-height);
        margin: var(--carousel-bullet-margin) 0;

        &.swiper-pagination-carousel-active {
          height: var(--carousel-pagination-carousel-active-width);
          opacity: 1;
        }
      }
    }

    .swiper-pagination-bullet-carousel {
      display: inline-block;
      opacity: 0.4;
      background: var(--bg-white);
      border-radius: var(--carousel-bullet-border-radius);
      transition: all 0.5s;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    .swiper-pagination-carousel-active {
      width: var(--carousel-pagination-carousel-active-width);
      opacity: 1;
    }

    .swiper-pagination-bullet {
      background: var(--bg-white);
      margin: 0 var(--carousel-bullet-margin);
    }

    &>span {
      transition: all 0.5s;

      &:hover {
        opacity: 1;
      }

      &.swiper-pagination-active {
        opacity: 1;
      }
    }

    // 兼容 ie11 样式
    bottom: var(--carousel-pagination-bottom);
  }

  // 兼容 ie11 样式
  .swiper-container-rtl .swiper-button-prev {
    color: var(--base-white);
    position: absolute;
    left: 0;
  }

  &-swiper-content {
    position: relative;

    .@{mCarouselPrefix}-swiper-button-next,
    .@{mCarouselPrefix}-swiper-button-prev {
      color: var(--base-white);
      position: absolute;

      &:after {
        content: none;
      }
    }

    .@{mCarouselPrefix}-swiper-button-next {
      width: auto;
    }

    .@{mCarouselPrefix}-swiper-button-prev {
      width: auto;
    }

    .@{mCarouselPrefix}-arrow-prev,
    .@{mCarouselPrefix}-arrow-next {
      .ui-icon-svg {
        height: var(--carousel-arrow-height);
        width: var(--carousel-arrow-width);
      }
    }
  }

  &-swiper-backgroundFill {

    background: rgba(0, 0, 0, .7);
    width: auto;
  }

  &-swiper-backgroundFill&-swiper-vertical {
    height: 100% !important;
  }

  &-swiper-imgFill {
    width: 100%;
    height: 100%;

    .swiper-wrapper {
      .swiper-slide {
        width: 100%;
        height: 100%;

        &>* {
          height: 100%;
          width: 100%;

          &>* {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  &-swiper-horizontal{
    .@{mCarouselPrefix}-swiper-button-next {
      right: 0;
    }
  
    .@{mCarouselPrefix}-swiper-button-prev {
      left: 0;
    }

    .@{mCarouselPrefix}-pagination {
      width: 100%;

      .swiper-pagination-bullet-carousel{
        margin: 0 var(--carousel-bullet-margin);
        width: var(--carousel-pagination-carousel-default-width);
        height: var(--carousel-bullet-height);
      }
    }
    
  }

  &-swiper-vertical{

    .swiper-wrapper {
      display: flex;
      flex-direction: column;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
    }

    .@{mCarouselPrefix}-swiper-button-next {
      bottom: 0;
      top: unset;
      right: unset;
    }
  
    .@{mCarouselPrefix}-swiper-button-prev {
      top: 0;
    }

    .@{mCarouselPrefix}-swiper-button-next, .@{mCarouselPrefix}-swiper-button-prev {
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
      width: calc(35 * var(--hd));
      height: calc(35 * var(--hd));
    }

    .@{mCarouselPrefix}-swiper-button-next{
      top: auto;
    }
    
    .@{mCarouselPrefix}-pagination{
      height: 100%;
      right: calc(10 * var(--hd));
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;

      .swiper-pagination-bullet-carousel{
        margin:var(--carousel-bullet-margin) 0;
        height: var(--carousel-pagination-carousel-default-width);
        width: var(--carousel-bullet-height);
      }
    }
  }



}