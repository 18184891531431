.@{photoEditorClsPrefix} {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-rtl {
    transform: scaleX(-1);
  }

  .@{photoEditorClsPrefix}-photoWrap {
    .@{photoEditorClsPrefix}-photoView {
      position: unset;
    }

    .PhotoView__PhotoBox {
      margin-top: unset;
    }

    &-hide {
      position: absolute;
      z-index: -1;
    }
  }

  .@{photoEditorClsPrefix}-toolBar {
    height: calc(40 * var(--hd));
    display: flex;
    align-items: center;
    justify-content: center;

    .PhotoView-toolBarBtn {
      margin: 0 calc(10 * var(--hd));
      color: var(--secondary-fc);
      cursor: pointer;
    }

    .PhotoView-slider {
      border-left: 1px solid var(--regular-fc);
      width: 1px;
      height: calc(22 * var(--hd));
      margin: 0 calc(10 * var(--hd));
      border-color: var(--secondary-fc);
    }

    .PhotoView-edit {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      color: var(--secondary-fc);

      .PhotoView-toolBarBtn {
        margin-right: calc(5 * var(--hd));
      }
    }

    .PhotoView-toolBarBtn-disabled {
      color: var(--invalid-fc);
      cursor: not-allowed;
    }

    .PhotoView-edit-disabled {
      color: var(--invalid-fc);
      cursor: not-allowed;

      .PhotoView-toolBarText {
        color: var(--invalid-fc);
        cursor: not-allowed;
      }
    }

    .PhotoView-toolBarText-rtl {
      transform: scaleX(1);
    }
  }

  .@{photoEditorClsPrefix}-photoCropper {
    display: flex;
    align-items: center;
    justify-content: center;

    &-hide {
      position: absolute;
      z-index: -1;
    }
  }
}