@import './../contextMenu/style.less';

.@{rightMenuClsPrefix} {
  position: relative;
  // height: 100%;
  &-point {
    position: absolute;
    background-color: var(--bg-white);
    border-top: var(--border-width) solid var(--checkbox-disabled-bg-color);
    border-bottom: var(--border-width) solid var(--checkbox-disabled-bg-color);
    // box-shadow: 0 3px 12px 0 rgb(0 0 0 / 12%);
  }

  //镜像样式
  &-rtl{
    transform: scaleX(-1);
  }
}
