/******************************** checkname dialog  ********************************/
.@{mUploadClsPrefix}-checkname-dialog {
  &-content {
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
  }
  &-sure-btn {
    position: absolute;
    bottom: 0;
  }
}

.@{mUploadClsPrefix}-checkname-item-folderInfo {
  min-height: calc(40 * var(--hd));
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: var(--form-item-padding-module);
  span {
    color: var(--primary);
    margin: 0 calc(10 * var(--hd));
  }
}

.@{mUploadClsPrefix}-checkname-item-desc {
  color: var(--regular-fc);
  span {
    color: var(--danger);
    margin: 0 calc(10 * var(--hd));
  }
}
.@{mUploadClsPrefix}-checkname-item-box {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: calc(10 * var(--hd)) calc(16 * var(--hd));
  border-bottom: var(--form-item-border-module);
  background-color: var(--bg-white);
}
.@{mUploadClsPrefix}-checkname-item {
  min-height: calc(40 * var(--hd));
  margin-bottom: calc(10 * var(--hd));
  &:last-child {
    border-bottom: var(--border-solid);
  }
  &:first-child {
    border-top: var(--border-solid);
  }
  &-icon {
    display: flex;
    align-items: center;
    margin-right: calc(6 * var(--hd));
  }
  &-filebox {
    max-width: 100%;
    overflow: hidden;
    margin-right: calc(6 * var(--hd));
    font-size: var(--font-size-12);
  }
  &-filename {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-filesize {
    margin-top: calc(2 * var(--hd));
    color: var(--regular-fc);
  }
  &-btns {
    padding: calc(6 * var(--hd)) calc(16 * var(--hd));
    .btn {
      padding: 0;
    }
    .active {
      color: var(--primary);
    }
    .noactive {
      color: var(--secondary-fc);
    }
  }
}
