.@{shareClsPrefix} {
  border: var(--hd) solid var(--border-color);
  .@{tableClsPrefix}-grid-thead .@{tableClsPrefix}-grid-th-order:hover {
    background-color: var(--label-bc);
  }

  &-title {
    border: 0;
    // border: var(--hd) solid var(--share-title-border-color);
    // border-bottom: 0;
    height: calc(50 * var(--hd));
    .@{titleClsPrefix}-title-top {
      font-size: var(--font-size-14);
      color: var(--main-fc);
    }
    &-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      &-com {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
      /* 顶部按钮（无数据关联） */
      &-options {
        &-icon {
          color: var(--primary);
          margin-right: calc(var(--hd) * 10);
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          &-disabled {
            color: var(--secondary-fc);
            cursor: not-allowed;
          }
        }
      }

      /* 搜索栏 */
      &-search-bar {
        margin-left: calc(20 * var(--hd));
      }
      /* 顶部按钮（放入菜单中，有数据关联） */
      &-menu {
        margin-left: calc(var(--hd) * 10);
      }
      // &-options-icon {
      //   color: var(--primary);
      //   margin-right: calc(var(--hd) * 10);
      //   cursor: pointer;
      //   &:last-child {
      //     margin-right: 0;
      //   }
      //   &-disabled {
      //     color: var(--secondary-fc);
      //     cursor: not-allowed;
      //   }
      // }
      // &-search-bar {
      //   margin-left: calc(20 * var(--hd));
      // }
    }
  }

  &-dialog {
    .@{dialogClsPrefix}-body-container {
      padding-bottom: calc(20 * var(--hd));
    }
    .@{dialogClsPrefix}-body {
      height: 100%;
    }
  }

  &-auth-dialog {
    .@{spinClsPrefix}-nested-loading {
      height: 100%;
    }
    .ui-auth.loading {
      display: none;
    }
  }

  &-obj_id {
    a {
      color: var(--primary);
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-table {
    border: 0;
    border-top: var(--hd) solid var(--diviling-line-color);
    tr {
      .@{shareClsPrefix}-table-operate {
        .@{menuClsPrefix} {
          .@{menuClsPrefix}-select-iconOverlay {
            .@{iconClsPrefix}-md {
              visibility: hidden;
              transition: all 0.1s;
            }
          }
        }
      }
    }
    tr:hover {
      .@{shareClsPrefix}-table-operate {
        .@{menuClsPrefix} {
          .@{menuClsPrefix}-select-iconOverlay {
            .@{iconClsPrefix}-md {
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

/** 批量操作弹框 */
.@{shareClsPrefix}-batch-dialog {
  .@{titleClsPrefix}-right-container,
  .@{dialogClsPrefix}-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .@{tableClsPrefix}-grid-tfoot {
    display: none;
  }
  .@{tableClsPrefix}-grid {
    border-color: var(--border-color);
  }


  &-pagation {
    margin-right: calc(10 * var(--hd));
  }

  &-selected {
    font-size: var(--font-size-12);
    color: var(--secondary-fc);
    position: absolute;
    left: calc(16 * var(--hd));
    &-num {
      color: var(--primary);
      margin: 0 calc(4 * var(--hd));
    }
  }
}
