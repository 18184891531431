.@{mBrowserClsPrefix} {
  background-color: var(--bg-white);
}

.@{mBrowserClsPrefix} {
  .@{mBrowserClsPrefix}-associative {
    &-selected {
      &-list-check-all-btn {
        display: block;
        text-align: right;
        font-size: var(--font-size-sm);
        color: var(--primary);
        line-height: calc(14 * var(--hd));
        margin-top: calc(6 * var(--hd));
        padding-bottom: calc(10 * var(--hd));
        padding-right: calc(4 * var(--hd));
      }
    }
    /* 已选局左对齐 */
    &.align-left {
      .@{mBrowserClsPrefix}-associative-selected {
        .@{mListClsPrefix}-column .@{mListClsPrefix}-body {
          // text-align: left;
          .@{mListClsPrefix}-content {
            justify-content: flex-start;
          }
        }
      }
    }
    /* 已选局中对齐 */
    &.align-center {
      .@{mBrowserClsPrefix}-associative-selected {
        .@{mListClsPrefix}-column .@{mListClsPrefix}-body {
          // text-align: center;
          .@{mListClsPrefix}-content {
            justify-content: center;
          }
        }
      }
    }
    /* 已选局右对齐 */
    &.align-right {
      .@{mBrowserClsPrefix}-associative-selected {
        .@{mListClsPrefix}-column .@{mListClsPrefix}-body {
          // text-align: center;
          .@{mListClsPrefix}-content {
            justify-content: flex-end;
            .@{mListClsPrefix}-item-row {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

/* 外部已选列表 */
.@{mBrowserClsPrefix}-associative {
  background-color: var(--bg-white);
  &.is-associative {
    line-height: normal;
    .@{mBrowserClsPrefix}-associative-inner {
      // 	padding-top: calc(12 * var(--hd));
      // 	padding-left: var(--h-spacing-lg);
      // 	padding-right: var(--h-spacing-lg);
      .@{formItemClsPrefix}-item-icon {
        position: static;
      }
    }
    .@{mBrowserClsPrefix}-associative-selected {
      padding-left: var(--h-spacing-lg);
      padding-right: var(--h-spacing-lg);
    }
    &.is-multiple.no-label {
      display: flex;
      flex-direction: row-reverse;
      // align-items: flex-start;
      align-items: center;
      &.is-empty .@{mBrowserClsPrefix}-associative-inner.is-placeholder {
        flex: 1;
      }
    }
  }
  &-inner {
    display: flex;
    padding-right: 0;
    &.is-placeholder {
      .@{formItemClsPrefix}-item-placeholder {
        color: var(--secondary-fc);
      }
    }
    &.is-voice-to-text {
      .@{formItemClsPrefix}-item-placeholder {
        justify-content: space-between;
      }
    }
    .@{formItemClsPrefix}-item-label {
      line-height: normal;
    }
    .@{formItemClsPrefix}-item {
      display: flex;
      padding-right: 0;
      line-height: normal;
      align-items: baseline;
      .is-finished {
        // text-decoration: line-through;
        color: var(--secondary-fc);
      }
      .is-deleted {
        color: #fa6e52;
      }
    }
    .@{formItemClsPrefix}-item-placeholder {
      color: var(--main-fc);
      display: flex;
      flex: 1 1 auto;
      justify-content: flex-end;
    }
    .@{formItemClsPrefix}-item-icon {
      // position: static;
    }
    &-item {
      margin-right: var(--h-spacing-md);
      &.is-link {
        color: var(--primary);
      }
    }
  }
  &.is-disabled,
  &.is-readonly {
    .@{formItemClsPrefix}-item-icon {
      display: none;
    }
  }
  &-count {
    margin-left: var(--h-spacing-md);
    display: flex;
    align-items: center;
  }
  &-selected {
    flex: 1 1 auto;
    overflow: hidden;
    &-list {
      overflow: initial;
      .@{mListClsPrefix}-body {
        .@{mListClsPrefix}-item {
          display: inline-flex;
          margin-right: var(--h-spacing-md);
          margin-top: calc(-3 * var(--hd));
          &:last-of-type {
            margin-right: 0;
          }
          &:not(:last-child) .@{mListClsPrefix}-content {
            border-bottom: none;
            &::after {
              display: none;
            }
          }
          .@{mListClsPrefix}-content {
            min-height: auto;
            font-size: var(--font-size-md);
            color: var(--main-fc);
            // max-width: 90px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      /* 关联浏览按钮特殊展示 */
      &.is-associative {
        &.@{mListClsPrefix}-column .@{mListClsPrefix}-body {
          text-align: left;
          padding-top: calc(12 * var(--hd));
          .@{mListClsPrefix}-item {
            display: flex;
            // margin-left: var(--h-spacing-lg);
            &::before {
              content: "";
              background-color: var(--secondary-fc);
              width: calc(5 * var(--hd));
              height: calc(5 * var(--hd));
              border-radius: var(--border-radius-xs);
              color: transparent;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
            .@{mListClsPrefix}-item-row {
              font-size: var(--font-size-sm);
              color: var(--regular-fc);
              text-align: left;
            }
            .@{mListClsPrefix}-content {
              min-height: calc(28 * var(--hd));
              font-size: var(--font-size-sm);
              color: var(--regular-fc);
              margin-left: var(--h-spacing-lg);
              max-width: inherit;
              justify-content: flex-start;
              .@{mBrowserClsPrefix}-associative-selected-item {
                max-width: inherit;
              }
            }
          }
        }
      }
      &.is-associative.need-mirror-RTL {
        &.@{mListClsPrefix}-column .@{mListClsPrefix}-body {
          .@{mListClsPrefix}-item {
            .@{mListClsPrefix}-content {
              margin-left: 0;
              margin-right: var(--h-spacing-lg);
            }
          }
        }
      }
      /* 标签需要特殊展示 */
      &.is-tag {
        .@{mBrowserClsPrefix}-associative-selected-item.is-tag {
          line-height: calc(24 * var(--hd));
          padding: 0 var(--h-spacing-md);
          border-radius: var(--border-radius-xs);
        }
        &.@{mListClsPrefix}-column {
          .@{mListClsPrefix}-body {
            .@{mListClsPrefix}-item {
              .@{mListClsPrefix}-item-row {
                color: inherit;
              }
            }
          }
        }
      }
    }
    .@{mListClsPrefix}-column {
      .@{mListClsPrefix}-body {
        border-bottom: none;
        text-align: right;
        line-height: calc(32 * var(--hd));
        .@{mListClsPrefix}-item {
          display: inline-flex;
          cursor: pointer;
          background-color: inherit;
          min-height: var(--input-height);
          width: 100%;
          .is-clickable {
            .@{mListClsPrefix}-item-row,
            .@{mListClsPrefix}-item-readonly-simple {
              color: var(--primary);
            }
          }
          .@{mListClsPrefix}-item-row {
            color: var(--main-fc);
            // line-height: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.6;
            white-space: normal;
            padding: 5px 0;
            text-align: right;
            word-break: break-word;
          }
          .@{mListClsPrefix}-item-col {
            flex-basis: auto;
            overflow: hidden;
          }
          .@{mListClsPrefix}-content {
            min-height: var(--input-height);
            border-bottom: none;
            justify-content: flex-end;
            &::after {
              display: none;
            }
            & > a {
              text-decoration: none;
            }
          }
          .@{mListClsPrefix}-item-readonly-simple.is-finished {
            color: var(--secondary-fc);
          }
        }
      }
    }
    &-item {
      display: inline-flex;
      align-items: center;
      overflow: hidden;
      // max-width: calc(110 * var(--hd)); // 折行后默认展示一条已选数据
      max-width: 100%;
      &-avatar {
        flex-shrink: 0; // 防止 flex 挤压变形
      }
      .browser-list-template {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.is-readonly,
      &.is-disabled {
        margin-right: var(--h-spacing-sm);
      }
      &.is-deletable {
        margin-right: 0;
      }
      &.is-clickable {
        color: var(--primary);
      }
      .is-finished {
        // text-decoration: line-through;
        color: var(--secondary-fc);
      }
      .is-deleted {
        color: #fa6e52;
      }
    }
  }
  &-selected.read-only-line-height-standard {
    line-height: var(--form-mobile-readonly-line-height);
    padding-top: var(--form-mobile-readonly-padding-y);
    padding-bottom: var(--form-mobile-readonly-padding-y);
    .@{mListClsPrefix}-column {
      .@{mListClsPrefix}-body {
        .@{mListClsPrefix}-item {
          line-height: var(--form-mobile-readonly-line-height);
          height: auto;
          margin-top: 0;
          .@{mListClsPrefix}-item-row {
            line-height: var(--form-mobile-readonly-line-height);
            padding-top: 0;
          }
          .@{mListClsPrefix}-item-readonly-simple {
            line-height: var(--form-mobile-readonly-line-height);
          }
        }
      }
    }
  }
  &.is-single {
    .@{mBrowserClsPrefix}-associative-selected-item {
      // max-width: calc(150 * var(--hd));
    }
  }
  /** 语音转文字 */
  &-voicebtn {
    display: flex;
    align-items: center;
    margin-left: var(--h-spacing-sm);
    &-icon {
      color: var(--primary);
      // border-radius: 50%;
      // background: var(--secondary-fc);
      // color: var(--base-white);
      // padding: calc(2 * var(--hd));
      // width: calc(18 * var(--hd));
      // height: calc(18 * var(--hd));
    }
  }
}

/** 关联浏览按钮，多选模式，非 label 时，右侧箭头样式处理 */
.@{mBrowserClsPrefix}.is-multiple {
  .@{mBrowserClsPrefix}-associative.is-associative {
    .@{mBrowserClsPrefix}-associative-inner:not(.is-label) {
      .@{formItemClsPrefix}-item-icon {
        height: calc(44 * var(--hd));
      }
    }
  }
}

/* 已选数据展示 - 平铺显示多行 换行显示 */
.@{mBrowserClsPrefix}.is-multiline {
  &.is-multiple {
    .@{mBrowserClsPrefix}-associative:not(.is-associative) {
      .@{mBrowserClsPrefix}-associative {
        &-inner {
          .@{formItemClsPrefix}-item-placeholder {
            white-space: normal;
          }
          .@{formItemClsPrefix}-item-icon {
            position: relative;
            margin-bottom: auto;
            top: calc(14 * var(--hd));
          }
          // &.is-placeholder,
          // &.is-single-data {
          //   .@{formItemClsPrefix}-item-icon {
          //     top: calc(14 * var(--hd));
          //   }
          // }
        }
        &-selected {
          &-item {
            max-width: 100%;
          }
          .@{mListClsPrefix}-column .@{mListClsPrefix}-body {
            // text-align: left;
            .@{mListClsPrefix}-item {
              max-width: 100%;
              width: unset;
            }
          }
          .@{mBrowserClsPrefix}-readonly-simple-content {
            max-width: 100%;
            width: unset;
          }
        }
      }
      .@{mBrowserClsPrefix}-readonly-simple {
        white-space: normal;
      }
    }
    .@{mBrowserClsPrefix}-associative {
      &.align-left {
        .@{mBrowserClsPrefix}-associative-selected {
          .@{mListClsPrefix}-column .@{mListClsPrefix}-body {
            text-align: left;
            .@{mListClsPrefix}-item-row {
              text-align: left;
            }
          }
        }
      }
      &.align-center {
        .@{mBrowserClsPrefix}-associative-selected {
          .@{mListClsPrefix}-column .@{mListClsPrefix}-body {
            text-align: center;
            .@{mListClsPrefix}-item-row {
              text-align: center;
            }
          }
        }
      }
    }
  }
  &.is-single {
    .@{mBrowserClsPrefix}-associative {
      .@{mBrowserClsPrefix}-associative-selected-item {
        /* 单选平铺数据展示不限制最大宽度 */
        max-width: 100%;
        width: unset;
      }
      .@{mListClsPrefix}-column .@{mListClsPrefix}-body {
        .@{mListClsPrefix}-item {
          max-width: 100%; 
        }
      }
    }
  }
}

/* 已选数据展示 - 换行显示 */
.@{mBrowserClsPrefix}-associative.wrap-display:not(.is-associative) {
  .@{mBrowserClsPrefix}-associative {
    &-inner {
      .@{formItemClsPrefix}-item-placeholder {
        white-space: normal;
      }
      .@{formItemClsPrefix}-item-icon {
        position: relative;
        margin-bottom: auto;
        top: calc(10 * var(--hd));
      }
      &.is-placeholder,
      &.is-single-data {
        .@{formItemClsPrefix}-item-icon {
          top: calc(14 * var(--hd));
        }
      }
    }
    &-selected {
      &-item {
        max-width: 100%;
      }
      .@{mListClsPrefix}-column .@{mListClsPrefix}-body {
        .@{mListClsPrefix}-item {
          width: 100%;
          max-width: 100%;
          .ui-m-list-content {
            justify-content: flex-end;
          }
        }
      }
      .@{mBrowserClsPrefix}-readonly-simple-content {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .@{mBrowserClsPrefix}-readonly-simple {
    white-space: normal;
  }
  /* 已选左对齐 */
  &.align-left {
    .@{mBrowserClsPrefix}-associative-selected {
      .@{mListClsPrefix}-column .@{mListClsPrefix}-body {
        .@{mListClsPrefix}-item {
          .ui-m-list-content {
            justify-content: flex-start;
          }
        }
      }
    }
    .@{mBrowserClsPrefix}-readonly-simple-content {
      justify-content: flex-start;
    }
  }
  /* 已选局中对齐 */
  &.align-center {
    .@{mBrowserClsPrefix}-associative-selected {
      .@{mListClsPrefix}-column .@{mListClsPrefix}-body {
        .@{mListClsPrefix}-item {
          .ui-m-list-content {
            justify-content: center;
          }
        }
      }
    }
    .@{mBrowserClsPrefix}-readonly-simple-content {
      justify-content: center;
    }
  }
}

/** 关联浏览按钮样式 */
.@{mBrowserClsPrefix}.is-associative {
  .@{mBrowserClsPrefix} {
    &-associative {
      &-selected-list-check-all-btn {
        padding-left: var(--h-spacing-lg);
      }
    }
  }
}

/** 浏览按钮后部追加按钮样式 */
.@{mBrowserClsPrefix}.append-extra-ele {
  .@{mBrowserClsPrefix} {
    &-suffix-btn {
      font-size: calc(12 * var(--hd));
      color: var(--primary);
      padding-bottom: calc(12 * var(--hd));
      padding-right: calc(4 * var(--hd));
    }
    &-associative {
      &-selected-list-check-all-btn {
        margin-bottom: calc(12 * var(--hd));
        padding-right: calc(4 * var(--hd));
      }
    }
  }
}
/** 关联浏览按钮后部追加按钮样式 */
.@{mBrowserClsPrefix}.is-associative.append-extra-ele:not(.is-empty) {
  .@{mBrowserClsPrefix} {
    &-suffix-btn {
      padding-left: var(--h-spacing-lg);
    }
  }
}
/** 只读下已选数据简化样式 */
.@{mBrowserClsPrefix}-associative-selected.read-only-line-height-standard {
  .@{mBrowserClsPrefix}-readonly-simple-content.@{mListClsPrefix}-item {
    line-height: var(--form-mobile-readonly-line-height);
    height: auto;
    .@{mListClsPrefix}-item-readonly-simple {
      line-height: var(--form-mobile-readonly-line-height);
      padding-top: 0px;
    }
  }
}
.@{mBrowserClsPrefix}-readonly-simple {
  display: flex;
  justify-content: flex-end;
  line-height: var(--m-form-item-line-height);
  white-space: nowrap;
}
.@{mBrowserClsPrefix}-readonly-simple-selected-list {
  border-bottom: none;
  text-align: right;
  height: auto;
  line-height: calc(var(--hd)*32);
  .@{mBrowserClsPrefix}-readonly-simple-content.@{mListClsPrefix}-item {
    position: relative;
    cursor: pointer;
    width: unset;
    margin-right: var(--h-spacing-md);
    margin-top: calc(-3 * var(--hd));
    display: inline-flex;
    text-align: left;
    align-items: center;
    white-space: nowrap;
    font-size: var(--font-size-md);
    color: var(--main-fc);
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: var(--input-height);
    border-bottom: none;
    justify-content: flex-end;
    background-color: inherit;
    .@{mListClsPrefix}-item-readonly-simple {
      color: var(--main-fc);
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.6;
      white-space: normal;
      padding: 5px 0;
      text-align: right;
      word-break: break-word;
      .is-finished {
        color: var(--secondary-fc);
      }
    }
  }
  .@{mBrowserClsPrefix}-associative-selected-item.is-clickable {
    .@{mListClsPrefix}-item-readonly-simple {
      color: var(--primary);
    }
  }

}
.@{mBrowserClsPrefix}-readonly-simple-selected-list.is-associative {
  text-align: left;
  padding-top: calc(var(--hd)* 12);
  .@{mBrowserClsPrefix}-readonly-simple-content {
    min-height: calc(var(--hd)* 28);
    font-size: var(--font-size-sm);
    color: var(--regular-fc);
    max-width: inherit;
    justify-content: flex-start;
    .@{mBrowserClsPrefix}-associative-selected-item {
      margin-left: var(--h-spacing-lg);
      max-width: inherit;
      .@{mListClsPrefix}-item-readonly-simple {
        font-size: var(--font-size-sm);
        color: var(--regular-fc);
        text-align: left;
      }
    }
  }
  .@{mBrowserClsPrefix}-readonly-simple-content:before {
    content: "";
    background-color: var(--secondary-fc);
    width: calc(var(--hd)* 5);
    height: calc(var(--hd)* 5);
    border-radius: var(--border-radius-xs);
    color: transparent;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 2px;
  }
}
.@{mBrowserClsPrefix}-associative {
  &.align-left {
    .@{mListClsPrefix}-column .@{mListClsPrefix}-body .@{mListClsPrefix}-item .@{mListClsPrefix}-item-row {
      text-align: left;
    }
    .@{mListClsPrefix}.@{mBrowserClsPrefix}-readonly-simple-selected-list {
      text-align: left;
      .@{mListClsPrefix}-item-readonly-simple {
        text-align: left;
      }
    }
  }
  &.align-center {
    .@{mListClsPrefix}-column .@{mListClsPrefix}-body .@{mListClsPrefix}-item .@{mListClsPrefix}-item-row {
      text-align: center;
    }
    .@{mListClsPrefix}.@{mBrowserClsPrefix}-readonly-simple-selected-list {
      text-align: center;
      .@{mListClsPrefix}-item-readonly-simple {
        text-align: center;
      }
    }
  }
}
