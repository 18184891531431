
.@{browserTypeClsPrefix} {
  display: inline-block;
  &-input {
    width: auto;
    min-width: calc(120 * var(--hd));
    &-selected {
      color: var(--main-fc);
      font-size: var(--font-size-sm);
    }
    &-arrow {
      cursor: pointer;
      line-height: 0;
      transition: transform 0.3s ease-in-out;
      // &.is-close {
      //   transform: rotate(0deg);
      // }
      // &.is-open {
      //   transform: rotate(-180deg);
      // }
    }
    &-clear {
      &.@{inputClsPrefix}-clear {
        line-height: 0;
      }
      opacity: 0;
    }
    &:hover {
      .@{browserTypeClsPrefix}-input-clear {
        opacity: 1;
      }
    }
  }
  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
  &-search-input {
    flex: 1 1 auto;
    padding: 0;
    border: none;
    outline: none;
    overflow: hidden;
    font-size: var(--font-size-sm);
    height: calc(var(--input-height) - var(--select-input-padding) * 2);
    line-height: calc(var(--input-height) - var(--select-input-padding) * 2);
  }
  &-panel {
    width: calc(480 * var(--hd));
    font-size: var(--font-size-sm);
    margin-top: var(--v-spacing-md);
    margin-bottom: var(--v-spacing-md);
    border: var(--border-solid);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius-xs);
    background-color: var(--bg-white);

    &-header {
      position: relative;
      padding: var(--v-spacing-md) var(--h-spacing-lg);
      border-bottom: var(--border-solid);
      background-color: var(--bg-base);

      &-left {
        margin-right: calc(28 * var(--hd));
      }

      &-setting {
        position: absolute;
        top: calc(13 * var(--hd));
        right: var(--h-spacing-lg);
        cursor: pointer;
        color: var(--secondary-fc);
        &:hover {
          color: var(--primary);
        }
      }
      
      &-group {
        &-item {
          margin-right: var(--h-spacing-lg);
          margin-top: var(--v-spacing-sm);
          margin-bottom: var(--v-spacing-sm);
          display: inline-block;
          cursor: pointer;
          &.is-active,
          &:hover {
            color: var(--primary);
            text-decoration: underline;
          }
        }
      }
    }

    &-body {
      min-height: calc(300 * var(--hd));

      &-spin {
        min-height: calc(300 * var(--hd));
      }

      &-empty {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        &.ui-empty {
          margin: 0;
        }
        .ui-empty-image {
          .ui-icon img {
            width: calc(100 * var(--hd));
            height: calc(100 * var(--hd));
          }
        }
      }
      &-group {
        display: flex;
        align-items: flex-start;
        border-bottom: var(--border-solid);
        padding-top: var(--v-spacing-md);
        padding-bottom: var(--v-spacing-md);
        margin-left: var(--h-spacing-lg);
        margin-right: var(--h-spacing-lg);

        &:last-of-type {
          border-bottom: 0;
          margin-bottom: 0;
        }
        &-label {
          flex: 0 0 auto;
          width: calc(80 * var(--hd));
          // margin-right: var(--h-spacing-lg);
          color: var(--secondary-fc);
          margin-top: var(--v-spacing-xs);
          margin-bottom: var(--v-spacing-xs);
          word-break: break-word;
        }
        &-item {
          cursor: pointer;
          margin-top: var(--v-spacing-xs);
          margin-bottom: var(--v-spacing-xs);
          display: inline-block;
          &.is-active,
          &:hover {
            color: var(--primary);
          }
          &::after {
            content: '';
            border-left: var(--border-solid);
            border-color: var(--regular-fc);
            width: calc(1 * var(--hd));
            height: calc(12 * var(--hd));
            margin-left: var(--h-spacing-md);
            margin-right: var(--h-spacing-md);
          }
          &:last-of-type {
            &::after {
              display: none;
            }
          }
        }
      }
      // 最后一个默认取消对应border
      &-last-group {
        border-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
  // 类型设置
  &-setting-modal {
    .@{dialogClsPrefix}-body {
      height: 100%;
      overflow: hidden;
    }
    .@{dialogClsPrefix}-body-container {
      overflow: hidden;
    }
    &-content {
      position: relative;
      &-head {
        position: relative;
        border: var(--border-solid);
        padding: 0 calc(12 * var(--hd));
        background-color: var(--bg-white);
        .@{menuClsPrefix} {
          padding-left: 0;
          z-index: 2;
          border-bottom: 0;
          width: 80%;
        }
      }
      &-table {
        .@{tableClsPrefix}-grid {
          border-top: 0;
        }
        .@{tableClsPrefix}-grid-td .@{selectClsPrefix} {
          width: calc(150 * var(--hd));
        }
      }
      &-settable {
        position: absolute;
        top: calc(2 * var(--hd));
        z-index: 1;
        .@{editableTableClsPrefix}-top {
          height: var(--menu-item-height);
          line-height: var(--menu-item-height);
          margin-right: calc(12 * var(--hd));
        }
      }
    }
  }
}

.@{browserTypeClsPrefix}-dropdown {
  margin: var(--v-spacing-sm) 0;
  padding-top: var(--v-spacing-md);
  padding-bottom: var(--v-spacing-md);
  background-color: var(--select-dropdown-bc);
  border: var(--border-solid);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow);
  z-index: 100;
  white-space: nowrap;
  // max-width: var(--select-dropdown-max-width);
  min-width: var(--select-dropdown-min-width);
  &-search-input {
    margin: 0 var(--h-spacing-sm) var(--v-spacing-sm);
  }
  .@{inputClsPrefix} {
    width:0;
    flex:1 1 auto;
  }
  .@{inputClsPrefix}-suffix {
    flex:initial;
  }
}

.@{browserTypeClsPrefix}-option-list {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: calc(10 * var(--select-option-height));
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  .@{browserTypeClsPrefix}-option-list-empty {
    font-size: var(--font-size-sm);
    color: var(--secondary-fc);
    // letter-spacing: -0.39px;
    line-height: calc(18 * var(--hd));
    padding-left: var(--h-spacing-lg);
    user-select: none;
    cursor: not-allowed;
  }
  .@{browserTypeClsPrefix}-group-title {
    padding: var(--v-spacing-sm) var(--h-spacing-lg);
    font-size: var(--font-size-sm);
    color: var(--regular-fc);
  }
  .@{browserTypeClsPrefix}-group-divide {
    height: 0;
    width: calc(100% - var(--h-spacing-lg) * 2);
    margin: var(--v-spacing-md) var(--h-spacing-lg);
    border-bottom: var(--border-width) solid var( --diviling-line-color);
  }
  .ui-scroller__wrap {
    max-height: calc(10 * var(--select-option-height));
  }
}

.@{browserTypeClsPrefix}-option-list-option {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: var(--select-option-height);
  box-sizing: border-box;
  white-space: normal;
  padding: var(--v-spacing-sm) var(--h-spacing-lg);
  font-size: var(--select-option-fs);
  color: var(--select-option-color);
  transition: var(--transition-color), var(--transition-bc), font-weight var(--transition-du) var(--transition-tf);
  &.is-active {
    color: var(--select-option-active-fc);
    font-weight: var(--font-weight-bold);
  }
  &.is-highlighted {
    color: var(--primary);
    background-color: var(--select-option-highligh-bc);
  }
  &.is-disabled {
    color: var(--select-option-disabled-fc);
    pointer-events: none;
    cursor: not-allowed;
  }
  &-tooltip {
    font-size: var(--font-size-sm);
    margin: var(--v-spacing-xs) 0;
    .@{popoverClsPrefix}-content {
      border-radius: var(--border-radius-xs);
    }
  }
  &-content {
    flex: 1 1 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1;
  }
  /* 分隔线 */
  &.has-seperator {
    margin-bottom: calc(20 * var(--hd));
    &::after {
      content: '';
      position: absolute;
      bottom: calc(-10 * var(--hd));
      left: 0;
      width: calc(100% - var(--h-spacing-lg) * 2);
      border-bottom: var(--border-solid);
      margin: 0 var(--h-spacing-lg);
    }
  }
}

.weapp-ie11 {
  .@{browserTypeClsPrefix}-input {
    padding-top: calc(3 * var(--hd)); /* 处理IE11里上下局中对齐问题 */
    padding-bottom: calc(3 * var(--hd));
  }
}
