.@{breadCrumbClsPrefix} {

  &-item,
  &-separator {
    padding: 0px 2px;
  }

  &-item {
    cursor: pointer;
    color: var(--secondary-fc);

    &:last-child {
      cursor: default;

      &:hover {
        color: var(--primary);
      }
    }

    &:hover {
      color: var(--primary);
      text-decoration: underline;
    }
  }

  &-separator {
    padding: 0px 2px;
    color: var(--secondary-fc);

    .@{iconClsPrefix} {
      line-height: 1;
    }
  }
}