.@{boxSelectionClsPrefix}-wrap {
  position: relative;

  .@{boxSelectionClsPrefix} {

    user-select: none;
    display: flex;

    // 镜像样式
    &-rtl {
      .mirror-text;
      >* {
        &:not(&:first-child) {
          .mirror-text-re;
        }
      }
    }

    &-element {
      position: absolute;
      z-index: 9999;
      top: 0;
      left: 0;
      height: 0;
      width: 0;
      border: 1px dashed #eee;
      background-color: #aaa !important;
      opacity: 0.6;
    }
  }

}