.@{mActionSheetClsPrefix} {
  &-carousel {
    .swiper-pagination-bullet {
      background-color: var(--secondary-fc);
    }

    .ui-m-carousel-swiper-backgroundFill {
      background-color: var(--bg-base);
    }

    .swiper-wrapper {
      align-items: unset;
    }
  }

  &-wrap {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--action-sheet-zindex);
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }

  &-carousel {
    .swiper-container {
      background-color: var(--bg-base);
    }
  }

  &-carousel {
    .ui-m-carousel-swiper-backgroundFill {
      background-color: var(--bg-base);
    }
  }

  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: var(--mask-bc);
    height: 100%;
    z-index: var(--action-sheet-zindex);

    &-hidden {
      display: none;
    }
  }

  // 隐藏掉 dialog 默认的 x 按钮
  &-close {
    display: none;
  }
}

.@{mActionSheetClsPrefix} {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  &-content {
    margin: 0 calc(10 * var(--hd)) calc(10 * var(--hd));
  }

  &-share {
    background-color: var(--bg-weak);
  }

  &-border-title {
    .@{mActionSheetClsPrefix}-title {
      // border-top-left-radius: calc(14 * var(--hd));
      // border-top-right-radius: calc(14 * var(--hd));
    }

    .@{mActionSheetClsPrefix}-button-list-main {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &-border-message {
    background-color: var(--m-actionsheet-bg-color);

    // .@{mActionSheetClsPrefix}-message {
    //   // border-top-left-radius: calc(14 * var(--hd));
    //   // border-top-right-radius: calc(14 * var(--hd));
    // }
    .@{mActionSheetClsPrefix}-button-list-main {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &-title,
  &-message {
    margin: 0 auto;
    padding: var(--h-spacing-lg) var(--h-spacing-lg);
    text-align: center;
    background-color: var(--bg-white);
    // border-bottom: var(--border-solid);
    .border-solid-then;
  }

  &-title {
    border-bottom: 0;
    font-size: var(--font-size-14);
    padding-bottom: 0;
    font-weight: var(--font-weight-base);
    color: var(--main-fc);
    border-radius: 0;
    background: var(--bg-base);
  }

  &-message {
    color: var(--color-text-caption);
    font-size: var(--font-size-base);
  }

  &-button-list {
    text-align: center;
    background-color: var(--m-actionsheet-bg-color);

    // color: var(--primary);
    &-main {
      // border-radius: var(--m-actionsheet-item-border-radius);
      max-height: calc(408 * var(--hd));
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      &-content {
        overflow: hidden;
        background-color: var(--bg-white);
      }
    }
    &-box.disable{
      background-color: var(--bg-base);
      opacity: var(--m-btn-disabled-opacity);
    }
    &-item {
      font-size: var(--font-size-base);
      margin:0 var(--v-spacing-lg);
      padding: 0;
      position: relative;
      //解决文字过长导致的折叠问题
      height: var(--m-actionsheet-item-height);
      line-height: var(--m-actionsheet-item-height);
      box-sizing: border-box;
      white-space: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      // color: var(--primary);
      &-line {
        height: var(--hd);
        transform: scaleY(0.3);
        background-color: var(--border-color);
      }

      &:first-child {
        border-top: none !important;
      }

      &&-active {
        background-color: var(--bg-tap);
      }
    }

    &-cancel {
      // margin-top: var(--v-spacing-md);
      position: relative;
      background-color: var(--bg-white);
      //margin-top: calc(10 * var(--hd));
      // border-radius: var(--m-actionsheet-item-border-radius);
      overflow: hidden;
    }

    .@{mActionSheetClsPrefix}-destructive-button {
      color: var(--danger);
    }
  }

  &-grid-list {
    // text-align: center;
    // color: var(--primary);
    &-main {
      padding: var(--m-actionsheet-grid-main-padding);
      background: var(--bg-base);

      &-content {
        display: flex;
        align-content: flex-start;
        flex-flow: row wrap;
        border-bottom: 0;
        width: 100%;
        background: var(--bg-base);
      }
    }

    &-item {
      margin-bottom: 28px;
      padding: 0 5px;
      flex: 0 0 20%;
      overflow: hidden;
      &.disable{
        background-color: var(--bg-base);
        opacity: var(--m-btn-disabled-opacity);
      }
      &-icon {
        width: var(--m-actionsheet-item-icon-width);
        height: var(--m-actionsheet-item-icon-width);
        border-radius: calc(6 * var(--hd));
        background: var(--bg-white);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
      }

      &-content {
        margin-top: 8px;
        font-family: var(--regular-ff);
        font-size: var(--font-size-12);
        color: var(--secondary-fc);
        letter-spacing: 0;
        text-align: center;
        font-weight: var(--font-weight-base);
        word-wrap: break-word;
      }
    }
  }

  &-share {
    &-list {
      display: flex;
      position: relative;
      border-top: var(--border-solid);
      padding: var(--v-spacing-xl) 0 var(--v-spacing-xl) var(--h-spacing-lg);
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      &-item {
        flex: none;
        margin: 0 calc(12 * var(--hd)) 0 0;
        &.disable{
          background-color: var(--bg-base);
          opacity: var(--m-btn-disabled-opacity);
        }

        &-icon {
          margin-bottom: var(--v-spacing-md);
          width: calc(60 * var(--hd));
          height: calc(60 * var(--hd));
          background-color: var(--bg-white);
          border-radius: var(--border-radius-sm);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &-title {
          color: var(--color-text-caption);
          font-size: var(--font-size-icontext);
          text-align: center;
        }
      }
    }

    &-cancel-button {
      height: var(--m-actionsheet-item-height);
      line-height: var(--m-actionsheet-item-height);
      text-align: center;
      background-color: var(--bg-white);
      font-size: var(--m-btn-font-size);
      position: relative;
      box-sizing: border-box;

      &&-active {
        background-color: var(--bg-tap);
      }
    }
  }
}

.@{mActionSheetClsPrefix}-dialog {
  .@{mDialogClsPrefix}-top-bottom {
    padding: 0;
    border-radius: 0;
  }
}

/*********************************************** grid 样式单独处理 ***********************************************/
.@{mActionSheetClsPrefix} {
  &-grid {
    .@{mActionSheetClsPrefix}-title::after {
      background: var(--bg-transparent);
    }
  }
}
