.@{collapseClsPrefix} {
  box-sizing: border-box;
  overflow: hidden;

  &--has-border {
    border-top-color: var(--collapse-panel-border-color);
    border-top-width: var(--hd);
    border-top-style: solid;
    border-bottom-color: var(--collapse-panel-border-color);
    border-bottom-width: var(--hd);
    border-bottom-style: solid;

    .@{collapseClsPrefix}-panel__title {
      border-left-color: var(--collapse-panel-border-color);
      border-left-width: var(--hd);
      border-left-style: solid;
      border-right-color: var(--collapse-panel-border-color);
      border-right-width: var(--hd);
      border-right-style: solid;
    }

    .@{collapseClsPrefix}-panel__content-box {
      padding: 0 calc(37 * var(--hd));
    }
  }

  &--no-border {
    border: none;

    .@{collapseClsPrefix}-panel__title {
      border: none;
    }

    .@{collapseClsPrefix}-panel__content-box {
      border: none;
    }
  }

  &-group {
    border: none;
    .@{collapseClsPrefix}-panel {
      &__title {
        border: none;
        background-color: var(--bg-transparent);
        color: var(--main-fc);
        font-weight: 600;
        font-size: var(--font-size-12);
      }
      &__content-box {
        padding: 0 calc(10 * var(--hd));
        border: none;
        background-color: var(--bg-transparent);
      }
      &__content {
        padding: 0;
        font-weight: 400;
        margin-bottom: calc(8 * var(--hd));
      }
      &__arrow-right {
        width: calc(16 * var(--hd));
        height: calc(16 * var(--hd));
        background: var(--collapse-panel-arrow-bg-color);
        border-radius: 4px;
        text-align: center;
        line-height: calc(22 * var(--hd));
        color: #8b8a8a;
        transform: rotate(-90deg);
        display: flex;
        right: 10px;
        align-items: center;
        justify-content: center;
        .ui-icon-xs {
          width: calc(12 * var(--hd));
          height: calc(12 * var(--hd));
        }
        &.needRTL {
          right: unset;
          left: calc(10 * var(--hd));
        }
      }
    }
  }
}

.@{collapseClsPrefix}-panel {
  box-sizing: border-box;

  &__title {
    border-left-width: var(--hd);
    border-left-style: solid;
    border-right-color: var(--collapse-panel-border-color);
    border-right-width: var(--hd);
    border-right-style: solid;
    font-size: var(--font-size-14);
    line-height: 20px;
    border-bottom-color: var(--collapse-panel-border-color);
    border-bottom-width: var(--hd);
    border-bottom-style: solid;
    color: #323233;
    padding: var(--collapse-panel-title-padding);
    cursor: pointer;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    &--bg-default {
      background-color: #f7f8fa;
    }

    &--bg-none {
      background-color: var(--bg-white);
    }

    .ui-icon {
      .Icon-Down-arrow01 {
        color: var(--secondary-fc);
      }
      .Icon-up-arrow04 {
        color: var(--secondary-fc);
      }
    }

    .@{collapseClsPrefix}-panel__arrow {
      display: flex;
    }
  }

  &__content-box {
    font-size: var(--font-size-14);
    line-height: calc(20 * var(--hd));
    background-color: var(--bg-white);
    border-bottom-color: var(--collapse-panel-border-color);
    border-bottom-width: var(--hd);
    border-bottom-style: solid;
    padding: 0 calc(36 * var(--hd));
    overflow: hidden;
    box-sizing: border-box;
  }

  &__content {
    padding: calc(15 * var(--hd)) 0;
  }

  &__arrow {
    fill: #323233;
    margin-right: calc(10 * var(--hd));
    transform: rotate(180deg);
    transform-origin: center;
    transition: transform 160ms cubic-bezier(0.5, 0, 0.5, 0.1);
  }

  &__arrow-right {
    position: absolute;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &.needRTL {
      right: unset;
      left: calc(6 * var(--hd));
    }
  }

  &--inactive {
    .@{collapseClsPrefix}-panel__arrow {
      transform: rotate(0deg);
    }
  }

  &--disabled {
    .@{collapseClsPrefix}-panel__arrow {
      fill: #c8c9cc;
    }

    .@{collapseClsPrefix}-panel__title {
      color: #c8c9cc;
      cursor: not-allowed;

      .ui-icon {
        .Icon-Down-arrow01 {
          color: var(--regular-fc);
        }
        .Icon-up-arrow04 {
          color: #c8c9cc;
        }
      }
    }
  }

  &--animating {
    user-select: none;
  }

  &--active {
    .@{collapseClsPrefix}-group-panel__arrow-right.needRTL {
      transform: rotate(90deg);
    }
  }
}
