.@{mshareClsPrefix} {
  /* header */
  &-panel-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &-searchbar {
      padding: var(--h-spacing-md) var(--h-spacing-xs);
    }
    &-left-label {
      margin-right: auto;
      font-size: var(--font-size-sm);
      color: var(--secondary-fc);
      font-size: var(--font-size-lg);
      text-align: center;
    }
    &.is-empty {
      padding: 0;
    }
    &-select-btn {
      color: var(--secondary-fc);
    }
    .@{mSearchbarClsPrefix} {
      width: 100%;
    }
  }
  /* content */
  &-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &-panel-bread-crumb {
    position: relative;
    padding: calc(8 * var(--hd)) 0;
    text-align: center;
    .cancel-btn,
    .check-num {
      color: var(--primary);
    }
    .cancel-btn {
      position: absolute;
      right: calc(20 * var(--hd));
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &-panel-content-box {
    flex: 1 1 auto;
    overflow: hidden;
    background-color: var(--m-bg-base);
  }
  &-panel-list {
    height: 100% !important;

    .@{mListClsPrefix}-item {
      // padding: var(--h-spacing-sm) 0;
      // align-items: center;
      width: 100%;
      min-height: calc(50 * var(--hd));
      // &:first-child {
      //   padding-left: var(--h-spacing-lg);
      // }
      .@{mListClsPrefix}-content {
        // padding-left: var(--h-spacing-lg);
        background-color: var(--bg-white);
        // padding-left: calc(10 * var(--hd));
        padding-left: var(--h-spacing-lg);
        padding-right: var(--h-spacing-lg);
      }
      .@{mListClsPrefix}-thumb,
      .@{mListClsPrefix}-grid-expandable-btn {
        display: none;
      }
    }
    .@{mListClsPrefix}-check {
      margin-left: var(--h-spacing-md);
      .@{mCcheckboxClsPrefix}-inner-left {
        margin-right: 0;
      }
    }
    .@{mListClsPrefix}-item-row {
      line-height: 1.3;
      font-size: var(--font-size-sm);
      color: var(--secondary-fc);
      padding: calc(2 * var(--hd)) 0;
      white-space: pre-wrap;
      &-void {
        display: none;
      }
      &-span {
        // padding-bottom: var(--h-spacing-sm);
        &-void {
          display: none;
        }
      }
      &-span-text-highlight {
        font-weight: normal;
        font-size: var(--font-size-md);
      }
    }
    .my-handle {
      margin-right: calc(6 * var(--hd));
    }
    // .@{mListClsPrefix}-column
    .@{mListClsPrefix}-body div:not(:last-child) .@{mListClsPrefix}-content {
      &:after {
        height: 0;
      }
    }
  }
  &-panel-list-item {
    // position: relative;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    overflow: hidden;
    line-height: calc(var(--hd) * 25);
    font-size: var(--font-size-md);
    // padding: var(--v-spacing-xs) 0;
    padding-top: var(--v-spacing-md);
    padding-bottom: var(--v-spacing-md);

    // 第一行高亮
    .@{mListClsPrefix}-item-row:first-child {
      font-weight: normal;
      font-size: var(--font-size-md);
      color: var(--main-fc);
    }
    &-icon {
      flex: 0 0 auto;
      color: var(--secondary-fc);
      width: calc(30 * var(--hd));
      display: flex;
      justify-content: center;
    }
    &.@{mListClsPrefix}-column {
      .@{mListClsPrefix}-content {
        min-height: calc(50 * var(--hd));
        padding-left: var(--h-spacing-lg);
      }
    }
    &-avatar {
      // margin-left: var(--h-spacing-md);
      flex: 0 0 auto;
      margin-right: var(--h-spacing-md);
    }
    &.has-avatar,
    &.has-icon {
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        border-bottom: none;
        height: var(--hd);
        background-color: var(--border-color);
        transform: scaleY(0.3);
        width: calc(
          100% - var(--h-spacing-lg) - var(--avatar-size-md) -
            var(--h-spacing-md)
        );
      }
    }
  }
  /* footer */
  &-panel-footer {
    display: flex;
    // justify-content: space-between;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    background-color: var(--bg-white);
    // padding-left: var(--h-spacing-lg);
    // padding-right: var(--h-spacing-lg);
    // height: calc(50 * var(--hd));
    &:not(.is-empty) {
      height: calc(50 * var(--hd));
    }
    &.border-solid-then::after {
      top: 0;
    }
    .@{mCcheckboxClsPrefix}-label {
      text-align: left;
    }
    .is-batch-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      // color: var(--secondary-fc);
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        height: 70%;
        width: var(--hd);
        background-color: var(--border-color);
        transform: translateY(-50%) scaleX(0.3);
      }
      .select-icon {
        margin-right: calc(4 * var(--hd));
      }
    }
    .is-selected-btn {
      margin-left: calc(5 * var(--hd));
      flex: 0 0 50%;
      height: 100%;
      margin-right: auto;
      max-width: calc(100 * var(--hd));
    }
    &-selectAll {
      align-items: center;
      .@{mCcheckboxClsPrefix}-label,
      .@{mCcheckboxClsPrefix}-left,
      .@{mCcheckboxClsPrefix}-right {
        padding: 0;
      }
    }
    .is-del-btn,
    .is-sort-btn {
      flex: 0 0 25%;
      margin-right: calc(14 * var(--hd));
      // margin-left: auto;
    }

    // .is-del-btn::after {
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   top: 50%;
    //   height: 50%;
    //   width: var(--hd);
    //   background-color: var(--border-color);
    //   transform: translateY(-50%) scaleX(0.3);
    // }
  }
  /* authForm */
  &-auth-dialog {
    // padding-bottom: var(--m-dialog-button-group-height);
    &-spin {
      height: 100%;
      overflow: auto;
      .@{spinClsPrefix}-container {
        height: 100%;
      }
    }
  }
  /** empty list */
  &-list-empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}
