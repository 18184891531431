.@{mEmoticonClsPrefix} {
  &-scroll {
    height: calc(185 * var(--hd));
    overflow-y: auto;
    padding: 0 var(--h-spacing-xs);
  }
  &-group {
    display: flex;
    padding: var(--h-spacing-sm) 0;
  }
  &-content-item {
    width: 10%;
    text-align: center;
    .@{iconClsPrefix}-svg {
      width: calc(25 * var(--hd));
      height: calc(25 * var(--hd));
    }
  }
}