// 默认状态
.@{checkboxClsPrefix} {
  display: inline-flex;
  align-items: center;
  line-height: normal;

  &-group {
    flex-wrap: wrap;
  }

  &-vertical {
    display: inline-flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    .@{checkboxClsPrefix}-wrapper {
      width: 100%;
      margin: calc(8 * var(--hd)) 0;
    }

    .@{checkboxClsPrefix}-label {
      width: 100%;
    }
  }

  &-left {
    flex-shrink: 0;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    vertical-align: middle;
    outline: none;
    cursor: var(--checkbox-cursor);

    width: var(--checkbox-size-base);
    height: var(--checkbox-size-base);

    &.hidden {
      display: none;
    }
  }

  .@{checkboxClsPrefix}-wrapper:hover &-inner {
    border-color: var(--checkbox-active-border-color);
  }

  &:hover::after,
  .@{checkboxClsPrefix}-wrapper:hover &::after {
    visibility: visible;
  }

  &-inner {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    background-color: var(--checkbox-default-background);
    width: 100%;
    height: 100%;
    direction: ltr;
    border: var(--border-width) solid var(--checkbox-border-color);
    border-radius: var(--checkbox-border-radius);
    border-collapse: separate;
    transition: all 0.3s;

    &::after {
      top: 42%;
      left: 22%;
      opacity: 0;
      transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
      content: " ";
    }
  }

  &-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: var(--checkbox-cursor);
    opacity: 0;
  }

  &-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--checkbox-border-radius);
    visibility: hidden;
    animation: antCheckboxEffect 0.36s ease-in-out;
    animation-fill-mode: backwards;
    content: "";
  }

  .isTag .@{checkboxClsPrefix}-label-span {
    display: block;
    line-height: 1;
  }

  &-tips {
    margin-left: calc(4 * var(--hd));

    .@{helpClsPrefix},
    .@{helpClsPrefix}-explain {
      display: flex;
    }
  }
}

// 选中状态
.@{checkboxClsPrefix}-checked {
  .@{checkboxClsPrefix}-inner {
    border-color: var(--checkbox-active-border-color);
    background-color: var(--checkbox-active-background-color);

    &::after {
      position: absolute;
      display: table;
      width: var(--checkbox-inner-width);
      height: var(--checkbox-inner-height);
      border: var(--border-width) solid var(--base-white);
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      // transition: all 0.2s cubic-bezier(0.71, -0.46, 0.88, 0.6) 0.1s;
      content: " ";
    }
  }
}

// 半选状态
.@{checkboxClsPrefix}-indeterminate {
  .@{checkboxClsPrefix}-inner {
    background-color: var(--checkbox-active-background-color);
    border-color: var(--checkbox-active-border-color);

    &::after {
      position: absolute;
      display: table;
      border-bottom: var(--border-width) solid var(--base-white);
      border-top: var(--border-width) solid transparent;
      border-right: var(--border-width) solid transparent;
      border-left: var(--border-width) solid transparent;
      opacity: 1;
      content: " ";
      width: var(--checkbox-indeterminate-inner-width);
      height: var(--checkbox-indeterminate-inner-height);
      transform: rotate(0deg) translateY(-50%) translateX(-50%);
      background: var(--bg-transparent);
      top: 50%;
      left: 50%;
    }
  }

  // 半选禁用
  &.@{checkboxClsPrefix}-disabled .@{checkboxClsPrefix}-inner {
    background-color: var(--checkbox-disabled-bg-color);
    border-color: var(--checkbox-disabled-border-color);

    &:after {
      animation-name: none;
      border-color: var(--checkbox-disabled-border-color);
    }
  }
}

// checkbox wrapper
.@{checkboxClsPrefix}-wrapper {
  display: inline-flex;
  align-items: center;
  cursor: var(--checkbox-cursor);
  font-size: var(--checkbox-font-size);
  color: var(--checkbox-font-color);
  margin: var(--hd) calc(16 * var(--hd)) var(--hd) 0;

  &.is-IE {
    &::after {
      content: "";
      min-height: inherit;
    }
  }

  // 隐藏 check 框
  &.hidden-check {
    .@{checkboxClsPrefix}-label {
      padding: 0;
    }
  }
}

// 禁用状态
.@{checkboxClsPrefix}-disabled {
  &+span {
    color: var(--checkbox-disabled-font-color);
  }

  .@{checkboxClsPrefix}-inner,
  .@{checkboxClsPrefix}-input {
    cursor: var(--checkbox-disabled-cursor);
    background-color: var(--checkbox-disabled-bg-color);
    border-color: var(--checkbox-disabled-border-color);

    &::after {
      border-color: var(--checkbox-disabled-border-color);
    }
  }
}

.@{checkboxClsPrefix}-wrapper-disabled {
  cursor: not-allowed;

  &:hover {
    .@{checkboxClsPrefix}-inner {
      border-color: var(--checkbox-disabled-border-color);
    }
  }
}

// 只读状态
.@{checkboxClsPrefix}-wrapper-readOnly {
  cursor: default;

  &:hover {
    .@{checkboxClsPrefix}-inner {
      border-color: var(--checkbox-disabled-border-color);
    }

    .@{checkboxClsPrefix}-checked {
      .@{checkboxClsPrefix}-inner {
        border-color: var(--checkbox-active-border-color);
      }
    }
  }
}

.@{checkboxClsPrefix}-wrapper-readOnly {
  .form-pc-readOnlyLineHeightStandard;
}

.@{checkboxClsPrefix}-readOnly {
  cursor: default;

  &+span {
    color: var(--checkbox-font-color);
  }

  .@{checkboxClsPrefix}-inner,
  .@{checkboxClsPrefix}-input {
    cursor: default;
    background-color: var(--checkbox-disabled-bg-color);
    border-color: var(--checkbox-disabled-border-color);
  }

  // 选中状态
  .@{checkboxClsPrefix}-inner-checked {
    border-color: var(--checkbox-active-border-color);
    background-color: var(--checkbox-active-background-color);
    opacity: 0.5;

    &::after {
      background-color: var(--checkbox-active-background-color);
      opacity: 0.5;
    }
  }
}

.@{checkboxClsPrefix}-label-readOnly {
  cursor: default;
}

.@{checkboxClsPrefix}-readOnly-stringMode {
  display: inline-flex;
  flex-direction: row;
  font-size: var(--font-size-12);
  color: var(--main-fc);
  cursor: default;

  &-item {
    margin-right: calc(20 * var(--hd));
  }
}

.@{checkboxClsPrefix}-readOnly-stringMode-readOnlyLineHeightStandard {
  .@{checkboxClsPrefix}-readOnly-stringMode-item {
    .form-pc-readOnlyLineHeightStandard
  }
}

.@{checkboxClsPrefix}-label {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: calc(4 * var(--hd));
  padding-left: calc(4 * var(--hd));

  &.has-tips {
    flex-wrap: nowrap;

    .@{checkboxClsPrefix}-label-span {
      white-space: unset;
    }
  }

  &.isTag {
    padding-right: calc(10 * var(--hd));
    padding-left: calc(10 * var(--hd));
    margin-left: calc(5 * var(--hd));
    margin-right: calc(5 * var(--hd));
    height: var(--tag-size-md);
    display: inline-flex;
    align-items: center;

    &.unchecked {
      display: inline-block;
      // vertical-align: middle;
      margin-right: calc(7 * var(--hd));
      padding-top: calc(5 * var(--hd));
      padding-bottom: calc(5 * var(--hd));
    }
  }

  &-disabled.unchecked {
    .@{checkboxClsPrefix}-label-span {
      color: var(--radio-disabled-font-color);
    }
  }

  &-span {
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    // line-height: 1;
  }
}

// image checkbox
.@{checkboxClsPrefix}-image {
  &-wrapper {
    width: calc(120 * var(--hd));
    height: calc(120 * var(--hd));
    background: var(--bg-white);
    border: var(--hd) solid var(--border-color);
    border-radius: calc(3 * var(--hd));
    display: inline-table;
    margin: calc(10 * var(--hd));
  }

  &-vertical &-wrapper {
    display: block;
  }

  &-box img {
    width: 100%;
  }

  &-footer {
    padding: calc(9 * var(--hd)) calc(8 * var(--hd));
  }
}

// image checkbox
.@{checkboxClsPrefix}-image {
  &-wrapper {
    width: calc(120 * var(--hd));
    height: calc(120 * var(--hd));
    background: var(--bg-white);
    border: var(--hd) solid var(--border-color);
    border-radius: calc(3 * var(--hd));
    display: inline-table;
    margin: calc(10 * var(--hd));
  }

  &-vertical {
    display: block;
  }

  &-vertical &-wrapper {
    display: block;
  }

  &-box img {
    width: 100%;
  }

  &-footer {
    padding: calc(9 * var(--hd)) calc(8 * var(--hd));
  }
}

.@{checkboxClsPrefix}-image-wrapper {
  .@{checkboxClsPrefix}-wrapper {
    display: flex;
    margin: 0;
  }

  .@{checkboxClsPrefix}-label {
    width: calc((120 * var(--hd)) - calc(34 * var(--hd)));
  }

  .@{checkboxClsPrefix}-label-span {
    width: 100%;
  }
}

@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}