.@{mSelectClsPrefix} {
  .@{triggerClsPrefix} {
    display: block;
  }

  input {
    pointer-events: none;
  }

  &-isHoldRight {
    .@{mInputClsPrefix}-control input {
      text-align: right;
    }

    .@{mInputClsPrefix}-control.is-readonly {
      text-align: right;
    }

    .@{mSelectClsPrefix}-input-suffix-wrap-tag {
      justify-content: right;
    }

    .@{mSelectClsPrefix}-input-wrap {
      justify-content: right;

      .ui-input-wrap {
        justify-content: right;
      }

      .ui-input-suffix {
        height: auto;
      }
    }
  }

  &-input-wrap {
    display: flex;
    flex-wrap: wrap;

    // 多选有比下拉边距
    &.@{mSelectClsPrefix}-selected-tag-multiple {
      .ui-tag {
        margin-bottom: calc(6 * var(--hd));
      }

      .ui-input-suffix {
        margin-left: calc(4 * var(--hd));
        margin-top: calc(3 * var(--hd));
        align-self: flex-start;
      }
    }

    &-tag {
      .ui-m-list-item {
        flex: 1;
      }

      .ui-input-wrap {
        padding: 0;
        border: none;
        background: none;
        display: flex;

        &.is-focus {
          box-shadow: none;
        }
      }
    }

    &.@{mSelectClsPrefix}-selected-showwrap {
      min-height: calc(44 * var(--hd));
      display: flex;
      align-items: center;
    }

    &.@{mSelectClsPrefix}-readonly-selectedWrap {
      .ui-tag {
        height: auto;
        white-space: unset;

        .ui-tag-text {
          line-height: calc(20 * var(--hd));
          white-space: unset;
          text-align: left;
        }

        .ui-select-input-selected {
          white-space: unset;
        }
      }

      .ui-select-input-tags-group {
        &.is-multiple {
          .ui-select-input-tag {
            height: auto;

            span {
              white-space: unset;
            }
          }
        }
      }
    }

    .ui-tag {
      // overflow: hidden;
      // no.2833234 解决配合select组件在tag模式下部分样式问题-配合表单处理
      max-width: 100%;
      margin-right: calc(8 * var(--hd));
    }
  }

  &-item-wrap {

    // 设置tag类型最大宽度为100% 超出隐藏
    .ui-m-tag {
      overflow: hidden;
      max-width: 100%;
      margin-right: calc(8 * var(--hd));
    }

    .@{mSelectClsPrefix}-item {
      .ui-m-checkbox.multiple {
        .ui-m-checkbox-label {
          width: calc(100% - 40 * var(--hd));
          text-align: left;
          overflow: hidden;

          &>.ui-m-checkbox-label-span {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
          }
        }
      }
    }
  }

  &-horizontal {
    &-anticlockwise {
      .ui-m-dialog-wrap {
        animation: unset;
        width: 100vh;
        transform-origin: bottom left;
        transform: rotate(-90deg) translateY(100vw);
      }
    }

    &-clockwise {
      .ui-m-dialog-wrap {
        animation: unset;
        width: 100vh;
        transform-origin: bottom right;
        transform: translateX(-100%) rotate(90deg)
      }
    }
  }

  &-input-suffix-wrap-tag {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
    max-width: calc(100% - 20 * var(--hd));
  }

  &.is-readonly.is-multiple {
    .ui-tag {
      margin-bottom: calc(6 * var(--hd));
    }
  }

  &.is-readonly {
    .ui-tag {
      color: var(--main-fc);
    }
  }

  &.is-readonly-readOnlyLineHeightStandard {
    .ui-m-list-item .ui-m-input-control.is-readonly span {
      .form-mobile-readOnlyLineHeightStandard;
      color: var(--m-readonly-text);
    }

    .ui-tag {
      height: auto;
    }

    .ui-tag .ui-tag-text {
      line-height: var(--form-mobile-readonly-line-height);
    }

    // 处理tag模式下的padding
    .ui-m-select-input-wrap.ui-m-select-selected-showwrap {
      min-height: auto;
      padding-top: var(--form-mobile-readonly-padding-y);
      padding-bottom: var(--form-mobile-readonly-padding-y);
    }
  }

  &.is-readonly-readOnlyLineHeightStandard.is-multiple {
    .ui-m-select-input-wrap.ui-m-select-selected-showwrap {
      padding-bottom: calc(var(--form-mobile-readonly-padding-y) - calc(6 * var(--hd)));
    }
  }

  &-input-selected {
    &[data-rtl='true'] {
      display: inline-block;
    }

    &-extra {
      &.need-ltr {
        transform: scaleX(1);
        direction: ltr;
      }
    }
  }

  &-extra {
    &.need-ltr {
      transform: scaleX(1);
      direction: ltr;
    }
  }
}

.@{mSelectClsPrefix}-dropdown {

  /* 底部弹出式 */
  .@{mDialogClsPrefix}-top {
    padding: 0;
    background-color: var(--bg-base);
    border-radius: 0;
    /* 开启gpu硬件加速 */
    transform: translate3d(0, 0, 0);
  }

  .searchbar-wrap {
    padding: 10px;
    background: var(--bg-white);
    border-bottom: 1px solid #ebebeb90;
  }

  /* 右侧弹出层式 */
  .@{mDialogClsPrefix}-wrap-right {
    width: 100%;

    .@{mDialogClsPrefix}-top {
      height: 100%;
      display: flex;
      flex-direction: column;

      .@{mSelectClsPrefix}-list {
        flex: 1 1 auto;
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
      }

      .@{mSelectClsPrefix}-item-cancel {
        flex: 0 0 auto;
      }
    }
  }

  &-title {
    background: var(--bg-white);
    padding: var(--v-spacing-lg) var(--h-spacing-lg);
    display: flex;

    &-content {
      font-size: var(--font-size-17);
      flex: 1 1 auto;
      padding: 0 calc(24 * var(--hd));
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-close {
      color: var(--secondary-fc);
      position: absolute;
      right: var(--v-spacing-lg);
    }
  }

  .@{mSelectClsPrefix}-item-wrap {
    padding: 0 var(--h-spacing-lg);
    background: var(--bg-white);
    
    &:last-of-type {
      .@{mSelectClsPrefix}-item {
        border-bottom: none;
      }
    }

    .@{uiMClsPrefix}-btn .@{uiMClsPrefix}-tag {
      line-height: 1;
    }

    .@{uiMClsPrefix}-checkbox .@{uiMClsPrefix}-checkbox-label .@{uiMClsPrefix}-checkbox-label-span .@{uiMClsPrefix}-tag {
      line-height: 1;
    }

    .is-disabled {
      background-color: var(--bg-white);
    }
  }

  .isSearching {
    background: #f4f5f6;
  }

  .@{mSelectClsPrefix}-item {
    height: var(--m-select-item-height);
    border: none;
    width: 100%;
    background: var(--bg-white);
    border-radius: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;

    &.is-active {
      color: var(--primary);
    }

    &.@{mSelectClsPrefix}-item-cancel {
      // margin-top: var(--v-spacing-md);
      margin-bottom: 0;

      .@{mBtnClsPrefix} {
        border-top: var(--border-solid);
        border-color: var(--bg-base);
        background-color: var(--m-chk-default-bc);
      }
    }

    &.@{mSelectClsPrefix}-item-sure {
      border-radius: var(--m-btn-radius);
      background-color: var(--primary);
    }

    & .isTag {
      margin-top: 12px;
    }

    & .multiple {}
  }

  .@{mSelectClsPrefix}-item:focus {
    background: var(--bg-white);
  }

  .@{mSelectClsPrefix}-list {
    padding-bottom: var(--v-spacing-md);
    background: var(--bg-white);
    max-height: calc(10 * var(--m-select-item-height));
    overflow-y: auto;
  }
}


.@{mSelectClsPrefix} {
  &-button {
    display: flex;
    align-items: center;

    .@{mSelectClsPrefix}-item-wrap {
      flex: 1;
    }

    .@{mSelectClsPrefix}-item-clear {
      color: var(--secondary-fc);
    }

    .@{mSelectClsPrefix}-item-clearsure {
      color: var(--primary);
    }

    >.@{mSelectClsPrefix}-list-clear {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        height: 50%;
        width: var(--hd);
        background-color: #e5e5e5;
        background-color: var(--border-color);
        transform: translateY(-50%) scaleX(.3);
      }
    }
  }
}


// select 支持单多选模式样式
.@{mSelectClsPrefix} {
  &-option-select-all-header {
    padding: 0 var(--h-spacing-lg);
    display: flex;
    line-height: calc(48 * var(--hd));
  }

  &-option-select-all-title {
    flex: 1;
    color: var(--regular-fc);
    text-align: left;
  }

  &-option-select-all-btn {
    flex: 1;
    color: var(--primary);
    text-align: right;

    span {
      padding: calc(16 * var(--hd)) 0 calc(16 * var(--hd)) calc(16 * var(--hd));
    }
  }

  &-single-options {
    .ui-m-btn {
      text-align: left;
      padding-left: 0;
      color: var(--m-readonly-text);
      display: flex;
      justify-content: space-between;
    }
  }

  &-list-single {
    padding-bottom: calc(10 * var(--hd));
  }

  &-single-multiple {

    .ui-m-dialog-content,
    .ui-m-dialog-top-bottom {
      border-radius: calc(16 * var(--hd)) calc(16 * var(--hd)) 0 0;
    }
  }
}

.@{mSelectClsPrefix}-list-empty-data {
  height: calc(44 * var(--hd));
  line-height: calc(44 * var(--hd));
  color: #999;
}