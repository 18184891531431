/* 联想搜索 */
.@{browserClsPrefix}-associative {
  .@{triggerClsPrefix} {
    display: inline-block;
  }

  /* 联想搜索弹出框 */
  &-dropdown {
    display: block;
    max-width: 100%;
    border: var(--border-solid);
    margin-top: var(--v-spacing-sm);
    margin-bottom: var(--v-spacing-sm);
    background: var(--bg-white);
    border-radius: var(--border-radius-xs);
    min-width: var(--browser-dropdown-min-width);
    font-size: var(--select-option-fs);
    box-sizing: border-box;
    box-shadow: var(--box-shadow-storke);

    /** 联想搜索列表自适应宽度 */
    &.auto-width {
      min-width: calc(100 * var(--hd));
      // max-width: calc(600 * var(--hd));
    }

    .@{browserClsPrefix}-extra-btn {

      // background: #ffffff;
      // box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.07);
      // &:first-child {
      &:first-of-type {
        background: var(--bg-white);
        box-shadow: 0 calc(-3 * var(--hd)) calc(8 * var(--hd)) 0 rgba(0, 0, 0, 0.07);
      }
    }

    // z-index: 100;
    /* 添加数据 */
    .@{browserClsPrefix}-associative-dropdown-add,
    .@{browserClsPrefix}-associative-dropdown-more {
      padding: var(--v-spacing-md) var(--h-spacing-md);
      cursor: pointer;
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      color: var(--primary);

      &-label {
        flex: 0 0 auto;
      }

      &-content {
        flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .@{browserClsPrefix}-extra-btn-content {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      // &:hover {
      //   background-color: var(--hover-bc);
      // }
    }

    .@{browserClsPrefix}-associative-dropdown-more {
      text-align: center;
      font-size: var(--font-size-12);
      color: var(--m-readonly-text);
      display: flex;
      justify-content: center;

      &:hover {
        color: var(--primary);
      }

      .@{browserClsPrefix}-extra-btn-icon {
        position: relative;
        top: calc(-1 * var(--hd));
      }
    }

    &.dropdownInvisible {
      display: none;
    }

    &-list {
      padding-top: var(--v-spacing-md);
      // padding-bottom: var(--v-spacing-sm);
      // max-height: var(--browser-dropdown-max-height);
      cursor: pointer;

      &-item {
        padding: var(--v-spacing-sm) var(--h-spacing-md);
      }

      &-tooltip {
        font-size: var(--font-size-sm);
        margin: var(--v-spacing-xs) 0;

        .@{popoverClsPrefix}-content {
          border-radius: var(--border-radius-xs);
          word-wrap: break-word;
        }
      }

      &-empty {
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--secondary-fc);
        //padding-top: var(--h-spacing-xs);
        padding: var(--v-spacing-sm) var(--h-spacing-md);
        line-height: var(--browser-search-input-height);
      }

      &.@{listClsPrefix}-column {
        .@{listClsPrefix}-body .@{listClsPrefix}-item {
          font-size: var(--font-size-sm);
          color: var(--main-fc);

          // &:hover {
          //   background-color: var(--bg-base);
          // }
          .@{listClsPrefix}-content {
            border-bottom: none;
            min-height: calc(30 * var(--hd));
          }
        }
      }
    }
  }

  &-selected-wrap {
    display: flex;
    width: 100%;
    white-space: normal;

    .@{listClsPrefix}-scrollview-wrap {
      margin-bottom: calc(-3 * var(--hd));
      // .@{scrollerClsPrefix}__wrap {
      //   // margin-right: 0 !important;
      // }
    }

    /* 将搜索框追加到已选数据后显示 */
    &.append-search {
      .@{browserClsPrefix}-associative-selected {
        &-list {
          max-height: initial;
        }

        .@{listClsPrefix}-body {
          display: flex;
          flex-wrap: wrap;

          .@{listClsPrefix}-item {
            flex: 0 0 auto;
          }

          .@{browserClsPrefix}-associative-search {
            min-width: calc(30 * var(--hd));
            flex: 1 1 calc(30 * var(--hd));

            &-input {
              min-width: calc(30 * var(--hd));
            }
          }
        }
      }
    }
  }

  &-selected-box {
    min-width: 0;
    width: 100%;
    flex: 1 1 auto;
    white-space: normal;

    .@{browserClsPrefix}-associative-selected-list-check-all-btn {
      display: inline-block;
      margin-right: var(--h-spacing-md);
    }

    // loading 效果
    &-loading-wrap {
      overflow: hidden;
    }
  }

  /* 联想搜索已选数据 */
  &-selected {
    display: inline-flex;
    width: auto;
    max-width: 100%;
    vertical-align: middle;
    // margin-right: var(--h-spacing-sm);
    position: relative;
    line-height: 1;

    &.hide-more {
      .@{browserClsPrefix}-associative-selected-list {
        max-height: var(--browser-search-input-height);
        overflow-y: hidden;
      }
    }

    &.hidden-more-btn {
      .@{listClsPrefix}-scrollview-wrap {
        margin-right: 0;
      }

      .@{browserClsPrefix}-associative-selected-btn {
        display: none;
      }
    }

    &.wrap-display {
      display: flex;

      .@{listClsPrefix}-content {
        width: auto;
      }
    }

    &.selected-wrap {
      .@{browserClsPrefix}-associative-selected {
        &-list.@{listClsPrefix}-column {
          .@{listClsPrefix}-body {
            .@{listClsPrefix}-item {
              &-row {
                white-space: pre-wrap;
                word-break: break-word;
                word-wrap: break-word;
                overflow-wrap: break-word;
              }
            }
          }
        }
      }
    }

    &:not(.selected-wrap) {
      .@{browserClsPrefix}-associative-selected {
        &-list {
          .@{listClsPrefix}-item-row.is-group-data {
            display: flex;
            align-items: baseline;

            .@{browserClsPrefix}-panel-list-template-content-wrapper {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    &-list {
      flex: 1 1 auto;
      overflow-y: auto;
      scrollbar-width: none; // 火狐在设置 height，在 hidden、audo 之间切换会出现滚动条
      transition: var(--transition-all);
      scroll-behavior: smooth;

      &-check-all-btn {
        font-size: var(--font-size-sm);
        line-height: calc(32 * var(--hd));
        cursor: pointer;

        &:hover {
          color: var(--primary);
          text-decoration: underline;
        }
      }

      &.@{listClsPrefix}-column {
        .@{listClsPrefix}-body {
          .@{listClsPrefix}-item {
            .@{listClsPrefix}-content {
              border-bottom: none;
            }
          }
        }
      }

      /* 换行显示 */
      &.is-wrap {
        .@{listClsPrefix}-body .@{listClsPrefix}-item {
          display: flex;
        }
      }

      /* 显示按钮 */
      &.show-more-btn {
        padding-right: calc(14 * var(--hd));
      }

      /* 标签需要特殊展示 */
      &.is-tag {
        margin-bottom: calc(2 * var(--hd));

        .@{browserClsPrefix}-associative-selected-item.is-tag {
          // position: relative;
          height: calc(24 * var(--hd));
          padding: calc(5 * var(--hd)) var(--h-spacing-md);
          padding-right: var(--h-spacing-xs);
          border-radius: var(--border-radius-xs);

          .item-delete-icon {
            color: inherit;
          }
        }

        &.@{listClsPrefix}-column {
          .@{listClsPrefix}-body {
            .@{listClsPrefix}-item {
              &:hover {

                .@{listClsPrefix}-item-row,
                .@{listClsPrefix}-item-col {
                  color: inherit;
                }
              }

              .@{listClsPrefix}-item-row {
                color: inherit;
              }
            }
          }
        }
      }
    }

    &-btn {
      position: absolute;
      // right: calc(6 * var(--hd));
      right: 0;
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: var(--browser-search-input-height);
      line-height: var(--browser-search-input-height);
      font-size: var(--font-size-sm);
      color: var(--secondary-fc);
      user-select: none;

      .label {
        display: inline-flex;
        align-items: center;
      }

      &:hover {
        color: var(--primary);
      }
    }

    &-item {
      margin-right: var(--h-spacing-md);

      // padding: var(--v-spacing-xs) 0;
      /* 浏览按钮内置模版样式处理 */
      .browser-list-template {
        // flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        transition: var(--transition-color);

        .item-delete-icon {
          opacity: 1;

          .@{iconClsPrefix}-xs {
            display: block;
          }
        }
      }
      .@{listClsPrefix}-item-readonly-simple{
        line-height: var(--form-pc-readonly-line-height);
        height: auto;
        white-space: pre-wrap;
        word-break: break-word;
        overflow-wrap: break-word;
      }
      &.is-clickable:hover {

        .browser-list-template,
        .@{listClsPrefix}-item-row,
        .@{listClsPrefix}-item-readonly-simple {
          color: var(--primary);
          text-decoration: underline;
        }
      }

      &.is-readonly,
      &.is-disabled {
        margin-right: var(--h-spacing-sm);
      }

      &.is-deletable {
        margin-right: 0;
      }

      &.disabled-delete {
        margin-right: var(--h-spacing-sm);

        .item-delete-icon {
          // opacity: 0 !important;
          display: none;
        }
      }

      .item-delete-icon {
        flex: 0 0 auto;
        opacity: 0;
        width: var(--icon-size-xs);
        height: var(--icon-size-xs);
        transition: opacity var(--transition-du) var(--transition-tf);
        color: var(--invalid-fc);

        // padding-left: var(--h-spacing-xs);
        &:hover {
          color: var(--secondary-fc);
        }

        .@{iconClsPrefix}-xs {
          display: none;
        }
      }

      .is-finished {
        // text-decoration: line-through;
        color: var(--secondary-fc);
      }

      .is-deleted {
        color: #fa6e52;
      }
    }

    .@{listClsPrefix}-column {
      border-bottom: none;

      .@{listClsPrefix}-item {
        max-width: 100%;
        cursor: pointer;
        display: inline-flex;
        background-color: inherit;
        min-height: var(--browser-search-input-height);
        padding-right: var(--h-spacing-sm);

        &-row {
          font-size: var(--font-size-sm);
          color: var(--main-fc);
          // line-height: 1;
          line-height: normal;
        }

        &-col {
          flex-basis: auto;
        }

        .@{listClsPrefix}-content {
          min-height: var(--browser-search-input-height);

          &>a {
            text-decoration: none;
          }
        }
      }
    }

    &-card {
      width: calc(300 * var(--hd));
      height: calc(360 * var(--hd));
      border: var(--border-solid);
      border-radius: var(--border-radius-sm);

      &-header {
        display: flex;
        justify-content: space-between;
        font-size: var(--font-size-sm);
        height: calc(40 * var(--hd));
        line-height: calc(14 * var(--hd));
        background-color: #f5f4f5;
        padding: calc(12 * var(--hd)) var(--h-spacing-lg) calc(12 * var(--hd)) var(--h-spacing-md);
        border-bottom: var(--border-solid);
        border-radius: calc(6 * var(--hd)) calc(6 * var(--hd)) 0 0;

        &-close {
          cursor: pointer;
          color: var(--regular-fc);
        }
      }

      &-content {
        height: calc(318 * var(--hd));
        background: var(--bg-white);
        border-radius: 0 0 calc(6 * var(--hd)) calc(6 * var(--hd));
      }

      &-search {
        height: calc(42 * var(--hd));
        padding: calc(6 * var(--hd)) var(--h-spacing-lg);
      }

      &-list {
        position: relative;
        height: calc(276 * var(--hd));

        .@{listClsPrefix}-scrollview-wrap {
          height: 100% !important;

          /* 为了使列表可以滚动加载，需要继承高度 */
          .@{scrollerClsPrefix} {
            border-radius: 0 0 calc(6 * var(--hd)) calc(6 * var(--hd));
          }
        }

        .content-empty {
          width: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin: 0;
        }
      }
    }

    &-textarea {
      textarea {
        border: none;
        padding-left: 0;
        padding-right: 0;

        &:focus {
          border-color: transparent;
          box-shadow: none;
        }
      }
    }
  }

  /* 联想搜索搜索框 */
  &-search {
    display: inline-block;

    // max-width: var(--browser-dropdown-min-width);
    &-input {
      .@{inputClsPrefix}-suffix {
        height: var(--input-height);
      }

      /**
      * 这里是为了让 placeholder 超出显示省略号
      * 但是 webkit 中，focus 时省略号不起作用
      * https://stackoom.com/question/3gEl9
      */
      .@{inputClsPrefix} {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.is-focus {
        .associative-search-icon:not(.disabled) {
          color: var(--primary);
        }
      }
    }

    /* 输入框后显示加号 */
    &.with-input-addon {
      .@{browserClsPrefix}-associative-search-input {
        padding-right: calc(40 * var(--hd));

        &-addon {
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: calc(32 * var(--hd));
          cursor: pointer;
          display: flex;
          overflow: hidden;
          align-items: center;
          justify-content: center;
          color: var(--secondary-fc);
          background-color: var(--input-group-addon-bg);
          border-radius: 0 var(--input-border-radius) var(--input-border-radius) 0;
          border-left: var(--border-solid);
          transition: var(--transition-all);

          .@{browserClsPrefix}-extra-btn-icon {
            margin-right: 0;
          }
        }

        &.is-focus {
          .@{browserClsPrefix}-associative-search-input {
            &-addon {
              color: var(--primary);
              background-color: var(--label-bc-active);
              border-left-color: var(--primary);
            }
          }
        }
      }
    }

    /* 显示输入框 */
    &.display-input {
      width: auto;
      min-width: calc(60 * var(--hd));
      max-width: calc(250 * var(--hd));

      &.adaptive-input-width {
        max-width: 100%;
      }

      &.is-empty {
        width: calc(100 * var(--hd));
      }

      .associative-search-icon:not(.disabled) {
        &:hover {
          color: var(--primary);
        }
      }
    }

    /* 加号模式，隐藏输入框 */
    &.hide-input {
      max-width: 100%;

      .associative-search-icon {
        margin-left: 0;
      }

      .@{browserClsPrefix}-associative-search-input {
        transition: var(--transition-all);
        width: calc(30 * var(--hd));
        border-radius: var(--browser-search-input-radius);
        padding-left: calc(7 * var(--hd));
        transform: scale(0.8);

        input {
          border-radius: var(--browser-search-input-radius);
          transition: var(--transition-all);
          opacity: 0;
        }

        &-addon {
          width: 0;
          // margin-right: 0;
          border-left-width: 0;
          border-radius: 0 var(--browser-search-input-radius) var(--browser-search-input-radius) 0;
        }
      }

      &.with-input-addon {
        .@{browserClsPrefix}-associative-search-input {
          padding-right: var(--h-spacing-md);
        }
      }

      &.is-focus {
        .@{browserClsPrefix}-associative-search-input {
          max-width: 100%;
          // width: var(--browser-dropdown-min-width);
          width: calc(100 * var(--hd));
          border-radius: var(--border-radius-xs);
          transform: scale(1);

          input {
            border-radius: var(--border-radius-xs);
            opacity: 1;
          }

          &-addon {
            width: calc(32 * var(--hd));
            // margin-right: calc(-8 * var(--hd));
            border-left-width: var(--hd);
            border-radius: 0 var(--border-radius-xs) var(--border-radius-xs) 0;
          }
        }

        &.with-input-addon {
          .@{browserClsPrefix}-associative-search-input {
            width: calc(132 * var(--hd));
            padding-right: calc(40 * var(--hd));
          }
        }
      }
    }

    /* 将已选数据显示到输入框中 */
    &.display-selected-in-input {
      &:not(.adaptive-input-width) {
        max-width: calc(150 * var(--hd));

        .@{browserClsPrefix}-associative-selected {
          &-item:not(.disabled-delete) {
            &:hover {
              .item-delete-icon {
                display: block;
              }
            }
          }
        }
      }

      &:not(.adaptive-input-height) {
        .@{browserClsPrefix}-associative-selected {
          &-list {
            max-height: var(--browser-search-input-height);
          }

          .@{listClsPrefix}-scrollview-wrap {
            // height: var(--input-height) !important;
            height: var(--input-height);

            .@{scrollerClsPrefix}__bar {
              display: none;
            }
          }
        }
      }

      &:not(.hide-input) {
        &.is-empty {
          .@{inputClsPrefix} {
            width: calc(120 * var(--hd)) !important;
            flex: 1 1 auto;
          }
        }

        .@{inputClsPrefix}-wrap {
          .@{inputClsPrefix}-suffix {
            width: calc(var(--font-size-md) + var(--h-spacing-xs));

            .associative-search-icon {
              position: absolute;
              top: 0;
              right: calc(7 * var(--hd));
              height: calc(30 * var(--hd));
            }
          }

          &.has-clear {
            .@{inputClsPrefix}-suffix {
              width: calc(var(--font-size-md) + var(--icon-size-s));
            }
          }
        }

        &.with-input-addon {
          .@{inputClsPrefix}-wrap {
            .@{inputClsPrefix}-suffix {
              .associative-search-icon {
                position: relative;
                top: 0;
                right: 0;
              }
            }
          }
        }
      }

      .@{browserClsPrefix}-associative-search-input {
        &.is-focus {
          .@{browserClsPrefix}-associative-selected:not(.is-multiple) {
            display: none;
          }

          .@{inputClsPrefix} {
            display: inline-block;
          }
        }

        .@{inputClsPrefix}-prefix {
          margin-right: 0;
          flex: 0 0 auto;
          max-width: calc(100% - var(--h-spacing-xs) - var(--icon-size-xs) - calc(10 * var(--hd)));
        }

        &.is-IE:not(.is-empty) {
          .@{inputClsPrefix}-prefix {
            max-width: 100%;
            flex: 0 0 auto;
          }
        }

        &:not(.is-focus) {
          .@{inputClsPrefix} {
            width: calc(10 * var(--hd));
          }
        }
      }

      .@{browserClsPrefix}-associative-selected {
        margin-right: 0;

        &-item {
          padding: var(--v-spacing-xs) 0;

          &.disabled-delete {
            .item-delete-icon {
              display: none;
            }
          }
        }

        .@{listClsPrefix}-column {
          .@{listClsPrefix}-item {
            min-height: var(--input-height);

            .@{listClsPrefix}-content {
              min-height: var(--input-height);
            }

            &:last-of-type {
              padding-right: 0;
            }

            &-col {
              min-width: calc(28 * var(--hd));
            }

            .item-delete-icon {
              // padding-left: var(--h-spacing-xs);
              // width: calc(var(--h-spacing-xs) + var(--icon-size-xs));
              background: linear-gradient(to right,
                  rgba(255, 255, 255, 0.1),
                  var(--bg-white));

              // position: absolute;
              // right: 0;
              .@{iconClsPrefix}-xs {
                background-color: var(--bg-white);
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    &-add-select {
      border: var(--border-solid);
      margin-top: var(--v-spacing-sm);
      margin-bottom: var(--v-spacing-sm);
      padding-top: var(--v-spacing-md);
      padding-bottom: var(--v-spacing-md);
      background: var(--bg-white);
      border-radius: var(--border-radius-xs);
      min-width: calc(130 * var(--hd));
      font-size: var(--select-option-fs);
      box-sizing: border-box;
      box-shadow: var(--box-shadow-storke);

      &-item {
        text-align: left;

        &:hover {
          background-color: #f8f8f8;
        }

        &.@{browserClsPrefix}-extra-btn {
          display: flex;
          padding: var(--v-spacing-sm) var(--h-spacing-md);
          color: var(--main-fc);

          .@{browserClsPrefix}-extra-btn-icon {
            .ui-icon-xs {
              width: calc(18 * var(--hd));
              height: calc(18 * var(--hd));
            }

            color: var(--secondary-fc);
            margin-right: var(--h-spacing-md);
          }
        }
      }
    }
  }

  /* 搜索框中的按钮样式 */
  .associative-search-icon {
    &:not(.disabled) {
      cursor: pointer;
    }
    width: var(--font-size-md);
    height: 100%;
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-left: var(--h-spacing-xs);

    .@{spinClsPrefix} {
      display: inline-block;
      width: var(--font-size-md);
      height: var(--font-size-md);
    }

    .@{iconClsPrefix} {
      vertical-align: initial;
    }
  }

  /* 联想搜索中列表的样式 */
  &-selected,
  &-dropdown-list {
    &-item {
      width: 100%;
      height: 100%;
      display: inline-flex;
      align-items: center;

      &-icon {
        margin-right: var(--h-spacing-md);
        color: var(--secondary-fc);
      }

      &-avatar {
        flex-shrink: 0;
        margin-right: var(--h-spacing-md);
      }

      &.is-highlight {
        background-color: var(--bg-base);
      }
    }

    .@{listClsPrefix}-item {
      .@{triggerClsPrefix} {
        width: 100%;
      }

      &-col {
        overflow: hidden;

        &.col1_row2,
        &.col2_row2 {
          .@{listClsPrefix}-item-row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-void {
              display: none;
            }

            &-span {
              &-void {
                display: none;
              }
            }
          }
        }
      }

      .@{listClsPrefix}-item-row {
        font-size: var(--font-size-sm);
        color: var(--main-fc);

        .@{listClsPrefix}-item-row-span-text-highlight {
          font-weight: normal;
          max-width: 100%;
          vertical-align: middle;
        }
      }

      &-row {
        padding: calc(2 * var(--hd)) 0;
        // line-height: 1.3;
        line-height: 1;
        cursor: pointer;
        max-width: 100%;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;

        &-void {
          display: none;
        }

        &:last-child {
          border-bottom: none;
          color: var(--secondary-fc);
          // padding-bottom: 0;
        }

        &:first-child {
          color: var(--main-fc);
        }

        &-span {
          margin-right: var(--h-spacing-sm);
          overflow: hidden;
          text-overflow: ellipsis;

          &-text-highlight {
            font-weight: normal;
          }

          &-void {
            display: none;
          }
        }
      }
    }
  }

  /* 列表功能按钮 */
  &-selected-fun-btn {
    display: flex;
    flex-wrap: nowrap;
    // line-height: var(--browser-search-input-height);
    height: calc(30 * 1px);

    .fun-btn {
      display: flex;
      align-items: center;
      margin-left: 10px;
      color: #999;
      cursor: pointer;

      &:hover {
        color: var(--primary);
      }
    }
  }
}

/* 限定多选样式调整 */
.@{browserClsPrefix}.is-multiple {
  .@{browserClsPrefix}-associative-selected-wrap.is-multiple.spread-input-width {
    width: 100%;
  }

  /* search input 样式调整 */
  .@{browserClsPrefix}-associative-search.display-input {
    transition: var(--transition-w);
    width: calc(100 * var(--hd));

    &.with-input-addon {
      width: calc(132 * var(--hd));
    }

    &.adaptive-input-width {
      max-width: 100%;
    }
  }

  /* 多行文本输入框显示 */
  .@{browserClsPrefix}-associative-search.display-in-textarea {
    width: auto;
    max-width: 100%;
  }
}

/* 只读样式调整 */
.@{browserClsPrefix} {
  &-associative-selected-wrap {
    &.is-readOnly {

      /** 滚动区域 */
      .@{listClsPrefix}-scrollview-wrap {
        .ui-scroller__bar.is-vertical {
          opacity: 1;
        }
      }
    }
  }
  // 只读效果行高优化
  &-associative-selected-wrap.read-only-line-height-standard  {
    .@{browserClsPrefix}-associative-selected {
      .@{listClsPrefix}-body {
        padding-top: var(--form-pc-readonly-padding-y);
        padding-bottom: var(--form-pc-readonly-padding-y);
        line-height: var(--form-pc-readonly-line-height);
        .@{listClsPrefix}-item {
          vertical-align: top;
          min-height: auto;
          .@{listClsPrefix}-content {
            min-height: auto;
            .@{listClsPrefix}-item-row {
              line-height: var(--form-pc-readonly-line-height);
              height: auto;
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}

/* 限定多选样式调整 */
.@{browserClsPrefix}.box-selection.is-multiple {
  .@{browserClsPrefix}-associative {
    &-search {

      /* 将已选数据显示到输入框中 */
      &.display-selected-in-input {
        &:not(.adaptive-input-height) {
          .@{browserClsPrefix}-associative-selected {

            // .@{scrollerClsPrefix} {
            //   overflow: unset;
            // }
            &-list {
              max-height: 100%;
            }

            .@{listClsPrefix}-scrollview-wrap {
              height: var(--input-height);

              // 显示被浏览按钮隐藏的竖直滚动条
              .@{scrollerClsPrefix}__bar.is-vertical {
                display: block;
              }
            }
          }
        }

        &:not(.hide-input) {
          .@{inputClsPrefix}-wrap {
            .@{inputClsPrefix}-suffix {
              width: auto;
              align-self: start;

              .associative-search-icon {
                position: relative;
                top: auto;
                right: auto;
                // margin-right: 7px;
                // margin-left: 0;
              }
            }
          }
        }

        .@{browserClsPrefix}-associative-search {
          &-input {
            overflow: hidden;

            .@{inputClsPrefix} {
              // min-width: 10px;
              // &-suffix {
              //   height: 100%;
              // }
            }

            &-suffix {
              display: flex;
            }
          }

          &-fun-btn {
            display: flex;
            align-items: center;
            height: var(--input-height);

            .fun-btn {
              line-height: 0;
              margin-left: calc(7 * var(--hd));
              cursor: pointer;
              // &:last-child {
              //   margin-right: 10px;
              // }
            }
          }
        }
      }

      &:not(.is-empty) {
        &.display-input {
          width: auto;
        }

        /* 将已选数据显示到输入框中 */
        &.display-selected-in-input {
          &:not(.adaptive-input-width) {
            max-width: 100%;
          }

          &.with-input-addon {
            .@{browserClsPrefix}-associative-search-input {
              padding-right: calc(40 * var(--hd));
            }
          }
        }

        /* 多行文本输入框显示 */
        &.display-in-textarea {
          width: auto;
          max-width: 100%;
        }

        // .@{browserClsPrefix}-associative-search-input {
        // padding-right: 0;
        // &.is-wrap {
        //   display: block;
        //   .@{browserClsPrefix}-associative-selected-wrap {
        //     padding-right: calc(18 * var(--hd));
        //   }
        //   .@{inputClsPrefix}-suffix {
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //   }
        // }
        // }
        .@{browserClsPrefix}-associative-selected-wrap {
          overflow: hidden;
        }
      }
    }

    &-selected {
      &.wrap-display {
        display: inline-flex;
      }

      &-wrap {
        &.is-empty {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .@{browserClsPrefix}-associative-selected-list {
            display: block;
          }
        }

        &.append-search {
          .@{browserClsPrefix}-associative-selected-list {
            &.is-wrap {
              .@{listClsPrefix}-body {
                display: block;
                flex-wrap: nowrap;
              }
            }

            .@{browserClsPrefix}-associative-box-selection-wrap {
              display: none;

              &.show-input {
                display: inline-flex;
              }
            }
          }
        }
      }

      &:not(.is-empty) {
        margin-right: 0;
        line-height: 1.15;
      }

      .@{listClsPrefix}-column {
        .@{listClsPrefix}-item {
          .item-delete-icon {
            background: none;
            position: relative;
          }
        }
      }
    }
  }
}

/* 已选数据在框内，input 样式 */
.@{browserClsPrefix} {
  &-associative-box-selection {
    &-wrap {
      position: relative;

      .@{browserClsPrefix} {
        &-associative-box-selection {
          &-stretched-span {
            display: none;
          }
        }
      }

      &:not(.is-empty) {
        .@{browserClsPrefix} {
          &-associative-box-selection {
            &-stretched-span {
              padding-right: var(--h-spacing-sm);
              display: inline-block;
              height: auto;
              min-width: calc(10 * var(--hd));
              min-height: var(--input-height);
              opacity: 0;
              line-height: var(--input-line-height);
              font-weight: var(--font-weight-base);
              font-size: var(--input-font-size);
              background-color: var(--input-bg);
              white-space: pre;
              overflow: hidden;
              text-overflow: ellipsis;
              transition: border-color var(--transition-du) var(--transition-tf),
                var(--transition-color);
            }

            &-input {
              display: inline-block;
              position: absolute;
              left: 0;
              width: calc(100% - calc(14 * var(--hd)));
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

/* 多类型单选样式调整 */
.@{browserClsPrefix}-types,
.@{browserClsPrefix}-share {
  &.is-single {
    .@{browserClsPrefix} {
      &-associative-search.display-selected-in-input {
        .@{browserClsPrefix}-associative-search-input:not(.is-focus) {
          .ui-input {
            width: 100%;
          }
        }
      }
    }
  }

  .@{browserClsPrefix}-associative {
    &-selected-wrap:not(.is-readOnly) {

      .@{browserClsPrefix}-types,
      .@{browserClsPrefix}-share {
        &-associative {
          &-inner {
            .@{browserClsPrefix} {
              width: auto;
            }
          }
        }
      }
    }
  }
}

/** 多类型样式调整，已选数据在框内 */
.@{browserClsPrefix}-types,
.@{browserClsPrefix}-share {
  &.box-selection {

    /* 限定多选样式调整 */
    .is-multiple {
      .@{browserClsPrefix}-associative {
        &-search {
          &:not(.is-empty) {
            &.display-input {
              width: calc(100 * var(--hd));
            }
          }
        }
      }
    }
  }

  &.box-selection:not(.is-empty) {
    .@{browserClsPrefix}-associative {
      &-selected-box {
        // display: flex;
      }

      // 操作按钮样式调整
      &-selected-fun-btn {
        flex: 0 0 auto;

        .fun-btn:first-child {
          margin-left: 0;
        }
      }

      &-selected-wrap:not(.is-readOnly) {
        min-height: 30px;
        overflow: hidden;
        border: var(--border-solid);
        border-radius: var(--input-border-radius);
        background-color: var(--input-bg);
        display: inline-flex;
        // flex-wrap: wrap;
        // align-items: center;
        vertical-align: middle;
        padding: 0 var(--input-padding-x);

        &:not(.show-input) {

          .@{browserClsPrefix}-types,
          .@{browserClsPrefix}-share {
            &-associative {
              &-inner {
                display: none;
              }
            }
          }
        }

        .@{browserClsPrefix}-types,
        .@{browserClsPrefix}-share {
          &-associative {
            &-selected {
              min-height: calc(30 * var(--hd));

              .ui-list-column {
                display: block;
              }
            }

            &-inner {
              border: none;
              min-height: calc(30 * var(--hd));

              .@{browserClsPrefix} {
                width: auto;
              }
            }
          }
        }
      }

      &-search-input {
        border: none;
        box-shadow: none;

        .@{inputClsPrefix}-suffix {
          width: calc(20 * var(--hd));
        }
      }
    }

    .@{browserClsPrefix}-types-select,
    .@{browserClsPrefix}-share-select {
      .@{selectClsPrefix}-input {
        border: none;
        box-shadow: none;
      }
    }
  }
}

/** 针对查看详情模式样式特殊处理 */
.@{browserClsPrefix}-share.show-detail {
  .@{browserClsPrefix}-associative {
    &-selected.is-multiple {
      .@{listClsPrefix}-scrollview-wrap {
        overflow: hidden;
      }
    }
  }
}

/** 浏览按钮后部追加按钮样式 */
.@{browserClsPrefix}.append-extra-ele {
  .@{browserClsPrefix} {
    &-associative-search {
      margin-right: calc(10 * var(--hd));
    }

    &-suffix-btn {
      display: inline-block;
      height: calc(30 * var(--hd));
      display: inline-flex;
      align-items: center;
      font-size: calc(12 * var(--hd));
      color: var(--primary);
      vertical-align: top;
    }
  }
}

/** 已选数据在框内，宽度自定义 */
.@{browserClsPrefix} {
  .@{browserClsPrefix}-associative {
    &-search {
      &.display-selected-in-input {
        &.custom-input-width {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }

  /** 多选框内样式，宽度自定义 */
  &.box-selection.is-multiple {
    .@{browserClsPrefix}-associative-search:not(.is-empty) {
      &.display-selected-in-input {
        &.custom-input-width {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
/** 已选数据对齐样式（多选） */
.@{browserClsPrefix}.is-multiple.box-selection {
  .@{browserClsPrefix}-associative {
    &-selected-wrap {
      &.spread-input-width {
        .@{browserClsPrefix}-associative-selected {
          width: 100%;
          &.align-center {
            .ui-list-body {
              justify-content: center;
            }
          }
          &.align-right {
            .ui-list-body {
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}
/** 已选数据对齐样式（单选） */
.@{browserClsPrefix}.is-single.box-selection {
  .@{browserClsPrefix}-associative-search-input {
    &.align-center {
      justify-content: center;
    }
    &.align-right {
      justify-content: flex-end;
    }
  }
}
/** 已选数据对齐样式（单选） */
.@{browserClsPrefix}-types,
.@{browserClsPrefix}-share {
  &.is-single.box-selection:not(.is-empty) {
    .@{browserClsPrefix}-associative-selected-box {
      display: flex;
      &.align-center {
        justify-content: center;
      }
      &.align-right {
        justify-content: flex-end;
      }
    }
  }
}
