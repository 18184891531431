.@{mCommentClsPrefix}-log {
  font-size: var(--font-size-12);
  height: 100%;
  background-color: var(--m-comment-bgcolor);
  display: flex;
  flex-direction: column;
  .@{mListClsPrefix}-scrollview-content {
    height: 100%;
  }
  &-header {
    flex: none;
  }
  &-body {
    height: 100%;
    flex: auto;
    overflow: hidden;
    &:not(:last-child) {
      padding-bottom: calc(45 * var(--hd));
    }
    .@{pullToRefreshClsPrefix}-content-wrapper {
      height: 100%;
    }
    .@{mListClsPrefix}-footer-nomore, .@{mListClsPrefix}-footer-more, .@{mListClsPrefix}-footer-loading {
      margin: 0;
      padding: calc(10 * var(--hd)) 0;
    }
  }  
  &-cancel {
    border: 0;
    border-radius: 0;
    border-top: var(--hd) solid #d9d9d9;
    background-color: var(--m-comment-bgcolor-white);
    position: fixed;
    height: calc(45 * var(--hd));
    bottom: 0;
  }
  &-avatar {
    position: absolute;
    left: calc(16 * var(--hd));
    top: calc(10 * var(--hd));
    .@{mAvatarClsPrefix}-container {
      width: calc(32 * var(--hd));
      height: calc(32 * var(--hd));
    }
  }
  &-read {
    color: var(--main-fc);
    height: calc(17 * var(--hd));
    line-height: calc(17 * var(--hd));
  }
  &-read&-unread {
    color: #FF666A;
  }
  &-list {
    padding: 0 calc(15 * var(--h));
  }
  &-item {
    padding-left: calc(60 * var(--hd));
    position: relative;
    background-color: var(--m-comment-bgcolor-white);
    &:not(:last-child) {
      &::after {
        z-index: 99;
        content: "";
        position: absolute;
        height: var(--hd);
        transform: scaleY(0.3);
        background-color: #e7e7e7;
        bottom: 0;
        right: 0;
        width: calc(100% - (60 * var(--hd)));
      }
    }
  }
  &-empty {
    padding-top: 95px;
    .@{emptyClsPrefix} {
      &-title {
        font-size: var(--font-size-14);
      }
    }
  }
  &-container {      
    color: var(--secondary-fc);
    padding: calc(10 * var(--hd)) calc(16 * var(--hd)) calc(10 * var(--hd)) 0;
  }
  &-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(3 * var(--hd));
    height: calc(20 * var(--hd));
    line-height: calc(20 * var(--hd));
  }
  &-username {
    flex: 0 1 auto;
    font-size: var(--font-size-14);
  }
  &-time {
    flex: none;
  }
}