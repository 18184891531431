.@{recordingClsPrefix} {
  font-size: var(--font-size-12);

  &-audio {

    &-btn {
      display: flex;
      align-items: center;
      font-size: var(--font-size-12);
      padding: calc(10 * var(--hd));
      border-bottom: var(--hd) solid #eee;

      &-disable {
        color: var(--regular-fc);
      }

      &-title {
        color: #2562ff;
        margin-left: calc(8 * var(--hd));
      }

      &-icon {
        background: #2562ff;
        display: inline-flex;
        height: calc(24 * var(--hd));
        width: calc(24 * var(--hd));
        align-items: center;
        justify-content: center;
        color: var(--base-white);
        border-radius: 50px;
      }
    }

    &-loading {
      margin: calc(9 * var(--hd));
      height: calc(32 * var(--hd));
      border-radius: 6px;
      border: var(--hd) solid #3c6cf6;
      display: flex;
      align-items: center;
      padding: 0 calc(3 * var(--hd)) 0 calc(8 * var(--hd));
      justify-content: space-between;

      &-name {
        .ellipsis();
        max-width: calc(100% - (55 * var(--hd)));
        flex: auto;
      }

      .@{spinClsPrefix} {
        right: calc(12 * var(--hd));
      }
    }

    &-upload {
      display: none;
    }

    &-list {
      padding: calc(9 * var(--hd));

      &>* {
        &:not(:last-child) {
          margin-bottom: calc(11 * var(--hd));
        }
      }
    }

    &-item {
      display: flex;
      align-items: center;
      background: #ffffff;
      border: var(--hd) solid #3c6cf6;
      border-radius: 6px;
      padding: 0 calc(3 * var(--hd));

      &-close {
        flex: none;
        color: #919191;
        width: calc(22 * var(--hd));
        height: calc(30 * var(--hd));
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

    }

    & &-player {
      background: #ffffff;
      color: #919191;

      .vjs-play-progress {
        background: #73859f;
      }

      .vjs-load-progress {

        div {
          background: #e3e0e0;
        }
      }

      .vjs-control-bar {
        background: #ffffff;
      }

      .vjs-volume-level {
        background: #73859f;
      }

      .vjs-slider {
        background: #e3e0e0;
      }

      .vjs-menu-item {
        color: #fff;

        &.vjs-selected {
          color: #919191;
        }
      }

      .vjs-play-progress:before {
        font-size: 1em;
      }

      .vjs-volume-level:before {
        font-size: 1em;
        top: -0.4em;
      }

    }
  }

  &-video {
    display: flex;
    flex-wrap: wrap;

    .@{recordingClsPrefix}-video-item,
    .@{recordingClsPrefix}-video-loading,
    .@{recordingClsPrefix}-upload {
      margin: calc(8 * var(--hd));
    }

    // &>* {
    //   flex: none;
    //   margin-bottom: calc(16 * var(--hd));
    // }

    // &:not(&-reverse)>* {
    //   margin-right: calc(16 * var(--hd));
    // }

    // &-reverse>* {
    //   margin-left: calc(16 * var(--hd));
    // }

    & &-upload-wrapper {
      width: initial;
      flex: none;
      cursor: pointer;
    }

    &-loading {
      width: calc(95 * var(--hd));
      height: calc(95 * var(--hd));
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      border: var(--hd) dashed var(--border-color);
      border-radius: 3px;
      padding: calc(2 * var(--hd)) calc(4 * var(--hd));
      position: relative;

      &-name {
        .ellipsis();
        max-width: 100%;
        position: absolute;
        right: calc(0 * var(--hd));
        padding: 0 calc(6 * var(--hd));
        bottom: calc(3 * var(--hd));
      }

      .@{spinClsPrefix} {
        width: 100%;
      }
    }

    &-upload {
      height: calc(95 * var(--hd));
      width: calc(95 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 3px;
      border: var(--hd) dashed var(--border-color);
      position: relative;

      &-title {
        color: var(--secondary-fc);
        margin-top: calc(6 * var(--hd));
      }

      .@{iconClsPrefix} {
        color: var(--invalid);

        svg {
          height: calc(32 * var(--hd));
          width: calc(32 * var(--hd));
        }
      }

    }

    & &-player {

      .vjs-tech {
        height: calc(95 * var(--hd));
        width: calc(95 * var(--hd));
      }
    }

    &-item {
      position: relative;
      background: #000;
      border-radius: 3px;

      &-delete {
        background: unset;
      }

      &-close {
        // background: #7E7E7E;
        // color: #fff;
        // border: var(--hd) solid #fff;
        // border-radius: calc(10 * var(--hd));
        // width: calc(18 * var(--hd));
        // height: calc(18 * var(--hd));
        // right: calc(-5 * var(--hd));
        // top: calc(-5 * var(--hd));
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        right: calc(-8 * var(--hd));
        top: calc(-8 * var(--hd));
        color: #999999;
        border-radius: 50%;
        background: var(--base-white);
      }
    }

    &-show {
      height: calc(95 * var(--hd));
      width: calc(95 * var(--hd));
      cursor: pointer;
    }

    &-preview {
      display: block;
      border-radius: 2px;

      &-wrapper {
        position: relative;
      }

      &-wrapper-rtl {
        transform: scaleX(-1);
      }

      &-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        color: #fff;
        border-radius: 0px 0px 2px 2px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 calc(6 * var(--hd));

        svg {
          filter: drop-shadow(0px 0px 4px rgb(0 0 0 / 0.5));
        }
      }

      &-footer-rtl {
        transform: scaleX(-1);
      }

      &-duration {
        text-shadow: 0px 0px 3px rgb(0 0 0 / 50%);
      }
    }

    &-delete {
      border: 1px dashed var(--border-color);
      height: calc(95 * var(--hd));
      width: calc(95 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: center;

      &-iconWrap {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-icon {
        svg {
          width: calc(var(--hd) * 32);
          height: calc(var(--hd) * 32);
          color: var(--invalid);
        }
      }
    }
  }

  &-limit {
    font-size: var(--font-size-12);
    color: #FF1A2A;
  }

  &-play {

    &-icon {
      position: absolute;
      border-radius: 50px;
      border: var(--hd) solid #fff;
      color: #fff;
      width: calc(22 * var(--hd));
      height: calc(22 * var(--hd));
      display: flex;
      justify-content: center;
      align-items: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0px 0px 4px rgb(0 0 0 / 20%), inset 0px 0px 2px rgb(0 0 0 / 20%);

      svg {
        filter: drop-shadow(0px 0px 4px rgb(0 0 0 / 0.5));
      }
    }
  }
}

// 只读与空状态
.@{recordingClsPrefix} {
  &-empty {
    margin: calc(4 * var(--hd)) 0;
  }

  &-audio,
  &-video {
    &-readOnly {

      .@{recordingClsPrefix}-empty {
        width: 100%;
        display: flex;
        align-items: center;
        margin: unset;
      }
    }

    &-readOnly-readOnlyLineHeightStandard {
      min-height: calc(30 * var(--hd));
      .form-pc-readOnlyLineHeightStandard;
    }
  }
}