
.@{mindMapClsPrefix}{
    height: 100%;
    width: 100%;
    position: relative;
    .@{mindMapClsPrefix}-container{
        height: 100%;
        width: 100%;
    }
    .@{mindMapClsPrefix}-footer{
        position: absolute;
        bottom: 60px;
        right: 30px;
        z-index: 3;
        &>span{
            display: inline-block;
            width: 45px;
            height: 35px;
            text-align: center;
            background-color: #fff;
            box-shadow: 1px 2px 1px rgba(0,0,0,.15);
            color: #999;
            line-height: 35px;
            cursor: pointer;
        }
    }
    &.@{mindMapClsPrefix}-full-screen {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        z-index: 1005;
        background-color: #fff;
    }
    jmexpander{
        line-height:9px;
    }

}