.@{mstepsClsPrefix} {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &-item-icon {
    width: 50%;
    text-align: center;
  }

  .@{mstepsClsPrefix}-item-content {
    font-size: var(--font-size-12);
    color: var(--main-fc);
  }

  .@{mstepsClsPrefix}-icon {
    width: var(--m-steps-vertical-icon-wrap-size);
    height: var(--m-steps-vertical-icon-wrap-size);
    display: flex;
    align-items: center;
    justify-content: center;
    border: calc(2 * var(--hd)) solid var(--m-btn-link-fc);
    border-width: var(--border-width-lg);
    border-radius: 50%;
    background-color: var(--m-btn-link-fc);
    color: var(--base-white);

    svg {
      width: calc(8 * var(--hd));
      height: calc(8 * var(--hd));
    }
  }

  &-item-subtitle {
    margin-left: calc(3 * var(--hd));
    color: var(--secondary-fc);
  }

  &-item-title {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &-process {
    .@{mstepsClsPrefix}-icon {
      background-color: var(--bg-white);
    }
  }

  &-finish {
    .@{mstepsClsPrefix}-line {
      background-color: var(--primary) !important;
    }
  }

  &-error {
    .@{mstepsClsPrefix}-icon {
      background-color: var(--danger);
      border-color: var(--danger);
    }

    .@{mstepsClsPrefix}-item-content,
    .@{mstepsClsPrefix}-item-description {
      color: var(--danger);
    }

    .@{mstepsClsPrefix}-item-subtitle {
      color: var(--m-btn-danger-disabled-bc);
    }

  }

  &-wait {
    .@{mstepsClsPrefix}-icon {
      background-color: var(--bg-white);
      color: var(--secondary-fc);
      border-color: var(--invalid);
    }

    .@{mstepsClsPrefix}-item-subtitle {
      color: var(--secondary-fc);
    }

    .@{mstepsClsPrefix}-item-content {
      color: var(--secondary-fc);
    }
  }

  &-wait+.@{mstepsClsPrefix}-item-content {
    color: var(--secondary-fc);
  }
}

//水平方向
.@{mstepsClsPrefix}-horizontal {
  .@{mstepsClsPrefix}-item {
    flex: 1;

    //隐藏最后一条线
    &:last-child {
      //清除最后一个元素占位
      flex: none;

      .@{mstepsClsPrefix}-item-head {
        .@{mstepsClsPrefix}-line-wrap {
          display: none;
        }
      }
    }


    &-head {
      width: 100%;
      display: flex;

      .@{mstepsClsPrefix}-item-icon {
        width: auto;
        display: flex;
        justify-content: center;
      }

      .@{mstepsClsPrefix}-line-wrap {
        flex: 1;
        padding: 0 var(--v-spacing-md);
      }

      .@{mstepsClsPrefix}-line {
        width: 100%;
        height: calc(1 * var(--hd));
        background: var(--steps-line-color);
        margin-top: calc(var(--m-steps-vertical-icon-wrap-size) / 2);
      }
    }

    .@{mstepsClsPrefix}-label-vertical {
      flex-direction: column;
      align-items: center;

      .@{mstepsClsPrefix}-icon {
        transform: translateY(25%);
      }

      .@{mstepsClsPrefix}-item-content {
        margin-top: var(--m-steps-content-lable-top);
        text-align: left;
      }

      .@{mstepsClsPrefix}-line {
        margin-top: 0.6em;
      }
    }

    .@{mstepsClsPrefix}-label-horizontal {
      .@{mstepsClsPrefix}-item-content {
        margin-left: var(--steps-cotent-label-spacing);
      }

      .@{mstepsClsPrefix}-icon {
        margin-top: calc(-0.5 * var(--hd));
      }
    }
  }

  .@{mstepsClsPrefix}-label-vertical.@{mstepsClsPrefix}-center {
    position: relative;

    .@{mstepsClsPrefix}-item-head {
      display: flex;
      justify-content: center;

      .@{mstepsClsPrefix}-line-wrap {
        position: absolute;
        left: 50%;
        right: -50%;
        padding: 0 calc(10 * var(--hd));
        width: 100%;
      }
    }


    //隐藏最后一条线
    &:last-child {
      //清除最后一个元素的占位
      flex: 1;

      .@{mstepsClsPrefix}-item-head {
        .@{mstepsClsPrefix}-line-wrap {
          display: none;
        }
      }
    }

    .@{mstepsClsPrefix}-label-vertical {
      .@{mstepsClsPrefix}-item-content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

}

// 垂直方向
.@{mstepsClsPrefix}-vertical {
  height: 100%;
  display: flex;
  flex-direction: column;
 
  .@{mstepsClsPrefix}-item {
    flex: 1;
    position: relative;

    //隐藏最后一条线
    &:last-child {
      //去除最后元素占位
      flex-grow: 0;

      .@{mstepsClsPrefix}-item-container {
        position: relative;
        min-height: var(--steps-icon-height);
      }

      .@{mstepsClsPrefix}-item-head {
        .@{mstepsClsPrefix}-item-icon {
          .@{mstepsClsPrefix}-line {
            display: none;
          }
        }
      }
    }

    &-container {
      display: flex;
      height: 100%;
      min-height: inherit;
    }

    .@{mstepsClsPrefix}-item-head {
      display: flex;
      min-height: inherit;

      .@{mstepsClsPrefix}-item-icon {
        display: flex;
        align-items: center;
        flex-direction: column;

        .@{mstepsClsPrefix}-line {
          flex: 1;
          width: calc(1 * var(--hd));
          background: var(--steps-line-color);
          margin: calc(3 * var(--hd)) 0; //控制线条和图标的距离
        }
      }
    }

    .@{mstepsClsPrefix}-item-content {
      margin-left: calc(5 * var(--hd));
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: translateY(-50%) translateY(var(--m-steps-vertical-icon-wrap-size-half));

      .@{mstepsClsPrefix}-item-title {
        padding-top: 0;
      }
    }
  }

  .@{mstepsClsPrefix}-item-icon {
    width: auto;
    display: inline-block;
    vertical-align: top;
  }
}