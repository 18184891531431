/**
* 列表公共样式
*/
.@{uploadClsPrefix}-drag-dialog {
  .ui-layout-header,
  .ui-layout-footer {
    display: none;
  }
  .ui-dialog-body {
    padding: 0;
    height: 100%;
  }
  &-content {
    height: 100%;
    background: var(--bg-white);
    border: var(--hd) dashed var(--upload-border-color);
    box-shadow: 0 0 calc(9 * var(--hd)) 0 rgba(0, 0, 0, 0.2);
    border-radius: calc(6 * var(--hd));
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    // text-align:;
    &-icon {
      // color: var(--primary);
      width: calc(65 * var(--hd));
      height: calc(65 * var(--hd));
    }
    &-text {
      margin: 0;
      font-size: var(--font-size-14);
      line-height: 1.5;
      color: var(--main-fc);
    }
    &-subtext {
      margin: 0;
      font-size: var(--font-size-12);
      line-height: 1.5;
      color: var(--secondary-fc);
    }
  }
}
