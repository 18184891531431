.@{switchClsPrefix} {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
  vertical-align: middle;
  line-height: 1;

  &-loading {
    position: relative;
    top: calc(-2 * var(--hd));
  }

  &-btn {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 0;
    vertical-align: middle;
    border-radius: var(--switch-border-radius);
    border: calc(1 * var(--hd)) solid transparent;
    background-color: var(--switch-default-bc);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    height: 100%;
    width: 100%;
  }

  &-sm {
    min-width: var(--switch-with-sm);
    height: var(--switch-height-sm);
    line-height: var(--switch-height-sm);
  }

  &-md {
    min-width: var(--switch-with-md);
    height: var(--switch-height-md);
    line-height: var(--switch-height-md);
  }

  &-lg {
    min-width: var(--switch-with-lg);
    height: var(--switch-height-lg);
    line-height: var(--switch-height-lg);
  }

  &-inner {
    position: absolute;
    left: var(--switch-inner-left);
    top: 0;
    color: var(--switch-inner-color);
    font-size: var(--switch-inner-font-size);
    width: var(--switch-inner-size-lg);
    height: var(--switch-inner-size-lg);
    border-radius: 50% 50%;
    background-color: var(--switch-inner-color);
    cursor: pointer;
    transform: scale(1);
    transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    animation-duration: 0.2s;
    // animation-name: switchNoHover;

    &-sm {
      width: var(--switch-inner-size-sm);
      height: var(--switch-inner-size-sm);
    }

    &-md {
      width: var(--switch-inner-size-md);
      height: var(--switch-inner-size-md);
    }

    &-lg {
      width: var(--switch-inner-size-lg);
      height: var(--switch-inner-size-lg);
    }
  }


  &:hover {
    .@{switchClsPrefix}-inner {
      transform: scale(1);
      animation-duration: 0.2s;
      // animation-name: switchHover;
    }
  }

  &:focus {
    // box-shadow: 0 0 0 2px tint(#2db7f5, 80%);
    outline: none;
  }

  &-label {
    // display: inline-block;
    display: flex;
    align-items: center;
    color: var(--switch-inner-color);
    font-size: var(--switch-inner-font-size);
    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    user-select: text;

    &-sm {
      line-height: var(--switch-height-sm);
      margin-left: var(--switch-label-margin-inner-sm);
      margin-right: var(--switch-label-margin-sm);
    }

    &-md {
      line-height: var(--switch-height-md);
      margin-left: var(--switch-label-margin-inner-md);
      margin-right: var(--switch-label-margin-md);
    }

    &-lg {
      line-height: var(--switch-height-lg);
      margin-left: var(--switch-label-margin-inner-lg);
      margin-right: var(--switch-label-margin-lg);
    }
  }

  // 选中状态
  &-checked {
    background-color: var(--switch-checked-bc);

    .@{switchClsPrefix}-inner {
      left: var(--switch-inner-checked-left-lg);

      &-sm {
        left: var(--switch-inner-checked-left-sm);
      }

      &-md {
        left: var(--switch-inner-checked-left-md);
      }

      &-lg {
        left: var(--switch-inner-checked-left-lg);
      }
    }

    .@{switchClsPrefix}-label {
      &-sm {
        margin-right: var(--switch-label-margin-inner-sm);
        margin-left: var(--switch-label-margin-sm);
      }

      &-md {
        margin-right: var(--switch-label-margin-inner-md);
        margin-left: var(--switch-label-margin-md);
      }

      &-lg {
        margin-right: var(--switch-label-margin-inner-lg);
        margin-left: var(--switch-label-margin-lg);
      }
    }

    .@{switchClsPrefix}-handle {
      color: var(--switch-checked-bc);
    }
  }

  // 禁用状态
  &-disabled {
    cursor: not-allowed;
    opacity: 0.5;

    .@{switchClsPrefix}-inner {
      animation-name: none;
      cursor: not-allowed;
    }

    &:hover {
      .@{switchClsPrefix}-inner {
        transform: scale(1);
        animation-name: none;
      }
    }
  }

  &-btn-disabled {
    cursor: not-allowed;
  }

  // 只读状态
  &-readOnly {
    cursor: default;
    opacity: 0.5;

    .@{switchClsPrefix}-inner {
      animation-name: none;
      cursor: default;
    }

    &:hover {
      .@{switchClsPrefix}-inner {
        transform: scale(1);
        animation-name: none;
      }
    }
  }

  &-btn-readOnly {
    cursor: default;
  }

  &-handle {
    color: var(--switch-default-bc);
  }
}

// @keyframes switchHover {
//   0% {
//     transform: scale(1);
//   }
//   100% {
//     transform: scale(1);
//   }
// }

// @keyframes switchNoHover {
//   0% {
//     transform: scale(1);
//   }
//   100% {
//     transform: scale(1);
//   }
// }