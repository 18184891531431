.@{slideTabsClsPrefix} {
  display: flex;
  clear: both;
  overflow: hidden;
  position: relative;
  padding: 2px;
  border-radius: 6px;
  &-item {
    position: relative;
    z-index: 2;
    flex: 1;
    background: rgba(1, 1, 1, 0);
    float: left;
    text-align: center;
    font-size: var(--btn-font-size);
    color: #999;
    padding: 11px 52px;
    border-radius: 6px;
    cursor: pointer;
    &.disabled {
      color: var(--checkbox-disabled-font-color);
      cursor: not-allowed;
    }
  }
  &-item-active {
    color: #fff;
    background-color: #5D9CEC;
    transition: all 0.2s;
    transition-delay: 0.4s;
  }
  &-bg {
    position: relative;
    z-index: 1;
    flex: 1;
    height: calc(100% - 4px);
    opacity: 1;
    background-color: #5D9CEC;
    position: absolute;
    transition: all 0.5s;
    border-radius: 6px;
  }
  &-bg-small {
    border-radius: 3px;
  }

  &-large {
    font-size: var(--btn-lg-font-size);
    padding: 5px 29.5px;
  }

  &-small {
    font-size: var(--btn-sm-font-size);
    padding: 4.75px 14px;
    border-radius: 3px;
  }
}
.@{slideTabsClsPrefix}-inline {
  display: inline-block;
}
