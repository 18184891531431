.@{yearViewClsPrefix} {
  * {
    box-sizing: border-box;
  }
  width: 100%;
  min-width: calc(100 * var(--hd));
  display: inline-block;
  box-sizing: border-box;
  // background-color: var(--bg-white);
  color: var(--placeholder-fc);
  font-size: var(--font-size-12);
}

.@{yearViewClsPrefix}-panel{
  float: left;
  margin-left: calc(5 * var(--hd));
  width: calc(280 * var(--hd));
  font-size: var(--font-size-12);
  display: flex;
  flex-direction: column;
  outline: none;
  z-index: 999;
  background: var(--bg-white);
  // border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius-xs);
  box-shadow: var(--box-shadow);
  margin-top: calc(5 * var(--hd));
  
  &-hideCurrentMonth{
    .@{yearViewClsPrefix}-panel-dayWrap{
      .cell-hidden{
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  //头部标题
  &-nav {
    width: 100%;
    height: calc(40 * var(--hd));
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-16);
    color: var(--black-fc);
    padding: 0 calc(5 * var(--hd));
    border-bottom: 1px solid var(--border-color);
  }

  //周面板天数面板容器
  &-content {
    height: calc(230 * var(--hd));
    padding: calc(2 * var(--hd)) calc(10 * var(--hd)) calc(8 * var(--hd));
    display: flex;
    flex-direction: column;
  }

  //周面板
  &-weekWrap {
    display: flex;
    height: calc(30 * var(--hd));

    .cell {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--main-fc);
      font-weight: var(--font-weight-bold);
    }
  }

  //天数面板
  &-dayWrap {
    flex: 1;
    height: 100%;
    display: flex;
    flex-flow: row wrap;

    // .cell-hidden{
    //   opacity: 0;
    //   pointer-events: none;
    // }

    .cell {
      flex: 0 0 (1/7 * 100%);
      cursor: default;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: var(--secondary-fc);
 
      .isInSelectRange,
      .isInHoverRange {
        background-color: var(--hover-bc);
      }
  
      .left {
        height: calc(24 * var(--hd));
        line-height: calc(24 * var(--hd));
        flex: 1;
      }
  
      .right {
        height: calc(24 * var(--hd));
        line-height: calc(24 * var(--hd));
        flex: 1;
      }
  
      .middle {
        width: calc(24 * var(--hd));
        height: calc(24 * var(--hd));
        line-height: calc(24 * var(--hd));
        display: flex;
        align-items: center;
        justify-content: center;
  
        &:hover {
          cursor: pointer;
          background-color: var(--hover-bc);
        }
  
        &-isCurrentMonth {
          color: var(--main-fc);
        }
  
        &-isToday {
          border: calc(2 * var(--hd)) solid var(--primary);
          border-radius: var(--border-radius-xs);
        }
  
        &-isSelect,
        &-isFirstSelect,
        &-isSecondSelect {
          background-color: var(--primary);
          border-radius: var(--border-radius-xs);
          color: var(--base-white);
  
          &:hover {
            background-color: var(--primary);
          }
        }
      }

      .isBeforeFirstSelectDate{
        color: var(--secondary-fc);
        pointer-events: none;
      }
  
      .isDisabled {
        cursor: not-allowed;
        color: var(--secondary-fc);
        background-color: var(--label-bc);
  
        &:hover {
          cursor: not-allowed;
          background-color: var(--label-bc);
        }
      }
    }
  }
}
.mirror-text-needLTR {
  direction: ltr!important;
  transform: scaleX(-1);
}
