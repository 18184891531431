.@{formSwitchClsPrefix} {
  display: inline-block;
  max-width: 100%;
  /* ----------------------------------------- 表单大小屏宽度样式调整(标准) -------------------------------------------- */
  .@{inputClsPrefix}-wrap {
    min-width: calc(100 * var(--hd));
    max-width: calc(250 * var(--hd));
  }
  .@{inputClsPrefix}-number {
    width: calc(100 * var(--hd));
  }
  .@{inputClsPrefix}-group {
    width: calc(180 * var(--hd));
  }
  
  .@{browserClsPrefix}-associative-search {
    &.hide-input {
      .@{browserClsPrefix}-associative-search-input {
        min-width: auto;
        max-width: auto;
      }
    }
  }
  &.disabledWidthLimit {
    .@{inputClsPrefix}-wrap {
      min-width: unset;
      max-width: unset;
    }
    .@{inputClsPrefix}-number {
      width: unset;
    }
    .@{inputClsPrefix}-group {
      width: unset;
    }
  
    .@{browserClsPrefix}-associative-search {
      &.hide-input {
        .@{browserClsPrefix}-associative-search-input {
          min-width: unset;
          max-width: unset;
        }
      }
    }
  }

  /* ----------------------------------------- 表单只读模式样式调整(标准) -------------------------------------------- */
  .@{inputClsPrefix}.is-readonly,
  .@{textareaClsPrefix}.is-readonly,
  .@{inputClsPrefix}-wrap.is-readonly,
  .@{scopeClsPrefix} .@{scopeClsPrefix}-input {
    padding-left: 0;
  }
  .@{dateQuickFilterClsPrefix}-readOnly {
    .dateLabel {
      padding-left: 0;
    }
  }
  .@{richTextClsPrefix}-readOnly {
    line-height: var(--form-item-line-height);
    word-break: break-word;
  }
  .@{browserClsPrefix} {
    vertical-align: middle;
  }
  .@{selectClsPrefix}-readonly {
    line-height: var(--form-item-line-height);
    vertical-align: middle;
    font-size: var(--font-size-12);
  }
  .@{inputClsPrefix}-number.is-readonly {
    font-size: var(--font-size-12);
  }
  &-mobile-readOnly .@{mSelectClsPrefix} .@{mListClsPrefix}-line {
    height: 100%;
  }
  /* 修复鸿蒙系统，加密数据未靠右对齐样式问题 */
  &-mobile-readOnly.huawei .@{encryClsPrefix} {
    width: 100%;
  }
  .@{formSwitchClsPrefix}-passport-readOnly{
    color: var(--main-fc);
  }
  /* ----------------------------------------- 校验样式区(校验模式) -------------------------------------------- */
  &-error {
    position: relative;
    display: inline-block;
    &-top {
      .@{formSwitchClsPrefix}-showError {
        top: calc(-43 * var(--hd));
      }
      .ui-formSwitch-arrow {
        top: unset;
        border-bottom: unset;
        border-top: var(--popover-arrow-width) solid
          var(--form-item-error-background);
        bottom: calc(
          calc(-1 * var(--popover-arrow-width)) + calc(1 * var(--hd))
        );
      }
      .ui-formSwitch-arrow-before {
        top: unset;
        border-bottom: unset;
        bottom: calc(-1 * var(--popover-arrow-width));
        border-top: var(--popover-arrow-width) solid var(--form-item-required);
      }
    }
    &-mobile-content {
      color: var(--form-item-required);
      font-size: var(--font-size-12);
      padding-bottom: var(--v-spacing-sm);
    }
    &-popupContainer {
      .@{popoverClsPrefix}-content.border {
        border: 1px solid var(--form-item-required);
        background: var(--form-item-error-background);
        border-radius: var(--border-radius-xs);
        font-size: var(--font-size-12);
      }
      .@{popoverClsPrefix}-arrow {
        &-bottomLeft,
        &-bottom,
        &-bottomRight {
          border-bottom-color: var(--form-item-error-background);
        }
        &-topLeft,
        &-top,
        &-topRight {
          border-top-color: var(--form-item-error-background);
        }
      }
      .@{popoverClsPrefix}-arrow-border {
        &-bottomLeft,
        &-bottom,
        &-bottomRight {
          border-bottom-color: var(--form-item-required);
        }
        &-topLeft,
        &-top,
        &-topRight {
          border-top-color: var(--form-item-required);
        }
      }
    }
    &-bottomRight {
      .@{formSwitchClsPrefix}-showError {
        right: calc(-9 * var(--hd));
        left: auto;
      }
      .@{formSwitchClsPrefix}-arrow, .@{formSwitchClsPrefix}-arrow-before {
        right: calc(10 * var(--hd));
        left: auto;
      }
    }
    &-right {
      .@{formSwitchClsPrefix}-showError {
        left: 100%;
        top: 0;
      }
      .@{formSwitchClsPrefix}-wrap {
        display: flex;
        align-items: center;
      }
      .@{formSwitchClsPrefix}-arrow-before {
        position: relative;
        border-top: var(--popover-arrow-width) solid transparent;
        border-bottom: var(--popover-arrow-width) solid transparent;
        border-left: var(--popover-arrow-width) solid transparent;
        border-right: var(--popover-arrow-width) solid var(--form-item-required);
        margin-left: 0;
        top: unset;
      }
      .@{formSwitchClsPrefix}-arrow {
        border-top: var(--popover-arrow-width) solid transparent;
        border-bottom: var(--popover-arrow-width) solid transparent;
        border-left: var(--popover-arrow-width) solid transparent;
        border-right: var(--popover-arrow-width) solid var(--form-item-error-background);
        margin-left: 0;
        top: unset;
        left: calc(1 * var(--hd));
      }
      .@{formSwitchClsPrefix}-content {
        margin-top: 0;
      }
    }
    .error-tip-newValidateTipType {
      color: var(--form-item-required-new);
      font-size: var(--font-size-12);
      padding-top: calc(3 * var(--hd));
    }
    &.show-error-new {
      .required-border;
      &.ui-formSwitch-RADIO-PC,
      &.ui-formSwitch-CHECKBOX-PC {
        display: inline-block;
      }
    }
  }
  &-showError {
    z-index: var(--form-item-zIndex);
    position: absolute;
  }
  &-RADIO-PC, &-CHECKBOX-PC {
    .@{formSwitchClsPrefix}-showError {
      top: calc(16 * var(--hd));
    }
  }
  &-hideError {
    display: none;
  }
  &-wrap {
    position: relative;
  }
  &-arrow-before {
    border-left: var(--popover-arrow-width) solid transparent;
    border-right: var(--popover-arrow-width) solid transparent;
    border-bottom: var(--popover-arrow-width) solid var(--form-item-required);
    width: 0;
    height: 0;
    margin-left: var(--h-spacing-md);
    position: absolute;
    top: calc(-1 * var(--popover-arrow-width));
  }
  &-arrow {
    border-left: var(--popover-arrow-width) solid transparent;
    border-right: var(--popover-arrow-width) solid transparent;
    border-bottom: var(--popover-arrow-width) solid
      var(--form-item-error-background);
    width: 0;
    height: 0;
    margin-left: var(--h-spacing-md);
    position: absolute;
    top: calc(calc(-1 * var(--popover-arrow-width)) + calc(1 * var(--hd)));
  }
  /* 必填图标样式 */
  &-requiredMark {
    &-container {
      display: inline-flex;
      align-items: center;
      width: 100%;
    }
    padding-left: calc(4 * var(--hd));
    &.needRTL {
      padding-left: 0;
      padding-right: calc(4 * var(--hd));
    }
  }
  /* 移动端、字段后必填图标样式 */
  &-requiredMark-container-mobile {
    position: relative;
    align-items: unset;
    .@{mListClsPrefix}-item, .@{mSelectClsPrefix}, .@{mBrowserClsPrefix} {
      width: 100%;
    }
    .@{mBrowserClsPrefix}-associative-selected .@{mListClsPrefix}-column .@{mListClsPrefix}-body .@{mListClsPrefix}-item {
      // width: auto;
      width: 100%;
    }
    .@{formItemClsPrefix}-requiredmark {
      position: absolute;
      right: calc(-13 * var(--hd));
      line-height: calc(44 * var(--hd));
      height: calc(44 * var(--hd));
      &.ui-formItem-requiredmark.ebcoms-assets-icon.s {
        height: calc(44 * var(--hd));
      }
    }
    .@{mSwitchClsPrefix} + span.@{formItemClsPrefix}-requiredmark {
      top: calc(-12 * var(--hd));
    }
    .@{sliderClsPrefix} + span.@{formItemClsPrefix}-requiredmark {
      top: calc(-12 * var(--hd));
    }
  }
  /* Switch 样式单独兼容 */
  .@{formSwitchClsPrefix}-SWITCH .@{formSwitchClsPrefix}-requiredMark-container-mobile {
    align-items: center;
  }
  /* DATETIMEPICKER-MB 样式单独兼容*/
  &-DATETIMEPICKER-MB {
    .@{formSwitchClsPrefix}-requiredMark-container-mobile{
      justify-content: end;
    } 
  }
  /* CUSTOM-MB样式单独兼容*/
  &-CUSTOM-MB {
    .@{formSwitchClsPrefix}-requiredMark-container-mobile{
      align-items: center;
    } 
  }
  /* INPUTNUMBER-MB 样式单独兼容 */
  &-INPUTNUMBER-MB {
    .@{formSwitchClsPrefix}-requiredMark-container-mobile{
      height: 100%;
      justify-content: end;
      align-items: center;
    } 
  }
  /* ----------------------------------------- 内容区(校验模式) -------------------------------------------- */
  &-content {
    padding: var(--v-spacing-sm) var(--h-spacing-sm);
    background: var(--form-item-error-background);
    border: var(--form-item-error-border);
    border-radius: var(--border-radius-xs);
    margin-top: var(--popover-arrow-width);
    line-height: normal;
    font-size: var(--font-size-12);
    white-space: nowrap;
  }
  /* ----------------------------------------- help提示 -------------------------------------------- */
  &-help {
    display: inline-flex;
    padding-left: var(--h-spacing-sm);
    align-items: center;
    align-self: flex-start;
    height: var(--form-item-line-height);
    &-UPLOAD,
    &-RICHTEXT {
      align-self: flex-start;
    }
    &.is-notCn{
    align-self: center; 
    }
  }
  /* ----------------------------------------- 表单样式(hoverEdit模式) -------------------------------------------- */
  &-readOnly,
  &-not-empty {
    border: 0;
    box-shadow: unset;
    padding: 0;
    display: inline-block;
    word-wrap: break-word;
    word-break: break-word;
    .@{inputClsPrefix}:not(.is-focus),
    .@{inputClsPrefix}-wrap:not(.is-focus) {
      border-color: transparent;
      background-color: var(--bg-transparent);
      .@{inputClsPrefix}-suffix {
        visibility: hidden;
      }
    }
    .@{selectClsPrefix}-input:not(.is-focus) {
      background-color: var(--bg-transparent);
      border-color: transparent;
      .@{inputClsPrefix}-suffix {
        visibility: hidden;
      }
    }
    .@{inputClsPrefix}-number:not(.is-focus) {
      .@{inputClsPrefix}-prepend,
      .@{inputClsPrefix}-append {
        display: none;
      }
      .@{inputClsPrefix} {
        text-align: left;
      }
    }
    .@{dateTimePickerClsPrefix} {
      background-color: var(--bg-transparent);
      &-rangeWrap {
        background-color: var(--bg-transparent);
        border-color: transparent;
        &-suffix {
          visibility: hidden;
        }
      }
      .@{inputClsPrefix}-suffix {
        visibility: hidden;
      }
    }
    .@{dateTimePickerClsPrefix}-panel-open .@{inputClsPrefix}-suffix{
      visibility: visible;
    }
    .@{datePickerClsPrefix}-wrap-suffix {
      visibility: hidden;
    }
    .@{datePickerClsPrefix}:not(.@{datePickerClsPrefix}-panel-open),
    .@{timePickerClsPrefix}:not(.@{timePickerClsPrefix}-panel-open) {
      .@{inputClsPrefix}-suffix {
        visibility: hidden;
      }
    }
    .@{datePickerClsPrefix}-rangeMaxWidth:not(.@{datePickerClsPrefix}-panel-open) {
      .@{datePickerClsPrefix}-rangeWrap {
        border-color: transparent;
        &-suffix {
          visibility: hidden;
        }
      }
    }
    .@{dateQuickFilterClsPrefix}:not(.@{dateQuickFilterClsPrefix}-panel-open) {
      .button-left,
      .button-right {
        display: none;
      }
      &-wrap .isPlaceholder {
        text-align: left;
        font-size: var(--font-size-12);
        padding-left: 0;
      }
    }
    .@{scopeClsPrefix} {
      input {
        border-color: transparent;
      }
    }
    .@{browserClsPrefix} {
      .@{browserClsPrefix}-associative-search:not(.is-focus) {
        &.hide-input .@{browserClsPrefix}-associative-search-input:not(.is-focus) {
          display: none;
        }
        .@{inputClsPrefix}-wrap {
          border-color: transparent;
        }
        .@{inputClsPrefix}-suffix {
          visibility: hidden;
        }
      }
    }
    // .@{browserClsPrefix}-types .@{browserClsPrefix}-types-associative-inner:not(.is-focused) .@{inputClsPrefix}-wrap:not(.is-focus) .@{selectClsPrefix}-input-selected,
    // .@{browserClsPrefix}-share .@{browserClsPrefix}-share-associative-inner:not(.is-focused) .@{inputClsPrefix}-wrap:not(.is-focus) .@{selectClsPrefix}-input-selected {
    //   visibility: hidden;
    // }
    // .@{browserClsPrefix}-types .@{browserClsPrefix}-types-associative-inner.is-focused .@{inputClsPrefix}-suffix,
    // .@{browserClsPrefix}-share .@{browserClsPrefix}-share-associative-inner.is-focused .@{inputClsPrefix}-suffix {
    //   visibility: unset;
    // }
    .@{cascaderClsPrefix}-picker:not(.@{cascaderClsPrefix}-picker-focused) {
      .@{cascaderClsPrefix}-picker-arrow {
        visibility: hidden;
      }
    }
    .@{cascaderClsPrefix}-picker.@{cascaderClsPrefix}-picker-focused {
      .@{cascaderClsPrefix}-input {
        border: var(--border-solid);
      }
    }
    /* ------------------------- 边距特殊处理 ----------------------------- */
    .@{browserClsPrefix}.is-single .@{inputClsPrefix}-wrap:not(.is-focus),
    .@{inputClsPrefix}:not(.is-focus), .@{inputClsPrefix}-wrap:not(.is-focus),
    .@{cascaderClsPrefix}-picker:not(.@{cascaderClsPrefix}-picker-focused) .@{cascaderClsPrefix}-picker-label
    {
      padding-left: 0;
      transition: var(--dialog-scale-transition);
    }
    .@{dateTimePickerClsPrefix}-rangeWrap .@{inputClsPrefix}{
      padding-left: var(--input-padding-x);
      transition: unset;
    }
    .@{datePickerClsPrefix}-rangeMaxWidth .@{inputClsPrefix}.@{datePickerClsPrefix}-rangeWrap-input {
      padding-left: var(--input-padding-x);
    }
    .@{cascaderClsPrefix}-picker.@{cascaderClsPrefix}-picker-focused {
      .@{cascaderClsPrefix}-input {
        padding-left: var(--input-padding-x);
      }
    }
  }
  &-readOnly {
    .@{dateTimePickerClsPrefix}:not(.@{dateTimePickerClsPrefix}-panel-open) {
      &-rangeWrap {
        & > .@{inputClsPrefix} {
          display: none;
        }
        & > .@{inputClsPrefix}:first-child {
          display: inline-block;
        }
        &-middle {
          display: none;
        }
        &-suffix {
          display: none;
        }
        &-input {
          text-align: left;
        }
      }
      &-wrap {
        .@{inputClsPrefix}-suffix {
          display: none;
        }
      }
    }
    .@{scopeClsPrefix} {
      &-line {
        display: none;
      }
      &-controls {
        .@{inputClsPrefix} {
          text-align: left;
          display: none;
        }
        .@{inputClsPrefix}:first-child {
          display: inline-block;
          padding-left: var(--input-padding-x);
        }
        .@{inputClsPrefix}:last-child {
          display: inline-block;
          padding-left: var(--input-padding-x);
        }
      }
    }
    .@{browserClsPrefix}.is-multiple {
      .@{browserClsPrefix}-associative-search:not(.is-focus) {
        .@{inputClsPrefix}-suffix {
          visibility: hidden;
        }
      }
    }
    .@{browserClsPrefix}-types .@{browserClsPrefix}-types-associative-inner-add,
    .@{browserClsPrefix}-share .@{browserClsPrefix}-share-associative-inner-add {
      border-color: transparent;
      .@{iconClsPrefix} {
        visibility: hidden;
      }
    }
    .@{inputClsPrefix}-number:not(.is-focus) {
      border-color: transparent;
    }
    .@{monthDayPickerClsPrefix}-icon {
      visibility: hidden;
    }
  }
  &-not-empty {
    color: var(--main-fc);
    .@{inputClsPrefix} {
      color: var(--main-fc);
    }
    .@{dateQuickFilterClsPrefix}:not(.@{dateQuickFilterClsPrefix}-panel-open) {
      .dateLabel {
        color: var(--main-fc);
        font-size: var(--font-size-12);
        text-align: left;
      }
    }
    .@{browserClsPrefix} {
      .@{inputClsPrefix}-wrap {
        border-color: var(--border-color);
        padding-left: var(--input-padding-x);
      }
      .@{inputClsPrefix}-suffix {
        display: flex;
      }
     
      &-types  .@{browserClsPrefix}-associative-search:not(.is-focus) .@{inputClsPrefix}-wrap {
        border-color: transparent;
      }
    }
    // /* 多选浏览框 编辑模式边框样式特殊处理 */
    // .@{browserClsPrefix}.is-multiple .@{browserClsPrefix}-associative:not(.is-empty).show-border{
    //   border-color: transparent;
    //   padding: 0;
    //   .@{browserClsPrefix}-associative-search.display-input {
    //     display: none;
    //   }
    // }
    // .@{browserClsPrefix}-types.is-multiple .@{browserClsPrefix}-associative-selected-wrap:not(.is-empty).show-border:not(.is-focused),
    // .@{browserClsPrefix}-share.is-multiple .@{browserClsPrefix}-associative-selected-wrap:not(.is-empty).show-border:not(.is-focused) {
    //   border-color: transparent;
    //   padding: 0;
    //   .@{browserClsPrefix}-associative-search.display-input {
    //     display: none;
    //   }
    // }
    // .@{browserClsPrefix}-types-associative-inner.display-input:not(.is-focused),
    // .@{browserClsPrefix}-share-associative-inner.display-input:not(.is-focused) {
    //   border-color: transparent;
    //   display: none;
    //   &:hover {
    //     border-color: transparent;
    //   }
    // }
    // .@{browserClsPrefix}-types.box-selection.disabled-hidemore.is-multiple:not(.is-empty) .@{browserClsPrefix}-associative-selected-wrap:hover .@{browserClsPrefix}-types-associative-inner,
    // .@{browserClsPrefix}-share.box-selection.disabled-hidemore.is-multiple:not(.is-empty) .@{browserClsPrefix}-associative-selected-wrap:hover .@{browserClsPrefix}-share-associative-inner {
    //     border-color: transparent;
    //     &:hover.display-input {
    //       border-color: transparent;
    //     }
    //     .@{browserClsPrefix}-types-select .@{inputClsPrefix}-wrap,
    //     .@{browserClsPrefix}-share-select .@{inputClsPrefix}-wrap {
    //       border-color: transparent;
    //     }
    // }
  }
  &-hoverEdit {
    color: var(--main-fc);
    vertical-align: middle;
    /* ------------------------- 边距特殊处理 ----------------------------- */
    .@{browserClsPrefix}.is-single .@{inputClsPrefix}-wrap:not(.is-focus),
    .@{inputClsPrefix}:not(.is-focus), .@{inputClsPrefix}-wrap:not(.is-focus)
    .@{cascaderClsPrefix}-picker:not(.@{cascaderClsPrefix}-picker-focused) .@{cascaderClsPrefix}-picker-label
    {
      transition: var(--dialog-scale-transition);
    }
    .@{scopeClsPrefix} .@{inputClsPrefix}, .@{dateTimePickerClsPrefix}-rangeWrap .@{inputClsPrefix}{
      transition: unset;
    }
    // .@{browserClsPrefix}.box-selection.is-multiple:not(.is-empty) .@{browserClsPrefix}-associative-search-input {
    //   border-color: var(--border-color);
    // }

    // .@{browserClsPrefix}.box-selection.is-multiple:not(.is-empty) .@{browserClsPrefix}-associative-search.is-focus .@{browserClsPrefix}-associative-search-input {
    //   border-color: var(--input-focus-border-color);
    // }
    
    // .@{browserClsPrefix}-types.box-selection.is-multiple:not(.is-empty) .@{browserClsPrefix}-associative-search-input {
    //   border-color: transparent;
    // }
    // .@{browserClsPrefix}-types.box-selection.is-multiple:not(.is-empty) .@{browserClsPrefix}-types-associative-inner.display-input:not(is-focused) {
    //   border-color: var(--border-color);
    // }
    // .@{browserClsPrefix}-types.box-selection.is-multiple:not(.is-empty) .@{browserClsPrefix}-types-associative-inner.display-input.is-focused {
    //   border-color: var(--input-focus-border-color);
    // }
    // .@{browserClsPrefix}-types.box-selection.is-multiple:not(.is-empty) .@{browserClsPrefix}-associative-search.is-focus .@{browserClsPrefix}-associative-search-input {
    //   border-color: transparent;
    // }
    // .@{browserClsPrefix}-types.box-selection.is-multiple .@{browserClsPrefix}-associative-selected-wrap.is-focused .@{inputClsPrefix}-wrap {
    //   padding-left: var(--input-padding-x);
    // }
  }
  /* ----------------------------------------- 表单样式(hoverEdit模式 + 镜像) -------------------------------------------- */
  &-readOnly.needRTL,
  &-not-empty.needRTL {
    .@{inputClsPrefix}:not(.is-focus) {
      padding-right: 0;
    }
    .@{inputClsPrefix}-number:not(.is-focus) {
      .@{inputClsPrefix} {
        text-align: right;
      }
    }
    .@{inputClsPrefix}-number.hide-ops {
      text-align: right;
      padding-right: 0;
      padding-left: var(--input-padding-x);
    }
    .@{dateQuickFilterClsPrefix}:not(.@{dateQuickFilterClsPrefix}-panel-open) {
      &-wrap .isPlaceholder {
        text-align: right;
      }
    }
    .@{cascaderClsPrefix}-picker:not(.@{cascaderClsPrefix}-picker-focused) {
      .@{cascaderClsPrefix}-input {
        padding-right: 0;
      }
    }
  }
  &-hoverEdit {
    &.needRTL .@{inputClsPrefix}-number.hide-ops {
      text-align: right;
      padding-left: 0;
    }
  }
  /* -----移动端只读特殊样式处理------- */
  &-mobile-readOnly.@{formSwitchClsPrefix}-INPUTNUMBER {
    display: table;
    height: 100%;
    .@{mInputClsPrefix}-number.is-readonly {
      display: table-cell;
      vertical-align: middle;
    }
  }
  &-mobile-readOnly.@{formSwitchClsPrefix}-RICHTEXT {
    display: table;
    height: 100%;
    .@{richTextClsPrefix}-readOnly {
      display: table-cell;
      vertical-align: middle;
      margin: 0;
    }
  }
  /* -----移动端加密字段特殊样式处理------- */
  &-mobile-readOnly.@{formSwitchClsPrefix}-encry .@{mListClsPrefix}-line {
    height: 100%;
    align-items: center;
  }
  /* -----PC端特殊样式处理------- */
  &-RADIO-PC,
  &-CHECKBOX-PC {
    display: inline-flex;
  }
}

/*
  配合多选浏览框处样式调整
*/
.@{formSwitchClsPrefix} {
  &-INPUT, &-PASSWORD, &-INPUTNUMBER, &-TEXTAREA, &-LOCALE,
  &-TIMEPICKER, &-DATEPICKER, &-DATETIMEPICKER, &-DATEQUICKFILTER,
  &-UPLOAD, &-COLORPICKER, &-RICHTEXT, &-SIGNATURE, &-ICONSELECTION,
  &-MONTHDAYPICKER, &-CASCADER, &-SHAREBROWSER, &-RICHICONSELECTION,
   &-BROWSER, &-SELECT,  &-TYPEBROWSER {
    &-PC {
      align-self: start;
    }
    &-passport{
      align-self: unset;
      align-items: center;
    }
  }
  &-INPUTNUMBER-PC{
    align-self: unset;
    align-items: center;
  }
}

/*
  最大宽度调整
*/
.@{formSwitchClsPrefix}.wrapMaxWidth {
  .@{browserClsPrefix}.box-selection .@{browserClsPrefix}-associative-search.display-selected-in-input .@{browserClsPrefix}-associative-search-input {
    max-width: unset;
  }
}

@media (max-width: 1280px) {
  .@{formSwitchClsPrefix} {
    .@{inputClsPrefix}-number {
      width: calc(50 * var(--hd));
    }
    .@{inputClsPrefix}-group {
      width: calc(130 * var(--hd));
    }
    &.disabledWidthLimit {
      .@{inputClsPrefix}-number {
        width: unset;
      }
      .@{inputClsPrefix}-group {
        width: unset;
      }
    }
  }
}

/* 镜像 */
.@{formSwitchClsPrefix}-arrow.needRTL {
  z-index: 1;
}
.@{formSwitchClsPrefix}.needRTL {
  textarea.@{textareaClsPrefix} {
    text-align: right;
  }
  .@{scopeClsPrefix} .@{scopeClsPrefix}-input.is-readonly {
    justify-content: center;
  }
}
/* 中大字体下浏览按钮高度调整 */
.@{formSwitchClsPrefix}-BROWSER-PC {
  .@{browserClsPrefix}-associative-search.display-selected-in-input {
    .@{browserClsPrefix}-associative-selected-item {
      padding: 0;
    }
    &:not(.adaptive-input-height) .@{browserClsPrefix}-associative-selected-list {
      max-height: calc(28 * var(--hd));
    }
  }
}