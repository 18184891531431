.@{FieldTemplateSelectClsPrefix}{
  border: var(--hd) solid var(--transfer-border-color);
  background:var(--base-white);
  width: calc(200 * var(--hd));
  height: 100%;
  &-search{
    padding: calc(5 * var(--hd));

    &-icon{
      color: var(--secondary-fc);
    }
  }

  &-content{
    height: calc(100%  - 40 * var(--hd));
   .@{listClsPrefix}-scrollview-wrap {
    height:100% !important;
  }
   &-left{
     height: 100%;
   }
   &-list{
     height: 100%;
     .@{listClsPrefix}-item{
      height: calc(30 * var(--hd));
      align-items: center;
      cursor: pointer;
      &:hover{
        background: var(--transfer-hover-color);
      }
     }
     .@{listClsPrefix}-content{
      line-height: calc(40 * var(--hd));
     }
     &-item{
       width: 100%;
       height: 100%;
       overflow: hidden;
       text-overflow: ellipsis;
       padding-left:calc(10 * var(--hd));
     }
   }
  }
}

// 镜像样式
.@{FieldTemplateSelectClsPrefix}-rtl {
  .@{listClsPrefix}-content{
    text-align: unset;
  }

  .@{FieldTemplateSelectClsPrefix}-content-list-item{
    padding: 0 calc(20 * var(--hd)) 0 0;
  }
}