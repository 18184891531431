.@{formItemClsPrefix} {
  padding: var(--form-item-v-spacing) 0;
  /* ----------------------------------------- 表单只读字段图标样式(移动端) -------------------------------------------- */
  &-label {
    position: relative;
    &-readOnly {
      .@{formItemClsPrefix}-label-col-readOnlyIcon {
        padding-left: var(--h-spacing-xs);
        display: inline-flex;
        align-items: center;
        height: calc(18 * var(--hd));
      }
    }
    .@{helpClsPrefix} .@{helpClsPrefix}-explain .@{iconClsPrefix} {
      margin-top: calc(-2 * var(--hd));
    }
  }
  /* ----------------------------------------- passport 样式调整(标准) -------------------------------------------- */
  .@{formSwitchClsPrefix}-passport {
    display: inline-flex;
    align-items: center;
    &-mobile {
      min-height: var(--m-form-item-line-height);
    }
  }
  /* ----------------------------------------- 表单标准模板(label+wrapper) -------------------------------------------- */
  &-label {
    // display: inline-block;
    display: inline-flex;
    vertical-align: middle;
    color: var(--main-fc);
    font-size: var(--font-size-12);
    width: 100%;
    line-height: var(--form-item-line-height);
    height: var(--form-item-line-height);
    &-span {
      max-width: calc(100% - var(--h-spacing-xs));
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
      &-delete {
        text-decoration: line-through;
      }
    }
    &-required {
      position: relative;
      .@{formItemClsPrefix}-label-span {
        max-width: calc(100% - calc(10 * var(--hd)) - var(--h-spacing-xs));
      }
      &-icon.@{iconClsPrefix}-wrapper {
        display: flex;
        align-items: center;
        padding-left: var(--h-spacing-xs);
      }
      /* -------   PC端必填标记(ebuilderIcon)--------------------------- */
      .ebcoms-assets-item:not(.ui-formItem-requiredmark-mobile) {
        height: 100%;
      }
    }
    /* pc Form 非中文模式 label改成折行 */
    &-noCn-PC {
      height: auto;
    }
    &-noCn-PC .@{formItemClsPrefix}-label-span {
      text-overflow: unset;
      word-break: break-word;
      white-space: unset;
    }
    &-noCn-PC .@{formItemClsPrefix}-requiredmark.@{formItemClsPrefix}-label-required-icon {
      height: var(--form-item-line-height);
    }
    .@{helpClsPrefix} {
      padding-left: var(--h-spacing-xs);
    }
    /* ----------------------------------------- 表单标准模板(自定义分组，高级搜索其他条件)-------------------------------------------- */
    &-custom {
      .@{selectClsPrefix} {
        width: calc(100% - calc(15 * var(--hd)));
        vertical-align: top;
        &-input {
          width: 100%;
          max-width: auto;
          min-width: auto;
          & > span:first-child {
            width: calc(100% - var(--icon-size-xs));
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            // text-align: center;
          }
        }
      }
      &-delete {
        .@{selectClsPrefix}-input-selected,
        .@{selectClsPrefix}-search-input,
        .@{mListClsPrefix}-item .@{mInputClsPrefix}-control input {
          text-decoration: line-through;
        }
      }
    }
    /* ----------------------------------------- 标签固定宽度 -------------------------------------------- */
    &-fixedWidth {
      width: calc(100 * var(--hd));
      &-allLine {
        width: 100%;
      }
    }
    &-col-changerow {
      margin-bottom: calc(6 * var(--hd));
    }
  }
  &-wrapper {
    position: relative;
    height: 100%;
    min-height: var(--form-item-line-height);
    vertical-align: middle;
    padding-right: var(--h-spacing-lg);

    // wrapper整体布局
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    /* ----------------------------------------- 表单标准模板(垂直居中对齐，部分组件微调)-------------------------------------------- */
    .@{uploadClsPrefix}-select-image {
      margin-left: 0;
    }
    /* ----------------------------------------- 标签固定宽度 -------------------------------------------- */
    &-fixedWidth {
      width: calc(100% - calc(100 * var(--hd)));
    }

    &-pos-left{
      justify-content: flex-start;
    }
    &-pos-center{
      justify-content: center;
    }
    &-pos-right{
      justify-content: right;
    }

    .@{formSwitchClsPrefix}.isIE {
      flex-shrink: 0;
      .@{browserClsPrefix}.is-multiple {
        flex-shrink: 0;
      }
    }
  }
  /* ----------------------------------------- 一行仅一个字段样式 -------------------------------------------- */
  &-line {
    width: 100%;
    .@{datePickerClsPrefix}-pickerWrapper {
      width: 100%;
    }
    .@{timePickerClsPrefix}-timeBox {
      width: 100%;
    }
    .@{richTextClsPrefix} {
      .ck-content {
        min-height: calc(150 * var(--hd));
      }
    }
    // &:not(.ui-formSwitch-hoverEdit) .@{browserClsPrefix}.box-selection {
    //   width: 100%;
    // }
  }
  &-wrapPos{
    // 如果开启右侧字段自定义位置，覆盖&-line的默认 100% 宽度
    width: auto;
  }
  /* ----------------------------------------- 一行多个字段间距样式 -------------------------------------------- */
  &-singleItem {
    display: inline-block;
    margin-right: var(--h-spacing-sm);
    max-width: 100%;
    &-last {
      margin-right: 0;
    }
    &-empty {
      margin-right: 0;
    }
    &:empty {
      margin-right: 0;
    }
    &.isIE:not(.isReadOnly) {
      &.@{formSwitchClsPrefix}-DATEPICKER-PC {
        line-height: 0;
      }
    }
  }
  /* ----------------------------------------- 带help提示信息，一行仅一个字段的样式调整 -------------------------------------------- */
  &-line-helpTip {
    display: inline-block;
    vertical-align: top;
    width: auto;
    vertical-align: middle;
    &-textarea {
      width: calc(
        100% - var(--help-icon-size-default) - var(--help-icon-margin-default) -
          var(--help-icon-margin-default)
      );
      .@{formSwitchClsPrefix}-help {
        vertical-align: top;
      }
    }
    &-slider {
      width: calc(100% - var(--help-icon-size-default) - var(--h-spacing-lg));
    }
  }
  &-line.@{formItemClsPrefix}-line-helpTip.@{formSwitchClsPrefix}-BROWSER-PC,
  &-line.@{formItemClsPrefix}-line-helpTip.@{formSwitchClsPrefix}-TYPESBROWSER-PC {
    max-width: calc(100% - var(--icon-size-s) - var(--h-spacing-sm));
  }
  /* ----------------------------------------- 自定义分组删除按钮样式 -------------------------------------------- */
  &-delete {
    position: absolute;
    top: calc(3 * var(--hd));
    right: calc(-30 * var(--hd));
    border: calc(1 * var(--hd)) solid var(--form-item-icon-color);
    border-radius: 50%;
    cursor: pointer;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--form-item-delete-icon-size);
    height: var(--form-item-delete-icon-size);
    .@{iconClsPrefix} {
      color: var(--searchAdvanced-form-item-icon-color);
    }
  }
  /* ----------------------------------------- 表单标准模板样式(非高级搜索内部) -------------------------------------------- */
  &-module {
    padding: var(--form-item-padding-module);
    .@{formItemClsPrefix}-label {
      color: var(--regular-fc);
      font-size: var(--font-size-12);
    }
    .@{formItemClsPrefix}-wrapper {
      padding: 0;
    }
  }
  /* ----------------------------------------- 必填样式 -------------------------------------------- */
  &-requiredmark {
    .Icon-Required {
      width: var(--form-item-required-icon-size);
      height: var(--form-item-required-icon-size);
    }
    &-img {
      max-width: calc(30 * var(--hd));
      max-height: calc(30 * var(--hd));
    }
    .@{mListClsPrefix}-item,
    .@{mSelectClsPrefix} {
      width: 100%;
    }
  }
  //ebuilder图标
  &-requiredmark.ebcoms-assets-icon.s {
    width: var(--icon-size-s);
    .@{iconClsPrefix}-svg {
      width: var(--form-item-required-icon-size);
    }
  }
  /* ----------------------------------------- 表单(移动端) -------------------------------------------- */
  &-mobile {
    padding: 0;
    .@{formItemClsPrefix}-singleItem {
      display: block;
      margin-right: 0;
    }
    .@{formItemClsPrefix}-singleItem.@{formSwitchClsPrefix}-mobile-readOnly {
      height: auto;
    }
    .@{formItemClsPrefix}-label {
      font-size: var(--font-size-md);
      background-color: var(--bg-base);
      color: var(--regular-fc);
      width: 100%;
      font-weight: var(--font-weight-base);
      overflow: unset;
      white-space: unset;
      text-overflow: unset;
      word-break: break-word;
      line-height: unset;
      padding: calc(13 * var(--hd)) 0;
      height: 100%;
      &-noWholeLine {
        background-color: var(--bg-white);
        padding-left: var(--h-spacing-lg);
        .ui-formItem-requiredmark.ebcoms-assets-icon.s {
          height: calc(20 * var(--hd));
        }
      }
      &-wholeLine {
        line-height: unset;
        // padding-top: var(--v-spacing-xl);
        padding-top: calc(22 * var(--hd));
        padding-bottom: var(--v-spacing-xs);
        padding-left: var(--h-spacing-lg);
        padding-right: var(--h-spacing-md);
        display: inline-flex;
        align-items: center;
      }
      &-noCn {
        padding-left: var(--h-spacing-lg);
        background: transparent;
      }
      &-span {
        vertical-align: middle;
        overflow: unset;
        white-space: unset;
        text-overflow: unset;
        word-break: break-word;
      }
      &-required:after {
        position: relative;
        padding-left: calc(4 * var(--hd));
        display: inline;
      }
      &-required-icon.ui-icon-wrapper {
        display: inline-flex;
        align-items: center;
        height: calc(18 * var(--hd));
      }
      &-wholeLine {
        .@{formItemClsPrefix}-label-required-icon.ui-icon-wrapper {
          padding-top: 0;
          height: calc(16 * var(--hd));
        }
        .@{formItemClsPrefix}-requiredmark.ebcoms-assets-icon.s {
          height: calc(16 * var(--hd));
        }
      }
      /* -------   移动端字段去掉边框(label内仅使用MSelect，处理边框+间距) -------- */
      .@{mListClsPrefix}-item.@{mInputClsPrefix}-item {
        padding-left: 0;
        &::after {
          display: none;
        }
      }
    }
    .@{formItemClsPrefix}-wrapper {
      background-color: var(--bg-white);
      min-height: var(--m-form-item-line-height);
      /* ----------------------------------------- 表单标准模板(垂直居中对齐，部分组件微调)-------------------------------------------- */
      /* -------   移动端字段去掉边框   -------- */
      .@{mListClsPrefix}-item .@{mListClsPrefix}-line,
      .@{mListClsPrefix}-item.@{mTextareaClsPrefix}-item,
      .@{mScopeClsPrefix} .@{mScopeClsPrefix}-wrap,
      .@{mScopeClsPrefix}
        .@{mScopeClsPrefix}-wrap
        .@{mScopeClsPrefix}-controls {
        border: 0;
        &:after {
          display: none;
        }
      }
      .@{mScopeClsPrefix}
        .@{mScopeClsPrefix}-wrap
        .@{mScopeClsPrefix}-controls::after {
        display: none;
      }
      .@{mListClsPrefix}-item.@{mTextareaClsPrefix}-item::after,
      .@{mListClsPrefix}-item.@{mInputClsPrefix}-item::after {
        display: none;
      }
      /* ----------移动端表单组件样式调整 -------------- */
      .@{richTextClsPrefix} {
        margin-top: var(--v-spacing-sm);
      }
      .@{mCcheckboxClsPrefix}-label,
      .@{mCcheckboxClsPrefix}-left,
      .@{mCcheckboxClsPrefix}-right,
      .@{mRadioClsPrefix}-label,
      .@{mRadioClsPrefix}-left,
      .@{mRadioClsPrefix}-right {
        padding: calc(10 * var(--hd)) 0;
      }
      .@{mRadioClsPrefix}-wrapper-hold-right,
      .@{mCcheckboxClsPrefix}-wrapper-hold-right {
        padding: 0;
        text-align: left;
      }
      /* ------- 镜像特殊处理 -------- */
      &.needRTL {
        .@{mRadioClsPrefix}-wrapper:not(.@{mRadioClsPrefix}-wrapper-hold-right),
        .@{mCcheckboxClsPrefix}-wrapper:not(.@{mCcheckboxClsPrefix}-wrapper-hold-right) {
          text-align: right;
        }
      }
      /* -------   label、wrapper占据一行的布局(样式特殊调整)   -------- */
      &-col-wholeLine {
        .@{formItemClsPrefix}-wrapper {
          padding-left: 0;
          padding-right: 0;
          .@{formSwitchClsPrefix} {
            padding-left: var(--h-spacing-lg);
            padding-right: var(--h-spacing-lg);
          }
          .@{formSwitchClsPrefix}-RICHTEXT {
            padding-left: 0;
            padding-right: 0;
            .@{mRichTextClsPrefix} .cke {
              border-color: transparent;
            }
            .@{mRichTextClsPrefix}-cke-readonly {
              padding-left: var(--h-spacing-lg);
              padding-right: var(--h-spacing-lg);
              // display: flex;
              // align-items: center;
            }
          }
        }
        .@{formSwitchClsPrefix}-BROWSER,
        .@{formSwitchClsPrefix}-TYPESBROWSER {
          .@{mBrowserClsPrefix}-associative-inner.is-placeholder:not(
              .is-voice-to-text
            )
            .@{formItemClsPrefix}-item-placeholder {
            justify-content: flex-start;
          }
          .@{mBrowserClsPrefix}-associative.is-readonly
            .@{mBrowserClsPrefix}-associative-selected
            .@{mListClsPrefix}-column
            .@{mListClsPrefix}-body {
            text-align: left;
          }
        }
        .@{formSwitchClsPrefix}-RATE {
          // rate由表单组件撑开内容
          padding: 0;
        }
        .@{mRateClsPrefix}-wrapper {
          padding: calc(7 * var(--hd)) 0; // 按照设计稿，高度加起来为44, padding top、bottom调整为7
        }
        .@{formSwitchClsPrefix}-PLUGIN {
          .ui-m-secret-select {
            .ui-m-secret-select-secretlevel-formSwitch,
            .ui-m-secret-select-secretvalidity-formSwitch {
              padding: 0;
            }
          }
        }
      }
      /* ------ 移动端一个字段占据一行(为了兼容scope居中对齐，wrapper改为flex布局(同pc), 则一行多个字段设width：100%) --------- */
      .@{formItemClsPrefix}-singleItem {
        width: 100%;
      }
      .@{formItemClsPrefix}-line-helpTip {
        width: 100%;
      }
      /* ----------------------------------------------------------------------------------------------------------- */
    }
    &-disabled {
      .@{formItemClsPrefix}-label,
      .@{formItemClsPrefix}-wrapper {
        color: var(--invalid);
      }
    }
    //ebuilder图标
    &-requiredmark.ebcoms-assets-icon.s {
      height: calc(18 * var(--hd));
    }
  }
}
.@{formClsPrefix}-searchGroup-mobile-body {
  .@{formItemClsPrefix}-mobile
    .@{formItemClsPrefix}-wrapper-col-wholeLine
    .@{formItemClsPrefix}-wrapper {
    .@{formSwitchClsPrefix},
    .@{mRichTextClsPrefix}-cke-readonly {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.@{formClsPrefix}-mobile-card
  .@{formItemClsPrefix}-label-noWholeLine
  .ui-formItem-requiredmark.ebcoms-assets-icon.s {
  height: calc(16 * var(--hd));
}
/* ----------------------------------------- 表单字段模板(移动端) -------------------------------------------- */
.@{formItemClsPrefix}-item {
  line-height: var(--m-form-item-line-height);
  vertical-align: middle;
  background-color: var(--bg-white);
  position: relative;
  padding: 0;
  align-items: center;
  display: flex;
  &-content {
    flex: 1;
    font-size: var(--font-size-14);
    color: var(--main-fc);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
  &-placeholder {
    flex: 1;
    font-size: var(--font-size-14);
    color: var(--secondary-fc);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
  &-icon {
    margin-left: var(--h-spacing-sm);
    color: var(--secondary-fc);
    text-align: center;
    display: flex;
    align-items: center;
  }
  &-underline {
    border-bottom: var(--border-solid);
  }
  &-isHoldRight {
    text-align: right;
    &.needRTL {
      text-align: left;
    }
  }
  &-label {
    background-color: var(--bg-white);
    line-height: var(--m-form-item-line-height);
    vertical-align: middle;
    &-required {
      display: flex;
      align-items: center;
    }
  }
  &-wrapper-required {
    display: flex;
    align-items: center;
    .@{formItemClsPrefix}-item {
      width: 100%;
    }
  }
}

.@{formClsPrefix}-mobile-card .@{formItemClsPrefix}-label-wholeLine {
  // card布局的整行标签，复写上方item label样式
  padding: calc(13 * var(--hd)) 0;
}
.@{formItemClsPrefix}-noPadding {
  &-top {
    .@{formSwitchClsPrefix} {
      padding-top: 0;
    }
  }
  &-bottom {
    .@{formSwitchClsPrefix} {
      padding-bottom: 0;
    }
  }
  &-left {
    .@{formSwitchClsPrefix} {
      padding-left: 0;
    }
  }
  &-right {
    .@{formSwitchClsPrefix} {
      padding-right: 0;
    }
  }
  &-all {
    .@{formSwitchClsPrefix} {
      padding: 0;
    }
  }
}

// /*
//   #1804916
//   概述：满足 Form的鼠标移入多选多类型编辑样式优化的需求
// */
// .@{formItemClsPrefix}:hover {
//   .@{browserClsPrefix}.is-multiple.box-selection:not(.is-empty)
//     .@{browserClsPrefix}-associative-search-input {
//     border: var(--border-solid);
//   }
//   .@{browserClsPrefix}-types.is-multiple.box-selection:not(.is-empty)
//     .@{browserClsPrefix}-associative-search-input,
//   .@{browserClsPrefix}-share.is-multiple.box-selection:not(.is-empty)
//     .@{browserClsPrefix}-associative-search-input {
//     border-color: transparent;
//   }
//   .@{browserClsPrefix}-types.is-multiple.box-selection:not(.is-empty)
//     .@{browserClsPrefix}-types-associative-inner,
//   .@{browserClsPrefix}-share.is-multiple.box-selection:not(.is-empty)
//     .@{browserClsPrefix}-share-associative-inner {
//     border: var(--border-solid);
//   }
// }
