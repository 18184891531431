.@{mQuickMenuClsPrefix}-triggerMenuDialog {

  .ui-dialog-body-container {
    background-color: var(--base-white);
  }

  .ui-title-right-container {
    display: inline-flex;
  }

  &-wrap {
    display: flex;
    flex-flow: wrap;
  }

  &-wrap-empty {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-item {
    width: 25%;
    height: calc(105 * var(--hd));
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &-item-icon {
    height: calc(50 * var(--hd));
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &-content {
      height: calc(40 * var(--hd));
      width: calc(40 * var(--hd));
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      svg {
        color: var(--base-white);
        width: calc(26 * var(--hd));
        height: calc(26 * var(--hd));
      }
    }
  }

  &-item-title {
    flex: 1;
    padding-top: calc(5 * var(--hd));

    &-text {
      color: var(--m-readonly-text);
      font-size: var(--font-size-14);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      // 换行替换
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      word-wrap: break-word;
      overflow-wrap: break-word;
      padding: 0 calc(8 * var(--hd));
    }
  }

  &-input {
    width: calc(200 * var(--hd));

    &-prefix {
      color: var(--secondary-fc);
    }
  }
}

// 空数据
.@{mQuickMenuClsPrefix}-triggerMenuDialog-empty {
  .ui-dialog-body {
    height: 100%;
  }

  .@{mQuickMenuClsPrefix}-triggerMenuDialog-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.@{mQuickMenuClsPrefix}-m-triggerMenuDialog {

  .ui-m-dialog-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-color: var(--bubble-color);
  }

  .ui-m-dialog-top {
    background-color: var(--bubble-color);
    background-image: var(--quick-menu-dialog-bg-image);
    padding: 0;
  }

  &-scroller {
    display: flex;
    flex-flow: wrap;
    padding: calc(12 * var(--hd)) calc(24 * var(--hd));
  }

  &-searchBar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 calc(24* var(--hd));

    .ui-m-input-item {
      width: 100%;
      background: var(--base-white);
      border-radius: calc(22 * var(--hd));

      .ui-m-input-label {
        width: calc(40* var(--hd));
        margin-right: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }

    svg {
      width: calc(16 * var(--hd));
      height: calc(16 * var(--hd));
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: calc(22 * var(--hd));
      height: calc(22 * var(--hd));
    }
  }
}

// 空数据
.@{mQuickMenuClsPrefix}-m-triggerMenuDialog-empty {

  .@{mQuickMenuClsPrefix}-m-triggerMenuDialog-emptyContent {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}