.@{alertClsPrefix} {
  position: relative;
  display: flex;
  align-items: center;
  padding: calc(8 * var(--hd)) calc(14 * var(--hd));
  word-wrap: break-word;
  border-radius: var(--border-radius-xs);
  font-size: var(--font-size-12);

  &-content {
    flex: 1;
    min-width: 0;
    line-height: calc(16 * var(--hd));
  }

  &-action {
    margin-left: calc(4 * var(--hd));
    line-height: calc(16 * var(--hd));
  }

  &-icon {
    margin-right: calc(4 * var(--hd));
  }

  &-description {
    display: none;
    font-size: var(--font-size-12);
    line-height: calc(16 * var(--hd));
  }

  &-info {
    background: #ebf4ff;
    border: calc(1 * var(--hd)) solid #bad8ff;
    .@{alertClsPrefix}-icon {
      color: var(--info);
    }
  }

  &-success {
    background-color: #e8f9e0;
    border: calc(1 * var(--hd)) solid #a0d585;
    .@{alertClsPrefix}-icon {
      color: var(--success);
    }
  }

  &-warning {
    background: #fff6e3;
    border: calc(1 * var(--hd)) solid #ffcd6a;
    .@{alertClsPrefix}-icon {
      color: var(--warning);
    }
  }

  &-error {
    background: #ffe4e4;
    border: calc(1 * var(--hd)) solid #ff9293;
    .@{alertClsPrefix}-icon {
      color: var(--danger);
    }
    .@{alertClsPrefix}-description > pre {
      margin: 0;
      padding: 0;
    }
  }

  &-close-icon {
    margin-left: calc(4 * var(--hd));
    padding: 0;
    overflow: hidden;
    font-size: var(--font-size-base);
    line-height: calc(16 * var(--hd));
    background-color: var(--bg-transparent);
    border: none;
    outline: none;
    cursor: pointer;

    &-icon {
      color: var(--secondary-fc);
      transition: color 0.3s;
      &:hover {
        color: var(--regular-fc);
      }
    }
  }

  &-close-text {
    color: var(--secondary-fc);
    transition: color 0.3s;
    &:hover {
      color: var(--regular-fc);
    }
  }

  &-with-description {
    align-items: flex-start;
    padding: calc(14 * var(--hd)) calc(14 * var(--hd));
  }

  &-with-description &-no-icon {
    padding: calc(8 * var(--hd)) calc(14 * var(--hd));
  }

  &-with-description &-icon {
    margin-right: calc(8 * var(--hd));
    font-size: calc(24 * var(--hd));
  }

  &-with-description &-message {
    display: block;
    margin-bottom: calc(4 * var(--hd));
  }

  &-message {
    color: var(--main-fc);
  }

  &-with-description &-description {
    display: block;
  }

  &-banner {
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
  }
}
