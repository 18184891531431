.ui-dialog-wrap.ui-confirm.modulePublishSuccessToReload-dialog {
  .ui-layout-header, .ui-layout-footer {
    display: none;;
  }
  .ui-confirm-body {
    border-radius: calc(6 * var(--hd));
    padding-left: calc(26 * var(--hd));
    padding-right: calc(20 * var(--hd));
  }
  &.is-chinese {
    .modulePublishSuccessToReload-dialog-content-right p {
      height: calc(50 * var(--hd));
    }
  }
} 

.modulePublishSuccessToReload-dialog-content-left {
  .ui-icon {
    width: calc(70 * var(--hd));
    height: calc(70 * var(--hd));
    .ui-icon-svg {
      width: 100%;
      height: 100%;
    }
  }
} 

.modulePublishSuccessToReload-dialog-content {
  display: flex;
  align-items: flex-start;
  .modulePublishSuccessToReload-dialog-close {
    position: absolute;
    right: var(--h-spacing-lg);
    top: var(--v-spacing-lg);
    cursor: pointer;
    color: var(--secondary-fc);
    &:hover {
      color: var(--main-fc);
    }
    .ui-icon-svg {
      width: calc(18 * var(--hd));
      height: calc(18 * var(--hd));
    }
  }
  .modulePublishSuccessToReload-dialog-button-group {
    text-align: right;
  }
  &-right {
    margin-left: calc(15 * var(--hd));
    font-size: var(--font-size-lg);
    color: #333333;
    p {
      line-height: 1.3;
      margin-top: calc(10 * var(--hd));
      margin-bottom: calc(10 * var(--hd));
      height: calc(90 * var(--hd));
      box-sizing: border-box;
    }
  }
  &-disabled-checkbox {
    margin-bottom: calc(23 * var(--hd));
    color: #999;
    line-height: 1;
    font-size: var(--font-size-sm);
    display: flex;
    align-items: center;
    .ui-checkbox-wrapper {
      margin-right: calc(9 * var(--hd));
    }
  }
  &-disabled-select {
    margin-left: var(--h-spacing-md);
    border: var(--border-solid);
    border-radius: var(--border-radius-xs);
    height: calc(30 * var(--hd));
    &-popup {
      z-index: 3000;
    }
  }

  /* 移动端样式 */
  &.is-mobile {
    display: flex;
    flex-direction: column;
    .ui-m-dialog-top {
      padding: calc(18 * var(--hd)) calc(24 * var(--hd));
    }
    .ui-m-dialog-content {
      width: 80%;
    }
    .ui-m-dialog-footer-btn-last {
      color: var(--primary);
    }
    .modulePublishSuccessToReload-dialog-content {
      &-left {
        width: 100%;
        .ui-icon {
          width: calc(80 * var(--hd));
          height: calc(80 * var(--hd));
          .ui-icon-svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      &-right {
        margin-left: 0;
        margin-top: calc(18 * var(--hd));
        p {
          line-height: 1.3;
          text-align: left;
          margin-top: 0;
          margin-bottom: 0;
        }
        .modulePublishSuccessToReload-dialog-content-disabled-checkbox {
          margin-bottom: 0;
          justify-content: center;
          .ui-checkbox-wrapper {
            margin-right: calc(6 * var(--hd));
          }
        }
      }
    }
  }
}

.ui-dialog-container.modulePublishSuccessToReload-dialog {
  .modulePublishSuccessToReload-dialog-content {
    padding: calc(30 * var(--hd)) calc((26 * var(--hd)));
  }
  .ui-dialog-wrap.ui-dialog-wrap-right {
    margin-top: calc(30 * var(--hd));
    margin-right: calc(30 * var(--hd));
  }
  .ui-dialog-content.ui-dialog-content-right {
    border-radius: calc(6 * var(--hd));
    .ui-dialog-body-container {
      border-radius: calc(6 * var(--hd));
    }
  }
  .ui-dialog-body-container {
    background-color: var(--base-white);
  }
  .ui-dialog-body {
    padding: 0;
  }
}
