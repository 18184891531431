.@{mRichTextClsPrefix} {
  position: relative;

  // 工具栏布局
  .cke_toolbox {
    display: flex;
    flex-wrap: wrap;
  }
  
  &-scroll {
    .cke_toolbox {
      display: block;
      width: max-content;
      width: -moz-max-content;
      width: -webkit-max-content;
      width: -ms-max-content;
    }
    .cke .cke_top {
      overflow-y: hidden;
      overflow-x: auto;
      height: auto;
      user-select: none;
    }
  }

  .cke {
    border: 0;
  }

  .cke_top {
    // 边线样式
    border-color: var(--diviling-line-color);

    // 调整工具栏大小
    .cke_button_icon svg {
      width: calc(22 * var(--hd));
      height: calc(22 * var(--hd));
    }
    .cke_combo .cke_combo_button {
      height: calc(30 * var(--hd));
    }
    .cke_combo .cke_combo_button .cke_combo_text {
      font-size: var(--font-size-14);
      width: calc(30 * var(--hd));
      padding-top: calc(3 * var(--hd));
      padding-bottom: calc(3 * var(--hd));
      padding-left: calc(8 * var(--hd));
    }

    .cke_combo__format .cke_combo_button .cke_combo_text,
    .cke_combo__font .cke_combo_button .cke_combo_text{
      width: 5em;
    }

    .cke_combo .cke_combo_open {
      height: calc(22 * var(--hd));
      line-height: calc(22 * var(--hd));
    }
    svg#Icon-Down-arrow01 {
      padding-top: calc(6 * var(--hd));
    }
    .cke_toolbar_separator {
      margin-top: calc(8 * var(--hd));
    }
    .cke_combo .cke_toolbar_separator {
      margin-top: calc(8 * var(--hd));
    }
  }

  &-router {
    width: 100%;
    font-size: var(--font-size-14);
  }

  &-outer {
    width: 100%;
    background-color: var(--bg-white);
    min-height: calc(106 * var(--hd));
    padding: calc(10 * var(--hd)) var(--h-spacing-lg);
    box-sizing: border-box;

    &-placeholder {
      color: var(--placeholder-fc);
    }
  }

  &-disabled {
    cursor: not-allowed;
    opacity: .5;
  }

  &-dialog {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    .@{mRichTextClsPrefix} {
      height: 100%;
    }

    &-content {
      flex: 1 1;

      // 编辑器样式复写
      .cke {
        height: 100%;
        border: 0;

        //工具栏样式
        // .cke_top {
        //   overflow-x: scroll;

        //   .cke_toolbox {
        //     display: block;
        //     width: max-content;
        //     width: -moz-max-content;
        //     width: -webkit-max-content;
        //     width: -ms-max-content;
        //   }
        // }
      }

      .cke_inner {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .cke_contents {
        flex: 1;
      }
    }

    &-footer-wrap {
      display: flex;
      flex-direction: column-reverse;
      -webkit-flex-direction: row-reverse;
      -moz-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;

      .ui-m-emoticon {
        border-top: var(--hd) solid var(--border-color);
      }
    }

    &-footer {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: calc(50 * var(--hd));
      line-height: calc(50 * var(--hd));
      border-top: var(--border-solid);
      box-sizing: border-box;

      &-item {
        flex: 1;
        position: relative;
        box-sizing: border-box;
        text-align: center;
        font-size: var(--font-size-17);
        color: var(--secondary-fc);

        &:after {
          //border边线效果
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          height: 50%;
          width: var(--border-width);
          background-color: var(--border-color);
        }

        &:last-child {
          color: var(--primary);

          &:after {
            width: 0;
          }
        }
      }
    }
  }

  &-voicebtn {
    &-container {
      width: 100%;
      text-align: right;
      background-color: var(--bg-white);
      padding: var(--v-spacing-md) var(--h-spacing-lg) var(--v-spacing-md) 0;
    }
    display: inline-flex;
    align-items: center;
    padding: calc(4 * var(--hd)) calc(10 * var(--hd));
    border: var(--form-line-border);
    border-radius: calc(12 * var(--hd));
    font-size: var(--font-size-12);
    color: var(--secondary-fc);
    .@{iconClsPrefix} {
      color: var(--primary);
      margin-right: calc(2 * var(--hd));
    }
  }

  .cke_button_icon {
    .@{mUploadClsPrefix}-select-input {
      position: absolute;
      width: 100%;
    }
  }
}

.@{mRichTextClsPrefix}-cke-readonly {
  font-size: var(--font-size-14);
}