.@{monthDayPickerClsPrefix} {
  * {
    box-sizing: border-box;
  }

  width: 100%;
  min-width: calc(60 * var(--hd));
  display: inline-block;
  box-sizing: border-box;
  background-color: var(--bg-white);
  color: var(--placeholder-fc);
  font-size: var(--font-size-12);

  &-maxWidth {
    max-width: calc(200 * var(--hd));
  }

  &-disabled {

    .@{inputClsPrefix}-wrap,
    .@{monthDayPickerClsPrefix}-rangeWrap {
      background-color: var(--label-bc);
      cursor: not-allowed;
      color: var(--placeholder-fc);
      border-color: var(--border-color);

      &:hover {
        border-color: var(--border-color);
      }
    }
  }

  &-readOnly {
    color: var(--main-fc);
    display: flex;
    align-items: center;
    background: none;
    height: calc(30 * var(--hd));
    min-width: unset;

    .date-span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.@{monthDayPickerClsPrefix}-wrap {
  display: flex;
  height: calc(30 * var(--hd));
  border-radius: var(--border-radius-xs);

  input {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

}

.@{monthDayPickerClsPrefix}-panel {
  box-sizing: border-box;
  width: calc(200 * var(--hd));
  background: var(--bg-white);
  color: var(--placeholder-fc);
  font-size: var(--font-size-12);
  background: var(--bg-white);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius-xs);
  box-shadow: var(--box-shadow);
  margin-top: calc(5 * var(--hd));
}

.@{monthDayPickerClsPrefix}-content {
  height: calc(180 * var(--hd));
  display: flex;
  flex-direction: row;
  color: var(--secondary-fc);
  border-bottom: calc(1 * var(--hd)) solid var(--border-color);
}

.@{monthDayPickerClsPrefix}-footer {
  height: calc(30 * var(--hd));
  padding: 0 calc(10 * var(--hd));
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  .cancel,
  .confirm {
    height: calc(30 * var(--hd));
    margin: 0 calc(8 * var(--hd));
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background: var(--bg-transparent);
    font-size: var(--font-size-sm);
    outline: none;
    color: var(--primary);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cancel {
    color: var(--regular-fc);
  }
}

//后缀suffix处理
.@{inputClsPrefix}-suffix {
  .@{monthDayPickerClsPrefix} {
    &-icon {
      margin-left: 4px;
    }

    &-clear {
      background: var(--bg-white);
      display: none;
      color: var(--invalid-fc);
      transition: color 0.3s ease, opacity 0.15s ease;

      &:hover {
        color: var(--secondary-fc);
      }

      &-show {
        cursor: pointer;
      }
    }
  }
}

.@{monthDayPickerClsPrefix}:hover {
  .@{monthDayPickerClsPrefix}-clear-show {
    display: inline-block;
  }
}