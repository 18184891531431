@cascaderInputClsPrefix: ~'rc-cascader';

.@{cityPickerClsPrefix} {
  min-width: calc(150 * var(--hd));
  max-width: calc(200 * var(--hd));
  width: 100%;

  .@{cascaderClsPrefix}-input {
    min-width: calc(150 * var(--hd));
    max-width: calc(200 * var(--hd));
    width: 100%;
  }
}