.@{helpClsPrefix} {
  display: inline-block;

  &-explain {
    cursor: pointer;
    color: var(--secondary-fc);

    .ui-icon {
      >.ui-icon-svg {
        width: var(--icon-size-s);
        height: var(--icon-size-s);
      }
    }
  }

  &-inner {
    word-break: break-word;
  }

  &-inner-center {
    text-align: center;
  }
}

.@{helpClsPrefix}-popover {
  .@{popoverClsPrefix}-content {
    max-width: calc(250 * var(--hd));
    background-color: var(--popover-tooltip-background);
    color: var(--base-white);
    line-height: 1.4;
    white-space: pre-wrap;
  }
  &.showExplainIcon {
    .@{popoverClsPrefix}-arrow {
      &-topLeft, &-bottomLeft {
        margin-left: calc(2 * var(--hd));
      }
      &-topRight, &-bottomRight {
        right: calc(2 * var(--hd));
      }
      &-leftTop, &-rightTop {
        top: calc(2 * var(--hd));
      }
      &-leftBottom, &-rightBottom {
        bottom: calc(2 * var(--hd));
      }
    }
  }
}

.@{helpClsPrefix}-noArrow {
  .@{popoverClsPrefix}-arrow {
    border-color: transparent;
  }
}