.@{emptyClsPrefix} {
  margin: 0 calc(8 * var(--hd));
  font-size: var(--font-size-14);
  text-align: center;

  &-image {
    width: 100%;
    // height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(8 * var(--hd));


    img {
      height: 100%;
    }

    svg {
      height: 100%;
      margin: auto;
    }
  }

  &-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-16);
    color: var(--regular-fc);
    font-weight: var(--font-weight-base);
    margin-bottom: calc(8 * var(--hd));
  }

  &-description {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-12);
    color: var(--secondary-fc);
    &-ie {
      margin-top: 5px;
      &>div:first-child {
        margin-bottom: 5px ;
      }
    }
  }

  &-ie-icon {
    width: 340px;
    height: 194px;
    .ui-icon-svg {
      width: 100%;
    }
  }

  &-main {
    height: 100%;
    width: 100%;
  }

  &-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: calc(16 * var(--hd));
  }

}