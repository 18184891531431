.@{emoticionClsPrefix} {
  .@{popoverClsPrefix}-content {
    padding: 0;
  }

  &-content {
    background: var(--bg-white);
    padding: var(--h-spacing-lg);
    height: calc(262 * var(--hd));
    margin: 0;
    border-radius: var(--border-radius-xs);

    &-scroll {
      width: calc(390 * var(--hd));
      height: 100%;
    }

    &-inner {
      width: calc(380 * var(--hd));
      border: var(--border-solid);
      border: var(--border-solid);
      padding: 0 var(--h-spacing-xs);
    }

    &-group {
      display: flex;
      padding: var(--h-spacing-sm) 0;
    }

    &-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 10%;
      .@{iconClsPrefix}-svg {
        width: calc(25 * var(--hd));
        height: calc(25 * var(--hd));
      }
    }
  }
}