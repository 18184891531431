.@{timePickerClsPrefix}-panel {
  width: calc(210 * var(--hd));
  display: inline-flex;
  flex-direction: column;
  color: var(--placeholder-fc);
  background: var(--base-white);
  font-size: var(--font-size-12);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius-xs);
  box-shadow: var(--box-shadow);
  margin-top: calc(5 * var(--hd));
}

.@{timePickerClsPrefix}-panel-HH {
  width: calc(105 * var(--hd));
}

.@{timePickerClsPrefix}-panel-detail {
  width: unset;

  &.isIE {
    width: auto; // ie11 不兼容unset
  }
}

.@{timePickerClsPrefix}-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.@{timePickerClsPrefix}-detailContent {
  display: flex;
  justify-content: center;

  &-detail {
    border-left: calc(1 * var(--hd)) solid var(--diviling-line-color);
  }

  &-detail-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &-detail-wrap-cell {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-fc);
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;

    &-disabled {
      cursor: not-allowed;
      color: var(--placeholder-fc);
    }
  }

  &-detail-wrap-cell {

    &:hover:not(&-disabled) {
      background: var(--label-bc);
      cursor: pointer;
    }

    &-selected:not(&-disabled) {
      background: var(--label-bc);
      color: var(--primary)
    }
  }
}

.@{timePickerClsPrefix}-footer {
  border-top: calc(1 * var(--hd)) solid var(--border-color);
  height: calc(30 * var(--hd));
  padding: 0 calc(10 * var(--hd));
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  .cancel,
  .confirm {
    margin: 0 calc(8 * var(--hd));
    height: calc(30 * var(--hd));
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background: transparent;
    font-size: var(--font-size-sm);
    outline: none;
    color: var(--primary);
    cursor: pointer;
  }

  .cancel {
    color: var(--regular-fc);
  }
}

.@{timePickerClsPrefix}-rangePanel {
  box-sizing: border-box;
  width: calc(328 * var(--hd));
  height: calc(282 * var(--hd));
  font-size: var(--font-size-12);
  display: flex;
  flex-direction: column;
  background: var(--base-white);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius-xs);
  box-shadow: var(--box-shadow);
  margin-top: calc(5 * var(--hd));
}

.@{timePickerClsPrefix}-rangeContent {
  height: calc(242.5 * var(--hd));
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .left,
  .right {
    display: flex;
    flex-direction: column;
    width: calc(148 * var(--hd));

    .title {
      flex: 1;
      display: flex;
      color: var(--regular-fc);
      align-items: center;
      justify-content: center;
    }

    .panel {
      height: calc(196 * var(--hd));
      border: var(--border-solid);
      border-radius: var(--border-radius-xs);
      color: var(--secondary-fc);
      margin-bottom: calc(10 * var(--hd));
      display: flex;
      flex-direction: row;
    }
  }
}

.@{timePickerClsPrefix}-rangeFooter {
  box-sizing: border-box;
  border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
  height: calc(40 * var(--hd));
  padding: 0 calc(10 * var(--hd));
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  border-top: calc(1 * var(--hd)) solid var(--border-color);

  .cancel,
  .confirm {
    margin: 0 calc(8 * var(--hd));
    height: calc(40 * var(--hd));
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background: transparent;
    font-size: var(--font-size-12);
    outline: none;
    color: var(--primary);
    cursor: pointer;
  }

  .cancel {
    color: var(--regular-fc);
  }
}