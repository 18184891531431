  .@{mcommonPhraseClsPrefix} {
    height: 100%;

    //MSetting子组件样式
    &-setting {
      height: 100%;
      background: rgb(246, 246, 246);
    
      &-spin{
        height: calc(100% - calc(120 * var(--hd)));
      }

      &-search {
        padding: calc(10 * var(--hd));
        background: var(--bg-white);
        .border-solid-then;
      }

      &-con {
        height: calc(100% - 90 * var(--hd));
        overflow-y: auto;
      }

      &-con-notabs{
        height: calc(100% - 48 * var(--hd));
      }

      &-tabs{
        background: #fff;

        .ui-m-menu-top-extra{
          padding: 0 calc(10 * var(--hd)) calc(6 * var(--hd)) 0;
        }

        .ui-m-menu-wrap .ui-m-menu-list-item-wrap{
          flex: none;
          padding-left: 0;
        }

        .ui-m-menu-top-left{
          padding: 0 calc(15 * var(--hd));
        }
        
        .ui-m-menu-top-extra{
          padding-bottom: 0;
        }
      }

      &-empty{
        height: calc(100% - 100 * var(--hd));
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .link {
          color: var(--link-fc);
        }
      }

      &-list-empty{
        height: calc(100% - 180 * var(--hd));
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &-list {
        &-swiper {
          width: 100%;
          height: 100%;
          padding-left: calc(10 * var(--hd));
          font-size: var(--font-size-14);

          .@{swipeActionClsPrefix}-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: calc(3 * var(--hd)) 0;
          }

          &-type {
            font-size: var(--font-size-12);
            color: var(--secondary-fc);
          }

          &-text {
            padding: calc(3 * var(--hd)) 0;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          // 镜像样式
          &-text.ui-rtl {
            text-align: right;
          }
        }

        .@{mListClsPrefix}-content {
          user-select: none;
        }

        .ui-swipe-action-btn-text {
          font-size: calc(17 * var(--hd));
        }
      }

      &-list-hidden {
        &-text-box{
         width: 100%;
         padding-top:calc(15 * var(--hd)) ;
         padding-left: calc(10 * var(--hd));
         background: rgb(246, 246, 246);
        }

        &-text {
          font-size: calc(14 * var(--hd));
          color: var(--regular-fc);
        }

        .@{mcommonPhraseClsPrefix}-setting-list {
          margin-top: calc(5 * var(--hd));
        }
      }
    }
    
    // MGroupSetting分组设置
    &-group-setting{
      height: 100%;
      background-color: rgb(246, 246, 246);

      &-list-empty{
        height: calc(100% - 100 * var(--hd));
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &-search {
        padding: calc(10 * var(--hd));
        background: var(--bg-white);
      }

      &-con {
        height: calc(100% - 50 * var(--hd));
        overflow-y: auto;
      }
      
    &-list {
      &-swiper {
        width: 100%;
        height: 100%;
        padding-left: calc(10 * var(--hd));
        font-size: var(--font-size-14);

        .@{swipeActionClsPrefix}-content {
          display: flex;
          align-items: center;
        }

        &-type {
          font-size: var(--font-size-12);
          color: var(--secondary-fc);
        }

        &-text {
          padding: calc(3 * var(--hd)) 0;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .content {
          margin-left: calc(10 * var(--hd));
          width: 100%;
          overflow: hidden;
        }
      }

      &-hidden {
        margin-top: calc(15 * var(--hd));

        &-text {
          font-size: calc(14 * var(--hd));
          margin-left: calc(16 * var(--hd));
          color: var(--regular-fc);
        }

        .@{mcommonPhraseClsPrefix}-group-setting-list {
          margin-top: calc(5 * var(--hd));
        }
      }

      .@{mListClsPrefix}-content {
        padding-left: calc(5 * var(--hd));
      }

      .ui-swipe-action-btn-text {
        font-size: calc(17 * var(--hd));
      }
    }
  }
   
  .footer-btngroup{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    padding: calc(14 * var(--hd)) 0 calc(22 * var(--hd)) 0;
    background: var(--bg-white);

    &-button {
      width: 50%;
      text-align: center;
      font-size: calc(17 * var(--hd));
    }

    &-save {
      color: var(--link-fc);
    }

    &-cancel {
      color: var(--secondary-fc);
    }

    &-line {
      transform: scale(0.1, 1.5);
      color: var(--border-color);
    }
  }

  // MGroupEdit
  &-group-edit {
    &-footer {
      .footer-btngroup
    }
  }

  // MGroupList
  &-group-list {
    height: 100%;
    background-color: rgb(246, 246, 246);

    &-popover{
     &-text{
      padding: 0 calc(40 * var(--hd));
      color: var(--regular-fc);
      display: flex;
      align-items: center;
     }

     .ui-popover{
      margin-top: calc(8 * var(--hd));
      margin-left: calc(5 * var(--hd));
     }

     .ui-icon{
      transform: translateY(calc(-1 * var(--hd)));
     }
    }

    &-mask{
      background: var(--mask-bc);
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &-spin{
      height: calc(100% - calc(70 * var(--hd)));
    }

    &-search {
      padding: calc(10 * var(--hd));
      background: var(--bg-white);
    }

    &-empty{
      height: calc(100% - 100 * var(--hd));
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .link {
        color: var(--link-fc);
      }
    }

    &-list {
      background: var(--bg-white);
      .ui-m-list-item{
        margin: 0 calc(16 * var(--hd));
      }
      .ui-m-list-content .ui-m-checkbox-inner-left {
        margin: 0;
      }
      .ui-m-list-content{
        padding: calc(6 * var(--hd));
      }
    }

    .group-name {
      font-size: var(--font-size-14);
    }

    .group-type {
      font-size: var(--font-size-12);
      color: var(--secondary-fc);
      margin-top: calc(2 * var(--hd));
    }

    &-footer {
      .footer-btngroup
    }
  }

  //MEditPhrase子组件样式
  &-edit {
    overflow-y: auto;

    &-footer {
      .footer-btngroup
    }
    
    .form-groupname{
      height: 100%;
      display: flex;
      background: var(--bg-white);
      align-items: center;
      justify-content: flex-end;
      padding-right: calc(10 * var(--hd));
      color: var(--main-fc);

      .ui-icon{
        margin-left: calc(8 * var(--hd));
        color: var(--secondary-fc);
      }

      .placeholder{
        color: var(--secondary-fc);
      }
    }
  }

    //直接使用列表的样式处理
    &-nodialog-content {
      background: var(--bg-white);
      width: 100%;
      text-align: center;
      overflow-y: hidden;
      position: relative;

      &-empty,&-list-empty {
        height: calc(100% - 50 * var(--hd));
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-icon{
          margin: calc(10 * var(--hd)) 0;
        }
      }

      &-footer {
        height: calc(var(--hd)*50);
        box-shadow: var(--box-shadow);
        display: flex;
        position: absolute;
        bottom: 0;
        width: 100%;
          .ui-icon{
            line-height: 0.8;
          }

        &-btn {
          display: flex;
          width: calc(50% - var(--border-width)/2);
          font-size: var(--font-size-17);
          padding: var(--v-spacing-lg) 0;
          align-items: center;
          justify-content: center;
          border-top: var(--border-width) solid var(--diviling-line-color);
          background: var(--bg-white);
        }

        &-setting {
          color: var(--secondary-fc);
          border-right: var(--border-width) solid var(--diviling-line-color);
        }

        &-add {
          color: var(--m-dialog-footer-btn-primary-color);
        }

        &-icon {
          margin: 0 calc(3 * var(--hd));

          .@{iconClsPrefix}-svg {
            width: calc(20 * var(--hd));
            height: calc(20 * var(--hd));
          }
        }
      }

      &-list-wrap {
        padding: 0 calc(24 * var(--hd));
        margin-top: calc(100 * var(--hd));
        height: calc(100% - 150 * var(--hd));
        overflow-y: auto;

        .groupname{
          margin-top: calc(2 * var(--hd));
          color: var(--secondary-fc);
          font-size: var(--font-size-14);
        }
        
      }

      &-list-wrap-nogroup{
        margin-top: calc(50 * var(--hd));
        height: calc(100% - 100 * var(--hd));

        .ui-m-list-item .ui-m-list-content{
          padding: calc(6 * var(--hd));
        }
      }

      &-header {
        padding: 0 calc(10 * var(--hd));
        position: absolute;
        top: 0;
        width: 100%;

        &-title {
          padding: calc(11 * var(--hd)) 0 calc(7 * var(--hd)) 0;
          font-size: var(--font-size-16);
          color: var(--pirmary-fc);
          font-weight: 500;
        }

        &-search {
          margin: calc(10 * var(--hd)) 0;
        }
      }

      &-tabs {
        .border-top-solid-then;
        &::before{
          z-index: 10;
        }
        margin-top: calc(10 * var(--hd));

        .ui-m-menu-top-left {
          padding: 0 calc(16 * var(--hd));
        }

        .ui-m-menu-list .ui-m-menu-list-item-wrap {
          flex: none;
          padding-left: 0;
        }
      }

      .@{mListClsPrefix} {
        &-content {
          display: flex;
          font-size: var(--font-size-16);
          color: var(--main-fc);
        }
      }
    }
  }

  .@{mcommonPhraseClsPrefix}-dialog {
    //复写dialog的paddinf样式
    .ui-m-dialog-top {
      padding: 0;
      // overflow-y: hidden;
    }

    .@{mListClsPrefix}-content {
      display: flex;
      font-size: var(--font-size-16);
      color: var(--main-fc);
    }

    .@{mcommonPhraseClsPrefix}-content-empty, .@{mcommonPhraseClsPrefix}-content-list-empty  {
      height: calc(100% - 20 * var(--hd));
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .@{mcommonPhraseClsPrefix}-content-list-empty {
      height: calc(100% - 120 * var(--hd));
    }

    .@{mDialogClsPrefix}-footer {
      height: calc(50 * var(--hd));
      &-btn:first-child {
        color: var(--secondary-fc);
      }
      box-shadow:0 calc(3 * var(--hd)) calc(20 * var(--hd)) var(--box-shadow-color);
    }

    .ui-m-dialog-footer.ui-m-dialog-footer-two .ui-m-dialog-footer-btn {
      border-top: none;
      border-bottom: none;
      border-radius: 0;
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: calc(17 * var(--hd));
      line-height: 1;

      &-icon {
        margin: 0 calc(3 * var(--hd));

        .@{iconClsPrefix}-svg {
          width: calc(20 * var(--hd));
          height: calc(20 * var(--hd));
        }
      }
    }

    .@{mcommonPhraseClsPrefix}-content {
      height: calc(285 * var(--hd));
      position: relative;
      display: flex;
      flex-direction: column;

      &-list-wrap {
        padding: 0 calc(16 * var(--hd));
        flex: 1;
        overflow-y: auto;

        .groupname{
          margin-top: calc(2 * var(--hd));
          color: var(--secondary-fc);
          font-size: var(--font-size-14);
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space:nowrap;
        }

        .phrase-name{
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space:nowrap;
        }

        // 镜像样式
        .phrase-name.ui-rtl {
          text-align: right;
        }
      }

      &-list-custom-item{
        width: 100%;
      }

      &-list-wrap-nogroup{
        .ui-m-list-item .ui-m-list-content{
          padding-bottom: calc(6 * var(--hd));
        }
      }

      &-header {
        &-title {
          padding: calc(11 * var(--hd)) 0 calc(7 * var(--hd)) 0;
          font-size: var(--font-size-16);
          color: var(--pirmary-fc);
          font-weight: 500;
        }

        &-search {
          margin: calc(10 * var(--hd));
          margin-bottom: 0;
        }
      }

      &-tabs {
        .border-top-solid-then;
        &::before{
          z-index: 10;
        }
        margin-top: calc(10 * var(--hd));

        .ui-m-menu-top-left {
          padding: 0 calc(16 * var(--hd));
        }

        .ui-m-menu-list-item-wrap {
          flex: none;
          padding-left: 0;
        }
      }

      &-list-wrap-nogroup{
        .ui-m-list-item{
          padding: calc(5 * var(--hd)) 0;
        }
      }

      }
      }

  .@{mcommonPhraseClsPrefix}-dialog,
  .@{mcommonPhraseClsPrefix}-edit-dialog,
  .@{mcommonPhraseClsPrefix}-content-dialog {
    background-color: rgb(246, 246, 246);
  }
  .@{mcommonPhraseClsPrefix}-edit-dialog{
    overflow-y: hidden;
  }
