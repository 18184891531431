.@{commentClsPrefix}-searchwarp {
  
  .@{searchAdvancedClsPrefix}-layout {
    .@{formClsPrefix} {
      .@{formClsPrefix}-col {
        .@{formItemClsPrefix} {
          padding-left: 0;
        }
      }
    }
    .@{formItemClsPrefix}-wrapper-col {
      padding-right: 10px;
    }
  }
  .@{searchAdvancedClsPrefix} {
    &-condition {
      .@{searchGroupClsPrefix}-body {
        margin: 0;
        width: 100%;
      }
      .@{formClsPrefix}-layout-add {
        padding-left: 0;
      }
    }
  }
}

.@{commentClsPrefix}-search {
  &-panel {
    & > div {
      border-radius: 0;    
      border: 0;
      border-bottom: var(--searchAdvanced-border);
    }
  }
}