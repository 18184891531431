@import "./dropdown.less";
@import "./input.less";
@import "./selector.less";
// @import "./icon.less";
// @import "./origin.less";

.@{cascaderClsPrefix}-multiple {

  &-readOnly {
    pointer-events: none;
    min-height: calc(30 * var(--hd));
    width: 100%;

    &-item {
      margin: calc(4 * var(--hd)) 0;
      color: var(--main-fc);
      font-size: var(--font-size-12);

      &-unique {
        height: calc(30 * var(--hd));
        line-height: calc(30 * var(--hd));
        margin: 0;
      }
    }
  }

  &-readOnly-readOnlyLineHeightStandard {
    min-height: unset;
    padding-top: var(--form-pc-readonly-padding-y);
    padding-bottom: var(--form-pc-readonly-padding-y);

    .@{cascaderClsPrefix}-multiple-readOnly-item-readOnlyLineHeightStandard {
      .form-pc-readOnlyLineHeightStandard;
      padding-top: unset;
      padding-bottom: unset;
      margin: 0;
    }
  }

  &-disabled {
    .rc-cascader-selector {
      cursor: not-allowed;
      opacity: 1;
      color: var(--input-disabled-color);
      background: var(--label-bc);

      // &:hover {
      //   border: var(--border-solid);
      // }

      .rc-cascader-selection-item {
        opacity: 1;
        color: var(--input-disabled-color);
        background: var(--label-bc);
      }
    }
  }
}

.rc-cascader-dropdown-popupInner-hidden {
  display: none;
}

@cascaderInputClsPrefix: ~'rc-cascader';