.@{commentClsPrefix} {
  &-subordinate {
    padding: calc(20 * var(--hd)) calc(16 * var(--hd));
    padding-bottom: calc(5 * var(--hd));
    text-align: right;
    flex: none;
    &-select {
      .ui-radio-label.isTag {
        margin: 0;
        // border: 1px solid #e5e5e5;
      }
    }
    &-body {
      width: calc(500 * var(--hd));
      box-shadow: -1px 1px 3px rgb(0 0 0 / 18%);
      border: 1px solid #ccc;
      padding: calc(15 * var(--hd));
      background-color: var(--bg-white);
    }
    &-overlay {
      
    }
    &-btn {
      .ui-icon {        
        transition: all 0.3s;
      }
      &:hover {
        .ui-icon {
          transform: rotate(-90deg);
        }
      }
    }
    &-content {
      border-bottom: 1px solid #ddd;
      border-top: 1px solid #ddd;
      padding: calc(15 * var(--hd)) 0;
    }
    &-list {
      > [data-rtl='true'] {
        .@{commentClsPrefix}-subordinate-search {
          .ui-input {
            transform: scaleX(1) !important;
          }
        }
      }
    }
    &-footer {
      margin: 15px 20px 12px;
      text-align: right;
    }
    &-check {
      margin: 0;
      margin-bottom: calc(5 * var(--hd));
    }
    &-title {
      display: flex;
      justify-content: space-between;
      color: var(--regular-fc);
      font-weight: bold;
      line-height: calc(30 * var(--hd));
      height: calc(30 * var(--hd));
      margin-top: calc(9 * var(--hd));
      margin-bottom: calc(10 * var(--hd));
    }
    &-search {
      display: inline-block;

    }
  }
}