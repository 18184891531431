// 默认状态
.@{radioClsPrefix} {
  display: inline-flex;
  align-items: center;
  line-height: normal;

  &-group {
    flex-wrap: wrap;
  }

  &-vertical {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    .@{radioClsPrefix}-wrapper {
      width: 100%;
      margin: 8px 0;
    }

    .@{radioClsPrefix}-label {
      width: 100%;
    }
  }

  &-left {
    flex-shrink: 0;
    position: relative;
    display: inline-block;
    // line-height: 1;
    white-space: nowrap;
    // vertical-align: middle;
    outline: none;
    cursor: var(--radio-cursor);

    width: var(--radio-size-base);
    height: var(--radio-size-base);

    &.hidden {
      display: none;
    }
  }

  .@{radioClsPrefix}-wrapper:hover &-inner {
    border-color: var(--radio-active-border-color);
  }

  &:hover::after,
  .@{radioClsPrefix}-wrapper:hover &::after {
    visibility: visible;
  }

  &-inner {
    position: absolute;
    // top: calc(-1 * var(--hd));
    top: 0;
    left: 0;
    display: inline-block;
    background-color: var(--radio-default-background);
    width: 100%;
    height: 100%;
    direction: ltr;
    border: var(--border-width) solid var(--radio-border-color);
    border-radius: 50%;
    border-collapse: separate;
    transition: all 0.3s;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: table;
      width: calc(8 * var(--hd));
      height: calc(8 * var(--hd));
      // border: var(--border-width) solid var(--radio-active-border-color);
      border-top: 0;
      border-left: 0;
      opacity: 0;
      transition: all 0.3s;
      content: " ";
      border-radius: 50%;
    }
  }

  &-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: var(--checkbox-cursor);
    opacity: 0;
  }

  &-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    visibility: hidden;
    animation: antCheckboxEffect 0.36s ease-in-out;
    animation-fill-mode: both;
    content: "";
  }

  .isTag .@{radioClsPrefix}-label-span {
    display: block;
    line-height: 1;
  }

  &-tips {
    margin-left: calc(4 * var(--hd));

    .@{helpClsPrefix},
    .@{helpClsPrefix}-explain {
      display: flex;
    }
  }
}

// 选中状态
.@{radioClsPrefix}-checked {
  .@{radioClsPrefix}-inner {
    border-color: var(--radio-active-border-color);
    background-color: var(--radio-active-background-color);

    &::after {
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%,-50%);
      opacity: 1;
      transition: all 0.3s;
      background: var(--radio-active-inner-background-color);
      // border-radius: 50%;
    }
  }
}

// checkbox wrapper
.@{radioClsPrefix}-wrapper {
  display: inline-flex;
  align-items: center;
  cursor: var(--radio-cursor);
  font-size: var(--radio-font-size);
  color: var(--radio-font-color);
  margin: var(--hd) calc(16 * var(--hd)) var(--hd) 0;

  &.is-IE {
    &::after {
      content: "";
      min-height: inherit;
    }
  }

  // 禁用状态
  &.@{radioClsPrefix}-wrapper-disabled {
    cursor: not-allowed;

    &:hover {
      .@{radioClsPrefix}-inner {
        border-color: var(--radio-disabled-border-color);
      }
    }
  }

  //只读状态
  &.@{radioClsPrefix}-wrapper-readOnly {
    cursor: default;

    &:hover {
      .@{radioClsPrefix}-inner {
        border-color: var(--radio-disabled-border-color);
      }

      .@{radioClsPrefix}-inner-checked {
        border-color: var(--radio-active-border-color);
      }
    }
  }

  &.@{radioClsPrefix}-wrapper-readOnly-readOnlyLineHeightStandard {
    .form-pc-readOnlyLineHeightStandard
  }

  // 隐藏 check 框
  &.hidden-check {
    .@{radioClsPrefix}-label {
      padding: 0;
    }
  }
}

// 禁用状态
.@{radioClsPrefix}-disabled {
  & + span {
    color: var(--radio-disabled-font-color);
  }

  .@{radioClsPrefix}-inner,
  .@{radioClsPrefix}-input {
    cursor: var(--radio-disabled-cursor);
    background-color: var(--radio-disabled-bg-color);
    border-color: var(--radio-disabled-border-color);

    &::after {
      border-color: var(--radio-disabled-border-color);
      background: var(--radio-disabled-border-color);
    }
  }
}

// 只读状态
.@{radioClsPrefix}-readOnly {
  & + span {
    color: var(--radio-font-color);
  }

  .@{radioClsPrefix}-inner,
  .@{radioClsPrefix}-input {
    cursor: default;
    background-color: var(--radio-disabled-bg-color);
    border-color: var(--radio-disabled-border-color);
  }

  .@{radioClsPrefix}-inner-checked,
  .@{radioClsPrefix}-input-checked {
    border-color: var(--radio-active-border-color);
    background-color: var(--radio-active-background-color);
    opacity: 0.5;

    &::after {
      background: var(--radio-active-inner-background-color);
      opacity: 0.5;
    }
  }
}

.@{radioClsPrefix}-readOnly-stringMode {
  display: inline-flex;
  flex-direction: row;
  font-size: var(--font-size-12);
  color: var(--main-fc);
  cursor: default;
}

.@{radioClsPrefix}-readOnly-stringMode-readOnlyLineHeightStandard {
  .@{radioClsPrefix}-readOnly-stringMode-item {
    .form-pc-readOnlyLineHeightStandard
  }
}

// tag
.@{radioClsPrefix}-label {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: calc(4 * var(--hd));
  padding-left: calc(4 * var(--hd));

  &.has-tips {
    flex-wrap: nowrap;

    .@{radioClsPrefix}-label-span {
      white-space: unset;
    }
  }

  &.isTag {
    padding-right: calc(10 * var(--hd));
    padding-left: calc(10 * var(--hd));
    margin-left: calc(5 * var(--hd));
    margin-right: calc(5 * var(--hd));

    &.unchecked {
      display: inline-block;
      margin-right: calc(7 * var(--hd));
      padding-top: calc(5 * var(--hd));
      padding-bottom: calc(5 * var(--hd));
    }
  }

  &-span {
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    // line-height: 1;
  }

  &-disabled.unchecked {
    .@{radioClsPrefix}-label-span {
      color: var(--radio-disabled-font-color);
    }
  }

  // &-readOnly {
  //   .@{radioClsPrefix}-label-span-checked {
  //     color: var(--main-fc)
  //   }
  // }
}

// image radio
.@{radioClsPrefix}-image {
  &-wrapper {
    width: calc(120 * var(--hd));
    height: calc(120 * var(--hd));
    background: var(--bg-white);
    border: var(--hd) solid var(--border-color);
    border-radius: calc(3 * var(--hd));
    display: inline-table;
    margin: calc(10 * var(--hd));
  }

  &-vertical {
    display: block;
  }

  &-vertical &-wrapper {
    display: block;
  }

  &-box img {
    width: 100%;
  }

  &-footer {
    padding: calc(9 * var(--hd)) calc(8 * var(--hd));
  }
}

.@{radioClsPrefix}-image-wrapper {
  .@{radioClsPrefix}-wrapper {
    display: flex;
    margin: 0;
  }

  .@{radioClsPrefix}-label {
    width: calc((120 * var(--hd)) - calc(34 * var(--hd)));
  }

  .@{radioClsPrefix}-label-span {
    width: 100%;
  }
}
