@import './animation.less';
@import '../rc-cascader/style/index.less';
@import './multiple/index.less';
@import './city-picker.less';

.@{cascaderClsPrefix} {

  // .reset-component();
  &-input.@{cascaderClsPrefix}-input {
    // Keep it static for https://github.com/ant-design/ant-design/issues/16738
    // position: static;
    position: relative;
    width: 100%;
    // https://github.com/ant-design/ant-design/issues/17582
    padding-right: calc(30 * var(--hd));
    // Add important to fix https://github.com/ant-design/ant-design/issues/5078
    // because input.less will compile after cascader.less
    background-color: var(--bg-transparent);
    cursor: pointer;
  }

  &-picker-show-search &-input.@{cascaderClsPrefix}-input {
    position: relative;
  }
  &-input{
    &.picker-input-mirror{
      padding-left: calc( 30 * var(--hd));
      padding-right: calc( 8 * var(--hd));
    }
  }
  &-picker {
    // .reset-component();

    min-width: calc(140 * var(--hd));
    max-width: calc(200 * var(--hd));
    width: 100%;
    position: relative;
    display: inline-block;
    background-color: #fff;
    border-radius: var(--border-radius-xs);
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    font-size: var(--font-size-12);
    color: var(--main-fc);

    &-with-value &-label {
      color: transparent;
    }

    &-disabled {
      color: var(--invalid);
      background: var(--label-bc);
      cursor: not-allowed;

      .@{cascaderClsPrefix}-input {
        cursor: not-allowed;
      }
    }

    &-readOnly {
      background: none;
      cursor: unset;
      height: calc(30 * var(--hd));
      line-height: calc(30 * var(--hd));
    }

    &-readOnly-selectedWrap {
      .selectedWrap-readyOnly
    }

    &-readOnly-ellipsis {
      .selectedWrap-ellipsis
    }

    &-readOnly-readOnlyLineHeightStandard {
      .form-pc-readOnlyLineHeightStandard
    }

    &:focus .@{cascaderClsPrefix}-input {
      // .active();
    }

    &-borderless .@{cascaderClsPrefix}-input {
      border-color: transparent !important;
      box-shadow: none !important;
    }

    // &-show-search,
    // &-focused {
    //   color: fade(#000, 25%);
    // }

    &-label {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: calc(20 * var(--hd));
      margin-top: calc(-10 * var(--hd));
      padding: 0 calc(26 * var(--hd)) 0 calc(12 * var(--hd));
      overflow: hidden;
      line-height: calc(20 * var(--hd));
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-clear {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: calc(12 * var(--hd));
      z-index: 2;
      background: var(--bg-white);
      cursor: pointer;
      opacity: 0;
      color: var(--invalid-fc);
      transition: color 0.3s ease, opacity 0.15s ease;

      &:hover {
        color: var(--secondary-fc);
      }
    }

    &:hover &-clear {
      opacity: 1;
    }

    // arrow
    &-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: calc(12 * var(--hd));
      z-index: 1;
      color: var(--secondary-fc);
      font-size: var(--font-size-12);
    }
  }

  &-picker-label{
    &.picker-label-mirror{
      padding: 0 calc( 12 * var(--hd)) 0 calc( 26 * var(--hd));
    }
  }
  // https://github.com/ant-design/ant-design/pull/12407#issuecomment-424657810
  &-picker-label:hover+&-input {
    &:not(.@{cascaderClsPrefix}-picker-disabled &) {
      // .hover();
      border-color: var(--input-hover-border-color);
    }
  }

  &-picker-small &-picker-clear,
  &-picker-small &-picker-arrow {
    right: calc(8 * var(--hd));
  }

  &-menus {
    position: absolute;
    z-index: var(--progress-zindex);
    font-size: var(--font-size-12);
    white-space: nowrap;
    background: var(--bg-white);
    border: var(--border-solid);
    box-shadow: var(--box-shadow-storke);
    border-radius: var(--border-radius-xs);

    ul,
    ol {
      margin: 0;
      list-style: none;
    }

    &>div {
      display: flex;
    }

    &-empty,
    &-hidden {
      display: none;
    }

    &.@{cascaderClsPrefix}-slide-up-enter.@{cascaderClsPrefix}-slide-up-enter-active&-placement-bottomLeft,
    &.@{cascaderClsPrefix}-slide-up-appear.@{cascaderClsPrefix}-slide-up-appear-active&-placement-bottomLeft {
      animation-name: weappSlideUpIn;
    }

    &.@{cascaderClsPrefix}-slide-up-enter.@{cascaderClsPrefix}-slide-up-enter-active&-placement-topLeft,
    &.@{cascaderClsPrefix}-slide-up-appear.@{cascaderClsPrefix}-slide-up-appear-active&-placement-topLeft {
      animation-name: weappSlideDownIn;
    }

    &.@{cascaderClsPrefix}-slide-up-leave.@{cascaderClsPrefix}-slide-up-leave-active&-placement-bottomLeft {
      animation-name: weappSlideUpOut;
    }

    &.@{cascaderClsPrefix}-slide-up-leave.@{cascaderClsPrefix}-slide-up-leave-active&-placement-topLeft {
      animation-name: weappSlideDownOut;
    }
  }

  &-menu {
    display: inline-block;
    min-width: calc(140 * var(--hd));
    // min-width: 111px;
    height: calc(196 * var(--hd));
    margin: 0;
    padding: calc(4 * var(--hd)) 0;
    overflow: auto;
    vertical-align: top;
    list-style: none;
    border-right: var(--border-solid);
    // -ms-overflow-style: auto; // https://github.com/ant-design/ant-design/issues/11857
    -ms-overflow-style: auto;

    &::-webkit-scrollbar {
      width: 0;
    }


    &:first-child {
      border-radius: var(--border-radius-xs) 0 0 var(--border-radius-xs);
    }

    &:last-child {
      margin-right: calc(-1 * var(--hd));
      border-right-color: transparent;
      border-radius: 0 var(--border-radius-xs) var(--border-radius-xs) 0;
    }

    &:only-child {
      border-radius: var(--border-radius-xs);
    }
  }

  &-menu-item {
    padding: calc(5 * var(--hd)) calc(12 * var(--hd));
    overflow: hidden;
    line-height: calc(20 * var(--hd));
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: var(--label-bc);
    }

    &-disabled {
      color: var(--invalid-bc);
      cursor: not-allowed;

      &:hover {
        background: var(--bg-transparent);
      }
    }

    .@{cascaderClsPrefix}-menu-empty & {
      color: var(--invalid-bc);
      cursor: default;
      pointer-events: none;
    }

    &-active:not(&-disabled) {

      &,
      &:hover {
        background-color: var(--label-bc);
      }
    }

    &-expand {
      position: relative;
      padding-right: calc(24 * var(--hd));
    }

    &-expand &-expand-icon,
    &-loading-icon {
      position: absolute;
      right: calc(12 * var(--hd));
      color: var(--regular-fc);
      font-size: var(--font-size-10);

      .@{cascaderClsPrefix}-menu-item-disabled& {
        color: var(--invalid-bc);
      }
    }

    & &-keyword {
      color: #F5222D;
    }
  }

  &-dropdown {
    z-index: var(--progress-zindex);
  }
}