.@{mVideoClsPrefix} {
  // width: 100%;

  &-dialog {
    background: none;

    .@{dialogClsPrefix}-body {
      // background-color: var(--base-black);
      padding: 0;
      display: flex;
      justify-content: center;
    }

    .@{dialogClsPrefix}-content {
      transform: translate(-50%, -50%) !important;
      left: 50%;
      width: auto !important;
      background: var(--bg-transparent);
      display: inline-block;
    }
  }

  &-width {
    width: 100%;
  }

  &-height {
    height: 100%;
  }

  &-video {
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-video-rtl,
  &-rtl {
    transform: scaleX(-1);
  }
}
