.@{tagClsPrefix} {
  display: inline-block;
  position: relative;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-image: none;
  font-size: var(--tag-font-size);
  font-weight: var(--font-weight-base);
  border: var(--tag-border);
  border-radius: var(--border-radius-xs);
  padding: 0 var(--tag-padding-x);
  transition: var(--transition-all);
  box-sizing: border-box;
  line-height: 0;

  &.ui-tag-normal{
    padding: 0;
  }

  &:hover {
    .@{tagClsPrefix}-close {
      display: flex;
    }
  }

  &-text {
    line-height: 1;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-close {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(-4 * var(--hd));
    right: calc(-4 * var(--hd));
    color: var(--invalid-fc);
    cursor: pointer;
    border-radius: 50%;

    &-icon {
      width: 100%;
      height: 100%;
      background: var(--invalid-fc);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: calc(2 * var(--hd));

      &:hover {
        background: var(--secondary-fc);
      }

    }

    .ui-icon-svg {
      width: calc(12 * var(--hd));
      height: calc(12 * var(--hd));
      color: var(--tag-solid-font-color);
    }
  }

  &.@{tagClsPrefix}-small {
    height: var(--tag-size-sm);
    display: inline-flex;
    align-items: center;
  }

  &.@{tagClsPrefix}-medium {
    height: var(--tag-size-md);
    display: inline-flex;
    align-items: center;
  }

  &.@{tagClsPrefix}-large {
    height: var(--tag-size-lg);
    display: inline-flex;
    align-items: center;
  }

  &.@{tagClsPrefix}-radius {
    border-radius: var(--border-radius-lg);
  }

  &.@{tagClsPrefix}-default {
    background-color: var(--tag-default-bg-color);
    border-color: var(--tag-default-border-color);
    color: var(--tag-default-font-color);
  }

  &.@{tagClsPrefix}-primary {
    background-color: var(--tag-primary-bg-color);
    border-color: var(--tag-primary-border-color);
    color: var(--tag-primary-font-color);
  }

  &.@{tagClsPrefix}-success {
    background-color: var(--tag-success-bg-color);
    border-color: var(--tag-success-border-color);
    color: var(--tag-success-font-color);
  }

  &.@{tagClsPrefix}-warning {
    background-color: var(--tag-warning-bg-color);
    border-color: var(--tag-warning-border-color);
    color: var(--tag-warning-font-color);
  }

  &.@{tagClsPrefix}-danger {
    background-color: var(--tag-danger-bg-color);
    border-color: var(--tag-danger-border-color);
    color: var(--tag-danger-font-color);
  }

  &.@{tagClsPrefix}-default-solid,
  &.@{tagClsPrefix}-primary-solid,
  &.@{tagClsPrefix}-success-solid,
  &.@{tagClsPrefix}-warning-solid,
  &.@{tagClsPrefix}-danger-solid {
    border: 0;
  }

  &.@{tagClsPrefix}-default-solid {
    background-color: var(--invalid-bc);
    color: var(--tag-solid-font-color);
  }

  &.@{tagClsPrefix}-primary-solid {
    background-color: var(--tag-primary-font-color);
    color: var(--tag-solid-font-color);
  }

  &.@{tagClsPrefix}-success-solid {
    background-color: var(--success);
    color: var(--tag-solid-font-color);
  }

  &.@{tagClsPrefix}-warning-solid {
    background-color: var(--warning);
    color: var(--tag-solid-font-color);
  }

  &.@{tagClsPrefix}-danger-solid {
    background-color: var(--danger);
    color: var(--tag-solid-font-color);
  }

  &.@{tagClsPrefix}-disabled {
    pointer-events: none;
    opacity: var(--tag-disabled-opacity);
  }
}