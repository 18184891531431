.@{pickerViewClsPrefix} {
  width: 100%;

  &-container {
    z-index: 1;
    font-size: var(--font-size-12);
    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
  }

  &-column {
    flex: 1;
    text-align: center;
    &.isIE {
      flex: 1 1 auto;
    }
    &:not(:last-child) {
      border-right: calc(1 * var(--hd)) solid var(--diviling-line-color);
    }
  }
  
  &-scroller {
    transition: all 300ms ease-out;
  }

  &-item {
    position: relative;
    white-space: nowrap;
    color: var(--main-fc);
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover:not(&-disabled) {
      background: var(--label-bc);
      cursor: pointer;
    }

    &-selected:not(&-disabled) {
      background: var(--label-bc);
      color: var(--primary)
    }

    &-disabled {
      cursor: not-allowed;
      color: var(--placeholder-fc);
    }
  }
}