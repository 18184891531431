.@{mTreeClsPrefix} {
  color: #323233;
  font-size: var(--font-size-12);
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: white;
  ul {
    padding: 0;
  }
  .tree-node-loading-wrapper {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    background-color: #edf4ff;
    .tree-node-loading {
      border-color: #155bd4;
      display: inline-block;
      height: calc(10 * var(--hd));
      width: calc(10 * var(--hd));
      border-radius: 50%;
      margin-right: calc(10 * var(--hd));
      border-width: var(--hd);
      border-style: solid;
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        height: calc(10 * var(--hd));
        width: calc(4 * var(--hd));
        background-color: #edf4ff;
        animation: 2s linear infinite;
        transform-origin: calc(10 * var(--hd)) calc(4 * var(--hd));
        left: -1px;
        top: calc(6 * var(--hd));
      }
    }
  }
  &-rtl-content {
    display: inline-flex !important;
  }
  &-rtl-search-content {
    transform: scaleX(1) !important;
  }
  &-rtl-search-high-light-content {
    display: inline;
  }

}
.@{mTreeClsPrefix} ul,
.@{mTreeClsPrefix} li {
  white-space: nowrap;
  list-style: none;
}
.@{mTreeClsPrefix}-bar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: calc(10 * var(--hd));
  &-switcher-warp {
    width: calc(16 * var(--hd));
    display: inline-block;
    .switcher {
      margin-bottom: calc(2 * var(--hd));
      transition: transform 0.3s ease;
      transform: rotate(90deg);
      color: #999999;
      cursor: pointer;
      .Icon-Right-arrow04 {
        width: calc(16 * var(--hd));
        height: calc(16 * var(--hd));
      }
    }
  }
  &.off {
    .switcher {
      transform: rotate(0deg);
    }
  }
  &.isSelected {
    background-color: #f5f5f5;
  }
  &:hover {
    .@{mTreeClsPrefix}-node {
      .operation {
        opacity: 0.8;
      }
    }
  }
  .directory-icon {
    position: absolute;
    left: calc(-18 * var(--hd));
    width: calc(16 * var(--hd));
    height: calc(16 * var(--hd));
    line-height: calc(28 * var(--hd));
    display: inline-block;
    vertical-align: middle;
    border: 0 none;
    cursor: pointer;
    outline: none;
  }
  .@{mTreeClsPrefix}-node {
    display: flex;
    cursor: pointer;
    width: 100%;
    padding-right: calc(10 * var(--hd));
    border-bottom: 1px solid #f4f3f3;
    line-height: calc(44 * var(--hd));
    height: calc(44 * var(--hd));
    &-content {
      padding: 0 calc(10 * var(--hd)) 0 calc(4 * var(--hd));
      display: inline-block;
      border: 1px solid transparent;
      border-radius: calc(4 * var(--hd));
      background-color: var(--bg-transparent);
      transition: all 0.3s ease-in-out;
      margin-left: calc(3 * var(--hd));
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      vertical-align: bottom;
      color: #333333;
      .__high-light {
        color: #4ba9f2;
      }
    }
    &-icon {
      margin: 0 calc(4 * var(--hd)) calc(4 * var(--hd));
    }
    .operation {
      display: inline-block;
      margin-left: calc(10 * var(--hd));
      opacity: 0;
      font-size: 0.8em;
      color: black;
      transition: opacity 0.3s ease-in;
      & > span {
        &.opt {
          &:not(:last-of-type) {
            border-left-color: #999999;
            margin-right: calc(8 * var(--hd));
            padding-right: calc(8 * var(--hd));
            border-left-width: calc(2 * var(--hd));
            border-left-style: solid;
          }
        }
      }
    }
  }
  .@{mTreeClsPrefix}-node-directory {
    margin-left: calc(5 * var(--hd));
  }
  .@{mTreeClsPrefix}-node-right {
    flex-direction: row-reverse;
    margin-left: calc(5 * var(--hd));
    align-items: center;
    .ui-m-checkbox {
      padding: calc(4 * var(--hd)) 0;
      background-color: unset;
      .ui-m-checkbox-inner-left {
        margin-right: 0;
      }
    }
    .checkbox-hidden {
      visibility: hidden;
    }
  }
  &:hover {
    background-color: #edf4ff;
  }
  .@{mTreeClsPrefix}-checkbox-wrap {
    margin-top: calc(-5 * var(--hd));
  }
}
.@{mTreeClsPrefix}-child {
  overflow: hidden;
  &-spin {
    height: calc(44 * var(--hd));
  }
  &-complete, &-loadmore {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(44 * var(--hd));
    margin: 0;
  }
  &-complete {
    color: var(--invalid);
  }
  &-loadmore {
    cursor: pointer;
    color: var(--primary);
  }
}
.@{mTreeClsPrefix}-search {
  margin:calc(10 * var(--hd)) 0;
  display: flex;
  align-items: center;
  &-label {
    display: inline-block;
    min-width: calc(40 * var(--hd));
  }
}
.@{mTreeClsPrefix}-empty {
  font-size: var(--font-size-12);
  color: var(--main-fc);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .Icon-No-data-available {
    width: calc(80 * var(--hd));
    height: calc(80 * var(--hd));
    margin-bottom: calc(10 * var(--hd));
    margin-top: calc(30 * var(--hd));;
  }
  &-label {
    text-align: center;
    &-text {
      font-size: var(--font-size-14);
      color: var(--regular-fc);
    }
    &-change {
      padding-top: calc(10 * var(--hd));
      color: var(--secondary-fc);
    }
  }
}
