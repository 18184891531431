.@{uploadClsPrefix}-inside {
  height: 100%;
}

.@{uploadClsPrefix}-inside-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 calc(6 * var(--hd));

  //&-search-bar,
  //&-header-bar {
  //  padding: 0 calc(16 * var(--hd));
  //}

  //&-content {
  //padding: 0 calc(6 * var(--hd)) 0 calc(16 * var(--hd));
  //}

  // 顶部搜索栏目
  &-search-bar {
    width: 100%;
    // padding: 0 calc(16 * var(--hd));
  }

  &-header-bar {
    display: flex;
    justify-content: space-between;
    // padding: calc(10 * var(--hd)) calc(16 * var(--hd)) 0 calc(16 * var(--hd));
    padding: calc(10 * var(--hd)) calc(6 * var(--hd)) 0;
    //margin: calc(10 * var(--hd)) 0;

    .left-coms {
      width: 100%;
    }

    .right-coms {

      .@{uploadClsPrefix}-batch-operation,
      .@{uploadClsPrefix}-select-box {
        margin-left: calc(16 * var(--hd));
      }
    }

    .left-coms,
    .right-coms {
      display: flex;
      align-items: center;
    }

    .@{uploadClsPrefix}-batch-operation-menu {
      margin: 0;

      .@{menuClsPrefix}-select-title {
        padding: 0;
      }

      .single-btn {
        margin: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.no-text {
          .single-icon {
            margin: 0;
          }
        }
      }
    }

    .@{uploadClsPrefix}-select-title {
      margin-left: calc(4 * var(--hd));
    }

    .@{uploadClsPrefix}-inside-panel-uploader {
      display: flex;
      align-items: center;
    }
  }

  // 列表区域
  &-content {
    height: 100%;
    flex: 1 1 auto;
    overflow: auto;

    .ui-scroller {}

    &-scroller {
      height: 100%;
    }

    &-scroller__view {
      padding-top: calc(10 * var(--hd));
    }

    &.is-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .@{uploadClsPrefix}-list {
      height: auto;
    }

    // 已选列表
    .@{uploadClsPrefix}-listT-item {
      display: flex;
      align-items: flex-start;
      padding: calc(0 * var(--hd)) calc(6 * var(--hd)) calc(0 * var(--hd)) calc(12 * var(--hd));
      min-height: calc(35 * var(--hd));
      margin-bottom: 0;

      &.is-selected {
        background-color: var(--label-bc);
        // &::before {
        //   content: '';
        //   transition: var(--transition-all);
        //   // opacity: 0.1;
        //   // background: var(--primary);
        //   background: var(--label-bc);
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   right: 0;
        //   bottom: 0;
        //   margin: auto;
        // }

        // &:hover {
        //   background: transparent;
        // }

        .@{uploadClsPrefix}-listT-item-body {
          z-index: 1;
        }
      }

      &-body {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        overflow: hidden;
        align-items: flex-start;
        // padding: calc(8 * var(--hd)) 0;


        .@{uploadClsPrefix}-listT-item-icon {
          &.ui-icon {
            margin: calc(2 * var(--hd));
          }
        }
      }

      &-append {
        // margin-top: calc(4 * var(--hd));
        margin-top: calc(6 * var(--hd));
      }

      .@{uploadClsPrefix}-listT-item-icon {
        display: flex;

        &.ui-icon {
          // margin: 0;
          // margin-top: calc(8 * var(--hd));
          margin-top: calc(10 * var(--hd));
        }
      }

      &-badge {
        position: absolute;
        left: 0;
        top: calc(2 * var(--hd));

        &.is-sortable {
          left: calc(22 * var(--hd));
        }
      }

      &.is-sortable {
        .@{uploadClsPrefix}-listT-item-file-icon {
          margin-left: calc(6 * var(--hd));
        }

      }

      .@{uploadClsPrefix}-listT-item-filename {
        overflow: hidden;
        text-overflow: ellipsis;
        // margin: calc(6 * var(--hd)) 0 calc(6 * var(--hd)) calc(6 * var(--hd));
        margin: calc(7 * var(--hd)) 0 calc(7 * var(--hd)) calc(6 * var(--hd));
        // margin-top: 0;
        // 两行展示
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        &.is-IE {
          white-space: nowrap;
        }
      }

      &-more {
        width: calc(24 * var(--hd));
        height: calc(24 * var(--hd));
        border-radius: calc(3 * var(--hd));
        display: none;
        align-items: center;
        justify-content: center;
        &:hover {
          background: #ebebeb;
          color: var(--secondary-fc);
        }
      }
    }

    .@{uploadClsPrefix}-listT-item-hover {
      // &.is-selected {
        // .@{uploadClsPrefix}-listT-item-more {
          // background: transparent;

          // &::before {
          //   content: '';
          //   transition: var(--transition-all);
          //   // opacity: 0.1;
          //   // background: var(--primary);
          //   background: var(--label-bc);
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   right: 0;
          //   bottom: 0;
          //   margin: auto;
          // }
        // }
      // }

      .@{uploadClsPrefix}-listT-item {
        &-more {
          display: flex;
        }
      }
    }

    // 待选列表
    .@{uploadClsPrefix}-listB-item {
      height: 100%;
      padding: 0 calc(6 * var(--hd)) 0 calc(16 * var(--hd));

      &-unsortable {
        padding: 0 calc(6 * var(--hd)) 0 calc(36 * var(--hd));
      }
    }
  }
}