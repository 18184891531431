@disabledColor: #ccc;
@border-radius-base: var(--border-radius-xs);
@primary-color: var(--primary);
@tooltip-color: #fff;
@tooltip-bg: tint(#666, 4%);
@tooltip-arrow-width: 4px;
@tooltip-distance: @tooltip-arrow-width+4;
@tooltip-arrow-color: @tooltip-bg;
@ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
@ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);

.borderBox() {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(
    0,
    0,
    0,
    0
  ); //  remove tap highlight color for mobile safari

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(
      0,
      0,
      0,
      0
    ); //  remove tap highlight color for mobile safari
  }
}

.@{sliderClsPrefix} {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: @border-radius-base;
  touch-action: none;
  .borderBox();

  &-show-percent {
    width: 90%;
    &-content {
      width: 10%;
      position: absolute;
      right: -10%;
      top: 0;
      text-align: center;
      font-size: var(--font-size-12);
    }
  }

  &-rail {
    position: absolute;
    width: 100%;
    background-color: var(--slider-bg);
    height: 4px;
    border-radius: @border-radius-base;
  }

  &-track {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: @border-radius-base;
    background-color: @primary-color;
  }

  &-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-top: -5px;
    border-radius: 50%;
    border: solid 2px @primary-color;
    background-color: #fff;
    touch-action: pan-x;

    &-dragging&-dragging&-dragging {
      border-color: @primary-color;
      box-shadow: 0 0 0 5px @primary-color;
    }

    &:focus {
      outline: none;
    }

    &-click-focused:focus {
      border-color: @primary-color;
      box-shadow: unset;
    }

    &:hover {
      border-color: @primary-color;
    }

    &:active {
      border-color: @primary-color;
      box-shadow: 0 0 5px @primary-color;
      cursor: -webkit-grabbing;
      cursor: grabbing;
    }
  }

  &-handle-showTip {
    display: none;
    position: absolute;
    top: -37px;
    left: -8px;
    width: 27px;
    height: 27px;
    line-height: 27px;
    background: #303133;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    &-active {
      display: block;
    }
    .arrow-down {
      position: absolute;
      top: 27px;
      left: 9px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 6px solid #303133;
    }
  }

  &-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
  }

  &-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #999;

    &-active {
      color: #666;
    }
  }

  &-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: var(--transparent);
  }

  &-dot {
    position: absolute;
    bottom: -2px;
    margin-left: -4px;
    width: 8px;
    height: 8px;
    border: 2px solid var(--slider-bg);
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;

    &-active {
      border-color: @primary-color;
    }

    &-reverse {
      margin-right: -4px;
    }
  }

  &-disabled {
    background-color: var(--slider-bg);

    .@{sliderClsPrefix}-track {
      background-color: @disabledColor;
    }

    .@{sliderClsPrefix}-handle,
    .@{sliderClsPrefix}-dot {
      border-color: @disabledColor;
      box-shadow: none;
      background-color: #fff;
      cursor: not-allowed;
    }

    .@{sliderClsPrefix}-mark-text,
    .@{sliderClsPrefix}-dot {
      cursor: not-allowed !important;
    }
  }
  &-readOnly {
    background-color: var(--slider-bg);

    .@{sliderClsPrefix}-track {
      background-color: @disabledColor;
    }

    .@{sliderClsPrefix}-handle,
    .@{sliderClsPrefix}-dot {
      border-color: @disabledColor;
      box-shadow: none;
      background-color: #fff;
      cursor: default;
    }

    .@{sliderClsPrefix}-mark-text,
    .@{sliderClsPrefix}-dot {
      cursor: default !important;
    }
  }
  &-progress-text {
    position: absolute;
    top: 15px;
  }
}

.@{sliderClsPrefix}-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;

  .@{sliderClsPrefix} {
    &-rail {
      height: 100%;
      width: 4px;
    }

    &-track {
      left: 5px;
      bottom: 0;
      width: 4px;
    }

    &-handle {
      margin-left: -5px;
      touch-action: pan-y;
    }

    &-mark {
      top: 0;
      left: 18px;
      height: 100%;
    }

    &-step {
      height: 100%;
      width: 4px;
    }

    &-dot {
      left: 2px;
      margin-bottom: -4px;

      &:first-child {
        margin-bottom: -4px;
      }

      &:last-child {
        margin-bottom: -4px;
      }
    }
  }
}

.motion-common() {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
}

.make-motion(@className, @keyframeName) {
  .@{className}-enter,
  .@{className}-appear {
    .motion-common();
    animation-play-state: paused;
  }

  .@{className}-leave {
    .motion-common();
    animation-play-state: paused;
  }

  .@{className}-enter.@{className}-enter-active,
  .@{className}-appear.@{className}-appear-active {
    animation-name: ~"@{keyframeName}In";
    animation-play-state: running;
  }

  .@{className}-leave.@{className}-leave-active {
    animation-name: ~"@{keyframeName}Out";
    animation-play-state: running;
  }
}

.zoom-motion(@className, @keyframeName) {
  .make-motion(@className, @keyframeName);

  .@{className}-enter,
  .@{className}-appear {
    transform: scale(0, 0); // need this by yiminghe
    animation-timing-function: @ease-out-quint;
  }

  .@{className}-leave {
    animation-timing-function: @ease-in-quint;
  }
}

.zoom-motion(rc-slider-tooltip-zoom-down, rcSliderTooltipZoomDown);

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }

  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}

.@{sliderClsPrefix}-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;

  .borderBox();

  &-hidden {
    display: none;
  }

  &-placement-top {
    padding: @tooltip-arrow-width 0 @tooltip-distance 0;
  }

  &-inner {
    padding: 6px 2px;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 1;
    color: @tooltip-color;
    text-align: center;
    text-decoration: none;
    background-color: @tooltip-bg;
    border-radius: @border-radius-base;
    box-shadow: 0 0 4px #d9d9d9;
  }

  &-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  &-placement-top &-arrow {
    bottom: @tooltip-distance - @tooltip-arrow-width;
    left: 50%;
    margin-left: -@tooltip-arrow-width;
    border-width: @tooltip-arrow-width @tooltip-arrow-width 0;
    border-top-color: @tooltip-arrow-color;
  }
}

//镜像样式
.@{sliderClsPrefix}-range.@{sliderClsPrefix}-rtl.@{sliderClsPrefix}-horizontal{
  transform: scaleX(-1);
  direction: rtl;
}

.@{sliderClsPrefix}-slider.@{sliderClsPrefix}-rtl.@{sliderClsPrefix}-horizontal {
  .@{sliderClsPrefix}-mark-text {
    transform: scaleX(-1) translateX(-50%) !important;
  }
}