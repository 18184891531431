.@{mPickerViewClsPrefix} {
  &-container {
    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 0 calc(20 * var(--hd));
    font-size: 1em;
    -webkit-mask-box-image: linear-gradient(to top, transparent, transparent 5%, white 20%, white 80%, transparent 95%, transparent);
    //修复ios16.4 -webkit-mask-box-image 的to top计算偏差问题
    mask-image: linear-gradient(to top, transparent, transparent 5%, white 20%, white 80%, transparent 95%, transparent);
  }

  &-column {
    flex: 1;
    max-height: 100%;
    text-align: center;
    overflow: hidden;
  }

  &-column-native {
    overflow: auto;
    height: 100%;
  }

  &-scroller {
    transition: 300ms;
    transition-timing-function: ease-out;
  }

  &-scroller-native {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-item {
    position: relative;
    white-space: nowrap;
    color: var(--secondary-fc);
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;

    &-selected:not(&-disabled) {
      color: var(--main-fc);
    }

    &-disabled {
      cursor: not-allowed;
      color: var(--placeholder-fc);
    }
  }

  &-highlight {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before,
    &::after {
      content: ' ';
      position: absolute;
      left: 0;
      right: auto;
      display: block;
      width: 100%;
      height: var(--hd);
      background-color: var(--border-color);
      transform: scaleY(0.3);
    }

    &:before {
      top: 0;
      bottom: auto;
    }

    &:after {
      bottom: 0;
      top: auto;
    }
  }
}