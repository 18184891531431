.@{messageClsPrefix} {
  top: calc(35 * var(--hd));
  position: fixed;
  z-index: var(--toast-zindex);
  animation-name: MessageMoveIn;
  animation-duration: 0.3s;
  max-width: 50%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: var(--dialog-message-zindex);

  &-single {
    &-show {
      animation-name: MessageSingleMoveIn;
      animation-duration: 0.3s;
    }
    padding-top: var(--v-spacing-md);
    &:first-child {
      padding-top: 0;
      animation: none;
    }
  }

  &-content {
    box-shadow: var(--dialog-box-shadow);
    text-align: center;
    padding: var(--v-spacing-md) var(--h-spacing-lg) var(--v-spacing-md) var(--v-spacing-md);
    border-radius: var(--border-radius-sm);
    background-color: var(--bg-white);
    position: relative;
    display: inline-flex;
    letter-spacing: calc(1.5 * var(--hd));
    line-height: 1.2;
    font-size: var(--font-size-sm);

    &:hover {
      .@{messageClsPrefix}-custom-close {
        display: block;
      }
    }
  }

  &-icon {
    color: var(--message-info-icon);
    align-items: center;
    transform: translateY(calc(-0.5 * var(--hd)));
    height: 1.2em;
  }

  &-icon.ui-icon {
    display: flex;
  }

  &-error-icon {
    color: var(--message-error-icon);
  }

  &-success-icon {
    color: var(--message-success-icon);
  }

  &-custom-close {
    position: absolute;
    top: calc(-1 * var(--icon-size-sm) / 2);
    right: calc(var(--icon-size-sm) / 2);
    cursor: pointer;
    display: none;

    .@{iconClsPrefix} {
      color: var(--secondary-fc);
      position: absolute;
      top: calc(2 * var(--hd));
      left: calc(2.3 * var(--hd));
    }
  }

  &-body {
    display: inline-block;
    padding-left: var(--h-spacing-md);
    color: var(--regular-fc);
    vertical-align: middle;
    word-break: break-word;
  }

  &-mobile {
    max-width: 100%;
    left: auto;
    transform: unset;
  }
}

@keyframes MessageMoveIn {
  0% {
    opacity: 0;
    top: 0;
  }

  100% {
    top: calc(35 * var(--hd));
    opacity: 1;
  }
}


@keyframes MessageSingleMoveIn {
  0% {
    padding: 0;
    opacity: 0;
  }

  to {
    padding-top: var(--v-spacing-md);
    opacity: 1;
  }
}

//移动端样式处理
.@{messageClsPrefix}-mobile {
  width: 100%;

  .@{messageClsPrefix}-body {
    padding-left: calc(3 * var(--hd));
  }
}

