/*********** SensitiveWordsDialog  ***********/

.@{mUploadClsPrefix}-sensitiveWords-table-words{ 
 color: var(--danger);
}
.@{mUploadClsPrefix}-sensitiveWords-content {
    padding: calc(20 * var(--hd)) calc(20 * var(--hd)) calc(70 * var(--hd)) calc(20 * var(--hd));
    overflow: auto;
    height: 100%;
  &-info {
    border: var(--hd) solid var(--warning);
    background: rgba(250, 173, 20, 0.2);
    padding: calc(10 * var(--hd));
    display: flex;
    align-items: center;
    &-icon {
      color: var(--warning);
      margin-right: calc(10 * var(--hd));
    }
    &-words {
      line-height: 1.5;
      color: var(--warning);
    }
  }
  &-footer {  
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    &.border-solid-then:after {
      top: 0;
    }
    .is-ok-btn {
      height: calc(50 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.@{mUploadClsPrefix}-sensitiveWords-list-item {
  font-size: var(--font-size-12);
  padding: calc(10 * var(--hd));
  border: var(--hd) solid var(--diviling-line-color);
  margin-top: calc(20 * var(--hd));
  &-top {
    display: flex;
    padding-bottom: calc(10 * var(--hd));
    border-bottom: var(--hd) solid var(--diviling-line-color);
  }
  &-t-l {
    margin-right: calc(10 * var(--hd));
    &-id {
      width: calc(40 * var(--hd));
      height: calc(40 * var(--hd));
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: var(--invalid);
      color: var(--base-white);
    }
  }
  &-t-r {
    &-label {
      color: var(--regular-fc);
      margin-bottom: calc(4 * var(--hd));
    }
  }
  &-bottom {
    padding-top: calc(10 * var(--hd));
  }
  &-b {
    &-label {
      margin-bottom: calc(6 * var(--hd));
    }
    &-sensitiveWords {
      color: var(--danger);
    }
  }
}