.@{anchorListClsPrefix} {
  padding: 0px calc(10 * var(--hd));;
  position: relative;
  margin-top: calc(10 * var(--hd));;
  transition: margin-top 0.3s;

  &-wrapper {
    position: absolute;
    right: 0;
    margin: calc(10 * var(--hd)) calc(15 * var(--hd)) calc(20 * var(--hd));
    padding: calc(8 * var(--hd)) calc(10 * var(--hd));
    overflow: auto;
    background-color: #fff;
    box-shadow: 0 3px 12px 0 rgba(0,0,0,0.12);
    border-radius: 3px;
    //transition: all .3s;
    cursor: pointer;
  }

  &-no-search {
    margin-top: calc(-10 * var(--hd));;
  }

  &-header {
    .@{inputClsPrefix} {
      min-height: calc(24 * var(--hd));;
      &-wrap {
        width: calc(100 * var(--hd));;
        .@{inputClsPrefix}-suffix {
          color: #999999;
          .@{inputClsPrefix}-clear {
            display: flex;
          }
        }
      }
    }
    &-search-hidden {
      visibility: hidden;
    }
    .Icon-Right-arrow03 {
      margin-left: calc(5 * var(--hd));;
      cursor: pointer;
      color: #999999;
      width: calc(16 * var(--hd));;
      height: calc(16 * var(--hd));;
    }
    &-btn {
      display: inline-block;
      padding: 5px;

      &:hover {
        .Icon-Right-arrow03 {
          color: #1890ff;
        }
      }
    }
  }

  &-ink {
    position: absolute;
    top: 0;
    left: calc(5 * var(--hd));;
    height: 100%;
    &::before {
      position: relative;
      display: block;
      width: var(--hd);
      height: 100%;
      margin: 0 auto;
      background-color: #f0f0f0;
      content: ' ';
    }
    &-ball {
      position: absolute;
      left: 50%;
      display: none;
      width: calc(3 * var(--hd));
      height: calc(8 * var(--hd));
      background-color: var(--primary);
      border: 1.5px solid var(--primary);
      border-radius: calc(8 * var(--hd));
      transform: translateX(-50%);
      transition: top 0.3s ease-in-out;
      &.visible {
        display: inline-block;
      }
    }
  }

  &.fixed &-ink &-ink-ball {
    display: none;
  }

  &-content-link {
    &__active {
      border: 1px solid var(--primary) !important;
    }
  }

  &-link {
    padding: 7px 0 7px 10px;
    line-height: 1.143;
    font-size: var(--font-size-12);

    &-title {
      position: relative;
      display: block;
      margin-bottom: calc(6 * var(--hd));;
      overflow: hidden;
      color: #666666;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: all 0.3s;
      cursor: pointer;
      outline: none;
      text-decoration: none;
      background-color: var(--bg-transparent);

      &:only-child {
        margin-bottom: 0;
      }
    }

    & > &-title:hover {
      color: var(--primary);
    }

    & > &-title {
      color: var(--regular-fc);
    }

    &-active > &-title {
      color: var(--primary);
    }
  }

  &-link &-link {
    padding-top: calc(5 * var(--hd));
    padding-bottom: calc(5 * var(--hd));
  }

  &-collapsed {
    padding: 0;
    cursor: pointer;
    &-btn {
      opacity: 0.5;
      background: #000000;
      color: white;
      border-radius: 3px;
      .@{iconClsPrefix} {
        margin: calc(4 * var(--hd));
      }
    }
    &-btn:hover {
      color: white;
    }
  }
}