.@{uploadClsPrefix}-batch {
  &-input {
    display: inline;
    .ui-input {
      width: calc(156 * var(--hd)) !important;
      height: calc(30 * var(--hd));
    }
  }

  &-operation {
    font-size: var(--font-size-12);
    color: var(--link-fc);
    cursor: pointer;

    // 操作按钮
    .single-btn {
      margin-left: calc(4 * var(--hd));
      margin-top: calc(5 * var(--hd));
      display: flex;
      align-items: center;
      color: var(--link-fc);
    }
    .single-icon {
      margin-right: calc(5 * var(--hd));
    }
    // 操作按钮 - menu 菜单
    &-menu {
      margin-top: calc(-10 * var(--hd));
      margin-left: calc(-10 * var(--hd));
      .@{menuClsPrefix}-select-title-text {
        max-width: 100%;
      }
      &-overlay {
        .@{menuClsPrefix}-list {
          max-width: calc(200 * var(--hd));
        }
      }
    }
    .@{menuClsPrefix}-select-title-icon {
      display: none;
    }
  }

  /*修改*/
  // &-table {
  //   .ui-table-grid-tr .ui-table-grid-selection-checkbox .ui-checkbox {
  //     margin-top: calc(-19 * var(--hd));
  //     // display: none;
  //   }
  //   /*修改*/
  // }
  // table 行
  &-table-item {
    padding: calc(4 * var(--hd)) 0;
    &.is-deleted,
    &.is-undefined {
      filter: grayscale(100%);
    }
    &.is-deleted:not(.is-undefined) {
      .table-item-row {
        &:not(.table-item-row-secondary) {
          .item-span {
            text-decoration: line-through;
          }
        }
      }
    }
    .table-item-row {
      display: flex;
      align-items: center;
      width: 100%;
      .file-icon,
      .item-span {
        margin-right: calc(8 * var(--hd));
        &.main-row {
          display: flex;
          max-width: 100%;
          flex: 1 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .filename {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex: 1 1 auto;
        }
        .filesize,
        .signtip {
          margin-left: calc(10 * var(--hd));
        }
        .signtip {
          color: var(--upload-signtip-color);
        }
      }
      &-secondary {
        color: var(--secondary-fc);
        /*修改*/
        margin-top: calc(-3 * var(--hd));
        /*修改*/
        padding-left: calc(24 * var(--hd));
      }
    }
  }

  // table/dialog 样式复写
  &-dialog {
    .@{titleClsPrefix}-right-container,
    .@{dialogClsPrefix}-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  &-table {
    .@{tableClsPrefix}-grid-tfoot {
      display: none;
    }
    .@{tableClsPrefix}-grid {
      border-color: var(--border-color);
    }
  }

  // 顶部 title 样式
  &-search-bar {
    .ui-input-wrap .ui-input-suffix {
      cursor: pointer;
    }
  }

  // 底部 footer 区域样式
  &-pagation {
    margin-right: calc(10 * var(--hd));
  }
  &-selected {
    font-size: var(--font-size-12);
    color: var(--secondary-fc);
    position: absolute;
    left: calc(16 * var(--hd));
    &-num {
      color: var(--primary);
      margin: 0 calc(4 * var(--hd));
    }
  }
}
