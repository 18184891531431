.@{mTagClsPrefix} {
  display: inline-block;
  position: relative;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-image: none;
  cursor: pointer;
  font-size: var(--m-tag-font-size);
  font-weight: var(--font-weight-base);
  border: var(--m-tag-border);
  border-radius: var(--border-radius-xs);
  padding: var(--m-tag-padding-y-lg) var(--m-tag-padding-x-lg);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;

  &:hover {
    .@{mTagClsPrefix}-close {
      display: flex;
    }
  }

  &-text {
    line-height: 1;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &-close {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(-7 * var(--hd));
    right: calc(-7 * var(--hd));
    color: var(--invalid-fc);
    cursor: pointer;

    &-icon {
      width: 100%;
      height: 100%;
      background: var(--invalid-fc);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: calc(2 * var(--hd));

      &:hover {
        background: var(--secondary-fc);
      }

    }


    .ui-icon-svg {
      width: calc(12 * var(--hd));
      height: calc(12 * var(--hd));
      color: var(--tag-solid-font-color);
    }
  }

  &.@{mTagClsPrefix}-small {
    padding: var(--m-tag-padding-y-xs) var(--m-tag-padding-x-sm);
    font-size: var(--m-tag-font-size-xs);
  }

  &.@{mTagClsPrefix}-medium {
    padding: var(--m-tag-padding-y-sm) var(--m-tag-padding-x-sm);
  }

  &.@{mTagClsPrefix}-large {
    padding: var(--m-tag-padding-y-lg) var(--m-tag-padding-x-lg);
  }

  // 配合表单处理
  &.@{mTagClsPrefix}-normal{
    padding: 0;
  }

  &.@{mTagClsPrefix}-radius {
    border-radius: var(--m-tag-border-radius);
  }

  &.@{mTagClsPrefix}-default {
    background-color: var(--bg-weak);
    border-color: var(--transparent);
    color: var(--main-fc);
  }

  &.@{mTagClsPrefix}-default-medium-checked {
    background-color: var(--m-tag-default-active-bg);
    border-color: var(--m-tag-default-active-border-color);
    color: var(--tag-primary-font-color);
  }

  &.@{mTagClsPrefix}-default-large-checked {
    background-color: var(--tag-solid-font-color);
    border-color: var(--tag-primary-font-color);
    color: var(--tag-primary-font-color);
  }

  &.@{mTagClsPrefix}-disabled {
    pointer-events: none;
    opacity: var(--m-tag-disabled-opacity);
  }
}