.@{triggerClsPrefix}-popupInner {
	position: absolute;
	left:-9999px;
	top:-9999px;
	z-index: var(--popup-zindex);
	&.rtlSafari {
		transform: translateZ(0);
	}
	// overflow: hidden; /* 会影响弹框的阴影效果 */
	&-noAlign {
		position: relative;
		top: auto;
		left: auto;
	}
	&-hidden {
		display: none;
	}
	&-visNone {
		visibility: hidden;
	}
	&-mask {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background-color: var(--mask-bc);
		z-index: var(--popup-zindex);
		&-hide {
			display: none;
		}
		&.rtlSafari {
			transform: translateZ(0);
		}
	}
}
.@{triggerClsPrefix}-popupInner.isIE {
	left: auto;
	top: auto;
	position: relative;
	opacity: 0;
	visibility: hidden;
}
.@{triggerClsPrefix}-popupInner-visible.isIE {
	visibility: visible;
	opacity: 1;
}
.@{triggerClsPrefix}-popupInner.enableFixedPosition {
	position: fixed;
}

.@{triggerClsPrefix} {
	&-childrenClassName {
		position: relative;
	}
	&-slide-up-enter {
		transition: var(--trigger-transtion-height);
		animation: triggerSlideUpIn 0.5s;
		-webkit-animation: triggerSlideUpIn 0.5s;
	}
	&-slide-up-leave {
		transition: var(--trigger-transtion-height);
		animation: triggerSlideUpOut 0.5s;
		-webkit-animation: triggerSlideUpOut 0.5s;
	}
	&-slide-down-enter {
		animation: triggerSlideDownIn 0.5s;
		-webkit-animation: triggerSlideDownIn 0.5s;
	}
	&-slide-down-leave {
		animation: triggerSlideDownOut 0.5s;
		-webkit-animation: triggerSlideDownOut 0.5s;
	}
	&-slide-left-enter {
		animation: triggerSlideLeftIn 0.5s;
		-webkit-animation: triggerSlideLeftIn 0.5s;
	}
	&-slide-left-leave {
		animation: triggerSlideLeftOut 0.5s;
		-webkit-animation: triggerSlideLeftOut 0.5s;
	}
	&-slide-right-enter {
		animation: triggerSlideRightIn 0.5s;
		-webkit-animation: triggerSlideRightIn 0.5s;
	}
	&-slide-right-leave {
		animation: triggerSlideRightOut 0.5s;
		-webkit-animation: triggerSlideRightOut 0.5s;
	}
	&-slider-bottomPopup-enter {
		animation: triggerSlideBottomPopup 0.5s;
		-webkit-animation: triggerSlideBottomPopup 0.5s;
	}
	&-slider-bottomPopup-leave {
		animation: triggerSlideBottomPopupOut 0.5s;
		-webkit-animation: triggerSlideBottomPopupOut 0.5s;
	}
}
@-webkit-keyframes triggerSlideUpIn {
	0% {
		opacity: 0;
		// height: 0;
	}

	100% {
		opacity: 1;
		// height: 1000px;
	}
}

@keyframes triggerSlideUpIn {
	0% {
		opacity: 0;
		// height: 0;
	}

	100% {
		opacity: 1;
		// height: 1000px; // 暂时先处理成1000px
	}
}

@-webkit-keyframes triggerSlideUpOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes triggerSlideUpOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@-webkit-keyframes triggerSlideDownIn {
	0% {
			opacity: 0;
	}

	to {
			opacity: 1
	}
}

@keyframes triggerSlideDownIn {
	0% {
			opacity: 0
	}

	to {
			opacity: 1
	}
}

@-webkit-keyframes triggerSlideDownOut {
	0% {
			opacity: 1
	}

	to {
			opacity: 0
	}
}

@keyframes triggerSlideDownOut {
	0% {
			opacity: 1
	}

	to {
			opacity: 0
	}
}

@-webkit-keyframes triggerSlideLeftIn {
	0% {
			opacity: 0
	}

	to {
			opacity: 1
	}
}

@keyframes triggerSlideLeftIn {
	0% {
			opacity: 0
	}

	to {
			opacity: 1
	}
}

@-webkit-keyframes triggerSlideLeftOut {
	0% {
			opacity: 1
	}

	to {
			opacity: 0
	}
}

@keyframes triggerSlideLeftOut {
	0% {
			opacity: 1
	}

	to {
			opacity: 0
	}
}

@-webkit-keyframes triggerSlideRightIn {
	0% {
			opacity: 0
	}

	to {
			opacity: 1
	}
}

@keyframes triggerSlideRightIn {
	0% {
			opacity: 0
	}

	to {
			opacity: 1
	}
}

@-webkit-keyframes triggerSlideRightOut {
	0% {
			opacity: 1
	}

	to {
			opacity: 0
	}
}

@keyframes triggerSlideRightOut {
	0% {
		opacity: 1
	}

	to {
			opacity: 0
	}
}
@-webkit-keyframes triggerSlideBottomPopup {
	0% {
		opacity: 0;
		// height: 0;
		bottom: -100%;
	}
	100% {
		opacity: 1;
		// height: 100%;
		bottom: 0;
	}
}
@keyframes triggerSlideBottomPopup{
	0% {
		opacity: 0;
		bottom: -100%;
	}
	100% {
		opacity: 1;
		bottom: 0;
	}
}

@-webkit-keyframes triggerSlideBottomPopupOut{
	0% {
		opacity: 1;
		// height: 100%;
		bottom: -100%;
	}
	100% {
		opacity: 0;
		// height: 0;
		bottom: 0;
	}
}

@keyframes triggerSlideBottomPopupOut{
	0% {
		opacity: 1;
		bottom: 0;
	}
	100% {
		opacity: 0;
		bottom: -100%;
	}
}