//外部预览样式
.@{signaturePrefix} {
  &-emptyWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }

  &-emptyIcon {
    display: inline-flex;
    align-items: flex-end;

    svg {
      width: calc(var(--hd) * 30);
      height: calc(var(--hd) * 30);
      color: var(--secondary-fc);
    }
  }

  &-text {
    display: inline-flex;
    align-items: flex-start;
    padding-top: calc(var(--hd) * 10);
    font-size: var(--font-size-12);
    color: var(--secondary-fc);
    max-width: 100%;

    &-span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &-in-form {
    position: relative;
    width: calc(var(--hd) * 100);
    height: calc(var(--hd) * 100);
    // background: var(--bg-base);
    // border: calc(var(--hd) * 1) solid var(--border-color);
    border: calc(var(--hd) * 1) dashed var(--border-color);
    border-radius: 3px;
    cursor: pointer;

    &-review {
      width: calc(var(--hd) * 170);
      height: calc(var(--hd) * 90);
    }

    &:hover {
      border: calc(var(--hd) * 1) dashed var(--primary);
    }

    .@{signaturePrefix}-inform-container {
      height: 100%;
    }
  }

  &-inform-container-delete {
    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      svg {
        width: calc(var(--hd) * 42);
        height: calc(var(--hd) * 42);
        color: var(--invalid);
      }
    }
  }

  &-canvas {
    position: absolute;
    left: 0;
    top: 0;
    margin: auto;
    display: block;
    right: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
  }

  &-disabled {
    opacity: 0.7;
    background: var(--label-bc);

    &:hover {
      border: none;
      border: calc(var(--hd) * 1) dashed var(--border-color);
      cursor: not-allowed;
    }
  }

  &-readOnly {
    opacity: 0.7;
    background: var(--label-bc);

    &:hover {
      border: none;
      border: calc(var(--hd) * 1) dashed var(--border-color);
      cursor: default;
    }
  }

  &-in-manage {
    .@{signaturePrefix}-manage {
      &-container {
        position: relative;
        width: calc(var(--hd) * 500);
        height: calc(var(--hd) * 280);
        border: calc(var(--hd) * 1) solid var(--border-color);

        canvas {
          position: absolute;
        }

        &:hover {
          border: calc(var(--hd) * 1) dashed var(--primary);
        }
      }

      &-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: calc(var(--hd) * 500);

        &-text {
          font-size: var(--font-size-12);
          color: var(--regular-fc);
        }

        >div {
          margin-top: calc(10 * var(--hd));
          margin-left: calc(10 * var(--hd));
        }
      }
    }

    .@{signaturePrefix}-manage-container-delete {
      display: flex;
      align-items: center;
      justify-content: center;

      &-icon {
        svg {
          width: calc(var(--hd) * 84);
          height: calc(var(--hd) * 84);
          color: var(--invalid);
        }
      }
    }
  }

  &-delete-preview {
    position: absolute;
    right: calc(-8 * var(--hd));
    top: calc(-8 * var(--hd));
    color: #999999;
    z-index: 1;
    border-radius: 50%;
    background: var(--base-white);
  }
}

//dialog弹窗内部
.@{signaturePrefix}-dialog {
  .@{dialogClsPrefix}-body {
    // padding-bottom: calc(10 * var(--hd));
    padding-bottom: 0;
  }

  .@{dialogClsPrefix}-footer {
    padding: var(--h-spacing-lg);
    position: relative;
  }

  &-content {
    text-align: center;

    &-qrcode {
      margin-top: calc(50 * var(--hd));
      margin-bottom: calc(20 * var(--hd));
    }

    &-qrcode-review {
      margin-top: calc(20 * var(--hd));
      margin-bottom: calc(20 * var(--hd));
    }

    &-text {
      color: var(--regular-fc);
      font-size: var(--font-size-12);
      line-height: calc(22 * var(--hd));
      margin-bottom: calc(50 * var(--hd));
    }

    &-text-review {
      margin-bottom: calc(20 * var(--hd));
    }
  }

  &-titleIcon {
    color: var(--secondary-fc);
    cursor: pointer;
  }
}

.@{signaturePrefix}-dialog-eSignature {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(450 * var(--hd));
  padding: var(--v-spacing-lg) var(--h-spacing-lg) 0;
}

.@{signaturePrefix}-dialog-eSignature-spin{
  min-height: calc(450 * var(--hd));
  display: flex;
  align-items: center;
  justify-content: center;
}

.@{signaturePrefix}-dialog-canvas {
  all: unset;

  &-srcollerContainer {
    height: 415px;
  }

  &-srcollerContainer-rtl {
    transform: scaleX(-1);
  }

  &-canvasContainer {
    width: 715px;
    height: 400px;
  }

  &-canvas {
    // position: absolute;
    // left: 0;
    // top: 0;
    background-color: var(--bg-white);
    display: block;
    // width: 100%;
    // height: 100%;
    // margin: 0 auto;
  }
}

.@{signaturePrefix}-dialog-canvas-tools {
  display: flex;
  height: calc(40 * var(--hd));
  margin-bottom: calc(10 * var(--hd));
  justify-content: center;

  &-brush {
    min-width: calc(230 * var(--hd));
    display: inline-flex;
    align-items: center;
    border-right: calc(1 * var(--hd)) solid #e2e2e2;
    padding-right: calc(5 * var(--hd));

    .brush-name {
      font-size: var(--font-size-12);
      color: var(--main-fc);
      width: calc(65 * var(--hd));
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;

      &:hover {
        cursor: default;
      }
    }

    .brush-icon {
      flex: 1;
      width: calc(15 * var(--hd));
      height: 100%;
      text-align: center;
      opacity: 0.3;
      display: inline-flex;

      &-active {
        opacity: 1;
      }

      &-xxxs,
      &-xxs,
      &-xs,
      &-sm,
      &-md,
      &-lg,
      &-xl {
        border-radius: 50%;
        background-color: var(--base-black);
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }

      &-xxxs {
        width: calc(2 * var(--hd));
        height: calc(2 * var(--hd));
      }

      &-xxs {
        width: calc(4 * var(--hd));
        height: calc(4 * var(--hd));
      }

      &-xs {
        width: calc(6 * var(--hd));
        height: calc(6 * var(--hd));
      }

      &-sm {
        width: calc(8 * var(--hd));
        height: calc(8 * var(--hd));
      }

      &-md {
        width: calc(12 * var(--hd));
        height: calc(12 * var(--hd));
      }

      &-lg {
        width: calc(16 * var(--hd));
        height: calc(16 * var(--hd));
      }

      &-xl {
        width: calc(20 * var(--hd));
        height: calc(20 * var(--hd));
      }
    }
  }

  &-colors {
    min-width: calc(315 * var(--hd));
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-right: calc(1 * var(--hd)) solid #e2e2e2;
    padding-right: calc(5 * var(--hd));

    .colors-name {
      font-size: var(--font-size-12);
      color: var(--main-fc);
      width: calc(65 * var(--hd));
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
      margin-right: calc(10 * var(--hd));

      &:hover {
        cursor: default;
      }
    }
  }

  &-tools {
    // min-width: calc(180 * var(--hd));
    display: inline-flex;
    align-items: center;
    flex: 1;

    .item {
      cursor: pointer;
      display: inline-flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      //禁止多次点击选中文字
      -moz-user-select: none;
      /*火狐*/
      -webkit-user-select: none;
      /*webkit浏览器*/
      -ms-user-select: none;
      /*IE10*/
      -khtml-user-select: none;
      /*早期浏览器*/
      user-select: none;

      &-name {
        width: calc(35 * var(--hd));
        font-size: var(--font-size-12);
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-icon-redo {
        transform: rotateY(180deg);
      }

      &-icon {
        color: var(--secondary-fc);

        &:hover {
          color: var(--primary);
        }
      }

      &-icon-disabled {
        color: var(--invalid-fc);
        cursor: not-allowed;

        &:hover {
          color: var(--invalid-fc);
        }
      }

      &.active {
        color: var(--primary);

        svg {
          color: var(--primary);
        }
      }
    }
  }
}

.@{signaturePrefix}-dialog-size {
  position: absolute;
  left: 0;
  right: 0;
  // top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  color: #666666;
  padding: var(--h-spacing-lg);

  &-canvasButtonWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(30 * var(--hd));
    background: #FFFFFF;
    border: 1px solid rgba(235, 235, 235, 1);
    box-shadow: 0 calc(2 * var(--hd)) calc(2 * var(--hd)) 0 rgba(0, 0, 0, 0.03);
    border-radius: calc(6 * var(--hd));
    font-size: var(--font-size-12);
    padding: calc(4 * var(--hd)) calc(10 * var(--hd));

    &-span {
      margin-left: calc(5 * var(--hd));
      height: calc(14 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &-span-rtl {
      direction: unset
    }
  }
}

.@{signaturePrefix}-scope-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: absolute;
  // width: 100%;
  // left: 0;
  // right: 0;
  left: 50%;
  transform: translate(-50%, 0);
  top: calc(60 * var(--hd));
}

.@{signaturePrefix}-scope {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: calc(30 * var(--hd));
    height: calc(30 * var(--hd));
    margin: 0 calc(6 * var(--hd));
    padding: 0;
  }

  &-select {
    width: calc(140 * var(--hd));

    &-input {
      .@{inputClsPrefix}-wrap {
        height: calc(30 * var(--hd));
      }
    }

    &-inputText {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &-span {
        max-width: calc(115 * var(--hd));
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &-select-dropdown {
    .@{selectClsPrefix}-option-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}