.weapp-ie11 {

  .@{inputClsPrefix}-wrap.@{datePickerClsPrefix}-wrap-input,
  .@{inputClsPrefix}-wrap.@{dateTimePickerClsPrefix}-wrap-input,
  .@{inputClsPrefix}-wrap.@{monthDayPickerClsPrefix}-wrap-input,
  .@{inputClsPrefix}-wrap.@{timePickerClsPrefix}-wrap-input {
    display: inline-flex;
  }

  .@{datePickerClsPrefix},
  .@{dateTimePickerClsPrefix},
  .@{monthDayPickerClsPrefix},
  .@{timePickerClsPrefix} {
    .@{inputClsPrefix} {
      min-height: 28px;
    }
  }

  .@{inputClsPrefix}.@{datePickerClsPrefix}-rangeWrap-input,
  .@{inputClsPrefix}.@{dateTimePickerClsPrefix}-rangeWrap-input,
  .@{inputClsPrefix}.@{monthDayPickerClsPrefix}-rangeWrap-input,
  .@{inputClsPrefix}.@{timePickerClsPrefix}-rangeWrap-input {
    min-height: 28px;
  }
}