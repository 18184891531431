.@{BatchOperatorClsPrefix} {
  &-dialog {
    .@{titleClsPrefix}-right-container,
    .@{dialogClsPrefix}-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .@{tableClsPrefix}-grid-tfoot {
      display: none;
    }
    .@{tableClsPrefix}-grid {
      border-color: var(--border-color);
    }
  }
  &-pagation {
    margin-right: calc(10 * var(--hd));
  }

  &-selected {
    font-size: var(--font-size-12);
    color: var(--secondary-fc);
    position: absolute;
    left: calc(16 * var(--hd));
    &-num {
      color: var(--primary);
      margin: 0 calc(4 * var(--hd));
    }
  }

  &-advanced-panel {
    margin-bottom: var(--v-spacing-lg);
  }

  &-first-select-tip {
    cursor: pointer; 
    color: var(--primary);
  }
  &-tips-message {
    .@{messageClsPrefix}-custom-close {
      display: block;
    }
  }
}
