.@{mSearchAdvancedClsPrefix} {
  background: var(--bg-white);
  padding: var(--v-spacing-md) var(--h-spacing-md);
  font-size: var(--font-size-14);
  &.usePanel{
    width: 100%;
    padding: 0;
  }
  &-onlyShowIcon {
    display: inline-block;
    background-color: var(--bg-transparent);
    padding: 0;
  }
  &-saIcon-active {
    .@{mSearchbarClsPrefix}
      .@{mSearchbarClsPrefix}-input
      .@{inputClsPrefix}-wrap
      .@{mSearchAdvancedClsPrefix}-saIcon {
      color: var(--primary);
    }
  }
  &-onlyShowIcon&-saIcon-active .@{mSearchAdvancedClsPrefix}-saIcon {
    color: var(--primary);
  }
  &-searchbar {
    &.has-voice-to-text {
      .@{mSearchAdvancedClsPrefix}-voicebtn {
        margin-left: calc(14 * var(--hd));
        margin-right: calc(4 * var(--hd));
      }
      .@{mSearchbarClsPrefix}-input {
        .@{inputClsPrefix}-wrap .@{inputClsPrefix}-suffix {
          .@{mSearchAdvancedClsPrefix}-saIcon {
            margin-left: calc(4 * var(--hd));
          }
        }
      }
    }
  }
  &-panel {
    padding: calc(10 * var(--hd)) 0;
    background-color: var(--bg-base);
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    height: calc(100% - var(--m-dialog-button-group-height));
    .@{searchGroupClsPrefix}-body {
      padding: 0 calc(10 * var(--hd));
      width: 100%;
      margin: 0;
      .@{formClsPrefix}-searchGroup-mobile-body {
        border-radius: var(--border-radius-md);
        background-color: var(--bg-transparent);
        padding: 0;
        &-custom {
          .@{searchGroupClsPrefix}-body {
            padding: 0;
          }
        }
      }
      .@{formItemClsPrefix}-mobile .@{formItemClsPrefix}-label {
        font-weight: var(--font-weight-base);
      }
      .@{mListClsPrefix}-item .@{mListClsPrefix}-line {
        border-bottom: 0;
      }
      .@{formClsPrefix}-searchGroup-mobile-body .@{formClsPrefix}-row::after {
        display: none;
      }
    }
    .@{searchGroupClsPrefix}-top {
      font-size: var(--font-size-14);
      color: var(--regular-fc);
      margin-top: var(--v-spacing-md);
      padding-left: var(--h-spacing-lg);
      padding-right: var(--h-spacing-lg);
      border: 0;
    }
    &-buttons {
      .@{mDialogClsPrefix}-footer-two {
        .@{mDialogClsPrefix}-footer-btn {
          width: 100%;
          &:first-child {
            border-radius: 0;
            border-right: 0;
            margin-bottom: var(--m-dialog-footer-btn-v-spacing);
          }
        }
      }
      .@{mDialogClsPrefix}-wrap{
        &-bottom{
          .@{mDialogClsPrefix}-footer-btn:not(:last-child){
            margin-top: 0;
          }
        }
      }
    }
  }
  &-layout {
    .@{searchGroupClsPrefix}-top {
      color: var(--regular-fc);
      border: 0;
    }
    .@{formItemClsPrefix}-label {
      color: var(--regular-fc);
    }
    .@{formItemClsPrefix}-wrapper {
      padding-right: 0;
      display: block;
       /* 高级搜索内部对齐方式处理特殊处理（修复高级搜索内部switch对齐问题（mb端））*/
       .@{formSwitchClsPrefix}-SLIDER-MB,
       .@{formSwitchClsPrefix}-SWITCH-MB{
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: flex-end;
       }
    }
    .@{formClsPrefix}-searchGroup-mobile-body .@{formClsPrefix}-row {
      border-bottom: 0;
      background-color: var(--bg-white);
      margin-top: calc(10 * var(--hd));
      padding: 0 var(--v-spacing-lg);
      border-radius: var(--border-radius-md);
      &:first-child {
        margin-top: 0;
      }
    }
    .@{formClsPrefix}-searchGroup-mobile-body .@{formClsPrefix}-row-allHide {
      margin: 0;
    }
    /* ------------  多列布局，但按照一行一列展示，用于高级搜索特殊样式处理 --------- */
    .@{formClsPrefix}-searchGroup-mobile-body .@{formClsPrefix}-row-multiLine {
      padding: 0;
      background-color: var(--bg-transparent);
      .@{formClsPrefix}-col {
        background-color: var(--bg-white);
        margin-top: calc(10 * var(--hd));
        padding: 0 var(--v-spacing-lg);
        border-radius: var(--border-radius-md);
      }
    }
    .@{formClsPrefix}-searchGroup-mobile-body-custom
      .@{formClsPrefix}-row-multiLine {
      margin-bottom: 0;
      .@{formClsPrefix}-col {
        margin-bottom: calc(22 * var(--hd));
      }
      .@{formClsPrefix}-col:last-child {
        margin-bottom: calc(10 * var(--hd));
      }
    }
  }
  &-container {
    padding: 0 calc(10 * var(--hd));
    &-isHoldRight {
      margin-bottom: var(--v-spacing-md);
      .@{mListClsPrefix}-item .@{mInputClsPrefix}-control input {
        text-align: right;
      }
      .@{formItemClsPrefix}-label {
        border-top-left-radius: var(--border-radius-md);
        border-bottom-left-radius: var(--border-radius-md);
      }
      .@{formItemClsPrefix}-wrapper {
        border-top-right-radius: var(--border-radius-md);
        border-bottom-right-radius: var(--border-radius-md);
        .@{mSelectClsPrefix} .@{mInputClsPrefix}-control input {
          height: 100%;
        }
      }
    }
    // background-color: var(--m-searchAdvanced-container-bg);
  }
  &-range {
    background-color: var(--bg-white);
    padding: var(--v-spacing-lg) var(--h-spacing-lg);
    border-radius: var(--border-radius-md);
    color: var(--main-fc);
    &-top {
      padding-bottom: var(--v-spacing-lg);
    }
    &-condition,
    &-condition-active {
      display: inline-block;
      width: calc(50% - calc(6 * var(--hd)));
      text-align: center;
      padding: var(--v-spacing-md) 0;
      background-color: var(--bg-weak);
      border-radius: var(--border-radius-md);

      &:last-child {
        margin-left: calc(12 * var(--hd));
        border-radius: var(--border-radius-sm);
      }
    }
    &-condition-active {
      border: calc(1 * var(--hd)) solid
        var(--m-searchAdvanced-condition-active-border);
      border-radius: var(--border-radius-md);
      color: var(--m-searchAdvanced-condition-active-border);
      background-color: var(--m-searchAdvanced-condition-active-bg);
    }
  }
  &-setting {
    color: var(--primary);
    height: 100%;
    &-search {
      padding: var(--v-spacing-md) var(--h-spacing-md);
      background-color: var(--bg-white);
      border-bottom: var(--searchAdvanced-border);
      height: calc(50 * var(--hd));
      .@{iconClsPrefix} {
        color: var(--secondary-fc);
      }
      &-cancel {
        color: var(--main-fc);
        padding: var(--v-spacing-md) var(--h-spacing-md);
      }
    }
    &-container {
      // height: calc(100% - calc(57 * var(--hd)));
      background-color: var(--m-searchAdvanced-container-bg);
    }
    .@{mSearchAdvancedClsPrefix}-setting-list {
      background: var(--bg-white);
      display: flex;
      align-items: center;
      &-right {
        border-bottom: var(--searchAdvanced-border);
        width: 100%;
        padding: var(--h-spacing-lg) 0;
        display: flex;
        &-single {
          flex: 1;
        }
      }
      &-switch {
        text-align: right;
        padding-right: var(--h-spacing-lg);
      }
      .@{iconClsPrefix} {
        color: var(--secondary-fc);
        vertical-align: middle;
        padding: 0 var(--h-spacing-lg);
      }
      &-title {
        color: var(--main-fc);
        vertical-align: middle;
      }
      &:last-child {
        .@{mSearchAdvancedClsPrefix}-setting-list-right {
          border-bottom: 0;
        }
      }
    }
    .@{listClsPrefix}-sortable-bg {
      background-color: #f4f5da;
    }
    .@{emptyClsPrefix} {
      height: calc(100% - 107px);
      padding-top: 50%;
      margin-top: calc(-50 * var(--hd));
    }
    .@{emptyClsPrefix} .@{emptyClsPrefix}-image .Icon-empty-file {
      width: calc(100 * var(--hd));
      height: calc(100 * var(--hd));
    }
  }
  &-HistorySearch {
    padding: var(--v-spacing-md) var(--h-spacing-lg);
    font-size: var(--font-size-14);
    height: 100%;
    &-search {
      width: 100%;
      color: var(--secondary-fc);
      margin-bottom: calc(10 * var(--hd));
      input.ui-input::-webkit-search-cancel-button {
        display: none;
      }
      input.ui-input[type="search"]::-ms-clear {
        display: none;
      }
      input.ui-input[type="search"] {
        -webkit-appearance: none;
      }
    }
    .@{inputClsPrefix}-group.@{inputClsPrefix}-group-append
      > .@{inputClsPrefix}-wrap {
      border-radius: var(--border-radius-lg);
    }
    .@{inputClsPrefix} {
      font-size: var(--font-size-14);
      background-color: var(--m-searchAdvanced-historySearch-input-bg);
      &-wrap {
        background-color: var(--m-searchAdvanced-historySearch-input-bg);
      }
    }
    .@{inputClsPrefix}-append {
      background-color: var(--bg-white);
      font-size: var(--font-size-14);
      border: 0;
      padding-left: var(--h-spacing-lg);
      padding-right: 0;
    }
    .@{mSearchAdvancedClsPrefix}-historyPanel {
      &-top {
        position: relative;
      }
      &-title {
        color: var(--main-fc);
        font-size: var(--font-size-14);
        padding-bottom: calc(14 * var(--hd));
      }
      &-icon {
        cursor: pointer;
        color: var(--searchAdvanced-form-item-icon-color);
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .@{mSearchAdvancedClsPrefix}-commonFilter-top {
      padding-top: var(--v-spacing-lg);
    }
    .@{mSearchAdvancedClsPrefix}-commonFilter-icon {
      padding-top: var(--v-spacing-lg);
    }
  }
  &-quikSearch {
    color: var(--secondary-fc);
    padding: 0 0 var(--v-spacing-xl) 0;
    &-edit {
      color: var(--m-searchAdvanced-quikSearch-editBtn-color);
      display: inline-block;
      padding-left: var(--h-spacing-lg);
    }
  }
  &-resList {
    vertical-align: middle;
    user-select: none;
    &-single {
      cursor: pointer;
      display: inline-block;
      background: var(--label-bc);
      color: var(--regular-fc);
      padding: var(--v-spacing-sm) var(--h-spacing-lg);
      margin-right: calc(
        var(--searchAdvanced-form-item-icon-width) / 2 + var(--h-spacing-sm)
      );
      border-radius: var(--border-radius-lg);
      position: relative;
      margin-bottom: var(--v-spacing-sm);
      user-select: none;
      &-content {
        max-width: calc(160 * var(--hd));
        display: flex;
        align-items: center;
        &-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &-close {
        display: none;
        border-radius: 50%;
        padding: var(--v-spacing-xs);
        text-align: center;
        position: absolute;
        top: calc(-1 * var(--searchAdvanced-historySearch-icon-position));
        right: calc(-1 * var(--searchAdvanced-form-item-icon-width) / 2);
        cursor: pointer;
        &-show {
          display: inline-block;
        }
      }
      &-active {
        background: #e9f7ff;
        border: calc(1 * var(--hd)) solid var(--primary);
        color: var(--primary);
      }
    }
  }
  &-prompt-noPrompt {
    .@{inputClsPrefix} {
      display: none;
    }
  }
  &-commonFilterList {
    &-top {
      color: var(--regular-fc);
      padding: var(--v-spacing-lg) var(--h-spacing-lg) var(--v-spacing-sm)
        var(--h-spacing-lg);
    }
    &-container {
      padding: 0 calc(10 * var(--hd));
      // background-color: var(--m-searchAdvanced-container-bg);
    }
    &-content {
      background-color: var(--bg-white);
      padding: var(--v-spacing-lg) var(--h-spacing-lg);
      border-radius: var(--border-radius-md);
    }
  }
  &-sortable-drag {
    border: calc(1 * var(--hd)) solid
      var(--m-searchAdvanced-condition-active-border);
    color: var(--m-searchAdvanced-condition-active-border);
    background-color: var(--m-searchAdvanced-condition-active-bg);
  }
  &-duplicationNameConfirm {
    .@{iconClsPrefix} {
      color: var(--warning);
    }
    .@{mDialogClsPrefix}-footer-btn-last {
      // color: var(--primary);
    }
  }
  &-footer {
    .@{mBtnClsPrefix}-btn-primary {
      color: var(--primary);
    }
  }
  &-rangepickerfield {
    &-top {
      padding-left: calc(12 * var(--hd));
      line-height: var(--line-height-sm);
      height: var(--line-height-sm);
      text-align: left;
    }
    &-table-operate {
      .@{checkboxClsPrefix}-left {
        align-self: baseline;
      }
      &-tip {
        color: var(--secondary-fc);
        font-size: var(--font-size-12);
        padding-top: var(--v-spacing-xs);
        white-space: break-spaces;
        word-break: break-word;
      }
    }
  }
  &-snapsConfig-dialog {
    background: var(--bg-base);
  }
}

.@{mSearchAdvancedClsPrefix}-browserConfig-wrapper,
.@{mSearchAdvancedClsPrefix}-browserConfig-form-item-readonly {
  .@{formItemClsPrefix}-delete {
    display: none;
  }
}
.@{mSearchAdvancedClsPrefix}-panel-content-height{
  height: 100%;
  &.searchadvanced-panel-content{
    padding-bottom: calc(57 * var(--hd));
  }
}

/* 含下级 */
.@{mSearchAdvancedClsPrefix}-browser-including-sub-m {
  .@{mCcheckboxClsPrefix}:before {
    content: '';
    border-top: var(--border-solid);
    width: calc(100% + calc(2 * var(--v-spacing-lg)));
    position: absolute;
    left: calc(-1 * var(--v-spacing-lg));
  }
  .@{mCcheckboxClsPrefix} {
    position: relative;
  }
  &.isCustom {
    .@{formItemClsPrefix}-mobile {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .@{mSearchAdvancedClsPrefix}-browser-including-sub-m-check {
      border-bottom-left-radius: var(--border-radius-md);
      border-bottom-right-radius: var(--border-radius-md);
    }
    .@{mCcheckboxClsPrefix}:before {
      width: 100%;
      left: 0;
    }
  }
}
.@{mSearchAdvancedClsPrefix}-layout {
  .@{formClsPrefix}-searchGroup-custom .@{formClsPrefix}-searchGroup-mobile-body-custom {
    .@{mSearchAdvancedClsPrefix}-browser-including-sub-m .@{mCcheckboxClsPrefix} {
      padding: 0 var(--h-spacing-lg);
    }
  }
}

/* 隐藏条件关系 */
.@{mSearchAdvancedClsPrefix}-panel.hideCondition {
  .@{formItemClsPrefix}-condition {
    display: none;
  }
}
/* 镜像 */
.@{mSearchAdvancedClsPrefix}-HistorySearch {
  .@{mSearchAdvancedClsPrefix}-quikSearch-edit.mirror-text-re {
    padding-left: 0;
    padding-right: var(--h-spacing-lg);
  }
}
.@{mSearchAdvancedClsPrefix}-panel.needRTL {
  .@{searchGroupClsPrefix}-right .@{mSearchAdvancedClsPrefix}-setting {
    text-align: left;
  }
  .@{formClsPrefix}-mobile .@{formClsPrefix}-searchGroup-mobile-body .@{formItemClsPrefix}-label {
    padding-right: 0;
  }
  .@{mSearchAdvancedClsPrefix}-container {
    &-isHoldRight {
      .@{mListClsPrefix}-item .@{mInputClsPrefix}-control input {
        text-align: left;
      }
    }
  }
}
