.@{searchGroupClsPrefix} {
  &-top {
    border-bottom: var(--searchGroup-border);
    line-height: var(--line-height-sm);
    height: var(--line-height-sm);
    color: var(--main-fc);
    &:empty {
      border: 0;
      padding: 0;
      height: auto;
    }
  }
  &-right {
    text-align: right;
    .@{iconClsPrefix} {
      cursor: pointer;
      color: var(--searchGroup-form-item-icon-color);
    }
  }
  &-show, &-hide {
    transition: transform 160ms cubic-bezier(0.5, 0, 0.5, 0.1);
  }
  &-hide {
    transform: rotate(180deg);
  }
  &-animateHeight {
    overflow: unset;
  }
}
.@{searchGroupClsPrefix}-isMobile {
  .@{searchGroupClsPrefix}-top {
    padding-bottom: var(--v-spacing-xs);
    border: 0;
    color: var(--regular-fc);
  }
  .@{searchGroupClsPrefix}-body {
    padding: 0;
    width: 100%;
    margin-left: 0;
  }
}
.@{searchGroupClsPrefix}-LargeSpacing {
  // width: 92%;
  // margin-left: 4%;
  // margin: 0 calc(7 * var(--hd));
}