.@{btnClsPrefix} {
  outline: none;
  position: relative;
  text-align: center;
  white-space: nowrap;
  display: block;
  width: 100%;
  background-image: none;
  background-color: var(--bg-white);
  color: var(--btn-font-color);
  font-size: var(--btn-font-size);
  font-weight: var(--font-weight-base);
  font-family: var(--regular-ff);
  line-height: var(--btn-line-height);
  cursor: pointer;
  border: var(--btn-border);
  border-radius: var(--btn-radius);
  padding: var(--btn-padding-y) var(--btn-padding-x);
  transition: var(--transition-all);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.@{btnClsPrefix}-radius {
    border-radius: var(--btn-circle-radius);
  }
  &.@{btnClsPrefix}-primary,
  &.@{btnClsPrefix}-success,
  &.@{btnClsPrefix}-warning,
  &.@{btnClsPrefix}-danger {
    &:hover {
      opacity: var(--btn-hover-opacity);
    }
    
    &.focus,
    &.active {
      &::before {
        opacity: var(--btn-active-opacity);
      }
    }
  }

  &.@{btnClsPrefix}-default {
    color: var(--btn-font-default-color);
    border-color: var(--btn-border-color);
    /* 使用before元素实现背景色 */
    &::before {
      content: '';
      transition: var(--transition-all);
      opacity: var(--btn-default-hover-opacity);
      background-color: var(--bg-transparent);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    &.height-inhert {
      &::before {
        height: inherit;
      }
    }
    &:hover {
      color: var(--btn-default-hover-color);
      border-color: var(--btn-default-hover-color);

      &::before {
        background-color: var(--btn-default-hover-bg);
      }
    }
    /*
    &:focus,
    &:active {
      color: var(--btn-default-active-color);
      border-color: var(--btn-default-active-color);
      
      &::before {
        background-color: var(--btn-default-focus-bg);
      }
    }
    */
    // &.focus, // &:focus,
    // // &:active,
    // &.active {
    //   color: var(--btn-default-active-color);
    //   background-color: var(--label-bc-active);
    //   border-color: var(--btn-default-active-color);
    // }
  }

  &.@{btnClsPrefix}-primary {
    background-color: var(--primary);
    &::before {
      background-color: var(--bg-transparent);
    }
    /* 点击状态额外添加，便于覆盖样式 */
    /*
    &:focus,
    &:active {
      background-color: var(--btn-primary-focus-bg);
    }
    */
    &:hover {
      background-color: var(--btn-primary-hover-bg);
    }
    // &:focus,
    &.focus,
    // &:active,
    &.active {
      &::before {
        background-color: var(--primary);
      }
    }
  }

  &.@{btnClsPrefix}-success {
    background-color: var(--success);

    /* 点击状态额外添加，便于覆盖样式 */
    /*
    &:focus,
    &:active {
      background-color: var(--btn-success-focus-bg);
    }
    &:hover {
      background-color: var(--btn-success-hover-bg);
    }
    */

    // &:focus,
    &.focus,
    // &:active,
    &.active {
      background-color: var(--btn-success-active-bc);
    }
  }

  &.@{btnClsPrefix}-warning {
    background-color: var(--warning);

    /* 点击状态额外添加，便于覆盖样式 */
    /*
    &:focus,
    &:active {
      background-color: var(--btn-warning-focus-bg);
    }
    &:hover {
      background-color: var(--btn-warning-hover-bg);
    }
    */

    // &:focus,
    &.focus,
    // &:active,
    &.active {
      background-color: var(--btn-warning-active-bc);
    }
  }

  &.@{btnClsPrefix}-danger {
    background-color: var(--danger);

    /* 点击状态额外添加，便于覆盖样式 */
    /*
    &:focus,
    &:active {
      background-color: var(--btn-danger-focus-bg);
    }
    &:hover {
      background-color: var(--btn-danger-hover-bg);
    }
    */
  
    &.focus,
    &.active {
      background-color: var(--btn-danger-active-bc);
    }
  }

  &.@{btnClsPrefix}-link {
    padding: 0;
    color: var(--btn-link-fc);
    background-color: var(--bg-transparent);

    &.disabled,
    &:disabled {
      background-color: var(--bg-transparent);
      color: var(--invalid-fc);
      cursor: not-allowed;
    }
  }

  &.@{btnClsPrefix}-default {
    &.disabled::before,
    &:disabled::before {
      display: none;
    }
  }

  &.@{btnClsPrefix}-default,
  &.@{btnClsPrefix}-primary,
  &.@{btnClsPrefix}-success,
  &.@{btnClsPrefix}-warning,
  &.@{btnClsPrefix}-danger {

    &.disabled,
    &:disabled {
      color: var(--base-white);
      background-color: var(--btn-default-disable-bg);
      border-color: var(--transparent);
      cursor: not-allowed;

      >* {
        pointer-events: none;
      }
    }
  }

  &.@{btnClsPrefix}-default {
    &.disabled,
    &:disabled {
      border-color: var(--btn-default-disable-border-color);
      color: var(--btn-default-disable-color);
    }
  }

  &.@{btnClsPrefix}-primary {
    &.disabled,
    &:disabled {
      background-color: var(--btn-primary-disable-bg);
    }
  }
  &.@{btnClsPrefix}-success {
    &.disabled,
    &:disabled {
      background-color: var(--btn-success-disable-bg);
    }
  }
  &.@{btnClsPrefix}-warning {
    &.disabled,
    &:disabled {
      background-color: var(--btn-warning-disable-bg);
    }
  }
  &.@{btnClsPrefix}-danger {
    &.disabled,
    &:disabled {
      background-color: var(--btn-danger-disable-bg);
    }
  }

  &-large {
    font-size: var(--btn-lg-font-size);
    padding: var(--btn-lg-padding-y) var(--btn-lg-padding-x);
    // &.@{btnClsPrefix}-default {
    //   &::before {
    //     height: calc(var(--btn-lg-padding-y) * 2 + var(--btn-lg-font-size));
    //   }
    // }
  }

  &-small {
    font-size: var(--btn-sm-font-size);
    padding: var(--btn-sm-padding-y) var(--btn-sm-padding-x);
    // &.@{btnClsPrefix}-default {
    //   &::before {
    //     height: calc(var(--btn-sm-padding-y) * 2 + var(--btn-line-height));
    //   }
    // }
  }

  &-inline {
    display: inline-block;
    width: auto;
  }
}