.@{mSignaturePrefix} {
  &-dialogWrap {
    height: 100%;
    background-color: #f7f7f7;
  }

  &-canvasWrap {
    height: 100%;
    background-color: #f7f7f7;

    &-isHorizontal {
      display: flex;
      flex-direction: row;
    }
  }

  &-canvasWrap-rtl,
  &-canvasWrap-isHorizontal-rtl {
    transform: scaleX(-1);
  }

  &-index-white-board {
    width: 100%;
    text-align: center;
    background-color: var(--bg-white);

    &-img {
      margin: calc(var(--hd) * 10) 0;
      height: 20vh;
      display: inline-block;
      width: 100%;
    }

    img {
      height: 100%;
    }
  }

  &-index-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #f7f7f7;
    margin: calc(var(--hd) * 10) 0;

    .ui-m-upload {
      width: auto;
    }

    .@{mSignaturePrefix}-excuteBtn {
      margin-right: calc(var(--hd) * 10);
    }
  }

  &-in-form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    width: calc(var(--hd) * 100);
    height: calc(var(--hd) * 100);
    // background: var(--bg-base);
    border: calc(var(--hd) * 1) dashed var(--primary);
    // border: calc(var(--hd) * 1) dashed var(--border-color);
    border-radius: var(--border-radius-xs);

    &-review {
      width: calc(var(--hd) * 170);
      height: calc(var(--hd) * 90);
    }

    .@{mSignaturePrefix}-icon {
      height: 50%;
      display: inline-flex;
      align-items: flex-end;

      svg {
        width: calc(var(--hd) * 30);
        height: calc(var(--hd) * 30);
        color: var(--primary);
      }
    }

    .@{mSignaturePrefix}-icon-rtl {
      transform: scaleX(-1);
    }

    .@{mSignaturePrefix}-text {
      height: 50%;
      display: inline-flex;
      align-items: flex-start;
      padding-top: calc(var(--hd) * 10);
      font-size: var(--font-size-12);
      color: var(--primary);
    }
  }

  &-in-form-delete {
    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      svg {
        width: calc(var(--hd) * 42);
        height: calc(var(--hd) * 42);
        color: var(--invalid);
      }
    }
  }

  &-delete-preview {
    position: absolute;
    right: calc(-8 * var(--hd));
    top: calc(-8 * var(--hd));
    color: #999999;
    z-index: 1;
    border-radius: 50%;
    background: var(--base-white);
  }

  &-canvas {
    position: absolute;
    left: 0;
    top: 0;
    margin: auto;
    display: block;
    right: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
  }

  &-disabled {
    opacity: 0.7;
    background: var(--label-bc);
  }

  &-readOnly {
    opacity: 0.7;
    background: var(var(--label-bc));
  }

  &-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  &-footer-rtl {
    transform: scaleX(-1);
  }

  &-scope {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(220 * var(--hd));
    height: calc(30 * var(--hd));
    position: fixed;
    left: 0;
    right: 0;
    top: calc(10 * var(--hd));
    margin: auto;

    &-button {
      width: calc(28 * var(--hd));
      height: calc(28 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-select-inputText {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-select-inputText-rtl {
      transform: scaleX(-1);
    }
  }

  //----------工具面板----------=
  &-tools {
    width: 100%;
    display: flex;
    height: calc(var(--hd) * 50);
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    background-color: #fcfcfc;
    border-top: calc(var(--hd) * 1) solid var(--diviling-line-color);
    border-bottom: calc(var(--hd) * 1) solid var(--diviling-line-color);
  }

  &-tools-isHorizontal {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: calc(var(--hd) * 50);
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fcfcfc;
    border-left: calc(var(--hd) * 1) solid var(--diviling-line-color);
    border-right: calc(var(--hd) * 1) solid var(--diviling-line-color);
  }

  &-color-panel,
  &-brush-panel {
    position: fixed;
    bottom: calc(var(--hd) * -50);
    width: 100%;
    height: calc(var(--hd) * 50);
    z-index: 1;
    background: var(--bg-white);
    transition: all 0.3s;
    display: flex;

    &.active {
      bottom: 0;
    }
  }

  &-color-panel-isHorizontal,
  &-brush-panel-isHorizontal {
    position: fixed;
    top: 0;
    right: calc(var(--hd) * -50);
    height: 100%;
    width: calc(var(--hd) * 50);
    z-index: 1;
    background: var(--bg-white);
    border-left: var(--border-solid);
    transition: all 0.3s;

    &.active {
      right: calc(var(--hd) * 50);
    }
  }


  &-brush-panel-isHorizontal {
    display: flex;
    flex-direction: column;
  }

  &-brush-panel-cell-wrap,
  &-brush-panel-cell-wrap-isHorizontal {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-color-panel-cell {
    position: relative;
    display: inline-block;
    width: calc(var(--hd) * 30);
    height: calc(var(--hd) * 30);
    margin: calc(var(--hd) * 5);
    border-radius: 50%;

    &-active {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 2px solid var(--base-white);
      width: calc(var(--hd) * 18);
      height: calc(var(--hd) * 18);
      border-radius: 50%;
    }
  }

  &-color-panel-cell-wrap {
    height: 100%;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-color-panel-cell-wrap-isHorizontal {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-brush-panel-cell {
    justify-content: space-evenly;
    display: inline-block;
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    background-color: var(--base-black);
    border-radius: 50%;

    &-active {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: auto;
      border: 2px solid var(--primary);
      border-radius: 50%;
    }
  }

  &-color {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(var(--hd) * 24);
    height: calc(var(--hd) * 24);

    &-current {
      width: 100%;
      height: 100%;
    }

    &-cell {
      display: inline-block;
      width: calc(var(--hd) * 7);
      height: calc(var(--hd) * 7);
    }
  }

  &-redo,
  &-undo,
  &-brush,
  &-clear,
  &-eraserMode,
  &-brushSize,
  &-brushColor {
    opacity: 0.7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-undo {
    .@{mSignaturePrefix}-toolBar-icon {
      transform: rotateY(180deg);
    }
  }

  &-toolBar-text {
    font-size: var(--font-size-12);
    margin-top: calc(var(--hd) * 4);
  }

  &-toolBar-text-rtl {
    transform: scaleX(1);
  }

  &-toolBar-icon {
    color: var(--regular-fc);

    &-active {
      color: var(--primary);
    }

    &-disabled {
      color: var(--invalid-fc);
    }
  }

  &-toolBar-iconBrushColor {
    position: relative;
    width: calc(var(--hd) * 23);
    height: calc(var(--hd) * 23);
    border-radius: 50%;
    background: conic-gradient(from 90deg, #FF0000, #FFE063, #00FF3A, #0F73FF, #FF00EB, );
  }

  &-toolBar-iconBrushColorContent {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(var(--hd) * 17);
    height: calc(var(--hd) * 17);
    border-radius: 50%;
    background-color: black;
  }
}

.@{mSignaturePrefix} {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  &-signatureCanvasWrap {
    background-color: var(--bg-white);
  }

  &-horizontalIcon {
    height: calc(var(--hd) * 30);
    width: calc(var(--hd) * 30);
    position: fixed;
    color: var(--secondary-fc);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-horizontalButtons {
    position: fixed;
    color: var(--secondary-fc);
    display: flex;
    align-items: center;
    justify-content: center;

    &-button {
      margin: 0 calc(var(--hd) * 5);
      width: calc(var(--hd) * 60);
      height: calc(var(--hd) * 30);
    }

    &-button-rtl {
      transform: scaleX(1);
    }
  }

  &-canvasButton {
    position: fixed;
    height: calc(var(--hd) * 30);
    width: calc(var(--hd) * 110);
    border: 1px solid rgba(235, 235, 235, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-white);
    border-radius: var(--border-radius-sm);

    &-icon {
      margin-right: calc(var(--hd) * 5);
      color: var(--main-fc);
    }

    &-text {
      height: calc(var(--hd) * 14);
      font-size: var(--font-size-12);
      color: var(--main-fc);
    }

    &-text-rtl {
      transform: scaleX(1);
      direction: ltr;
    }
  }
}

.@{mSignaturePrefix}-signature-sliderBtn {
  height: calc(33 * var(--hd));
  width: calc(33 * var(--hd));
  border-radius: 50%;
  position: absolute;
  background: #8f8f8f;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--base-white);
}

.@{mSignaturePrefix}-signature-xslider {
  position: absolute;
  width: calc(100% - 170 * var(--hd));
  z-index: 2;
}

.@{mSignaturePrefix}-signature-yslider {
  position: absolute;
  height: calc(100% - 70 * var(--hd));
  z-index: 1;
}


.@{mSignaturePrefix}-horizontal_placeholder {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;

  &-wrap {
    z-index: -1;
    font-size: 60px;
    color: #D3D3D3;
    opacity: 0.7;
    text-wrap: nowrap;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.@{mSignaturePrefix}-horizontalTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  font-size: var(--font-size-lg);
  background: var(--regular-fc);
  color: rgb(255, 255, 255);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &-text {
    transform: rotate(-90deg);
    transform-origin: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}