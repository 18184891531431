.@{videoClsPrefix} {
  // width: 100%;

  &-close {
    position: absolute;
    right: calc(2 * var(--hd));
    top: calc(2 * var(--hd));
    // right: 0;
    // top: 0;
    z-index: 999;
    cursor: pointer;

    svg {
      width: calc(30 * var(--hd));
      height: calc(30 * var(--hd));
      color: var(--base-white);
    }
  }

  &-dialog {
    background: none;

    .@{dialogClsPrefix}-content {
      width: 100% !important;
      max-width: unset !important;
      // max-height: unset !important;
      max-height: 100% !important;
      background: var(--bg-transparent);
    }

    .@{dialogClsPrefix}-body {
      padding: 0;
      display: flex;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0);
    }

    .@{dialogClsPrefix}-body-container {
      max-height: 100% !important;
      background-color: rgba(0, 0, 0, 0);
    }
  }

  &-dialog-video_no_mask {
    .@{dialogClsPrefix}-content {
      box-shadow: unset;
    }
  }

  &-width {
    width: 100%;
  }

  &-height {
    height: 100%;
  }

  &-video {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;

    video {
      height: 100%;
      max-width: 100%;
      // object-fit: fill
    }

    video::-internal-media-controls-overflow-button {
      // display: none !important;
    }
  }

  &-rtl {
    transform: scaleX(-1);
  }
}