.@{cardDetailFormClsPrefix}, .@{mCardDetailFormClsPrefix} {
  &-loadingComponent  {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    &-tip {
      font-size: var(--font-size-12);
      color: var(--secondary-fc);
      padding-left: var(--h-spacing-sm);
      padding-top: calc(3 * var(--hd));
    }
    .Icon-Loading {
      color: var(--secondary-fc);
    }
  }
}