.@{mAuthClsPrefix} {
  height: 100%;
  .@{spinClsPrefix}-nested-loading, .@{spinClsPrefix}-container {
    height: 100%;
  }
  &-form {
    height: 100%;
    padding-bottom: var(--m-dialog-button-group-height);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: var(--m-bg-base);
  }
  &-extendObj{
    .@{formItemClsPrefix}-noLabel .@{formSwitchClsPrefix}-CHECKBOX {
      padding-top: 0;
      padding-bottom: 0;
      .border-solid-then;
    }
    .@{formItemClsPrefix}-noLabel .@{mCcheckboxClsPrefix}-group .@{mCcheckboxClsPrefix}-label::after, .@{formItemClsPrefix}-noLabel .@{mCcheckboxClsPrefix}-group .@{mCcheckboxClsPrefix}-wrapper-hold-right:not(:last-child)::after {
      display: none;
    }
    &:after {
      display: none;
    }
  }
}

/* 镜像 */
.@{mAuthClsPrefix}-form.needRTL {
  .@{formItemClsPrefix}-mobile .@{formItemClsPrefix}-label-noWholeLine {
    padding-right: 0;
    padding-left: var(--h-spacing-lg);
  }
}