@import './cropper.m.less';


.@{mImageCropperClsPrefix} {

  input[type='file'] {
    display: none;
  }

  &-cropper-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &-default-preview {
    display: block;
    width: 100%;
    height: 100%;
  }

  &-empty-icon {
    display: block;
    text-align: center;
    background-color: #f2f2f2;
    // min-height: 200px;
    // line-height: 200px;
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    svg {
      width: 40px;
      height: 40px;
      color: rgb(187, 187, 187);
    }
  }

  &-img {
    &-preview {
      position: relative;
      margin: 0 auto;
      overflow: hidden;
      border-radius: 50%;
      background: #f2f2f2;

      &>*:not(img) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-box {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      &-hidden {
        display: none;
      }
    }

    &-box-rtl {
      transform: scaleX(-1);
    }
  }

  &-photo {
    &-preview {
      position: relative;
      margin: 0 auto;
      overflow: hidden;
      background-color: #f2f2f2;

      &>*:not(img) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }


  &-bottom {
    display: flex;
    padding: 0 15px;
    margin-top: 10px;
    justify-content: flex-end;

    button {
      margin-right: 10px;
    }
  }
}

.@{mImageCropperClsPrefix}-preview-container {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 8px;

  &-rtl {
    transform: scaleX(-1);
  }

  &-clone {
    position: absolute;
    display: flex;
    // top: 0;
    // left: 0;
    // height: calc(100% + 1px);
    // width: calc(100% + 1px);
    // transform: translate(-0.5px, -0.5px);
    // z-index: var(--image-cropper-mask-zIndex);
  }

  &-photo-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-color);
  }
}

.@{mImageCropperClsPrefix}-container {

  &-photo2 {
    width: 0px;
    height: 0px;
    overflow: hidden;
  }
}

.@{mImageCropperClsPrefix}-dialog {

  .@{mDialogClsPrefix}-wrap {
    top: unset !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-hide {
      display: none;
    }
  }

  &-content {
    width: 100%;
    height: 100%;
    background-color: var(--bg-white);
    padding-top: calc(20 * var(--hd));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--base-black);
  }

  &-toolBar {
    height: calc(30 * var(--hd));
    padding: 0 calc(25 * var(--hd));
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    &-icon {
      color: var(--base-white);
    }

    &-icon-rtl {
      transform: scaleX(1);
    }
  }

  &-toolBar-rtl {
    transform: scaleX(-1);
  }

  &-footer {
    position: absolute;
    bottom: calc(20 * var(--hd));
    width: 100%;
    display: flex;
    align-items: center;
    height: calc(50 * var(--hd));

    &-item,
    &-item-middle {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(30 * var(--hd));
      font-size: var(--font-size-16);
      color: var(--base-white);
    }

    &-item-middle {
      flex: 2;
    }
  }
}

.@{mImageCropperClsPrefix}-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px;

  &-tip {
    font-size: var(--font-size-14);
    color: var(--regular-fc);
    padding: calc(10 * var(--hd)) 0;
    margin: 0 calc(10 * var(--hd));
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-tip-bottom {
    margin-top: calc(30 * var(--hd));
  }

  &-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    height: calc(50 * var(--hd));
    border-top: 1px solid var(--border-color);

    &-item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(30 * var(--hd));
      font-size: var(--font-size-16);
      color: var(--primary);

      input[type='file'] {
        display: none;
      }
    }

    &-item-middle {
      border-left: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
    }
  }
}

.@{mImageCropperClsPrefix}-photo {
  // max-width: 800px;
  position: relative;

  &-preview-clone {
    position: absolute;
    // width: 120px;
    // height: 160px;
    background: #f2f2f2;
    display: flex;

    &-img {
      width: unset !important;
      height: unset !important;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      flex: 1; // ie 11 max-width 失效，使用 flex 撑满
      object-fit: contain;
    }
  }
}