.@{timelineClsPrefix} {
    list-style: none;
    margin: 0;
    padding: 0;

    &-item {
        // position: relative;
        // padding: 0 0 12px 0;
        list-style: none;
        margin: 0;
        display: flex;

        &-tail {
            position: absolute;
            // left: 5px;
            left: calc(50% - 1px);
            top: 0;
            height: 100%;
            border-left: 1px solid #E5E5E5;
        }

        &-content-left {
            // display: flex;
            font-size: 12px;
          &.need-mirror-RTL {
            text-align: right;
          }
        }

        &-content-middle {
            position: relative;
            // display: flex;
        }

        &-content {
            // display: flex;
          &:not(.need-mirror-RTL) {
            padding: calc(6 * var(--hd)) 0 calc(10 * var(--hd)) calc(24 * var(--hd));
          }
          &.need-mirror-RTL {
            padding: calc(6 * var(--hd)) calc(24 * var(--hd)) calc(10 * var(--hd)) 0;
          }
            position: relative;
            top: -3px;
        }

        &-head {
            position: absolute;
            width: 22px;
            height: 22px;
            border-radius: 100px;
            left: calc(50% - 11px);
            text-align: center;
            box-shadow: 0 0 0 6px #f9f9f9;
            // background-color: #E5E5E5;
            background-color: rgb(213, 213, 213);

            .ui-icon {
                width: 100%;
                height: 100%;
                vertical-align: top;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            .ui-avatar {
                width: 100%;
                height: 100%;
                
                .ui-avatar-container {
                    width: 100%;
                    height: 100%;
                }
            }
            .dotTop {
                padding:6px 2px;
                position: absolute;
                top: -32px;
                left: -15px;
                color: #999;
                line-height: 1;
                background-color: var(--bg-base);
            }
        }

        &-dotbottom {
            margin-top: 12px;
            padding: 4px;
            width: 100%;
            position: absolute;
            top: 2px;
            text-align: center;
            font-size: 12px;
            color: #333;
            background-color: var(--bg-base);
        }
    }

    &-item:last-child {

        .@{timelineClsPrefix}-item-tail {
            display: none;
        }
    }

    &&-pending &-item-last &-item-tail {
        display: block;
    }

  &-container{
  }

  &-pagination {
    &-more {
      color: var(--secondary-fc);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: var(--font-size-12);

      &:hover {
        color: #5d9cec;
      }
    }

    &-all {
      font-size: var(--font-size-12);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--secondary-fc);
    }
  }
}
