/**
* 列表公共样式
*/
.@{uploadClsPrefix} {

  &-listT,
  &-listB {
    margin-top: calc(5 * var(--hd));

    &-has-button {
      margin-top: calc(10 * var(--hd));
    }

    &-hide-button {
      margin-top: 0;
    }
  }

  /** more 按钮，popover 按钮 **/
  &-popover-span {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

/*********************************************************************************************/
/*******************************  列表样式 - listType = 'list'  *******************************/
/*********************************************************************************************/

/**
* 已上传列表样式
*/
.@{uploadClsPrefix} {
  &-listT-item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    color: var(--secondary-fc);
    position: relative;
    // line-height: var(--upload-list-item-height);
    align-items: center;
    /*修改*/
    // padding: 0 calc(10 * var(--hd));
    /*修改*/
    line-height: 1;

    /** 特殊样式 - 渲染 **/
    &:hover {
      background: var(--label-bc);
    }
  }

  &-listT-item.flex-wrap {
    flex-wrap: wrap;
  }

  /** 附件已删除 */
  &-listT-item.is-deleted,
  &-listT-item.is-undefined {

    //.@{uploadClsPrefix}-listT-item-body {
    //  filter: grayscale(100%);
    //}
    //&.is-IE {
    .@{uploadClsPrefix}-listT-item-signtip,
    .@{uploadClsPrefix}-listT-item-tip {
      color: var(--regular-fc);
    }

    //}
  }

  &-listT-item.is-deleted:not(.is-undefined) {
    .@{uploadClsPrefix}-listT-item-filename {
      text-decoration: line-through;
    }
  }

  &-listT-item.is-undefined {
    .@{uploadClsPrefix}-listT-item-filename {
      margin-top: calc(8 * var(--hd));
    }
  }

  /*******************************  左侧区域  *******************************/
  &-listT-item-span {
    display: flex;
    // align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    // min-width: 150px;

    // margin-right: calc(10 * var(--hd));
    /*修改*/
    // min-height: var(--upload-list-item-height);
    /*修改*/
    /*修改*/
    // margin-left: calc(-13 * var(--hd));
  }

  // &-listT-item-span-sortable {
  //   margin-left:
  // }

  &-listT-item {

    /** 角标 */
    &-badge {
      position: absolute;
      top: calc(-2 * var(--hd));
      &.is-sortable {
        left: calc(22 * var(--hd));
      }
    }
    /** 文件图标 **/
    &-icon.@{iconClsPrefix} {
      // float: left;
      // height: var(--upload-list-item-height);
      display: flex;
      align-items: center;
      margin-top: calc(8 * var(--hd));
      margin-left: calc(8 * var(--hd));
      margin-right: calc(8 * var(--hd));

      &.@{uploadClsPrefix}-handle {
        margin-right: 0;
        cursor: pointer;
        color: var(--secondary-fc);
      }
    }

    /** 文件名称 **/
    &-filename {
      // float: left;
      margin-top: calc(5 * var(--hd));
      color: var(--regular-fc);
      text-decoration: none;
      min-width: calc(22 * var(--hd));
      // max-width: calc(100% - calc(350 * var(--hd)));
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
      line-height: calc(20 * var(--hd));
    }

    /** 文件名称 - 重命名 input 框 **/
    &-input {
      // max-width: calc(100% - calc(350 * var(--hd)));
      min-width: calc(80 * var(--hd));
      // float: left;
    }

    /** 文件大小 **/
    &-filesize {
      // float: left;
      margin-top: calc(5 * var(--hd));
      color: var(--regular-fc);
      margin-left: calc(10 * var(--hd));
      line-height: calc(20 * var(--hd));
    }

    /** 密级信息 **/
    &-span &-seclevel {
      margin-left: calc(10 * var(--hd));
    }

    /** 签署状态 **/
    &-signtip {
      //margin-top: calc(5 * var(--hd));
      //padding: 0 calc(5 * var(--hd));
      color: var(--upload-signtip-color);
      // line-height: var(--upload-list-item-height);
      //white-space: nowrap;
      // float: left;
      line-height: calc(20 * var(--hd));
      white-space: normal;
      word-wrap: break-word;
    }
  }

  /*******************************  右侧区域 - 文字信息  *******************************/
  &-listT-item {

    /** 右侧区域外层容器 **/
    &-right {
      display: flex;
      align-items: center;
      margin-left: calc(32 * var(--hd));

      // margin-top: calc(-5 * var(--hd));
      .@{uploadClsPrefix}-listT-item-seclevel,
      .@{uploadClsPrefix}-listT-item-uploaddate,
      .@{uploadClsPrefix}-listT-item-username {
        margin-right: calc(10 * var(--hd));
      }

      .@{uploadClsPrefix}-listT-item-seclevel,
      .@{uploadClsPrefix}-listT-item-username {
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: break-spaces;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      /*修改内容*/
      &-sortable {
        margin-left: calc(54 * var(--hd));
      }

      /*修改内容*/
    }

    /** 时间 **/
    &-uploaddate {
      // width: calc(124 * var(--hd));
    }

    /** 申述提醒 **/
    &-tip {
      color: var(--upload-errortip-color);
    }

    /************* 无用 *************/
    &-errorinfo {
      color: var(--danger);
    }
  }

  /** 无换行 **/
  &-listT-item:not(.wrap) {
    .@{uploadClsPrefix}-listT-item {
      &-span {
        display: flex;
        align-items: center;
      }

      &-filesize,
      &-filename,
      &-icon.ui-icon {
        margin-top: 0;
      }
    }
  }

  /*******************************  右侧区域 - 操作按钮  *******************************/
  &-listT-item {

    /** 操作按钮外层容器 **/
    &-oparate {
      display: none;
      color: var(--regular-fc);
      height: 100%;
      padding: calc(7 * var(--hd)) 0;
      margin-right: calc(10 * var(--hd));
    }

    /** 操作按钮 **/
    &-opt {
      margin-left: calc(14 * var(--hd));
      // 做了修改
      display: flex;
      align-items: center;
      line-height: 1;
      cursor: pointer;

      // 文字样式
      .label {
        margin-left: calc(4 * var(--hd));
      }
    }

    /** 操作按钮 - 特殊样式（首位） **/
    &-opt:first-child {
      margin-left: 0;
    }

    /** 操作按钮（更多）外层容器 **/
    &-more {
      position: relative;
    }

    /** 操作按钮（更多） **/
    &-opt-more {
      z-index: 2;
      height: 100%;
      width: 100%;
      // margin-left: calc(14 * var(--hd));
      display: flex;
      align-items: center;
      line-height: 1;
      cursor: pointer;

      // 文字样式
      .label {
        margin-left: calc(4 * var(--hd));
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-items: center;
      }
    }

    /** 删除按钮 **/
    &-delete {
      color: var(--invalid-fc);
      display: flex;
      // margin-left: calc(14 * var(--hd));
      align-items: center;
      line-height: 1;
      cursor: pointer;
    }

    /** 操作/更多 按钮 - 特殊状态（悬浮） */
    &-opt:hover,
    &-more:hover {
      color: var(--primary);
    }

    /************* 无用 *************/
    &-cancel {
      // float: right;
      line-height: var(--upload-list-item-height);
      cursor: pointer;
    }
  }

  /*******************************  右侧区域 - 更多按钮 - 操作按钮 *******************************/
  &-listT-item-tigger-popup,
  &-listT-item-tigger-popup-more {
    font-size: var(--font-size-12);
    margin-left: calc(4 * var(--font-size-12) + 32 * var(--hd));
    // 分割线
    .has-separator {
      border-bottom: var(--hd) solid var(--border-color);
    }

    & ul {
      margin: 0;
      top: calc(23 * var(--hd));
      right: 0;
      z-index: 99999;
      box-shadow: 0 calc(3 * var(--hd)) calc(12 * var(--hd)) 0 rgb(0 0 0 / 12%);
      border: var(--hd) solid var(--border-color);
      border-radius: calc(2 * var(--hd));
      color: var(--regular-fc);
      background-color: var(--bg-white);
      text-align: center;
      padding: calc(8 * var(--hd)) 0;

      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        list-style-type: none;
        text-align: left;
        min-width: calc(120 * var(--hd));
        cursor: pointer;
        padding: calc(8 * var(--hd)) calc(16 * var(--hd));

        &>span:nth-of-type(2) {
          margin-left: calc(12 * var(--hd));
        }
      }
    }

    &:hover ul {
      display: block;
    }

    li:hover {
      background-color: var(--label-bc);
      color: var(--primary);
    }
  }

  &-listT-item-tigger-popup-more {
    margin-left: calc(0 * var(--hd));
  }

  /*******************************  左侧区域 - 特殊样式 - hover *******************************/
  &-listT-item {

    /** hover 更新文件名称样式 **/
    &-hover &-filename {
      cursor: pointer;
      color: var(--regular-fc);

      &-canPreview {
        color: var(--primary);
      }
    }

    /** hover 更新文件大小样式 **/
    &-hover &-filesize {
      text-decoration: none;
    }
  }

  /** 已删除附件需要特殊处理 */
  &-listT-item-hover.is-deleted,
  &-listT-item-hover.is-undefined {
    .@{uploadClsPrefix}-listT-item-filename {
      cursor: default;
    }
  }

  /*******************************  右侧区域 - 特殊样式 - hover *******************************/
  &-listT-item {

    /** hover 隐藏 时间、密级、申述信息 **/
    &-hover:not(.wrap) &-uploaddate,
    &-hover:not(.wrap) &-username,
    &-hover:not(.wrap) &-seclevel,
    &-hover:not(.wrap) &-tip {
      display: none;
    }

    // &-hover.wrap:not(.optionPopover, .optionHover) &-uploaddate,
    // &-hover.wrap:not(.optionPopover, .optionHover) &-username,
    // &-hover.wrap:not(.optionPopover, .optionHover) &-seclevel,
    // &-hover.wrap:not(.optionPopover, .optionHover) &-tip {
    //   display: none;
    // }

    /** 操作按钮（外层容器） **/
    &-hover.wrap {
      .@{uploadClsPrefix}-listT-item-oparate {
        display: flex;
        // padding: 0 calc(4 * var(--hd));
        height: calc(20 * var(--hd));
        // margin-left: calc(25 * var(--hd));
      }
    }

    /** hover 显示工具栏  **/
    &-hover &-oparate {
      display: flex;
      opacity: 1;
    }
  }

  &-listT-item.read-only-line-height-standard {
    line-height: var(--form-pc-readonly-line-height);
    height: auto;
    margin-top: 0;

    .@{uploadClsPrefix}-listT-item-filename {
      line-height: var(--form-pc-readonly-line-height);
      margin-top: 0;
    }

    .@{uploadClsPrefix}-listT-item-uploaddate {
      line-height: var(--form-pc-readonly-line-height);
    }

    .@{uploadClsPrefix}-listT-item-filesize {
      line-height: var(--form-pc-readonly-line-height);
    }
  }
}

/**
* 待上传列表样式
*/
.@{uploadClsPrefix} {

  /*******************************  顶部区域 - 文件信息 *******************************/
  &-listB-item {
    width: 100%;
    color: var(--regular-fc);
    position: relative;
    // line-height: calc(25 * var(--hd));
    padding: calc(4 * var(--hd)) calc(10 * var(--hd));

    /** 外层容器 **/
    &-content {
      position: relative;
      display: flex;
      width: 100%;
      //height: calc(20 * var(--hd));
      // line-height: calc(20 * var(--hd));
      line-height: 1;
      align-items: flex-start;
      padding-top: calc(5 * var(--hd));
    }

    &-unsortable {
      padding-left: calc(29 * var(--hd));
    }

    // &-left {
    //   position: relative;
    //   display: flex;
    //   width: 100%;
    //   height: calc(20 * 1px);
    //   height: calc(20 * var(--hd));
    //   line-height: calc(20 * 1px);
    //   line-height: calc(20 * var(--hd));
    // }
    /** 文件图标 **/
    &-icon.@{iconClsPrefix} {
      color: var(--secondary-fc);
      // margin-left: calc(5 * var(--hd));
      //margin-top: calc(5 * var(--hd));
      margin-right: calc(5 * var(--hd));
      display: flex;
      align-items: center;
    }

    /** 文件名称 **/
    &-filename {
      color: var(--regular-fc);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      max-width: 100%;
      white-space: normal;
      word-wrap: break-word;
      line-height: calc(20 * var(--hd));
      //margin-top: calc(5 * var(--hd));
    }

    /** 文件大小/密级信息 **/
    &-filesize,
    &-seclevel {
      margin-left: calc(10 * var(--hd));
      //margin-top: calc(5 * var(--hd));
      line-height: calc(20 * var(--hd));
    }

    &-errorinfo,
    &-analysis-info {
      // float: right;
      margin: calc(4 * var(--hd)) calc(20 * var(--hd)) 0;
      white-space: normal;
    }

    /** 错误提示信息 **/
    &-errorinfo {
      color: var(--danger);
      white-space: normal;
      word-wrap: break-word;
      line-height: calc(20 * var(--hd));
    }

    /** 文件解析进度 */
    &-analysis-info {
      color: var(--secondary-fc);
    }

    /** 操作按钮外层容器 **/
    &-opt {
      display: inline-flex;
      margin-left: auto;
      //margin-top: calc(5 * var(--hd));
      //padding: calc(7 * var(--hd)) 0;
      margin-right: calc(10 * var(--hd));
    }

    &-more {
      margin-left: calc(14 * var(--hd));
      display: flex;
      align-items: center;
      line-height: 1;
      cursor: pointer;
    }

    /** 操作按钮（
    更多/取消） **/
    &-opt-more,
    &-cancel,
    &-breakpoint-resumption {
      cursor: pointer;
      padding-right: calc(5 * var(--hd));
      // color: var(--invalid-fc);
      // color: var(--regular-fc);
      color: var(--secondary-fc);
      margin-left: calc(4 * var(--hd));
      display: flex;
      align-items: center;
      line-height: 1;
    }

    /** 操作按钮（更多） **/
    &-opt-more :hover {
      color: var(--primary);
    }

    /** 操作按钮（取消） **/
    &-cancel:hover {
      // color: var(--secondary-fc);
      color: var(--primary);
    }

    &-breakpoint-resumption {
      color: var(--primary);
      // display: none;
    }
  }

  &-listB-item:hover {
    background: var(--label-bc);

    .@{uploadClsPrefix}-listB-item {
      &-breakpoint-resumption {
        color: var(--primary);
      }
      &-filename {
        -webkit-line-clamp: unset;
      }
    }
  }

  /*******************************  顶部区域 - 更多按钮 - 操作按钮 *******************************/
  &-listB-item-tigger-popup {
    font-size: var(--font-size-12);

    & ul {
      margin: 0;
      top: calc(23 * var(--hd));
      right: 0;
      z-index: 99999;
      box-shadow: 0 calc(3 * var(--hd)) calc(12 * var(--hd)) 0 rgb(0 0 0 / 12%);
      border: var(--hd) solid var(--border-color);
      border-radius: calc(2 * var(--hd));
      color: var(--regular-fc);
      background-color: var(--bg-white);
      text-align: center;
      padding: calc(8 * var(--hd)) 0;

      li:hover {
        background-color: var(--label-bc);
        color: var(--primary);
      }

      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        list-style-type: none;
        text-align: left;
        min-width: calc(120 * var(--hd));
        cursor: pointer;
        padding: calc(8 * var(--hd)) calc(16 * var(--hd));

        &>span:nth-of-type(2) {
          margin-left: calc(12 * var(--hd));
        }
      }
    }

    &:hover ul {
      display: block;
    }
  }

  /*******************************  底部区域 - 上传进度 *******************************/
  &-listB-item {

    /** 进度条外层容器 **/
    &-slider-box {
      position: relative;
      display: flex;
      align-items: center;
      // height: calc(18 * var(--hd));
      // line-height: calc(18 * var(--hd));
      margin-top: calc(4 * var(--hd));
    }

    /** 进度条 **/
    &-slider {
      width: 100%;
      margin-top: 0;
      margin-left: calc(20 * var(--hd));
      margin-right: calc(60 * var(--hd));
    }

    /** 进度（文字） **/
    &-percent {
      font-size: var(--font-size-12);
      color: var(--secondary-fc);
      position: absolute;
      right: calc(10 * var(--hd));
      bottom: 0;
    }
  }
}

/*******************************************************************************/
/*******************************  特殊样式 - 换行  *******************************/
/*******************************************************************************/

/**
* 已上传列表样式
*/
.@{uploadClsPrefix} {
  &-listT-item.wrap {
    line-height: calc(20 * var(--hd));
    display: block;
    margin-bottom: calc(2 * var(--hd));
    // padding-top: calc(4 * var(--hd));
    // padding-bottom: calc(4 * var(--hd));
  }

  &-listT-item.wrap.read-only-line-height-standard {
    line-height: var(--form-pc-readonly-line-height);
    height: auto;
    margin-top: 0;

    .@{uploadClsPrefix}-listT-item-filename {
      line-height: var(--form-pc-readonly-line-height);
      margin-top: 0;
    }

    .@{uploadClsPrefix}-listT-item-uploaddate {
      line-height: var(--form-pc-readonly-line-height);
    }

    .@{uploadClsPrefix}-listT-item-filesize {
      line-height: var(--form-pc-readonly-line-height);
    }
  }

  /*******************************  左侧区域  *******************************/
  &-listT-item {
    &.wrap &-icon {
      // line-height: calc(25 * var(--hd));
      display: block;
    }

    /** 换行显示文件名称 **/
    &.wrap &-filename {
      margin-top: calc(5 * var(--hd));
      margin-bottom: 0;
      // overflow: visible;
      // padding-top: calc(7 * var(--hd));
      line-height: calc(20 * var(--hd));
      word-break: break-word;
      overflow-wrap: break-word;
      white-space: normal;
      word-wrap: break-word;
      // max-width: calc(100% - calc(50 * var(--hd)));
      text-overflow: unset;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &.hasSignTip {
        // max-width: calc(100% - calc(130 * var(--hd)));
      }
    }

    /**  文件大小/签批功能 **/
    &.wrap &-filesize,
    &.wrap &-signtip {
      // float: unset;
      text-decoration: none;
    }
  }

  /*******************************  右侧区域  *******************************/
  &-listT-item {

    /** 上传时间 **/
    &.wrap &-uploaddate {
      // margin-left: calc(25 * var(--hd));
      color: var(--secondary-fc);
    }

    /** 特殊状态 - hover **/
    // &-hover.wrap {
    //   /** 密级/时间/申述信息 **/
    //   .@{uploadClsPrefix}-listT-item-seclevel,
    //   .@{uploadClsPrefix}-listT-item-uploaddate,
    //   .@{uploadClsPrefix}-listT-item-username,
    //   .@{uploadClsPrefix}-listT-item-tip {
    //     display: none;
    //   }
    //   /** 操作按钮（外层容器） **/
    //   .@{uploadClsPrefix}-listT-item-oparate {
    //     display: flex;
    //     padding: 0 calc(4 * var(--hd));
    //     height: calc(25 * var(--hd));
    //     // margin-left: calc(25 * var(--hd));
    //   }
    // }
  }


  /*******************************  鼠标移入  *******************************/
  &-listT-item-hover.wrap {
    .@{uploadClsPrefix}-listT-item {
      &-filename {
        -webkit-line-clamp: unset;
      }
    }
  }
}

/*******************************************************************************/
/*******************************  特殊样式 -简易  *******************************/
/*******************************************************************************/
.@{uploadClsPrefix} {
  /** 已上传列表外层容器 **/

  /** 待上传列表外层容器 **/

  &-listT-item-simple {
    display: flex;
    // padding-left: 0;
    line-height: var(--upload-list-item-height);
    flex-wrap: nowrap;
    justify-content: space-between;

    .@{uploadClsPrefix}-listT-item {

      &-oparate,
      &-opt-more {
        margin-left: 0;
        margin-right: 0;
      }

      &-input {
        max-width: 100%;
      }
    }
  }

  &-listT-item-simple.@{uploadClsPrefix}-listT-item-hover {
    .@{uploadClsPrefix}-listT-item {
      &-span {
        // max-width: calc(100% - calc(40 * var(--hd)));
      }
    }
  }
}

/*********************************************************************************************/
/*******************************  特殊样式 - listType = 'img'  *******************************/
/*********************************************************************************************/

// 通用样式
.@{uploadClsPrefix} {
  &-listT-item-box {
    position: relative;
  }

  &-listT-item-img {

    /** 图片外层容器 **/
    &-box {
      background-color: var(--upload-image-bgcolor);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;

      &.is-deleted {
        background-color: transparent;
        outline: var(--upload-outline);
      }

      img {
        max-width: 100%;
        max-height: 100%;
        flex: 1 1 auto; // ie 11 max-width 失效，使用 flex 撑满
        object-fit: contain;
      }
    }

    // 已删除/不存在的附件
    &-deleted {
      &-box {
        width: 100%;
        height: 100%;
        // color: #808080;
        color: #C0C0C0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-content {
        text-align: center;
      }

      &-text {
        font-size: var(--font-size-12);
        margin-top: calc(6 * var(--hd));
        color: var(--secondary-fc);
      }
    }

    &-popup-content {
      text-align: center;
    }
  }

  /** 图片外层容器 **/
  &-listT-item-img-box {
    background-color: var(--upload-image-bgcolor);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    img {
      max-width: 100%;
      max-height: 100%;
      flex: 1 1 auto; // ie 11 max-width 失效，使用 flex 撑满
      object-fit: contain;
    }
  }
}

/*******************************  特殊样式 - imgListType = 'horizontal'  *******************************/
.@{uploadClsPrefix} {
  &-listT-item-imglist-h {
    position: relative;
    padding: calc(8 * var(--hd));

    // margin-right: calc(8 * var(--hd));
    &:hover {
      background-color: var(--label-bc);
    }

    /** 操作按钮 **/
    &-oparate {
      display: none;
    }

    /** 操作按钮（hover） **/
    &:hover &-oparate {
      display: inline-block;
      text-align: center;

      position: absolute;
      right: calc(6 * var(--hd));
      bottom: calc(6 * var(--hd));
      background: rgba(0, 0, 0, 0.4);
      border-radius: calc(3 * var(--hd));
      color: var(--base-white);

      &>span {
        cursor: pointer;
        margin: calc(3 * var(--hd)) calc(4 * var(--hd));
      }
    }
  }
}

/*******************************  特殊样式 - imgListType = 'vertical'  *******************************/
.@{uploadClsPrefix} {
  &-listT-item-imglist-v {
    width: 100%;
    cursor: pointer;
    padding: calc(5 * var(--hd));

    &:hover {
      background: var(--hover-bc);
    }

    /** 操作按钮 **/
    &-op {
      display: none;

      &>span {
        margin-right: calc(5 * var(--hd));
      }
    }

    /** 操作按钮（hover） **/
    &:hover &-op {
      display: block;
      position: absolute;
      top: calc(45 * var(--hd));
      right: calc(10 * var(--hd));
    }
  }
}

/*******************************  litType = 'img'，图片上传的上传按钮需要已上传的在一行显示  *******************************/
// .@{uploadClsPrefix} {
//   &-line-of-row {
//     .@{uploadClsPrefix}-listT-item-imglist-h {
//       padding-top: 0;
//     }
//   }
// }

/*********************************************************************************************/
/**************************************  特殊样式 - 评论  **************************************/
/*********************************************************************************************/
.@{uploadClsPrefix} {

  // 待上传/已上传通用评论样式
  &-listT,
  &-listB {
    &-comment {
      .@{uploadClsPrefix} {
        &-listT,
        &-listB {
          &-item {
            line-height: initial;
            align-items: center;
            // flex-wrap: wrap;
            padding: 0;

            &>span:first-child {
              display: flex;
              align-items: center;
              overflow: hidden;
            }

            &-opt {
              padding: 0;
            }

            &-filename {
              max-width: none;
            }

            &-uploaddate,
            &-seclevel,
            &-username {
              display: none;
            }

            &-filesize {
              color: var(--secondary-fc);
            }

            &-icon {
              height: auto;
              line-height: 0;

              .@{iconClsPrefix}-svg {
                vertical-align: middle;
              }
            }

            &-right {
              // float: none;
              height: auto;
              padding: calc(4 * var(--hd)) calc(4 * var(--hd)) calc(4 * var(--hd)) 0;
            }

            &-oparate {
              padding: 0;
            }

            // 图片样式调整
            &-imglist-h {
              &-oparate {
                display: none;
              }

              &-comment-delete {
                display: none;
                position: absolute;
                top: calc(2 * var(--hd));
                right: calc(2 * var(--hd));
                color: var(--base-white);
                background-color: var(--m-upload-delete-btn-backgroud);
                border-radius: calc(10 * var(--hd));
                cursor: pointer;
              }

              &-des {
                font-size: var(--font-size-12);
                text-align: center;
                color: var(--regular-fc);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: calc(4 * var(--hd));
                cursor: pointer;

                &-filesize,
                &-filename {
                  display: inline-block;
                }

                &-filename {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  //display: -webkit-box;
                  //-webkit-line-clamp: 3;
                  //-webkit-box-orient: vertical;
                  //white-space: normal;
                  //word-wrap: break-word;
                  overflow: hidden;
                }
              }

              &-des:hover {
                color: var(--primary);
              }

              &-comment-oparate {
                color: var(--regular-fc);

                :hover {
                  color: var(--primary);
                  cursor: pointer;
                }
              }
            }

            &-imglist-h:hover {
              .@{uploadClsPrefix}-listT-item-imglist-h-comment-delete {
                display: inline-block;
              }
            }
          }
        }

        &-listT-item {
          display: flex;
        }

        &-listB-item {
          &:not(.is-error) {
            display: flex;
          }

          &.is-error {
            .@{uploadClsPrefix}-listB-item-slider-box {
              display: none;
            }
          }
        }
      }

      .@{uploadClsPrefix}-list .@{listClsPrefix}-content {
        min-height: auto;
      }

      .@{listClsPrefix}-item {
        margin-bottom: calc(1 * var(--hd));
        max-width: 100%;
      }
    }
  }

  // 待上传列表样式
  &-listB-item-comment {
    &.@{uploadClsPrefix}-listB-item {
      margin-bottom: 0;
      overflow: hidden;
      padding-top: calc(8 * var(--hd));
    }

    .@{uploadClsPrefix}-listB-item {
      &-icon {
        margin-left: calc(var(--hd) * 8);
        margin-right: calc(var(--hd) * 8);
      }

      &-percent {
        display: inline-block;
        position: static;
      }

      &-content {
        height: auto;
        line-height: initial;
        flex: auto;
        // width: 50%;
        padding: calc(4 * var(--hd)) calc(4 * var(--hd)) calc(4 * var(--hd)) 0;
      }

      &-slider {
        display: none;

        &-box {
          display: inline-block;
          flex: none;
          margin-top: 0;
          margin-left: calc(5 * var(--hd));
        }
      }

      &-filename {
        min-width: calc(20 * var(--hd));
      }

      // &-filesize,
      // &-seclevel {
      //   flex: auto;
      // }
      &-seclevel {
        display: inline-block;
      }

      &-cancel {
        opacity: 0;
        position: static;
        flex: none;
        margin: 0 calc(5 * var(--hd));
      }
    }

    &:hover {
      .@{uploadClsPrefix}-listB-item-cancel {
        opacity: 1;
      }
    }
  }

  // 已上传列表样式
  &-listT-item-comment {
    .@{uploadClsPrefix}-listT-item {
      &-slider {
        display: none;

        &-box {
          display: inline-block;
          position: absolute;
        }
      }

      &-span {
        padding: calc(4 * var(--hd)) calc(4 * var(--hd)) calc(4 * var(--hd)) 0;
        min-height: auto;
        width: initial;
      }

      &-signtip {
        padding-top: 0;
        flex: auto;
        //margin-left: calc(-10 * var(--hd));
        margin-right: calc(10 * var(--hd));
      }

      &-filename {
        max-width: none;
      }

      &-seclevel {
        display: inline-block;
      }

      &-uploaddate {
        display: none;
      }

      &-username {
        display: none;
      }

      &-more {
        height: auto;
      }

      &-opt {
        .label {
          margin-left: 0;
        }

        &:first-child {
          margin-left: calc(5 * var(--hd));
        }
      }

      &:hover {
        .@{uploadClsPrefix}-listB-item {
          &-cancel {
            opacity: 1;
          }
        }
      }
    }
  }

  // 已上传列表样式（hover 模式）
  &-listT-item-comment.@{uploadClsPrefix}-listT-item-hover {
    .@{uploadClsPrefix}-listT-item {
      &-seclevel {
        display: inline-block;
      }
    }
  }

  // 评论图片样式（hover 模式）
  //&-listT-item-imglist-h-hover {
  //  .@{uploadClsPrefix}-listT-item-imglist-h-des-filename {
  //    -webkit-line-clamp: unset;
  //  }
  //}
}

/*********************************************************************************************/
/***************************************  悬浮操作栏样式  **************************************/
/*********************************************************************************************/
.@{uploadClsPrefix} {
  &-row-tools {
    position: absolute;
    display: flex;
    // height: calc(28 * var(--hd));
    top: 0;
    border-radius: var(--border-radius-xs);
    z-index: 10;
    box-sizing: border-box;
    margin-left: calc(14 * var(--hd));

    &-opt {
      display: flex;
      padding: calc(6 * var(--hd)) 0 calc(6 * var(--hd)) calc(12 * var(--hd));
      line-height: 1;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;

      &:hover {
        color: var(--base-white);
        // opacity: 0.8;
      }

      cursor: pointer;

      // 文字样式
      .label {
        margin-left: calc(4 * var(--hd));
      }
    }

    &-icon {
      width: 16px;
      height: 16px;
    }

    &-more {
      /*修改*/
      height: 28px;
      padding: 0 calc(12 * var(--hd)); // 使用padding 避免tigger触发问题

      /*修改*/
      &:hover {
        color: var(--base-white);
      }
    }

    &-item {
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.8);
      opacity: 0.8;
      background: #151515;
      white-space: nowrap;
      cursor: pointer;

      &.no__btn {
        margin-left: calc(-4 * var(--font-size-12) - 14 * var(--hd));
      }

      &-no__moreBtn {
        padding-right: calc(12 * var(--hd));
      }

      // 处理为大按钮的情况下的样式
      &.no_drag {
        margin-left: calc(20 * var(--hd));
      }

      &:hover {
        .ui-icon {
          opacity: 1;
        }
      }

      &-sortable {
        &.no__btn {
          margin-left: calc(-4 * var(--font-size-12) + 8 * var(--hd));
        }
      }
    }
  }

  &-row-tools-hover {
    display: inline-block;
  }
}

/*********************************************************************************************/
/**********************************  特殊样式 - optionHover/optionPopover/optionCover/normal  **********************************/
/*********************************************************************************************/

/**
* 已上传列表样式 - optionHover
*/
.@{uploadClsPrefix} {
  &-listT-item.optionHover {
    position: relative;
    display: flex;
    align-items: flex-start;

    .@{uploadClsPrefix}-listT-item {
      &-body {
        margin-bottom: calc(2 * var(--hd));
        width: 100%;
      }

      // &-filename {
      //   margin-bottom: calc(2 * var(--hd));
      // }

      &-right {
        flex-wrap: wrap;
      }

      &-uploaddate,
      &-seclevel,
      &-username {
        white-space: pre-wrap;
      }

      &-seclevel,
      &-username {
        -webkit-line-clamp: unset;
      }

      &-toolbar {
        margin-top: calc(5 * var(--hd));
        line-height: calc(20 * var(--hd));
      }

      &-filesize {
        color: var(--secondary-fc);
        margin-right: calc(10 * var(--hd));
      }

      &-oparate {
        padding: 0;
        margin-top: calc(5 * var(--hd));
        height: calc(20 * var(--hd));
        margin-left: calc(10 * var(--hd));
      }

      &-opt {
        margin-left: calc(10 * var(--hd));
      }

      /** 操作按钮 - 特殊样式（首位） **/
      &-opt:first-child {
        margin-left: 0;
      }
    }
  }

  &-listT-item-hover.optionHover {
    .@{uploadClsPrefix}-listT-item {
      &-filesize {
        display: none;
      }
    }
  }
}

/**
* 已上传列表样式 - optionCover
*/
.@{uploadClsPrefix} {
  &-listT-item-hover.optionCover {
    .@{uploadClsPrefix}-listT-item {

      /** 密级/人员/时间 **/
      &-seclevel,
      &-uploaddate,
      &-username {
        display: none;
      }
    }
  }
}

/**
* comment 样式 -optionHover
*/
.@{uploadClsPrefix} {
  &-listT-item-comment.optionHover {
    .@{uploadClsPrefix}-listT-item {
      margin: 0;

      &-span {
        padding: calc(2 * var(--hd)) calc(4 * var(--hd)) calc(2 * var(--hd)) 0;
      }

      //&-right {
      //  display: none;
      //}

      &-append {
        margin-top: calc(2 * var(--hd));
      }
    }
  }
}
/**
* 表单只读行高样式优化
*/
.@{uploadClsPrefix}.read-only-line-height-standard {
  padding-top: var(--form-pc-readonly-padding-y);
  padding-bottom: var(--form-pc-readonly-padding-y);
  .@{uploadClsPrefix}-listT-item-icon.ui-icon {
    margin-top: 5px;
  }
  .@{uploadClsPrefix}-listT-item.optionHover {
    .@{uploadClsPrefix}-listT-item-toolbar,
    .@{uploadClsPrefix}-listT-item-oparate {
      margin-top: 0;
    }
  }
  .ui-list-content {
    min-height: auto;
  }
  .@{uploadClsPrefix}-listT-item-imglist-h {
    padding-top: calc(4 * var(--hd));
  }
}
