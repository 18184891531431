.@{mDateTimePickerClsPrefix} {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.@{mDateTimePickerClsPrefix}-quickWrap {
  display: flex;
  flex-direction: row;
  height: calc(44 * var(--hd));

  &-btn,
  &-span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-span {
    flex: 1;
  }
}

.@{mDateTimePickerClsPrefix}-hideIcon {
  .@{formItemClsPrefix}-item-icon {
    display: none;
  }
}

.@{mDateTimePickerClsPrefix}-disabled {
  pointer-events: none !important;

  .@{formItemClsPrefix}-item {

    &-content,
    &-icon {
      color: var(--invalid);
    }
  }

  .@{mDateTimePickerClsPrefix}-quickWrap {
    color: var(--invalid);
  }
}

.@{mDateTimePickerClsPrefix}-readOnly {
  pointer-events: none !important;

  .@{formItemClsPrefix}-item {

    &-content,
    &-icon {
      color: var(--m-readonly-text);
    }

    min-height: calc(44 * var(--hd));
  }

  .@{mDateTimePickerClsPrefix}-quickWrap {
    color: var(--m-readonly-text);

    .@{mDateTimePickerClsPrefix}-quickWrap-span {
      &.needRTL {
        transform: scaleX(-1);
        direction: rtl;
      }
    }
  }
}

.@{mDateTimePickerClsPrefix}-readOnly-readOnlyLineHeightStandard {

  .@{formItemClsPrefix}-item {
    .form-mobile-readOnlyLineHeightStandard;
  }
}

.@{mDateTimePickerClsPrefix}-readOnly-readOnlyLineHeightStandard-start {
  .@{formItemClsPrefix}-item {
    padding-bottom: unset;
    min-height: auto;
  }
}

.@{mDateTimePickerClsPrefix}-readOnly-readOnlyLineHeightStandard-end {
  .@{formItemClsPrefix}-item {
    padding-top: unset;
    min-height: auto;
  }
}

.@{mDateTimePickerClsPrefix} {
  &-nav {
    height: calc(50 * var(--hd));
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 calc(24 * var(--hd));
    .border-solid-then;

    &-left,
    &-right {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .cancel,
    .clear,
    .confirm,
    .setTime {
      font-size: var(--font-size-16);
      line-height: unset;
    }

    .cancel,
    .clear,
    .setTime {
      padding-right: calc(10 * var(--hd));
      color: var(--regular-fc);
    }
  }

  &-content {
    font-size: var(--font-size-12);
  }
}

.@{mDateTimePickerClsPrefix}-dialog {
  .@{mDialogClsPrefix}-content {
    border-radius: calc(16 * var(--hd)) calc(16 * var(--hd)) 0 0;
  }

  .@{mDialogClsPrefix}-top-bottom {
    padding: 0;
  }

  .@{mPickerViewClsPrefix}-container {
    height: calc(500 * var(--hd));
  }

  .@{mPickerViewClsPrefix}-item {
    padding: 0;
    font-size: var(--font-size-14);
  }

  .@{mPickerViewClsPrefix}-selected {
    font-weight: 500;
  }
}

.@{mDateTimePickerClsPrefix}-rangeDialog {
  // .@{mDialogClsPrefix}-content {
  //   border-radius: calc(16 * var(--hd)) calc(16 * var(--hd)) 0 0;
  // }

  .@{mDialogClsPrefix}-top {
    // background-color: var(--m-dialog-footer-bg);

    &-bottom {
      padding: 0;
    }
  }

  .@{mDialogClsPrefix}-footer {
    // background-color: var(--m-dialog-footer-bg);

    // &-btn{
    //   margin-top: 10px;
    // }
  }

  .@{formItemClsPrefix}-item {
    padding: 0 calc(16 * var(--hd));
    text-align: start;
  }

  .@{formItemClsPrefix}-item-label {
    color: var(--regular-fc);
  }
}

.@{mDateTimePickerClsPrefix}-action-sheet {
  .@{mActionSheetClsPrefix}-message {
    color: var(--primary)
  }
}