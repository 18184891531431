.@{browserClsPrefix}-quick-import-dialog {
  &-title.@{titleClsPrefix}-title {
    display: flex;
    .@{helpClsPrefix} {
      margin-left: calc(6 * var(--hd));
    }
    .@{helpClsPrefix}-explain {
      display: flex;
    }
  }
  &-content {
    font-size: var(--font-size-sm);
    &.is-loading,
    &.is-success,
    &.is-error {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: calc(500 * var(--hd));
    }
    &-success-icon {
      width: calc(60 * var(--hd));
      height: calc(60 * var(--hd));
      .ui-icon-svg {
        width: 100%;
        height: 100%;
      }
    }
    &-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: calc(500 * var(--hd));
      .@{spinClsPrefix} {
        height: auto;
      }
      & > span:not([class*="-"]) {
        display: none !important;
      }
    }
    &-paragraph {
      font-size: var(--font-size-lg);
    }
    &-detail {
      font-size: var(--font-size-md);
      .success-num {
        color: var(--success);
      }
      .error-num {
        color: var(--danger);
      }
      &-btn {
        color: var(--link-fc);
        font-size: var(--font-size-md);
        cursor: pointer;
      }
    }
  }

  &-textarea {
    width: 100%;
    max-width: 100%;
    line-height: calc(18 * var(--hd));
    margin-top: var(--v-spacing-md);
    margin-bottom: var(--v-spacing-md);
    padding-top: var(--v-spacing-md);
    padding-bottom: var(--v-spacing-md);
  }
}

.@{browserClsPrefix}-quick-import-detail-dialog {
  &-content {
    background-color: var(--bg-base);
  }
  &-search-bar {
    display: flex;
    align-items: flex-end;
    padding-bottom: var(--v-spacing-lg);
    &-left {
      flex: 1 1 auto;
    }
    &-right {
      flex: 0 0 auto;
      padding-left: var(--h-spacing-lg);
      padding-right: calc(30 * var(--hd));
    }
  }
  &-search-form {
    &.ui-form-module {
      .ui-formItem-module {
        padding: 0;
        padding-right: var(--h-spacing-lg);
      }
      .ui-form-row {
        &:last-child {
          border-bottom: none;
        }
        .ui-form-col {
          background-color: var(--bg-transparent);
          border-right: none;
          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }
        }
      }
      .ui-form-row-first {
        border-top: none;
      }
    }
  }
  &-table {
    min-height: calc(500 * var(--hd));
  }
}
