@import "../components/style/index.m.less";
@import "../auto-complete/style/index.m.less";
@import "../types-browser/style/index.m.less";

/* 弹框 */
.@{mBrowserClsPrefix}-dialog {
  .@{mDialogClsPrefix}-content {
    border-radius: 0;
  }
  .@{mDialogClsPrefix}-top {
    padding: 0;
    text-align: left;
    border-radius: 0;
    background-color: var(--m-bg-base);
  }
  .@{mDialogClsPrefix}-footer {
    .ui-m-dialog-footer-btn {
      border-top: none;
      .border-solid-then;
      &::after {
        top: 0;
      }
    }
  }
}

/* 关联浏览按钮 浏览按钮列表 */
.@{mBrowserClsPrefix}-dialog-browser-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .@{mBrowserClsPrefix}-dialog-browser-list {
    flex: 1 1 auto;
    overflow: auto;
    background-color: var(--m-bg-base);
    // padding-bottom: calc(50 * var(--hd));
    &-empty {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-item {
      padding-left: var(--h-spacing-lg);
      background-color: var(--bg-white);
      // border-bottom: var(--border-solid);
      .border-solid-then;
      .@{formItemClsPrefix}-label-noWholeLine {
        padding-left: 0;
      }
      &.@{formItemClsPrefix}-mobile
        .@{formItemClsPrefix}-wrapper-col-wholeLine
        .@{formItemClsPrefix}-wrapper {
        .ui-formSwitch {
          padding-left: 0;
          padding-top: var(--v-spacing-sm);
          padding-bottom: var(--v-spacing-sm);
        }
        .@{mBrowserClsPrefix}-associative-inner.is-placeholder
          .@{formItemClsPrefix}-item-placeholder {
          justify-content: flex-end;
        }
      }
    }
  }
  .@{mBrowserClsPrefix}-dialog-browser-btn {
    flex: 0 0 auto;
    text-align: center;
    padding: var(--v-spacing-lg) 0;
    font-size: var(--font-size-lg);
    .border-solid-then;
    &::after {
      top: 0;
      bottom: initial;
    }
  }
}
/* 人力浏览按钮 页签快速切换菜单 */
.@{mBrowserClsPrefix}-prepend-menu {
  height: calc(30 * var(--hd));
  padding-right: var(--h-spacing-md);
  max-width: calc(100 * var(--hd));
  .@{mMenuClsPrefix}-content {
    position: fixed;
    margin-top: calc(4 * var(--hd));
    left: 0;
  }
  .@{mMenuClsPrefix}-mask {
    top: auto;
    margin-top: var(--m-serach-bar-height);
  }
  .@{mMenuClsPrefix}-menu {
    margin: 0 calc(4 * var(--hd));
  }
  .@{mMenuClsPrefix}-menu .@{mMenuClsPrefix}-top {
    padding: 0;
    height: calc(30 * var(--hd));
    line-height: calc(30 * var(--hd));
  }
  .@{mMenuClsPrefix}-top::after {
    height: 0;
  }
  .@{mMenuClsPrefix}-top-left {
    width: 100%;
  }
  .@{mMenuClsPrefix}-top-text {
    padding-left: calc(6 * var(--hd));
  }
  .@{mMenuClsPrefix}-wrap {
    background: var(--m-bg-base);
    border-radius: calc(15 * 1px);
    position: relative;
  }
  .@{mMenuClsPrefix}-menu .@{mMenuClsPrefix}-top-left-content {
    display: flex;
    justify-content: center;
    padding: 0 calc(4 * var(--hd));
  }
  .@{mMenuClsPrefix}-list-item-content {
    text-overflow: ellipsis;
    width: 90%;
  }
}
/* 人力浏览按钮 左侧面板快速切换菜单 */
.@{mBrowserClsPrefix}-prepend-btn {
  height: calc(30 * var(--hd));
  padding-right: var(--h-spacing-md);
  max-width: calc(110 * var(--hd));
  &-wrap {
    width: 100%;
    height: 100%;
    background: var(--m-bg-base);
    border-radius: calc(15 * 1px);
    position: relative;
    margin: 0 calc(4 * var(--hd));
  }
  &-content {
    display: flex;
    justify-content: center;
    padding: 0 calc(4 * var(--hd));
    width: 100%;
    height: 100%;
    line-height: calc(30 * var(--hd));
  }
  &-text {
    padding-left: calc(6 * var(--hd));
    color: var(--main-fc);
    float: left;
    height: 100%;
    max-width: 80%;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &-icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(24 * var(--hd));
    text-align: center;
    color: var(--secondary-fc);
  }
}

/* 人力浏览按钮 tab页签特殊处理 */
.@{mBrowserClsPrefix}-panel-header-menu {
  &.@{menuClsPrefix} {
    width: 100%;
    padding-left: 0;
    background: var(--m-bg-base);
    padding-bottom: var(--h-spacing-sm);
  }
  .@{menuClsPrefix}-list {
    font-size: var(--font-size-lg);
    // margin-bottom: var(--h-spacing-md);
    background-color: var(--bg-white);
    .@{menuClsPrefix}-list-item,
    .@{menuClsPrefix}-list-subitem {
      position: relative;
      text-align: left;
      .@{menuClsPrefix}-list-item-lefticon {
        .@{iconClsPrefix} {
          color: var(--primary);
          &-svg {
            width: calc(18 * var(--hd));
            height: calc(18 * var(--hd));
          }
        }
      }
    }
    .@{menuClsPrefix}-list-item,
    .@{menuClsPrefix}-list-subitem,
    .@{menuClsPrefix}-list-subitem-title {
      position: relative;
      &::after {
        content: "";
        border-bottom: none;
        height: var(--hd);
        background-color: var(--border-color);
        transform: scaleY(0.3);
        width: calc(100% - 32 * var(--hd) - 2 * var(--h-spacing-lg));
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .@{menuClsPrefix}-list-item {
      min-height: calc(50 * var(--hd));
    }
    .@{menuClsPrefix}-list-subitem {
      &-title {
        min-height: calc(50 * var(--hd));
      }
    }
  }
  > .@{menuClsPrefix}-list {
    > .@{menuClsPrefix}-list-subitem > .@{menuClsPrefix}-list-subitem-title,
    > .@{menuClsPrefix}-list-item {
      .@{menuClsPrefix}-list-item-lefticon {
        // margin-left: calc(5 * var(--hd));
        justify-content: center;
        text-align: center;
        border-radius: 50%;
        background-color: #ffcd50;
        width: calc(32 * var(--hd));
        height: calc(32 * var(--hd));
        line-height: calc(28 * var(--hd));
        margin-right: var(--h-spacing-lg);
        .@{iconClsPrefix} {
          color: var(--base-white);
        }
      }
      &:nth-of-type(1) {
        .@{menuClsPrefix}-list-item-lefticon {
          background-color: #ffcd50;
        }
      }
      &:nth-of-type(2) {
        .@{menuClsPrefix}-list-item-lefticon {
          background-color: #5d9cec;
        }
      }
      &:nth-of-type(3) {
        .@{menuClsPrefix}-list-item-lefticon {
          background-color: #24cef6;
        }
      }
      &:nth-of-type(4) {
        .@{menuClsPrefix}-list-item-lefticon {
          background-color: #40d8ad;
        }
      }
      &:nth-of-type(5) {
        .@{menuClsPrefix}-list-item-lefticon {
          background-color: #ff666a;
        }
      }
    }
  }
}

.@{mBrowserClsPrefix}-panel-header-menu-btns {
  padding-bottom: var(--h-spacing-md);
  background-color: var(--m-bg-base);
  .@{mBrowserClsPrefix}-menu-btn {
    &:nth-of-type(1) {
      .@{mBrowserClsPrefix}-menu-btn-icon {
        background-color: #24cef6;
      }
    }
    &:nth-of-type(2) {
      .@{mBrowserClsPrefix}-menu-btn-icon {
        background-color: #5d9cec;
      }
    }
  }
}

/* 面板 */
.@{mBrowserClsPrefix}-panel {
  &-header-bar {
    min-height: calc(42 * var(--hd));
    padding-right: var(--h-spacing-lg);
    background-color: var(--bg-white);
    // border-bottom: var(--border-solid);
    &-select {
      .@{selectClsPrefix}-input {
        font-size: var(--font-size-md);
      }
    }
    &-label {
      margin-right: var(--h-spacing-md);
      font-size: var(--font-size-sm);
      color: var(--secondary-fc);

      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &-search-bar {
      width: 100%;
    }
    .list-check-all {
      // width: calc(35 * var(--hd));
      text-align: left;
    }
    .right-coms {
      // padding-right: var(--h-spacing-md);
      .list-check-all {
        color: var(--primary);
      }
    }
  }
  &-header-menu {
    width: 100%;
    .@{menuClsPrefix}-list-item,
    .@{menuClsPrefix}-list-subitem-title {
      border-left: 0;
    }
  }
  &-content {
    /* 针对特殊菜单展示页面调整滚动区域 */
    &.has-inner-tab.outer-tab {
      .@{mBrowserClsPrefix}-panel {
        &-content-spinner {
          overflow: auto;
        }
        &-list {
          overflow: visible;
        }
      }
    }
    &-spinner {
      background-color: var(--m-bg-base);
    }
    .ui-encry {
      z-index: 2;
    }
  }
  &-mult-type-item-title {
    font-size: var(--font-size-md);
  }
  &-mult-type-item-content-more {
    font-size: var(--font-size-md);
  }
  &-left-spinner {
    flex: 0 0 auto;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
  }
  &-left-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    .@{mListClsPrefix}-scrollview-content {
      height: 100%;
      .@{mListClsPrefix}-body {
        // height: 100%;
        overflow: auto;
      }
    }
  }
}

.@{mBrowserClsPrefix}-search-bar {
  // padding-left: var(--h-spacing-md);
  // padding-right: var(--h-spacing-md);
  background-color: var(--bg-white);
  flex-wrap: wrap;
  &.no-search.has-btns {
    justify-content: flex-end;
    padding-top: var(--h-spacing-md);
    padding-bottom: var(--h-spacing-md);
  }
  &.is-sortable {
    .@{mBrowserClsPrefix}-search-bar-sortable-icon {
      padding-top: var(--v-spacing-sm);
      padding-bottom: var(--v-spacing-sm);
    }
  }
  &-sortable-list {
    &-item {
      &.@{mBtnClsPrefix} {
        border-color: transparent;
        &.is-active {
          color: var(--primary);
        }
      }
    }
  }

  &-popover {
    .@{popoverClsPrefix}-arrow.@{popoverClsPrefix}-arrow-bottomRight {
      right: calc(4 * var(--hd)); /* 处理移动端 Popover 箭头定位问题 */
    }
    &-icon {
      color: var(--secondary-fc);
      padding-left: var(--h-spacing-lg);
      padding-top: var(--v-spacing-sm);
      padding-bottom: var(--v-spacing-sm);
    }
    &-btn {
      border: none;
      background-color: var(--bg-white);
      min-width: calc(150 * var(--hd));
    }
    .@{popoverClsPrefix}-content-bottomRight {
      padding: 0;
    }
  }

  &-top {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(42 * var(--hd));
    padding-left: var(--h-spacing-md);
  }
  &-left {
    width: calc(200 * var(--hd));
  }

  // 高级搜索
  .@{mBrowserClsPrefix}-search-advance {
    &.no-advance-search {
      .@{mSearchAdvancedClsPrefix}-cancel-icon,
      .@{mSearchAdvancedClsPrefix}-saIcon,
      .@{mSearchAdvancedClsPrefix}-split-line {
        display: none;
      }
    }
    .@{mSearchbarClsPrefix}
      .@{mSearchbarClsPrefix}-input
      .@{inputClsPrefix}-wrap
      .@{iconClsPrefix} {
      min-height: var(--input-height);
      display: flex;
      align-items: center;
    }
  }
}

/** 左侧面板 */
.@{mBrowserClsPrefix}-panel-left {
  &-search {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    &-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 0 var(--h-spacing-lg);
      .right-coms {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
      }
      .left-coms {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
      }
    }
  }
}

/* 已选面板 */
.@{mBrowserClsPrefix}-selected-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  .@{mBrowserClsPrefix}-panel-header-bar {
    min-height: calc(40 * var(--hd));
    // border-bottom: var(--border-solid);
  }
  .@{mBrowserClsPrefix}-search-input {
    padding: var(--v-spacing-md) var(--h-spacing-md);
  }
  .@{mListClsPrefix}-scrollview-wrap {
    height: 100% !important;
    overflow: auto;
    .@{scrollerClsPrefix}__wrap {
      margin-right: 0 !important;
    }
  }
  &-search {
    // border-bottom: var(--border-solid);
    background-color: var(--bg-white);
  }
  &-list {
    height: 100% !important;
    background-color: var(--m-bg-base);
    // padding-left: var(--h-spacing-md);
    // padding-top: var(--h-spacing-md);
    /* 处理列表的下边线 */
    .@{mListClsPrefix}-body {
      div:not(:last-child) .@{mListClsPrefix}-content {
        border-bottom: none;
        &::after {
          display: none;
        }
        .@{mBrowserClsPrefix}-selected-panel-list-item {
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: calc(100% - var(--h-spacing-lg));
            height: var(--hd);
            background-color: var(--border-color);
            transform: scaleY(0.3);
          }
          &.has-handle {
            &::after {
              width: calc(
                100% - var(--h-spacing-lg) - var(--h-spacing-md) -
                  var(--icon-size-sm)
              );
            }
          }
          &.has-icon {
            &::after {
              width: calc(
                100% - var(--h-spacing-lg) - var(--icon-size-md) -
                  var(--h-spacing-md)
              );
            }
            &.has-handle {
              &::after {
                width: calc(
                  100% - var(--h-spacing-lg) - var(--icon-size-md) -
                    var(--h-spacing-md) - var(--icon-size-sm) -
                    var(--h-spacing-md)
                );
              }
            }
          }
          &.has-avatar {
            &::after {
              width: calc(
                100% - var(--h-spacing-lg) - var(--avatar-size-md) -
                  var(--h-spacing-md)
              );
            }
            &.has-handle {
              &::after {
                width: calc(
                  100% - var(--h-spacing-lg) - var(--avatar-size-md) -
                    var(--h-spacing-md) - var(--icon-size-sm) -
                    var(--h-spacing-md)
                );
              }
            }
          }
        }
      }
    }
    &-item {
      flex: 1 1 auto;
      &-icon-move {
        display: inline-flex;
        align-items: center;
        color: var(--secondary-fc);
        margin-left: 0;
        margin-right: var(--h-spacing-md);
      }
      .item-delete-icon {
        display: none;
      }
      .item-split-icon {
        color: var(--secondary-fc);
        // color: var(--regular-fc);
      }
    }
    .@{mListClsPrefix}-scrollview-content {
      height: 100%;
      .@{mListClsPrefix}-body {
        height: auto;
        overflow: auto;
      }
    }
    &.@{mListClsPrefix}-column {
      .@{mListClsPrefix}-content {
        // padding-top: var(--h-spacing-md);
        padding-left: var(--h-spacing-lg);
      }
      .@{mListClsPrefix}-item {
        min-height: calc(50 * var(--hd));
      }
      .@{mListClsPrefix}-item-row {
        font-size: var(--font-size-sm);
        // padding-bottom: var(--h-spacing-sm);
        padding: calc(3 * var(--hd)) 0;
        color: var(--secondary-fc);
        line-height: 1.3;
        &:only-of-type {
          color: var(--main-fc);
          font-size: var(--font-size-md);
        }
        &:last-of-type {
          padding-bottom: 0;
        }
        &-span {
          margin-right: var(--h-spacing-md);
          &-text-highlight {
            font-weight: normal;
            font-size: var(--font-size-md);
            white-space: pre-wrap;
          }
          &-wrap-line {
            white-space: pre-wrap;
          }
        }
      }
    }
  }
  &-content-spinner {
    height: 100%;
    background-color: var(--m-bg-base);
  }
  &-list-empty {
    margin-top: calc(120 * var(--hd));
    .@{emptyClsPrefix}-title,
    .@{emptyClsPrefix}-description {
      color: var(--invalid-fc);
      font-size: var(--font-size-sm);
    }
  }
  &-quick-menu {
    .@{mQuickMenuClsPrefix}-start-btn {
      bottom: calc(80 * var(--hd));
    }
  }
  .@{mBrowserClsPrefix}-panel-dialog-footer-btns {
    position: relative;
    flex: 0 0 auto;
    .@{mBtnClsPrefix} {
      flex: 1 1 auto;
    }
    &.border-solid-then::after {
      top: 0;
    }
    .is-clear-btn {
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        height: 50%;
        width: var(--hd);
        background-color: var(--border-color);
        transform: translateY(-50%) scaleX(0.3);
      }
    }
  }
  /* 禁止编辑模式下的样式 */
  &.disabled-edit {
  }
}

/* 人员范围多选已选面板 */
.@{mBrowserClsPrefix}-selected-dialog {
  .@{mDialogClsPrefix}-content {
    border-radius: 0;
  }
  .@{mDialogClsPrefix}-top {
    padding: 0;
  }
}

/* 列表 */
.@{mBrowserClsPrefix}-panel-list {
  height: 100% !important;
  // padding-left: var(--h-spacing-lg);
  background-color: var(--m-bg-base);
  .@{mListClsPrefix}-body {
    .@{mListClsPrefix}-item:not(:last-child) .@{mListClsPrefix}-content {
      &::after {
        display: none;
      }
      .@{mBrowserClsPrefix}-panel-list-item {
        &::after {
          width: calc(100% - var(--h-spacing-lg));
        }
        &.has-icon {
          &::after {
            width: calc(
              100% - var(--h-spacing-lg) - var(--icon-size-md) -
                var(--h-spacing-md)
            );
          }
        }
        &.has-avatar {
          &::after {
            width: calc(
              100% - var(--h-spacing-lg) - var(--avatar-size-md) -
                var(--h-spacing-md)
            );
          }
        }

        .ui-encry {
          z-index: 2;
        }
      }
    }
  }
  &-item {
    flex: 1 1 auto;
    &-icon-arrow {
      flex: 0 0 auto;
      color: var(--secondary-fc);
      padding-left: var(--h-spacing-md);
      padding-right: var(--h-spacing-lg);
    }
  }
  &.@{mListClsPrefix}-column {
    .@{mListClsPrefix}-content {
      min-height: calc(50 * var(--hd));
      // padding-top: var(--h-spacing-md);
      padding-left: var(--h-spacing-lg);
    }
    .@{mListClsPrefix}-item-row {
      line-height: 1.3;
      font-size: var(--font-size-sm);
      color: var(--secondary-fc);
      // padding-bottom: var(--h-spacing-sm);
      padding: calc(3 * var(--hd)) 0;
      &:last-of-type {
        padding-bottom: 0;
      }
      &:only-of-type {
        color: var(--main-fc);
        font-size: var(--font-size-md);
      }
      &-void {
        display: none;
      }
      &-span {
        margin-right: var(--h-spacing-md);
        &-void {
          display: none;
        }
        &-text-highlight {
          font-weight: normal;
          font-size: var(--font-size-md);
          white-space: pre-wrap;
        }
        &-wrap-line {
          white-space: pre-wrap;
        }
      }
    }
    .@{mListClsPrefix}-item-checkbox-right {
      display: inline-flex;
      position: static;
      flex: 0 0 auto;
      padding: 0;
      height: calc(44 * var(--hd));
      width: calc(44 * var(--hd));
      text-align: center;
      align-items: center;
      .@{mCcheckboxClsPrefix}-left {
        flex: 1 1 auto;
        text-align: left;
      }
      .@{mCcheckboxClsPrefix}-inner-left {
        margin-right: 0;
      }
    }
  }
  &.@{mBrowserClsPrefix}-panel-list-clickable {
    .@{mListClsPrefix}-item-row-span.item-clickable {
      color: var(--primary);
    }
    .@{mListClsPrefix}-item-checkbox-right {
      flex: 0 0 auto;
      position: relative;
      margin-left: var(--h-spacing-lg);
    }
  }
}

/* 标签列表 */
.@{mBrowserClsPrefix}-panel-tag-list {
  &-item {
    height: calc(24 * var(--hd));
    line-height: calc(24 * var(--hd));
    padding: 0 var(--h-spacing-md);
    margin-left: var(--h-spacing-lg);
    border-radius: var(--border-radius-xs);
  }
  &.@{mListClsPrefix}-column {
    .@{mListClsPrefix}-content {
      justify-content: space-between;
    }
    .@{mListClsPrefix}-item {
      &-checkbox-right {
        position: static;
        // padding: calc(10 * var(--hd)) 0;
      }
    }
  }
}

/* 索引列表 */
.@{mBrowserClsPrefix}-panel-index-list {
  &-wrap {
    overflow: hidden;
  }
  &.@{mListClsPrefix}-column {
    .@{mListClsPrefix}-item-checkbox-right {
      width: calc(58 * var(--hd));
    }
  }
}

/* 树形 */
.@{mBrowserClsPrefix}-panel-tree {
  height: 100%;
  display: flex;
  flex-direction: column;
  &:not(.is-tree) {
    overflow: auto;
  }
  > .@{spinClsPrefix}-nested-loading {
    height: auto;
    overflow: auto;
  }

  // 移动树形（mtree）
  .@{mTreeClsPrefix}-node-content {
    text-align: left;
  }
  // 顶部
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: calc(var(--hd) * 42);
    padding-left: var(--h-spacing-lg);
    padding-right: var(--h-spacing-lg);
    background-color: var(--base-white);
    .left-coms,
    .right-coms {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .left-coms {
      flex: 1 1 auto;
    }
    .right-coms {
      flex: 0 0 auto;
    }
    &-left-label {
      margin-right: var(--h-spacing-md);
      font-size: var(--font-size-sm);
      color: var(--secondary-fc);

      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .list-check-all {
      color: var(--primary);
    }
  }
  // 节点
  &-node {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    overflow: hidden;
    line-height: calc(var(--hd) * 25);
    font-size: var(--font-size-md);

    &-icon {
      flex: 0 0 auto;
      margin-right: var(--h-spacing-md);
      margin-top: calc(var(--hd) * -2);
    }
    &-content {
      flex: 1 1 auto;
      text-align: left;
      color: var(--main-fc);
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-checkbox.@{mCcheckboxClsPrefix}-wrapper {
      margin-right: 0;
      display: none;
    }
  }
  .@{mTreeClsPrefix}-node-right {
    overflow: hidden;
  }
  .@{mTreeClsPrefix}-node-content {
    padding-right: 0;
  }
}

/* 卡片列表 */
.@{mBrowserClsPrefix}-panel-card-list {
  .ui-m-list-item {
    background: var(--m-bg-base);
    width: 100%;
  }
  .ui-m-list-content {
    position: relative;
    border-radius: calc(6 * var(--hd));
    margin: calc(5 * var(--hd)) calc(10 * var(--hd));
    background: var(--bg-white);
    width: 100%;
  }
  &-item {
    display: flex;
    padding: calc(10 * var(--hd));
    width: 100%;
  }
  &-item-img {
    position: relative;
    margin-right: calc(10 * var(--hd));
    width: calc(110 * var(--hd));
    height: calc(70 * var(--hd));
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--m-browser-img-bg);
    img {
      max-width: 100%;
      max-height: 100%;
    }
    &-enlarge {
      position: absolute;
      right: calc(5 * var(--hd));
      bottom: calc(8 * var(--hd));
      background-color: var(--base-black);
      color: var(--base-white);
      opacity: 0.2;
      border-radius: 50%;
      text-align: center;
      line-height: calc(18 * var(--hd));
      width: calc(20 * var(--hd));
      height: calc(20 * var(--hd));
    }
  }
  &-item-content {
    width: calc(100% - calc(120 * var(--hd)));
  }
  &-item-with-tag {
    .@{mBrowserClsPrefix}-panel-card-list-item-main {
      width: calc(100% - calc(100 * var(--hd)));
    }
    .@{mBrowserClsPrefix}-panel-card-list-item-seco {
      width: calc(100% - calc(40 * var(--hd)));
    }
  }
  &-item-main {
    color: var(--main-fc);
    margin-bottom: var(--v-spacing-sm);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-item-seco {
    color: var(--secondary-fc);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-item-tag {
    position: absolute;
    right: 0;
    top: 0;
    .@{mTagClsPrefix} {
      max-width: calc(100 * var(--hd));
      padding: calc(4 * var(--hd));
    }
    .tag-round {
      border-radius: 0 0 0 100%;
      transform: translateX(calc(10 * var(--hd)) ;);
      .ui-m-tag-text {
        visibility: hidden;
      }
    }
    .tag-rectangular {
      border-top-left-radius: 0;
      border-bottom-left-radius: calc(10 * var(--hd));
    }
  }
}

/* 卡片列表已选面板 */
.@{mBrowserClsPrefix}-selected-panel-card-list {
  .ui-m-list-content {
    .@{mBrowserClsPrefix}-panel-card-list-item::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      height: var(--hd);
      height: var(--hd);
      background-color: #e5e5e5;
      background-color: var(--border-color);
      transform: scaleY(0.3);
      width: calc(100% - var(--h-spacing-lg));
    }
  }

  .@{mBrowserClsPrefix}-panel-card-list-item {
    padding: calc(10 * var(--hd)) calc(10 * var(--hd)) calc(10 * var(--hd)) 0;
  }
}

/* 弹框底部 */
.@{mBrowserClsPrefix}-panel-footer {
  height: auto;
  flex-wrap: wrap;
  &.border-solid-then::after {
    top: 0;
  }
  .@{mBrowserClsPrefix}-panel-footer-extra {
    width: 100%;
    flex-basis: auto;
    padding: var(--m-btn-padding-y) var(--m-btn-padding-x);
    &.border-solid-then::after {
      width: 200%;
      left: -20%;
    }
  }
  .@{mBrowserClsPrefix}-panel-footer-btns {
    display: flex;
    width: 100%;
    height: calc(50 * var(--hd));
    button {
      height: calc(50 * var(--hd));
      font-size: var(--font-size-17);
      &.is-clear-btn,
      &.is-cancel-btn {
        color: var(--secondary-fc);
      }
      &.is-selected-btn {
        text-align: left;
        &.need-rtl {
          text-align: right;
        }
        &.is-empty {
          color: var(--secondary-fc);
        }
      }
      &.is-ok-btn {
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          height: 50%;
          width: var(--hd);
          background-color: var(--border-color);
          transform: translateY(-50%) scaleX(0.3);
        }
      }
    }
  }
  .@{mBrowserClsPrefix}-panel-footer-more-btn {
    display: flex;
    color: var(--secondary-fc);
    align-items: center;
  }
  &.is-single {
    .@{mBrowserClsPrefix}-panel-footer-btns {
      button {
        flex: 1 1 50%;
      }
    }
  }
  &.is-multiple {
    .@{mBrowserClsPrefix}-panel-footer-btns {
      // button {
      //   flex: 0 0 25%;
      // }
      button:first-child {
        flex: 0 0 50%;
      }
    }
  }
}
.@{mBrowserClsPrefix}-panel-dialog-footer {
  .@{mDialogClsPrefix}-top {
    padding: 0;
  }
  &-btns {
    display: flex;
    width: 100%;
    bottom: 0;
    position: absolute;
    .@{mBtnClsPrefix} {
      height: calc(50 * var(--hd));
      font-size: var(--font-size-17);
      flex: 0 0 50%;
      &.is-clear-btn,
      &.is-cancel-btn {
        color: var(--secondary-fc);
      }
    }
  }
  &-more-btn {
    display: flex;
    color: var(--secondary-fc);
    align-items: center;
    padding: var(--m-btn-padding-y) var(--h-spacing-lg) var(--m-btn-padding-y)
      var(--m-btn-padding-x);
  }
}

/* 共享浏览按钮 */
.@{mBrowserClsPrefix}-share-list-item-row-span {
  display: inline-block;
  margin-top: calc(2 * var(--hd));
  &-highlight {
    color: var(--main-fc);
    font-size: var(--font-size-14);
    font-weight: 400;
  }
}

// 移动端数据自动折行
.@{mBrowserClsPrefix}-panel-list,
.@{mBrowserClsPrefix}-selected-panel-list {
  .@{mListClsPrefix}-item,
  .@{listClsPrefix}-item {
    &-row {
      &-span.@{mListClsPrefix}-item-row-span-text-highlight {
        white-space: normal;
        display: inline-block;
        word-break: break-word;
      }
    }
    .@{mBrowserClsPrefix}-panel-list-item {
      padding-right: var(--h-spacing-lg);
    }
  }
}
