.@{appIconClsPrefix} {
  display: inline-block;
  &-box {
    display: inline-block;
  }
  &-bg-rect {
    border-radius: 14%;
  }
  &-bg-circle {
    border-radius: 50%;
  }
  &-bg-color {
    background-color: var(--regular-fc);
  }

  &-yellow {
    background-color: var(--icon-yellow);
  }
  &-blue {
    background-color: var(--icon-blue);
  }
  &-indigo {
    background-color: var(--icon-indigo);
  }
  &-green {
    background-color: var(--icon-green);
  }
  &-red {
    background-color: var(--icon-red);
  }

  .ui-icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ui-icon-svg {
    width: 100%;
    height: 100%;
  }
  &-hasBg {
    .ui-icon-svg {
      width: 60%;
      height: 60%;
    }
  }
}
