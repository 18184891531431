.@{datePickerClsPrefix}-rangePanel {
  width: calc(626 * var(--hd));
  font-size: var(--font-size-12);
  display: flex;
  flex-direction: column;
  outline: none;
  z-index: 999;
  background: var(--bg-white);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius-xs);
  box-shadow: var(--box-shadow);
  margin-top: calc(5 * var(--hd));
}

.@{datePickerClsPrefix}-rangeContent {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;

  .@{datePickerClsPrefix}-dayPanel-dayWrap {
    .cell-hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .item {
    flex: 1;
    display: flex;
    flex-direction: column;

    .@{datePickerClsPrefix}-titleDateNav {
      padding: 0 calc(22 * var(--hd));
    }

    .@{datePickerClsPrefix}-inputNav {
      padding: 0 calc(22 * var(--hd));
    }

    &-content {
      height: calc(235 * var(--hd));
      padding: 0 calc(20 * var(--hd)) calc(8 * var(--hd));
      display: flex;
      flex-direction: column;
    }
  }
}

.@{datePickerClsPrefix}-inputNav {
  height: calc(40 * var(--hd));
  padding: 0 calc(14 * var(--hd));
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: calc(1 * var(--hd)) solid var(--diviling-line-color);

  .dateInput,
  .timeInput {
    width: calc(255 * var(--hd));
    height: calc(30 * var(--hd));
  }
}

.@{datePickerClsPrefix}-unLimitArea {
  box-sizing: border-box;
  height: calc(30 * var(--hd));
  display: flex;

  .left,
  .right {
    flex: 1;
    display: flex;
    justify-content: center;
    color: var(--regular-fc);

    .item {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding-bottom: calc(6 * var(--hd));
    }

    .isSelect {
      font-weight: var(--font-weight-bold);
      color: var(--primary);
    }

    .isDisabled {
      cursor: not-allowed;
    }
  }
}

.@{datePickerClsPrefix}-rangeTitleDateSelectPanel {
  width: 100%;
  height: calc(305 * var(--hd));
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1
  }
}

.@{datePickerClsPrefix}-rangeFooter {
  box-sizing: border-box;
  height: calc(40 * var(--hd));
  padding: 0 calc(4 * var(--hd));
  border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;

  &-unlimitWrap,
  &-confirmWrap {
    display: flex;

    .item {
      margin: 0 calc(10 * var(--hd));
      height: calc(20 * var(--hd));
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--font-size-sm);
      cursor: pointer;
      color: var(--secondary-fc);
      line-height: 1;
    }
  }

  &-unlimitWrap {
    margin-left: calc(16 * var(--hd));

    .item {
      &:hover {
        color: var(--primary);
      }

      &-isSelect {
        color: var(--primary);
      }

      &-disabled {
        cursor: not-allowed;
        color: var(--invalid-fc);

        &:hover {
          color: var(--invalid-fc);
        }
      }
    }
  }

  &-confirmWrap {
    .confirm {
      color: var(--primary);
      padding: 0 calc(6 * var(--hd));
      border: calc(1 * var(--hd)) solid var(--primary);
      border-radius: var(--border-radius-xs);

      &-disabled {
        color: var(--invalid-fc);
        border: calc(1 * var(--hd)) solid var(--invalid-fc);
        cursor: not-allowed;
      }
    }
  }
}