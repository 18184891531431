.@{mScopeClsPrefix} {

  &.@{mScopeClsPrefix}-required .@{mScopeClsPrefix}-required-mark {
    color: var(--danger);
    font-size: var(--font-size-12);
    left: calc(-10 * var(--hd));
    top: 50%;
    line-height: 0;
    position: absolute;
    width: calc(5 * var(--hd));
  }

  .@{mScopeClsPrefix}-wrap {
    position: relative;

    .@{mScopeClsPrefix}-controls {
      display: flex;
      align-items: center;
      width: 100%;

      .ui-m-list-line {
        border: none;
      }
    }

    .@{mScopeClsPrefix}-input {
      // flex: 1;
      outline: none;
      // font-size: var(--font-size-base);
      -webkit-appearance: none;
      appearance: none;
      // padding: calc(2 * var(--hd)) 0;
      border: none;
      box-shadow: none;
      background-color: var(--bg-transparent);

      // line-height: var(--line-height-base);
      // box-sizing: border-box;
      // -webkit-overflow-scrolling: touch;
      // width: calc(50% - 24 * var(--hd));
      &::after {
        display: none;
      }

      &:disabled {
        color: var(--input-disabled-color);
      }

      &.text-underline {
        text-decoration: underline;
      }

    }

    .@{mScopeClsPrefix}-line {
      display: inline-block;
      text-align: center;
      color: var(--main-fc);
      font-weight: var(--font-weight-base);
      line-height: var(--m-scope-line-height);
      padding: var(--m-scope-line-padding);
    }
  }

  &:not(.@{mScopeClsPrefix}-column) {
    .@{mScopeClsPrefix}-wrap {
      height: var(--m-input-height);
      display: flex;
      align-items: center;
    }

    .@{mScopeClsPrefix}-required-mark+.@{mScopeClsPrefix}-controls {
      width: calc(100% - 10 * var(--hd));
    }
  }
}