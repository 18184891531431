.@{tableClsPrefix}-grid {
  color: #333333;
  font-size: var(--font-size-12);
  position: relative;
  background-color: var(--bg-white);;
  border: 1px solid var(--border-color);

  .ui-skeleton {
    border: none;
  }

  &-no-pagination {
    //border-bottom: none;
    .@{tableClsPrefix}-grid-empty {
      //border-bottom-width: 1px;
      //border-bottom-style: solid;
      //border-bottom-color: var(--border-color);

      &::after {
        content: '';
        min-height: inherit;
        font-size: 0;
      }
    }
  }

  &-resizable {
    th {
      cursor: pointer;
      &:hover {
        background-color: #e2ecf2;
      }
    }
  }
  //overflow: hidden;

  &.@{tableClsPrefix}-grid-scroll-position-left,
  &.@{tableClsPrefix}-grid-scroll-position-middle {
    .@{tableClsPrefix}-grid-th-sticky-right:before {
      content: "";
      position: absolute;
      top: calc(-100% - 10px);
      bottom: -10px;
      width: 30px;
      left: 0;
      transition-property: box-shadow;
      transition-duration: .2s;
      box-shadow: -6px 0 6px -4px rgb(200 201 204 / 50%);
      -webkit-clip-path: polygon(-50% 50%,50% 50%,50% 100%,-50% 100%);
      clip-path: polygon(-50% 50%,50% 50%,50% 100%,-50% 100%);
    }

    .@{tableClsPrefix}-grid-td-sticky-right:before {
      content: "";
      position: absolute;
      top: calc(-100% - 10px);
      bottom: 0px;
      width: 30px;
      left: 0;
      transition-property: box-shadow;
      transition-duration: .2s;
      box-shadow: -6px 0 6px -4px rgb(200 201 204 / 50%);
      -webkit-clip-path: polygon(-50% 50%,50% 50%,50% 100%,-50% 100%);
      clip-path: polygon(-50% 50%,50% 50%,50% 100%,-50% 100%);
    }
  }

  &.@{tableClsPrefix}-grid-scroll-position-left {
    .@{tableClsPrefix}-grid-th-sticky-left:before {
      box-shadow: none !important;
    }

    .@{tableClsPrefix}-grid-td-sticky-left:before {
      box-shadow: none !important;
    }
  }

  &.@{tableClsPrefix}-grid-scroll-position-right,
  &.@{tableClsPrefix}-grid-scroll-position-middle {

    .@{tableClsPrefix}-grid-th-sticky-left:before {
      content: "";
      position: absolute;
      top: calc(-100% - 10px);
      bottom: -10px;
      width: 30px;
      right: 0;
      transition-property: box-shadow;
      transition-duration: .2s;
      box-shadow: 6px 0 6px -4px rgb(200 201 204 / 50%);
      -webkit-clip-path: polygon(50% 50%,150% 50%,150% 100%,50% 100%);
      clip-path: polygon(50% 50%,150% 50%,150% 100%,50% 100%);
    }

    .@{tableClsPrefix}-grid-td-sticky-left:before {
      content: "";
      position: absolute;
      top: calc(-100% - 10px);
      bottom: 0px;
      width: 30px;
      right: 0;
      transition-property: box-shadow;
      transition-duration: .2s;
      box-shadow: 6px 0 6px -4px rgb(200 201 204 / 50%);
      -webkit-clip-path: polygon(50% 50%,150% 50%,150% 100%,50% 100%);
      clip-path: polygon(50% 50%,150% 50%,150% 100%,50% 100%);
    }
  }

  &.@{tableClsPrefix}-grid-scroll-position-right {
    .@{tableClsPrefix}-grid-th-sticky-right:before {
      box-shadow: none !important;
    }

    .@{tableClsPrefix}-grid-td-sticky-right:before {
      box-shadow: none !important;
    }
  }

  &.@{tableClsPrefix}-grid-scroll-position-left {
    .@{tableClsPrefix}-grid-fixed-left {
      box-shadow: none;
    }
  }

  &.@{tableClsPrefix}-grid-scroll-position-right {
    .@{tableClsPrefix}-grid-fixed-right {
      box-shadow: none;
    }
  }

  &-table {
    min-width: 100%;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    //contain: strict;
    //IE浏览器下表格表头下线不展示
    &.@{tableClsPrefix}-grid-table-ie{
      border-collapse: separate;
    };

    &-ellipsis {
      .@{tableClsPrefix}-grid-nowrap {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .@{tableClsPrefix}-grid-td-sticky,
      .@{tableClsPrefix}-grid-th-sticky {
        & > span {
          width: 100%;
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    &-sticky {
      .@{tableClsPrefix}-grid-table {
        border-collapse: separate;
        border-spacing: 0;

        th, td {
          border-right: 0;
        }

        .@{tableClsPrefix}-grid-td-selection {
          & > span {
            display: inline;
          }
        }
      }
    }

    &__virtual {
      .@{tableClsPrefix}-grid-scroll {
        table {
          border-collapse: separate !important;

          thead {
            th {
              border-left: 0;

              &:last-child {
                border-right: 0;
              }
            }
          }
        }
      }
      // 虚拟列表场景下无需 colgroup 进行列统一样式设置
      .@{tableClsPrefix}-grid-fixed-left{
        colgroup{
          display: none;
        }
      }

    }

    &__mobile {
      border: 0;

      .@{tableClsPrefix}-grid-td, .@{tableClsPrefix}-grid-th {
        border-bottom: 0;

        &:after{
          position: absolute;
          content: " ";
          height: calc(1 * var(--hd));
          width: 100%;
          left: 0;
          bottom: 0;
          transform-origin: 0 0;
          -webkit-transform-origin: 0 0;
          transform: scaleY(0.3);
          background-color: var(--table-border-color);
        }
      }

      &:after{
        position: absolute;
        content: " ";
        height: calc(1 * var(--hd));
        width: 100%;
        left: 0;
        bottom: 0;
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        transform: scaleY(0.3);
        background-color: var(--table-border-color);
      }
    }
  }

  &-header {
    overflow: hidden;
    background-color: var(--table-head-bg);

    &-warp {
      background-color: var(--table-head-bg);
    }
  }

  &-bordered {
    border-left: none;

    .@{tableClsPrefix}-grid-header {
      background-color: var(--bg-transparent);

      &-warp {
        background-color: var(--bg-transparent);
      }

      .@{tableClsPrefix}-grid-th-sticky {
        background-color: var(--bg-white);;
      }
    }

    .@{tableClsPrefix}-grid-thead {
      .@{tableClsPrefix}-grid-th-sticky {
        background-color: var(--bg-white);;
      }
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;

    }

    .@{tableClsPrefix}-grid-empty {
      border-left: 1px solid var(--table-light-border-color);
    }

    .@{tableClsPrefix}-grid-table-sticky {
      .@{tableClsPrefix}-grid-table {
        border-collapse: separate;
        border-spacing: 0;

        th, td {
          border-right: 0;
        }
      }
    }

    th,
    td {
      border-color: var(--table-light-border-color);
      border-width: 1px;
      border-style: solid;
      border-top: 0;

      &:first-child {
        //border-left: 0;
      }

      &:last-child {
        //border-right: 0;
      }
    }

    .@{tableClsPrefix}-grid-td__virtual,
    .@{tableClsPrefix}-grid-th__virtual {
      border-left: 1px solid var(--table-light-border-color);

      &-first {
        border-left: 1px solid var(--table-light-border-color);
      }

      &-last {
        border-right: 0;
      }
    }

    .@{tableClsPrefix}-grid-fixed-left {

      .@{tableClsPrefix}-grid-header .@{tableClsPrefix}-grid-table {
        border-right: 1px solid var(--table-light-border-color);
      }

      .@{tableClsPrefix}-grid-td__virtual {
        border-left: 1px solid var(--table-light-border-color);

        &-first {
          border-left: 1px solid var(--table-light-border-color);
        }

        &-last {
          border-right: none;
          //border-right: 1px solid var(--table-light-border-color);
        }
      }
    }

    .@{tableClsPrefix}-grid-tfoot {
      border-left: 1px solid var(--table-light-border-color);
    }

    .@{tableClsPrefix}-grid-sticky-position, .@{tableClsPrefix}-grid-sticky-header-warp, .@{tableClsPrefix}-grid-thead-sticky-position {
      background-color: white
    }
  }

  &-bordered&-table__virtual {
    border-left: 1px solid var(--border-color);
  }

  &-bordered&-table__fixed {
    .@{tableClsPrefix}-grid-fixed-left {
      .@{tableClsPrefix}-grid-body-outer {
        border-right: 1px solid var(--table-light-border-color);
      }
    }

    .@{tableClsPrefix}-grid-td__virtual,
    .@{tableClsPrefix}-grid-th__virtual {
      border-right: 1px solid var(--table-light-border-color);
      border-left: none;

      &-first {
        border-right: 1px solid var(--table-light-border-color);
        border-left: none;
      }

      &-last {
        border-right: 0;
      }
    }
  }

  &-text-align-left {
    text-align: left;
  }

  &-text-align-right {
    text-align: right;

    .@{tableClsPrefix}-grid-thead-sort {
      //margin-right: 16px;
    }
  }

  &-text-align-center {
    text-align: center;
  }

  &-nowrap {
    //white-space: nowrap;
    //word-break: keep-all;
  }

  &-thead {
    background-color: var(--table-head-bg);
    .@{tableClsPrefix}-grid-tr {
      cursor: unset;
      .@{tableClsPrefix}-grid-th:hover {
        //background: #F5F5F5;
      }
    }
    .@{tableClsPrefix}-grid-tr:hover,
    .@{tableClsPrefix}-grid-tr__mouseover {
      background: none;
    }

    .@{tableClsPrefix}-grid-th {
      background-color: var(--table-head-bg);
      color: var(--main-fc);
      position: relative;

      .react-resizable-handle {
        position: absolute;
        padding: 0;
        width: 10px;
        height: 100%;
        bottom: 0;
        right: -5px;
        cursor: col-resize;
        background: none;
        touch-action: none;
      }
    }

    .@{tableClsPrefix}-grid-th-order:hover {
      background: var(--table-head-hover-bg);
      .@{tableClsPrefix}-grid-thead-sort {
        .ui-icon {
          visibility: visible;
        }
      }
    }

    &-sort {
      position: relative;
      //display: flex;
      align-items: center;
      margin-left: 3px;
      &-btn {
        cursor: pointer;
        // 仅在title为常规string为flex
        &.flex-btn{
          // 当固定列时 flex布局会被覆盖 需加important
          display: flex !important;
          // align-items: center;
        }
        align-items: center;
        .ui-icon {
          opacity: 0.8;
          visibility: hidden;
          margin-top: -2px;
        }
        .ui-icon:hover {
          opacity: 1;
        }
        .sort-btn-asc {
          visibility: visible;
          transform: rotateZ(180deg)
        }
        .sort-btn-desc {
          visibility: visible;
        }
        .sort-btn-visible {
          visibility: visible;
        }
        .sort-btn-hidden {
          visibility: hidden;
        }

        &-text{
          display: inline-block;
          max-width: calc(100% - 14px - 3px);
          overflow: hidden;
          // height: 14px;
          // line-height: 14px;
          text-overflow: ellipsis;
          white-space: noWrap;
        }
      }
    }

    &-border-bottom {
      border-bottom:  solid var(--share-title-border-color);
    }

    &-bordered {
      background-color: var(--bg-transparent);
      .@{tableClsPrefix}-grid-th {
        background-color: var(--bg-transparent);
      }
    }
  }

  &-thead > tr > th {
    &[colspan] {
      text-align: center;
    }
  }

  &-tbody {
    color: var(--table-font-color);
  }

  &-fixed {
    width: auto;
  }

  &-fixed-left,
  &-fixed-right {
    position: absolute;
    top: 0;
    overflow: hidden;
    background-color: white;

    .@{tableClsPrefix}-grid-fixed {
      border-left: 0;
      border-right: 0;
      border-spacing: 0;
      colgroup{
        display: none;
      }
    }

    .@{tableClsPrefix}-grid-table-ellipsis {
      table-layout: auto;
    }
  }

  &-fixed-left {
    left: 0;
    // FIXME: 为了让fix住的部分底部阴影去除
    box-shadow: 6px 0 6px -4px rgb(200 201 204 / 50%);

    .@{tableClsPrefix}-grid-header .@{tableClsPrefix}-grid-table {
      border-right: 0;
    }

    .@{tableClsPrefix}-grid-body {
      margin-right: -8px;
      box-sizing: border-box;
      

      // ie 滚动条处理 - start (只需要处理左侧 右侧固定如果有滚动条需要显示)
      -ms-scroll-chaining: chained;
      -ms-overflow-style: none;
      -ms-content-zooming: zoom;
      -ms-scroll-rails: none;
      -ms-content-zoom-limit-min: 100%;
      -ms-content-zoom-limit-max: 500%;
      -ms-scroll-snap-type: proximity;
      -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
      -ms-overflow-style: none;
      overflow: auto;
      &::-ms-scrollbar {
        width: 0 !important;
        display: none !important;
      }
      // ie 滚动条处理 - end

    }
  }

  &-fixed-right {
    right: 0;
    // FIXME: 为了让fix住的部分底部阴影去除
    box-shadow: -6px 0 6px -4px rgb(200 201 204 / 50%);

    .@{tableClsPrefix}-grid-header .@{tableClsPrefix}-grid-table {
      border-left: 0;
    }

    .@{tableClsPrefix}-grid-body .@{tableClsPrefix}-grid-table {
      border-left: 0;
    }

    .@{tableClsPrefix}-grid-body {
      box-sizing: border-box;
      //margin-bottom: -8px;

    }
  }

  &-scroll {

    table {
      width: auto;
      min-width: 100%;
    }

    .@{tableClsPrefix}-grid-body {
      position: relative;
    }
  }

  &-empty {
    text-align: center;
    min-height: calc(35 * var(--hd));
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--table-font-color);
    //border-bottom-width: 1px;
    //border-bottom-style: solid;
    //border-bottom-color: var(--border-color);
    line-height: calc(35 * var(--hd));
    &::after {
      content: '';
      min-height: inherit;
      font-size: 0;
    }
    // ie下需要展示上边
    &.show-top-border{
      border-top: 1px solid #ebedf0;
    }
  }

  &-td.@{tableClsPrefix}-grid-td-sticky {
    background-color: var(--bg-white);
  }

  &-td.@{tableClsPrefix}-grid-td-sticky,
  &-th.@{tableClsPrefix}-grid-th-sticky {
    position: sticky;
    z-index: 5;
    overflow: visible;
  }

  &-tr {
    background-color: var(--bg-transparent);
    display: table-row;
    contain-intrinsic-size: 35px;    // 2600 pieces of data optimized from 1600ms => 800ms
    content-visibility: auto;

    .@{tableClsPrefix}-grid-selection-show {
      .@{tableClsPrefix}-grid-selection-checkbox-rowIndex {
        display: none;
      }
    }

    .@{tableClsPrefix}-grid-selection-checkbox {
      .ui-checkbox {
        display: none;
      }
    }
    .@{tableClsPrefix}-grid-selection-checkbox-rowIndex {
      padding-left: calc(4 * var(--hd));
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__gray {
      background: var(--table-head-bg);
      .@{tableClsPrefix}-grid-td-sticky {
        background-color: var(--table-head-bg);
      }
    }

    &__expanded {
      td {
        background-color: #f7f8fa;
        padding: 0 calc(12 * var(--hd));
        word-break: break-word;
      }
    }

    &:hover,
    &__mouseover {
      background-color: var(--table-hover-bg);
      .@{tableClsPrefix}-grid-selection-checkbox {
        .ui-checkbox {
          display: block;
        }
      }
      .@{tableClsPrefix}-grid-selection-checkbox-rowIndex {
        display: none;
      }
      td {
        background-color: var(--table-hover-bg);
      }
    }
  }

  &-tr__disable-highlight {
    &:hover {
      background-color: var(--bg-white);
    }
  }

  &-th,
  &-td {
    border-bottom-color: var(--table-border-color);
    font-size: var(--font-size-12);
    display: table-cell;
    box-sizing: border-box;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    vertical-align: middle;
    // height: calc(35 * var(--hd));
    // line-height: calc(35 * var(--hd));
    height: calc(40 * var(--hd));
    line-height: calc(40 * var(--hd));
    color: var(--table-font-color);
    word-break: break-word;

    &:first-child {
      padding: 0 calc(16 * var(--hd));
    }

    &:last-child {
      padding: 0 calc(16 * var(--hd));
    }

    &-small {
      padding: calc(6 * var(--hd)) calc(8 * var(--hd)) !important;
    }

    &-large {
      padding: calc(12 * var(--hd)) calc(14 * var(--hd)) !important;
    }

    &-primary {
      color: var(--main-fc)
    }

    &-sortable-column {
      padding: 0 calc(2 * var(--hd)) 0 calc(12 * var(--hd));
    }
    &-selection+&-sortable-column {
      padding-left: var(--hd) !important;
    }

    &-sortable-icon {
      color: #999999;
    }
    // 大字体场景下序号列表头展示不全问题
    &[data-index ="table-index"]{
      padding: 0 calc(var(--hd)* 12);
    }
  }

  &-td&-td-selection+&-td-sortable-column {
    padding-left: var(--hd);
  }
  
  &-th {
    //background-color: #f7f8fa;
    //line-height: 24px;
    padding: 0 calc(12 * var(--hd));
    font-weight: 500;
    line-height: calc(24 * var(--hd));
    .ui-checkbox {
      // padding-bottom: calc(8 * var(--hd));
      height: calc(24 * var(--hd));
    }
  }

  &-th.@{tableClsPrefix}-grid-th-selection {
    .ui-checkbox{
      margin-right: calc(5 * var(--hd));
      .ui-checkbox-left {
        line-height: unset;
      }
    }

    > .ui-select {
      line-height: unset;
      margin-top: -8px;
      .ui-select-input-wrap{
        height: calc(14 * var(--hd)) !important;
        line-height:calc(14 * var(--hd)) ;
        .ui-select-input-placeholder {
          display: none;
        }
        .ui-input-wrap {
          padding: 0;
          border: none;
          background: unset;
          min-width: unset;
          line-height: unset;
          box-shadow: none;
          min-height: unset;
          line-height: calc(14 * var(--hd)) !important;
          .ui-select-input-arrow {
            display: inline-block;
            line-height: calc(14 * var(--hd));
          }
        }
      }
    }
  }

  &-td.@{tableClsPrefix}-grid-td-selection {
    //overflow: auto;
    .@{tableClsPrefix}-grid-selection-radio{
      line-height: 0;
    }
  }
  &-td.@{tableClsPrefix}-grid-td-selection > span{
    display: flex;
    align-items: center;
  }

  &-th.@{tableClsPrefix}-grid-th-selection,
  &-td.@{tableClsPrefix}-grid-td-selection,
  &-th.@{tableClsPrefix}-grid-th-expand,
  &-td.@{tableClsPrefix}-grid-td-expand {
    padding-right: 0;
  }

  &-td.@{tableClsPrefix}-grid-td-expand {
    .@{tableClsPrefix}-grid-expandable-icon {
      .ui-icon-svg {
        color: var(--secondary-fc);
      }
    }
  }

  &-td.@{tableClsPrefix}-grid-td-sortable-column{
    padding: 0 calc(2 * var(--hd)) 0 calc(12 * var(--hd));
    cursor: pointer;
  }

  &-td {
    line-height: calc(24 * var(--hd));
    //line-height: 1.7;
    padding: 0 calc(12 * var(--hd));
    position: relative;
    border-bottom-color: var(--table-border-color);

    .@{tableClsPrefix}-grid-expandable-btn {
      border-color: var(--table-light-border-color);
      background-color: var(--bg-white);
      cursor: pointer;
      display: inline-block;
      width: calc(14 * var(--hd));
      height: calc(14 * var(--hd));
      text-align: center;
      line-height: calc(12 * var(--hd));
      border-width: 1px;
      border-style: solid;
      user-select: none;

      &-switcher {
        position: absolute;
        //left: -18px;
        width: calc(16 * var(--hd));
        height: calc(16 * var(--hd));
        line-height: 2;
        display: inline-block;
        vertical-align: middle;
        border: 0 none;
        cursor: pointer;
        outline: none;
        top: calc(6 * var(--hd));
        &:after {
          border: calc(5 * var(--hd)) solid transparent;
          border-left-color: #999999;
          color: #646566;
          content: '';
          display: inline-block;
          border-left-width: calc(7 * var(--hd));
          transform: rotate(90deg);
          transform-origin: 23.09% 50%;
          transition: transform 0.3s ease;
        }
      }
    }

    .@{tableClsPrefix}-grid-td-expand-line-com {
      &.ie-no-shrink{
        flex-shrink: 0;
      }
      .Icon-up-arrow02 {
        color: var(--main-fc);
      }
    }

    .@{tableClsPrefix}-grid-td-expand-line-num {
      width: calc(15 * var(--hd));
      height: calc(15 * var(--hd));
      line-height: calc(15 * var(--hd));
      text-align: center;
      display: inline-block;
      border: 1px solid var(--main-fc);
      border-radius: 50%;
    }

    .switcher-off {
      &:after {
        transform: rotate(0deg);
      }
    }

    &-multiple-row {
      border-color: var(--table-light-border-color);
      border-left-width: 1px;
      border-right-width: 1px;
      border-top-width: 0;
      border-style: solid;
    }

    &__virtual {
      line-height: calc(40 * var(--hd));;
    }
  }

  &-tr:last-child &-td {
    //border-bottom-color: var(--border-color);
    border-bottom: none;
  }

  &-tfoot {
    padding: calc(8 * var(--hd)) calc(12 * var(--hd));
    overflow: hidden;
    border-top: 1px solid var(--table-border-color);

    .@{tableClsPrefix}-grid-tfoot-page {
      margin-top: 0;
    }

    .@{tableClsPrefix}-grid-batch.@{tableClsPrefix}-grid-batch--fixed {
      position: fixed;
      left: unset;
      z-index: 1;
      bottom: 0;
      padding-top: calc(12 * var(--hd));
      box-sizing: border-box;
      border-top-width: 1px;
      border-top-style: solid;
    }
  }

  &-tfoot-page {
    //margin: 10px 0;
    float: right;
  }

  &-tfoot-more {
    color: var(--secondary-fc);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &-tfoot-more:hover {
    color: #5d9cec
  }

  &-tfoot-all{
    cursor: default;
  }

  &-tfoot-all:hover {
    color: var(--secondary-fc);
  }

  &-tfoot-loading {
    .ui-spin {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: calc(3 * var(--hd));
    }
  }

  &-tfoot-loading:hover {
    color: var(--secondary-fc);
  }

  a {
    cursor: pointer;
  }

  .@{tableClsPrefix}-grid-batch {
    padding-left: calc(12 * var(--hd));
    padding-bottom: calc(12 * var(--hd));
    display: flex;
    align-items: center;

    .batch-component {
      //line-height: 20px;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .@{tableClsPrefix}-grid-batch__foot {
    float: left;
  }

  &-selection-checkbox {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    line-height: 1;
    font-size: 0;
    display: inline-block;
  }

  .@{tableClsPrefix}-grid-sticky-header {
    overflow-x: scroll;
  }

  &-sticky-position, &-sticky-header-warp {
    position: fixed;
    opacity: 0;
    overflow: hidden;
    z-index: 9;
  }
}

.@{tableClsPrefix}-sortable-bg.@{tableClsPrefix}-grid-tr {
  // drop placeholder的css类名
  // border: dashed 1px #ccc !important;
  outline: #ccc dashed calc(1.8 * var(--hd))  !important;
  background-color: #f4f5da !important;
  td {
    border: none;
  }
  span {
    visibility:hidden !important;
  }
}
.@{tableClsPrefix}-sortable-drag.@{tableClsPrefix}-grid-tr {
  background: #ffffff !important;
  box-shadow: 0 3px 12px 0 rgba(0,0,0,0.12) !important;
  opacity: 1 !important;
}

.@{tableClsPrefix}-grid-th-selection-select-warp {
  .ui-select-dropdown {
    margin-top: -5px;
    margin-bottom: 0;
  }
}

.weapp-ie11 {
  .@{tableClsPrefix}-grid {
    &-tr {
      &__expanded {
        word-break: break-word;
      }
    }

    &-th,
    &-td {
      word-break: break-word;
    }
  }
}
