@import "./common.less";

.@{listClsPrefix} {
  display: inline-block;
  // overflow: hidden;
  height: 100%;

  .switcher-off {
    &:after {
      transform: rotate(0deg);
    }
  }
  &-none {
    width: 35px;
  }

  &-bordered {
    border: 1px solid #E5E5E5;
  }
}
.@{listClsPrefix}-scrollview {
  height: 100%;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  &.hasFooter {
    height: calc(100% - 24px);
  }
}
.@{listClsPrefix}-grid-expandable-btn{
  width: 35px;
}
.@{listClsPrefix}-grid-expandable-btn-switcher {
  // position: absolute;
  //left: -18px;
  width: 16px;
  height: 16px;
  // line-height: 2;
  display: inline-block;
  vertical-align: middle;
  border: 0 none;
  cursor: pointer;
  outline: none;
  // top: 6.5px;
  &:after {
    border: 5px solid transparent;
    border-left-color: #999999;
    color: #646566;
    content: '';
    display: inline-block;
    border-left-width: 7px;
    transform: rotate(90deg);
    transform-origin: 23.09% 50%;
    transition: transform 0.3s ease;
  }
}

// 横向布局
.@{listClsPrefix}-row .@{listClsPrefix}-item {
  float: left;
}
.@{listClsPrefix}-row .@{listClsPrefix}-header {
  float: none;
}
.@{listClsPrefix}-row .@{listClsPrefix}-customitem {
  float: left;
}
.@{listClsPrefix}-row .@{listClsPrefix}-body {
  overflow: hidden;
  zoom: 1;
}
// 横向显示,超出显示滚动不换行
.@{listClsPrefix}-row .@{listClsPrefix}-body-scroll {
  white-space: nowrap;
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
// 纵向布局
.@{listClsPrefix}-column {
  width: 100%;
}
// .@{listClsPrefix}-column .@{listClsPrefix}-body {
//   border-bottom: var(--hd) solid var(--diviling-line-color);
// }
// .@{listClsPrefix}-column .@{listClsPrefix}-body-noBorder {
//   border: none;
// }
.@{listClsPrefix}-column .@{listClsPrefix}-body
div:not(:last-child)
.@{listClsPrefix}-content {
  border-bottom: calc(1 * var(--hd)) solid var(--diviling-line-color);
}
.@{listClsPrefix}-column .@{listClsPrefix}-body .@{listClsPrefix}-item.@{listClsPrefix}-item-noBorder .@{listClsPrefix}-content {
  border: none;
}
.@{listClsPrefix}-column .@{listClsPrefix}-item {
  position: relative;
  display: flex;
  // min-height: calc(35 * var(--hd));
  background-color: var(--bg-transparent);
  vertical-align: middle;
  overflow: hidden;
  transition: background-color 200ms;
  align-items: stretch;
  &.isActive {
    background-color: #edf4ff;
  }
  & > *:not(.@{listClsPrefix}-content) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
  }
  .@{listClsPrefix}-check.ui-checkbox-wrapper {
    display: none;
  }
  .@{listClsPrefix}-check.ui-checkbox-wrapper-checked {
    display: flex;
    width: calc(35 * var(--hd));
    text-align: center;
  }

  &-noorder {
    .@{listClsPrefix}-check.ui-checkbox-wrapper {
      display: flex;
      width: calc(35 * var(--hd));
      text-align: center;
    }
  }

  &:hover {
    .@{listClsPrefix}-check.ui-checkbox-wrapper {
      display: flex;
      width: calc(35 * var(--hd));
      text-align: center;
    }

  }

  &.@{listClsPrefix}-item-check:hover .@{listClsPrefix}-order {
    display: none;
  }

  &-multiple {
    padding: calc(5 * var(--hd)) calc(15 * var(--hd)) calc(5 * var(--hd)) 0;
    .@{listClsPrefix}-content {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &-wrap {
    .@{listClsPrefix}-content {
      white-space: normal;
    }
  }
  &-checkbox-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-55%);
  }
  &-col {
    flex: 1;
  }
  &-row {
    font-size: var(--font-size-14);
    color: var(--list-color);
    &-span {
      display: inline-block;
      word-break: break-word;
      &-text-highlight {
        color: var(--main-fc);
        font-weight: bold;
      }
    }
  }
}
/* list左图片显示*/
.@{listClsPrefix}-thumb {
  width: calc(35 * var(--hd));
  text-align: center;
  // &:first-child {
  //   // margin-right: calc(16 * var(--hd));
  // }

  // &:last-child {
  //   // margin-left: calc(8 * var(--hd));
  // }
  &-img {
    width: calc(16 * var(--hd));
    height: calc(16 * var(--hd));
    vertical-align: middle;
  }
}

.@{listClsPrefix}-order {
  width: calc(35 * var(--hd));
  color: var(--secondary-fc);
  text-align: center;
}
.@{listClsPrefix}-content {
  position: relative;
  overflow: hidden;
  width: 100%;
  // height: 100%;
  // min-height: calc(35 * var(--hd));
  min-height: calc(40 * var(--hd));
  color: var(--black-fc);
  font-size: var(--font-size-12);
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .ellipsis();
}
.@{listClsPrefix}-sortable-bg.@{listClsPrefix}-item {
  // drop placeholder的css类名
  //border: dashed 1px #ccc;
  outline: var(--upload-outline);
  background-color: #f4f5da;
  >* {
    visibility:hidden;
  }
}
.@{listClsPrefix}-sortable-drag.@{listClsPrefix}-item {
  background: #ffffff;
  box-shadow: 0 3px 12px 0 rgba(0,0,0,0.12);
  opacity: 1 !important;
}
// .@{listClsPrefix}-item-noorder {
//   .@{listClsPrefix}-check.ui-checkbox-wrapper {
//     display: flex;
//     width: calc(35 * var(--hd));
//     text-align: center;
//   }
// }
.@{listClsPrefix}-header-item-noorder {
  .@{listClsPrefix}-check.ui-checkbox-wrapper {
    display: flex;
    width: calc(35 * var(--hd));
    text-align: center;
  }
}

// .@{listClsPrefix}-item:hover {
//   .@{listClsPrefix}-check.ui-checkbox-wrapper {
//     display: flex;
//     width: calc(35 * var(--hd));
//     text-align: center;
//   }
//   .@{listClsPrefix}-order {
//     display: none;
//   }
// }
.@{listClsPrefix}-indexedlist-container {
  position: relative;
}
.@{listClsPrefix}-quick-search-bar {
  position: absolute;
  top: 0;
  right: calc(20 * var(--hd));
  z-index: 0;
  text-align: center;
  font-size: var(--font-size-10);
  list-style: none;
  padding: 0;
  cursor: pointer;
  &-item {
    list-style-type: none;
    padding: 2px;
    &.active {
      color: var(--primary);
    }
  }
}
.@{listClsPrefix}-header-line {
  display: inline-block;
}
.@{listClsPrefix}-footer-more,
.@{listClsPrefix}-footer-nomore,
.@{listClsPrefix}-footer-loading {
  color: #999999;
  font-size: var(--font-size-12);
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.@{listClsPrefix}-footer-nomore {
  cursor: default;
}

.@{listClsPrefix}-footer-more:hover {
  color: #5d9cec;
}
.@{listClsPrefix}-pagination {
  margin-top: 10px;
}


// 镜像样式
.@{listClsPrefix}-rtl.@{listClsPrefix}-sortable {
  transform: scaleX(-1);
  direction: rtl;

  .@{listClsPrefix}-content{
    >*{
      transform: scaleX(-1);
      direction: ltr;
    }
    text-align: right;
  }

  .@{listClsPrefix}-header,
  .@{listClsPrefix}-footer {
    transform: scaleX(-1);
  }

  .@{listClsPrefix}-item-checkbox-right {
    left: 0;
    right: unset!important;
  }

  .@{listClsPrefix}-body-scroll{
    direction: ltr;
  }
}

.@{listClsPrefix}-rtl.@{listClsPrefix}-sortable.@{listClsPrefix}-expandable {
  .@{listClsPrefix}-content {
    >* {
      transform: scaleX(1);
      direction: ltr;
    }
  }
}

.@{listClsPrefix}-rtl {
  .@{boxSelectionClsPrefix} {
    .mirror-text-re;
  }
}