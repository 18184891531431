.comment-ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.comment-link() {
  color: var(--comment-link-color);
  &:hover {
    text-decoration: underline;
  }
}

.comment-separate() {
  position: relative;
  margin-right: 8px;
  padding-right: 8px;
  &::after {
    content: "";
    border-right: 1px solid #dcdede;
    position: absolute;
    height: calc(20 * var(--hd));
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}