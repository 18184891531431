.@{colorBoardClsPrefix} {
  display: inline-flex;
  align-items: center;

  .current {
    width: calc(30 * var(--hd));
    height: calc(30 * var(--hd));
    margin-right: calc(5 * var(--hd));
  }

  .current-circle {
    border-radius: 50%;
  }

  .option {
    min-height: calc(30 * var(--hd));
    display: flex;
    flex-direction: column;

    &-line1,
    &-line2 {
      display: flex;
      padding: calc(2 * var(--hd)) 0;
    }

    &-colorCell {
      cursor: pointer;
      display: inline-block;
      margin: 0 calc(2 * var(--hd));
      width: calc(12 * var(--hd));
      height: calc(12 * var(--hd));

    }

    &-colorCell-circle {
      border-radius: 50%;
    }
  }
}