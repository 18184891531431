.@{layoutAdaptClsPrefix} {
  &-left {
    flex: 1;
  }

  &-right {
    margin-top: 16px;
  }
}


@media screen and (min-width: 1440px) {
  .@{layoutAdaptClsPrefix} {
    display: flex;

    &-left {
    }

    &-right {
      min-width: 380px;
      max-width: 380px;
      margin-top: 0;
      margin-left: 16px;
    }
  }

}
