.@{mToastClsPrefix} {
  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    // background-color: var(--mask-bc);
    z-index: var(--dialog-route-layout-zindex);
  }

  &-wrapper {
    left: 50%;
    position: fixed;
    animation-name: ToastMoveIn;
    animation-duration: 0.3s;
    top: 50%;
    z-index: var(--toast-zindex);
    transform: translate(-50%, -50%);
  }

  &-wrapper-isHorizontal {
    animation: unset;
    transform: translate(-50%, -50%) rotate(-90deg);

    &-clockwise {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &-box-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    background: var(--base-black);
    border-radius: var(--border-radius-sm);
  }

  &-content {
    box-shadow: var(--dialog-box-shadow);
    text-align: center;
    display: inline-block;
    padding: var(--v-spacing-md) var(--h-spacing-lg);
    border-radius: var(--border-radius-sm);
    // background-color: var(--bg-white);
    position: relative;

    &:hover {
      .@{mToastClsPrefix}-custom-close {
        display: block;
      }
    }
  }

  &-loading-icon {
    margin-bottom: calc(6 * var(--hd));
  }

  &-loading,
  &-icon {
    display: inline-block;
    position: relative;
    margin: 0 auto;
    margin-bottom: calc(6 * var(--hd));
    vertical-align: middle;
    font-size: var(--font-size-base);
    width: calc(36 * var(--hd));
    height: calc(36 * var(--hd));
    border-radius: 50%;
  }

  &-icon {
    position: relative;
    // background-color: var(--message-info-icon);
    color: var(--base-white);
    border: var(--hd) solid var(--base-white);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-spin {
    .ui-spin-dot-item:nth-child(5) {
      border: calc(2 * var(--hd)) solid var(--secondary-fc);
    }

    .ui-spin-dot-item.start-point span {
      background: var(--bg-white);
    }

    .ui-spin-dot-item {
      border: calc(2 * var(--hd)) solid var(--base-white);
      border-color: var(--base-white) transparent transparent transparent;
    }
  }

  &-body {
    // margin-top: 6px;
    position: relative;
    display: inline-block;
    // padding-left: var(--h-spacing-md);
    line-height: 1.5;
    font-size: var(--font-size-md);
    color: var(--base-white);
  }
}

@keyframes ToastMoveIn {
  0% {
    opacity: 0;
    // top: 0;
  }

  100% {
    transform: translate(-50%, -50%);
    // top: 50%;
    opacity: 1;
  }
}