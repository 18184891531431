.@{mAudioClsPrefix} {
  &-audio {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &-audio audio {
    line-height: 0;
    width: 100%;
  }

  &-rtl {
    transform: scaleX(-1);
  }
}