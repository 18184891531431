.@{commentClsPrefix}-floor {
  border: var(--comment-grey-border);
  background-color: var(--comment-grey-bg-color);
  border-radius: calc(3 * var(--hd));
  height: calc(33 * var(--hd));
  transition: height 0.3s;
  margin-top: calc(6 * var(--hd));
  overflow: hidden;
  font-size: var(--font-size-12);
  &-change {
    &-btn {
      height: calc(31 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    &-icon {
      transition: transform 0.3s;
      padding: 0 calc(5 * var(--hd));
      &-expand {
        transform: rotate(180deg);
      }
    }
    &-title {
      color: var(--main-fc);
    }
  }
  &-content {
    border-top: var(--comment-grey-border);
    .@{commentClsPrefix}-item:hover {
      background: inherit;
    }
  }
  &-list {
    display: block;
    padding: 0 calc(10 * var(--hd));
    .@{listClsPrefix}-pagination {
      margin-bottom: calc(10 * var(--hd));
    }
  }
  &-item {
    &:not(:last-child) {
      border-bottom: var(--border-width) dashed var(--border-color);
    }
    &-hover {
      .@{commentClsPrefix}-floor-item-option {
        opacity: 0;
      }
      &:hover {        
        .@{commentClsPrefix}-floor-item-option {
          opacity: 1;
        }
      }
    }
    &-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: calc(9 * var(--hd)) 0;
      .@{commentClsPrefix}-username {
        height: auto;
      }
      .@{mCommentClsPrefix}-item-privy {
        margin-left: auto;
        top: 0;
        font-size: var(--font-size-12);
      }
      .@{commentClsPrefix}-floor-item-option {
        margin-left: auto;
      }
    }
    &-footer {
      &-content {
        display: flex;
        flex-wrap: wrap;
        .@{commentClsPrefix}-floor-item-option {
          margin-left: auto;
        }
      }
      &-content:not(:empty) {
        padding-bottom: calc(10 * var(--hd));
      }
    }
    & &-reply {
      padding: 0;
      background-color: var(--comment-grey-bg-color);
    }
    &-label {
      padding-right: calc(5 * var(--hd));
    }
    &-option {
      display: inline-flex;
      float: right;
      color: var(--regular-fc);
      .@{commentClsPrefix}-iconbtn {
        &:not(:last-child) {
          padding-right: calc(16 * var(--hd));
        }
      }
    }
  }
  &-empty {
    height: calc(45 * var(--hd));
  }

  &-list.ui-list-rtl {
    .@{commentClsPrefix}-floor-item {
      transform: scaleX(-1) !important;

      &-content {
        .@{commentClsPrefix}-item-text {
          transform: scaleX(1) !important;
        }
      }
    }
  }
}


.@{commentClsPrefix}-floor-item-option-more {
  .ui-popover-content {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      display: flex;
      flex-direction: column;
  
      .ui-comment-iconbtn {
        padding: var(--v-spacing-md) 0;
        height: auto;
        color: var(--regular-fc);

        .ui-comment-iconbtn-title {
          margin-left: calc(5* var(--hd));
        }
  
        &:first-child {
          border-bottom: var(--border-width) solid var(--border-color);
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}