@import './cropper.less';

.@{imageCropperClsPrefix} {

  input[type='file'] {
    display: none;
  }

  .@{dialogClsPrefix} {

    &-body-container,
    &-footer {
      background-color: var(--bg-white);
    }
  }

  &-container {
    display: flex;
    // justify-content: center;
    padding: 10px 15px;

    &-left {
      position: relative;
      min-width: 234px;
      min-height: 234px;
    }

    &-right {
      position: relative;
      margin-left: calc(50 * var(--hd));
    }

    &-image {
      display: block;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }

    &-photo {
      margin-left: calc(50 * var(--hd));

      &-wrap {
        position: relative;
        background: #f2f2f2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-photo-wrap-rtl {
      transform: scaleX(-1);
    }

    &-photo2 {
      width: 0px;
      height: 0px;
      overflow: hidden;
    }

    &-tip {
      margin: 10px 0;
      font-size: var(--font-size-12);
      color: var(--secondary-fc);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &-avatar {
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
    }
  }

  &-container {
    min-width: 500px;
    // min-height: 295px;
  }

  &-photo_container {
    min-width: 760;
    // min-height: 390px;
  }

  &-cropper-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #f2f2f2;
  }

  &-preview-container {
    position: relative;

    &-clone {
      position: absolute;
      top: 0;
      left: 0;
      height: calc(100% + 1px);
      width: calc(100% + 1px);
      transform: translate(-0.5px, -0.5px);
      z-index: var(--image-cropper-mask-zIndex);
    }
  }

  &-default-preview {
    display: block;
    width: 100%;
    height: 100%;
  }

  &-empty-icon {
    display: block;
    text-align: center;
    background-color: #f2f2f2;
    min-height: 200px;
    line-height: 200px;
    width: 100%;
    height: 100%;

    svg {
      width: 40px;
      height: 40px;
      color: rgb(187, 187, 187);
    }
  }

  &-img {
    &-preview {
      position: relative;
      margin: 0 auto;
      overflow: hidden;
      border-radius: 50%;
      background: #f2f2f2;

      &>*:not(img) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-preview-rtl {
      transform: scaleX(-1);
    }

    &-box {
      width: 100%;
      max-height: calc(320 * var(--hd));
      height: 100%;
      position: relative;
      overflow: hidden;

      &-photoMode {
        max-height: calc(320 * var(--hd));
        min-height: calc(320 * var(--hd));
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-hidden {
        display: none;
      }
    }

    &-box-rtl {
      transform: scaleX(-1);
    }
  }

  &-photo {
    &-preview {
      position: relative;
      margin: 0 auto;
      overflow: hidden;
      // border-radius: 50%;
      background: #f2f2f2;

      &>*:not(img) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-preview-empty {
      display: flex;
      align-items: center;
      justify-content: center;

      &>*:not(img) {
        position: unset;
        top: unset;
        left: unset;
        transform: unset;
      }
    }
  }

  &-tools {
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 25px;
    margin-bottom: 10px;

    &-upload {
      .@{iconClsPrefix}-wrapper {
        line-height: initial;
      }
    }

    &-upload,
    &-upload label,
    &-reupload,
    &-reupload label,
    &-delete {
      margin-right: calc(10 * var(--hd));
      cursor: pointer;
      font-size: var(--font-size-12);
      color: var(--secondary-fc);

      &:hover {
        color: var(--primary);
      }
    }

    &-reupload-text {
      display: flex;
      align-items: center;
    }

    &-zoomIn,
    &-zoomOut,
    &-rotate {
      cursor: pointer;
      margin-right: calc(10 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: var(--secondary-fc);
        width: calc(16 * var(--hd));
        height: calc(16 * var(--hd));
      }

      &:hover {
        svg {
          color: var(--primary);
        }
      }
    }

    &-text-item {
      min-width: calc(50 * var(--hd));
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &-upload-image {
    display: none;
  }

  &-bottom {
    display: flex;
    padding: 0 15px;
    margin-top: 10px;
    justify-content: flex-end;

    button {
      margin-right: 10px;
    }
  }
}

.@{imageCropperClsPrefix}-photo {
  max-width: 800px;
  position: relative;

  &-preview-clone {
    position: absolute;
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    margin: auto;

    &-img {
      width: unset !important;
      height: unset !important;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      flex: 1; // ie 11 max-width 失效，使用 flex 撑满
      object-fit: contain;
    }
  }
}