.@{treeClsPrefix} {
  color: #323233;
  font-size: var(--font-size-12);
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;
  //overflow-y: scroll;
  //height: 100%;
  ul {
    padding: 0;
  }
  .tree-node-loading-wrapper {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    .tree-node-loading {
      border-color: #155bd4;
      display: inline-block;
      height: calc(10 * var(--hd));
      width: calc(10 * var(--hd));
      border-radius: 50%;
      margin-right: calc(10 * var(--hd));
      border-width: var(--hd);
      border-style: solid;

      @keyframes loading-animation {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }

      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        height: calc(10 * var(--hd));
        width: calc(4 * var(--hd));
        animation: 2s linear loading-animation infinite;
        transform-origin: calc(10 * var(--hd)) calc(4 * var(--hd));
        background: #ECF5FF;
        left: calc(-1 * var(--hd));
        top: calc(6 * var(--hd));
      }
    }
  }

  &-rtl-content {
    display: inline-flex !important;
  }
  &-rtl-search-content {
    transform: scaleX(1) !important;
  }
  &-rtl-search-high-light-content {
    display: inline;
  }


  &-with-search {
    height: calc(100% - calc(50 * var(--hd)));
  }

  &-warp {
    height: 100%;
  }
}
.@{treeClsPrefix} ul,
.@{treeClsPrefix} li {
  white-space: nowrap;
  list-style: none;
}
.@{treeClsPrefix}-bar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: calc(2 * var(--hd));
  padding-bottom: calc(2 * var(--hd));
  padding-right: calc(10 * var(--hd));
  color: var(--secondary-fc);
  &-switcher-warp {
    width: calc(16 * var(--hd));
    display: inline-block;
    flex-shrink: 0;
    .switcher {
      // margin-bottom: 2px;
      transition: transform 0.3s ease;
      transform: rotate(90deg);
      color: var(--secondary-fc);
      cursor: pointer;
      .Icon-Right-arrow04 {
        width: calc(16 * var(--hd));
        height: calc(16 * var(--hd));
      }
    }
  }

  &.off {
    .switcher {
      transform: rotate(0deg);
    }
  }
  &.isSelected {
    background-color: #f5f5f5;
  }
  &:hover {
    .@{treeClsPrefix}-node {
      .operation {
        opacity: 0.8;
      }
    }
  }
  .directory-icon {
    position: absolute;
    left: calc(-18 * var(--hd));
    width: calc(16 * var(--hd));
    height: calc(16 * var(--hd));
    line-height: calc(28 * var(--hd));
    display: inline-block;
    vertical-align: middle;
    border: 0 none;
    cursor: pointer;
    outline: none;
  }
  .@{treeClsPrefix}-node {
    display: flex;
    line-height: 2;
    cursor: pointer;
    width: 100%;
    &-content {
      padding: 0 calc(10 * var(--hd)) 0 calc(4 * var(--hd));
      display: inline-block;
      border: 1px solid transparent;
      border-radius: 4px;
      transition: all 0.3s ease-in-out;
      margin-left: calc(3 * var(--hd));
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      vertical-align: bottom;
      color: var(--main-fc);
      .__high-light {
        color: #4ba9f2;
      }
    }
    &-icon {
      margin: 0 calc(4 * var(--hd));
    }
    .operation {
      display: inline-block;
      margin-left: calc(10 * var(--hd));
      opacity: 0;
      font-size: 0.8em;
      color: black;
      transition: opacity 0.3s ease-in;
      & > span {
        &.opt {
          &:not(:last-of-type) {
            border-left-color: #999999;
            margin-right: calc(8 * var(--hd));
            padding-right: calc(8 * var(--hd));
            border-left-width: calc(2 * var(--hd));
            border-left-style: solid;
          }
        }
      }
    }
    &-checkbox {
      .ui-checkbox-wrapper {
        margin-right: 0;
        height: 100%;
      }
    }
    .checkbox-hidden {
      visibility: hidden;
    }
  }
  .@{treeClsPrefix}-node-right {
    flex-direction: row-reverse;
    margin-left: calc(5 * var(--hd));
  }
  &:hover {
    background-color: #f5f5f5;
  }
}
.@{treeClsPrefix}-child {
  overflow: hidden;
  &-complete, &-loadmore, &-spin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(30 * var(--hd));
    margin: 0;
  }
  &-complete {
    color: var(--invalid);
  }
  &-loadmore {
    cursor: pointer;
    color: var(--primary);
  }
  &-spin {
    .ui-spin {
      display: flex;
      align-items: center;
    }
  }
}
.@{treeClsPrefix}-search {
  margin: calc(10 * var(--hd)) 0;
  display: flex;
  align-items: center;
  &-label {
    font-size: var(--font-size-12);
    color: var(--main-fc);
    display: inline-block;
    min-width: calc(40 * var(--hd));
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ui-input-wrap {
    .ui-input-suffix {
      .ui-icon {
        color: var(--secondary-fc);
      }
      .Icon-cancel {
        margin-top: -3px;
        margin-right: 3px;
      }
    }
  }
}
.@{treeClsPrefix}-empty {
  font-size: var(--font-size-12);
  color: var(--main-fc);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .Icon-No-data-available {
    width: calc(80 * var(--hd));
    height: calc(80 * var(--hd));
    margin-bottom: calc(10 * var(--hd));
    margin-top: calc(30 * var(--hd));;
  }
  &-label {
    text-align: center;
    &-text {
      font-size: var(--font-size-14);
      color: var(--regular-fc);
    }
    &-change {
      padding-top: calc(10 * var(--hd));
      color: var(--secondary-fc);
    }
  }
}
