/*********************************************************************************************/
/*******************************  列表样式 - listType = 'list'  *******************************/
/*********************************************************************************************/

/**
* 已上传列表样式
*/
.@{mUploadClsPrefix} {
  &-listT {
    width: 100%;
    border-top: var(--hd) solid var(--diviling-line-color);
    display: block;
    /**修改*/
    background: var(--bg-white);
    /*修改*/
    .ui-m-checkbox-wrapper {
      margin-top: calc(25 * var(--hd));
      display: flex;
      justify-content: flex-start;
      background-color: var(--bg-white);
      background-color: var(--m-chk-default-bc);
      vertical-align: middle;
      position: relative;
      /*修改*/
      margin-left: calc(9 * var(--hd));
      /*修改*/
    }
    &-item {
      width: 100%;
      display: flex;
      align-content: center;
      position: relative;
      align-items: center;
      /*修改*/
      margin-left: calc(9 * var(--hd));
      // margin-right: calc(16 * var(--hd));
      width: calc(100% - calc(16 * var(--hd)));
      /*修改*/
      //   .ui-m-checkbox-inner-left {
      //     margin-top: 50px;
      //     // margin-right: calc(19 * 1px);
      //     margin-right: calc(19 * var(--hd));
      // }

      /** 附件已删除 */
      &.is-deleted,
      &.is-undefined {
        filter: grayscale(100%);
        .@{mUploadClsPrefix}-listT-item-fileinfo-filename {
          color: var(--regular-fc);
        }
      }
      &.is-deleted:not(.is-undefined) {
        .@{mUploadClsPrefix}-listT-item-fileinfo-filename {
          text-decoration: line-through;
        }
      }

      &-icon {
        margin-right: calc(10 * var(--hd));
        &.@{mUploadClsPrefix}-handle {
          color: var(--secondary-fc);
        }
      }
      &-fileinfo {
        width: calc(100% - calc(60 * var(--hd)));
        padding: calc(8 * var(--hd)) 0;
        &-filename {
          font-size: var(--font-size-14);
          color: var(--main-fc);
          width: 100%;
          white-space: normal;
          word-wrap: break-word;
          white-space: pre-wrap;
          line-height: 1.5;
          &.overflow {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .secondary-text {
          margin-right: calc(10 * var(--hd));
          font-size: var(--font-size-12);
          line-height: calc(20 * var(--hd));
          float: left;
          white-space: pre-wrap;
        }
        &-username,
        &-time,
        &-filesize,
        &-signtip,
        &-seclevel {
          display: inline-block;
          color: var(--secondary-fc);
        }
        &-filesize {
          clear: both;
        }
        &-signtip {
          color: var(--upload-signtip-color);
        }
        &-tip {
          color: var(--upload-errortip-color);
          clear: both;
        }
      }
      &-fileinfo::after {
        width: 150%;
      }
      &-fileinfo.read-only-line-height-standard {
        line-height: var(--form-mobile-readonly-line-height);
        height: auto;
        padding: 0px;
        & > div {
          line-height: var(--form-mobile-readonly-line-height);
        }
      }
      &.wrap &-fileinfo {
        &-filename {
          text-overflow: unset;
          word-break: break-word;
          white-space: break-spaces;
        }
      }
      &.is-sortable &-fileinfo {
        width: calc(100% - calc(80 * var(--hd)));
      }
      &-more {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: var(--secondary-fc);
        text-align: right;
        margin-left: auto;
        color: var(--secondary-fc);
      }
    }
  }
}

/**
* 待上传列表样式
*/
.@{mUploadClsPrefix} {
  &-listB {
    display: block;
    .ui-m-checkbox-wrapper {
      margin-top: 12px;
      margin-left: 9px;
      display: flex;
      justify-content: flex-start;
      background-color: var(--bg-white);
      background-color: var(--m-chk-default-bc);
      vertical-align: middle;
      position: relative;
    }
  }
  &-listB-listitem &-listB-listitem-fileinfo &-listB-listitem-fileinfo-slider {
    margin-top: 0;
  }
  &-listB-listitem {
    width: 100%;
    line-height: calc(25 * var(--hd));
    display: flex;
    align-items: center;
    /*修改*/
    margin-left: calc(9 * var(--hd));
    // margin-right: calc(16 * var(--hd));
    /*修改**/
    &-wrapper {
      display: flex;
      width: 100%;
    }
    &-icon {
      display: flex;
      align-items: center;
      margin-right: calc(10 * var(--hd));
    }
    &-fileinfo {
      width: 100%;
      padding: calc(5 * var(--hd)) 0;
      .secondary-text {
        display: inline-block;
        margin-right: calc(10 * var(--hd));
      }
      &-wrapper {
        width: calc(100% - calc(80 * var(--hd)));
      }
      &-filename {
        font-size: var(--font-size-14);
        color: var(--main-fc);
        white-space: normal;
        word-wrap: break-word;
        line-height: 1.5;
        &.overflow {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &-filesize,
      &-seclevel {
        align-self: center;
        line-height: calc(15 * var(--hd));
        font-size: var(--font-size-12);
        color: var(--secondary-fc);
      }
      &-progress {
        display: flex;
        align-items: center;
      }
      &-percent {
        color: var(--secondary-fc);
        line-height: calc(20 * var(--hd));
        font-size: var(--font-size-10);
        margin-left: calc(10 * var(--hd));
      }
      &-errorinfo,
      &-analysis-info {
        white-space: normal;
        word-wrap: break-word;
        line-height: 1.5;
        margin: calc(4 * var(--hd)) 0;
      }
      &-analysis-info {
        color: var(--secondary-fc);
      }
      &-errorinfo {
        color: var(--danger);
      }
      &-breakpoint-resumption {
        margin-left: calc(10 * var(--hd));
        color: var(--primary);
      }
    }
    &-fileinfo::after {
      width: 150%;
      left: calc(30 * var(--hd));
    }
    // 取消/更多 按钮
    &-cancel-icon,
    &-more {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      color: var(--secondary-fc);
      /*修改*/
      margin-right: calc(16 * var(--hd));
    }
    &-batch {
      padding-left: 0px !important;
    }
  }
}

/*******************************************************************************/
/*******************************  列表样式 - listType = 'img'  *******************************/
/*******************************************************************************/

/**
* 已上传列表样式
*/
.@{mUploadClsPrefix} {
  // width: auto;
  .@{mUploadClsPrefix}-listT {
    background-color: var(--bg-transparent);
  }
  /* 图片 box 外层容器 */
  .@{mUploadClsPrefix}-listT-item-imglist {
    // width: calc(70 * var(--hd));
    // height: calc(70 * var(--hd));
    margin-top: calc(10 * var(--hd));
    margin-right: calc(10 * var(--hd));
    position: relative;
    float: left;
    /* 图片删除按钮 */
    &-delete {
      position: absolute;
      border: var(--hd) solid;
      top: calc(-6 * var(--hd));
      right: calc(-4 * var(--hd));
      color: var(--base-white);
      background-color: var(--m-upload-delete-btn-backgroud);
      border-radius: calc(10 * var(--hd));
    }
  }

  /* 图片 box */
  &-listT-item {
    &-img-box {
      background-color: var(--upload-image-bgcolor);
      display: flex;
      align-items: center;
      justify-content: center;

      &.is-deleted {
        background-color: transparent;
        border: var(--upload-outline);
      }

      img {
        max-width: 100%;
        max-height: 100%;
        flex: 1 1;
        object-fit: contain;
      }
    }
    // 已删除外层容器
    &-img-deleted-box {
      width: 100%;
      height: 100%;
      // color: #808080;
      color: #C0C0C0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    //  错误提示
    &-info {
      text-align: center;
    }
  }
}

/**
* 待上传列表样式
*/
.@{mUploadClsPrefix} {
  &-img {
    .ui-m-list-column .ui-m-list-item {
      background-color: var(--bg-transparent);
      float: left;
    }
    .@{mUploadClsPrefix}-listB-item-imglist {
      position: relative;
      float: left;
      &-delete {
        position: absolute;
        border: var(--hd) solid;
        top: calc(-6 * var(--hd));
        right: calc(-4 * var(--hd));
        color: var(--base-white);
        background-color: var(--m-upload-delete-btn-backgroud);
        border-radius: calc(10 * var(--hd));
      }
    }
    .@{mUploadClsPrefix}-listB-item-uploading {
      // width: calc(70 * var(--hd));
      // height: calc(70 * var(--hd));
      padding-right: 0;
      padding-top: 0;
      margin-right: calc(10 * var(--hd));
      margin-top: calc(10 * var(--hd));
      border: var(--hd) solid var(--border-color);
      text-align: center;
      opacity: 0.3;
      background: var(--base-black);
      .@{mUploadClsPrefix}-listB-item-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--base-white);
      }
    }
    .@{mUploadClsPrefix}-listB-item-uploaded {
      padding-right: 0;
      margin-right: calc(10 * var(--hd));
      margin-top: calc(10 * var(--hd));
      text-align: center;
      box-sizing: border-box;
      .@{mUploadClsPrefix}-listB-item-inner {
        border: var(--hd) solid var(--border-color);
        // width: calc(70 * var(--hd));
        // height: calc(70 * var(--hd));
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .@{mUploadClsPrefix}-listB-item-errorinfo {
        // width: calc(70 * var(--hd));
        width: 100%;
        margin-top: calc(5 * var(--hd));
        color: var(--danger);
      }
    }
  }
}

/*********************************************************************************************/
/**************************************  批量签署按钮样式  **************************************/
/*********************************************************************************************/
.@{mUploadClsPrefix} {
  &-signall {
    position: absolute;
    bottom: calc(6 * var(--hd));
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    &-inAll {
      bottom: 0;
    }
    &-inAll.@{mBtnClsPrefix} {
      position: relative;
      border: none;
      color: var(--primary);
      background: var(--bg-white);
      width: 100%;
    }
  }
}

/*********************************************************************************************/
/**************************************  特殊样式 - 评论  **************************************/
/*********************************************************************************************/
.@{mUploadClsPrefix}-dialog-comment {
  padding-bottom: calc(57 * var(--hd));
}
.@{mUploadClsPrefix},
.@{mUploadClsPrefix}-dialog {
  .@{mUploadClsPrefix}-listT {
    border: 0;
    .@{mListClsPrefix}-body .@{mListClsPrefix}-item .@{mListClsPrefix}-content {
      border-bottom: 0;
    }
    // 屏蔽评论中附件列表底部的线
    &-comment {
      .@{mListClsPrefix}-body
        .@{mListClsPrefix}-item
        .@{mListClsPrefix}-content {
        &::after {
          content: none;
        }
      }
    }
  }
  .@{mUploadListTItemClsPrefix}-comment {
    padding-left: 0;
    padding: 0;
    margin-bottom: calc(4 * var(--hd));
    .@{mUploadListTItemClsPrefix}-icon .@{iconClsPrefix}-svg {
      width: calc(20 * var(--hd));
      height: calc(20 * var(--hd));
    }
    .@{mUploadListTItemClsPrefix}-fileinfo {
      display: flex;
      width: auto;
      align-items: center;
      max-width: calc(100% - calc(60 * var(--hd)));
      &::after {
        content: none;
      }
      & > div {
        padding: 0;
      }
    }
    .@{mUploadListTItemClsPrefix}-more {
      color: var(--placeholder-fc);
    }
    .@{mUploadListTItemClsPrefix}-fileinfo-filename {
      font-size: var(--font-size-12);
      padding: calc(5 * var(--hd)) 0;
      margin-right: calc(8 * var(--hd));
    }
    .@{mUploadListTItemClsPrefix}-fileinfo-seclevel {
      margin-right: calc(8 * var(--hd));
    }
    .@{mUploadListTItemClsPrefix}-fileinfo-time {
      display: none;
    }
  }
  .@{mUploadListTItemClsPrefix}-comment-image {
    padding: 0;
    .@{mUploadListTItemClsPrefix}-imglist {
      // width: calc(70 * var(--hd));
      // height: calc(70 * var(--hd));
      margin-top: calc(10 * var(--hd));
      margin-right: calc(10 * var(--hd));
      position: relative;
      float: left;
      &-delete {
        position: absolute;
        border: var(--hd) solid;
        top: calc(-6 * var(--hd));
        right: calc(-4 * var(--hd));
        color: var(--base-white);
        background-color: var(--m-upload-delete-btn-backgroud);
        border-radius: calc(10 * var(--hd));
      }
    }
  }
  .@{mUploadListBItemClsPrefix}-comment {
    border: 0;
    line-height: initial;
    .@{mUploadListBItemClsPrefix} {
      &-icon {
        .@{iconClsPrefix}-svg {
          width: calc(20 * var(--hd));
          height: calc(20 * var(--hd));
        }
      }
      &-cancel-icon {
        top: calc(-1 * var(--hd));
        color: var(--secondary-fc);
        transform: initial;
        position: relative;
        margin-left: calc(5 * var(--hd));
        right: 0;
      }
      &-fileinfo-wrapper {
        display: flex;
        padding-right: calc(30 * var(--hd));
        align-items: center;
      }
      &-fileinfo {
        display: flex;
        align-items: center;
        width: inherit;
        flex: auto;
        overflow-x: hidden;
        overflow-y: visible;
        &::after {
          content: none;
        }
        &-filename {
          padding: 0;
          font-size: var(--font-size-12);
          margin-right: calc(8 * var(--hd));
        }
        &-filesize,
        &-seclevel {
          line-height: initial;
          margin-right: calc(8 * var(--hd));
          // flex: auto;
        }
        &-percent {
          right: 0;
          float: initial;
          top: 0;
          margin-left: calc(5 * var(--hd));
          line-height: initial;
        }
        &-errorinfo {
          margin-bottom: 0;
          left: 0;
          bottom: 0;
          position: absolute;
          display: none;
        }
        &-slider {
          display: none;
        }
      }
    }
  }
}

/*******************************************************************************/
/*************************  特殊样式 - 图片与文件列表一行展示  **********************/
/*******************************************************************************/
.@{mUploadClsPrefix}.isListTShowImage {
  .@{mUploadClsPrefix}-listT-item-imglist {
    margin-bottom: calc(10 * var(--hd));
  }
}

/**************************************  表单只读样式  **************************************/
.@{mUploadClsPrefix}.read-only-line-height-standard {
  .@{mListClsPrefix}-body {
    line-height: var(--form-mobile-readonly-line-height);
    padding-top: var(--form-mobile-readonly-padding-y);
    padding-bottom: var(--form-mobile-readonly-padding-y);
    height: auto;
  }
}
