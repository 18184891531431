.@{mIconDropdownClsPrefix} {
  margin-left: var(--m-icon-dropdown-margin-h);
  display: inline-block;
  &-icon-container {
    display: inline-block;
  }
  .@{iconClsPrefix} {
    color: var(--secondary-fc);
  }
  &-content {
    padding-left: var(--v-spacing-md);
    padding-bottom: var(--v-spacing-md);
    padding-right: 16px;

    &-container {
      padding: 0 var(--h-spacing-lg);
      margin: var(--v-spacing-xs) 0;
      background-color: var(--bg-white);
      min-width: calc(135 * var(--hd));
      max-width: calc(240 * var(--hd));
      box-shadow: var(--box-shadow);
    }
  }
  &-list-item {
    color: var(--main-fc);
    width: 100%;

    .@{iconClsPrefix} {
      color: var(--secondary-fc);
    }

    &-content {
      //width: calc(100% - 60px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
      padding-top: 4px;

      &-icon {
        margin-right: calc(10 * var(--hd));
      }
    }

    &-checked {
      color: var(--link-fc);

      &-icon {
        margin-right: calc(10 * var(--hd));
      }

      .@{iconClsPrefix} {
        color: var(--link-fc);
      }
    }

    &-order {
      float: right;
      margin-right: 0
    }
  }
  &-trigger-animation{
    &-down-enter {
      //animation: fadeIn 0.6s;
    }
  }
  &-trigger-animation-leave {
	//animation: fadeOut 0.35s;
  }
  // 折行效果
  &-wrap {
    margin-left: unset;
    padding-left: var(--m-icon-dropdown-margin-h);
  }
  &-trigger-wrap {
    .@{mIconDropdownClsPrefix}-content-container {
      min-width: calc(150 * var(--hd));
      .@{mListClsPrefix}-content {
        min-height: calc(44 * var(--hd));
      }
      .@{mListClsPrefix}-column .@{mListClsPrefix}-body div:not(:last-child) .@{mListClsPrefix}-content {
        &:after {
          content: none;
        }
      }
    }
    .@{mIconDropdownClsPrefix}-list-item {
      display: inline-flex;
      align-items: center;
      min-width: calc(150 * var(--hd) - 2 * var(--h-spacing-lg));
      max-width: calc(240 * var(--hd) - 2 * var(--h-spacing-lg));
      &-content {
        text-overflow: unset;
        white-space: unset;
        word-wrap:break-word;
        white-space: normal;
        &-icon {
          margin-right: calc(12 * var(--hd));
        }
      }
      &-checked {
        &-icon {
          margin-right: calc(12 * var(--hd));
        }
      }
      &-order {
        margin-left: calc(12 * var(--hd));
        padding-top: unset;
      }
    }
  }
  &-trigger-wrap.@{mIconDropdownClsPrefix}-hasIcon {
    .@{mIconDropdownClsPrefix}-list-item {
      &-content {
        padding-left: calc(32 * var(--hd));

        &-icon {
          &~span.@{mIconDropdownClsPrefix}-list-item-content {
            padding-left: 0;
          }
        }
      }
    }
  }
}
.@{mIconDropdownClsPrefix}-left {
  margin-right: var(--m-icon-dropdown-margin-h);
  margin-left: 0;
}
@keyframes fadeIn {
  0% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
  }

  to {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* 镜像 */
.@{mIconDropdownClsPrefix}-content.needRTL {

  .@{mIconDropdownClsPrefix}-list-item-content-icon {
    margin-right: 0;
    margin-left: calc(10 * var(--hd));
  }
  .@{mIconDropdownClsPrefix}-list-item {
    text-align: left;
    .@{mIconDropdownClsPrefix}-list-item-content {
      text-align: right;
    }
  }
}