/* 选项卡card效果 */
.@{menuClsPrefix}-card {
  position: relative;
  display: flex;
  align-items: center;
  // height: var(--menu-item-height);
  border-bottom: var(--border-width) solid var(--border-color);
  height: var(--menu-item-height);
  .@{menuClsPrefix}-tab-top-container {
    bottom: calc(-1 * var(--hd)); // 底部边线样式
  }
  .@{menuClsPrefix}-tab-top-container {
    height: var(--menu-item-height);
  }
  .@{menuClsPrefix}-list {
    // border-left: var(--border-width) solid var(--border-color);
    &:first-child {
      border-top-left-radius: var(--border-radius-xs);
    }
    &:last-child {
      border-top-right-radius: var(--border-radius-xs);
    }
  }
  .@{menuClsPrefix}-list-item {
    padding: 0 var(--v-spacing-lg);
    border-top: var(--border-width) solid var(--border-color);
    border-right: var(--border-width) solid var(--border-color);
    border-bottom: var(--border-width-lg) solid transparent;
    box-sizing: border-box;
    &-disabled { // 禁用
      cursor: unset;
      color: var(--invalid);
      &:hover {
        color: var(--invalid);
      }
    }
    &-active {
      border-bottom-color: var(--primary);
    }
    &:first-child {
      border-left: var(--border-width) solid var(--border-color);
    }
  }
  .@{menuClsPrefix}-bar {
    height: 100%;
  }
  .@{menuClsPrefix}-nav-container {
    height: 100%;
  }
  .@{menuClsPrefix}-overflow-more {
    height: var(--menu-item-height);
    padding: 0 var(--v-spacing-lg);
    border-top: var(--border-width) solid var(--border-color);
    border-right: var(--border-width) solid var(--border-color);
    border-bottom: var(--border-width-lg) solid transparent;
    &-select {
      border-left: var(--border-width) solid var(--border-color);
    }
  }
}