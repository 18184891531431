.@{boardClsPrefix} {
  display: inline-flex;
  padding: 0 var(--h-spacing-md);

  &.ui-list {
    overflow-x: auto !important;
  }



  .ui-list-body {
    border: 0;
    height: 100%;
  }

  &-list:first-child {
    margin-left: 0;
  }

  &.not-draggable &-list-title {
    cursor: inherit !important;
  }

  &-list {
    margin: var(--h-spacing-md);
    display: flex;
    flex-direction: column;
    position: relative;

    &-title {
      border-top-left-radius: var(--border-radius-xs);
      border-top-right-radius: var(--border-radius-xs);
      background-color: var(--board-list-bg-color);
      cursor: pointer;
    }

    &-title>span {
      color: var(--main-fc);
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-md);
      display: block;
      padding: var(--h-spacing-md);
      user-select: none;
      white-space: normal;
    }

    &.is-custom &-cards {
      background-color: var(--bg-transparent);
    }

    &-cards {
      height: 100%;
      background-color: var(--board-list-bg-color);
      display: flex;
      flex-direction: column;
      padding: var(--h-spacing-md) var(--h-spacing-md);
      user-select: none;
      width: var(--board-list-width);
      border-bottom-left-radius: var(--border-radius-xs);
      border-bottom-right-radius: var(--border-radius-xs);
    }
  }

  &-list-content {
    height: 100%;
    overflow: hidden;
  }

  &-list-cards.not-draggable &-card {
    cursor: inherit !important;
  }



  &-card {
    cursor: pointer;
    border-radius: var(--border-radius-xs);
    background-color: var(--bg-white);
    padding: var(--h-spacing-md);
    min-height: calc(40 * var(--hd));
    margin-bottom: var(--v-spacing-md);
    user-select: none;
    display: flex;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &-detail {
      overflow: hidden;
      position: relative;
      z-index: 10;
    }

    &-title {
      clear: both;
      display: block;
      margin: 0 0 var(--v-spacing-md);
      overflow: hidden;
      word-wrap: break-word;
      color: var(--main-fc);
      white-space: normal;
    }

    &-description {
      clear: both;
      display: block;
      margin: 0 0 var(--v-spacing-sm);
      padding-top: var(--v-spacing-md);
      overflow: hidden;
      word-wrap: break-word;
      font-size: var(--font-size-sm);
      color: var(--regular-fc);
      white-space: normal;
    }
  }

  &-drag-ghost {
    border: 0 !important;
    position: relative;
  }

  &-drag-ghost:before {
    border-radius: var(--border-radius-xs);
    content: "";
    background-color: var(--invalid-bc);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 11;
  }

  &-drag-card {
    opacity: 1 !important;
  }

  &-drag-handle {
    cursor: pointer;
  }

  // 镜像处理
  .@{boardClsPrefix}-list-cards.@{boardClsPrefix}-rtl {

    .@{boardClsPrefix}-card-wrapper {
      transform: scaleX(1);
    }

    .@{boardClsPrefix}-card-detail {
      transform: scaleX(-1);
    }
  }

  &-rtl {
    .@{boardClsPrefix}-list-title {
      transform: scaleX(-1);
    }

    .@{listClsPrefix}-footer {
      .@{spinClsPrefix}-show-text-rtl .@{spinClsPrefix}-text {
        transform: scaleX(-1) !important;
        direction: ltr;
        left: unset;
        right: calc(16 * var(--hd));
      }
    }
  }
}