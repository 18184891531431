// 整体input框
.@{cascaderInputClsPrefix}-multiple {
  min-width: 200px;
  background-color: #fff;
  display: inline-flex;
  flex-direction: row;
  padding-left: var(--input-padding-x);
  padding-right: var(--input-padding-x);
}

// 外部输入框 -> 面板收起边框样式
.rc-cascader-allow-clear.@{cascaderInputClsPrefix}-multiple {
  border: calc(1 * 1px) solid #e5e5e5;
  border-radius: var(--border-radius-xs);
}

// 外部输入框 -> 面板打开边框样式
.rc-cascader-open.@{cascaderInputClsPrefix}-multiple {
  border: 1px solid #5d9cec;
  box-shadow: 0 0 calc(3 * 1px) #5d9cec;
  outline: 0;
}

// 外部输入框 -> placeholder占位样式
.@{cascaderInputClsPrefix} {
  .@{cascaderInputClsPrefix}-selection-placeholder {
    // padding-left: 8px;
    display: inline-flex;
    align-items: center;
    opacity: 1;
    color: var(--placeholder-fc);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: auto;
    margin-bottom: auto;
    font-size: var(--font-size-12);
  }
}

// --------------------只读--------------------
.@{cascaderClsPrefix}-multiple-readOnly {
  pointer-events: none;
  min-height: calc(30 * var(--hd));

  &-item {
    margin: calc(4 * var(--hd)) 0;
    color: var(--main-fc);
    font-size: var(--font-size-12);

    &-unique {
      height: calc(30 * var(--hd));
      line-height: calc(30 * var(--hd));
      margin: 0;
    }
  }
}


// suffix 按钮调整
.@{cascaderInputClsPrefix}-allow-clear .@{cascaderInputClsPrefix}-clear,
.@{cascaderInputClsPrefix}-show-arrow .@{cascaderInputClsPrefix}-arrow {
  position: static;
  display: inline-flex;
  flex: none;
  align-items: center;
  height: 28px;
  z-index: 1;
  color: var(--secondary-fc);
  cursor: pointer;
}