
.@{mListClsPrefix}-item {
  position: relative;
  &.@{mTextareaClsPrefix}-item {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    min-height: var(--m-input-height);
    // padding-left: var(--h-spacing-lg);
    // padding-right: var(--h-spacing-lg);
    // border-bottom: var(--border-solid);
    &::after {
      width: calc(100% - var(--h-spacing-lg));
      right: 0;
      left: inherit;
    }

    &.@{mTextareaClsPrefix}-item-single-line {
      align-items: center;

      .@{mTextareaClsPrefix}-label {
        align-self: center;
      }

      .@{mTextareaClsPrefix}-control {
        padding-top: 0;
        padding-bottom: 0;

        textarea {
          line-height: calc(var(--line-height-paragraph) * var(--font-size-heading));
        }
      }

      .@{mTextareaClsPrefix}-clear {
        margin-top: 0;
      }

      &.@{mTextareaClsPrefix}-error {
        .@{mTextareaClsPrefix}-error-extra {
          margin-top: 0;
        }
      }
    }
  }
}

.@{mTextareaClsPrefix}-label {
  align-self: flex-start;
  color: var(--main-fc);
  text-align: left;
  min-height: var(--m-input-height);
  // font-size: @font-size-heading;
  font-size: var(--font-size-base);
  line-height: var(--m-input-height);
  margin-left: 0;
  margin-right: var(--h-spacing-sm);
  white-space: nowrap;
  overflow: hidden;

  &.@{mTextareaClsPrefix}-label-2 {
    width: calc(2 * var(--m-input-label-width));
  }

  &.@{mTextareaClsPrefix}-label-3 {
    width: calc(3 * var(--m-input-label-width));
  }

  &.@{mTextareaClsPrefix}-label-4 {
    width: calc(4 * var(--m-input-label-width));
  }

  &.@{mTextareaClsPrefix}-label-5 {
    width: calc(5 * var(--m-input-label-width));
  }

  &.@{mTextareaClsPrefix}-label-6 {
    width: calc(6 * var(--m-input-label-width));
  }

  &.@{mTextareaClsPrefix}-label-7 {
    width: calc(7 * var(--m-input-label-width));
  }
}

.@{mTextareaClsPrefix}-control {
  flex: 1;
  padding-top: calc(10 * var(--hd));
  padding-bottom: calc(9 * var(--hd));

  .@{mTextareaClsPrefix}-readonly {
    /* 让html浏览器显示空白空格符 */
    color: var(--main-fc);
    font-size: var(--font-size-base);
    white-space: pre-wrap;
    word-break: break-word;

    .@{mTextareaClsPrefix}-readonly-link {
      color: #5d9cec;
      text-decoration: none;
      &:hover {
        color: #5d9cec;
      }
      &.ui-rtl-inline {
        transform: scaleX(1);
        direction: ltr;
      }
    }
  }

  textarea {
    outline: none;
    color: var(--main-fc);
    // font-size: @font-size-heading;
    font-size: var(--font-size-base);
    line-height: calc(var(--line-height-paragraph) * var(--font-size-heading));
    appearance: none;
    width: 100%;
    padding: 0;
    border: 0;
    background-color: var(--bg-transparent);
    overflow: visible;
    -webkit-overflow-scrolling: touch;
    display: block;
    resize: none;
    word-break: break-word;
    word-wrap: break-word;

    &::placeholder {
      color: var(--color-text-placeholder);
      opacity: 1;
    }

    &:disabled {
      color: var(--color-text-disabled);
      background-color: rgba(255,255,255,0);
      -webkit-opacity:1;
    }
  }
}

.@{mTextareaClsPrefix}-clear {
  display: none;
  color: var(--secondary-fc);

  &-active {
    background-color: var(--input-color-icon-tap);
  }
}

&.@{mTextareaClsPrefix}-focus {
  .@{mTextareaClsPrefix}-clear {
    display: block;
  }
}

.@{mTextareaClsPrefix}-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: var(--v-spacing-xs);
  padding-right: var(--h-spacing-sm);
}
.@{mTextareaClsPrefix}-voicebtn {
  display: inline-flex;
  align-items: center;
  padding: calc(4 * var(--hd)) calc(10 * var(--hd));
  border: var(--form-line-border);
  border-radius: calc(12 * var(--hd));
  font-size: var(--font-size-12);
  color: var(--secondary-fc);
  .@{iconClsPrefix} {
    color: var(--primary);
    margin-right: calc(2 * var(--hd));
  }
  &-margin-left {
    margin-left: calc(12 * var(--hd));
  }
  &-margin-right {
    margin-right: calc(15 * var(--hd));
  }
}
.@{mTextareaClsPrefix}-count {
  color: var(--color-text-placeholder);
  font-size: var(--font-size-base);
  span {
    color: var(--main-fc);
  }
}

&.@{mTextareaClsPrefix}-error {
  .@{mTextareaClsPrefix}-control {
    display: flex;
    flex-direction: column;
    textarea {
      color: var(--danger);
      flex: 1;
    }
  }

  .@{mTextareaClsPrefix}-error-extra {
    margin-top: calc(12 * var(--hd));
    width: var(--icon-size-sm);
    height: var(--icon-size-sm);
    margin-left: var(--h-spacing-md);
    background-size: var(--icon-size-sm) var(--icon-size-sm);
    // .encoded-svg-background('error');
  }
}

&.@{mTextareaClsPrefix}-disabled {
  .@{mTextareaClsPrefix}-label {
    color: var(--color-text-disabled);
  }
}

.@{mTextareaClsPrefix}-item.is-high-light {
  & + div.required-fc {
    padding: var(--input-padding-y) 0;
  }
}


// 处理多行文本的
.@{mListClsPrefix}-item {
  &.@{mTextareaClsPrefix}-item.is-form-readonly {
    min-height: auto;
    .ui-m-textarea-control {
      padding-top: 0;
      padding-bottom: 0;
    }
    .ui-m-textarea-readonly {
      display: inline-block;
      padding: var(--form-mobile-readonly-padding-y) var(--form-mobile-readonly-padding-x);
      line-height: var(--form-mobile-readonly-line-height);
    }
  }
}