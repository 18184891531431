@import './common.less';

.@{mMapClsPrefix} {
  position: relative;
  font-size: var(--font-size-14);
  color: var(--main-fc);

  // 地图层级
  >div:first-child {
    z-index: var(--map-zindex);
  }

  //联想搜索面板
  &-search-input-wrap {
    .@{mMapClsPrefix}-search-input-trigger {
      .@{mMapClsPrefix}-search-input-list {
        margin-top: 0;
        border-top: var(--hd) solid #ebebeb;
        .@{mMapClsPrefix}-search-input-item{
          height: calc(35 * var(--hd));
        }
      }
    }
  }

  &-innermap {
    &-rtl {
      transform: scaleX(-1) !important;
    }
  }

  //SearchInput样式
  &-overlay-search {
    z-index: var(--map-search-zindex); // 需要比地图层级高一些

    .@{mMapClsPrefix}-search-input-innerwrap {
      background: var(--bg-white);
      padding: calc(10 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .@{mMapClsPrefix}-search-input-cancel {
      margin: 0 calc(15 * var(--hd));
    }

    .@{mMapClsPrefix}-search-input {
      flex: 1;
      box-sizing: border-box;
      background: #f7f7f7;
      color: var(--invalid);
      border-radius: calc(15 * var(--hd));
      padding: 0 calc(10 * var(--hd));
      height: calc(30 * var(--hd));

      .@{mListClsPrefix}-line {
        height: calc(30 * var(--hd));
        display: flex;
        align-items: center;
      }

      .@{mInputClsPrefix}-label {
        .@{iconClsPrefix} {
          color: var(--secondary-fc);
        }

        width: auto;
      }
    }

    // 去掉listitem的底部边线
    .@{mListClsPrefix}-item .@{mListClsPrefix}-line {
      border: 0;
    }
  }

  &-overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    &-switch {
      margin-top: calc(9 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(44 * var(--hd));
      height: calc(44 * var(--hd));
      z-index: var(--map-wrap-zindex);
      background: var(--bg-white);
      border-radius: 50%;
      cursor: pointer;
      align-self: flex-end;
      margin-right: calc(10 * var(--hd));
    }
  }

  &-dialog {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &-content {
      flex: 1 1;
    }

    &-footer-wrap {
      display: flex;
      flex-direction: column-reverse;
      -webkit-flex-direction: row-reverse;
      -moz-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;

      .ui-m-emoticon {
        border-top: var(--hd) solid var(--border-color);
      }

    }

    &-footer {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: calc(50 * var(--hd));
      line-height: calc(50 * var(--hd));
      border-top: var(--border-solid);
      box-sizing: border-box;

      &-item {
        flex: 1;
        position: relative;
        box-sizing: border-box;
        text-align: center;
        font-size: var(--font-size-17);
        color: var(--secondary-fc);

        &:after {
          //border边线效果
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          height: 50%;
          width: var(--border-width);
          background-color: var(--border-color);
        }

        &:last-child {
          color: var(--primary);

          &:after {
            width: 0;
          }
        }
      }
    }
  }

  &-wrap {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: var(--dialog-route-layout-zindex);
    background-color: var(--mask-bc);
  }

  // 地图切换面板
  &-panel {
    &-content {
      padding: calc(16 * var(--hd)) calc(24 * var(--hd));
    }
  }

  &-empty {
    padding-top: 45%
  }
}