.@{mRadioClsPrefix}-wrapper {
  display: flex;
  justify-content: flex-start;
  background-color: var(--m-radio-default-bc);
  vertical-align: middle;
  font-size: var(--radio-font-size);
  color: var(--radio-font-color);
  position: relative;

  &-hold-right {
    justify-content: space-between;
  }

  /**
  * 图片模式下，wrapper position 需要设置为 unset 让 input 的 absolute 覆盖范围达到整个图片单选组件
  */
  &-image {
    position: unset;
  }
}

.@{mRadioClsPrefix}-wrapper-readOnly-readOnlyLineHeightStandard {

  .@{mRadioClsPrefix}-readOnly,
  .@{mRadioClsPrefix}-label-readOnly {
    .form-mobile-readOnlyLineHeightStandard
  }
}

// 一组数据渲染 border-bottom
.@{mRadioClsPrefix}-group {
  .@{mRadioClsPrefix}-label {
    .border-solid-then;
  }

  .@{mRadioClsPrefix}-wrapper:last-child {
    .@{mRadioClsPrefix}-label:after {
      background-color: var(--bg-transparent);
    }
  }
}

.@{mRadioClsPrefix}-group-readOnly-image {
  padding-top: var(--form-mobile-readonly-padding-y);
  padding-bottom: var(--form-mobile-readonly-padding-y);
}

// 默认状态
.@{mRadioClsPrefix} {
  position: relative;
  // display: inline-block;
  line-height: 1;
  // white-space: nowrap;
  vertical-align: middle;
  outline: none;

  &-label,
  &-left,
  &-right {
    padding: calc(12 * var(--hd)) 0;
  }

  &-label {
    width: 100%;
    font-size: var(--font-size-14);
    color: var(--main-fc);
    font-weight: var(--font-weight-base);
    line-height: var(--m-radio-size-base);

    &-disabled {
      color: var(--m-radio-disabled-font-color);
      // color: var(--m-readonly-text);
    }

    &-span-rtl {
      display: inline-block;
    }
  }

  &-inner {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    width: var(--m-radio-size-base);
    height: var(--m-radio-size-base);
    direction: ltr;
    border-radius: 50%;
    border-collapse: separate;
    transition: all 0.3s;

    &-left {
      margin-right: calc(19 * var(--hd));
    }

    &-right {
      margin-left: calc(19 * var(--hd));
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 26%;
      display: table;
      width: var(--m-radio-check-width);
      height: var(--m-radio-check-height);
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(0) translate(-50%, -60%);
      opacity: 0;
      transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
      content: " ";
      pointer-events: none;
    }

    &-checked::after {
      position: absolute;
      display: table;
      border: calc(1 * 1px) solid #5d9cec;
      border: var(--border-width) solid var(--m-radio-check-bc);
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      content: " ";
    }
  }

  &-input {
    position: absolute;
    // visibility: hidden;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  &-left,
  &-right {
    &.hidden {
      display: none;
    }
  }

  &-tips {
    color: var(--secondary-fc);
  }
}

// 选中状态
.@{mRadioClsPrefix}-checked {
  .@{mRadioClsPrefix}-inner {
    &::after {
      position: absolute;
      display: table;
      border: var(--border-width) solid var(--m-radio-check-bc);
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      content: " ";
      pointer-events: none;
    }
  }
}

// 禁用状态
// .@{mRadioClsPrefix}-wrapper-disabled {
//   &:active {
//     background-color: var(--m-radio-default-bc);
//   }
// }
.@{mRadioClsPrefix}-disabled {
  &+span {
    // color: var(--m-radio-disabled-font-color);
  }

  .@{mRadioClsPrefix}-inner,
  .@{mRadioClsPrefix}-input {
    &::after {
      border-color: var(--m-radio-disabled-border-color);
    }
  }
}

// 只读状态
.@{mRadioClsPrefix}-readOnly {
  &+span {
    // color: var(--m-radio-disabled-font-color);
  }

  .@{mRadioClsPrefix}-inner,
  .@{mRadioClsPrefix}-input {
    &::after {
      // border-color: var(--m-radio-disabled-border-color);
      border-color: var(--m-radio-check-bc);
    }
  }
}

.@{mRadioClsPrefix}-label-readOnly {
  // color: var(--m-radio-disabled-font-color);
  color: var(--m-readonly-text);
}

// image checkbox
.@{mRadioClsPrefix}-image {
  &-box img {
    width: 100%;
  }

  &-vertical &-wrapper {
    display: block;
  }
}

// iamge radio mb
.@{mRadioClsPrefix}-image-wrapper {
  position: relative;
  width: calc(73 * var(--hd));
  height: calc(73 * var(--hd));
  background: var(--bg-white);
  // border: var(--hd) solid var(--border-color);
  // border-radius: calc(3 * var(--hd));
  display: inline-table;
  margin: calc(10 * var(--hd));

  .@{mRadioClsPrefix} {
    &-wrapper {
      padding: 0;
    }

    &-inner {
      // position: absolute;
      margin: calc(3 * var(--hd)) calc(3 * var(--hd)) 0 0;
      width: calc(16 * var(--hd));
      height: calc(16 * var(--hd));
      background: var(--bg-transparent);
      border: none;

      // border: var(--base-white) var(--hd) solid;
      &-checked {
        border-color: var(--m-chk-check-border-color);
        background-color: var(--m-chk-check-bc);
      }
    }

    &-inner::after {
      left: 16%;
      // opacity:1;
      position: absolute;
      display: table;
      border: var(--border-width) solid var(--base-white);
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -60%);
      // opacity: 1;
      content: " ";
      pointer-events: none;
    }

    &-label,
    &-left,
    &-right {
      padding: 0;
    }

    &-left {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      justify-content: flex-end;
    }

    &-label {
      margin-top: calc(6 * var(--hd));
      font-size: var(--font-size-12);
      line-height: 1.2;

      >span {
        text-overflow: ellipsis;
        height: 100%;
        display: inline-block;
        width: calc(73 * var(--hd));
        overflow: hidden;
      }
    }
  }
}
