/* 普通Tabs效果 */
.@{menuClsPrefix}-tab {
  color: var(--regular-fc);
  .@{menuClsPrefix}-list-item:hover {
    color: var(--primary);
  }

  &-add {
    height: 100%;
    display: flex;
    align-items: center;
    &-showLeftPadding {
      padding-left: var(--h-spacing-lg);
    }
  }

  // 拖拽
  .@{menuClsPrefix}-list-sortwrap {
    display: inline-flex;
    align-items: center;
    height: 100%;
  }
  .menu-item-sortable {
    display: inline-flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    &-handle {
      cursor: pointer;
    }
  }
}

.@{menuClsPrefix}-tab-top {
  position: relative;
  display: flex;
  align-items: flex-end; // 兼容模块设置了外层高度的情况
  width: 100%;
  padding: 0 var(--h-spacing-lg);
  height: var(--menu-item-height);
  border-bottom: var(--border-width) solid var(--border-color); // 底部边线

  .menu-item-sortable { // 拖拽图标
    float: left;
    padding-bottom: var(--border-width-lg);
  }

  &-container {
    position: relative;
    flex: auto;
    -ms-flex: auto;
    -webkit-flex: auto;
    overflow: hidden;
    .@{menuClsPrefix}-overflow-more {
      padding-left: var(--menu-tab-item-margin-right);
      &-hideLeftPadding {
        padding-left: 0;
      }
      // tab | secondTab | card 更多效果，容器宽度<=200特殊显示处理
      &-select {
        padding-left: 0;
        max-width: 100%;
        .@{menuClsPrefix}-overflow-more-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  &-container-ellipsis { // tab | secondTab | card 更多只能放下一个菜单特殊处理
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .@{menuClsPrefix}-list {
      flex: 1;
      overflow: hidden;
      .@{menuClsPrefix}-list-item,
      .@{menuClsPrefix}-list-item-content-wrap {
        max-width: 100%;
      }
    }
  }

  .@{menuClsPrefix}-tab-top-container {
    height: var(--menu-item-height);
    bottom: calc(-1 * var(--hd)); // 底部边线和选中效果重叠
  }

  .@{menuClsPrefix}-bar { // 处理底部边线问题
    width: 100%;
    height: 100%;
  }

  .@{menuClsPrefix}-list>div:last-child {
    padding-right: 0;
    margin-right: 0; // 兼容Eb或者业务 样式调整 margin padding 等
  }

  .@{menuClsPrefix}-list>.@{menuClsPrefix}-list-item-closable:last-child {
    padding-right: var(--menu-tab-item-margin-right);
  }

  // 高度继承top-container层的 (处理业务自定义问题)
  .@{menuClsPrefix}-bar,
  .@{menuClsPrefix}-nav-container,
  .@{menuClsPrefix}-nav-wrap,
  .@{menuClsPrefix}-nav-scroll,
  .@{menuClsPrefix}-nav,
  .@{menuClsPrefix}-list,
  .@{menuClsPrefix}-list-item,
  .@{menuClsPrefix}-list-subitem {
    height: 100%;
  }

  .@{menuClsPrefix}-list { // 加个高度之后需要设置float: left （防止宽度计算问题）
    float: left;
  }

  .@{menuClsPrefix}-list-subitem {
    padding-right: var(--menu-tab-item-margin-right);
    float: left;
    border: 0;
  }

  .@{menuClsPrefix}-list-item {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: var(--menu-tab-item-margin-right);
    text-align: center;
    border: 0;
    &:hover {
      color: var(--primary);
    }
    &-content-wrap {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      border-bottom: var(--border-width-lg) solid transparent;
    }
    &-disabled {
      // 禁用
      &:hover {
        color: var(--invalid);
      }
    }
    &-active {
      .@{menuClsPrefix}-list-item-content-wrap {
        border-color: var(--primary);
      }
    }
    &-closeicon {
      position: absolute;
      top: 0;
      right: calc(10 * var(--hd));
      width: var(--icon-size-xs);
      height: 100%;
      .@{iconClsPrefix} {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: calc(-8 * var(--hd));
      }
    }
    &-editicon {
      margin-bottom: var(--border-width-lg);
    }
  }

  .@{menuClsPrefix}-list-subitem-title {
    border: 0;
    &:hover {
      color: var(--primary);
    }
  }

  // 单独处理tab-top 头部有左右16px padding后的箭头图标等样式
  .@{menuClsPrefix}-nav-container-scrolling-hasDropDownIcon {
    padding-left: calc( 28 * var(--hd));
    padding-right: calc( 54 * var(--hd));
  }
  .@{menuClsPrefix}-tab-next-hasDropDownIcon {
    right: calc(28 * var(--hd));
  }
  .@{menuClsPrefix}-tab-dropdown-icon {
    width: calc(20 * var(--hd));
  }
  .@{menuClsPrefix}-tab-prev, .@{menuClsPrefix}-tab-next {
    width: calc(18 * var(--hd));
    .@{iconClsPrefix} {
      width: calc(18 * var(--hd));
    }
  }
}

.@{menuClsPrefix}-tab-left,
.@{menuClsPrefix}-tab-right {
  position: relative;
  width: var(--menu-tab-left-width);
  border: 0;

  .@{menuClsPrefix}-tab-top-container {
    height: 100%;
  }

  // nav
  .@{menuClsPrefix}-bar {
    height: 100%;
  }

  .@{menuClsPrefix}-nav-container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .@{menuClsPrefix}-nav-container-scrolling {
    padding-top: var(--menu-arrow);
    padding-bottom: var(--menu-arrow);
  }

  .@{menuClsPrefix}-nav-wrap {
    height: 100%;

    // list listitem
    .@{menuClsPrefix}-list {
      display: inline-block;
      width: 100%;
    }

    .@{menuClsPrefix}-list-item {
      width: 100%;
    }
  }

  .@{menuClsPrefix}-nav-scroll {
    height: var(--menu-scroll-wdith);
  }

  .@{menuClsPrefix}-tab-prev,
  .@{menuClsPrefix}-tab-next {
    width: 100%;
    height: var(--menu-arrow);
    line-height: var(--menu-arrow);
    text-align: center;
  }

  .@{menuClsPrefix}-tab-prev {
    top: var(--menu-arrow-top);
  }

  .@{menuClsPrefix}-tab-next {
    bottom: 0;
  }
}

.@{menuClsPrefix}-tab-left  {
  border-right: var(--border-solid);

  .@{menuClsPrefix}-tab-top-container { // 边线处理
    right: calc(-1 * var(--hd));
  }
  .@{menuClsPrefix}-list-item {
    position: relative;
    border-right: var(--border-width-lg) solid transparent;

    &-active {
      border-right-color: var(--primary);
    }
  }
}

.@{menuClsPrefix}-tab-right {
  border-left: var(--border-solid);

  .@{menuClsPrefix}-tab-top-container { // 边线处理
    left: calc(-1 * var(--hd));
  }

  .@{menuClsPrefix}-list-item {
    position: relative;
    padding-left: calc(20 * var(--hd));
    border-left: var(--border-width-lg) solid transparent;

    &-active {
      border-left-color: var(--primary);
    }
  }
}

// 多级菜单弹层、更多弹层: 去掉高度限制, 内容折行显示
.@{menuClsPrefix}-trigger-tab,
.@{menuClsPrefix}-tab-dropdown {
  .@{menuClsPrefix}-list-item,
  .@{menuClsPrefix}-list-subitem-title {
    height: auto;
    padding: var(--h-spacing-md);
  }
  .@{menuClsPrefix}-list-item-content {
    overflow: unset;
    white-space: normal;
  }
}

// 超长下拉菜单
.@{menuClsPrefix}-tab-dropdown {
  max-height: calc(318 * var(--hd));

  .@{menuClsPrefix}-list-item {
    // 删除按钮样式
    &-closeicon,
    &-editicon {
      display: flex;
      visibility: hidden;
      align-items: center;
      justify-content: center;
      padding-left: var(--h-spacing-xs);
      .@{iconClsPrefix} {
        color: var(--secondary-fc);
        svg {
          cursor: pointer;
          width: var(--menu-close-width);
          height: var(--menu-close-width);
        }
        &:hover {
          color: var(--primary)
        }
      }
    }
    &:hover {
      .@{menuClsPrefix}-list-item-closeicon,
      .@{menuClsPrefix}-list-item-editicon {
        visibility: visible;
      }
    }
  }

  &-icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: calc(30 * var(--hd));
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--secondary-fc);

    &:hover {
      color: var(--primary);
    }
  }
}

.@{menuClsPrefix}-mode-horizontal {
  .@{triggerClsPrefix} {
    width: auto !important;
    float: left;
  }

  .@{menuClsPrefix}-list-item-expandicon {
    margin-right: 0;
    margin-left: var(--h-spacing-md);
  }

  .@{menuClsPrefix}-list-subitem {
    &-title {
      box-sizing: border-box;
    }
  }
}

// secondtab
.@{menuClsPrefix}-secondtab {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  // min-height: calc(30 * var(--hd));
  padding: calc(4 * var(--hd)) 0;

  .@{menuClsPrefix}-overflow-more {
    // height: var(--menu-secondtab-item-height);
    text-align: center;
    padding-left: var(--h-spacing-lg);
    border-left: var(--border-solid);
    &-select {
      border-left: 0;
      padding-left: 0;
    }
  }

  .@{menuClsPrefix}-list-item {
    height: auto;
    // height: var(--menu-secondtab-item-height);
    border-right: var(--border-solid);
    text-align: center;
    padding: 0 var(--h-spacing-lg);
    &-content {
      display: inline-block;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
    &:hover {
      color: var(--primary);
    }
    &-disabled {
      // 禁用
      &:hover {
        color: var(--invalid);
      }
    }
  }
}

// secondtab - new
.@{menuClsPrefix}-secondtab-new {
  align-items: flex-start;
  height: calc(32 * var(--hd));
  border-bottom: var(--border-solid);
  .@{menuClsPrefix}-list-item, .@{menuClsPrefix}-overflow-more {
    // height: var(--menu-secondtab-new-item-height);
    // line-height: var(--menu-secondtab-new-item-height);
    // padding: 0 calc(12 * var(--hd));
    padding: calc(4 * var(--hd)) calc(12 * var(--hd));
    border: 0;
    border-radius: var(--border-radius-xs);
    position: relative;
    &:hover {
      color: var(--primary);
      &::after {
        content: '';
        position: absolute;
        border-radius: var(--border-radius-xs);
        background-color: var(--primary);
        opacity: 0.14;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
      }
    }
  }
  .@{menuClsPrefix}-list-item {
    margin-right: calc(6 * var(--hd));
  }
  .@{menuClsPrefix}-list-item-active {
    color: var(--primary);
    &::after {
      content: '';
      position: absolute;
      border-radius: var(--border-radius-xs);
      background-color: var(--primary);
      opacity: 0.14;
      top: 0;
      right: 0;
      left: 0;
      height: 100%;
    }
  }
  .@{menuClsPrefix}-list-item:first-child {
    padding-left: calc(12 * var(--hd));
  }
  .@{menuClsPrefix}-tab-top-container-ellipsis .@{menuClsPrefix}-list {
    padding-right: calc(6 * var(--hd));
  }
}

// 通用
.@{menuClsPrefix}-overflow-more {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  float: left;

  &-text {
    display: inline-block;
    width: max-content;
    width: -moz-max-content;
    width: -webkit-max-content;
    width: -ms-max-content;
    line-height: normal;
  }
  &-icon {
    display: inline-block;
    width: var(--icon-size-xs);
  }
  &-hover {
    color: var(--primary);
  }
}

.@{menuClsPrefix}-hasmore {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  // fix: icon加载慢导致宽度计算问题
  .@{menuClsPrefix}-list-item-lefticon,
  .@{menuClsPrefix}-list-item-activeicon {
    min-width: var(--font-size-16);
  }
  .@{menuClsPrefix}-list-subitem-title .@{menuClsPrefix}-list-item-righticon {
    width: var(--icon-size-xs);
  }
}

// 影藏底部灰色边线
.@{menuClsPrefix}-hideBottomLine {
  border: 0;
  .menu-item-sortable {
    padding-bottom: 0;
  }
  .@{menuClsPrefix}-list-item-editicon {
    margin-bottom: 0;
  }
}

// 影藏选中蓝色边线
.@{menuClsPrefix}-hideActiveLine {
  .@{menuClsPrefix}-list-item-active{
    .@{menuClsPrefix}-list-item-content-wrap {
      border: 0;
    }
  }
}

// 左右滚动切换-可编辑删除效果
.@{menuClsPrefix}-tab {
  .@{menuClsPrefix}-nav-container {
    .@{menuClsPrefix}-list > .@{menuClsPrefix}-list-item-closable:last-child {
      padding-right: 0;
    }
    .@{menuClsPrefix}-list-item,
    .@{menuClsPrefix}-list-subitem {
      &:hover {
        .@{menuClsPrefix}-list-item-editicon {
          display: inline-flex;
        }
      }
    }
  }
  .@{menuClsPrefix}-bar-closable {
    .@{menuClsPrefix}-nav-container {
      .@{menuClsPrefix}-list > .@{menuClsPrefix}-list-item-closable:last-child {
        padding-right: var(--menu-tab-item-margin-right);
      }
    }
  }

  // 纵向
  &-right {
    .@{menuClsPrefix}-list-sortwrap {
      position: relative;
      width: 100%;
      padding-left: var(--h-spacing-xs);
      padding-top: var(--h-spacing-md);
      padding-bottom: var(--h-spacing-md);
      &::after {
        position: absolute;
        content: '';
        top: 50%;
        left: calc(-1 * var(--hd));
        width: calc(2 * var(--hd));
        height: calc(22 * var(--hd));
        margin-top: calc(-11 * var(--hd));
        background-color: transparent;
      }
      &-active::after {
        background-color: var(--primary);
      }
      .@{menuClsPrefix}-list-item {
        display: flex;
        width: calc(100% - calc(22 * var(--hd)));
        border-left: 0;
        padding-left: 0;
        &-closeicon,
        &-editicon {
          padding: 0 var(--h-spacing-xs);
        }
      }
      .menu-item-sortable-handle {
        padding: 0 var(--v-spacing-xs);
      }
      .@{menuClsPrefix}-list-item-content {
        padding-right: var(--h-spacing-md);
      }
      .@{menuClsPrefix}-list-item, .@{menuClsPrefix}-list-subitem-title {
        height: auto;
      }
    }
    .menu-button-add {
      display: inline-block;
      color: var(--primary);
      margin-left: calc(6 * var(--hd));
      cursor: pointer;
    }
  }
}
