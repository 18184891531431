.@{mMultiMenuClsPrefix} {
  .@{mMenuClsPrefix}-top-left {
		display: block;
		width: 100%;
		padding-right: calc(12 * var(--hd));
		&-content {
			display: inline-flex;
			width: auto;
			max-width: calc(50% - calc(20 * var(--hd)));
			margin-right: calc(20 * var(--hd));
		}
		.@{mMenuClsPrefix}-top-text {
			max-width: unset;
		}
		.@{mMenuClsPrefix}-top-left-content-active {
			color: var(--primary);
			.@{mMenuClsPrefix}-top-icon, .Icon-Down-arrow01,
			.@{mMenuClsPrefix}-top-icon, .Icon-Down-arrow01 {
				color: var(--main-fc);
			}
		}
	}
	.@{mMenuClsPrefix}-wrap-active .@{mMenuClsPrefix}-top-left {
		color: var(--main-fc);
	}
	.@{mMenuClsPrefix}-wrap-active .@{mMenuClsPrefix}-top-left .@{mMenuClsPrefix}-top-icon .Icon-Down-arrow01,
	.@{mMenuClsPrefix}-wrap-active .@{mMenuClsPrefix}-top-left .@{mMenuClsPrefix}-top-icon .Icon-Down-arrow01 {
		color: var(--secondary-fc);
	}
}