/******************************** checkname dialog  ********************************/
.@{mUploadClsPrefix}-secret-select {
  &-dialog-wrap {
    .@{mDialogClsPrefix}-top {
      padding-left: calc(16 * var(--hd));
      padding-right: calc(16 * var(--hd));
    }
    .@{mDialogClsPrefix}-body,
    .@{mDialogClsPrefix}-footer {
      background-color: var(--bg-white);
    }
    .panel {
      .info {
        margin-top: calc(10 * var(--hd));
      }
      .tips {
        font-size: var(---font-size-14);
        color: var(--secondary-fc);
        text-align: left;
        line-height: calc(20 * var(--hd));
      }
      .content {
        .tips-row {
          position: relative;
          height: calc(40 * var(--hd));
          .tips-line {
            background: linear-gradient(
              to right,
              rgba(0, 0, 0, 0) 0%,
              #d8d8d8 50%,
              rgba(0, 0, 0, 0) 100%
            );
            position: absolute;
            top: 50%;
            width: 100%;
            height: 1px;
          }
          .tips {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            padding: 0 calc(10 * var(--hd));
            text-align: center;
            white-space: nowrap;
            background: #fff;
          }
        }
      }
    }
    // 密级组件
    .@{mSecretSelectClsPrefix} {
      margin-top: calc(20 * var(--hd));
      &-label {
        display: inline-flex;
        &-span {
          line-height: calc(20 * var(--hd));
          white-space: nowrap;
        }
      }
      &-secretvalidity {
        &-formSwitch {
          text-align: left;
        }
        &-input-help-tip {
          text-align: left;
        }
      }
    }
  }
  &-localSecurity-dialog-wrap {
    .ui-m-dialog-top-title {
      text-align: left;
    }
    .@{mSecretSelectClsPrefix} {
      margin-top: 0;
    }
  }
}
