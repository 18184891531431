.@{mRecordingClsPrefix} {
  font-size: var(--font-size-12);

  &-audio {

    &-btn {
      display: flex;
      align-items: center;
      font-size: var(--font-size-12);
      padding: calc(10 * var(--hd));
      border-bottom: var(--hd) solid #eee;

      &-disable {
        color: var(--regular-fc);
      }

      &-title {
        color: #2562ff;
        margin-left: calc(8 * var(--hd));
      }

      &-icon {
        background: #2562ff;
        display: inline-flex;
        height: calc(24 * var(--hd));
        width: calc(24 * var(--hd));
        align-items: center;
        justify-content: center;
        color: var(--base-white);
        border-radius: 50px;
      }
    }

    &-upload {
      display: none;
    }

    &-list {
      padding: calc(9 * var(--hd));

      &>* {
        &:not(:last-child) {
          margin-bottom: calc(11 * var(--hd));
        }
      }
    }

    &-item {
      display: flex;
      align-items: center;
      background: #ffffff;
      border: var(--hd) solid #3c6cf6;
      border-radius: 6px;
      padding: 0 calc(3 * var(--hd));
      user-select: none;
      -webkit-touch-callout: none;

      &-delete {
        background: #F7F7F7;
        border: var(--hd) solid var(--border-color);
      }

      &-close {
        flex: none;
        color: #919191;
        width: calc(22 * var(--hd));
        height: calc(30 * var(--hd));
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }

    & &-player {
      background: #ffffff;
      color: #919191;

      .vjs-volume-panel {
        // display: none;
      }

      .vjs-play-progress {
        background: #73859f;
      }

      .vjs-load-progress {

        div {
          background: #e3e0e0;
        }
      }

      .vjs-control-bar {
        background: #ffffff;
      }

      .vjs-volume-level {
        background: #73859f;
      }

      .vjs-slider {
        background: #e3e0e0;
      }

      .vjs-menu-item {
        color: #fff;

        &.vjs-selected {
          color: #919191;
        }
      }

      .vjs-play-progress:before {
        font-size: 1em;
      }

      .vjs-volume-level:before {
        font-size: 1em;
        top: -0.4em;
      }
    }

    &-delete {
      flex: 1;
      display: flex;
      align-items: center;
      height: calc(30 * var(--hd));
      color: #999999;

      .@{mRecordingClsPrefix}-audio-delete {

        &-icon,
        &-text {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }

        &-icon {
          width: calc(40 * var(--hd));
        }

        &-text {
          font-size: var(--font-size-12);
        }
      }
    }
  }

  &-video {
    display: flex;
    flex-wrap: wrap;

    .@{mRecordingClsPrefix}-video-item,
    .@{recordingClsPrefix}-video-loading,
    .@{recordingClsPrefix}-upload {
      margin: calc(8 * var(--hd));
    }

    // &>* {
    //   flex: none;
    //   margin-bottom: calc(16 * var(--hd));
    // }

    // &:not(&-reverse)>* {
    //   margin-right: calc(16 * var(--hd));
    // }

    // &-reverse>* {
    //   margin-left: calc(16 * var(--hd));
    // }

    &-upload {
      height: calc(95 * var(--hd));
      width: calc(95 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 3px;
      border: var(--hd) dashed var(--border-color);
      position: relative;

      &-title {
        color: var(--secondary-fc);
        margin-top: calc(6 * var(--hd));
      }

      .@{iconClsPrefix} {
        svg {
          width: calc(var(--hd) * 32);
          height: calc(var(--hd) * 32);
          color: var(--invalid);
        }
      }

      &-wrapper {
        width: initial;
        flex: none;
      }

    }

    &-upload-title-rtl {
      transform: scaleX(1);
    }

    &-upload-title-rtl {
      transform: scaleX(1);
    }

    & &-player {

      .vjs-tech {
        height: calc(95 * var(--hd));
        width: calc(95 * var(--hd));
      }

    }

    &-item {
      position: relative;
      background: #000;
      border-radius: 3px;

      &-delete {
        background: unset;
      }

      &-close {
        // background: #7E7E7E;
        // color: #fff;
        // border: var(--hd) solid #fff;
        // border-radius: calc(10 * var(--hd));
        // width: calc(18 * var(--hd));
        // height: calc(18 * var(--hd));
        // right: calc(-5 * var(--hd));
        // top: calc(-5 * var(--hd));
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        right: calc(-8 * var(--hd));
        top: calc(-8 * var(--hd));
        color: #999999;
        border-radius: 50%;
        background: var(--base-white);
      }
    }

    &-show {
      height: calc(95 * var(--hd));
      width: calc(95 * var(--hd));
    }

    &-hidden {
      display: none;
    }

    &-delete {
      border: 1px dashed var(--border-color);
      height: calc(95 * var(--hd));
      width: calc(95 * var(--hd));
      display: flex;
      align-items: center;
      justify-content: center;

      &-icon {
        svg {
          width: calc(var(--hd) * 32);
          height: calc(var(--hd) * 32);
          color: var(--invalid);
        }
      }
    }
  }
}

//只读与空状态
.@{mRecordingClsPrefix} {

  &-audio,
  &-video {
    &-readOnly {
      .@{recordingClsPrefix}-empty {
        width: 100%;
        display: flex;
        align-items: center;
        margin: unset;
      }
    }

    &-readOnly-readOnlyLineHeightStandard {
      min-height: calc(30 * var(--hd));
      .form-mobile-readOnlyLineHeightStandard;
    }
  }
}