.@{mListClsPrefix}-item {
  &.@{mInputClsPrefix}-item {
    height: var(--m-input-height);
    // padding-left: var(--h-spacing-lg);
    // padding-right: var(--h-spacing-lg);
    &::after {
      width: calc(100% - var(--h-spacing-lg));
      right: 0;
      left: inherit;
    }
  }

  .@{mListClsPrefix}-line {
    display: flex;
    // border-bottom: var(--border-solid);
  }

  .@{mInputClsPrefix}-label {
    color: var(--main-fc);
    font-size: var(--font-size-base);
    // font-size: @font-size-heading;
    height: var(--m-input-height);
    line-height: var(--line-height-base);
    box-sizing: border-box;
    margin-left: 0;
    margin-right: var(--h-spacing-sm);
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: calc(2 * var(--hd)) 0;

    &.@{mInputClsPrefix}-label-2 {
      width: calc(2 * var(--m-input-label-width));
    }

    &.@{mInputClsPrefix}-label-3 {
      width: calc(3 * var(--m-input-label-width));
    }

    &.@{mInputClsPrefix}-label-4 {
      width: calc(4 * var(--m-input-label-width));
    }

    &.@{mInputClsPrefix}-label-5 {
      width: calc(5 * var(--m-input-label-width));
    }

    &.@{mInputClsPrefix}-label-6 {
      width: calc(6 * var(--m-input-label-width));
    }

    &.@{mInputClsPrefix}-label-7 {
      width: calc(7 * var(--m-input-label-width));
    }
  }

  .@{mInputClsPrefix}-control {
    font-size: var(--input-font-size);
    flex: 1;
    padding: calc(2 * var(--hd)) 0;

    input, span {
      outline: none;
      color: var(--main-fc);
      font-size: var(--font-size-base);
      appearance: none;
      width: 100%;
      padding: calc(2 * var(--hd)) 0;
      border: 0;
      background-color: var(--bg-transparent);
      height: var(--line-height-base);
      line-height:normal;
      box-sizing: border-box;
      -webkit-overflow-scrolling: touch;

      &::placeholder {
        color: var(--color-text-placeholder);
        font-size: var(--font-size-base);
        line-height: 1.2;
        opacity: 1;
      }

      &:disabled {
        color: var(--invalid-fc);
        background-color: rgba(255,255,255,0);
        -webkit-opacity:1;
      }
    }

    &.is-readonly {
      overflow: auto;
      span {
        display: inline-block;
        white-space: nowrap;
        line-height: calc(36 * var(--hd));
      }
    }
  }

  .@{mInputClsPrefix}-clear {
    display: none;
    height: var(--m-input-height);
    line-height: var(--m-input-height);
    border-radius: 50%;
    padding-left: var(--h-spacing-sm);
    overflow: hidden;
    font-style: normal;
    color: var(--secondary-fc);
    &-active {
      background-color: var(--input-color-icon-tap);
    }
  }

  &.@{mInputClsPrefix}-focus {
    .@{mInputClsPrefix}-clear {
      display: flex;
      align-items: center;
    }
  }

  .@{mInputClsPrefix}-extra {
    display: flex;
    align-items: center;
    flex: initial;
    min-width: 0;
    height: var(--m-input-height);
    overflow: hidden;
    padding-right: 0;
    line-height: var(--line-height-base);
    color: var(--color-text-caption);
    font-size: var(--font-size-subhead);
    margin-left: var(--h-spacing-sm);
  }

  &.@{mInputClsPrefix}-error {
    .@{mInputClsPrefix}-control {
      input {
        color: #f50;
      }
    }

    .@{mInputClsPrefix}-error-extra {
      height: var(--icon-size-sm);
      width: var(--icon-size-sm);
      margin-left: var(--v-spacing-sm);
      // .encoded-svg-background('error');

      background-size: var(--icon-size-sm) auto;
    }
  }

  &.@{mInputClsPrefix}-disabled {
    .@{mInputClsPrefix}-label {
      color: var(--color-text-disabled);
    }
  }
}

.@{mInputClsPrefix}-number {
  display: inline;
  &.is-readonly {
    display: inline-block;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input {
    text-align: center;
    // width: 100%;
  }
  &.hide-ops input {
    text-align: left;
    &.ui-rtl {
      text-align: right;
    }
  }
  &-operate-btn {
    // display: inline-flex;
    // align-items: center;
    // justify-content: center;
    width: calc(20 * var(--hd));
    height: calc(20 * var(--hd));
    // line-height: calc(20 * var(--hd));
    padding: 0;
    .@{iconClsPrefix} {
      margin-top: -1px;
    }
    &.@{btnClsPrefix}:disabled,
    &.@{btnClsPrefix}.is-disabled {
      color: var(--invalid);
      border-color: var(--border-color);
      background-color: var(--bg-base);
    }
  }
  &::after {
    display: none;
  }
  .@{mListClsPrefix}-line {
    display: flex;
    // border-bottom: none;
  }
  .@{mInputClsPrefix}-label.@{mInputClsPrefix}-label-5 {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
  .@{mInputClsPrefix}-label,
  .@{mInputClsPrefix}-extra {
    padding-left: var(--h-spacing-xs);
    padding-right: var(--h-spacing-xs);
  }
}

// 语音转文字按钮
.@{mListClsPrefix}-voicebtn {
  display: flex;
  align-items: center;
  color: var(--primary);
  margin-left: var(--h-spacing-md);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}


.@{mInputClsPrefix} {
  &-control {
    &.is-auto-scroll {
      input {
        position: relative;
        z-index: 200;
      }
    }
    &.is-scroll {
      position: relative;

      .ui-m-input-auto-scroll {
        display: block;
        opacity: 1;

        @keyframes blink {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }

        &::after {
          content: "";
          position: absolute;
          bottom: calc(10 * var(--hd));
          left: 0;
          width: calc(1 * var(--hd));
          height: calc(var(--font-size-base) + 2 * var(--hd));
          background-color: #0095ff;
          animation: blink 1s infinite;
        }
        @keyframes scroll {
          0% {
              transform: 0;
          }
        
          100% {
              transform: translateX(-100%);
          }
        }
        > span {
          display: inline-block;
          white-space: nowrap;
          outline: none;
          width: auto;
          color: var(--secondary-fc);
          font-size: var(--font-size-base);
          appearance: none;
          padding: calc(2 * var(--hd)) 0;
          border: 0;
          background-color: var(--bg-transparent);
          height: var(--line-height-base);
          line-height: calc(var(--line-height-base) - 4 * var(--hd));
          box-sizing: border-box;
          -webkit-overflow-scrolling: touch;
        }

        > span.need-scroll {
          animation: scroll 10s linear infinite 0.5s;
        }
      }
      input {
        opacity: 0;
      }
    }
  }
  &-auto-scroll {
    opacity: 0;
    overflow: hidden;   
    color: var(--secondary-fc);
    position: absolute;
    left: 0;
    top: 0;
  }

  // 不跟随主题色 直接使用主题蓝色
  &-readonly-link {
    color: #5d9cec;
    text-decoration: none;
    &:hover {
      color: #5d9cec;
    }
  }
}

.@{mInputClsPrefix}-readonly.is-high-light {
  .@{mInputClsPrefix}-control.is-readonly {
    .rich-text-highlight-active {
      display: inline;
      background-color: var(--highlight-bg);
      color: var(--highlight-color);
    }
  }
}
.@{mInputClsPrefix}-item.is-high-light {
  & + div.required-fc {
    padding: var(--input-padding-y) 0;
  }
}

.@{mInputClsPrefix}-item.is-form-readonly {
  height: auto;
  .ui-m-input-control.is-readonly {
    padding: 0;
  }
  .ui-m-input-control.is-readonly span {
    color: var(--main-fc);
    padding: var(--form-mobile-readonly-padding-y) var(--form-mobile-readonly-padding-x);
    line-height: var(--form-mobile-readonly-line-height);
    word-break: break-word;
  }
}

.@{mInputClsPrefix}-item.is-readonly-selectedWrap {
  height: auto;
  ui-m-input-control.is-readonly {
    padding: 0;
  }
  
  .ui-m-input-extra {
    height: auto;
  
  }
  .ui-m-input-control.is-readonly span {
    padding: var(--form-mobile-readonly-padding-y) var(--form-mobile-readonly-padding-x);
    line-height: var(--form-mobile-readonly-line-height);
    height: auto;
    white-space: normal;
    // touch-action: none;
  }
}

.@{mInputClsPrefix}-number {
  &.is-readonly.is-form-readonly {
    padding: var(--form-mobile-readonly-padding-y) var(--form-mobile-readonly-padding-x);
    line-height: var(--form-mobile-readonly-line-height);
    min-height: auto;
  }
}