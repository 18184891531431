.@{mCascaderListClsPrefix} {
  &-breadcrumb-wrap {
    display: flex;
    align-items: center;
    min-height: calc(44 * var(--hd));
    justify-content: space-between;
    background-color: var(--bg-base);
    &.is-empty {
      display: none;
    }
  }
  &-breadcrumb-check-all {
    // line-height: 44px;
    flex: 0 0 auto;
    color: var(--primary);
    padding-left: var(--h-spacing-md);
    padding-right: var(--h-spacing-lg);
  }
  &-breadcrumb {
    text-align: left;
    flex: 1 1 auto;
    font-size: var(--font-size-lg);
    min-height: calc(44 * var(--hd));
    white-space: nowrap;
    overflow-x: auto;
    padding: calc(13 * var(--hd)) var(--h-spacing-lg) var(--h-spacing-md);
    .@{breadCrumbClsPrefix}-separator {
      padding: 0 calc(6 * var(--hd));
    }
    .@{breadCrumbClsPrefix}-item {
      color: var(--primary);
      padding: 0;
      &:last-of-type {
        color: var(--regular-fc);
      }
    }
  }
  /* 根节点 */
  &-root-node {
    text-align: left;
    font-size: var(--font-size-lg);
    min-height: calc(44 * var(--hd));
    padding: calc(13 * var(--hd)) var(--h-spacing-lg) var(--h-spacing-md);
    color: var(--main-fc);
    background-color: var(--bg-base);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .border-solid-then;
  }
  &-list {
    display: none;
    padding-left: var(--h-spacing-lg);
    background-color: var(--bg-white);
    &.is-active {
      display: block;
    }
    &-item {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      overflow: hidden;
      // min-height: calc(44 * var(--hd));
      // border-right: solid var(--border-width) #ebebeb;
      // min-height: calc(50 * var(--hd));
      line-height: calc(25 * var(--hd));
      font-size: var(--font-size-md);
      // padding-left: var(--h-spacing-lg);
      padding-right: var(--h-spacing-md);
      padding-top: var(--v-spacing-md);
      padding-bottom: var(--v-spacing-md);
      &-icon {
        flex: 0 0 auto;
        margin-right: var(--h-spacing-md);
        margin-top: calc(-2 * var(--hd));
        &.stop-department {
          color: var(--danger);
          margin-left: var(--h-spacing-sm);
        }
      }
      &-avatar {
        flex: 0 0 auto;
        margin-right: var(--h-spacing-md);
      }
      &-content {
        flex: 1 1 auto;
        overflow: hidden;
        color: var(--main-fc);
        text-overflow: ellipsis;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        .content-inner {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre-wrap;
        }
        .content-extra {
          color: var(--secondary-fc);
        }
      }
      &-arrow {
        color: var(--secondary-fc);
        padding-right: var(--h-spacing-md);
        padding-left: var(--h-spacing-md);
      }
      &::before {
        content: "";
        position: absolute;
        right: calc(57 * var(--hd));
        top: 50%;
        transform: translateY(-50%);
        width: calc(1 * var(--hd));
        height: calc(20 * var(--hd));
        background-color: #ebebeb;
      }
      &.is-leaf,
      &.is-disabled {
        &::before {
          display: none;
        }
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: var(--hd);
        background-color: var(--border-color);
        transform: scaleY(0.3);
      }
      &.has-icon {
        &::after {
          width: calc(100% - var(--avatar-size-md) - var(--h-spacing-md));
        }
      }
      &.has-avatar {
        &::after {
          width: calc(100% - var(--avatar-size-md) - var(--h-spacing-md));
        }
      }
      &.disabled-expand {
        .@{mCascaderListClsPrefix}-list-item-content {
          color: var(--secondary-fc);
        }
      }
    }
    &-template-item {
      &-row {
        font-size: var(--font-size-sm);
        color: var(--main-fc);
        line-height: 1.3;
        // line-height: 1;
        color: var(--main-fc);
        overflow: hidden;
        text-overflow: ellipsis;
        padding: calc(2 * var(--hd)) 0;
        &:last-of-type {
          padding-bottom: 0;
        }
        &:only-of-type {
          .@{mCascaderListClsPrefix}-list-template-item-row-span {
            color: var(--main-fc);
          }
        }
        &-span {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: var(--font-size-sm);
          color: var(--secondary-fc);
          float: inherit !important;
          &:not(:last-of-type) {
            margin-right: var(--h-spacing-lg);
          }
          &.item-clickable {
            color: var(--primary);
          }
          &-text-highlight {
            font-weight: normal;
            color: var(--main-fc);
            font-size: var(--font-size-md);
            &.item-clickable {
              color: var(--primary);
            }
          }
          &-void {
            display: none;
          }
        }
        &-void {
          display: none;
        }
      }
      &-col {
        flex: 1 1 auto;
        max-width: 100%;
        overflow: hidden;
        &.col1_row2,
        &.col1_row3,
        &.col2_row2 {
          .@{mCascaderListClsPrefix}-list-template-item-row {
            display: flex;
            align-items: center;
            flex-wrap: wrap; /* 当两列布局中某一项内容过长时可以换行显示 */
            // justify-content: space-between;
            &-void {
              display: none;
            }
          }
        }
      }
      &-template-string,
      &-template-string-encry {
        overflow: hidden;
      }
    }
    .@{mListClsPrefix}-content {
      min-height: calc(50 * var(--hd));
    }
    .@{mListClsPrefix}-item-checkbox-right {
      flex: 0 0 auto;
      position: relative;
      // padding: var(--v-spacing-md) 0;
      padding-left: var(--h-spacing-lg);
      &.@{mCcheckboxClsPrefix}-wrapper-disabled {
        display: none;
      }
    }
  }
  .@{mListClsPrefix}-column .@{mListClsPrefix}-body {
    div:not(:last-child) .@{mListClsPrefix}-content {
      border-bottom: none;
      &::after {
        display: none;
      }
    }
    div:last-child .@{mListClsPrefix}-content {
      .@{mCascaderListClsPrefix}-list-item {
        &::after {
          display: none;
        }
      }
    }
  }
}

/* ExtraButton PC端 */
.@{browserClsPrefix}-extra-com {
  margin-right: var(--h-spacing-md);
}
.@{browserClsPrefix}-extra-select,
.@{browserClsPrefix}-extra-btn {
  display: inline-flex;
  align-items: center;
  // &:last-of-type {
  //   margin-right: 0;
  // }
  &-icon {
    line-height: normal;
    margin-right: var(--h-spacing-xs);
  }
}

/* ExtraButton 移动端 */
.@{mBrowserClsPrefix}-extra-btn.is-mobile {
  border: none;
  background-color: var(--bg-white);
  min-width: calc(150 * var(--hd));
}

/* MenuButton */
.@{mBrowserClsPrefix}-menu-btn {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: var(--bg-white);
  padding-left: var(--h-spacing-lg);
  // padding-right: var(--h-spacing-md);
  font-size: var(--font-size-lg);
  .border-solid-then;
  &::after {
    right: 0;
    left: inherit;
    width: calc(100% - 32 * var(--hd) - 2 * var(--h-spacing-lg));
  }
  &-icon {
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    background-color: #ffcd50;
    width: calc(32 * var(--hd));
    height: calc(32 * var(--hd));
    line-height: calc(28 * var(--hd));
    margin-right: var(--h-spacing-lg);
    .@{iconClsPrefix} {
      color: var(--base-white);
    }
  }
  &-content {
    flex: 1 1 auto;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
  }
}

/* StatusMenu */
.@{browserClsPrefix}-search-bar-status-menu {
  .@{menuClsPrefix}-select-iconOverlay {
  }
}
.@{browserClsPrefix}-search-bar-status-menu-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--main-fc);
  padding: var(--v-spacing-md) var(--h-spacing-sm) var(--v-spacing-md)
    var(--h-spacing-lg);
  min-height: calc(30 * var(--hd));
  &-checkbox {
    flex: 0 0 auto;
    margin-left: 0;
    margin-right: var(--h-spacing-md);
    .@{checkboxClsPrefix}-inner {
      border-radius: calc(2 * var(--hd));
    }
  }
  &-content {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:hover {
    background-color: var(--bg-base);
  }
}

.@{mBrowserClsPrefix}-panel-header-bar-select {
  &.is-mobile {
    cursor: none;
    .ui-select-dropdown {
      .ui-select-option {
        cursor: none;
      }
      .ui-scroller__bar.is-horizontal {
        display: none;
      }
    }
  }
}
