.@{avatarClsPrefix},
.@{mAvatarClsPrefix} {
  position: relative;
  // display: inline-flex;
  // justify-content: center;
  // align-items: center;
  display: inline-block;
  overflow: hidden;
  line-height: 0;
  vertical-align: middle;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 0;
    font-size: var(--font-size-12);
    overflow: hidden;
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    &-icon {
      padding: calc(2 * var(--hd));
      padding-bottom: calc(1 * var(--hd));
    }
  }

  &-name {
    line-height: 0; // fix: 文字上传不居中
  }

  .ui-icon, .ui-icon-svg {
    width: 100%;
    height: 100%;
  }

  &-lg {
    width: var(--avatar-size-lg);
    height: var(--avatar-size-lg);
  }

  &-md {
    width: var(--avatar-size-md);
    height: var(--avatar-size-md);
    .@{avatarClsPrefix}-name {
      transform: scale(0.83);
    }
  }

  &-sm {
    width: var(--avatar-size-sm);
    height: var(--avatar-size-sm);
    .@{avatarClsPrefix}-name {
      transform: scale(0.7);
    }
  }

  &-xs {
    width: var(--avatar-size-xs);
    height: var(--avatar-size-xs);
    .@{avatarClsPrefix}-name {
      transform: scale(0.6);
    }
  }

  &-circle {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 50%;

    .@{avatarClsPrefix}-img, img, &>span {
      border-radius: 50%;
    }
  }

  &-img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  &-skeleton{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}