.@{editableTableClsPrefix} {
  font-size: var(--font-size-12);
  color: var(--main-fc);
  background: var(--bg-transparent);

  // table样式复写 修复人力浏览按钮多选被影响的问题
  .@{tableClsPrefix}-grid-table-ellipsis .@{tableClsPrefix}-grid-tbody .@{tableClsPrefix}-grid-nowrap {
    overflow: visible;
    text-overflow: inherit;
    white-space: normal;
  }

  // 带switch框的头部上下居中
  &-thead-cheakall {
    display: flex;
    align-items: center;
  }

  // upload组件样式复写,只显示上传按钮
  .@{uploadClsPrefix} {
    padding: 0;

    .@{uploadClsPrefix}-select-drag {
      padding: 0;
      border: 0;
    }

    .@{uploadClsPrefix}-select-drag-info {
      display: none;
    }

    .ui-upload-select-drag-btn {
      height: calc(30 * var(--hd));
    }
  }


  // 行内顶部对齐
  &-align-top {
    .@{tableClsPrefix}-grid-selection-checkbox {
      display: flex;
      align-items: center;
      height: calc(30 * var(--hd));
      transform: none;
    }

    .@{tableClsPrefix}-grid-selection-checkbox-rowIndex,
    .@{tableClsPrefix}-grid-td-sortable-icon {
      display: flex;
      align-items: center;
      height: calc(30 * var(--hd));

      .ui-iocn {
        vertical-align: top;
      }
    }

    .@{tableClsPrefix}-grid-tr:hover {
      .@{tableClsPrefix}-grid-td .@{tableClsPrefix}-grid-selection-checkbox-rowIndex {
        display: none;
      }
    }

    .@{editableTableClsPrefix}-container {
      .@{tableClsPrefix}-grid-td {
        padding-top: calc(5 * var(--hd));
      }
    }

    .@{tableClsPrefix}-grid-td {
      vertical-align: top;
      line-height: calc(30 * var(--hd));


      .@{tableClsPrefix}-th-sortable-icon {
        padding-top: calc(5 * var(--hd));
      }

      .@{formItemClsPrefix}-wrapper {
        display: flex;
        align-items: center;

        .@{formSwitchClsPrefix}:not(.show-error-new) {
          display: flex;
          align-items: center;
        }

        // 必填的情况下, 预留右侧的宽度。防止必填图标显示和不显示的情况,不对齐
        .@{formSwitchClsPrefix}-requiredMark-container {
           padding-right: var(--icon-size-s);
        }
        .@{formSwitchClsPrefix}-requiredMark-container-showMark {
          padding-right: 0;
        }

        .@{formItemClsPrefix}-singleItem {
          align-self: flex-start;
        }

        .@{inputClsPrefix}.is-readonly {
          word-break: break-word;
        }
      }

      .@{formItemClsPrefix}-wrapper .@{radioClsPrefix},
      .@{formItemClsPrefix}-wrapper .@{checkboxClsPrefix},
      .@{formItemClsPrefix}-wrapper .@{switchClsPrefix} {
        padding-top: 0;
      }

      .@{formSwitchClsPrefix} {
        vertical-align: top;

        .@{switchClsPrefix} {
          vertical-align: top;
        }

        .@{switchClsPrefix}-md {
          margin-top: 0;
        }
      }
    }
  }

  &-align-middle {

    // fix:switch框上下不居中 textarea上下不居中
    &-table-casitem {
      .@{formItemClsPrefix}-wrapper {
        display: flex;
        align-items: center;

        .@{formSwitchClsPrefix}-SWITCH,
        .@{formSwitchClsPrefix}-TEXTAREA {
          display: flex;
          align-items: center;
        }

        .@{switchClsPrefix} {
          //复写formItem的padding-top
          padding: 0;
        }
      }
    }
  }

  &-dialog {
    .@{dialogClsPrefix}-body-container {
      overflow: hidden;

      .@{dialogClsPrefix}-body {
        height: 100%;
      }
    }

    .Icon-Global-reduction {
      width: calc(18 * var(--hd));
      height: calc(18 * var(--hd));
    }
  }

  &-top {
    height: calc(40 * var(--hd));
    line-height: calc(40 * var(--hd));

    &-tools {
      float: right;

      &-item {
        margin: 0 calc(5 * var(--hd));
        float: left;
        cursor: pointer;
        color: var(--primary);

        &-maximize {
          float: right;
          color: var(--secondary-fc);
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .@{iconClsPrefix}-wrapper {
      vertical-align: middle;
    }

    // 镜像处理
    &-rtl {
      .@{editableTableClsPrefix}-top-title {
        transform: none;
      }
    }
  }

  &-top-title {
    >span {
      display: inline-flex;
      align-items: center;
    }

    .@{helpClsPrefix} {
      line-height: 0;
    }
  }

  &-container {

    // table样式复写
    .@{tableClsPrefix} {
      &-grid-thead {
        .@{tableClsPrefix}-grid-tr {
          background-color: var(--label-bc);
        }
      }

      &-grid-tbody {
        .@{tableClsPrefix}-grid-tr {
          &:hover {
            background-color: var(--label-bc);

            .@{editableTableClsPrefix}-row-tools {
              display: inline-block;
              min-width: calc(122 * var(--hd));
            }
          }
        }
      }

      &-grid-td {
        position: relative;
        color: var(--main-fc);
        //td padding复写
        padding-bottom: calc(5 * var(--hd));

        // select下拉框宽度不超过100%处理
        .@{formSwitchClsPrefix}-SWITCH .@{selectClsPrefix} {
          max-width: 100%;

          >div {
            max-width: 100%;
          }
        }

        //浏览按钮文字超长处理
        .@{formSwitchClsPrefix}-BROWSER{
         max-width: 100%;
        }

        .@{editableTableClsPrefix}-text {
          display: inline-block;
          max-width: 100%;
          overflow-x: hidden;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .@{editableTableClsPrefix}-text-wrap {
          display: flex;
          align-items: center;
        }

        .@{formSwitchClsPrefix} {
          line-height: 1;

          .@{textareaClsPrefix} {
            resize: vertical;
            max-height: calc(200 * var(--hd));
          }
        }

        //折叠按钮居中
        .@{tableClsPrefix}-grid-expandable-btn-switcher {
          top: calc(11 * var(--hd))
        }

        .@{formItemClsPrefix}-module {
          padding: 0;
        }

        //必填校验位置复写
        .@{formItemClsPrefix}-wrapper {
          .@{formSwitchClsPrefix}-showError {
            top: 100%;
            left: 0;
          }
        }
      }
    }
  }

  &-row-tools {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    display: none;
    height: calc(28 * var(--hd));
    opacity: 0.7;
    background: var(--base-black);
    border-radius: var(--border-radius-xs);
    color: var(--base-white);
    padding: 0px var(--h-spacing-md);
    z-index: 10;
    width: 0;
    box-sizing: border-box;
    margin-left: calc(12 * var(--hd));

    &-item {
      display: inline-block;
      height: 100%;
      width: calc(18 * var(--hd));
      margin: 0 var(--h-spacing-xs);
      cursor: pointer;

      &:hover {
        .ui-icon {
          opacity: 1;
        }
      }

      .ui-icon {
        display: flex;
        align-items: center;
        height: 100%;
        opacity: .7;
      }

      .ui-icon svg {
        width: calc(18 * var(--hd));
        height: calc(18 * var(--hd));
      }
    }
  }

  &-editing {
    &-container {
      background: var(--bg-transparent);
      border-radius: calc(-3 * var(--hd));
      ;
      border: var(--border-width) solid var(--border-color);
    }

    &-item {
      padding: calc(10 * var(--hd)) calc(16 * var(--hd));

      &:first-child {
        border-bottom: var(--border-width) solid var(--border-color);
      }

      &-title {
        color: var(--secondary-fc);
        line-height: calc(30 * var(--hd));
      }

      &-select {
        min-width: calc(200 * var(--hd));
      }
    }
  }

  .@{editableTableClsPrefix}-thead-checkall {
    overflow: inherit;
    text-overflow: inherit;
    white-space: inherit;

    .@{checkboxClsPrefix}-wrapper,
    .@{checkboxClsPrefix}-label {
      overflow: inherit;
      text-overflow: inherit;
      white-space: inherit;
      display: flex;
    }

    .@{checkboxClsPrefix}-label-span {
      overflow: inherit;
      text-overflow: inherit;
      white-space: inherit;
    }

  }

  &-required-wrap {
    overflow: inherit;
    text-overflow: inherit;
    white-space: inherit;
    display: flex;

    .@{editableTableClsPrefix}-required-text {
      overflow: inherit;
      text-overflow: inherit;
      white-space: inherit;
      position: relative;
    }

    .@{editableTableClsPrefix}-required-sign {
      display: flex;
      align-items: center;
      &-beforeLabel {
        padding-right: var(--h-spacing-xs);
      }
      &-afterLabel {
        padding-left: var(--h-spacing-xs);
      }
    }
  }

  &-collapse-panel {
    .@{collapseClsPrefix} {
      border: 0;
    }

    .@{collapseClsPrefix}-panel__title {
      background: var(--bg-transparent);
      padding: 0;
      border: 0;

      .@{editableTableClsPrefix}-top {
        width: 100%;
        padding: 0;
        padding-right: calc(30 * var(--hd));
      }

      .@{collapseClsPrefix}-panel__arrow-right {
        margin: 0;
      }
    }

    .@{collapseClsPrefix}-panel__content-box {
      padding: 0;
      border: 0;
    }

    .@{collapseClsPrefix}-panel__content {
      padding: 0;
    }

    .@{tableClsPrefix}-grid-table {
      tr:last-child td {
        border-bottom: 0;
      }
    }
  }

  // 折叠按钮图标样式
  &-collapse-icon {
    width: calc(16 * var(--hd));
    height: calc(16 * var(--hd));
    background: #ebebeb;
    border-radius:calc(4 * var(--hd));
    color: #8b8a8a;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}