@select-prefix: ~'rc-cascader';

.@{select-prefix} {
  &-dropdown {
    min-height: auto;
    border: none;
  }

  &-menus {
    display: flex;
    flex-wrap: nowrap;
  }

  &-menu {
    flex: none;
    margin: 0;
    padding: 0;
    list-style: none;
    // border-left: 1px solid blue;
    height: 180px;
    min-width: 100px;
    overflow: auto;

    &:first-child {
      border-left: 0;
    }

    &-item {
      display: flex;
      flex-wrap: nowrap;
      padding-right: 20px;
      position: relative;

      &-selected {
        background: rgba(0, 0, 255, 0.05);
      }

      &-active {
        background: rgba(0, 255, 0, 0.1);
      }

      &-disabled {
        // opacity: 0.5;
      }



      &-content {
        flex: auto;
      }

      &-expand-icon {
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &-checkbox {
    position: relative;
    display: block;
    flex: none;
    width: 20px;
    height: 20px;
    // border: 1px solid blue;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
    }

    &-checked::after {
      content: '✔️';
    }

    &-indeterminate::after {
      content: '➖';
    }
  }

  // ====================== RTL ======================
  &-rtl {
    direction: rtl;

    .@{select-prefix}-menu {
      flex: none;
      margin: 0;
      padding: 0;
      list-style: none;
      border-left: none;
      // border-right: 1px solid blue;

      &:first-child {
        border-right: 0;
      }
    }
  }
}