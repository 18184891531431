.@{inputClsPrefix},
.@{textareaClsPrefix},
.@{inputClsPrefix}-wrap {
  min-height: var(--input-height);
  font-size: var(--input-font-size);
  font-weight: var(--font-weight-base);
  line-height: var(--input-line-height);
  padding: var(--input-padding-y) var(--input-padding-x);
  border: var(--border-solid);
  box-shadow: var(--input-shadow);
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: var(--input-border-radius);
  background-color: var(--input-bg);
  transition: border-color var(--transition-du) var(--transition-tf),
    var(--transition-color);

  &::placeholder {
    color: var(--input-placeholder-color);
    font-size: var(--input-font-size);
    opacity: 1;
    transition: var(--transition-color);
  }

  &:hover {
    border-color: var(--input-hover-border-color);

    // 处理 input disabled禁用情况下 hover placeholder color颜色问题
    &:not([disabled])::placeholder {
      color: var(--input-hover-placeholder-color);
    }
  }

  &.in-safari {

    /* 修复 safari 浏览中 input 框的 placeholder 向下偏移问题 */
    &::placeholder {
      position: relative;
      top: -2px;
    }
  }

  &.is-focus,
  &:focus {
    border-color: var(--input-focus-border-color);
    // color: var(--input-focus-color);
    box-shadow: var(--input-focus-shadow);
    outline: 0;
  }

  &.is-readonly {
    border: none;
    background: inherit;
    display: inline-flex;
    box-shadow: none;
    word-break: break-word;
    padding-left: 0;
    padding-right: 0;
    color: var(--main-fc);
  }

  &.is-high-light+div.required-fc {
    padding: var(--input-padding-y) var(--input-padding-x);
  }

  &.is-high-light {

    &.is-readonly,
    .is-readonly {
      display: inline-block;
    }

    &.is-high-light-required:not(.is-readonly) {
      border: calc(1 * var(--hd)) solid var(--form-item-required-new);
    }

    &.is-high-light-required.is-focus:not(.is-readonly) {
      box-shadow: 0 0 var(--input-focus-shadow-blur) var(--form-item-required-new);
    }
  }

  &.is-readonly-link {
    display: inline-block;
  }

  &.is-readonly-selectedWrap {
    display: inline-block;
    .selectedWrap-readyOnly
  }

  &.is-readonly-ellipsis {
    display: inline-block;
    .selectedWrap-ellipsis
  }

  &.is-readonly-inputReadOnly {

    &.is-focus,
    &:focus {
      border: var(--border-solid);
      // color: var(--input-focus-color);
      box-shadow: none;
      outline: 0;
    }
  }

  &.is-disabled,
  &:disabled {
    color: var(--input-disabled-color);
    background-color: var(--input-disabled-bg);
    border-color: var(--input-disabled-border-color);

    &:hover {
      .@{inputClsPrefix}::placeholder {
        color: var(--input-placeholder-color);
      }

      .@{inputClsPrefix}-clear {
        visibility: visible;
      }
    }

    opacity: 1;
    cursor: not-allowed;
  }

  &-readonly-link {
    color: #5d9cec;
    text-decoration: none;

    &:hover {
      color: #5d9cec;
    }
  }
}

/* 带前缀后缀图标 */
.@{inputClsPrefix}-wrap {
  display: inline-flex;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;

  &:hover {
    .@{inputClsPrefix}::placeholder {
      color: var(--input-hover-placeholder-color);
    }

    .@{inputClsPrefix}-clear {
      visibility: visible;
    }
  }

  .@{inputClsPrefix} {
    border: none;
    padding: 0;
    height: auto;
    width: 100%;
    min-width: 0;

    &,
    &.is-focus,
    &:focus {
      box-shadow: none;
    }
  }

  .@{inputClsPrefix}-clear {
    cursor: pointer;
    color: var(--invalid-fc);
    line-height: var(--icon-size-s);

    &:hover {
      color: var(--secondary-fc);
    }
  }

  .@{inputClsPrefix}-limit {
    color: var(--input-placeholder-color);
    margin-left: var(--h-spacing-sm);
  }

  .@{inputClsPrefix}-prefix {
    margin-right: var(--h-spacing-sm);
    display: flex;
    flex: none;
    align-items: center;
  }

  .@{inputClsPrefix}-suffix {
    display: flex;
    flex: none;
    align-items: center;
    height: 100%;
    // border: 1px solid red;
    color: var(--secondary-fc);
  }
}

/* 带前缀后缀内容 */
.@{inputClsPrefix}-group {
  display: inline-flex;

  .@{inputClsPrefix} {
    padding: 0 var(--input-padding-x);
    height: auto;
    // &:focus {
    //   box-shadow: none;
    // }
  }

  .@{inputClsPrefix}-wrap {
    flex: 1 1 auto;

    .@{inputClsPrefix} {
      padding: 0;
    }
  }

  .@{inputClsPrefix}-prepend,
  .@{inputClsPrefix}-append {
    display: inline-block;
    flex: 0 0 auto;
    min-height: var(--input-height);
    box-sizing: border-box;
    padding: var(--input-padding-y) var(--input-padding-x);
    border: var(--input-border-width) solid var(--input-border-color);
    box-shadow: var(--input-shadow);
    font-size: var(--input-font-size);
    font-weight: var(--input-font-weight);
    line-height: var(--input-line-height);
    border-radius: var(--input-border-radius);
    background-color: var(--input-group-addon-bg);
    color: var(--input-group-addon-color);
    margin-bottom: 0;

    &+.btn {
      padding: 0;
      border: 0;
    }
  }

  &>.@{inputClsPrefix}-append {
    border-left: none;
  }

  &>.@{inputClsPrefix}-prepend {
    border-right: none;
  }

  &>.@{inputClsPrefix}-append,
  &.@{inputClsPrefix}-group-prepend>.@{inputClsPrefix}-wrap,
  &.@{inputClsPrefix}-group-prepend>.@{inputClsPrefix} {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &>.@{inputClsPrefix}-prepend,
  &.@{inputClsPrefix}-group-append>.@{inputClsPrefix}-wrap,
  &.@{inputClsPrefix}-group-append>.@{inputClsPrefix} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

/* 密码框 */
.@{inputClsPrefix}-password-icon {
  cursor: pointer;
  user-select: none;
}

/* 数字框 */
.@{inputClsPrefix}-number {

  &.@{inputClsPrefix},
  .@{inputClsPrefix} {
    line-height: normal;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    & {
      text-align: center;
    }
  }

  &.hide-ops {
    text-align: left;

    .@{inputClsPrefix} {
      text-align: left;
    }
  }

  &.is-readonly {
    display: inline-block;
    color: var(--main-fc);
  }

  &.is-readonly-selectedWrap {
    display: inline-block;
    .selectedWrap-readyOnly
  }

  &.is-readonly-ellipsis {
    display: inline-block;
    .selectedWrap-ellipsis
  }

  .@{inputClsPrefix}-prepend,
  .@{inputClsPrefix}-append {
    padding: 0;
    transition: var(--transition-all);

    .@{inputClsPrefix}-number-operate {
      text-align: center;
      font-size: var(--font-size-lg);
      width: calc(40 * var(--hd));
      height: 100%;
      display: flex;
      flex-direction: column;

      &-btn {
        flex: 1 1 auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--secondary-fc);
      }
    }
  }

  &:not(.is-disabled) {

    &.@{inputClsPrefix}-wrap {

      &.is-focus,
      &:hover {
        box-shadow: var(--input-focus-shadow);
        border-color: var(--input-focus-border-color);
      }

      &.align-left,
      &.align-right {

        .@{inputClsPrefix} {
          text-align: left;
        }

        .@{inputClsPrefix}-number-operate {
          user-select: none;

          &-btn.not-background {
            &:hover {
              color: var(--primary);
            }
          }
        }
      }
    }

    &.@{inputClsPrefix}-group {
      user-select: none;

      &.is-focus,
      &:hover {
        box-shadow: var(--input-focus-shadow);

        .@{inputClsPrefix},
        .@{inputClsPrefix}-wrap {
          box-shadow: none;
          border-color: var(--input-focus-border-color);
        }

        .@{inputClsPrefix}-prepend,
        .@{inputClsPrefix}-append {
          border-color: var(--input-focus-border-color);
          background-color: var(--label-bc-active);

          .@{inputClsPrefix}-number-operate {
            &-btn {
              color: var(--primary);
            }

            >span:first-child {
              border-bottom-color: var(--input-focus-border-color);
            }
          }
        }
      }

      &.align-left,
      &.align-right {
        .@{inputClsPrefix} {
          text-align: left;
        }

        .@{inputClsPrefix}-number-operate {
          line-height: calc(var(--input-height) / 2);

          &-btn {
            &.not-background {
              &:hover {
                color: var(--primary);
              }
            }
          }

          >span:not(.not-background):first-child {
            border-bottom: var(--border-solid);
            transition: border-color var(--transition-du) var(--transition-tf);
          }
        }
      }
    }
  }

  &.align-left,
  &.align-right {
    .@{inputClsPrefix}-number-operate {
      >span {
        display: block;
        height: calc(14 * var(--hd));
        line-height: 0;

        &:not(.not-background):first-of-type {
          border-bottom: var(--border-solid);
        }
      }
    }
  }

  &-operate {
    cursor: pointer;
  }

  &-operate-btn {
    &.is-disabled {
      cursor: not-allowed;
    }
  }
}

// input 支持滚动条样式调整
.@{inputClsPrefix} {
  &-scroll-wrap {
    position: relative;

    input.@{inputClsPrefix} {
      position: relative;
      opacity: 1;
      z-index: 100;
    }

    &:hover {
      .is-auto-scroll {
        opacity: 0;
      }

      .ui-input-auto-scroll {
        display: block;
        opacity: 1;

        @keyframes scroll {
          0% {
            transform: 0;
          }

          100% {
            transform: translateX(-100%);
          }
        }

        @keyframes scrollrtl {
          0% {
            transform: 0 scaleX(-1);
          }

          100% {
            transform: translateX(-100%) scaleX(-1);
          }
        }

        span.need-scroll {
          animation: scroll 10s linear infinite;
        }

        &.need-rtl {
          span.need-scroll {
            animation: scrollrtl 10s linear infinite;
          }
        }
      }

      .has-value {
        &.ui-input-auto-scroll {
          display: none;
        }

        &.is-auto-scroll {
          opacity: 1;
        }
      }

      .ui-input-append {
        position: relative;
      }
    }

    &.ui-input-wrap.is-focus {
      .is-auto-scroll {
        opacity: 1;
      }

      .ui-input-auto-scroll.is-auto-scroll {
        opacity: 0;
      }
    }
  }

  &-auto-scroll {
    opacity: 0;
    overflow: hidden;
    color: var(--secondary-fc);
    position: absolute;
    left: var(--input-padding-x);
    top: 0;

    &.ui-input {
      width: calc(100% - 16 * var(--hd));
    }

    @keyframes scroll {
      0% {
        transform: 0;
      }

      100% {
        transform: translateX(-100%);
      }
    }

    span {
      padding: var(--input-padding-y) var(--input-padding-x);
      line-height: var(--input-line-height);
      display: inline-block;
      white-space: nowrap;
    }
  }

  // inputAt组件定位控制
  &-at {
    &-location {
      position: absolute;
      z-index: 9999;
      top: 0;
      left: 0;
      min-height: calc(20 * var(--hd));
    }
  }
}


// 表单专属样式
.@{inputClsPrefix},
.@{textareaClsPrefix},
.@{inputClsPrefix}-number {
  &.is-form-readonly {
    padding: var(--form-pc-readonly-padding-y) var(--form-pc-readonly-padding-x);
    line-height: var(--form-pc-readonly-line-height);
    min-height: auto;
  }
}

.weapp-ie11 {
  .@{inputClsPrefix} {
    min-height: calc(var(--input-height) + 2 * var(--hd));
  }
}

.@{inputClsPrefix} {
  /* 文本框补充文字颜色 */
  color: var(--input-text-color);
}