.@{mCascaderClsPrefix} {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  //mdialog定制化调整
  &-dialog {
    .@{mDialogClsPrefix}-wrap {
      height: 80%;
    }

    .@{mDialogClsPrefix}-content {
      height: 100%;
      border-radius: calc(16 * var(--hd)) calc(16 * var(--hd)) 0 0;
    }

    .@{mDialogClsPrefix}-top {
      height: 100%;
    }

    .@{mCascaderClsPrefix} {
      height: 100%;

      &-view {
        height: 100%;
      }
    }

    .@{mDialogClsPrefix}-top-bottom {
      padding: 0;
    }
  }

  //头部导航栏
  &-nav {
    min-height: calc(45 * var(--hd));
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 calc(24 * var(--hd));

    &-left,
    &-right {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .cancel,
    .clear,
    .confirm {
      font-size: var(--font-size-16);
      line-height: unset;
    }

    .cancel,
    .clear {
      padding-right: calc(10 * var(--hd));
      color: var(--regular-fc);
    }
  }

  &-searchBar {
    height: calc(45 * var(--hd));
    padding: calc(4 * var(--hd)) calc(16 * var(--hd));
    .border-solid-then;
  }

  &-view {
    // height: calc(500 * var(--hd));
    text-align: start;
    --height: auto;

    &-tabs {
      &.m-cascader-tabs {
        --title-font-size: 14px;
        --content-padding: none;

        .m-cascader-tabs-header {
          height: 45px;
        }

        .m-cascader-tabs-tab-list {
          height: 100%;
        }
      }
    }

    &-header {
      &-title {
        max-width: 84px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &-content-checkbox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: calc(4 * var(--hd)) 0 calc(4 * var(--hd)) calc(20 * var(--hd));

      .ui-m-checkbox-left {
        padding: 0;
      }

      >span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--font-size-14);
      }
    }

    &-content {
      &.m-cascader-list {
        --border-inner: none;
        --border-bottom: none;
        --border-top: none;
        overflow-y: auto;
      }

      height: var(--height);
      padding-top: 4px;
      box-sizing: border-box;
    }

    &-item {
      font-size: 14px;

      &-active {
        color: var(--primary);
      }
    }

    .m-cascader-list-inner {
      margin-bottom: 0;
    }

    &-keyword {
      color: #F5222D;

      &.need-mirror {
        transform: scaleX(1);
      }
    }
  }

  &-item {
    &.need-mirror {
      .ui-formItem-item-content {
        transform: scaleX(1);
      }
    }
  }
}

.@{mCascaderClsPrefix}-single-readOnly,
.@{mCascaderClsPrefix}-multiple-readOnly {
  pointer-events: none;
  min-height: calc(44 * var(--hd));

  .@{formItemClsPrefix}-item {
    color: var(--m-readonly-text);

    &-content,
    &-icon {
      color: var(--m-readonly-text);
    }
  }
}

.@{mCascaderClsPrefix}-formItem-content {
  flex: 1 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.@{mCascaderClsPrefix}-single-readOnly-readOnlyLineHeightStandard {

  .@{formItemClsPrefix}-item {
    min-height: unset;
    .form-mobile-readOnlyLineHeightStandard;
  }
}

.@{mCascaderClsPrefix}-multiple-readOnly-readOnlyLineHeightStandard {
  .form-mobile-readOnlyLineHeightStandard;

  .@{mCascaderClsPrefix}-formItem-content-readOnly-readOnlyLineHeightStandard {
    min-height: unset;
    .form-mobile-readOnlyLineHeightStandard;
    padding-top: unset;
    padding-bottom: unset;
  }
}


.@{mCascaderClsPrefix}-disabled {
  pointer-events: none;

  .@{formItemClsPrefix}-item {
    color: var(--invalid-fc);

    &-content,
    &-icon {
      color: var(--invalid-fc);
    }

  }
}

.@{mCascaderClsPrefix}-view {
  .@{mMenuClsPrefix}-wrap {
    width: 50%;
  }
}

.@{mCascaderClsPrefix}-view-menu-single {
  .@{mMenuClsPrefix}-wrap {
    width: 25%;
  }
}

.@{mCascaderClsPrefix}-footer {
  width: 100%;
  height: calc(50 * var(--hd));
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 calc(16 * var(--hd));
  border-top: var(--border-solid);
  background-color: var(--bg-white);

  &-text {
    font-size: var(--font-size-16);
    color: var(--primary);
  }

  &-btn {
    width: calc(85 * var(--hd));
  }
}

.@{mCascaderClsPrefix}-select-dialog {

  //mdialog定制化调整
  .@{mDialogClsPrefix}-wrap {
    height: 80%;
  }

  .@{mDialogClsPrefix}-content {
    height: 100%;
    border-radius: calc(16 * var(--hd)) calc(16 * var(--hd)) 0 0;
  }

  .@{mDialogClsPrefix}-top {
    height: calc(100% - calc(52 * var(--hd)));
  }

  .@{mCascaderClsPrefix} {
    height: 100%;

    &-view {
      height: 100%;
    }
  }

  .@{mDialogClsPrefix}-top-bottom {
    padding: 0;
  }

  .@{mDialogClsPrefix}-content {
    border-radius: calc(16 * var(--hd)) calc(16 * var(--hd)) 0 0;
  }

  .@{mDialogClsPrefix}-top {
    padding: calc(12 * var(--hd));
  }

  &-header {
    border-bottom: var(--border-solid);
    color: var(--secondary-fc);
    display: flex;
    justify-content: flex-start;
    padding: calc(10 * var(--hd)) 0;
  }

  &-list {
    .__high-light {
      color: #F5222D;
    }
  }
}

.@{mCascaderClsPrefix}-SwipeAction {

  .@{swipeActionClsPrefix} {
    width: 100%;
    min-height: calc(45 * var(--hd));
    font-size: var(--font-size-14);
  }

  .@{swipeActionClsPrefix}-content {
    display: flex;
    align-items: center;
    min-height: calc(45 * var(--hd));
  }

  .@{swipeActionClsPrefix}-btn {
    margin: calc(1 * var(--hd));
  }

  &.need-mirror-RTL {
    .ui-swipe-action-content {
      transform: scale(1);
      justify-content: end
    }
  }
}

.@{mCascaderClsPrefix}-emptyView {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: var(--border-solid);
}