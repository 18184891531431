/******************************** secret level dialog  ********************************/
.@{uploadClsPrefix}-secret-select {
  &-dialog-wrap {
    .ui-title-icon {
      border: 0;
    }
    .ui-dialog-body,
    .ui-dialog-footer,
    .ui-dialog-body-container {
      background-color: var(--bg-white);
    }
    .panel {
      .message {
        font-size: var(--font-size-12);
        line-height: calc(22 * var(--hd));
        color: var(--main-fc);
      }
      .tips {
        font-size: var(--font-size-12);
        line-height: calc(22 * var(--hd));
        color: var(--regular-fc);
      }
      .content {
        color: var(--main-fc);
      }
      .content .tips {
        margin: calc(10 * var(--hd)) 0 calc(4 * var(--hd)) 0;
      }
    }
    // 密级组件
    .@{secretSelectClsPrefix} {
      &-secretlevel,
      &-secretvalidity {
        padding: var(--form-item-padding-module);
        padding-top: 0;
        padding-bottom: 0;
        background: var(--bg-white);
        border-left: var(--border-solid);
        border-right: var(--border-solid);
      }
      &-secretlevel {
        border-bottom: var(--border-solid);
        border-top: var(--border-solid);
      }
      &-secretvalidity {
        border-bottom: var(--border-solid);
        margin-bottom: calc(var(--hd) * 40);
      }
    }
  }
}
