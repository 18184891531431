.@{skeletonClsPrefix} {
  overflow: hidden;
  background: var(--bg-white);
  width: 100%;

  &:empty {
    margin: 0;
    padding: 0;
    border: 0;
  }

  &-border {
    border: var(--border-solid);
  }

  &-half {
    display: inline-block;
    width: 50%;
    border-bottom: var(--border-solid);
    &:nth-child(odd) {
      border-right: var(--border-solid);
    }
  }

  &-block {
    padding-bottom: calc(5 * var(--hd));
    border: 0;
    background: var(--bg-transparent);
    .@{skeletonClsPrefix}-inner {
      border-radius: 0;
    }
  }

  &-single {
    display: flex;
    align-items: center;
    position: relative;
    &-left {
      height: 28%;
      width: 25%;
      padding: 0 var(--h-spacing-lg);
    }
    &-right {
      width: 75%;
      height: 28%;
      padding: 0 var(--h-spacing-lg);
    }
    &-avatar {
      padding-left: calc(16 * var(--hd));
      padding-top: calc(10 * var(--hd));
      height: 100%;
      &-content {
        width: calc(30 * var(--hd));
        height: calc(30 * var(--hd));
        flex: none;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    &-row {
      flex: auto;
      display: flex;
      align-items: center;
      height: 100%;
      flex-wrap: wrap;
      padding: calc(10 * var(--hd)) 0;
      & > * {
        width: 100%;
        padding-bottom: calc(8 * var(--hd));
        &:last-child {
          margin-bottom: calc(-8 * var(--hd));
          width: 75%;
        }
      }
    }

    &:after{
      position: absolute;
      content: " ";
      height: calc(1 * var(--hd));
      width: 100%;
      left: 0;
      bottom: 0;
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
      transform: scaleY(0.3);
      background-color: var(--diviling-line-color);
    }
  }
  &-single:last-child {
    border-bottom: 0;
  }
}
.@{skeletonClsPrefix}-inner {
  background: linear-gradient(
    90deg,
    var(--skeleton-inner-first-color) 25%,
    var(--skeleton-inner-first-color) 37%,
    var(--skeleton-inner-first-color) 63%
  );
  background-size: 400% 100%;
  -webkit-animation: skeleton-loading 1.8s ease infinite;
  animation: skeleton-loading 1.8s ease infinite;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-xs);
}
@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
