.@{dateMenuClsPrefix}-content {
  display: inline-flex;
  flex-direction: row;
}

.@{dateMenuClsPrefix} {
  display: flex;
  background: none;
  min-height: calc(40 * var(--hd));
  &.date-menu-needRTL{
    .ui-scroller__bar.is-horizontal{
      transform: scaleX(-1);
    }
  }
  .@{datePickerClsPrefix} {
    background: none;
    min-width: calc(150 * var(--hd));
  }
}

//滚动条兼容处理
.@{dateMenuClsPrefix}-scroller {
  width: calc(100% - 200 * var(--hd));
  display: inline-block;

  .@{dateMenuClsPrefix}-scrollerWrap {
    overflow-x: hidden;
    -ms-overflow-x: hidden;
  }
}

.@{dateMenuClsPrefix}-group {
  &-content {
    display: inline-flex;
    flex-direction: row;
    border: var(--border-solid);
    border-radius: var(--border-radius-xs);
    margin-left: calc(10 * var(--hd));

    &-area {
      display: inline-flex;
      flex-direction: row;
      border: var(--border-solid);
      border-radius: var(--border-radius-xs);
      margin-right: calc(10 * var(--hd));
    }
  }

  &-content_byGroup {
    border: none;
  }


  &-content &-groupBtn-wrap:not(:last-child) {
    border-right: var(--diviling-line-solid);
  }

  &-groupBtn {
    border: none;
    border-radius: 0%;

    &&-isSelect {
      background-color: var(--primary);
      color: var(--base-white);

      &:hover {
        color: var(--base-white);
      }
    }
  }
}