.@{mCcheckboxClsPrefix}-wrapper {
  display: flex;
  justify-content: flex-start;
  background-color: var(--m-chk-default-bc);
  vertical-align: middle;
  position: relative;

  &-hold-right {
    justify-content: space-between;
  }

  /**
  * 图片模式下，wrapper position 需要设置为 unset 让 input 的 absolute 覆盖范围达到整个图片单选组件
  */
  &-image {
    position: unset;
  }
}

// 一组数据渲染 border-bottom
.@{mCcheckboxClsPrefix}-group {
  .@{mCcheckboxClsPrefix}-label {
    .border-solid-then;
  }

  .@{mCcheckboxClsPrefix}-wrapper:last-child {
    .@{mCcheckboxClsPrefix}-label:after {
      background-color: var(--bg-transparent);
    }
  }
}

.@{mCcheckboxClsPrefix}-group-readOnly-image {
  padding-top: var(--form-mobile-readonly-padding-y);
  padding-bottom: var(--form-mobile-readonly-padding-y);
}

// 默认状态
.@{mCcheckboxClsPrefix} {
  position: relative;
  // display: inline-block;
  line-height: 1;
  // white-space: nowrap;
  vertical-align: middle;
  outline: none;

  &-label,
  &-left,
  &-right {
    padding: calc(12 * var(--hd)) 0;
  }

  &-label {
    width: 100%;
    font-size: var(--font-size-14);
    //color: var(--main-fc);
    color: var(--m-readonly-text);
    font-weight: var(--font-weight-base);
    line-height: var(--m-chk-size-base);

    &-disabled {
      color: var(--m-chk-disabled-font-color);
    }
  }

  &-inner {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    background-color: var(--m-chk-default-bc);
    width: var(--m-chk-size-base);
    height: var(--m-chk-size-base);
    direction: ltr;
    border: var(--border-width) solid var(--m-chk-border-color);
    border-radius: 50%;
    border-collapse: separate;
    transition: all 0.3s;

    &-left {
      margin-right: calc(19 * var(--hd));
    }

    &-right {
      margin-left: calc(19 * var(--hd));
    }

    &-checked {
      border-color: var(--m-chk-check-border-color);
      background-color: var(--m-chk-check-bc);
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 26%;
      display: table;
      width: var(--m-chk-check-width);
      height: var(--m-chk-check-height);
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(0) translate(-50%, -50%);
      opacity: 0;
      transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
      content: " ";
      pointer-events: none;
    }

    &-checked::after {
      position: absolute;
      display: table;
      border: var(--border-width) solid var(--base-white);
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -60%);
      opacity: 1;
      // transition: all 0.2s cubic-bezier(0.71, -0.46, 0.88, 0.6) 0.1s;
      content: " ";
      pointer-events: none;
    }
  }

  &-input {
    position: absolute;
    // visibility: hidden;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  &-left,
  &-right {
    &.hidden {
      display: none;
      // position: absolute;
      // top: 0;
      // right: 0;
      // bottom: 0;
      // left: 0;
      // z-index: 1;
      // width: 100%;
      // height: 100%;
      // opacity: 0;
    }
  }

  &-tips {
    color: var(--secondary-fc);
  }
}

// 选中状态
.@{mCcheckboxClsPrefix}-checked {
  .@{mCcheckboxClsPrefix}-inner {
    border-color: var(--m-chk-check-border-color);
    background-color: var(--m-chk-check-bc);

    &::after {
      position: absolute;
      display: table;
      border: var(--border-width) solid var(--base-white);
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -60%);
      opacity: 1;
      // transition: all 0.2s cubic-bezier(0.71, -0.46, 0.88, 0.6) 0.1s;
      content: " ";
      pointer-events: none;
    }
  }
}

// 半选状态
.@{mCcheckboxClsPrefix}-indeterminate {
  .@{mCcheckboxClsPrefix}-inner {
    background-color: var(--m-chk-check-bc);
    border-color: var(--m-chk-check-border-color);

    &::after {
      top: 50%;
      left: 50%;
      width: var(--m-chk-indeterminate-inner-width);
      height: var(--m-chk-indeterminate-inner-height);
      background-color: var(--bg-white);
      border: 0;
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      content: " ";
      pointer-events: none;
    }
  }
}

// 禁用状态
.@{mCcheckboxClsPrefix}-wrapper-disabled {
  &:active {
    background-color: var(--m-chk-default-bc);
  }
}

.@{mCcheckboxClsPrefix}-disabled {
  &+span {
    // color: var(--m-chk-disabled-font-color);
    color: var(--m-readonly-text);
  }

  .@{mCcheckboxClsPrefix}-inner,
  .@{mCcheckboxClsPrefix}-input {
    background-color: var(--m-chk-disabled-bg-color);
    border-color: var(--m-chk-disabled-border-color);

    &::after {
      border-color: var(--m-chk-disabled-inner-border-color);
      pointer-events: none;
    }
  }

  // 半选禁用样式
  &.@{mCcheckboxClsPrefix}-indeterminate {
    .@{mCcheckboxClsPrefix}-inner::after {
      background-color: var(--m-chk-disabled-inner-border-color);
    }
  }
}

// 只读状态
.@{mCcheckboxClsPrefix}-wrapper-readOnly {
  &:active {
    background-color: var(--m-chk-default-bc);
  }
}

.@{mCcheckboxClsPrefix}-readOnly {
  &+span {
    // color: var(--m-chk-disabled-font-color);
    color: var(--m-readonly-text);
  }

  .@{mCcheckboxClsPrefix}-inner,
  .@{mCcheckboxClsPrefix}-input {
    opacity: 0.5;
    // background-color: var(--m-chk-disabled-bg-color);
    // border-color: var(--m-chk-disabled-border-color);

    &::after {
      // border-color: var(--m-chk-disabled-inner-border-color);
    }
  }

  // 半选只读样式
  &.@{mCcheckboxClsPrefix}-indeterminate {
    .@{mCcheckboxClsPrefix}-inner::after {
      // background-color: var(--m-chk-disabled-inner-border-color);
    }
  }
}

.@{mCcheckboxClsPrefix}-wrapper-readOnly-readOnlyLineHeightStandard {

  .@{mCcheckboxClsPrefix}-readOnly,
  .@{mCcheckboxClsPrefix}-label-readOnly {
    .form-mobile-readOnlyLineHeightStandard
  }
}

.@{mCcheckboxClsPrefix}-wrapper-checked {

  // .@{mCcheckboxClsPrefix}-label-readOnly {
  //   color:var(--main-fc);
  // }
  .@{mCcheckboxClsPrefix}-readOnly {
    .@{mCcheckboxClsPrefix}-inner {
      // background-color: var(--m-chk-check-bc);
      // border-color: var(--m-chk-check-border-color);
      // &::after {
      //   border-color: var(--base-white);
      //   pointer-events: none;
      // }
    }
  }
}

// image checkbox
.@{mCcheckboxClsPrefix}-image {
  &-box img {
    width: 100%;
  }

  &-vertical &-wrapper {
    display: block;
  }
}

// iamge checkbox mb
.@{mCcheckboxClsPrefix}-image-wrapper {
  position: relative;
  width: calc(73 * var(--hd));
  height: calc(73 * var(--hd));
  background: var(--bg-white);
  // border: var(--hd) solid var(--border-color);
  // border-radius: calc(3 * var(--hd));
  display: inline-table;
  margin: calc(10 * var(--hd));

  .@{mCcheckboxClsPrefix} {
    &-wrapper {
      padding: 0;
    }

    &-inner {
      // position: absolute;
      margin: calc(3 * var(--hd)) calc(3 * var(--hd)) 0 0;
      width: calc(16 * var(--hd));
      height: calc(16 * var(--hd));
      background: var(--bg-transparent);
      // border: none;
      border: var(--base-white) var(--hd) solid;

      &-checked {
        border-color: var(--m-chk-check-border-color);
        background-color: var(--m-chk-check-bc);
      }
    }

    &-inner::after {
      left: 16%;
      opacity: 1;
      position: absolute;
      display: table;
      border: var(--border-width) solid var(--base-white);
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -60%);
      opacity: 1;
      content: " ";
      pointer-events: none;
    }

    &-label,
    &-left,
    &-right {
      padding: 0;
    }

    &-left {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      justify-content: flex-end;
    }

    &-label {
      margin-top: calc(6 * var(--hd));
      font-size: var(--font-size-12);
      line-height: 1.2;

      >span {
        text-overflow: ellipsis;
        height: 100%;
        display: inline-block;
        width: calc(73 * var(--hd));
        overflow: hidden;
      }
    }
  }
}
