.@{richTextClsPrefix},
.@{mRichTextClsPrefix} {
  // 重写编辑器样式
  .cke {
    overflow: hidden;
    border: var(--border-solid);
    border-radius: var(--border-radius-xs);
  }
  .cke_top {
    background: var(--bg-white);
    border-color: var(--border-color);
    padding: calc(2 * var(--hd)) calc(4 * var(--hd)) calc(2 * var(--hd));
    border-bottom: var(--border-solid);
    .ui-icon {
      color: var(--richtext-icon-color);
    }
    svg {
      width: var(--richtext-icon-width);
      height: var(--richtext-icon-width);
      cursor: pointer;
      vertical-align: middle;
      circle,
      path {
        // 复制到ck4的图标需要改到这里才能生效
        cursor: pointer;
        color: var(--richtext-icon-color);
      }
    }
    svg#Icon-Down-arrow01 {
      width: calc(10 * var(--hd));
      height: calc(10 * var(--hd));
    }
    // 工具栏
    a.cke_button_off:hover,
    a.cke_button_off:focus,
    a.cke_button_off:active {
      border: 0;
    }
    .cke_button {
      width: auto;
      height: auto;
      padding: var(--richtext-icon-padding) !important;
      margin: var(--richtext-icon-margins) !important;
      cursor: pointer;
      border: 0 !important;
      border-radius: var(--border-radius-xs);
      min-height: calc(24 * var(--hd));
      box-sizing: border-box;
      &:hover {
        background: var(--richtext-icon-bg-hover);
      }
      .cke_button_icon {
        width: auto;
        height: auto;
        margin-top: 0;
        cursor: pointer;
        color: var(--richtext-icon-color);
        .@{uploadClsPrefix}-select-input {
          position: absolute;
          width: 100%;
        }
      }
      svg#Icon-background-color {
        rect {
          width: calc(14 * var(--hd));
          height: calc(14 * var(--hd));
        }
      }
    }
    // 特殊处理：禁用下鼠标浮动效果处理 (例如有些功能不选中的时候按钮是禁用的, 当前并不是整个编辑器的禁用)
    .cke_button_disabled {
      cursor: not-allowed;
      &:hover {
        background: var(--bg-transparent) !important;
      }
      .cke_button_icon {
        cursor: not-allowed;
        svg {
          cursor: not-allowed;
          circle,
          path {
            cursor: not-allowed;
          }
        }
      }
    }
    .cke_toolbar_separator {
      height: var(--richtext-icon-width);
      margin-top: calc(6 * var(--hd));
      margin-left: calc(2 * var(--hd));
      margin-right: calc(2 * var(--hd));
      background-color: var(--richtext-separator-border-color);
    }
    .cke_combo {
      margin: 0;
      &:after {
        border: 0;
        display: none;
      }
      .cke_combo_button {
        border: 0;
        border-radius: var(--border-radius-xs);
        padding: 0;
        margin: 0;
        height: calc(24 * var(--hd));
        margin: calc(2 * var(--hd));
        // margin: 0;
        // margin-top: calc(2 * var(--hd));
        cursor: pointer;
        .cke_combo_text {
          cursor: pointer;
          color: var(--richtext-icon-color);
          line-height: calc(24 * var(--hd));
          width: calc(30 * var(--hd));
          padding-left: calc(4 * var(--hd));
        }
        .cke_combo_open {
          cursor: pointer;
        }
      }
      .cke_combo_open {
        height: var(--richtext-icon-width);
        line-height: var(--richtext-icon-width);
        svg#Icon-Down-arrow01 {
          margin-top: calc(1 * var(--hd));
        }
      }
      .cke_toolbar_separator {
        margin-top: calc(4 * var(--hd));
      }
    }
    .cke_combo__format .cke_combo_button .cke_combo_text,
    .cke_combo__font .cke_combo_button .cke_combo_text{
      width: 5em;
    }
    .cke_combo_on a.cke_combo_button,
    .cke_combo_off a.cke_combo_button:hover,
    .cke_combo_off a.cke_combo_button:focus,
    .cke_combo_off a.cke_combo_button:active {
      padding: 0;
      background: var(--richtext-icon-bg-hover);
    }
    .cke_button_on {
      background: var(--richtext-icon-bg-active);
    }
    .cke_button_icon_arrow {
      width: var(--richtext-icon-arrow-width);
      vertical-align: middle;
      cursor: pointer;
      path {
        cursor: pointer;
      }
    }
    .cke_button_label {
      display: none;
    }
    .cke_toolgroup {
      padding: 0;
    }
    .cke_toolbar .cke_combo + .cke_toolbar_end,
    .cke_toolbar .cke_combo + .cke_toolgroup {
      margin: 0;
    }
    .cke_toolgroup a.cke_button:last-child:after,
    .cke_toolgroup a.cke_button.cke_button_disabled:hover:last-child:after {
      display: none;
    }
  }
  .cke_bottom {
    display: none;
  }

  // 默认影藏工具栏处理
  &-hide {
    .cke_top {
      display: none;
    }
  }

  // 禁用样式处理
  &-disabled {
    .cke_top {
      background-color: var(--label-bc);
      .ui-icon {
        color: var(--richtext-icon-disabled-color);
      }
      svg {
        cursor: not-allowed;
        circle,
        path {
          // 复制到ck4的图标需要改到这里才能生效
          cursor: not-allowed;
          color: var(--richtext-icon-disabled-color);
        }
      }
      svg#Icon-Down-arrow01 {
        color: var(--richtext-icon-disabled-color);
      }
      // 扩展按钮禁用样式
      .cke_button:hover {
        background-color: var(--bg-transparent);
      }
      .cke_button__customextendbtn {
        background-color: var(--label-bc);
        cursor: not-allowed;
      }
      a.cke_button_disabled .cke_button_icon,
      a.cke_button_disabled .cke_button_arrow {
        cursor: not-allowed;
        opacity: 1;
        color: var(--richtext-icon-disabled-color);
      }
      .cke_combo {
        cursor: not-allowed;
      }
      .cke_combo_disabled .cke_combo_button .cke_combo_inlinelabel,
      .cke_combo_disabled .cke_combo_button .cke_combo_open {
        cursor: not-allowed;
        opacity: 1;
        color: var(--richtext-icon-disabled-color);
      }
      .cke_combo .cke_combo_button {
        cursor: not-allowed;
        .cke_combo_text {
          color: var(--richtext-icon-disabled-color);
          cursor: not-allowed;
        }
      }
    }
  }

  // 扩展按钮样式
  &-extendicon {
    .ui-icon {
      svg {
        width: var(--richtext-icon-width);
        height: var(--richtext-icon-width);
      }
    }
  }

  // at组件样式处理
  &-at {
    margin-top: var(--v-spacing-sm);
  }

  // videoUplaod组件样式
  &-video {
    font-size: var(--font-size-12);
    &-modal {
      .@{dialogClsPrefix}-body-container {
        background-color: var(--bg-white);
      }
      .@{dialogClsPrefix}-body {
        padding-top: calc(10 * var(--hd));
      }
      &-maximize {
        .@{dialogClsPrefix}-mask,
        .@{dialogClsPrefix}-wrap {
          // fix: 最大化上传视频弹框无法显示
          z-index: var(--richtext-video-zIndex);
        }
      }
    }
    &-content-item {
      line-height: calc(30 * var(--hd));
      padding: calc(5 * var(--hd)) 0;
      &-lable {
        color: var(--regular-fc);
      }
      &-comp {
        &-inputnumber {
          .@{inputClsPrefix} {
            width: calc(100 * var(--hd));
          }
        }
        .@{radioClsPrefix} {
          vertical-align: middle;
        }
      }
    }
    .@{menuClsPrefix} {
      min-height: calc(30 * var(--hd));
      margin-bottom: calc(10 * var(--hd));
      &-list-item {
        height: calc(30 * var(--hd));
      }
    }
    .@{uploadClsPrefix} {
      padding: calc(5 * var(--hd)) 0;
    }
  }

  // 输入框内的atmention插件样式
  &-atmention {
    &-container {
      position: absolute;
      z-index: 9997;
      width: var(--at-container-size);
      background: var(--bg-white);
      border: var(--border-solid);
      border-radius: var(--border-radius-xs);
      font-size: var(--font-size-12);
      color: var(--main-fc);
      box-shadow: var(--box-shadow);
      &-hide {
        display: none;
      }
      &-rtl {
        transform: scaleX(-1);
      }
    }
  }
}

// rtl镜像显示 (工具栏从右到左)
.@{richTextClsPrefix}-rtl {
  transform: scaleX(-1);
  .ui-entry { // 加密组件镜像
    transform: scaleX(-1);
    text-align: right;
  }
}
.@{richTextClsPrefix}-rtl,
.cke_maximized_rtl { // cke_maximized_rtl: rtl镜像最大化的时候的class
  // 工具栏
  .cke_top {
    .cke_combo,
    .cke_button,
    .cke_toolbar_separator,
    .cke_combo_button,
    .cke_combo .cke_combo_button .cke_combo_text {
      float: right;
    }
    .cke_combo .cke_combo_button .cke_combo_text {
      text-align: right;
      padding-left: 0;
      padding-right: calc(var(--hd)*4);
    }
    // 撤销 重做 按钮换方向
    .cke_button__undo,
    .cke_button__redo {
      transform: scaleX(-1);
    }
    // 块引用按钮换方向
    .cke_button__blockquote {
      transform: scale(-1);
    }
  }
}

// ck弹框 fix: 修复rtl镜像通过<html>加transform: scaleX(-1)造成的样式问题(最大化不需要处理)
.cke_dialog_container_rtl .cke_rtl {
  transform: scaleX(-1);
}
/* ck弹层 fix: 修复rtl镜像通过<html>加transform: scaleX(-1)造成的样式问题(最大化不需要处理) */
.cke_panel_rtl {
  transform: scaleX(-1);
}

// fix: 最大化标签弹框无法显示
.@{richTextClsPrefix}-emoticon {
  z-index: var(--richtext-emoticon-zIndex);
}

// 工具栏布局
.@{richTextClsPrefix} .cke_toolbox{
  display: flex;
  flex-wrap: wrap;
}

// 弹框样式复写
.cke_dialog_body {
  background: var(--modal-bc);
  box-shadow: var(--dialog-box-shadow);
  border-radius: var(--border-radius-sm);
  border: var(--border-solid);
  overflow: hidden;
  .cke_dialog_title {
    background: var(--bg-transparent);
    border-bottom: var(--border-solid);
    height: calc(50 * var(--hd));
    line-height: calc(50 * var(--hd));
    padding: 0 var(--h-spacing-lg);
    color: var(--main-fc);
    font-weight: normal;
  }
  .cke_dialog_ui_vbox_child {
    padding: 0;
  }
  a.cke_dialog_close_button {
    top: calc(18 * var(--hd));
    cursor: pointer;
  }
  .cke_dialog_contents {
    padding: 0;
  }
  .cke_dialog_contents_body {
    padding: var(--h-spacing-lg);
  }
  .cke_dialog_footer {
    background: var(--bg-transparent);
    border-top: var(--border-solid);
    height: calc(50 * var(--hd));
    line-height: calc(50 * var(--hd));
    padding: 0 var(--h-spacing-lg);
    .cke_resizer {
      display: none;
    }
    .cke_dialog_footer_buttons {
      margin: 0;
      a.cke_dialog_ui_button span {
        font-weight: normal;
      }
      .cke_dialog_ui_hbox_first {
        padding-right: calc(10 * var(--hd));
      }
    }
    a.cke_dialog_ui_button {
      background: var(--bg-white);
      border: var(--border-solid);
      border-radius: var(--border-radius-xs);
      height: calc(30 * var(--hd));
      line-height: calc(30 * var(--hd));
      padding: 0 var(--h-spacing-lg);
      span {
        font-weight: normal;
      }
    }
    a.cke_dialog_ui_button_ok {
      background: var(--primary);
      border-color: var(--primary);
    }
  }
}

// 提交之后的模块样式处理 (目前直接复制ckeditor的contents.css, 后期做主题样式同步的处理)
.@{richTextClsPrefix}-cke-readonly,
.@{mRichTextClsPrefix}-cke-readonly {
  font-family: Arial, PingFangSC-Light, Hiragino Sans GB, Microsoft YaHei,
    sans-serif;
  font-size: var(--font-size-12);
  color: var(--main-fc);
  background-color: #fff;
  line-height: 1.5;
  word-wrap: break-word;
  word-break: break-word;
  overflow: auto;
  > *:not(table) {
    /* 覆盖浏览器自带的margin-block-start */
    /* fix : align:center不生效问题 */
    margin: 0;
  }
  /* fix: chrome49 对以上的css选择器不兼容 */
  div,
  p,
  ol,
  ul {
    margin: 0;
  }
  img {
    max-width: 100%;
    height: auto !important; // pc+mobile 只读去掉图片高度限制，图片宽高自适应
    vertical-align: sub;
  }
  table {
    table-layout: fixed;
    border-collapse: collapse;
    border-color: #ccc;
    max-width: 100%;
  }
  table td {
    border: 1px solid #ccc;
    padding: 0;
    text-indent: 2px;
  }

  table[border="0"] > tbody > tr > td,
  table[border="0"] > tbody > tr > th {
    border: 0;
  }

  /******* chenjm 2021-06-01 e9的样式处理迁移 end *******/

  blockquote {
    font-style: italic;
    font-family: Georgia, Times, "Times New Roman", serif;
    padding: 2px 0;
    border-style: solid;
    border-color: #ccc;
    border-width: 0;
  }

  .cke_contents_ltr blockquote {
    padding-left: 20px;
    padding-right: 8px;
    border-left-width: 5px;
  }

  .cke_contents_rtl blockquote {
    padding-left: 8px;
    padding-right: 20px;
    border-right-width: 5px;
  }

  a {
    color: #5d9cec;
    text-decoration-line: none;
  }

  input {
    appearance: auto;
  }

  ol,
  ul,
  dl {
    /* IE7: reset rtl list margin. (#7334) */
    *margin-right: 0px;
    /* Preserved spaces for list items with text direction different than the list. (#6249,#8049)*/
    padding: 0 40px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    line-height: 1.2;
  }

  hr {
    border: 0px;
    border-top: 1px solid #ccc;
  }

  img.right {
    border: 1px solid #ccc;
    float: right;
    margin-left: 15px;
    padding: 5px;
  }

  img.left {
    border: 1px solid #ccc;
    float: left;
    margin-right: 15px;
    padding: 5px;
  }

  pre {
    white-space: pre-wrap; /* CSS 2.1 */
    word-wrap: break-word; /* IE7 */
    -moz-tab-size: 4;
    tab-size: 4;
  }

  .marker {
    background-color: Yellow;
  }

  span[lang] {
    font-style: italic;
  }

  figure {
    text-align: center;
    outline: solid 1px #ccc;
    background: rgba(0, 0, 0, 0.05);
    padding: 10px;
    margin: 10px 20px;
    display: inline-block;
  }

  figure > figcaption {
    text-align: center;
    display: block; /* For IE8 */
  }

  /* Widget Styles */
  .code-featured {
    border: 5px solid red;
  }

  .math-featured {
    padding: 20px;
    box-shadow: 0 0 2px rgba(200, 0, 0, 1);
    background-color: rgba(255, 0, 0, 0.05);
    margin: 10px;
  }

  .image-clean {
    border: 0;
    background: none;
    padding: 0;
  }

  .image-clean > figcaption {
    font-size: 0.9em;
    text-align: right;
  }

  .image-grayscale {
    background-color: white;
    color: #666;
  }

  .image-grayscale img,
  img.image-grayscale {
    filter: grayscale(100%);
  }

  .embed-240p {
    max-width: 426px;
    max-height: 240px;
    margin: 0 auto;
  }

  .embed-360p {
    max-width: 640px;
    max-height: 360px;
    margin: 0 auto;
  }

  .embed-480p {
    max-width: 854px;
    max-height: 480px;
    margin: 0 auto;
  }

  .embed-720p {
    max-width: 1280px;
    max-height: 720px;
    margin: 0 auto;
  }

  .embed-1080p {
    max-width: 1920px;
    max-height: 1080px;
    margin: 0 auto;
  }

  // chenjm 2022-04-11 fix:视频容器下的间隙问题
  .ckeditor-html5-video {
    font-size: 0;
  }
}

// eb表单统一行高需求
.@{richTextClsPrefix}-cke-readonly-lineheight {
  line-height: var(--form-pc-readonly-line-height);
  padding-top: var(--form-pc-readonly-padding-y);
  padding-bottom: var(--form-pc-readonly-padding-y);
  background-color: transparent;
}
.@{mRichTextClsPrefix}-cke-readonly-lineheight {
  line-height: var(--form-mobile-readonly-line-height);
  padding-top: var(--form-mobile-readonly-padding-y);
  padding-bottom: var(--form-mobile-readonly-padding-y);
}

// PC端只读时是否显示边框控制
.@{richTextClsPrefix}-cke-readonly-showborder {
  border: var(--border-solid);
  border-radius: var(--border-radius-xs);
  padding: var(--input-padding-y) var(--input-padding-x);;
}

// 敏感词高亮效果 (和ck底层的样式对应 - 统一编辑和只读)
.rich-text-highlight-bg {
  background-color: var(--highlight-bg);
}
.rich-text-highlight-active {
  background-color: var(--highlight-bg);
  color: var(--highlight-color);
}