@import './animation.less';

@trigger-button-width-height: 40;
@trigger-button-padding1: 6;
@trigger-button-padding2: 10;

.@{mQuickMenuClsPrefix} {
  &-pop-menu-container {
    .@{mQuickMenuClsPrefix}-pop-menu {
      position: absolute;
      bottom: 0;
      right: 0;

      &.buttons-length-1 {
        .@{mQuickMenuClsPrefix}-pop-menu-1 {
          animation-name: btns1idx1, opacity;
          right: calc(44 * var(--hd));
          bottom: calc(180 * var(--hd));

          .@{mQuickMenuClsPrefix}-pop-menu-tips {
            right: calc((@trigger-button-width-height + @trigger-button-padding2)* var(--hd));
          }
        }
      }

      &.buttons-length-2 {
        .@{mQuickMenuClsPrefix}-pop-menu-1 {
          // animation-name: btns2idx1, opacity;
          right: calc(44 * var(--hd));
          bottom: calc(180 * var(--hd));

          .@{mQuickMenuClsPrefix}-pop-menu-tips {
            right: calc((@trigger-button-width-height + @trigger-button-padding2)* var(--hd));
          }
        }

        .@{mQuickMenuClsPrefix}-pop-menu-2 {
          // animation-name: btns2idx2, opacity;
          right: calc(124 * var(--hd));
          bottom: calc(100 * var(--hd));

          .@{mQuickMenuClsPrefix}-pop-menu-tips {
            right: calc((@trigger-button-width-height + @trigger-button-padding2)* var(--hd));
          }
        }
      }

      &.buttons-length-3 {
        .@{mQuickMenuClsPrefix}-pop-menu-1 {
          // animation-name: btns3idx1, opacity;
          right: calc(50 * var(--hd));
          bottom: calc(190 * var(--hd));

          .@{mQuickMenuClsPrefix}-pop-menu-tips {
            right: calc((@trigger-button-width-height + @trigger-button-padding2)* var(--hd));
          }
        }

        .@{mQuickMenuClsPrefix}-pop-menu-2 {
          // animation-name: btns3idx2, opacity;
          right: calc(120 * var(--hd));
          bottom: calc(120 * var(--hd));

          .@{mQuickMenuClsPrefix}-pop-menu-tips {
            right: calc((@trigger-button-width-height + @trigger-button-padding2)* var(--hd));
          }
        }

        .@{mQuickMenuClsPrefix}-pop-menu-3 {
          // animation-name: btns3idx3, opacity;
          right: calc(110 * var(--hd));
          bottom: calc(40 * var(--hd));

          .@{mQuickMenuClsPrefix}-pop-menu-tips {
            right: calc((@trigger-button-width-height + @trigger-button-padding2)* var(--hd));
          }
        }
      }

      &.buttons-length-4 {
        .@{mQuickMenuClsPrefix}-pop-menu-1 {
          animation-name: btns4idx1, opacity;
          right: calc(40 * var(--hd));
          bottom: calc(190 * var(--hd));

          .@{mQuickMenuClsPrefix}-pop-menu-tips {
            right: calc((@trigger-button-width-height / 2) * var(--hd));
            bottom: calc((@trigger-button-width-height + @trigger-button-padding1) * var(--hd));
          }
        }

        .@{mQuickMenuClsPrefix}-pop-menu-2 {
          animation-name: btns4idx2, opacity;
          right: calc(105 * var(--hd));
          bottom: calc(170 * var(--hd));

          .@{mQuickMenuClsPrefix}-pop-menu-tips {
            right: calc((@trigger-button-width-height + @trigger-button-padding2)* var(--hd));
          }
        }

        .@{mQuickMenuClsPrefix}-pop-menu-3 {
          animation-name: btns4idx3, opacity;
          right: calc(130 * var(--hd));
          bottom: calc(108 * var(--hd));

          .@{mQuickMenuClsPrefix}-pop-menu-tips {
            right: calc((@trigger-button-width-height + @trigger-button-padding2)* var(--hd));
          }
        }

        .@{mQuickMenuClsPrefix}-pop-menu-4 {
          animation-name: btns4idx4, opacity;
          right: calc(110 * var(--hd));
          bottom: calc(45 * var(--hd));

          .@{mQuickMenuClsPrefix}-pop-menu-tips {
            right: calc((@trigger-button-width-height + @trigger-button-padding2)* var(--hd));
          }
        }

        .@{mQuickMenuClsPrefix}-pop-menu-1-closeAnimation,
        .@{mQuickMenuClsPrefix}-pop-menu-2-closeAnimation,
        .@{mQuickMenuClsPrefix}-pop-menu-3-closeAnimation,
        .@{mQuickMenuClsPrefix}-pop-menu-4-closeAnimation {
          animation: none;
        }
      }
    }

    .@{mQuickMenuClsPrefix}-pop-menu-tips {
      position: absolute;
      min-height: calc(30 * var(--hd));
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: var(--base-white);
      padding: calc(6 * var(--hd));
      border-radius: calc(4 * var(--hd));

      &-item {
        height: 100%;
        width: max-content;
        min-width: calc(40 * var(--hd));
        max-width: calc(220 * var(--hd));
        border-radius: var(--border-radius-xs);
        font-size: var(--font-size-12);
        color: var(--main-fc);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-align: center;
        word-wrap: break-word;
      }

      &-item-isIE {
        display: inline-block;
        width: auto;
      }
    }

    .@{mQuickMenuClsPrefix}-pop-menu-tips-isMobile {
      max-width: calc(180 * var(--hd));
    }

    .@{mQuickMenuClsPrefix}-pop-menu-tips-isIE {
      display: table;
    }
  }

  &-pop-menu-container-rtl.ui-trigger-popupInner.rtlSafari {
    transform: unset;
  }

  &-pop-menu-1,
  &-pop-menu-2,
  &-pop-menu-3,
  &-pop-menu-4 {
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(@trigger-button-width-height * var(--hd));
    height: calc(@trigger-button-width-height * var(--hd));

    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    svg {
      color: var(--base-white);
      width: calc(26 * var(--hd));
      height: calc(26 * var(--hd));
    }
  }
}