/* ----------------------------------------- 表单线条模式 -------------------------------------------- */
.@{formClsPrefix}-line {
  padding: var(--v-spacing-lg) 0;
  background-color: var(--bg-white);
  border: var(--form-line-container-border);

  .@{formClsPrefix}-row {
    border: 0;
    padding-bottom: var(--v-spacing-lg);
    &:last-child {
      border-bottom: 0;
    }
  }

  .@{formClsPrefix}-row .@{formClsPrefix}-col {
    border-left: 0;
    border-right: 0;
    &:first-child, &:last-child {
      border-left: 0;
      border-right: 0;
    }
  }

  .@{formItemClsPrefix} {
    padding: 0 calc(25 * var(--hd));
  }

  .@{formItemClsPrefix}-label-col,
  .@{formItemClsPrefix}-wrapper-col {
    padding: calc(5 * var(--hd)) 0;
  }

  .@{formItemClsPrefix}-wrapper-col {
    border-bottom: var(--form-line-border);
  }

  .@{formItemClsPrefix}-noLabel .@{formItemClsPrefix}-wrapper-col {
    border-bottom: 0;
  }
}