/******************************* 外壳 *******************************/
.@{uploadClsPrefix}-progress {
  position: fixed;
  right: calc(100 * var(--hd));
  bottom: calc(100 * var(--hd));
  transition: width linear 0.3s, border linear 0.5s,
    background-color linear 0.5s;
  z-index: 9999999999;
  width: calc(68 * var(--hd));
  height: calc(68 * var(--hd));
  //overflow: hidden;
  // cursor: move;
  cursor: default;
  border: var(--hd) solid transparent;
  display: flex;
  border-radius: calc(33 * var(--hd));
  background-color: var(--bg-transparent);
  padding: calc(3 * var(--hd));
  &.hide {
    display: none;
  }
  // &.doing:hover {
  //   width: calc(290 * var(--hd));
  // }

  &:hover {
    width: calc(290 * var(--hd));
  }
  overflow: hidden;
  background: var(--bg-white);
  border: var(--hd) solid #ebebeb;
  box-shadow: 0 0 calc(6 * var(--hd)) 0 rgb(0 0 0 / 8%);
  border-radius: calc(34 * var(--hd));
}

/******************************* 左侧圆形进度区域 *******************************/
.@{uploadClsPrefix}-progress-circle-progress {
  background: var(--bg-white);
  border: var(--hd) solid #ebebeb;
  box-shadow: 0 0 calc(6 * var(--hd)) 0 rgba(0, 0, 0, 0.08);
  border-radius: calc(34 * var(--hd));

  /*支持IE9及以上*/
  // cursor: pointer;
  cursor: default;
  .circle-bar {
    position: relative;
    width: calc(60 * var(--hd));
    height: calc(60 * var(--hd));
    display: inline-block;
  }
  .progress {
    position: absolute;
    width: calc(56 * var(--hd));
    height: calc(56 * var(--hd));
    background-color: var(--bg-white);
    border-radius: 50%;
    left: calc(2 * var(--hd));
    top: calc(2 * var(--hd));
    line-height: calc(58 * var(--hd));
    text-align: center;
    // span {
    //   font: calc(16 * var(--hd)) Arial;
    // }
    .ui-icon-svg {
      width: calc(35 * var(--hd));
      height: calc(35 * var(--hd));
      color: var(--primary);
    }
  }
  .circle-bar-left,
  .circle-bar-right {
    width: calc(30 * var(--hd));
    height: calc(60 * var(--hd));
    overflow: hidden;
    position: relative;
    float: left;
    background: #ebebeb;
  }
  .circle-bar-left {
    border-radius: calc(60 * var(--hd)) 0 0 calc(60 * var(--hd));
  }
  .circle-bar-right {
    border-radius: 0 calc(60 * var(--hd)) calc(60 * var(--hd)) 0;
  }
  .circle-bar-left div,
  .circle-bar-right div {
    content: "";
    position: absolute;
    display: block;
    width: calc(30 * var(--hd));
    height: calc(60 * var(--hd));
    background-color: var(--bg-white);
    border-radius: calc(60 * var(--hd)) 0 0 calc(60 * var(--hd));
    transition: all 0.1s;
    transform-origin: right center;
    transform: rotateZ(-180deg);
  }
  .circle-bar-left div {
    transition: all 0.1s 0.1s;
  }
  .circle-bar-right div {
    content: "";
    position: absolute;
    display: block;
    border-radius: 0 calc(60 * var(--hd)) calc(60 * var(--hd)) 0;
    transform-origin: left center;
  }
  .circle-bar:nth-child(1) .circle-bar-left div,
  .circle-bar:nth-child(1) .circle-bar-right div {
    background-color: var(--primary);
  }

  &.done {
    .circle-bar:nth-child(1) .circle-bar-left div,
    .circle-bar:nth-child(1) .circle-bar-right div {
      background-color: var(--success);
    }
    .progress .ui-icon-svg {
      color: var(--success);
    }
  }
}

/******************************* 右侧圆形进度区域 *******************************/
.@{uploadClsPrefix}-progress-line-progress {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 calc(10 * var(--hd)) 0 calc(4 * var(--hd));
  .content-box {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-align: center;
  }
  .main-text {
    font-size: var(--font-size-12);
    color: var(--main-fc);
    font-weight: var(--font-weight-base);
  }
  .secondary-text {
    font-size: var(--font-size-12);
    color: var(--secondary-fc);
    font-weight: var(--font-weight-base);
  }
}

/******************************* 上传完成样式 *******************************/
.@{uploadClsPrefix}-progress {
  &.done {
    .circle-bar:nth-child(1) .circle-bar-left div,
    .circle-bar:nth-child(1) .circle-bar-right div,
    .@{sliderClsPrefix}-track {
      background-color: var(--success);
    }
    .progress .ui-icon-svg {
      color: var(--success);
    }
  }
}
