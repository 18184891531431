.@{mDialogClsPrefix} {
  &-wrap {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: var(--dialog-route-layout-zindex);
    animation-duration: var(--dialog-animation-time);
    -webkit-animation-duration: var(--dialog-animation-time);
    -o-animation-duration: var(--dialog-animation-time);
    -moz-animation-duration: var(--dialog-animation-time);

    // box-shadow: var(--box-shadow);
    &-fadeIn {
      animation-name: MDialogFadeIn;
      -webkit-animation-name: MDialogFadeIn;
      -o-animation-name: MDialogFadeIn;
      -moz-animation-name: MDialogFadeIn;
    }

    &-fadeOut {
      animation-name: MDialogFadeOut;
      -webkit-animation-name: MDialogFadeOut;
      -o-animation-name: MDialogFadeOut;
      -moz-animation-name: MDialogFadeOut;
    }

    &-fadeIn-closeAnimation,
    &-fadeOut-closeAnimation {
      animation: none;
    }

    &-hide {
      display: none;
    }

    &-visNone {
      visibility: hidden;
    }

    .@{mDialogClsPrefix}-footer-two {
      display: flex;
      align-content: center;
    }

    .@{mDialogClsPrefix}-footer-two .@{mDialogClsPrefix}-footer-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &-bottom,
    &-top,
    &-left,
    &-right {
      bottom: 0;
      top: auto;

      .@{mDialogClsPrefix}-content {
        width: 100%;
        border-radius: 0;
      }

      .@{mDialogClsPrefix}-footer-btn {
        margin-top: var(--m-dialog-footer-btn-v-spacing);
      }

      .@{mDialogClsPrefix}-footer-two .@{mDialogClsPrefix}-footer-btn {
        margin-top: 0;
      }

      &-fadeIn {
        animation-name: MDialogBottomFadeIn;
        -webkit-animation-name: MDialogBottomFadeIn;
        -o-animation-name: MDialogBottomFadeIn;
        -moz-animation-name: MDialogBottomFadeIn;
      }

      &-fadeOut {
        bottom: -100%;
        animation-name: MDialogBottomFadeOut;
        -webkit-animation-name: MDialogBottomFadeOut;
        -o-animation-name: MDialogBottomFadeOut;
        -moz-animation-name: MDialogBottomFadeOut;
      }
    }

    &-top {
      bottom: auto;
      top: 0;

      &-fadeIn {
        animation-name: MDialogTopFadeIn;
        -webkit-animation-name: MDialogTopFadeIn;
        -o-animation-name: MDialogTopFadeIn;
        -moz-animation-name: MDialogTopFadeIn;
      }

      &-fadeOut {
        animation-name: MDialogTopFadeOut;
        -webkit-animation-name: MDialogTopFadeOut;
        -o-animation-name: MDialogTopFadeOut;
        -moz-animation-name: MDialogTopFadeOut;
        top: -100%;
      }
    }

    &-left {
      width: 100%;
      margin: 0;
      height: 100%;

      .@{mDialogClsPrefix}-content {
        height: 100%;
      }

      &-fadeIn {
        animation-name: MDialogLeftFadeIn;
        -webkit-animation-name: MDialogLeftFadeIn;
        -o-animation-name: MDialogLeftFadeIn;
        -moz-animation-name: MDialogLeftFadeIn;
      }

      &-fadeOut {
        animation-name: MDialogLeftFadeOut;
        -webkit-animation-name: MDialogLeftFadeOut;
        -o-animation-name: MDialogLeftFadeOut;
        -moz-animation-name: MDialogLeftFadeOut;
        left: -100%;
      }
    }

    &-right {
      width: 100%;
      margin: 0;
      height: 100%;
      right: 0;
      left: auto;

      .@{mDialogClsPrefix}-content {
        height: 100%;
      }

      &-fadeIn {
        animation-name: MDialogRightFadeIn;
        -webkit-animation-name: MDialogRightFadeIn;
        -o-animation-name: MDialogRightFadeIn;
        -moz-animation-name: MDialogRightFadeIn;
      }

      &-fadeOut {
        animation-name: MDialogRightFadeOut;
        -webkit-animation-name: MDialogRightFadeOut;
        -o-animation-name: MDialogRightFadeOut;
        -moz-animation-name: MDialogRightFadeOut;
        right: -100%;
      }
    }
  }

  &-content {
    margin: 0 auto;
    width: 70%;
    background-color: var(--bg-white);
    border-radius: var(--m-dialog-circle-radius);
    position: relative;
  }

  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: var(--mask-bc);
    z-index: var(--dialog-route-layout-zindex);
    animation-duration: var(--dialog-animation-time);
    -webkit-animation-duration: var(--dialog-animation-time);
    -o-animation-duration: var(--dialog-animation-time);
    -moz-animation-duration: var(--dialog-animation-time);

    &-fadeIn {
      animation-name: MDialogFadeIn;
      -webkit-animation-name: MDialogFadeIn;
      -o-animation-name: MDialogFadeIn;
      -moz-animation-name: MDialogFadeIn;
    }

    &-fadeOut {
      animation-name: MDialogFadeOut;
      -webkit-animation-name: MDialogFadeOut;
      -o-animation-name: MDialogFadeOut;
      -moz-animation-name: MDialogFadeOut;
    }

    &-hide {
      display: none;
    }
  }

  &-top {
    padding: calc(24 * var(--hd));
    text-align: center;
    color: var(--main-fc);
    font-size: var(--font-size-14);
    overflow: auto;
    background-color: var(--bg-white);
    border-radius: var(--m-dialog-circle-radius);

    &-title {
      font-size: var(--font-size-17);
      padding: calc(var(--spacing-sm) / 2) 0;
    }

    &-message {
      padding: calc(var(--spacing-sm) / 2) 0;
    }

    &-description {
      color: var(--secondary-fc);
      padding: calc(var(--spacing-sm) / 2) 0;
    }

    &-icon {
      height: var(--m-dialog-icon-size);
      text-align: center;

      .@{iconClsPrefix},
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-bottom {
      padding-top: var(--v-spacing-lg);
      padding-bottom: var(--v-spacing-lg);
    }
  }

  &-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: var(--m-dialog-footer-bg);
    border-radius: var(--m-dialog-circle-radius);

    &-autoHeight {
      position: relative;
    }

    &-two {
      .@{mDialogClsPrefix}-footer-btn {
        display: inline-block;
        width: calc(50% - (var(--border-width) / 2));

        &:first-child {
          border-bottom-left-radius: var(--m-dialog-circle-radius);
          border-right: var(--border-width) solid var(--diviling-line-color);
        }

        &-last {
          border-bottom-left-radius: 0;
        }

        &.needRTL:first-child {
          border-bottom-right-radius: var(--m-dialog-circle-radius);
          border-bottom-left-radius: unset;
          border-right: 0;
          border-left: var(--border-width) solid var(--diviling-line-color);
        }

        &-last.needRTL {
          border-bottom-right-radius: unset;
          border-bottom-left-radius: var(--m-dialog-circle-radius);
        }
      }
    }

    &-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .@{mDialogClsPrefix}-footer-btn {
        &-last {
          border-radius: 0;

          &:not(.needRTL) {
            border-right: 0;
          }
        }

        &-grid {
          margin-top: 0;

          &.needRTL:first-child {
            border-right: 0;
          }
        }
      }
    }

    &-btn {
      font-size: var(--font-size-16);
      padding: var(--v-spacing-lg) 0;
      text-align: center;
      border-top: var(--border-width) solid var(--diviling-line-color);
      background: var(--bg-white);
      color: var(--m-dialog-footer-btn-color);

      &:first-child {
        margin: 0;
      }

      &-last {
        border-bottom-left-radius: var(--m-dialog-circle-radius);
        border-bottom-right-radius: var(--m-dialog-circle-radius);
      }

      &:active {
        background-color: var(--m-btn-default-active-bc);
      }

      &-primary {
        color: var(--m-dialog-footer-btn-primary-color);
      }

      &-warning {
        color: var(--m-dialog-footer-btn-warning-color);
      }

      &-grid {
        border-right: var(--border-width) solid var(--diviling-line-color);
      }
    }
  }

  &-routeLayout {
    height: 100%;
    top: 0;
    bottom: 0;
    z-index: var(--dialog-route-layout-zindex);
    overflow: hidden;
    position: fixed;
    width: 100%;
    background: var(--bg-white);
    animation-duration: var(--dialog-layout-animation-time);
    -webkit-animation-duration: var(--dialog-layout-animation-time);
    -o-animation-duration: var(--dialog-layout-animation-time);
    -moz-animation-duration: var(--dialog-layout-animation-time);

    &-fadeIn {
      animation-name: MDialogRightFadeIn;
      -webkit-animation-name: MDialogRightFadeIn;
      -o-animation-name: MDialogRightFadeIn;
      -moz-animation-name: MDialogRightFadeIn;
    }

    &-fadeOut {
      // animation-name: MDialogRightFadeOut;
      // -webkit-animation-name: MDialogRightFadeOut;
      // -o-animation-name: MDialogRightFadeOut;
      // -moz-animation-name: MDialogRightFadeOut;
      // right: -100%;
      opacity: 0;
      transition: opacity 300ms ease-in;
    }
  }
}

.@{mButtonGroupClsPrefix} {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  background-color: var(--bg-white);
  box-shadow: var(--box-shadow);

  &-btn {
    flex: 1;
    text-align: center;
    line-height: var(--m-dialog-button-group-height);
    height: var(--m-dialog-button-group-height);
    position: relative;
    font-weight: var(--font-weight-base);
    color: var(--secondary-fc);
    font-size: var(--font-size-17);

    &>span:after {
      content: "|";
      position: absolute;
      right: calc(-1 * var(--hd));
      color: var(--m-dialog-button-group-line-color);
    }

    &:last-child>span:after {
      content: "";
    }

    &-primary {
      color: var(--m-dialog-button-color-primary);
    }

    &-success {
      color: var(--m-dialog-button-color-success);
    }

    &-warning {
      color: var(--m-dialog-button-color-warning);
    }

    &-danger {
      color: var(--m-dialog-button-color-danger);
    }

    &-link {
      color: var(--m-dialog-button-color-link);
    }

    &-disabled {
      span {
        opacity: 0.7;
      }
    }

    &.needRTL {
      &>span:after {
        content: '';
      }

      &:after {
        content: "|";
        position: absolute;
        left: 0;
        color: var(--m-dialog-button-group-line-color);
      }

      &:first-child {
        content: '';
      }
    }

    &-inner {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-inner-Mobile {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: 1.2em;
      max-height: 2.4em;

      &.text-ellipsis {
        font-size: calc(14 * var(--hd));
      }
    }
  }
}

@keyframes MDialogFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes MDialogFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes MDialogBottomFadeIn {
  0% {
    opacity: 0;
    bottom: -100%;
  }

  100% {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes MDialogBottomFadeOut {
  0% {
    opacity: 1;
    bottom: 0;
  }

  100% {
    opacity: 0;
    bottom: -100%;
  }
}

@keyframes MDialogTopFadeIn {
  0% {
    opacity: 0;
    top: -100%;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes MDialogTopFadeOut {
  0% {
    opacity: 1;
    top: 0;
  }

  100% {
    opacity: 0;
    top: -100%;
  }
}

@keyframes MDialogLeftFadeIn {
  0% {
    opacity: 0;
    left: -100%;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes MDialogLeftFadeOut {
  0% {
    opacity: 1;
    left: 0;
  }

  100% {
    opacity: 0;
    left: -100%;
  }
}

@keyframes MDialogRightFadeIn {
  0% {
    opacity: 0;
    right: -100%;
  }

  100% {
    opacity: 1;
    right: 0;
  }
}

@keyframes MDialogRightFadeOut {
  0% {
    opacity: 1;
    right: 0;
  }

  100% {
    opacity: 0;
    right: -100%;
  }
}