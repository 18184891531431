.@{dateQuickFilterClsPrefix} {
  * {
    box-sizing: border-box;
  }

  width: 100%;
  min-width: calc(100 * var(--hd));
  max-width: calc(280 * var(--hd));
  display: inline-block;
  box-sizing: border-box;
  font-size: var(--font-size-12);
}

.@{dateQuickFilterClsPrefix}-disabled {
  cursor: not-allowed;
  color: var(--secondary-fc);
  background-color: var(--label-bc);

  .@{dateQuickFilterClsPrefix}-wrap {
    cursor: not-allowed;

    .dateLabel,
    .button-left,
    .button-right {
      color: var(--secondary-fc);
      cursor: not-allowed;

      &:hover {
        color: var(--secondary-fc);
      }
    }
  }
}

.@{dateQuickFilterClsPrefix}-readOnly {
  color: var(--main-fc);
  display: inline-flex;
  align-items: center;
  background: none;
  height: calc(30 * var(--hd));
  min-width: unset;
  width: 100%;

  .date-span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.@{dateQuickFilterClsPrefix}-readOnly-readOnlyLineHeightStandard {
  .form-pc-readOnlyLineHeightStandard
}

.@{dateQuickFilterClsPrefix}-wrap {
  display: inline-block;
  height: calc(30 * var(--hd));
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--black-fc);

  .button-left,
  .button-right {
    display: flex;
    align-items: center;
    justify-content: center;
    border: calc(1 * var(--hd)) solid var(--border-color);
    background: var(--bg-transparent);
    cursor: pointer;
    outline: none;

    &:hover {
      color: var(--primary);
    }
  }

  .dateLabel {
    flex: 1 1 auto;
    // flex-grow: 1;
    // flex-shrink: 1;
    // flex-basis: 0%;
    //flex:1 = flex:1 1 0 但ie11会有兼容问题拆开写先
    min-width: 0;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 calc(10 * var(--hd));
    cursor: pointer;

    &:hover {
      color: var(--primary);
    }

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .isPlaceholder {
    color: var(--placeholder-fc);
  }
}

.@{dateQuickFilterClsPrefix}-weekPanel {
  width: calc(280 * var(--hd));
  font-size: var(--font-size-12);
  display: flex;
  flex-direction: column;
  outline: none;
  z-index: 999;
  background: var(--bg-white);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius-xs);
  box-shadow: var(--box-shadow);
  margin-top: calc(5 * var(--hd));
}