.@{cardDetailFormClsPrefix}, .@{mCardDetailFormClsPrefix} {
  &-title {
    padding-bottom: var(--h-spacing-lg);
  }
  &-title-avatar {
    width: var(--card-detail-form-title-avatar-size);
    height: var(--card-detail-form-title-avatar-size);
  }
  &-title-avatar-rtl {
    transform: scaleX(-1);
  }
  &-title-input {
    display: inline-block;
    width: calc(100% - var(--card-detail-form-title-avatar-size) - var(--border-width) - var(--border-width));
    line-height: var(--card-detail-form-title-avatar-size);
    padding-left: var(--h-spacing-md);
    .@{inputClsPrefix}, .@{textareaClsPrefix} {
      background-color: var(--bg-transparent);
      border-color: transparent; 
      &::placeholder {
        color: var(--secondary-fc);
      }
      &:hover {
        border-color: var(--input-hover-border-color);
      }
      &:focus {
        background-color: var(--card-detail-form-title-input-bgColor);
        border-color: var(--input-focus-border-color);
      }
    }
    .@{textareaClsPrefix} {
      max-width: 100%;
      resize: none;
      margin-top: calc(3 * var(--hd));
    }
  }

  &-title-textarea {
    height: var(--card-detail-form-title-avatar-size);
  }
 
  &-form, &-top {
    margin-bottom: var(--v-spacing-lg);
    &-alwaysShow, &-collapsible {
      margin-bottom: 0;
    }
    &-alwaysShow, &-collapsible {
      .@{formClsPrefix}-row:last-child {
        border-bottom: 0;
      }
    }
    &-follow {
      margin-top: var(--v-spacing-lg);
      margin-bottom: 0;
      &:empty {
        margin-top: 0;
      }
    }
    .@{formItemClsPrefix}-singleItem {
      display: inline-block;
      vertical-align: middle;
      .@{avatarClsPrefix} {
        width: calc(25 * var(--hd));
        height: calc(25 * var(--hd));
        vertical-align: text-bottom;
      }
    }
    .@{formItemClsPrefix}-wrapper {
      color: var(--main-fc);
    }
  }

  .@{formClsPrefix}-col {
    min-height: 0;
  }

  &-tags {
    border-top: var(--form-item-border-module);
    font-size: var(--font-size-12);
    background-color: var(--bg-white);
    color: var(--secondary-fc);
    user-select: none;
    &-search {
      padding: var(--v-spacing-sm) var(--h-spacing-lg);
      border-left: var(--border-solid);
      border-right: var(--border-solid);
      border-bottom: var(--border-solid);
      .@{inputClsPrefix}-wrap {
        max-width: calc(200 * var(--hd));
      }
    }
    &-row {
      display: flex; 
      border-bottom: var(--form-item-border-module);
      border-right: var(--border-solid);
      &:last-child {
        border-bottom: 0;
      }
    }
    &:empty {
      border-top: 0;
    }
    &-single {
      cursor: pointer;
      text-align: center;
      padding: calc(12 * var(--hd)) var(--h-spacing-lg);
      border-left: var(--form-item-border-module);
      border-right: var(--form-item-border-module);
      border-right-color: transparent;
      flex: 1;
      display: flex;
      align-items: center;

      &:first-child {
        border-left: var(--border-solid);
      }
      &:hover {
        background-color: var(--label-bc);
      }
      &-content {
        padding-left: var(--h-spacing-md);
        vertical-align: middle;
        text-align: left;
        &.needRTL {
          padding-right: var(--h-spacing-md);
          padding-left: 0;
        }
      }
      &:empty {
        border-left-color: transparent;
        &:hover {
          background-color: var(--bg-transparent);
          cursor: unset;
        }
      }
      &-needRightBorder + & {
        border-left: var(--form-item-border-module);
      }
    }
    &-footer {
      padding: var(--v-spacing-sm) 0;
      cursor: pointer;
      border: var(--form-item-border-module);
      background-color: var(--bg-white);
      text-align: center;
      font-size: var(--font-size-12);
      color: var(--card-detail-more-fc);
      &-hideTagFooter {
        border-bottom: var(--form-item-border-module);
      }
      &-hide {
        border-top: 0;
      }
      &-icon {
        margin-left: var(--h-spacing-md);
      }
      & > span, &-icon {
        vertical-align: middle;
      }
      &-mobile {
        color: var(--primary);
        border-left: 0;
        border-right: 0;
        position: relative;
        .@{cardDetailFormClsPrefix}-footer-icon {
          color: var(--primary);
        }
        &-inner {
          .@{cardDetailFormClsPrefix}-footer-icon {
            // color: #888888;
          }
          // color: #888888;
          // border-bottom: 0;
        }
      }
      &-validateTip {
        color: var(--form-item-required);
      }
      &-validateIcon {
        color: var(--form-item-required);
        position: absolute;
        right: var(--h-spacing-lg);
        top: calc(13 * var(--hd));
      }
    }
  }

  /* ------------------ 分类 ------------------ */
  &-tags-group {
    // Menu 的外层宽度与Menu需保持一致，避免影响计算
    &-container {
      padding: 0 var(--h-spacing-lg);
      border: var(--border-solid);
      border-top: 0;
    }
    .@{menuClsPrefix}-list-item-active .@{menuClsPrefix}-list-item-content {
      color: var(--primary);
    }
  }
  &-tags-empty {
    padding: calc(12 * var(--hd)) var(--h-spacing-lg);
    text-align: center;
    border-left: var(--border-solid);
    border-right: var(--border-solid);
    &-m {
      text-align: center;
      color: var(--secondary-fc);
      padding: calc(15 * var(--hd)) 0;
    }
  }
  &-form-groupList {
    margin-bottom: 0;
  }
  /* ----------------------------------------- */

  // 紧急程度样式
  &-urgency {
    &-content {
      font-size: var(--font-size-14);
      color: var(--main-fc);
      vertical-align: middle;
      line-height: var(--form-item-line-height);
    }
    &-icon {
      width: calc(15 * var(--hd));
      height: calc(15 * var(--hd));
      border-radius: 50%;
      background-color: #f1f1f1;
      vertical-align: middle;
      display: inline-block;
      margin-right: var(--h-spacing-sm);
      &-urgent {
        background-color: #ffb131;
      }
      &-veryUrgent {
        background-color: #e20001;
      }
    }
    &-options {
      background-color: var(--bg-white);
      padding: var(--v-spacing-sm) 0;
      border: var(--form-item-border-module);
      box-shadow: var(--box-shadow-storke);
      cursor: pointer;
      .@{cardDetailFormClsPrefix}-urgency {
        padding: 0 var(--h-spacing-lg);
        &:hover {
          background-color: #4eade8;
          .@{cardDetailFormClsPrefix}-urgency-content {
            color: var(--base-white);
          }
        }
      }
    }
  }

  &-label {
    .@{formItemClsPrefix}-label {
      color: var(--card-detail-more-fc);
    }
  }
}
.@{cardDetailFormClsPrefix} {
  &-template-del {
    // text-decoration: line-through;
    color: var(--secondary-fc);
  }
  &-template-delTip {
    color: var(--form-item-required);
  }
  &-alwaysShow-borderBottom {
    .@{formClsPrefix}-row:last-child {
      border-bottom: var(--form-item-border-module);
    }
  }
  &-free-tip {
    color: #fb6e52;
    font-size: var(--font-size-12);
    font-style: italic;
    line-height: calc(30 * var(--hd));
  }
  &-tags-footer {
    border-left: var(--border-solid);
    border-right: var(--border-solid);
    border-bottom: var(--border-solid);
    &-readonly {
      border-bottom: var(--border-solid);
    }
  }
  &-form-normal-empty {
    margin-bottom: 0;
  }
  &-form-collapsible {
    .@{formClsPrefix}-row-first {
      border-top: var(--diviling-line-solid);
    }
  }
  
  &-form-alwaysShow-empty ~ .@{cardDetailFormClsPrefix}-tags-footer-hidden,
    &-form-alwaysShow-empty ~ .@{cardDetailFormClsPrefix}-collapse .@{formClsPrefix}-row-first {
    border-top: var(--border-solid);
  }
}
.@{mCardDetailFormClsPrefix} {
  &-free-tip {
    text-align: center;
    color: var(--secondary-fc);
    margin-top: calc(76 * var(--hd));
  }
  &-free-field.is-empty {
    display: none;
  }
  .@{formItemClsPrefix} {
    &-mobile {
      padding-top: 0;
      padding-bottom: 0;
      transition: height 0.3s ease-in-out 0s;
      .border-solid-then;
    }
  }
  .@{formItemClsPrefix}-noLabel {
    .@{mBrowserClsPrefix}-associative-inner .@{formItemClsPrefix}-item {
      align-items: center;
    }
  }

  &-collapsible {
    .@{formItemClsPrefix}-mobile {
      border-bottom: 0;
    }
    &-needBorderTop {
      .border-top-solid-then;
    }
  }
  &-form-mobile {
    .border-top-solid-then;
    &-normal {
      &::before {
        background-color: var(--bg-transparent);
      }
    }
    .@{formClsPrefix}-row {
      border-bottom: 0;
      &::after {
        background-color: var(--bg-transparent);
      }
    }
    &:empty {
      border: 0;
      &::before {
        background-color: var(--bg-transparent);
      }
      &::after {
        background-color: var(--bg-transparent);
      }
    }
  }
  &-tags-footer {
    font-size: var(--font-size-14);
    line-height: calc(44 * var(--hd));
    height: calc(44 * var(--hd));
    vertical-align: middle;
    padding: 0;
    border: 0;
    .border-solid-then;
    &-hideBorderTop {
      border-top: 0;
      &::before {
        background-color: var(--bg-transparent);
      }
    }
  }
  .@{formItemClsPrefix}-item-label {
    font-size: var(--font-size-md);
    color: var(--regular-fc);
    font-weight: var(--font-weight-base);
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
    word-break: break-word;
    line-height: unset;
  }
  .@{formItemClsPrefix}-noLabel {
    background-color: var(--bg-white);
    .@{formItemClsPrefix}-wrapper {
      min-height: calc(var(--m-form-item-line-height) - calc(13 * var(--hd)));
      .@{formSwitchClsPrefix}-BROWSER, .@{formSwitchClsPrefix}-TYPESBROWSER {
        .@{mBrowserClsPrefix}-associative-inner.is-placeholder .@{formItemClsPrefix}-item-placeholder {
          justify-content: flex-end;
        }
        .@{mBrowserClsPrefix}-associative.is-readonly .@{mBrowserClsPrefix}-associative-selected .@{mListClsPrefix}-column .@{mListClsPrefix}-body {
          text-align: right;
        }
      }
    }
    .@{mUploadClsPrefix}-detail-select {
      padding: 0;
      border: 0;
    }
    .@{mUploadClsPrefix}-listT {
      border-top: 0;
    }
    .@{formItemClsPrefix}-requiredmark-mobile {
      display: none;
    }
    .@{mBrowserClsPrefix} .@{formItemClsPrefix}-requiredmark-mobile {
      display: inline-block;
    }
    .@{formSwitchClsPrefix}-BROWSER .@{formSwitchClsPrefix}-requiredMark-container-mobile > div {
      width: 100%;
    }
  }
  &-form-tags {
    .@{formClsPrefix}-row:last-child {
      border-bottom: 0;
      &::after {
        background-color: var(--bg-transparent);
      }
    }
  }
  &-common-upload {
    .@{mUploadClsPrefix}-select-lebel {
      padding-left: 0;
      padding-right: 0;
    }
    &.needRTL {
      .@{mUploadClsPrefix}-select-label .@{mUploadClsPrefix}-row .@{layoutClsPrefix}-col:first-child {
        text-align: right;
      }
    }
  }
  &-form-mobile-title {
    color: var(--regular-fc);
    padding: calc(21 * var(--hd)) 0 calc(9 * var(--hd)) var(--h-spacing-lg);
  }
  &-search {
    padding: var(--v-spacing-sm) var(--h-spacing-lg);
    background-color: var(--bg-white);
  }
}

@import "../link-form-data-template/style/index.less";
@import "../loading/style/index.less";

/* 镜像 */
// .@{mCardDetailFormClsPrefix}-form.needRTL {
//   .@{formItemClsPrefix}-wrapper.needRTL .@{formSwitchClsPrefix}-BROWSER {
//     .@{mBrowserClsPrefix}-associative-count {
//       .mirror-text-re;
//     }
//   }
// }