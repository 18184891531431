.@{menuClsPrefix}-select {
  display: inline-block;

  &-cover {
    position: relative;
    display: inline-block;
    &-children {
      display: inline-block;
      border: var(--border-solid);
      border-radius: var(--border-radius-xs);
      cursor: pointer;
    }

    &-content {
      box-shadow: unset;
      border: var(--border-solid);
    }

    &-outer {
      z-index: calc(var(--popup-zindex) + 2); // fix: 兼容safari样式问题
      .@{menuClsPrefix}-select-cover-children {
        background-color: var(--bg-white);
        color: var(--regular-fc);
        border: var(--border-solid);
      }

      .@{menuClsPrefix}-select-iconOverlay {
        // fix: 图标抖动
        border: 0;
      }
    }
  }

  &-cover-noboder{
    >.@{menuClsPrefix}-select-cover-children {
      border-color: transparent;
    }
  }

  &-cover-container {
    position: relative;
    font-size: var(--font-size-12);
    // margin-top: calc(-1 * var(--border-width)); // fix: 图标抖动

    &-bindTriggerContainer {
      margin-top: 0;
    }

    .@{menuClsPrefix}-select-cover-content {
      box-shadow: var(--box-shadow);
    }

    // &-btnOverlay{
    //   .@{menuClsPrefix}-select-title-icon {
    //     /* fix: 图标抖动 */
    //     .@{iconClsPrefix}-svg {
    //       padding-right: var(--hd);
    //     }
    //   }
    // }

    &-active {
      .@{menuClsPrefix}-select-iconOverlay {
        .@{iconClsPrefix} {
          color: var(--primary);
        }
      }
    }

    &-topLeft,
    &-topRight,
    &-top {
      display: flex;
      flex-direction: column-reverse;
      .@{menuClsPrefix}-select-cover-content {
        margin-bottom: calc(-2 * var(--hd));
      }

      .@{menuClsPrefix}-select-cover-outer .@{menuClsPrefix}-select-cover-children {
        border-top: 0;
        vertical-align: top;
        border-radius: 0;
        border-bottom-left-radius: calc(4 * var(--hd));
        border-bottom-right-radius: calc(4 * var(--hd));
      }
    }

    &-topRight {
      .@{menuClsPrefix}-select-cover-outer {
        text-align: right;
      }

      .@{menuClsPrefix}-select-cover-content {
        border-bottom-right-radius: 0;
      }
      &-rtl {
        .@{menuClsPrefix}-select-cover-outer {
          text-align: left;
        }
      }
    }

    &-topLeft {
      .@{menuClsPrefix}-select-cover-content {
        border-bottom-left-radius: 0;
      }
    }

    &-bottomLeft,
    &-bottomRight,
    &-bottom {
      .@{menuClsPrefix}-select-cover-content {
        margin-top: calc(-1 * var(--hd));
      }

      .@{menuClsPrefix}-select-cover-outer .@{menuClsPrefix}-select-cover-children {
        border-bottom: 0;
        vertical-align: top;
        border-radius: 0;
        border-top-left-radius: calc(4 * var(--hd));
        border-top-right-radius: calc(4 * var(--hd));
      }
    }

    &-bottomRight {
      .@{menuClsPrefix}-select-cover-outer {
        text-align: right;
      }

      .@{menuClsPrefix}-select-cover-content {
        border-top-right-radius: 0;

      }
      // &-rtl {
      //   .@{menuClsPrefix}-select-cover-outer {
      //     text-align: left;
      //   }
      // }
    }

    &-bottomLeft {
      .@{menuClsPrefix}-select-cover-content {
        border-top-left-radius: 0;
      }
    }

    &-rightTop {
      &-rtl {
        .@{menuClsPrefix}-select-cover-outer {
          text-align: right;
        }
      }
    }
  }

  &-title {
    height: var(--menu-select-top-height);
    display: flex;
    padding: 0 calc(10 * var(--hd));
    box-sizing: border-box;
    cursor: pointer;

    &-hover {
      .@{menuClsPrefix}-select-title-text {
        color: var(--main-fc);
      }
    }

    &-text {
      display: inline-block;
      line-height: var(--menu-select-top-height);
      max-width: var(--menu-item-content-width);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: var(--regular-fc);
    }

    &-icon {
      display: flex;
      align-items: center;
      max-width: calc(12 * var(--hd));

      .@{iconClsPrefix} {
        color: var(--secondary-fc);
      }
    }
  }

  &-container {
    width: var(--menu-trigger-width);
    max-height: var(--menu-select-height);
    border: var(--border-width) solid var(--diviling-line-color);
    box-sizing: border-box;

    .@{menuClsPrefix}-list-item-content {
      display: inline-block;
    }

    .@{menuClsPrefix}-list-item {
      padding-left: var(--v-spacing-lg);

      &:hover {
        background-color: var(--menu-item-hover-bc);
      }

      &-active {
        background-color: var(--bg-transparent);
      }
    }

    .@{menuClsPrefix}-list-subitem-title {
      padding-left: var(--v-spacing-lg);

      &:hover {
        background-color: var(--menu-item-hover-bc);
      }
    }
  }

  &-trigger {
    min-width: unset;
    max-width: unset;
    box-shadow: none;
    background-color: var(--bg-transparent);
    padding: 0;
    border-radius: 0;
    border: 0;

    .@{menuClsPrefix}-list {
      min-width: var(--menu-trigger-width-min);
      max-width: var(--menu-trigger-width-max);
      padding: var(--v-spacing-md) 0;
      font-size: var(--font-size-12);
      color: var(--main-fc);
      background-color: var(--bg-white);
    }

    &-bottom,
    &-bottomRight,
    &-bottomLeft {
      >div{
        padding-top: var(--v-spacing-xs);
      }
    }

    &-top,
    &-topLeft,
    &-topRight {
      .@{menuClsPrefix}-select-trigger {
        margin-bottom: var(--v-spacing-xs);
      }
    }

    &-left,
    &-leftBottom,
    &-leftTop {
      .@{menuClsPrefix}-select-trigger {
        margin-right: var(--h-spacing-xs);
      }
    }

    &-right,
    &-rightBottom,
    &-rightTop {
      .@{menuClsPrefix}-select-trigger {
        margin-left: var(--h-spacing-xs);
      }
    }

    &-normal{
      border-radius: var(--border-radius-xs);
      box-shadow: var(--box-shadow);
      border: var(--border-width) solid var(--diviling-line-color);
    }
  }

  .Icon-Right-arrow04,
  .Icon-Down-arrow04 {
    width: calc(16 * var(--hd));
    height: calc(16 * var(--hd));
  }

  // slectType === 'iconOverlay
  &-iconOverlay {
    display: inline-block;
    .@{iconClsPrefix} {
      color: var(--secondary-fc);
      padding: calc(2 * var(--hd));
    }

    &:hover {
      .@{iconClsPrefix} {
        color: var(--primary);
      }
    }
  }

  // slectType === 'btn
  &-btn {
    display: flex;
    height: calc(30 * var(--hd));
    align-items: center;
    color: var(--base-white);
    // border: var(--border-solid);
    border-radius: var(--border-radius-xs);
    overflow: hidden;

    .@{btnClsPrefix} {
      height: 100%;
      padding: 0 calc(12 * var(--hd));
    }

    &-button {
      display: flex;
      align-items: center;
      // float: left;
      border: 0;
      border-radius: 0;
      padding-top: 0;
      padding-bottom: 0;

      &-icon {
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: var(--h-spacing-xs);

        .@{iconClsPrefix}-svg {
          width: calc(10 * var(--hd));
          height: calc(10 * var(--hd));
        }
      }

    }

    &-more {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary);
      height: 100%;
      width: calc(30 * var(--hd));
      cursor: pointer;

      .@{iconClsPrefix} {
        padding-bottom: calc(3 * var(--hd));
      }
      .@{iconClsPrefix}-svg {
        width: calc(10 * var(--hd));
        height: calc(10 * var(--hd));
      }

      &-hover {
        opacity: var(--btn-hover-opacity);
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        height: 100%;
        width: var(--border-width);
        background-color: var(--base-black);
        opacity: .08;
      }
    }

    // 不同按钮类型样式
    &-default {
      border: var(--border-solid);
      .@{menuClsPrefix}-select-btn-more {
        color: var(--regular-fc);
        background-color: var(--bg-white);
        &-hover {
          color: var(--primary);
          border-color: var(--primary);
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border: 0;
            margin: auto;
            width: 100%;
            height: 100%;
            background-color: var(--primary);
            transition: var(--transition-all);
            opacity: var(--btn-default-hover-opacity);
          }
          &:after {
            background-color: var(--primary);
          }
          .@{iconClsPrefix} {
            color: var(--primary);
          }
        }
      }
    }
    &-success {
      .@{menuClsPrefix}-select-btn-more {
        background-color: var(--success);
      }
    }
    &-warning {
      .@{menuClsPrefix}-select-btn-more {
        background-color: var(--warning);
      }
    }
    &-danger {
      .@{menuClsPrefix}-select-btn-more {
        background-color: var(--danger);
      }
    }
  }
}

// iconOverlay 新版UI
.@{menuClsPrefix}-selectwrap-iconOverlay {
  vertical-align: middle;
  .@{menuClsPrefix}-select-cover {
    display: flex;
    align-items: center;
  }
  .@{menuClsPrefix}-select-cover-children,
  .@{menuClsPrefix}-select-iconOverlay {
    display: inline-flex;
    align-items: center;
  }
  // .@{menuClsPrefix}-select-cover-children {
  //   border: 0;
  // }
}
.@{menuClsPrefix}-selectpopup-iconOverlay {
  .@{menuClsPrefix}-select-cover-children {
    padding: 0;
    margin: 0;
    // border: 0;
    border: var(--border-solid);
    background: transparent;
  }
  .@{menuClsPrefix}-select-iconOverlay {
    border-radius: var(--border-radius-xs);
    border: var(--border-solid);
    background-color: var(--bg-white);
  }

  .@{menuClsPrefix}-select-cover-container-bottomLeft,
  .@{menuClsPrefix}-select-cover-container-bottomRight,
  .@{menuClsPrefix}-select-cover-container-bottom {
    .@{menuClsPrefix}-select-cover-children {
      border: 0;
      padding-bottom: calc(4 * var(--hd));
    }
    .@{menuClsPrefix}-select-cover-content {
      margin-top: 0;
    }
  }

  .@{menuClsPrefix}-select-cover-container-topLeft,
  .@{menuClsPrefix}-select-cover-container-topRight,
  .@{menuClsPrefix}-select-cover-container-top {
    .@{menuClsPrefix}-select-cover-children {
      padding-top: calc(4 * var(--hd));
      border: 0;
    }
    .@{menuClsPrefix}-select-cover-content {
      margin-bottom: 0;
    }
  }
}

// 镜像
.@{menuClsPrefix}-select-rtl {
  // 下拉菜单-按钮组
  .@{menuClsPrefix}-select-btn-button {
    border-top-right-radius: var(--btn-radius);
    border-bottom-right-radius: var(--btn-radius);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .@{menuClsPrefix}-select-btn-button-icon {
    margin-right: 0;
    margin-left: var(--h-spacing-xs);
  }
  .@{menuClsPrefix}-select-btn-default .@{menuClsPrefix}-select-btn-button {
    border-right: var(--border-solid);
    border-left: 0;
  }
}