@borderLineColor: calc(1 * var(--hd)) solid var(--diviling-line-color);
.@{resourcePanelClsPrefix}.no-scroll {
    .resource-table-container {
        width: 100%;
    }
}
.@{resourcePanelClsPrefix} {
    position: relative;
    user-select: none;
    font-size: var(--font-size-12);
    color: var(--main-fc);
    background-color: #FFFFFF;
    .ui-resource-panel-box {
        // border-bottom: @border;
    }
    .ui-resource-panel-box.no-data {
        .table-header {
            border-right: @borderLineColor;
        }
        .resource-table-head th:last-child {
            border-right: 0;
        }
    }
    table {
        width: 100%;
        tr {
            >td, >th {
                text-align: center;
                height: calc(34 * var(--hd));
                box-sizing: content-box;
            }
        }
        thead {
            >tr {
                >th {
                    font-weight: var(--font-weight-base);
                    border-bottom: calc(1 * var(--hd)) solid var(--diviling-line-color);
                    border-right: calc(1 * var(--hd)) solid var(--diviling-line-color);
                }
            }
        }
        tbody {
            >tr {
                >td {
                    border-right: calc(1 * var(--hd)) solid var(--diviling-line-color);
                    border-bottom: calc(1 * var(--hd)) solid var(--diviling-line-color);
                }
            }
        }
    }
    .resource-table-side {
        // border: 1px solid red;
        width: calc(184 * var(--hd));
        flex: 0 0 calc(184 * var(--hd));
        // table {
        //     width: calc(184 * var(--hd));
        //     flex: 0 0 calc(184 * var(--hd));
        // }
        thead {
            >tr {
                >th {
                    border-right: calc(1 * var(--hd)) solid var(--diviling-line-color);
                }
            }
        }
    }
    .resource-table-container {
        display: flex;
        .resource-table-content {
            // border: 1px solid red;
            // width: calc(~'100% -'calc(184 * var(--hd))); // ###########
            // overflow-x: auto;
    
            flex-grow: 1;
            .resource-table {
                .resource-table-head {
                    >tr {
                        .title {
                            display: inline-flex;
                            width: 100%;
                            height: 100%;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                    .scroller {
                        border: 0;
                    }
                }
            }
    
        }
    }
    .table-header {
        display: flex;
        overflow: hidden;
        font-family: Helvetica;
        // font-size: var(--font-size-12);
        color: var(--secondary-fc);
        border-left: calc(1 * var(--hd)) solid var(--diviling-line-color);
        // border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
        .resource-table-side {
            th {
                border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
            }
        }
        .resource-table-content {
            // overflow: hidden; // ##########
            // margin-bottom: calc(-4 * var(--hd));
            .resource-table {
                // margin-bottom: calc(4 * var(--hd));
                .resource-table-head {
                    th {
                      position: relative;
                      border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
                      .time-line-content {
                        position: absolute;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        width: calc(8 * var(--hd));
                        height: calc(8 * var(--hd));
                        bottom: calc(-3.5 * var(--hd));
                        margin-left: calc(-4 * var(--hd));
                        cursor: pointer;
                        z-index: 1;
                        .ui-help {
                          display: inherit;
                        }
                        .time-line {
                          display: inline-block;
                          width: calc(4 * var(--hd));
                          height: calc(4 * var(--hd));
                          border-radius: 100%;
                          background-color: red;
                        }
                      }
                    }
                }
            }
        }
    }
    // .table-header, .table-container {
    //     .resource-table-container {
    //         width: 100%;
    //     }
    // }
    .table-container {
        display: flex;
        overflow-y: auto;
        overflow-x: auto;
        border-left: calc(1 * var(--hd)) solid var(--diviling-line-color);
        
        .resource-table-content {
            // overflow-x: auto;
        }
        .resource-table-body {
            >tr.selectable {
                >td {
                    cursor: not-allowed;
                    .section {
                        td {
                            cursor: not-allowed;
                        }
                    }
                }
                // >td.section-item:hover {
                //     opacity: 1;
                //     background-color: inherit;
                // }
            }
            >tr {
                >td {
                    .section {
                        height: 100%;
                        display: flex;
                        td {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            flex: 1;
                            cursor: pointer;
                            user-select: none;
                        }
                        // td:hover {
                        //     opacity: 0.8;
                        //     background-color: var(--placeholder-fc);
                        // }
                    }
                }
                // >td.section-item:hover {
                //     opacity: 0.8;
                //     background-color: var(--placeholder-fc);
                // }
                .section-item {
                    user-select: none;
                }
                // .item-active {
                //     background-color: var(--info) !important;
                // }
            }
        }
        .no-data {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: calc(34 * var(--hd));
            color: var(--secondary-fc);
            border-bottom: @borderLineColor;
            border-right: @borderLineColor;
        }
    }

    .ui-resource-panel-fixed-sider {
        display: inline-block;
        position: absolute;
        z-index: 1;
        top: 0;
        background-color: #FFFFFF;
        border-top: @borderLineColor;
        border-left: @borderLineColor;
        box-shadow: 6px 0 6px -5px rgb(200 201 204 / 50%);
        .sider-content {
            overflow-y: hidden;
            overflow-x: hidden;
        }
    }
}

.@{resourcePanelClsPrefix}.pc-view {
    .table-container {
        .resource-table-body {
            >tr.selectable {
                >td.section-item:hover {
                    opacity: 1;
                    background-color: inherit;
                }
            }
            >tr {
                >td {
                    .section {
                        td:hover {
                            opacity: 0.8;
                            background-color: var(--placeholder-fc);
                        }
                    }
                }
                >td.section-item:hover {
                    opacity: 0.8;
                    background-color: var(--placeholder-fc);
                }
                .item-active {
                    background-color: var(--primary) !important;
                }
            }
        }
    }
    .ui-resource-panel-box {
        .table-header {
            .resource-table-container {
                width: 100%;
            }
        }
        .table-container {
            .resource-table-container {
                width: 100%;
            }
        }
    }
}

.@{resourcePanelClsPrefix}.mobile-click-locate {
    .table-container {
        .resource-table-body {
            >tr {
                .item-active {
                    background-color: var(--primary) !important;
                }
            }
        }
    }
}

.@{resourcePanelClsPrefix}.mobile-view {
    .table-header, .table-container {
        .resource-table-content {
            width: calc(~'100% -'calc(184 * var(--hd)));
        }
    }
}

.@{resourcePanelClsPrefix}.timeline {
    .table-header {
        .resource-table-content {
            .resource-table {
                margin-bottom: calc(4 * var(--hd));
            }
        }
    }
    .table-container {
        margin-top: calc(-4 * var(--hd));
    }
}

.resource-panel-popver-now-time {
    overflow: hidden;
}