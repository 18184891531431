@class-prefix-tabs: ~'m-cascader-tabs';

.@{class-prefix-tabs} {
  --title-font-size: 17px;
  --content-padding: 12px;
  --active-line-height: 2px;
  --active-line-border-radius: 0;
  position: relative;

  &-header {
    position: relative;
    border-bottom: solid 1px var(--diviling-line-color);
  }

  &-tab-list {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;
  }

  &-tab-wrapper {
    padding: 0 12px;

    &-stretch {
      flex: auto;
    }
  }

  &-tab {
    white-space: nowrap;
    padding: 8px 0 10px;
    width: min-content;
    margin: 0 auto;
    font-size: var(--title-font-size);
    position: relative;
    cursor: pointer;
  }

  &-tab-active {
    color: var(--primary);
    border-bottom: 2px solid var(--primary);
  }

  &-tab-disabled {
    // opacity: 0.5;
    color: var(--invalid);
    cursor: not-allowed;
  }

  // &-tab-line {
  //   position: absolute;
  //   bottom: 0;
  //   height: var(--active-line-height);
  //   color: var(--primary);
  //   background: var(--primary);
  //   border-radius: var(--active-line-border-radius);
  // }
  &-content {
    padding: var(--content-padding);
  }

  // &-header-mask {
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   z-index: 1;
  //   width: 30px;
  //   height: 100%;
  //   pointer-events: none;
  // }
  // &-header-mask-left {
  //   left: 0;
  //   background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
  // }
  // &-header-mask-right {
  //   right: 0;
  //   background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
  // }
}