.@{timePickerClsPrefix} {
  * {
    box-sizing: border-box;
  }

  width: 100%;
  min-width: calc(60 * var(--hd));
  display: inline-block;
  box-sizing: border-box;
  background-color: var(--bg-white);
  color: var(--placeholder-fc);
  font-size: var(--font-size-12);

  &-maxWidth {
    max-width: calc(200 * var(--hd));
  }

  &-rangeMaxWidth {
    max-width: calc(250 * var(--hd));
  }

  &-disabled {
    color: var(--placeholder-fc);
    cursor: not-allowed;

    .@{inputClsPrefix}-wrap,
    .@{timePickerClsPrefix}-rangeWrap {
      background-color: var(--label-bc);
      cursor: not-allowed;
      color: var(--placeholder-fc);
      border-color: var(--border-color);

      &:hover {
        color: var(--placeholder-fc);
        border-color: var(--border-color);
      }
    }

    .@{timePickerClsPrefix}-wrap {

      &-input,
      &-span {
        color: var(--placeholder-fc);
      }
    }
  }

  &-readOnly {
    color: var(--main-fc);
    display: inline-flex;
    align-items: center;
    background: none;
    height: calc(30 * var(--hd));
    min-width: unset;

    .date-span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-readOnly-readOnlyLineHeightStandard {
    .form-pc-readOnlyLineHeightStandard
  }
}

.@{timePickerClsPrefix}-wrap {
  height: calc(30 * var(--hd));
  cursor: pointer;

  input {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-span {
    color: var(--main-fc);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.@{timePickerClsPrefix}-rangeWrap {
  display: flex;
  height: calc(30 * var(--hd));
  border: var(--border-solid);
  border-radius: var(--border-radius-xs);
  padding: 0 calc(8 * var(--hd));
  min-width: calc(100 * var(--hd));

  &:hover {
    border: calc(1 * var(--hd)) solid var(--invalid);
  }

  &-active {
    border: calc(1 * var(--hd)) solid var(--primary);
    box-shadow: 0 0 calc(3 * var(--hd)) 0 var(--primary);
  }

  &-input {
    flex: 1 1 auto;
    // flex: 1 1 auto;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    //flex:1 = flex:1 1 0 但ie11会有兼容问题拆开写先
    border: none;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }

  &-middle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-fc)
  }

  &-suffix {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--secondary-fc);
  }
}

// icon处理
.@{timePickerClsPrefix} {
  &-icon {
    margin-left: 4px;
  }

  .@{timePickerClsPrefix}-wrap,
  .@{timePickerClsPrefix}-rangeWrap {
    .@{timePickerClsPrefix}-clear {
      background: var(--bg-white);
      color: var(--invalid-fc);
      transition: color 0.3s ease, opacity 0.15s ease;

      &:hover {
        color: var(--secondary-fc);
      }

      &-show {
        cursor: pointer;
      }
    }
  }

  .@{timePickerClsPrefix}-wrap {
    .@{timePickerClsPrefix}-clear {
      display: none;
    }
  }

  .@{timePickerClsPrefix}-rangeWrap {
    .@{timePickerClsPrefix}-clear {
      opacity: 0;
    }
  }

  &:hover {
    .@{timePickerClsPrefix}-wrap {
      .@{timePickerClsPrefix}-clear-show {
        display: inline-block;
      }
    }

    .@{timePickerClsPrefix}-rangeWrap {
      .@{timePickerClsPrefix}-clear-show {
        opacity: 1;
      }
    }
  }
}