@import "../../../style/prefix.less";

.@{mCommentClsPrefix}-audio {
  height: var(--m-comment-audio-height);
  width: 100%;
  display: block;
  &-container {
    background-color: var(--m-comment-audio-bgcolor);
    border-radius: 20px;
    display: flex;
    align-items: center;
  }
  &-close {
    color: #979797;
    cursor: pointer;
    margin-right: calc(15 * var(--hd));
  }
  &::-webkit-media-controls-panel {
    // background-color: var(--m-comment-audio-bgcolor);
    // height: var(--m-comment-audio-height);
    // padding: 0 calc(4 * var(--hd));
  }
  &::-webkit-media-controls-play-button {
    // background-color: var(--m-comment-audio-play-color);
    // background-size: calc(14 * var(--hd));
    // border-radius: 50%;
    // flex-basis: calc(20 * var(--hd));
    // height: calc(20 * var(--hd));
    // min-width: calc(0 * var(--hd));
  }
  &::-webkit-media-controls-current-time-display, &::-webkit-media-controls-time-remaining-display {
    color: var(--m-comment-audio-text-color);
    font-size: calc(12 * var(--hd));
  }
  &::-webkit-media-controls-current-time-display {
    margin-left: calc(10 * var(--hd));
  }
  &::-webkit-media-controls-volume-control-container {
    display: none;
  }
  &::-webkit-media-controls-timeline {

  }

}