@import "../panel/style/index.less";
@import "../date-time-picker/style/index.less";
@import "../date-quick-filter/style/index.less";
@import "../time-picker/style/index.less";
@import "../month-day-picker/style/index.less";
@import "../date-menu/style/index.less";
@import "./IE.less";


.@{datePickerClsPrefix} {
  * {
    box-sizing: border-box;
  }

  width: 100%;
  min-width: calc(60 * var(--hd));
  display: inline-block;
  box-sizing: border-box;
  background-color: var(--bg-white);
  color: var(--placeholder-fc);
  font-size: var(--font-size-12);

  &-maxWidth {
    max-width: calc(200 * var(--hd));
  }

  &-rangeMaxWidth {
    max-width: calc(300 * var(--hd));
  }

  &-overflow {
    .@{datePickerClsPrefix}-wrap-input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-disabled {
    color: var(--placeholder-fc);
    cursor: not-allowed;

    .@{inputClsPrefix}-wrap,
    .@{datePickerClsPrefix}-rangeWrap {
      background-color: var(--label-bc);
      cursor: not-allowed;
      color: var(--placeholder-fc);
      border-color: var(--border-color);

      &:hover {
        border-color: var(--border-color);
      }
    }

    .@{datePickerClsPrefix}-wrap {

      &-input,
      &-span {
        color: var(--placeholder-fc);
      }

      &-content {
        color: var(--input-disabled-color);
      }
    }
  }

  &-readOnly {
    color: var(--main-fc);
    display: inline-flex;
    align-items: center;
    background: none;
    height: calc(30 * var(--hd));
    min-width: unset;

    .date-span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-readOnly-readOnlyLineHeightStandard {
    .form-pc-readOnlyLineHeightStandard
  }
}

//选择器样式
.@{datePickerClsPrefix}-wrap {
  height: calc(30 * var(--hd));
  line-height: calc(30 * var(--hd));
  border-radius: var(--border-radius-xs);
  cursor: pointer;

  input {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-content,
  &-placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // height: calc(30 * var(--hd));
    // line-height: calc(30 * var(--hd));
  }

  &-content {
    color: var(--main-fc);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .@{inputClsPrefix}-wrap {
    display: flex;
    height: 100%;
    align-items: center;
  }
}

.@{datePickerClsPrefix}-rangeWrap {
  display: flex;
  height: calc(30 * var(--hd));
  border: var(--border-solid);
  border-radius: var(--border-radius-xs);
  padding-right: calc(8 * var(--hd));
  min-width: calc(100 * var(--hd));

  &:hover {
    border: calc(1 * var(--hd)) solid var(--invalid);
  }

  &-active {
    border: calc(1 * var(--hd)) solid var(--primary);
    box-shadow: 0 0 calc(3 * var(--hd)) 0 var(--primary);
  }

  &-input {
    flex: 1 1 auto;
    // flex: 1 1 auto;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    //flex:1 = flex:1 1 0 但ie11会有兼容问题拆开写先
    border: none;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
      // border-bottom: calc(2 * var(--hd)) solid var(--primary);
      border-radius: 0;
    }
  }

  &-middle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-fc)
  }

  &-suffix {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--secondary-fc);
  }
}

//后缀suffix处理
.@{datePickerClsPrefix} {
  &-icon {
    margin-left: 4px;
  }

  .@{inputClsPrefix}-suffix,
  .@{datePickerClsPrefix}-wrap,
  .@{datePickerClsPrefix}-rangeWrap {
    .@{datePickerClsPrefix}-clear {
      background: var(--bg-white);
      color: var(--invalid-fc);
      transition: color 0.3s ease, opacity 0.15s ease;

      &:hover {
        color: var(--secondary-fc);
      }

      &-show {
        cursor: pointer;
      }
    }
  }

  .@{inputClsPrefix}-suffix,
  .@{datePickerClsPrefix}-wrap {
    .@{datePickerClsPrefix}-clear {
      display: none;
    }
  }

  .@{datePickerClsPrefix}-rangeWrap {
    .@{datePickerClsPrefix}-clear {
      opacity: 0;
    }
  }

  &:hover {

    .@{inputClsPrefix}-suffix,
    .@{datePickerClsPrefix}-wrap {
      .@{datePickerClsPrefix}-clear-show {
        display: inline-block;
      }
    }

    .@{datePickerClsPrefix}-rangeWrap {
      .@{datePickerClsPrefix}-clear-show {
        opacity: 1;
      }
    }
  }
}

/* 镜像翻转 - 重置 */
.mirror-text-re {
  transform: scaleX(1) !important;
  direction: ltr !important;
}

.mirror-text-needLTR {
  direction: ltr !important;
}

/* 镜像翻转 */
.mirror-text {
  transform: scaleX(-1);
  direction: rtl;
}