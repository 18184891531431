.@{sensitiveWordScanClsPrefix}-scanIcon {
  background-color: var(--sensitive-word-scan-icon-bg);
  width: var(--sensitive-word-scan-icon-size);
  height: var(--sensitive-word-scan-icon-size);
  border-radius: var(--border-radius-sm);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* position */
  position: fixed;
  top: calc(100 * var(--hd));
  right: calc(4 * var(--hd));
}

/* ---------------------- 高亮 ---------------------- */
.@{sensitiveWordScanClsPrefix}-highlight-container {
  position: relative;
  &-textarea {
    /* 多行文本默认样式，非占据整行效果，高亮容器需同步调整，以免影响多个字段放在同一行的效果 */
    display: inline-block;
  }
}
.@{sensitiveWordScanClsPrefix}-highlight {
  color: transparent;
  overflow: auto;
  &-bg {
    background: var(--highlight-bc);
  }
  &-fc {
    color: var(--highlight-bc);
  }
  &-input, &-textarea {
    color: fieldtext;
  }
  &-input {
    overflow: hidden;
    word-break: keep-all;
    height: calc(30 * var(--hd));
    &-inner {
      height: calc(20 * var(--hd));
      display: inline-block;
      &-prefix, &-suffix   {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        display: inline-block;
        height: 100%;
        background-color: var(--bg-white);
      }
      &-prefix {
        left: 0;
      }
      &-suffix {
        right: 0;
      }
    }
  }
  &-textarea {
    display: inline-block;
  }
  &-origin {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--bg-transparent);
    z-index: 2;
    .@{inputClsPrefix}, .@{inputClsPrefix}-wrap, .@{textareaClsPrefix} {
      background: var(--bg-transparent);
      color: transparent;
    }
    .@{inputClsPrefix}-prefix {
      color: var(--regular-fc);
    }
  }
  /* 只读 */
  &-readOnly.is-readonly {
    display: inline-block;
    color: var(--regular-fc);
    height: auto;
  }
  &-readOnly.is-readonly.resizable {
    resize: none;
  }
  &-prefix .@{sensitiveWordScanClsPrefix}-highlight-input-inner {
    margin-left: var(--h-spacing-sm);
  }
  &-prefix.is-disabled {
    .@{sensitiveWordScanClsPrefix}-highlight-input-inner-prefix, .@{sensitiveWordScanClsPrefix}-highlight-input-inner-suffix {
      background-color: var(--input-disabled-bg);
      &-readOnly {
        background-color: var(--bg-transparent);
      }
    }
  }
}
.@{sensitiveWordScanClsPrefix}-highlight-input-inner-prefix, .@{sensitiveWordScanClsPrefix}-highlight-input-inner-suffix {
  &-readOnly {
    background-color: var(--bg-transparent);
  }
}
.@{sensitiveWordScanClsPrefix}-highlight.@{textareaClsPrefix} {
  min-height: var(--input-height);
}

/* ---------------------- 弹框内容区 ---------------------- */

.@{sensitiveWordScanClsPrefix}-content {
  /* Trigger弹层模式 */
  &.@{sensitiveWordScanClsPrefix}-trigger {
    & > div {
      width: calc(400 * var(--hd));
      min-height: calc(550 * var(--hd));
      background-color: var(--base-white);
      box-shadow: var(--dialog-box-shadow);
      margin-right: calc(10 * var(--hd));
    }
  }
  /* 图标拖拽+Trigger模式*/
  &.@{sensitiveWordScanClsPrefix}-trigger-daggable {
    .@{popoverClsPrefix} {
      padding: 0;
    }
    .@{popoverClsPrefix}-tooltip, .@{popoverClsPrefix}-content {
      background-color: var(--base-white);
      width: 100%;
      max-width: unset;
      height: 100%;
      box-shadow: none;
      padding: 0;
    }
  }

  .@{dialogClsPrefix}-body {
    padding-left: 0;
    padding-right: 0;
  }
  .@{titleClsPrefix}-inDialog {
    background-color: var(--diviling-line-color);
    border-bottom: 0;
    .@{titleClsPrefix}-icon {
      border: 0;
      .Icon-Sensitive-words {
        width: calc(24 * var(--hd));
        height: calc(24 * var(--hd));
      }
    }
  }
  /* Dialog顶部 */
  &-top {
    padding-top: var(--v-spacing-lg);
  }
  &-title {
    color: var(--sensitive-word-content-title-color);
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-base);
    padding-right: var(--v-spacing-md);
    &.needRTL {
      padding-right: 0;
      padding-left: var(--v-spacing-md);
    }
  }
  &-title-tip {
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-base);
    color: var(--secondary-fc);
  }
  /* 弹框内容 */
  .@{dialogClsPrefix}-body-container {
    background-color: var(--bg-white);
  }
  .@{dialogClsPrefix}-body-right {
    height: 100%;
  }

  &-top, &-total, &-tip, &-btn, &-loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-total {
    font-size: var(--font-size-14);
    color: var(--sensitive-word-content-title-color);
    padding-top: var(--v-spacing-md);
    &-inner {
      color: var(--sensitive-word-content-total-color);
      font-size: var(--font-size-16);
      padding: 0 var(--h-spacing-sm);
    }
    &:hover {
      cursor: pointer;
      color: var(--primary);
      .@{sensitiveWordScanClsPrefix}-content-total-inner {
        cursor: pointer;
        color: var(--primary);
      }
    }
  }
  &-tip {
    color: var(--secondary-fc);
    padding-top: var(--v-spacing-xs);
    font-size: var(--font-size-12);
    &-empty {
      color: var(--sensitive-word-content-tip-empty);
    }
  }
  &-btn {
    padding-top: calc(10 * var(--hd));
    padding-bottom: calc(13 * var(--hd));
  }
  &-loading {
    margin-top: var(--v-spacing-lg);
    .@{emptyClsPrefix}-title {
      color: var(--sensitive-word-content-loading-color);
      margin-top: var(--v-spacing-lg);
      font-size: var(--font-size-14);
    }
  }
  &-table {
    /* ------------------- 列表整体样式处理 ------------------- */
    margin: 0 var(--h-spacing-lg);
    border: 0;
    th, td {
      color: var(--sensitive-word-content-title-color);
    }
    tr {
      cursor: pointer;
    }
    .@{tableClsPrefix}-grid-tbody .@{tableClsPrefix}-grid-tr:last-child:not(.@{tableClsPrefix}-grid-tr__expanded) .@{tableClsPrefix}-grid-td {
      border-bottom: var(--border-solid);
      border-color: var(--diviling-line-color);
    }
    /* ------------------- 列表展开样式 --------------------- */
    .@{tableClsPrefix}-grid-th-expand {
      text-align: right;
      padding-right: var(--h-spacing-lg);
    }
    .@{tableClsPrefix}-grid-tr__expanded td {
      background-color: var(--bg-white);
      border-bottom: var(--border-solid);
      border-color: var(--diviling-line-color);
      padding: 0;
    }
    .@{tableClsPrefix}-grid-expandable-btn-switcher-custom {
      color: var(--secondary-fc);
    }
    .@{sensitiveWordScanClsPrefix}-expand-column {
      display: flex;
      align-items: center;
      justify-content: right;
      padding-right: var(--h-spacing-lg);
    }
    .@{sensitiveWordScanClsPrefix}-expand-frequency {
      padding-right: var(--h-spacing-sm);
      text-align: center;
    }
    /* ------------------- 子列表整体样式处理 ------------------- */
    .@{sensitiveWordScanClsPrefix}-expandRender {
      padding: var(--v-spacing-xs) 0;
      display: flex;
      flex-wrap: wrap;
      &-single {
        margin: var(--v-spacing-xs) var(--h-spacing-xs) var(--v-spacing-xs) 0;
        background-color: var(--sensitive-word-content-expandRender-single-background-color);
        padding: var(--v-spacing-md) var(--h-spacing-lg);
        border-radius: var(--border-radius-lg);
        color: var(--regular-fc);
        cursor: pointer;
        &-active {
          background-color: var(--sensitive-word-content-expandRender-single-active-background-color);
        }
      }
    }
  }
}

.@{sensitiveWordScanClsPrefix}-trigger-closeIcon {
  cursor: pointer;
  display: inline-block;
  top: calc(var(--hd)*3);
  vertical-align: middle;
  padding: calc(var(--hd)*6);
  margin-left: var(--h-spacing-md);
  color: var(--secondary-fc);
  &:hover {
    background-color: var(--dialog-icon-background);
    border-radius: var(--border-radius-xs);
  }
}

/* ---------------------- 顶部标题区---------------------- */
.@{sensitiveWordScanClsPrefix}-title .@{titleClsPrefix}-icon{
  border: 0;
  .Icon-Sensitive-words {
    width: calc(24 * var(--hd));
    height: calc(24 * var(--hd));
  }
}

.@{sensitiveWordScanClsPrefix}-title.needRTL:not(.@{titleClsPrefix}-noIcon) .@{titleClsPrefix}-title-top {
  padding-left: var(--h-spacing-md);
  padding-right: 0;
}

/* ---------------------- 布局区 ---------------------- */
.@{sensitiveWordScanClsPrefix}-layout {
  height: 100%;
  &-left, &-right {
    overflow: hidden;
    background-color: var(--bg-white);
    border-right: var(--border-solid);
    &-hidden {
      border-right: 0;
    }
  }
  &-right {
    border-left: var(--border-solid);
    border-right: 0;
  }
  &-scan-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
  &-scan-content {
    height: 100%;
    overflow: auto;
  }
}