.@{transferClsPrefix} {
  height: 100%;
  width: 100%;

  .@{layoutClsPrefix}-row {
    height: 100%;
  }

  &-wrap {
    max-height: var(--transfer-max-height);
    position: relative;
    width: 100%;
    height: 100%;
    border: var(--hd) solid var(--transfer-border-color);
    display: inline-flex;
    background: var(--bg-white);

    .@{transferClsPrefix}-line {
      position: absolute;
      width: var(--hd);
      right: 50%;
      top: 0;
      bottom: 0;
      background-color: var(--transfer-border-color);
    }
  }

  &-list-wrap {
    padding-top: calc(10 * var(--hd));
    height: 100%;

    .@{transferClsPrefix}-list {
      .@{listClsPrefix}-content{
        cursor: pointer;
        &:hover {
          background: var(--transfer-hover-color);
        }
      }
    }

    //checkbox样式复写为圆角
    .@{checkboxClsPrefix} {
      &-inner {
        border-radius: 50%;
        width: var(--transfer-checkbox-size);
        height: var(--transfer-checkbox-size);
      }
    }

    .@{transferClsPrefix}-search {
      margin: 0 var(--transfer-space-16) calc(10 * var(--hd)) var(--transfer-space-16);
    }

    .@{transferClsPrefix}-check-all {
      cursor: pointer;
      padding: calc(12* var(--hd)) calc(16* var(--hd));
      padding-right: 0;

      &:hover {
        background: var(--transfer-hover-color);
      }

      &::after {
        content: '';
        height: 0;
        line-height: 0;
        display: block;
        clear: both;
        visibility: hidden;
      }

      &-checkbox {
        float: right;
        margin-right: 0;
        padding-right: var(--transfer-space-16);

        .@{checkboxClsPrefix} {
          margin-right: 0;
        }
      }
    }

    .@{transferClsPrefix}-empty {
      width: 100%;
      height: calc(100% - var(--hd) * 80);
      padding:10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .@{listClsPrefix}-scrollview-wrap {
      height: calc(100% - var(--hd) * 80) !important;
    }
  }

  &-list-wrap-right {
    position: relative;
    height: 100%;

    .@{transferClsPrefix}-list {
      .@{listClsPrefix}-content {
        align-items: unset;
      }
    }

    .@{listClsPrefix}-scrollview-wrap {
      margin-bottom: calc(30 * var(--hd));
    }
  }

  &-list-item {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: calc(10 * var(--hd));
    padding-left: var(--transfer-space-16);

    &:hover &-icon {
      visibility: visible;
    }

    &-content {}

    &-icon {

      visibility: hidden;
      cursor: pointer;

      &:hover {
        visibility: visible;
      }

      .@{iconClsPrefix} {
        color: var(--invalid-fc);
      }
    }

    &-options{
      background-color: var(--bg-base);
    }
  }

  &-footer {
    width: 100%;
    position: absolute;
    bottom: calc(10 * var(--hd));
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-12);
    padding: 0 var(--transfer-space-16);
    margin-top: calc(20 * var(--hd));

    &-selected {
      color: var(--secondary-fc);
    }

    &-remove {
      color: var(--primary);
      cursor: pointer;
      user-select: none;
    }
  }
}