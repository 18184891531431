@import "./index_column.less";

.@{hrmCardClsPrefix} {

  /* ------------------------通用--------------------------------------    */
  &-popup {
    animation: hrmCardSlideUpIn 0.5s;
    position: absolute;
    z-index: var(--popover-zindex);
  }

  &-popup-rtl {
    left: unset !important;
  }

  &-popup-rtl {
    left: unset !important;
  }

  &-layout {
    &-content {
      height: 100%;

      .@{spinClsPrefix}-container,
      .@{scrollerClsPrefix} {
        height: 100%;
      }
    }
  }

  &-loading-panel {
    height: 100%;
  }

  &-header-draggable {
    cursor: move;
  }

  &-loading-panel {
    // min-height: calc(200 * var(--hd));
    min-height: calc(270 * var(--hd));
    overflow-x: hidden; //兼容IE
  }

  &-panel {
    width: var(--hrm-card-panel-width);
    border-radius: var(--border-radius-sm);
    background-color: var(--bg-white);
    margin: var(--v-spacing-sm) 0;
    min-height: calc(200 * var(--hd));
    border: calc(1 * var(--hd)) solid rgba(109, 109, 109, 0.2);
    box-shadow: 0 0 calc(4 * var(--hd)) 0 rgba(109, 109, 109, 0.2);
    z-index: var(--popover-zindex);
  }

  &-panel-top {
    width: 100%;
    line-height: var(--line-height-base);
    height: var(--line-height-base);
    top: 0;
    padding-top: 0;
    padding-bottom: 0;
    z-index: var(--popover-zindex);

    &-close {
      top: calc(-1 * var(--hd));
    }
  }

  &-panel-hide {
    display: none;
  }

  &-panel-container {
    // padding-bottom: var(--h-spacing-md);
    min-height: calc(200 * var(--hd));
  }

  &-panel-container-list_empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-panel-empty {
    height: calc(200 * var(--hd));
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-panel-top {
    color: var(--main-fc);
    font-size: var(--font-size-12);
    background-color: var(--modal-top-bc);
    padding: 0 var(--h-spacing-lg);
    line-height: var(--line-height-base);
    height: var(--line-height-base);
    border-bottom: var(--border-solid);
    border-top-left-radius: var(--border-radius-sm);
    border-top-right-radius: var(--border-radius-sm);
    position: relative;
    display: flex;

    &-title {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: inline-block;
      padding-right: calc(12 * var(--hd) + var(--icon-size-xs));

      &-link {
        cursor: pointer;
      }
    }

    &-close {
      position: absolute;
      top: 0;
      right: calc(10 * var(--hd));
      cursor: pointer;
      color: var(--secondary-fc);

      .@{iconClsPrefix} {
        padding: calc(6 * var(--hd));

        &:hover {
          background-color: var(--dialog-icon-background);
          border-radius: var(--border-radius-xs);
        }
      }
    }
  }

  /* ------------------------内部联系人--------------------------------------    */
  &-panel-inside {
    width: calc(510 * var(--hd));
  }

  //baseinfo部分
  &-panel-inside-base_info {
    display: flex;
    padding-top: var(--v-spacing-lg);
    font-size: var(--font-size-12);

    &-item {
      // padding-top: var(--v-spacing-md);
      padding: var(--v-spacing-xs) 0;
      font-size: var(--font-size-12);
      display: flex;

      &-main {

        // padding-top: var(--v-spacing-sm);
        .@{hrmCardClsPrefix}-panel-inside-base_info-item-label {
          min-width: calc(40 * var(--hd));
          flex: 0 0 auto;
          padding: 0;
        }
      }

      &-label {
        vertical-align: text-top;
        // min-width: calc(36 * var(--hd) + var(--h-spacing-sm));
        color: var(--secondary-fc);
        padding-right: var(--h-spacing-sm);

        &-content {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          word-wrap: break-word;
          word-break: break-word;
          white-space: nowrap;
        }
      }

      &-wrapper {
        vertical-align: text-top;
        color: var(--main-fc);

        // padding-right: var(--h-spacing-sm);
        &-content {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          word-wrap: break-word;
          overflow: hidden;
          word-break: break-word;

          &-link {
            color: var(--primary);
          }
        }
      }
    }

    &-info {
      flex: 4;
      position: relative;

      &-position {
        position: absolute;
        cursor: pointer;
        top: 0;
        right: calc(29 * var(--hd));
        color: var(--primary);
        display: flex;
        align-items: center;

        .@{iconClsPrefix} {
          color: var(--secondary-fc);
          margin-right: var(--h-spacing-sm);
        }
      }

      &-qrcode {
        position: absolute;
        color: var(--secondary-fc);
        top: calc(-1 * var(--v-spacing-lg));
        right: calc(-60 * var(--hd));

        &-mask {
          display: none;
          width: calc(1.5 * var(--icon-size-md));
          height: calc(0.75 * var(--icon-size-md));
          background-color: var(--bg-white);
          transform: rotate(45deg);
          position: absolute;
          top: calc(-1 * var(--v-spacing-lg) + calc(var(--icon-size-md) / 2));
          right: calc(-60 * var(--hd));
          z-index: 1;
        }

        &-panel {
          padding: calc(21 * var(--hd)) calc(30 * var(--hd));
          background-color: var(--bg-white);
          margin: var(--h-spacing-sm);
          border: var(--border-solid);
          box-shadow: var(--dialog-box-shadow);

          &-tip {
            text-align: center;
            color: var(--secondary-fc);
            padding-top: var(--v-spacing-sm);
          }
        }
      }

      &-qrcode:hover {
        z-index: 2;
        color: var(--primary);
        cursor: pointer;
      }
    }

    &-img {
      color: var(--success);
      margin-left: calc(32 * var(--hd));
      position: relative;
      flex: 1;

      &-avatar {
        margin-bottom: var(--v-spacing-md);
        width: calc(60 * var(--hd));
        height: calc(60 * var(--hd));

        .@{avatarClsPrefix}-lg {
          width: 100%;
          height: 100%;
        }

        &-btn {
          margin-left: calc(-10 * var(--hd));
        }
      }

      &-mask {
        width: calc(60 * var(--hd));
        height: calc(60 * var(--hd));
        border-radius: 50%;
        // opacity: var(--hrm-card-opacity);
        background-color: var(--bg-transparent);
        color: transparent;
        position: absolute;
        top: 0;
        cursor: pointer;
        z-index: 11;

        &-hidden {
          display: none;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.60);
          color: var(--base-white);
          text-align: center;
          vertical-align: middle;
          line-height: calc(60 * var(--hd));
        }
      }
    }

    &-name {
      margin-bottom: var(--v-spacing-xs);
      color: var(--main-fc);
      display: flex;
      align-items: center;

      // 此样式用于二期二维码trigger
      // & > span:last-child {
      //   margin-left: calc(12 * var(--hd));
      //   color: var(--secondary-fc);
      // }
      &-name {
        font-weight: bolder;
        font-size: var(--font-size-14);
        color: var(--main-fc);
        vertical-align: middle;
        margin-right: var(--h-spacing-md);
        max-width: calc(100 * var(--hd));
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
      }

      &-workcode {
        display: inline-block;
        max-width: calc(100 * var(--hd));
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: calc(12 * var(--hd));
        color: var(--secondary-fc);
      }

      &-position {
        max-width: calc(100 * var(--hd));
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: auto;
        margin-right: calc(29 * var(--hd));
        color: var(--primary);
        display: flex;
        align-items: center;
        cursor: pointer;

        .@{iconClsPrefix} {
          color: var(--secondary-fc);
          margin-right: var(--h-spacing-sm);
        }

        &-span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    &-sex {
      vertical-align: middle;

      &-m,
      &-f {
        .@{iconClsPrefix}-md {
          width: calc(16 * var(--hd));
          height: calc(16 * var(--hd));
        }
      }

      &-m {
        color: var(--hrm-card-hrm-sex-bg-m);
      }

      &-f {
        color: var(--hrm-card-hrm-sex-bg-f);
        transform: rotate(-45deg);
      }
    }

    &-follow {
      cursor: pointer;
      text-align: center;
      height: calc(30 * var(--hd));

      &-status {
        margin-left: var(--h-spacing-sm);
      }

      &-doFollow {
        display: none;
      }

      &-willFollow,
      &-didFollow {
        color: var(--secondary-fc);
        font-size: var(--font-size-12);
        display: inline-block;
        padding: var(--v-spacing-sm) calc(11 * var(--hd)) var(--v-spacing-sm) calc(9 * var(--hd));
        border: var(--border-solid);
        border-color: transparent;
        border-radius: var(--border-radius-xs);

        .@{iconClsPrefix} {
          text-align: center;
          margin-right: var(--h-spacing-sm);
          vertical-align: middle;
          color: var(--secondary-fc);
        }

        &>span {
          vertical-align: middle;
        }
      }

      &-willFollow:hover {
        border-color: var(--border-color);
        color: var(--primary);

        .@{iconClsPrefix} {
          color: var(--primary);
        }
      }

      &-didFollow {
        color: var(--success);

        .@{iconClsPrefix} {
          color: var(--success);
        }
      }

      &-approved {
        .@{hrmCardClsPrefix}-panel-inside-base_info-follow-status-cancel {
          display: none;
        }

        &:hover {
          border-color: var(--border-color);
          color: var(--regular-fc);
          padding-left: calc(17 * var(--hd));
          padding-right: calc(17 * var(--hd));

          .@{iconClsPrefix} {
            display: none;
          }

          .@{hrmCardClsPrefix}-panel-inside-base_info-follow-status {
            display: none;
          }

          .@{hrmCardClsPrefix}-panel-inside-base_info-follow-status-cancel {
            display: inline;
          }
        }
      }
    }

    &-buttons-container {
      padding-bottom: calc(11 * var(--hd));
      display: flex;

      &-left {
        flex: 1;
      }

      &-right {
        flex: 3;
        padding-top: var(--v-spacing-sm);
      }
    }

    &-buttons {
      color: var(--hrm-card-buttons-iconBgColor);
      // display: flex;
      display: inline-block;

      .@{iconClsPrefix} {
        padding-right: calc(20 * var(--hd));
        cursor: pointer;

        // flex: 1;
        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  //detailinfo部分
  &-panel-inside-detail_info {
    &-detail {
      border-top: var(--hrm-card-border);
      padding: calc(12 * var(--hd)) var(--h-spacing-lg);

      &-single {
        // width: 50%;
        vertical-align: text-top;
        overflow: hidden;
        word-break: break-word;
        display: inline-block;

        &:nth-child(odd) {
          width: 55%;
        }

        &:nth-child(odd):not(&-notInside) {
          padding-right: calc(56 * var(--hd));
        }

        &:nth-child(even) {
          width: 45%;
        }

        &-fourWords {
          .@{hrmCardClsPrefix}-panel-inside-detail_info-item-label {
            max-width: calc(90 * var(--hd));
            flex: 0 0 auto;
          }

          .@{hrmCardClsPrefix}-panel-inside-detail_info-item-wrapper {
            min-width: calc(100% - calc(90 * var(--hd)));
            flex: 1 1 auto;
          }
        }

        &__link {
          .@{hrmCardClsPrefix}-panel-inside-detail_info-item-wrapper-content {
            cursor: pointer;
          }
        }
      }
    }

    &-item {
      // padding-top: var(--v-spacing-md);
      padding: var(--v-spacing-xs) 0;
      font-size: var(--font-size-12);
      display: flex;

      &-main {

        // padding-top: var(--v-spacing-sm);
        .@{hrmCardClsPrefix}-panel-inside-detail_info-item-label {
          min-width: calc(40 * var(--hd));
          flex: 0 0 auto;
          padding: 0;
        }
      }

      &-label {
        vertical-align: text-top;
        // min-width: calc(36 * var(--hd) + var(--h-spacing-sm));
        color: var(--secondary-fc);
        padding-right: var(--h-spacing-sm);

        &-content {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          word-wrap: break-word;
          word-break: break-word;
          white-space: nowrap;
        }
      }

      &-wrapper {
        vertical-align: text-top;
        color: var(--main-fc);

        // padding-right: var(--h-spacing-sm);
        &-content {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          word-wrap: break-word;
          overflow: hidden;
          word-break: break-word;

          &-link {
            color: var(--primary);
          }
        }
      }
    }
  }

  //statistics部分
  &-panel-inside-statistics_info {
    border-top: var(--hrm-card-border);
    display: flex;
    padding: calc(13 * var(--hd)) var(--h-spacing-lg);
    flex-wrap: wrap;

    &-minHeight {
      min-height: calc(80 * var(--hd));
    }

    &-single {
      width: 16%;
      font-size: var(--font-size-12);
      color: var(--secondary-fc);
      cursor: pointer;
      text-align: center;
      min-height: calc(55 * var(--hd));

      &-number {
        font-size: var(--font-size-14);
        font-weight: 500;
        padding-bottom: var(--v-spacing-sm);
      }

      &-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-bottom: calc(14 * var(--hd));

        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  //footer部分
  &-panel-inside-footer {
    color: var(--secondary-fc);
    font-size: var(--font-size-12);
    display: flex;
    padding: 0 var(--h-spacing-lg);
    padding-bottom: var(--v-spacing-md);

    & span {
      color: var(--main-fc);
    }

    &-single {
      flex: 1;

      &:last-child {
        text-align: right;
      }
    }
  }

  /* ------------------------外部联系人--------------------------------------    */
  &-panel-outer {

    // min-height: auto;
    // .@{hrmCardClsPrefix}-panel-hrm-name > span:last-child {
    //   margin-left: 0;
    // }
    // .@{hrmCardClsPrefix}-loading-panel {
    //   min-height: calc(150 * var(--hd));
    // }
    // .@{hrmCardClsPrefix}-panel-container {
    //   padding: 0;
    // }
    .@{hrmCardClsPrefix}-panel-hrm {
      padding-bottom: 0;
    }

    .@{hrmCardClsPrefix}-item-label {
      max-width: calc(90 * var(--hd));
    }

    .@{hrmCardClsPrefix}-item-wrapper {
      max-width: calc(100% - calc(90 * var(--hd)));
      min-width: auto;
    }

    .@{hrmCardClsPrefix}-info-detail-single:nth-child(odd) {
      width: 50%;
    }

    .@{hrmCardClsPrefix}-info-detail-single:nth-child(even) {
      width: 50%;
    }

    .@{hrmCardClsPrefix}-info-detail-single:last-child {
      width: 100%;
    }
  }

  &-panel-external,
  &-panel-wechat {
    width: calc(410 * var(--hd));
  }

  /* ------------------------ 部门/群组样式 --------------------------------------   */
  &-hrmlist {

    .ui-scroller__bar.is-horizontal {
      display: none;
    }

    width: 100%;

    // padding: var(--v-spacing-md) var(--h-spacing-lg);
    .@{listClsPrefix}-column .@{listClsPrefix}-body div:not(:last-child) .@{listClsPrefix}-content {
      border-bottom: 0;
    }

    &-single {
      padding: var(--v-spacing-sm) var(--h-spacing-sm);
      cursor: pointer;
      font-size: var(--font-size-12);
      display: inline-flex;
      width: 100%;
      align-items: center;

      &:hover {
        background-color: #f7f7f7;
      }

      &-img {
        vertical-align: middle;
        margin: 0 var(--h-spacing-md);
      }

      &-content {
        flex: 1;
      }

      &-name {
        vertical-align: middle;
        font-size: var(--font-size-12);
        color: var(--main-fc);
        padding: calc(2 * var(--hd)) 0;

        &:empty {
          padding: 0;
        }
      }

      &-position {
        font-size: var(--font-size-12);
        color: var(--secondary-fc);
        padding: calc(2 * var(--hd)) 0;

        &:empty {
          padding: 0;
        }
      }

      &-name,
      &-position {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(240 * var(--hd));
      }
    }

    &-single-emptyAvatar {
      padding-left: var(--h-spacing-lg);
      padding-right: var(--h-spacing-lg);
    }

    &-tip {
      font-size: var(--font-size-12);
      color: var(--secondary-fc);
      padding: var(--v-spacing-md) var(--h-spacing-lg);
    }

    &-more {
      text-align: center;
      color: var(--secondary-fc);
      font-size: var(--font-size-12);
      cursor: pointer;
      padding-top: var(--v-spacing-md);
      border-top: var(--border-solid);

      &:hover {
        color: var(--primary);
      }
    }

    .ui-list-footer-more {
      border-top: var(--border-solid);
      margin: 0;
      padding: calc(6 * var(--hd)) 0 calc(2 * var(--hd));
    }
  }

  // &-avatar {
  //   vertical-align: sub;
  //   margin-right: var(--h-spacing-sm);
  // }
  &-panel-department,
  &-panel-subcompany,
  &-panel-group,
  &-panel-position,
  &-panel-role {
    width: calc(310 * var(--hd));

    .@{hrmCardClsPrefix}-panel-top {
      position: relative;
      padding-left: calc(var(--h-spacing-sm) + var(--h-spacing-md));

      &-title {
        padding-left: calc(10 * var(--hd));
      }

      &-icon {
        display: inline-flex;
        align-items: center;

        svg {
          color: var(--secondary-fc);
        }
      }
    }
  }
}

@-webkit-keyframes hrmCardSlideUpIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hrmCardSlideUpIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}