@import './constants.less';
@import '../component/search/style/index.less';
@import './search.less';
@import './rich.less';
@import './floor.less';
@import './operation.less';

.@{commentClsPrefix} {
  border: 1px solid #E5E5E5;
  background: var(--bg-white);
  &-top {
    position: relative;
    border-bottom: var(--border-width) solid var(--border-color);
    display: flex;
    &-btns {
      flex: none;
      line-height: calc(40 * var(--hd));
      & > .@{iconClsPrefix} {
        cursor: pointer;
        & + .@{iconClsPrefix} {
          margin-left: var(--comment-margin);
        }
      }
    }
    // .@{menuClsPrefix}-nav-wrap .@{menuClsPrefix}-list-item {
    //   margin: 0 calc(6 * var(--hd)) 0 0;

    // }
    // .@{menuClsPrefix}-list-item, .@{menuClsPrefix}-list-subitem-title {
    //   height: calc(40 * var(--hd));
    //   padding: 0 var(--comment-padding);
    //   color: var(--regular-fc);
    //   margin: 0;
    //   top: calc(-1 * var(--hd));
    // }
    // .@{menuClsPrefix}-tab-top {
    //   height: auto;
    //   &-container {
    //     height: auto;
    //   }
    // }
  }
  &-tabs {
    flex: auto;
    border: none;
    height: calc(40 * var(--hd));
  }
  &-body {
    padding-left: calc(40 * var(--hd));
    position: relative;

    &-not-avatar {
      padding-left: 0;
    }

    &-only-content {
      padding-left: 0;
    }
  }
  &-action {
    cursor: pointer;
    color: var(--regular-fc);
    user-select: none;

    &:hover {
      color: var(--primary);
    }
    &:hover &-name {
      color: var(--primary);
    }
    &-name {
      vertical-align: middle;
      padding-left: calc(4 * var(--hd));
      color: var(--regular-fc);
    }
    &-separation {
      position: relative;
      width: var(--hd);
      vertical-align: middle;

      &::after {
        content: "";
        border-right: var(--hd) solid #dcdede;
        position: absolute;
        height: calc(16 * var(--hd));
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }
  &-textarea {
    width: 100%;
    max-width: 100%;
    font-family: var(--regular-ff);
    // border-bottom: 0;
    // border-bottom-left-radius: 0;
    // border-bottom-right-radius: 0;
    &-quote {      
      padding: calc(10 * var(--hd));
      width: 100%;
    }
    &-wrap {
      .@{textareaClsPrefix}-wrap {
        display: block;
        // &:focus {
        //   border-color: var(--input-focus-border-color);
        //   box-shadow: var(--input-focus-shadow);
        //   outline: 0;
        // }
      }
    }
    &-container {
      width: 100%;
      border: var(--border-solid);
      display: flex;
      flex-wrap: wrap;
      transition: border-color var(--transition-du) var(--transition-tf), var(--transition-color);
      border-radius: var(--input-border-radius);
      &:not(&-readonly):hover {
        border-color: var(--input-hover-border-color);
      }
      &-readonly {
        min-height: calc(68 * var(--hd));
      }
      &&-focus {
        border-color: var(--input-focus-border-color);
        box-shadow: var(--input-focus-shadow);
        outline: 0;
      }
      .@{textareaClsPrefix}, .@{textareaClsPrefix}-wrap {
        border: none;
        box-shadow: none;
      }
      &.is-high-light {
        border: none;
      }
      &.is-high-light-required {
        border: none;
        box-shadow: none;
      }
    }
    &-address {
      &.@{commentClsPrefix}-address {
        margin-bottom: 0;
        .comment-ellipsis();
      }
      .@{commentClsPrefix}-address-icon {
        color: var(--primary);
      }
      a.@{commentClsPrefix}-link.@{commentClsPrefix}-address-name {
        color: var(--primary);
      }
      &-container {
        padding: calc(3 * var(--hd)) calc(11 * var(--hd));
        border: var(--comment-grey-border);
        border-radius: calc(15 * var(--hd));
        display: inline-flex;
        align-items: center;
        max-width: 100%;
        margin-top: calc(7 * var(--hd));
      }
      &-close {
        color: var(--placeholder-fc);
        cursor: pointer;
        margin-left: calc(5 * var(--hd));
      }
    }
  }
  &-quote {
    width: 100%;
    display: flex;      
    flex-wrap: wrap;
    padding: calc(14 * var(--hd));
    padding-bottom: 0;
    border: var(--comment-grey-border);
    background-color: var(--comment-grey-bg-color);
    border-radius: calc(3 * var(--hd));
    &-header {
      width: 100%;
      position: relative;
      color: var(--secondary-fc);
    }
    &-clear {
      position: absolute;
      top: calc(-4 * var(--hd));
      right: calc(-4 * var(--hd));
      cursor: pointer;
    }
    &-name {
      margin-left: calc(8 * var(--hd));
      margin-right: calc(6 * var(--hd));
    }
    & &-content {
      padding-left: 0;
      padding-right: 0;
      &:hover {
        background-color: var(--comment-grey-bg-color);
      }
    }
  }
  &-edit {
    padding: var(--comment-padding);    
    font-size: var(--font-size-12);
    &-input {
      border: var(--border-width) solid var(--border-color);
      padding: 0 calc(6 * var(--hd));
      height: calc(30 * var(--hd));
      line-height: calc(28 * var(--hd));
      user-select: none;
      cursor: text;
      color: var(--placeholder-fc);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-more {
      margin-top: calc(5 * var(--hd));
    }
    &-text {
      .ck-content {
        height: calc(150 * var(--hd));
      }
      .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  &-more {
    &-icon {
      cursor: pointer;
      
      &:hover, &-selected {
        color: var(--primary);
      }
    }
  }
  &-avatar {
    position: absolute;
    top: 0;
    left: 0;
    &-container {
      cursor: pointer;
      display: inline-block;
      height: var(--avatar-size-md);
      overflow: hidden;
    }
    &-notdata {
      cursor: auto;
    }
  }
  &-html {
    background-color: inherit;
    opacity: 1;
    img:not([pluginname=emoticon]) {
      max-height: calc(200 * var(--hd));
    }
  }
  &-content {
    // padding: var(--comment-padding) 0;
    &-link.@{commentClsPrefix}-link {
      color: var(--comment-link-color);
    }
  }
  &-upload {
    &-list {
      padding-bottom: calc(10 * var(--hd));
      .@{listClsPrefix}-item {
        background-color: var(--bg-transparent);
      }
      .@{uploadClsPrefix} {
        margin-top: -3px;
      }
    }
    &-style {
      .@{commentClsPrefix}-label-title {
        padding-top: calc(8 * var(--hd));
      }
    }
    &-label{
      .@{commentClsPrefix}-label-title {
        padding-top: calc(8 * var(--hd));
      }
    }
  }
  &-create-labels {
    & > *:first-child {
      margin-top: calc(15 * var(--hd));
    }

    & > .ui-list-scrollview-wrap {
      .ui-list-rtl {
        .ui-list-scrollview-content {
          >.ui-list-body {
            transform: scaleX(1) !important;
          }
        }
      }
    }
  }
  &-option {
    min-height: var(--comment-option-height);
    // margin-bottom: var(--comment-padding);
    display: flex;
    padding-top: calc(5 * var(--hd));
    align-items: center;
    // flex-wrap: wrap;
    &-setting {
      margin-left: calc(-16 * var(--hd));
      display: flex;
      flex-wrap: wrap;
    }
    &-icons {
      flex: auto;
    }
    &-container {
      flex: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      line-height: calc(28 * var(--hd));
    }
    &-info {
      flex: none;
      max-width: 200%;
    }
    &-submit {
      flex: none;
      text-align: right;
      line-height: calc(28 * var(--hd));
      & > * + * {
        margin-left: calc(10 * var(--hd));
      }
      .@{btnClsPrefix} {
        padding: calc(3 * var(--hd)) calc(9 * var(--hd));
      }
      &-desensitization{
        cursor: pointer;
        color: #5391e9;
      }
    }
    & &-item {
      flex: none;
      display: inline-flex;
      margin-right: 10px;
      padding-right: 10px;
      position: relative;
      vertical-align: middle;

      &:not(:last-child) {
        .comment-separate();
      }
    }
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    &-setting {
      &-readonly {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      & > * {
        margin-left: calc(16 * var(--hd));
      }

      & > span {
        display: inline-block;
      }
      .@{uploadClsPrefix} {
        width: auto;
        display: inline-block;
      }
    }
  }
  &-username {
    color: var(--comment-link-color);    
    padding-right: calc(5 * var(--hd));
    display: inline-block;
    height: var(--comment-text-height);
    line-height: var(--comment-text-height);
    &&-notdata {
      cursor: auto;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &-signature {

    &-container {
      padding: calc(10 * var(--hd)) calc(12 * var(--hd)) calc(5 * var(--hd));

      & > * + * {
        margin-left: calc(10 * var(--hd));
      }

    }
    &-img {
      display: inline-block;
      position: relative;

      &-close {
        position: absolute;
        background: #c6c6c6;
        border-radius: 50%;
        color: #ffffff;
        top: calc(-7 * var(--hd));
        right: calc(-7 * var(--hd));
        visibility: hidden;
        cursor: pointer;

        &-show {
          visibility: visible;
        }
      }

      &-hide {
        visibility: hidden;
      }

      &:hover &-close {
        visibility: visible;
      }

      & > img {
        height: calc(60 * var(--hd));
        border: var(--hd) solid var(--border-color);
      }
    }
  }
  &-to {
    padding: 0 calc(6 * var(--hd));
    color: var(--main-fc);
  }
  &-link {
    cursor: pointer;
    color: var(--comment-link-color);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &-filelist {
    &-readonly {
      
    }

    &-rtl {
      .@{uploadClsPrefix} {
        &-listT {
          .@{listClsPrefix} {
            &-body {
              //transform: scaleX(1) !important;
            }
            &-item {
              //transform: scaleX(-1) !important;
            }
          }
        }
      }
    }
  }
  &-label {
    display: flex;
    // flex-wrap: wrap;
    &-title {
      vertical-align: top;
      flex: none;
      color: var(--secondary-fc);
      // height: var(--comment-text-height);
      line-height: 1.4;
      &::after {
        content: "\ff1a";
        padding: 0 calc(5 * var(--hd)) 0 calc(2 * var(--hd));
      }
    }
    &-children {
      flex: auto;
      width: 0;
    }
  }
  &-list {    
    &:not(:first-child):not(&-nodata ) {      
      border-top: var(--border-width) dashed var(--border-color);
    }
    & &-body {
      display: block;
    }
    &-body {
      & > * > .@{listClsPrefix}-body {
        border-bottom: var(--border-width) dashed var(--border-color);
      }
    }
    &-nomore {
      & > * > .@{listClsPrefix}-body {
        border-bottom-width: 0;
      }
    }
    .@{commentClsPrefix}-item:not(:last-child) {      
      border-bottom: var(--border-width) dashed var(--border-color);
    }
    .ui-list-rtl {
      .ui-list-body {
        .@{commentClsPrefix}-item {
          //transform: scaleX(-1);
          //direction: ltr;

          .@{commentClsPrefix}-body {
            .@{commentClsPrefix}-item-box {
              .@{commentClsPrefix}-item-content {
                &>.ui-list-scrollview-wrap {
                  .ui-list-rtl {
                    .ui-list-scrollview-content {
                      >.ui-list-body {
                        //transform: scaleX(1) !important;
                      }
                    }
                  }
                }

                .@{commentClsPrefix}-item-text {
                  .@{commentClsPrefix}-link {
                    display: inline-block;
                    direction: ltr;
                  }
                }
              }
            }
          }
        }
      }
    }
    .@{paginationClsPrefix} {
      text-align: right;
      padding: 0 var(--comment-padding);
      padding-top: calc(10 * var(--hd));
      padding-bottom: calc(4 * var(--hd));
      border-top: var(--border-width) dashed var(--border-color);
      height: inherit;
      margin-top: 0;
    }
  }
  &-empty {
    padding: calc(30 * var(--hd)) 0 calc(50 * var(--hd));
    margin: 0;
  }
  &-item {
    display: inline-block;
    width: 100%;
    padding: calc(10 * var(--hd)) var(--comment-padding);
    background: var(--bg-transparent);
    font-size: var(--font-size-12);
    position: relative;
    &&-background {
      background: var(--comment-hover-bgcolor);
    }
    &-avatar {
      // top: -4px;
    }
    &-header {
      padding-bottom: calc(4 * var(--hd));
    }
    &-privy {
      display: block;
      float: right;
      background: #FFFFFF;
      border: 1px solid #E5E5E5;
      border-radius: calc(3 * var(--hd));
      padding: 0 calc(8 * var(--hd));
      height: calc(24 * var(--hd));
      line-height: calc(22 * var(--hd));
      position: relative;
      top: calc(-4 * var(--hd));
      flex: none;
    }
    &-footer {
      &-set {
        font-size: var(--font-size-12);
      }
      &-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      &-info {
        flex: none;
        height: var(--comment-text-height);
        line-height: var(--comment-text-height);
        margin-bottom: calc(5 * var(--hd));
        max-width: 100%;
      }
      &-more-btn {
        color: var(--secondary-fc);
        cursor: pointer;
      }
      &-btns {
        flex: auto;
        text-align: right;
        transition: opacity 0.3s;
        & > * {
          & + * {
            margin-left: var(--comment-margin);
          }
          &:not(.always-show) {
            opacity: 0;
          }
        }
      }
      &-more {
        padding: calc(3 * var(--hd)) 0;
        color: var(--secondary-fc);

        .@{commentClsPrefix}-iconbtn {
          display: flex;
          align-items: center;
          line-height: inherit;

          &-title {
            .ellipsis();
          }
        }

        & > * {
          width: 100%;          
          padding: 0 calc(11 * var(--hd));
          cursor: pointer;
        }
      }
    }
    &:hover {
      // background: var(--comment-hover-bgcolor);
    }
    &-show &-footer-btns > *:not(.always-show) {
      opacity: 1;
    }
    &-header, &-footer {
      color: var(--secondary-fc);
    }
    &-reply {
      color: var(--secondary-fc);
      &-text {
        // margin-top: var(--comment-margin);
        margin-top: var(--v-spacing-xs);
        word-wrap: break-word;
        word-break: break-word;
        line-height: 1.5;
        .@{commentClsPrefix}-html {
          color: var(--secondary-fc);
        }
      }
      &-header {
        display: inline-block;
        // color: var(--regular-fc);
        color: var(--secondary-fc);
      }
    }
    &-content {
      color: var(--regular-fc);
      margin-bottom: var(--comment-margin);
      & > *:not(:last-child) {
        margin-bottom: var(--comment-margin);
      }
    }
    &-text {
      color: var(--main-fc);
      word-wrap: break-word;
      word-break: break-word;
      line-height: 1.5;
      flex: auto;
      overflow: hidden;
      white-space: pre-wrap;

      .@{encryClsPrefix} {
        white-space: normal;
      }

      &-container {
        display: flex;
      }
      /*
      img {
        width: var(--comment-emoji-size);
        height: var(--comment-emoji-size);
      }
      */
      .@{commentClsPrefix}-html {
        a {
          color: var(--comment-link-color);
          &[pluginname="at"] {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    &-text-rich {
      white-space: normal;
    }
    &-from {
      padding-right: calc(4 * var(--hd));
    }
    &-other {
      width: 100%;
      transition: height 0.3s;
      padding-top: calc(7 * var(--hd));
    }
    &-at {
      cursor: pointer;
      color: var(--comment-link-color);
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &-custom-upload {
    display: inline-block;
    position: relative;
    & > span {
      display: inline-block;
    }
    &-input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      visibility: hidden;
    }
  }
  &-at {
    display: inline-block;
    &-location {
      position: absolute;
      z-index: 9999;
      top: 0;
      left: 0;
      height: calc(20 * var(--hd));
    }
  }
  &-address {
    margin-bottom: calc(10 * var(--hd));
    font-size: var(--font-size-12);
    &-title {
      font-size: var(--font-size-12);
      color: var(--secondary-fc);
      &-username {
        display: inline-block;
        font-size: var(--font-size-14);
        color: var(--main-fc);
        font-weight: bold;
        margin-right: calc(10 * var(--hd));
      }
      & > span:not(:last-child) {
        margin-right: calc(5 * var(--hd));
      }
    }
    &-icon {
      color: var(--secondary-fc);
      .@{iconClsPrefix}-svg {
        width: calc(16 * var(--hd));
        height: calc(16 * var(--hd));
      }
    }
    a.@{commentClsPrefix}-link {
      color: var(--regular-fc);
      vertical-align: middle;
    }
  }
  &-upload {
    &-signature {
      .@{uploadClsPrefix}-listT-item-imglist-h {
        padding: 0;
      }
    }
    &-style {
      .@{uploadClsPrefix} {
        padding: 0;
      }
      .@{uploadClsPrefix}-listT, .@{uploadClsPrefix}-listB {
        margin-top: 0;
      }
    }
    &-uploading {
      .@{uploadClsPrefix}-listB-item-cancel {
        display: none;
      }
    }
    &-content {
      margin-top: calc(-3 * var(--hd));
    }
  }
  &-attachments {
    display: flex;
    flex-wrap: wrap;
    &-imgbox {
      margin: 0 var(--comment-margin) var(--comment-margin) 0;
      flex: none;
    }
    &-img {
      height: var(--comment-img-size);
      width: var(--comment-img-size);
      position: relative;
      overflow: hidden;
      border-radius: 3px;
      border: 1px solid var(--border-color);
      cursor: pointer;
      .@{iconClsPrefix} {
        color: var(--base-white);
        z-index: 6;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: none;
      }
      &:hover .@{iconClsPrefix} {
        display: inline-block;
      }
      &-mask {
        position: absolute;
        width: 0px;
        height: 0px;
        top: 50%;
        left: 50%;
        opacity: 0.5;
        background-color: var(--base-black);
        z-index: 5;
        transition: all 0.3s;
        overflow: hidden;
        text-align: center;
      }
      &:hover &-mask {
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
      }
      & > img {
        width: 100%;
        user-select: none;
        height: auto;
        max-width: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        max-height: inherit;
      }
      &-title {
        text-align: center;
        padding: calc(5 * var(--hd)) 0 calc(2 * var(--hd));
        width: var(--comment-img-size);
      }
    }
    &-filebox {
      flex: auto;
      width: 100%;
      &:not(:last-child) {
        padding-bottom: calc(2 * var(--comment-word-padding));
      }
    }
    &-filebox a&-file {
      color: var(--regular-fc);
    }
    &-file {
      display: inline-block;
      cursor: pointer;
      max-width: 100%;
      span:not(.@{iconClsPrefix}) {
        vertical-align: middle;
      }
      .@{iconClsPrefix} {
        margin-right: calc(5 * var(--hd));
      }
    }
  }
  &-iconbtn {
    cursor: pointer;
    user-select: none;
    display: inline-block;
    height: var(--comment-text-height);
    line-height: var(--comment-text-height);
    &:hover {
      color: var(--primary);
    }
    .@{iconClsPrefix}-wrapper {
      line-height: 1;
      padding-right: calc(2 * var(--hd));
    }
    &-readonly {
      cursor: not-allowed;
    }
    &-title {
      
    }
    & > div {
      display: inline-block;
    }
  }
  &-upvote {
    display: inline-block;
    cursor: pointer;
    &-popup {
      .@{popoverClsPrefix}-content {
        border: var(--border-solid);
        box-shadow: var(--comment-popup-shadow);
      }
    }
    &-avatar {
      display: flex;
    }
    &-children {
      &:hover {
        color: var(--primary);
        .@{commentClsPrefix}-upvote-icon {
          color: var(--primary);
        }
      }

      &-rtl {
        .ui-icon {
          & > .ui-rtl {
            transform: scaleX(1) !important;
          }
        }
      }
    }
    &-content {
      background: #FFFFFF;
      border: 1px solid #E5E5E5;
      box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.12);
      border-radius: 3px;      
      padding: calc(8 * var(--hd));
      margin-bottom: calc(6 * var(--hd));
    }
    &-arrow {
      &, &-border{
        width: 0;
        height: 0;
        border-left: var(--popover-arrow-width) solid transparent;
        border-right: var(--popover-arrow-width) solid transparent;
        border-top: var(--popover-arrow-width) solid var(--popover-background);
        border-bottom: 0;
        top: calc(100% - var(--popover-arrow-width));
        right: var(--h-spacing-md);
        position: absolute;
      }
      &-content {
        top: calc(100% - calc(var(--popover-arrow-width) + var(--hd)));
        z-index: 9;
      }
      &-border {
        border-top: var(--popover-arrow-width) solid var(--border-color);
      }
    }
    &-list {
      .@{scrollerClsPrefix}__view {
        display: flex;
        align-items: center;
        max-height: var(--comment-like-height);
        & > div {
          flex: none;
          display: inline-block;
          padding: calc(2 * var(--hd));
        }
      }
      .is-vertical {
        display: none;
      }
      &&-all {
        .@{scrollerClsPrefix}__view {
          flex-wrap: wrap;
        }        
        .is-vertical {
          display: block;
        }
      }
    }
    & &-icon {
      line-height: 1;
      padding-right: calc(2 * var(--hd));
      color: var(--secondary-fc);
    }
    &-limit-width {      
      max-width: calc(510 * var(--hd));
    }
    &-more {
      cursor: pointer;
      .@{iconClsPrefix} {
        vertical-align: middle;
      }
    }
    &-name {
      font-size: var(--font-size-12);
      color: var(--regular-fc);
      padding: calc(2.5 * var(--hd)) calc(6 * var(--hd));
      background-color: #EDEDED;
      border-radius: 2px;
      border: var(--hd) solid #BBBBBB;
      margin-bottom: calc(12 * var(--hd));
    }
  }
  &-reply {
    &-top {
      color: var(--main-fc);
      margin-bottom: calc(9 * var(--hd));
    }
    &-user {
      color: var(--secondary-fc);
      padding-left: 5px;
    }
    &-btns {
      padding-top: 8px;
      &::after {
        content: "";
        clear: both;
        display: block;
      }
    }
    &-setting {
      float: right;
      color: var(--black-fc);
      height: calc(32 * var(--hd));
      line-height: calc(32 * var(--hd));
      &-item {
        display: inline-block;
        & > * + *{
          margin-left: var(--comment-margin);
        }
        & > div {
          display: inline-block;
        }
        &:not(:last-child) {
          &::after {
            content: "";
            border-right: 1px solid #dcdede;
            margin-right: 8px;
          }
        }
      }
      .@{checkboxClsPrefix} {
        width: auto;
        display: inline-block;
        &-label {
          vertical-align: inherit;
        }
      }
    }
    &-open {
      cursor: pointer;
      user-select: none;
      display: inline-block;
      color: var(--main-fc);
      height: var(--comment-text-height);
      line-height: var(--comment-text-height);
      .@{iconClsPrefix} {
        vertical-align: middle;
        color: var(--regular-fc);
        transition: transform 0.3s;
      }
      & > span:not(.@{iconClsPrefix}) {
        color: var(--primary);
        padding: 0 2px;
      }
      &-active {
        .@{iconClsPrefix}:not(.@{commentClsPrefix}-reply-loading) {
          transform: rotate(-90deg);
        }
      }
      &-disabled {
        cursor: not-allowed;
      }
    }
    & &-loading {
      width: calc(18 * var(--hd));
      height: calc(18 * var(--hd));
      text-align: center;
      line-height: calc(22 * var(--hd));
    }
    &-info {
      display: flex;
    }
  }
  &-private {
    display: flex;
    align-items: center;
    user-select: none;
    margin-right: calc(5 * var(--hd));
    &:not(:last-child) {      
      .comment-separate();
    }
    .@{checkboxClsPrefix}-wrapper {
      margin: 0;
    }
  }
  &-replylist {
    height: 100%;
  }
  &-log {
    padding: 0 calc(18 * var(--hd));
    .@{listClsPrefix}-body {
      border-bottom: none;
      .@{listClsPrefix}-item.@{listClsPrefix}-item-noorder {
        background: inherit;         
        & .@{listClsPrefix}-content {
          border-bottom: var(--hd) dashed var(--diviling-line-color);
        }
      }
    }
    &:not(&-page) .@{listClsPrefix}-body .@{listClsPrefix}-item {
      &:last-child .@{listClsPrefix}-content {
        border-bottom: 0;
      }
    }
    &-select {
      padding: calc(8 * var(--hd)) 0;
    }
    &-radio {
      color: var(--regular-fc);
      margin-right: calc(6 * var(--hd));

      &.@{radioClsPrefix}-wrapper-checked {
        .@{tagClsPrefix} {
          color: var(--primary);
          background: inherit;
          border-color: #ffffff00;
          overflow: hidden;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1000%;
            background: var(--primary);
            opacity: 0.15;
          }
        }
      }

      
    }
    &-empty {
      padding: calc(30 * var(--hd));
    }
    &-status {      
      padding: 0 calc(20 * var(--hd));
    }
    &-time {
      flex: auto;
      display: inline-block;
      width: 100%;
    }
    &-item {
      width: 100%;
      display: flex;
      & > {
        flex: none;
      }
      & > div {
        color: var(--secondary-fc);
        display: inline-block;
      }
      & > span {
        color: var(--secondary-fc);
      }
      & > a {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &-username {
      width: calc(80 * var(--hd));
      display: inline-block;
      color: var(--comment-link-color);
      vertical-align: middle;
      cursor: pointer;
    }
    &-hassee {
      color: var(--comment-font-red);
    }
  }
  &-reportcus-info {
    padding: 0 calc(18 * var(--hd));
    & > div > .@{listClsPrefix} {
      right: 0 !important;
    }
  }
  &-echarts {
    &-header {
      background: #f7f7f7;
      height: calc(40 * var(--hd));
      display: flex;
      align-items: center;
      padding: 0 calc(16 * var(--hd));
    }
    &-search {
      &-date {
        .@{formItemClsPrefix}-wrapper-col {
          margin-top: calc(10 * var(--hd));
        }
        .@{formItemClsPrefix}-item-placeholder {
          text-align: left;
        }
        .@{mDateTimePickerClsPrefix} + .@{mDateTimePickerClsPrefix} {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: var(--hd);
            width: 100%;
            background-color: var(--border-color);
            transform: scaleY(0.3);
            pointer-events: none;
            z-index: 9;
          }
        }
      }
    }
    &-title {
      flex: auto;
    }
    &-cancel {
      height: calc(30 * var(--hd));
      background: #f3f3f3;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--font-size-12);
      user-select: none;
      cursor: pointer;
      border-bottom: var(--border-width) solid var(--border-color);
      border-top: var(--border-width) solid var(--border-color);
      color: var(--regular-fc);
    }
    &-btn {
      flex: none;
      color: #8e8e8e;

      &-selected {
        color: var(--primary);
      }
    }
    &-select {
      text-align: center;
      padding: var(--comment-padding) 0;
    }
    &-types {
      padding-bottom: var(--comment-padding);
      .@{radioClsPrefix}-wrapper-checked {
        color: var(--primary);
      }
    }
    &-date {
      .@{radioClsPrefix}-wrapper-checked {
        .@{radioClsPrefix}-label {          
          color: var(--primary);
        }
        .@{tagClsPrefix} {
          border-color: var(--primary);
        }
      }
      .@{radioClsPrefix} {
        display: inline-block;
        vertical-align: text-bottom;
      }
      .@{datePickerClsPrefix}-rangePickerWrapper {
        display: inline-flex;
      }
    }
  }
  &-task {
    &-top {
      margin-bottom: var(--comment-margin);
      color: var(--main-fc);
    }
    &-user {
      color: var(--secondary-fc);
      padding: 0 calc(5 * var(--hd));
      display: inline-block;
      .@{iconClsPrefix} {
        color: var(--comment-link-color);
        cursor: pointer;
      }
    }
    &-user-rtl {
      transform: scaleX(-1);
    }
    &-btns {
      margin-top: calc(8 * var(--hd));
      display: flex;
      flex-wrap: wrap;
      .@{btnClsPrefix} {
        padding: calc(3 * var(--hd)) calc(9 * var(--hd));
      }
      & > *:not(:last-child) {
        margin-right: var(--comment-margin);
      }
    }
    &-text {
      .@{textareaClsPrefix}-wrap {
        display: block;
      }
    }
    &-date {
      display: inline-flex;
      align-items: center;
      &-title {
        flex: none;
        padding-right: calc(5 * var(--hd));
      }
      .@{datePickerClsPrefix} {
        width: auto;
        margin-left: calc(5 * var(--hd));
      }
    }
    &-open {
      margin-top: calc(5 * var(--hd));
      line-height: inherit;
    }
    
    &-browser {
      .@{browserClsPrefix}-associative-selected .@{listClsPrefix}-item {
        min-height: auto;
        .@{listClsPrefix}-content {
          min-height: auto;
        }
      }
      .@{listClsPrefix}-item-row {
        & > span {
          // .comment-link();
        }
      }
    }
    &-attachments {
      padding-top: calc(10 * var(--hd));
      border-top: var(--border-width) dashed var(--border-color);;
      margin-top: calc(10 * var(--hd));
    }
    textarea {
      max-width: 100%;
    }
  }
  &-replylist {
    margin-top: calc(8 * var(--hd));
    &-user {
      color: var(--comment-link-color);
      margin-right: calc(8 * var(--hd));
    }      
    &-item {
      &:not(:last-child) {
        margin-bottom: calc(6 * var(--hd));
      }
    }
    &-content {
      margin-top: calc(5 * var(--hd));
      color: var(--main-fc);
    }      
    &-hasMore {
      color: var(--main-fc);
      cursor: pointer;
    }
  }
  &-horizontal {
    &-item {
      width: 25%;
      float: left;
    }
    .@{commentClsPrefix}-item-footer-info {
      height: auto;
    }
    & > .@{listClsPrefix}-scrollview-content > .@{listClsPrefix}-body {
      position: relative;
      // min-height: 700px;
      color: var(--primary);
      &::after {
        content: "";
        clear: both;
        display: block;
      }
    }
  }
  &-horizontal-nomasonry {
    & > .@{listClsPrefix}-scrollview-content > .@{listClsPrefix}-body {
      display: flex;
      flex-wrap: wrap;
      .@{commentClsPrefix}-horizontal-item {
        width: auto;
        max-width: 100%;
        flex-grow: 1;
        .@{commentClsPrefix}-lable-children {
          width: auto;
        }
      }
    }
  }
  &-readonly {
    &-item {
      position: relative;
      &-mask {
        content: "";
        display: block;
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: -1px;
        bottom: -1px;
        border: 1px solid #ffffff00;
        user-select: none;
        &:hover {
          border: 1px dashed var(--primary);
        }
      }
      &:first-child &-mask {
        top: 0;
      }
      &:last-child &-mask {
        bottom: 0;
      }
      &-close {
        position: absolute;
        right: calc(12 * var(--hd));
        top: calc(8 * var(--hd));
        cursor: pointer;
        opacity: 0;
        color: var(--primary);
      }
      &-mask:hover &-close {
        opacity: 1;
      }
    }
  }
  &-phrase {
    .@{commonPhraseClsPrefix} {
      width: inherit;
    }
  }
}

.@{commentClsPrefix}-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  .@{listClsPrefix}-body {
    border: none;
  }
  &-tabs {
    height: calc(40 * var(--hd));
    padding: 0;
    border: 0;
  }
  &-body .@{commentClsPrefix}-empty {
    border-top: none;
    padding-top: 100px;
  } 
  // .@{menuClsPrefix} .@{menuClsPrefix}-list-item:not(:last-child) {
  //   margin-right: calc(20 * var(--hd));
  // }
  // .@{menuClsPrefix} {
  //   .@{menuClsPrefix}-list-subitem-title, .@{menuClsPrefix}-list-item {      
  //     align-items: center;      
  //     padding-bottom: 0;
  //     padding-top: calc(10 * var(--hd));
  //     .@{menuClsPrefix}-list-item-content {
  //       height: calc(20 * var(--hd));
  //       line-height: calc(20 * var(--hd));
  //     }
  //     .@{menuClsPrefix}-list-item-righticon {
  //       height: calc(20 * var(--hd));
  //       line-height: calc(20 * var(--hd));
  //     }
  //   }
  // }
  // .@{menuClsPrefix} {
  //   border: none;
  //   padding: 0;
  //   &-list-item, &-list-subitem {
  //     padding: 0 calc(5 * var(--hd));
  //     margin: 0;
  //     height: calc(46 * var(--hd));
  //   }
  //   &-list-subitem {
  //     &-title {
  //       height: 100%;
  //       border-bottom: 0;
  //     }
  //   }
  // }
  &-overlay {
    max-width: calc(180 * var(--hd));
  }
  & &-item.@{commentClsPrefix}-item {
    border: var(--border-solid);
    margin-bottom: calc(15 * var(--hd));
    background-color: var(--comment--menu-card-bgcolor);
    &:hover {
      background: var(--comment-hover-bgcolor);
    }
  }
  &-top {
    border-bottom: var(--comment-menu-border);
    position: relative;
    flex: none;
    padding: 0 calc(16 * var(--hd));
    // .@{menuClsPrefix}-bar {
    //   position: relative;
    // }
  }
  &-body {
    flex: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    
    & > .@{spinClsPrefix}-nested-loading {
      flex: auto;
      overflow: hidden;
      
      & > .@{spinClsPrefix}-container {
        height: 100%;

        & > .@{commentClsPrefix}-menu-list {
          height: 100%;
        }
      }
    }

    &, .@{commentClsPrefix}-list:not(.@{commentClsPrefix}-menu-card-list) {
      & > * > * > .@{scrollerClsPrefix}__wrap {    
        padding: var(--comment-menu-padding);
      }
    }
  }
  & .@{commentClsPrefix}-list&-list {
    flex: auto;
    overflow: hidden;
    border-top: 0;
  }
  &-browser {
    vertical-align: top;
    height: var(--comment-text-height);
    line-height: var(--comment-text-height);
    display: inline-flex;
    align-items: center;

    .@{browserClsPrefix}-associative-selected .@{listClsPrefix}-column .@{listClsPrefix}-body .@{listClsPrefix}-item {
      min-height: initial;
      .@{listClsPrefix}-content {
        height: var(--comment-text-height);
        min-height: initial;
      }
    }

    .@{browserClsPrefix}-associative-selected-wrap {
      height: 100%;
      display: flex;
      align-items: center;
    }

  } 
  &-card {
    border: var(--comment-menu-border);
    background-color: var(--comment--menu-card-bgcolor);
    & .@{commentClsPrefix}-list&-list {
      border-top: 0;
    }
    &:not(:last-child) {
      margin-bottom: var(--comment-menu-padding);
    }
    .@{commentClsPrefix}-item {
      padding: calc(10 * var(--hd)) 0;
      border-left: var(--comment-padding) solid #ffffff00;
      border-right: var(--comment-padding) solid #ffffff00;
    }
    &-top {
      padding: calc(11 * var(--hd)) var(--comment-menu-padding);
      border-bottom: var(--comment-menu-border);
      color: var(--main-fc);
      font-size: var(--font-size-12);
      font-weight: 600;
    }
  }
  &-search {
    &-wrap {
      text-align: right;
      min-width: calc(220 * var(--hd));;
      .@{searchAdvancedClsPrefix} {
        width: calc(220 * var(--hd));
      }
    }
    &-container {
      flex: none;
    }
    &-panel {
      width: 100%;
      padding: var(--comment-padding);
      padding-bottom: 0;
    }
  }
  &-target-name {
    margin-left: 10px;
  }
}


.@{commentClsPrefix} {
  &-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.@{formClsPrefix}{
  .secondAuth-hidden{
    border: none !important;
  }
}

.weapp-ie11 {
  .@{commentClsPrefix} {
    &-label {
      &-children {
        flex: auto;
        width: 100%;
      }
    }
  }
}