// @import "../../../../node_modules/intro.js/introjs.css";
@import "../styles/introjs.scss";

@intro-bg-color: rgba(255, 255, 255, .88);
@intro-arrow-space: -13px;

.introjs-tooltip-rtl {
  transform: scaleX(-1);
}

.introjs-helperLayer {
  box-shadow: rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
}

.introjs-tooltipReferenceLayer .introjs-tooltip {
  background: transparent;
  max-width: calc(302 * var(--hd));
  color: var(--main-fc);
  box-shadow: none; //box-shadow unset值为none，e11兼容改为none（unset ie11不兼容）

  //顶部标题
  .introjs-tooltip-header {
    font-family: var(--regular-ff);
    display: none;

    .introjs-skipbutton {
      position: relative;
      color: transparent;
      display: none;

      &::after {
        position: absolute;
        left: 50%;
        top: 25%;

        transform: translateY(-50%);
        content: ' ';
        width: calc(var(--hd) * 1.2);
        height: calc(var(--hd) * 14);
        background: var(--secondary-fc);
        transform: rotate(45deg);

        visibility: visible;
        cursor: pointer;
      }

      &::before {
        position: absolute;
        left: 50%;
        top: 25%;

        content: ' ';
        width: calc(var(--hd) * 1.2);
        height: calc(var(--hd) * 14);
        background: var(--secondary-fc);
        transform: rotate(-45deg);
        visibility: visible;
        cursor: pointer;
      }
    }
  }

  //内容文字
  .introjs-tooltiptext {
    font-family: var(--regular-ff);
    line-height: 1.4;
    letter-spacing: var(--hd);
    background: @intro-bg-color;
    border-top-left-radius: calc(5 * var(--hd));
    border-top-right-radius: calc(5 * var(--hd));
    padding: calc(16 * var(--hd));
  }

  .introjs-tooltiptext-no-bullet{
    border-radius: calc(5 * var(--hd));
  }

  .introjs-bullets {
    border-bottom-left-radius: calc(5 * var(--hd));
    border-bottom-right-radius: calc(5 * var(--hd));
    background: @intro-bg-color;
    padding-bottom: calc(20 * var(--hd));
    padding-top: calc(4 * var(--hd));
    padding-bottom: calc(16 * var(--hd));

    ul {
      float: right;
      margin-right: calc(20 * var(--hd));
    }

    li>a {
      width: calc(16 * var(--hd));
      height: calc(3 * var(--hd));
    }

    a.active {
      background: var(--primary);
      opacity: .8;
    }

    a:hover {
      background: var(--primary);
      opacity: .8;
    }
  }

  //底部按钮组
  .introjs-tooltipbuttons {
    border-top: none;
    position: absolute;
    display: inline-block;
    padding: calc(16 * var(--hd)) 0;
    width: 100%;

    .introjs-button {
      .ui-btn;
      display: inline-block;
      width: auto;
      margin: 0 calc(var(--hd) * 5);
      text-shadow: none;
      color: var(--regular-fc);

      &:focus {
        box-shadow: none;
      }
    }

    .introjs-prevbutton,
    .introjs-nextbutton {
      float: right;
    }

    .introjs-skipbutton{
      margin-left: 0;
    }

    .introjs-skipbutton {
      float: left;
    }

    .introjs-nextbutton {
      background: var(--primary);
      color: var(--base-white);
      border: var(--hd) solid var(--primary);
      display: flex;
      font-size: var(--font-size-12);
      font-weight: var(--font-weight-base);
      margin-right: 0;

      &:hover,
      &:active {
        color: var(--base-white);
        border: var(--hd) solid var(--primary);
      }
    }

    .introjs-disabled {
      display: none;
    }
  }

  .introjs-tooltipbuttons-placement-top{
    display: none;
  }

  .introjs-tooltipbuttons-placement-bottom{
    display: block;
  }
}

.introjs-tooltip.wea-top{
  .introjs-tooltipbuttons{
    top: 0;
  }

  .introjs-tooltipbuttons-placement-top{
    display: block;
  }

  .introjs-tooltipbuttons-placement-bottom{
    display: none;
  }

  .introjs-arrow{
    bottom: @intro-arrow-space;
  }
}

.introjs-tooltip.wea-bottom .introjs-arrow{
  top: @intro-arrow-space;
}

.introjs-tooltip.wea-left .introjs-arrow{
  right: @intro-arrow-space;
}

.introjs-tooltip.wea-right .introjs-arrow{
  left: @intro-arrow-space;
}