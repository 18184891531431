.@{iconClsPrefix} {
  &.icon-placeholer {
    display: inline-block;
  }
  &-yellow {
    color: var(--icon-yellow);
  }
  &-blue {
    color: var(--icon-blue);
  }
  &-indigo {
    color: var(--icon-indigo);
  }
  &-green {
    color: var(--icon-green);
  }
  &-red {
    color: var(--icon-red);
  }

  &-img {
    // width: var(--icon-image-default-width);
    // height: var(--icon-image-default-height);
  }

  &-xxs {
    width: var(--icon-size-xss);
    height: var(--icon-size-xss);
  }

  &-xs {
    width: var(--icon-size-xs);
    height: var(--icon-size-xs);
  }

  &-s {
    width: var(--icon-size-s);
    height: var(--icon-size-s);
  }

  &-sm {
    width: var(--icon-size-sm);
    height: var(--icon-size-sm);
  }

  &-md {
    width: var(--icon-size-md);
    height: var(--icon-size-md);
  }

  &-lg {
    width: var(--icon-size-lg);
    height: var(--icon-size-lg);
  }

  &-wrapper {
    display: inline-block;
    line-height: 0;
    vertical-align: middle;
  }

  &-spin {
    display: inline-block;
    &:not(.need-mirror-RTL) {
      -webkit-animation: loadingCircleRcIcon 1s infinite linear;
      animation: loadingCircleRcIcon 1s infinite linear;
    }
    &.need-mirror-RTL {
      -webkit-animation: loadingCircleRcIconRTL 1s infinite linear;
      animation: loadingCircleRcIconRTL 1s infinite linear;
      animation-direction: reverse;
    }
  }
}

@-webkit-keyframes loadingCircleRcIcon {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadingCircleRcIcon {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
