.@{localePrefix} {

  // input {
  //   border: none;
  // }
  &-readOnly {
    font-size: var(--font-size-12);
    color: var(--main-fc);
    display: inline-flex;
    align-items: center;
    background: none;
    height: calc(30 * var(--hd));
  }

  &-readOnly-selectedWrap {
    .locale-span {
      .selectedWrap-readyOnly
    }
  }

  &-readOnly-ellipsis {
    .locale-span {
      .selectedWrap-ellipsis
    }
  }

  &-disabled {
    .@{localePrefix}-input-icon-disabled {
      svg {
        cursor: not-allowed;
        color: var(--invalid-fc);

        &:hover {
          color: var(--invalid-fc);
        }
      }
    }
  }

  &-input-icon {
    svg {
      cursor: pointer;
      color: var(--secondary-fc);

      &:hover {
        color: var(--primary);
      }
    }
  }

  &-input-icon-isSetLocale {
    svg {
      cursor: pointer;
      color: var(--success);

      &:hover {
        color: var(--success);
      }
    }
  }

  &-dialog {

    .@{dialogClsPrefix} {
      &-body {
        padding: 0 var(--v-spacing-lg);
      }

      &-body-container,
      &-footer {
        background-color: var(--bg-white);
      }
    }

    &-header {
      height: calc(var(--hd) * 40);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: var(--secondary-fc);

      .@{localePrefix}-dialog-checkboxBtnWrap {
        display: inline-flex;
        align-items: center;

        &-text {
          cursor: pointer;
          padding: 0 calc(var(--hd) * 2);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: var(--font-size-14);
        }

        &-icon {
          cursor: pointer;
        }
      }
    }

    &-popup {
      width: calc(var(--hd) * 400);
      background: var(--bg-white);
      border: var(--border-width) solid var(--border-color);
      border-radius: var(--border-radius-xs);
      box-shadow: var(--box-shadow);
      margin-top: calc(5 * var(--hd));
      padding: calc(20 * var(--hd)) calc(20 * var(--hd)) calc(5 * var(--hd)) calc(20 * var(--hd));

      &-checkboxWrap {
        display: flex;
        justify-content: center;
      }

      &-checkbox {
        width: 50%;
        height: calc(25 * var(--hd));
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0;

        .@{checkboxClsPrefix}-label {
          width: calc(100% - 14px);
        }
      }

      &-handleBtnWrap {
        margin-top: calc(10 * var(--hd));
        border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
        height: calc(40 * var(--hd));
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &-handleBtn {
        margin-left: calc(6 * var(--hd));
      }
    }

    &-content-alert {
      margin: calc(15 * var(--hd)) 0;
    }

    &-content-TEXTAREA {
      max-width: unset;
    }

    &-footer {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      height: calc(30 * var(--hd));

      &-span {
        margin: 0 8px;
        cursor: pointer;
        color: var(--secondary-fc);
        font-size: var(--font-size-12);

        &:hover {
          color: var(--primary);
        }
      }

      &-gap {
        border-right: 1px solid #979797;
        width: 1px;
        height: 11px;
      }
    }

    &-tabContent {
      &-richText {
        margin-top: calc(4 * var(--hd));
      }

      &-textArea {
        margin-top: calc(4 * var(--hd));
        max-width: unset;
      }
    }
  }

  &-checkbox {
    &-text {
      // font-size: var(--font-size-14);
    }

    &-lang {
      font-size: var(--font-size-12);
      color: var(--secondary-fc);
    }
  }

  &-TEXTAREA,
  &-RICHTEXT {
    display: flex;
  }

  &-TEXTAREA {
    .@{localePrefix}-input-icon {
      margin-left: calc(6 * var(--hd));
      margin-top: calc(6 * var(--hd));
    }
  }

  &-RICHTEXT {
    .@{richTextClsPrefix} {
      flex: 1;
    }

    .@{localePrefix}-input-icon {
      margin-left: calc(6 * var(--hd));
      margin-top: calc(10 * var(--hd));
    }
  }
}

.weapp-ie11 {
  .ui-locale {
    max-width: 100%;
    width: 100%;
  }
}