//日期时间范围面板
.@{dateTimePickerClsPrefix}-rangePanel {
  width: calc(626 * var(--hd));
  font-size: var(--font-size-12);
  display: flex;
  flex-direction: column;
  outline: none;
  z-index: 999;
  background: var(--bg-white);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius-xs);
  box-shadow: var(--box-shadow);
  margin-top: calc(5 * var(--hd));
}

.@{dateTimePickerClsPrefix}-MultiAreaWrap {
  box-sizing: border-box;
  border-bottom: calc(1 * var(--hd)) solid var(--diviling-line-color);
  justify-items: center;
  align-items: center;
}

.@{dateTimePickerClsPrefix}-MultiArea {
  display: grid;
  padding: calc(8 * var(--hd)) calc(10 * var(--hd));
  gap: calc(8 * var(--hd)) 0;
  grid-template-columns: repeat(8, 12.5%);
  place-items: center center;

  .multiBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: calc(12 * var(--hd));
    background-color: var(--label-bc);
    color: var(--regular-fc);
    width: calc(60 * var(--hd));
    height: calc(24 * var(--hd));

    &-isSelect {
      background-color: var(--primary);
      color: var(--base-white);
    }
  }
}

.@{dateTimePickerClsPrefix}-rangeNav {
  box-sizing: border-box;
  height: calc(40 * var(--hd));
  display: flex;
  flex-direction: row;
  border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
  border-bottom: calc(1 * var(--hd)) solid var(--diviling-line-color);

  .buttonWrap {
    width: calc(60 * var(--hd));
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      color: var(--main-fc);
      border: 0;
      background: var(--bg-transparent);
      cursor: pointer;
      outline: none;

      :hover {
        color: var(--primary);
      }
    }
  }

  .titleWrap {
    flex: 1;
    font-size: var(--font-size-md);
    color: var(--main-fc);
    font-weight: var(--font-weight-bold);
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      &:hover {
        color: var(--primary);
        cursor: pointer;
      }
    }
  }

  .whitespaceWrap {
    width: calc(60 * var(--hd));
  }
}

.@{dateTimePickerClsPrefix}-rangeContent {
  box-sizing: border-box;
  height: calc(315 * var(--hd));
  display: flex;
  flex-direction: row;

  .@{dateTimePickerClsPrefix}-dayPanel-dayWrap {
    .cell-hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .item {
    flex: 1;
    display: flex;
    flex-direction: column;

    .@{dateTimePickerClsPrefix}-titleDateNav {
      padding: 0 calc(22 * var(--hd));
    }

    .@{dateTimePickerClsPrefix}-inputNav {
      padding: 0 calc(22 * var(--hd));
    }

    &-content {
      flex: 1;
      padding: 0 calc(20 * var(--hd)) calc(8 * var(--hd));
      display: flex;
      flex-direction: column;
    }
  }
}

.@{dateTimePickerClsPrefix}-rangeFooter {
  box-sizing: border-box;
  height: calc(40 * var(--hd));
  padding: 0 calc(4 * var(--hd));
  border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;

  &-unlimitWrap,
  &-confirmWrap {
    display: flex;

    .item {
      margin: 0 calc(10 * var(--hd));
      height: calc(20 * var(--hd));
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--font-size-sm);
      cursor: pointer;
      color: var(--secondary-fc);
      line-height: 1;
    }
  }

  &-unlimitWrap {
    margin-left: calc(16 * var(--hd));

    .item {
      &:hover {
        color: var(--primary);
      }

      &-isSelect {
        color: var(--primary);
      }

      &-disabled {
        cursor: not-allowed;
        color: var(--invalid-fc);

        &:hover {
          color: var(--invalid-fc);
        }
      }
    }
  }

  &-confirmWrap {
    .confirm {
      color: var(--primary);
      padding: 0 calc(6 * var(--hd));
      border: calc(1 * var(--hd)) solid var(--primary);
      border-radius: var(--border-radius-xs);

      &-disabled {
        color: var(--invalid-fc);
        border: calc(1 * var(--hd)) solid var(--invalid-fc);
        cursor: not-allowed;
      }
    }
  }
}

.@{dateTimePickerClsPrefix}-rangeTitleDateSelectPanel {
  width: 100%;
  height: calc(305 * var(--hd));
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1
  }
}