/**
* 上传区域样式
*/

/*******************************  litType = 'list'  *******************************/
.@{uploadClsPrefix} {

  /** 上传区域外层容器 **/
  &-select-box {
    position: relative;
    display: inline-block;
  }

  &-tip {
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: var(--regular-fc);
    font-size: var(--font-size-12);
    line-height: calc(16 * var(--hd));
    margin-left: calc(3 * var(--hd));
    margin-top: calc(4 * var(--hd));

    &-image {
      width: calc(100 * var(--hd));
      margin-left: calc(8 * var(--hd));
      white-space: pre-wrap;
      word-break: break-word;
      word-wrap: break-word;
      overflow-wrap: break-word;
      line-height: calc(16 * var(--hd));
      text-align: center;
    }

    &-help {
      min-width: calc(140 * var(--hd));
      padding: calc(2 * var(--hd)) calc(12 * var(--hd));
      text-align: center;
    }

    &-help-title {
      line-height: calc(24 * var(--hd));
    }

    .ui-help {
      vertical-align: bottom;
    }
  }

  /** input type='file' 样式 **/
  &-select-input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    visibility: hidden;
    width: 100%;
    cursor: pointer;
    z-index: 0;
  }

  /** 文字信息（按钮） **/
  &-select-title {
    margin-left: calc(8 * var(--hd));
  }

  &-select-drag-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    &-highlight {
      background-color: var(--primary);
      opacity: 0.1;
    }
  }

  /** 支持拖拽 **/
  &-select-drag {
    position: relative;
    border: var(--upload-outline-color) dashed var(--hd);
    padding: calc(3 * var(--hd));
    border-radius: calc(3 * var(--hd));
    display: flex;
    align-items: center;

    &-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-content {
      display: flex;
      align-items: center;
    }

    &:hover {
      border: var(--primary) dashed var(--hd);
      cursor: pointer;
    }

    &:hover {
      border: var(--primary) dashed var(--hd);
      cursor: pointer;
    }

    /** 按钮 **/
    &-btn {
      padding: calc(3 * var(--hd)) calc(7 * var(--hd));
      display: inline-flex;
      align-items: center;
    }

    /** 文字信息（提示） **/
    &-info {
      margin-left: calc(7 * var(--hd));
      font-size: var(--font-size-12);
      color: var(--placeholder-fc);
    }

    /** 高亮显示拖拽 */
    &-highlight {
      border: var(--primary) dashed var(--hd);
    }

    /** 禁用/只读 **/
    &-disabled,
    &-readOnly {
      cursor: not-allowed;
      border: var(--upload-outline-color) dashed var(--hd);

      &:hover {
        cursor: not-allowed;
        border: var(--upload-outline-color) dashed var(--hd);
      }
    }
  }

  &-select-paste {
    &-icon {
      display: inline-flex;
      z-index: 1;
      // color: var(--regular-fc);
      color: var(--secondary-fc);

      &:hover {
        color: var(--primary);
      }
    }

    &-wrap {
      width: 100%;
    }

    &-input {
      border: none;
      background: transparent;
      width: 100%;
      min-height: calc(24 * var(--hd));
      padding: calc(2 * var(--hd)) calc(8 * var(--hd));

      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }
}

/*******************************  litType = 'img'  *******************************/
.@{uploadClsPrefix} {

  /** 外层容器 **/
  &-select-image {
    position: relative;
    width: calc(100 * var(--hd));
    height: calc(100 * var(--hd));
    outline: var(--upload-outline);
    cursor: pointer;
    text-align: center;
    color: var(--secondary-fc);
    margin-left: calc(6 * var(--hd));

    &:hover {
      outline: var(--upload-outline-hover);
    }

    /** 禁用/只读 样式 **/
    &-disabled,
    &-readOnly {
      background: var(--upload-disabled-backgroud);
      border: var(--border-solid);
      outline: none;
      cursor: not-allowed;

      &:hover {
        outline: none;
      }
    }

    &-highlight {
      //   border: var(--primary) dashed var(--hd);
      outline: var(--upload-outline-hover);
    }
  }

  /** 按钮样式 **/
  &-image-select {
    &-box {
      width: 100%;
      height: 100%;

      .@{uploadClsPrefix}-select-paste-icon {
        position: absolute;
        top: 5px;
        left: 6px;
      }
    }

    /** 按钮定为 */
    &-content {
      position: relative;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
    }

    &-paste {
      &-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }

      &-textarea {
        text-align: center;
        border: none;
        background: transparent;

        &:focus {
          border: none;
          box-shadow: none;
        }
      }
    }
  }

  /** 文字信息（按钮） **/
  &-select-image {
    &-add-icon {
      padding-top: calc(30 * var(--hd));
    }

    &-title {
      margin-top: calc(20 * var(--hd));
      padding: 0 calc(4 * var(--hd));
      font-size: var(--font-size-12);
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-select-qrcode {
    width: 100%;
    height: 100%;
    padding: calc(5 * var(--hd));
    color: var(--secondary-fc);

    &-icon {
      position: absolute;
      top: 0;
      right: 0;

      &:hover {
        color: var(--primary);
      }
    }

    &-wrap {
      width: 100%;
      height: 100%;
    }
  }

  // /** 图标样式 **/
  // &-select-image .@{iconClsPrefix}-wrapper {
  //   padding-top: calc(30 * var(--hd));
  // }
}

/*******************************  buttonType = 'drag'  *******************************/
.@{uploadClsPrefix} {

  /** 拖拽区域 **/
  &-select-drag-area {
    position: relative;
    cursor: pointer;
    border: var(--upload-outline-color) dashed var(--hd);
    padding: calc(3 * var(--hd));
    border-radius: calc(3 * var(--hd));
    display: flex;
    align-items: center;

    &:hover {
      border: var(--primary) dashed var(--hd);
    }

    &-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
      padding: calc(10 * var(--hd));
      width: 100%;
    }

    &-icon {
      color: var(--primary);
    }

    &-text,
    &-info {
      margin-top: calc(8 * var(--hd));
    }

    &-text {
      font-size: var(--font-size-14);
      line-height: 1.5;
      color: var(--main-fc);
      width: 100%;
    }

    &-info {
      font-size: var(--font-size-12);
      line-height: 1.5;
      text-align: center;
      color: var(--secondary-fc);
    }

    /** 高亮显示拖拽 */
    &-highlight {
      border: var(--primary) dashed var(--hd);
    }
  }
}

/*******************************  litType = 'img'，图片上传的上传按钮需要已上传的在一行显示  *******************************/
.@{uploadClsPrefix} {
  &-line-of-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: calc(8 * var(--hd));

    /** 上传区域外层容器 **/
    .@{uploadClsPrefix}-select-image {
      padding: calc(8 * var(--hd));
      margin: calc(8 * var(--hd));
    }
  }
}

/*******************************  custom  *******************************/
.@{uploadClsPrefix} {
  &-custom-select {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
}

/*******************************  单图片上传  *******************************/
.@{uploadClsPrefix} {
  &-select-image {
    &-hidden {
      display: none;
    }
  }
}

/******************************* 空数据展示  *******************************/
.@{uploadClsPrefix} {

  /** Upload 无数据且只读/禁用样式 **/
  .@{uploadClsPrefix}-empty-uploader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(3 * var(--hd));
    border-radius: calc(30 * var(--hd));
    font-size: var(--font-size-12);
    color: var(--secondary-fc);
    min-height: calc(30 * var(--hd));
    line-height: 1;
    &.read-only-line-height-standard {
      line-height: var(--form-pc-readonly-line-height);
      padding-top: var(--form-pc-readonly-padding-y);
      padding-bottom: var(--form-pc-readonly-padding-y);
      min-height: auto;
    }
  }
}