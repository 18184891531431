.@{uploadClsPrefix}.@{uploadClsPrefix}-table {
  max-width: 100%;
}

.@{uploadClsPrefix}-table {
  &-panel {
    //border: var(--hd) solid var(--border-color);
    // title 栏目
    &-title {
      border: 0;
      background: transparent;
      min-height: calc(50 * var(--hd));
      padding-right: 0;
      padding-left: 0;

      .@{uploadClsPrefix}-select-title {
        margin: 0;
      }

      .@{titleClsPrefix}-title-top {
        color: var( --main-fc);
        font-size: var(--font-size-14);
      }
    }

    &-search-bar {
      display: inline-block;
      margin-left: var(--h-spacing-md);
    }

    // 列表区域
    &-content {
      &-table {
        border: 0;

        &.is-sortable,
        &.is-checkable {
          .@{tableClsPrefix}-grid-thead {
            .@{tableClsPrefix}-grid-tr:first-child {
              .@{tableClsPrefix}-grid-th:first-child {
                border-top-left-radius: calc(3 * var(--hd));
              }
              .@{tableClsPrefix}-grid-th:last-child {
                border-top-right-radius: calc(3 * var(--hd));
              }
            }
          }
        }
        &:not(.is-sortable),
        &:not(.is-checkable) {
          .@{tableClsPrefix}-grid-tr:first-child {
            .@{tableClsPrefix}-grid-th:nth-child(2) {
              border-top-left-radius: calc(3 * var(--hd));
            }
            .@{tableClsPrefix}-grid-th:last-child {
              border-top-right-radius: calc(3 * var(--hd));
            }
          }
        }

        // &.@{tableClsPrefix}-grid-resizable th:hover {
        //   background-color: var(--table-head-bg);
        // }
        // border-top: var(--hd) solid var(--diviling-line-color);

        @keyframes uploading-row-blink {
          0% {
            opacity: 0.6;
          }
          25% {
            opacity: 1;
            background: #def41d47;
          }
          50% {
            opacity: 0.6;
          }
          75% {
            opacity: 1;
            background: #def41d47;
          }
          100% {
            opacity: 0.6;
          }
        }

        // 正在上传的行
        .uploading-row-tr {
          // 闪烁
          animation: uploading-row-blink 1s 1 linear;
        }

        // 待上传信息
        .uploading-info {
          color: var(--regular-fc);

          &.errorInfo {
            color: var(--danger);
          }

          &.uploading {
            color: var(--primary);
          }
        }
      }

      .@{uploadClsPrefix}-table {

        &-item {
          position: relative;
          padding: calc(6 * var(--hd)) 0;

          // 主附件角标
          &-badge {
            position: absolute;
            left: calc(-14 * var(--hd));
            top: calc(2 * var(--hd));
          }

          .table-item-row {
            display: flex;
            // 主要信息展示区域
            &-main {

              // 附件图标
              .file-icon {
                margin-right: calc(6 * var(--hd));
                margin-top: calc(5 * var(--hd));
                height: calc(22 * var(--hd));
                //display: inline-flex;
                //align-items: center;
              }

              // 不换行
              .item-span:not(.autowrap) {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                max-width: calc(100% - calc(16 * var(--hd)));
              
                .filename {
                  flex: 1 1 auto;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              
                .signtip {
                  flex: 0 0;
                  white-space: nowrap;
                }
              }

              // 附件名称
              .filename {
                &.canPreview {
                  cursor: pointer;

                  &:hover {
                    color: var(--primary);
                  }
                }
              }
              /** 签署状态 **/
              .signtip {
                //margin-top: calc(5 * var(--hd));
                //padding: 0 calc(5 * var(--hd));
                color: var(--upload-signtip-color);
                // line-height: var(--upload-list-item-height);
                //white-space: nowrap;
                // float: left;
                line-height: calc(20 * var(--hd));
                white-space: normal;
                word-wrap: break-word;
                margin-left: calc(4 * var(--hd));
              }
            }
            // 附件信息展示区域
            &-secondary {
              padding-left: calc(22 * var(--hd));

              .ban-tips {
                color: var(--danger);
              }
            }
          }

          &-progress-wrap {
            position: absolute;
            height: calc(2 * var(--hd));
            z-index: 22;
            bottom: 0;
            left: 0;
            &.left-1 {
              left: calc(-50 * var(--hd));
            }
            &.left-2 {
              left: calc(-32 * var(--hd));
            }
            &.left-3 {
              left: calc(-82 * var(--hd));
            }

            .progress {
              position: relative;
              // width: 100%;
              height: calc(2 * var(--hd));
              // background: #5D9CEC;
              background: var(--primary);
              &.error {
                // background: var(--danger);
                background: var(--placeholder-fc);
              }
            }
          }

          &.version {
            cursor: pointer;
            &:hover {
              color: var(--primary);
            }
          }
        }

        // 操作按钮
        &-operates {
          display: flex;
          align-items: center;

          .option-btn {
            display: inline-block;
            color: var(--primary);
            margin: var(--v-spacing-sm);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  //  重命名弹框
  &-rename-content {
    width: 100%;
  }
}

