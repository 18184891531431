@import './constants.less';
@import "../../../style/prefix.less";

.@{mCommentClsPrefix}-upvote {
  & {
    display: inline-block;
    cursor: pointer;
    &-wrap {
      display: flex;
      align-items: center;
    }
  }
  &-name-list {
    display: flex;
    align-items: center;
  }

  &-name-item {
    flex: 1;
    display: flex;
    word-break: keep-all;
    overflow: hidden;
    align-items: center;
  }

  &-name-wrap {

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
  }

  &-name {
    align-items: center;
    word-break: keep-all;
    font-size: var(--font-size-12);

    &:not(:first-child)::before {
      content: '、';
    }
  }

  &-suffix {
    word-break: keep-all;
  }

  &-icon-vote {
    margin-right: calc(7 * var(--hd));
  }

  &-icon-arrow {
    margin-left: calc(15 * var(--hd));
    padding:calc(3 * var(--hd)) calc(5 * var(--hd));
  }

  //日报模块的点赞列表

  &-blog {
    display: flex;
    align-items: center;

    &-avatar {
      flex: 1;
      display: flex;

      .@{mAvatarClsPrefix} {
        margin-right: calc(5 * var(--hd));
      }

      display: flex;
      align-items:center;
    }

    &-more {}
  }
}

//路由层内的样式
.@{mCommentClsPrefix}-upvote-dialog {
  .@{mCommentClsPrefix}-upvote-with-avatar {
    height: 100%;
  }

  .@{mCommentClsPrefix}-upvote-avatar-title {
    background: var(--bg-base);
    padding: 0 calc(16 * var(--hd));
    font-weight: 400;
    color: var(--black-fc);
    height: calc(40 * var(--hd));
    line-height: calc(40 * var(--hd));
  }

  .@{mCommentClsPrefix}-upvote-avatar-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-y: auto;
  }

  .@{mCommentClsPrefix}-upvote-item {
    font-size: var(--font-size-12);
    color: var(--secondary-fc);
    text-align: center;
    width: 20%;
    margin-top: calc(10 * var(--hd));
  }

  .@{mCommentClsPrefix}-upvote-name {
    margin-top: calc(4 * var(--hd));
    &::before {
      content: none;
    }
  }
}

//头像列表平板上显示7个
@media screen and (min-width: 768px) {
  .@{mCommentClsPrefix}-upvote-dialog {
    .@{mCommentClsPrefix}-upvote-item {
      width: 14%;
    }
  }
}