.@{menuClsPrefix}-rightMenu {
  .@{menuClsPrefix}-list-item {
    padding-left: calc(10 * var(--hd));
    padding-right: calc(10 * var(--hd));
    // color: var(--main-fc);
    // .@{iconClsPrefix} {
    //   color: var(--main-fc);
    // }
  }
  .@{menuClsPrefix}-list-item,
  .@{menuClsPrefix}-list-subitem {
    min-width: var(--right-menu-min-width);
  }
  .@{menuClsPrefix}-list-subitem-title {
    padding-left: calc(10 * var(--hd));
    padding-right: calc(10 * var(--hd));
    // color: var(--main-fc);
    // &:hover {
    //   .ui-icon {
    //     color: var(--main-fc);
    //   }
    // }
  }
  .@{menuClsPrefix}-list-item-rightMenu-correct {
    // color: var(--primary);
    // .@{iconClsPrefix} {
    //   color: var(--primary);
    // }
  }
}

.@{menuClsPrefix}-trigger-rightMenu {
  max-width: none;
}

.@{rightMenuClsPrefix}-active-icon {
  margin-right: var(--right-menu-icon-margin-rigth);
  width: var(--right-menu-icon-width);
}
