.weapp-ie11 {

  .rc-cascader-allow-clear.@{cascaderInputClsPrefix}-multiple,
  .rc-cascader-open.@{cascaderInputClsPrefix}-multiple {
    .@{cascaderInputClsPrefix}-selector {
      .@{cascaderInputClsPrefix}-selection-overflow {
        max-height: 150px;
        overflow-y: scroll;
        padding-right: 16px;
      }
    }
  }
}


// --------------------selector通用样式--------------------
.rc-cascader-allow-clear.@{cascaderInputClsPrefix}-multiple,
.rc-cascader-open.@{cascaderInputClsPrefix}-multiple {
  .@{cascaderInputClsPrefix}-selector {
    min-height: 28px;
    flex: 1 1 auto;
    border: none;
    cursor: pointer;
    // width: 100%;
    padding-right: 0;

    &:hover {
      border-color: var(--input-hover-border-color);
    }

    .@{cascaderInputClsPrefix}-selection-overflow {
      max-height: 150px;
      overflow-y: scroll;
    }

    //多选input框中item处理
    .@{cascaderInputClsPrefix}-selection-item {
      display: inline-flex;
      border: 1px solid rgba(229, 229, 229, 1);
      background: #F9F9F9;
      border-radius: 4px;
      padding-top: 2px;
      padding-bottom: 2px;
      margin: 2px 0;
      color: var(--main-fc);

      >span {
        // max-width: 140px;
        font-size: var(--font-size-12);
      }

      // &-content {
      //   padding: 0 4px;
      // }
      &-remove {
        margin-left: 4px;
      }

      &-content-disabled {
        color: var(--invalid);
      }
    }

    .@{cascaderInputClsPrefix}-selection-overflow-item {
      // max-width: unset;
      width: 100%;

      &-suffix {
        display: none;
      }
    }
  }

  &:focus {
    .@{cascaderInputClsPrefix}-selector {
      border-color: var(--input-focus-border-color);

      box-shadow: var(--input-focus-shadow);
      outline: 0;
    }
  }
}

// --------------------当没有数据时需要特殊处理下--------------------
.rc-cascader-allow-clear.@{cascaderInputClsPrefix}-multiple-empty,
.rc-cascader-allow-clear.@{cascaderInputClsPrefix}-multiple-empty {
  .@{cascaderInputClsPrefix}-selector {
    .@{cascaderInputClsPrefix}-selection-overflow {
      display: none;
    }
  }
}

// --------------------禁用需要特殊处理下--------------------
.rc-cascader-disabled.@{cascaderClsPrefix}-multiple-disabled {
  background-color: var(--label-bc);

  .rc-cascader-selector {
    cursor: not-allowed;
    opacity: 1;
    color: var(--input-disabled-color);

    .rc-cascader-selection-item {
      opacity: 1;
      color: var(--input-disabled-color);
      background: var(--label-bc);
    }
  }
}