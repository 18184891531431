/**
* 外层容器样式
*/
.@{mUploadClsPrefix}-expand-btn {
  height: calc(40 * var(--hd));
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-white);
  /* width: 75px; */
  /* height: 17px; */
  font-family: PingFangSC-Regular;
  font-size: var(--font-size-12);
  color: var(--secondary-fc);
  text-align: right;
  font-weight: var(--font-weight-base);

  /** 下标线 */
  .border-solid-then;

  &-label {
    margin-right: calc(8 * var(--hd));
  }
}
