@unitTime: 100ms;

.pathChildren(@counter) when (@counter > 0) {
  path:nth-child(@{counter}) {
    animation-delay: (@unitTime * @counter);
  }
  .pathChildren(@counter - 1);// 递归调用自身
}

// 反向
.pathChildrenReversal(@counter, @length) when (@counter <= @length) {
  path:nth-child(@{counter}) {
    animation-delay: (@unitTime * (@length - (@counter - 1)));
  }
  .pathChildrenReversal(@counter + 1, @length);// 递归调用自身
}

.@{mSoundRecordingClsPrefix} {
  &-container {
    text-align: center;
    height: 100%;
    &-body {
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: calc(45 * var(--hd));
    }
  }
  &-hidden {
    display: none;
  }
  &-button {
    width: calc(77 * var(--hd));
    height: calc(77 * var(--hd));
    border-radius: 50%;
    position: relative;
    display: block;
    border: calc(4 * var(--hd)) solid #DAE2EB;
    padding: calc(8 * var(--hd));
    background-color: var(--m-sound-recording-button-color);
    background-clip: content-box;
    margin: 0 calc(24 * var(--hd));
  }    
  &-press &-button {
    &::after {
      content: "";
      position: absolute;
      top: calc(8 * var(--hd));
      left: calc(8 * var(--hd));
      right: calc(8 * var(--hd));
      bottom: calc(8 * var(--hd));
      border-radius: 50%;
      background-color: var(--m-sound-recording-button-mask-color);
      z-index: 9;
      opacity: 0.25;
    }
  }
  &-dialog {
    .@{mDialogClsPrefix}-top {
      height: 100%;
    }
  }
  &-duration {
    font-size: var(--font-size-12);
    color: var(--secondary-fc);
    padding: calc(16 * var(--hd)) 0;
    opacity: 0;
    top: calc(16 * var(--hd));
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }  
  &-press &-duration {
    opacity: 1;
  }
  &-title {
    padding-top: calc(8 * var(--hd));
    font-size: var(--font-size-12);
    color: var(--secondary-fc);
  }
  &-spread {
    height: calc(22 * var(--hd));
    width: calc(67 * var(--hd));
    &-svg {
      path {
        fill: none;
        stroke: #5D9CEC;
        stroke-width: 2;
        stroke-linecap:round;
        stroke-linejoin: round;
        // animation: sizeChange (@unitTime * 12) infinite;
      }
      // .pathChildren(12);
    }
    .@{iconClsPrefix}-svg {
      height: 100%;
      width: 100%;
    }
  }
  &-voice {
    opacity: 0;
    &-reversal {
      transform: rotateY(180deg);
      // .@{mSoundRecordingClsPrefix}-spread-svg {
      //   .pathChildrenReversal(1, 12);
      // }
    }
  }
  &-press &-voice {
    opacity: 1;
  }
  
}


@keyframes sizeChange {
  0% {
    transform: translateY(calc((1 - 0.3) * 107px + (21.36px - 50%))) scaleY(0.3);
  }

  50% {
    transform: translateY(0px) scaleY(1);
  }

  100% {
    transform: translateY(calc((1 - 0.3) * 107px + (21.36px - 50%))) scaleY(0.3);
  }
}