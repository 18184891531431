.@{repeatFrequencyClsPrefix}-rule {
  &-radio-option:nth-child(2) {
    margin-top: calc(10 * var(--hd));
  }

  &-radio-option {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: calc(12 * var(--hd));
    // line-height: calc(50 * var(--hd));
    input {
      width: calc(80 * var(--hd));
      margin: 0 calc(10 * var(--hd));
    }

    .ui-select {
      margin: 0 calc(10 * var(--hd));
    }

    &-handler {
      margin-right: calc(10 * var(--hd));
      .ui-radio-input {
        width: calc(14 * var(--hd));
      }
    }
  }

  &-custom-select {
    .ui-scroller__wrap {
      margin: 0 !important;
    }
  }
}
