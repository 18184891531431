.@{mListClsPrefix} {
  display: inline-block;
  // overflow: hidden;
  height: 100%;

  .switcher-off {
    &:after {
      transform: rotate(0deg);
    }
  }
  &-none {
    width: 35px;
  }
}
.@{mListClsPrefix}-grid-expandable-btn{
  width: 35px;
}
.@{mListClsPrefix}-grid-expandable-btn-switcher {
  position: absolute;
  //left: -18px;
  width: 16px;
  height: 16px;
  // line-height: 2;
  display: inline-block;
  vertical-align: middle;
  border: 0 none;
  cursor: pointer;
  outline: none;
  // top: 6.5px;
  &:after {
    border: 5px solid transparent;
    border-left-color: #999999;
    color: #646566;
    content: '';
    display: inline-block;
    border-left-width: 7px;
    transform: rotate(90deg);
    transform-origin: 23.09% 50%;
    transition: transform 0.3s ease;
    pointer-events: none;
  }
}
// 横向布局
.@{mListClsPrefix}-row .@{mListClsPrefix}-item {
  float: left;
}
.@{listClsPrefix}-row .@{listClsPrefix}-header {
  float: none;
}
.@{mListClsPrefix}-row .@{mListClsPrefix}-customitem {
  float: left;
}
.@{mListClsPrefix}-row .@{mListClsPrefix}-body {
  overflow: hidden;
  zoom: 1;
}
// 横向显示,超出显示滚动不换行
.@{mListClsPrefix}-row .@{mListClsPrefix}-body-scroll {
  white-space: nowrap;
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
// 纵向布局
.@{mListClsPrefix}-column {
  width: 100%;
}
.@{mListClsPrefix}-scrollview {
  height: 100%;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  &.hasFooter {
    height: calc(100% - 24px);
  }
}
// .@{mListClsPrefix}-column .@{mListClsPrefix}-body {
//   border-bottom: var(--hd) solid var(--diviling-line-color);
// }
// .@{mListClsPrefix}-column .@{mListClsPrefix}-body-noBorder {
//   border: none;
// }
.@{mListClsPrefix}-column .@{mListClsPrefix}-line {
  min-height: calc(35 * var(--hd));
  line-height: 100%;
}
.@{mListClsPrefix}-column
  .@{mListClsPrefix}-body
  div:not(:last-child)
  .@{mListClsPrefix}-content {
  position: relative;
  &:after{
    position: absolute;
    content: " ";
    height: calc(1 * var(--hd));
    width: 100%;
    left: 0;
    bottom: 0;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform: scaleY(0.3);
    background-color: var(--diviling-line-color);
    pointer-events: none;
  }
}
.@{mListClsPrefix}-column
  .@{mListClsPrefix}-body
  .@{mListClsPrefix}-item.@{mListClsPrefix}-item-noBorder
  .@{mListClsPrefix}-content {
  border: none;
}
.@{mListClsPrefix}-column .@{mListClsPrefix}-item {
  position: relative;
  display: flex;
  // min-height: calc(35 * var(--hd));
  background-color: var(--bg-white);
  vertical-align: middle;
  overflow: hidden;
  transition: background-color 200ms;
  align-items: center;
  &.isActive {
    background-color: #edf4ff;
  }
  & > *:not(.@{mListClsPrefix}-content) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-multiple {
    padding: calc(5 * var(--hd)) calc(15 * var(--hd)) calc(5 * var(--hd)) 0;
    .@{mListClsPrefix}-content {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &-wrap {
    .@{mListClsPrefix}-content {
      white-space: normal;
    }
  }
  &-checkbox-right {
    position: absolute;
    right: 0;
  }

  &-swipe {
    &-sortable {
      z-index: 998;
    }

  }

  /* list左图片显示*/
  .@{mListClsPrefix}-thumb {
    width: calc(35 * var(--hd));
    text-align: center;
    // &:first-child {
    //   // margin-right: calc(16 * var(--hd));
    // }

    // &:last-child {
    //   // margin-left: calc(8 * var(--hd));
    // }
    &-img {
      width: calc(16 * var(--hd));
      height: calc(16 * var(--hd));
      vertical-align: middle;
    }
  }
  .@{mListClsPrefix}-check.ui-m-checkbox-wrapper {
    display: none;
  }
  .@{mListClsPrefix}-order {
    // display: inline-block;
    width: calc(35 * var(--hd));
    color: var(--secondary-fc);
    text-align: center;
  }
  &-noorder {
    .@{mListClsPrefix}-check.ui-m-checkbox-wrapper {
      display: flex;
      width: calc(35 * var(--hd));
      text-align: center;
      padding: 0;
      height: calc(35 * var(--hd));
      padding-left: calc(5 * var(--hd));
      align-items: center;
    }
  }
  &:hover {
    .@{mListClsPrefix}-check.ui-m-checkbox-wrapper {
      display: flex;
      width: calc(35 * var(--hd));
      text-align: center;
    }
    .@{mListClsPrefix}-order {
      display: none;
    }
  }
  &-col {
    flex: 1 1;
  }
  &-row {
    font-size: var(--font-size-14);
    color: var(--list-color);
    &-span {
      display: inline-block;
      word-break: break-word;
      &-text-highlight {
        color: var(--main-fc);
        font-weight: bold;
      }
    }
  }
}
/* list左侧主内容*/
.@{mListClsPrefix}-content {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: calc(35 * var(--hd));
  color: var(--black-fc);
  font-size: var(--font-size-12);
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .ellipsis();
}

.@{mListClsPrefix}-header-item-noorder {
  .@{mListClsPrefix}-check.ui-m-checkbox-wrapper {
    display: flex;
    text-align: center;
    width: calc(35 * var(--hd));
    height: calc(35 * var(--hd));
    padding-left: calc(5 * var(--hd));
    align-items: center;
  }
}


.@{mListClsPrefix}-indexedlist-container {
  position: relative;
}
.@{mListClsPrefix}-quick-search-bar {
  position: absolute;
  top: 0;
  right: calc(20 * var(--hd));
  z-index: 0;
  text-align: center;
  font-size: var(--font-size-10);
  list-style: none;
  padding: 0;
  cursor: pointer;
  &-item {
    list-style-type:none;
    padding: 2px;
    &.active {
      color: var(--primary);
    }
  }
}
.@{mListClsPrefix}-header-line {
  display: inline-block;
}
.@{mListClsPrefix}-footer-more,
.@{mListClsPrefix}-footer-nomore,
.@{mListClsPrefix}-footer-loading {
  color: #999999;
  font-size: var(--font-size-12);
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.@{mListClsPrefix}-footer-more {
  color: #5d9cec;
}
.@{mListClsPrefix}-pagination {
  margin-top: 10px;
}

// 镜像样式
.@{mListClsPrefix}-rtl.@{mListClsPrefix}-sortable {
  transform: scaleX(-1);
  direction: rtl;

  .@{mListClsPrefix}-content{
    >*{
      transform: scaleX(-1);
      direction: ltr;
    }
    text-align: right;
  }

  .@{mListClsPrefix}-header,
  .@{mListClsPrefix}-footer {
    transform: scaleX(-1);
  }

  .@{mListClsPrefix}-item-checkbox-right {
    left: 0;
    right: unset;
  }

  .@{mListClsPrefix}-body-scroll{
    direction: ltr;
  }
}