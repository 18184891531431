.@{layoutClsPrefix} {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  &-has-side {
    flex-direction: row;
  }
  &-children {
    flex: 1 1 auto;
    overflow: hidden;
  }
  .layout-box-block {
    flex: 0 0 auto;
    display: block;
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    transition: var(--transition-w), var(--transition-h);
    &.is-dragging {
      transition: none;
    }
    &.is-hidden {
      min-width: 0;
    }
  }
  &-inner-container {
    width: 100%;
    flex: 1 1 auto;
  }
  &-content.content-col {
    overflow-x: auto;
    width: 100%;
    flex: 1 1 auto;
  }
}

.@{layoutClsPrefix}-box-wrap {
  position: relative;
  height: 100%;
  width: 100%;
  .@{layoutClsPrefix}-box-show-btn {
    // display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    box-sizing: border-box;
    color: var(--secondary-fc);
    cursor: pointer;
    background: var(--bg-white);
    z-index: 100;
    border: var(--border-solid);
    &:hover {
      background-color: var(--bg-base);
      // box-shadow: var(--box-shadow);
    }
    .@{iconClsPrefix}-svg {
      width: var(--show-btn-size);
      height: var(--show-btn-size);
    }
    &.is-outer {
      z-index: 99; /* 如果内外层的按钮重叠，确保内层的显示在上层 */
    }
    &.show-btn-left,
    &.show-btn-right {
      top: 50%;
      transform: translateY(-50%);
      width: var(--show-btn-width);
      height: var(--show-btn-height);
      border-radius: var(--show-btn-border-radius);
    }
    &.show-btn-left {
      right: calc(-1 * var(--show-btn-width));
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      // &:hover {
      //   box-shadow: 5px 5px 10px -4px #ddd, 5px -5px 10px -4px #ddd;
      // }
    }
    &.show-btn-right {
      left: calc(-1 * var(--show-btn-width));
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      // &:hover {
      //   box-shadow: -5px -5px 10px -4px #ddd, -5px 5px 10px -4px #ddd;
      // }
    }
    &.show-btn-top,
    &.show-btn-bottom {
      left: 50%;
      transform: translateX(-50%);
      width: var(--show-btn-height);
      height: var(--show-btn-width);
      border-radius: var(--show-btn-border-radius);
    }
    &.show-btn-top {
      top: calc(-1 * var(--show-btn-width));
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      // &:hover {
      //   box-shadow: -5px -5px 10px -4px #ddd, 5px -5px 10px -4px #ddd;
      // }
    }
    &.show-btn-bottom {
      bottom: calc(-1 * var(--show-btn-width));
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      // &:hover {
      //   box-shadow: -5px 5px 10px -4px #ddd, 5px 5px 10px -4px #ddd;
      // }
    }
  }
}

.@{layoutClsPrefix}-box {
  max-width: 100%;
  &.is-hidden {
    // visibility: hidden;
  }
  &.is-none {
    // display: none;
  }
  &.is-draggable {

  }
  &-resize {
    cursor: col-resize;
    z-index: 100;
    width: calc(8 * var(--hd));
    height: 100%;
    position: absolute;
    top: 0;
    &.show-resize-left {
      right: 0;
    }
    &.show-resize-right {
      left: 0;
    }
  }
}

.weapp-ie11 .ui-layout-header.header-inner-col {
  flex-shrink: 0; // 兼容处理IE11顶部显示不全问题
}

// Grid system
.@{layoutClsPrefix}-row {
  display: flex;
  flex-flow: row wrap;

  &::before,
  &::after {
    display: flex;
  }

  // No wrap of flex
  &-no-wrap {
    flex-wrap: nowrap;
  }
}

// x轴原点
.@{layoutClsPrefix}-row-start {
  justify-content: flex-start;
}

// x轴居中
.@{layoutClsPrefix}-row-center {
  justify-content: center;
}

// x轴反方向
.@{layoutClsPrefix}-row-end {
  justify-content: flex-end;
}

// x轴平分
.@{layoutClsPrefix}-row-space-between {
  justify-content: space-between;
}

// x轴有间隔地平分
.@{layoutClsPrefix}-row-space-around {
  justify-content: space-around;
}

// 顶部对齐
.@{layoutClsPrefix}-row-top {
  align-items: flex-start;
}

// 居中对齐
.@{layoutClsPrefix}-row-middle {
  align-items: center;
}

// 底部对齐
.@{layoutClsPrefix}-row-bottom {
  align-items: flex-end;
}

.@{layoutClsPrefix}-col {
  position: relative;
  max-width: 100%;
  // Prevent columns from collapsing when empty
  min-height: 1px;
}

.@{layoutClsPrefix}-col-0 {
  display: block;
  flex: 0 0 0;
  max-width: 0;
}
.@{layoutClsPrefix}-col-push-0 {
  left: auto;
}
.@{layoutClsPrefix}-col-pull-0 {
  right: auto;
}
.@{layoutClsPrefix}-col-offset-0 {
  margin-left: 0;
}
.@{layoutClsPrefix}-col-order-0 {
  order: 0;
}

.@{layoutClsPrefix}-col-1 {
  display: block;
  flex: 0 0 var(--col-1);
  max-width: var(--col-1);
}
.@{layoutClsPrefix}-col-push-1 {
  left: var(--col-1);
}
.@{layoutClsPrefix}-col-pull-1 {
  right: var(--col-1);
}
.@{layoutClsPrefix}-col-offset-1 {
  margin-left: var(--col-1);
}
.@{layoutClsPrefix}-col-order-1 {
  order: 1;
}

.@{layoutClsPrefix}-col-2 {
  display: block;
  flex: 0 0 var(--col-2);
  max-width: var(--col-2);
}
.@{layoutClsPrefix}-col-push-2 {
  left: var(--col-2);
}
.@{layoutClsPrefix}-col-pull-2 {
  right: var(--col-2);
}
.@{layoutClsPrefix}-col-offset-2 {
  margin-left: var(--col-2);
}
.@{layoutClsPrefix}-col-order-2 {
  order: 2;
}

.@{layoutClsPrefix}-col-3 {
  display: block;
  flex: 0 0 var(--col-3);
  max-width: var(--col-3);
}
.@{layoutClsPrefix}-col-push-3 {
  left: var(--col-3);
}
.@{layoutClsPrefix}-col-pull-3 {
  right: var(--col-3);
}
.@{layoutClsPrefix}-col-offset-3 {
  margin-left: var(--col-3);
}
.@{layoutClsPrefix}-col-order-3 {
  order: 3;
}

.@{layoutClsPrefix}-col-4 {
  display: block;
  flex: 0 0 var(--col-4);
  max-width: var(--col-4);
}
.@{layoutClsPrefix}-col-push-4 {
  left: var(--col-4);
}
.@{layoutClsPrefix}-col-pull-4 {
  right: var(--col-4);
}
.@{layoutClsPrefix}-col-offset-4 {
  margin-left: var(--col-4);
}
.@{layoutClsPrefix}-col-order-4 {
  order: 4;
}

.@{layoutClsPrefix}-col-5 {
  display: block;
  flex: 0 0 var(--col-5);
  max-width: var(--col-5);
}
.@{layoutClsPrefix}-col-push-5 {
  left: var(--col-5);
}
.@{layoutClsPrefix}-col-pull-5 {
  right: var(--col-5);
}
.@{layoutClsPrefix}-col-offset-5 {
  margin-left: var(--col-5);
}
.@{layoutClsPrefix}-col-order-5 {
  order: 5;
}

.@{layoutClsPrefix}-col-6 {
  display: block;
  flex: 0 0 var(--col-6);
  max-width: var(--col-6);
}
.@{layoutClsPrefix}-col-push-6 {
  left: var(--col-6);
}
.@{layoutClsPrefix}-col-pull-6 {
  right: var(--col-6);
}
.@{layoutClsPrefix}-col-offset-6 {
  margin-left: var(--col-6);
}
.@{layoutClsPrefix}-col-order-6 {
  order: 6;
}

.@{layoutClsPrefix}-col-7 {
  display: block;
  flex: 0 0 var(--col-7);
  max-width: var(--col-7);
}
.@{layoutClsPrefix}-col-push-7 {
  left: var(--col-7);
}
.@{layoutClsPrefix}-col-pull-7 {
  right: var(--col-7);
}
.@{layoutClsPrefix}-col-offset-7 {
  margin-left: var(--col-7);
}
.@{layoutClsPrefix}-col-order-7 {
  order: 7;
}

.@{layoutClsPrefix}-col-8 {
  display: block;
  flex: 0 0 var(--col-8);
  max-width: var(--col-8);
}
.@{layoutClsPrefix}-col-push-8 {
  left: var(--col-8);
}
.@{layoutClsPrefix}-col-pull-8 {
  right: var(--col-8);
}
.@{layoutClsPrefix}-col-offset-8 {
  margin-left: var(--col-8);
}
.@{layoutClsPrefix}-col-order-8 {
  order: 8;
}

.@{layoutClsPrefix}-col-9 {
  display: block;
  flex: 0 0 var(--col-9);
  max-width: var(--col-9);
}
.@{layoutClsPrefix}-col-push-9 {
  left: var(--col-9);
}
.@{layoutClsPrefix}-col-pull-9 {
  right: var(--col-9);
}
.@{layoutClsPrefix}-col-offset-9 {
  margin-left: var(--col-9);
}
.@{layoutClsPrefix}-col-order-9 {
  order: 9;
}

.@{layoutClsPrefix}-col-10 {
  display: block;
  flex: 0 0 var(--col-10);
  max-width: var(--col-10);
}
.@{layoutClsPrefix}-col-push-10 {
  left: var(--col-10);
}
.@{layoutClsPrefix}-col-pull-10 {
  right: var(--col-10);
}
.@{layoutClsPrefix}-col-offset-10 {
  margin-left: var(--col-10);
}
.@{layoutClsPrefix}-col-order-10 {
  order: 10;
}

.@{layoutClsPrefix}-col-11 {
  display: block;
  flex: 0 0 var(--col-11);
  max-width: var(--col-11);
}
.@{layoutClsPrefix}-col-push-11 {
  left: var(--col-11);
}
.@{layoutClsPrefix}-col-pull-11 {
  right: var(--col-11);
}
.@{layoutClsPrefix}-col-offset-11 {
  margin-left: var(--col-11);
}
.@{layoutClsPrefix}-col-order-11 {
  order: 11;
}

.@{layoutClsPrefix}-col-12 {
  display: block;
  flex: 0 0 var(--col-12);
  max-width: var(--col-12);
}
.@{layoutClsPrefix}-col-push-12 {
  left: var(--col-12);
}
.@{layoutClsPrefix}-col-pull-12 {
  right: var(--col-12);
}
.@{layoutClsPrefix}-col-offset-12 {
  margin-left: var(--col-12);
}
.@{layoutClsPrefix}-col-order-12 {
  order: 12;
}

.@{layoutClsPrefix}-col-13 {
  display: block;
  flex: 0 0 var(--col-13);
  max-width: var(--col-13);
}
.@{layoutClsPrefix}-col-push-13 {
  left: var(--col-13);
}
.@{layoutClsPrefix}-col-pull-13 {
  right: var(--col-13);
}
.@{layoutClsPrefix}-col-offset-13 {
  margin-left: var(--col-13);
}
.@{layoutClsPrefix}-col-order-13 {
  order: 13;
}

.@{layoutClsPrefix}-col-14 {
  display: block;
  flex: 0 0 var(--col-14);
  max-width: var(--col-14);
}
.@{layoutClsPrefix}-col-push-14 {
  left: var(--col-14);
}
.@{layoutClsPrefix}-col-pull-14 {
  right: var(--col-14);
}
.@{layoutClsPrefix}-col-offset-14 {
  margin-left: var(--col-14);
}
.@{layoutClsPrefix}-col-order-14 {
  order: 14;
}

.@{layoutClsPrefix}-col-15 {
  display: block;
  flex: 0 0 var(--col-15);
  max-width: var(--col-15);
}
.@{layoutClsPrefix}-col-push-15 {
  left: var(--col-15);
}
.@{layoutClsPrefix}-col-pull-15 {
  right: var(--col-15);
}
.@{layoutClsPrefix}-col-offset-15 {
  margin-left: var(--col-15);
}
.@{layoutClsPrefix}-col-order-15 {
  order: 15;
}

.@{layoutClsPrefix}-col-16 {
  display: block;
  flex: 0 0 var(--col-16);
  max-width: var(--col-16);
}
.@{layoutClsPrefix}-col-push-16 {
  left: var(--col-16);
}
.@{layoutClsPrefix}-col-pull-16 {
  right: var(--col-16);
}
.@{layoutClsPrefix}-col-offset-16 {
  margin-left: var(--col-16);
}
.@{layoutClsPrefix}-col-order-16 {
  order: 16;
}

.@{layoutClsPrefix}-col-17 {
  display: block;
  flex: 0 0 var(--col-17);
  max-width: var(--col-17);
}
.@{layoutClsPrefix}-col-push-17 {
  left: var(--col-17);
}
.@{layoutClsPrefix}-col-pull-17 {
  right: var(--col-17);
}
.@{layoutClsPrefix}-col-offset-17 {
  margin-left: var(--col-17);
}
.@{layoutClsPrefix}-col-order-17 {
  order: 17;
}

.@{layoutClsPrefix}-col-18 {
  display: block;
  flex: 0 0 var(--col-18);
  max-width: var(--col-18);
}
.@{layoutClsPrefix}-col-push-18 {
  left: var(--col-18);
}
.@{layoutClsPrefix}-col-pull-18 {
  right: var(--col-18);
}
.@{layoutClsPrefix}-col-offset-18 {
  margin-left: var(--col-18);
}
.@{layoutClsPrefix}-col-order-18 {
  order: 18;
}

.@{layoutClsPrefix}-col-19 {
  display: block;
  flex: 0 0 var(--col-19);
  max-width: var(--col-19);
}
.@{layoutClsPrefix}-col-push-19 {
  left: var(--col-19);
}
.@{layoutClsPrefix}-col-pull-19 {
  right: var(--col-19);
}
.@{layoutClsPrefix}-col-offset-19 {
  margin-left: var(--col-19);
}
.@{layoutClsPrefix}-col-order-19 {
  order: 19;
}

.@{layoutClsPrefix}-col-20 {
  display: block;
  flex: 0 0 var(--col-20);
  max-width: var(--col-20);
}
.@{layoutClsPrefix}-col-push-20 {
  left: var(--col-20);
}
.@{layoutClsPrefix}-col-pull-20 {
  right: var(--col-20);
}
.@{layoutClsPrefix}-col-offset-20 {
  margin-left: var(--col-20);
}
.@{layoutClsPrefix}-col-order-20 {
  order: 20;
}

.@{layoutClsPrefix}-col-21 {
  display: block;
  flex: 0 0 var(--col-21);
  max-width: var(--col-21);
}
.@{layoutClsPrefix}-col-push-21 {
  left: var(--col-21);
}
.@{layoutClsPrefix}-col-pull-21 {
  right: var(--col-21);
}
.@{layoutClsPrefix}-col-offset-21 {
  margin-left: var(--col-21);
}
.@{layoutClsPrefix}-col-order-21 {
  order: 21;
}

.@{layoutClsPrefix}-col-22 {
  display: block;
  flex: 0 0 var(--col-22);
  max-width: var(--col-22);
}
.@{layoutClsPrefix}-col-push-22 {
  left: var(--col-22);
}
.@{layoutClsPrefix}-col-pull-22 {
  right: var(--col-22);
}
.@{layoutClsPrefix}-col-offset-22 {
  margin-left: var(--col-22);
}
.@{layoutClsPrefix}-col-order-22 {
  order: 22;
}

.@{layoutClsPrefix}-col-23 {
  display: block;
  flex: 0 0 var(--col-23);
  max-width: var(--col-23);
}
.@{layoutClsPrefix}-col-push-23 {
  left: var(--col-23);
}
.@{layoutClsPrefix}-col-pull-23 {
  right: var(--col-23);
}
.@{layoutClsPrefix}-col-offset-23 {
  margin-left: var(--col-23);
}
.@{layoutClsPrefix}-col-order-23 {
  order: 23;
}

.@{layoutClsPrefix}-col-24 {
  display: block;
  flex: 0 0 var(--col-24);
  max-width: var(--col-24);
}
.@{layoutClsPrefix}-col-push-24 {
  left: var(--col-24);
}
.@{layoutClsPrefix}-col-pull-24 {
  right: var(--col-24);
}
.@{layoutClsPrefix}-col-offset-24 {
  margin-left: var(--col-24);
}
.@{layoutClsPrefix}-col-order-24 {
  order: 24;
}
