.@{atClsPrefix} {
  cursor: pointer;

  &-contaienr {
    width: var(--at-container-size);
    background: var(--bg-white);
    border: var(--border-solid);
    border-radius: var(--border-radius-xs);
    font-size: var(--font-size-12);
    color: var(--main-fc);
    box-shadow: var(--box-shadow);

    &-rtl {
      .@{atClsPrefix}-list-nodata {
        .@{emptyClsPrefix} {
          &-description {
            transform: scaleX(1) !important;
          }
        }
      }
    }
  }

  &-menu-wrap {
    padding: 0 calc(10 * var(--hd));
    .@{menuClsPrefix} {
      padding: 0;
    }
  }

  &-menu {
    border-bottom: 0;

    .@{menuClsPrefix}-tab-prev .ui-icon {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    .@{menuClsPrefix}-tab-next .ui-icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    .@{menuClsPrefix}-list .@{menuClsPrefix}-list-item {
      border: 0;
    }
  }

  &-virtualmenu {
    margin-bottom: calc(5 * var(--hd));
  }

  &-input {
    margin: var(--v-spacing-sm) var(--h-spacing-md);
  }

  &-list {
    display: block;

    .@{listClsPrefix}-item {
      min-height: var(--at-item-size);
    }

    .@{listClsPrefix}-content {
      min-height: var(--at-item-size);
    }

    &-item {
      display: flex;
      align-items: center;
      width: 100%;
      height: var(--at-item-size);
      line-height: var(--at-item-size);
      padding: 0 var(--h-spacing-md);
      cursor: pointer;

      &-active {
        background: var(--label-bc);
      }

      &-icon {
        margin-right: var(--v-spacing-md);
        .@{iconClsPrefix}-svg {
          width: calc(20 * var(--hd));
          height: calc(20 * var(--hd));
        }
      }

      .@{avatarClsPrefix} {
        margin-right: var(--v-spacing-md);
        vertical-align: middle;

        &-container {
          width: calc(20 * var(--hd));
          height: calc(20 * var(--hd));
        }
      }

      &:hover {
        background: var(--label-bc);
      }

      &-name {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        vertical-align: middle;
        margin-right: auto;
      }

      &-public {
        padding: 0 calc(8 * var(--hd));
        color: var(--secondary-fc);
      }
    }

    &-overlay {
      max-width: calc(180 * var(--hd));

      & > span {
        word-break: break-word;

        &:not(:last-child)::after {
          content: "";
          border-left: 1px solid var(--base-white);
          padding-left: calc(5 * var(--hd));
          margin-left: calc(5 * var(--hd));
        }
      }
    }

    &-joiners {
      position: relative;
      top: -1px;
      padding: 0 var(--v-spacing-md);
      margin-top: calc(2 * var(--hd));
      /* 一条@数据的高度加1px的顶部边距高度 */
      min-height: calc(var(--at-item-size) + (6 * var(--hd)));

      &:hover {
        background: var(--label-bc);
      }

      &-inner-wrap {
        border-top: var(--border-solid);

        .@{atClsPrefix}-list-item {
          padding: 0;
          color: var(--primary);
          height: calc(35 * var(--hd));
        }
      }
    }

    &-nodata {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-nodata,
    &-loading {
      color: var(--invalid);
      text-align: center;
      height: calc(10 * var(--at-item-size));
      line-height: var(--at-item-size);
    }

    &-loading {
      color: var(--main-fc);
    }
  }
}
