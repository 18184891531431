//头部标题组件
.@{datePickerClsPrefix}-titleDateNav,
.@{dateTimePickerClsPrefix}-titleDateNav,
.@{dateQuickFilterClsPrefix}-titleDateNav {
  width: 100%;
  height: calc(40 * var(--hd));
  box-sizing: border-box;
  display: flex;
  padding: 0 calc(5 * var(--hd));

  &-btnWrap {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-fc);

    &:hover {
      color: var(--primary);
    }

    &>span {
      cursor: pointer;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    color: var(--main-fc);
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-bold);

    span {
      &:hover {
        color: var(--primary);
        cursor: pointer;
      }
    }
  }
}