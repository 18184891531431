@borderEBEBEB: calc(1 * var(--hd)) solid var(--diviling-line-color);

.fc {
  @fcColHeaderCellHeight: calc(34 * var(--hd));
  font-size: var(--font-size-12);
  font-family: var(--regular-ff);
  color: var(--regular-fc);

  .fc-col-header {
    .fc-col-header-cell {
      height: @fcColHeaderCellHeight;
      border-left: 0;
      border-right: 0;
      border-bottom: 0;

      .fc-scrollgrid-sync-inner {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-base);
        font-family: PingFangSC-Regular;
        font-weight: var(--font-weight-base);
      }

      a {
        color: var(--main-fc);
      }

      .fc-col-header-cell-wrap {
        display: flex;
        align-items: center;

        .fc-day-sign-icon-wrap {
          display: flex;
          margin-left: calc(6 * var(--hd));
          min-width: calc(18 * var(--hd));
          min-height: calc(18 * var(--hd));
        }

        .fc-day-sign-icon {
          width: calc(18 * var(--hd));
          height: calc(18 * var(--hd));
        }
      }
    }

    .fc-timegrid-axis {
      .fc-timegrid-axis-frame {
        height: 100%;
        justify-content: center;
        color: var(--secondary-fc);
      }
    }
  }

  .fc-col-header-rtl {
    transform: scaleX(-1);
  }

  .fc-daygrid-day-top {
    position: relative;
    height: calc(36 * var(--hd));
    display: flex;
    align-items: center;
    padding-left: calc(16 * var(--hd));
    padding-right: calc(16 * var(--hd));

    .fc-daygrid-day-number {
      font-family: Helvetica-Bold;
      font-size: calc(14 * var(--hd));
      color: var(--regular-fc);
      padding: 0;
      font-weight: var(--font-weight-bold);
    }

    .fc-day-sign {
      // margin-left: calc(12 * var(--hd));
    }

    .fc-day-sign-text {
      position: absolute;
      top: 0;
      right: 0;
      padding-left: calc(2 * var(--hd));
      padding-top: calc(2 * var(--hd));
      z-index: 5;

      .fc-day-sign-icon-wrap {
        display: flex;
        // margin-left: calc(6 * var(--hd));
        min-width: calc(14 * var(--hd));
        min-height: calc(14 * var(--hd));
      }

      .fc-day-sign-icon {
        width: calc(14 * var(--hd));
        height: calc(14 * var(--hd));
      }
    }

    .fc-day-sign-textBox {
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      width: 0;
      height: 0;
      border: calc(30 * var(--hd)) solid;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
      opacity: 0.2;
      border-right: 0;
    }

    .fc-day-sign-icon-wrap {
      margin-left: calc(6 * var(--hd));
      min-width: calc(18 * var(--hd));
      min-height: calc(18 * var(--hd));
    }

    .fc-day-sign-icon {
      width: calc(18 * var(--hd));
      height: calc(18 * var(--hd));
    }

    .fc-day-cnDate-wrap {
      height: 100%;
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: var(--font-size-12);
      color: var(--placeholder-fc);
      min-width: calc(20 * var(--hd));
    }

    .fc-day-cnDate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .fc-daygrid-event-harness,
  .fc-timegrid-event-harness,
  .fc-event-dragging {
    .fc-event-main {
      width: 100%;
      font-size: var(--font-size-12);
      color: var(--regular-fc);
    }
  }

  .fc-event-dragging-rtl {
    transform: scaleX(-1);
  }

  .fc-timegrid-body-rtl {
    .fc-event-dragging-rtl {
      transform: scaleX(1);
    }
  }

  .fc-scrollgrid-section-body {
    .fc-scroller-harness {
      color: var(--secondary-fc);
      font-size: var(--font-size-12);
    }
  }

  .fc-timegrid-slots {
    .fc-timegrid-slot-label {
      color: var(--secondary-fc);
      font-size: var(--font-size-12);
    }
  }

  .fc-daygrid-day-events,
  .fc-popover {
    .fc-daygrid-event {
      display: flex;
      align-items: center;
      height: calc(24 * var(--hd));
      padding-left: calc(10 * var(--hd));
      padding-right: calc(10 * var(--hd));
      margin-top: 0;
      margin-bottom: calc(4 * var(--hd));
      margin-left: calc(4 * var(--hd));
      margin-right: calc(4 * var(--hd));
    }
  }

  .fc-scrollgrid-section-header {
    .fc-scroller {
      height: calc(34 * var(--hd));
      border-bottom: @borderEBEBEB;
    }
  }

  .fc-view-harness {
    .fc-more-popover {
      padding: calc(16 * var(--hd)) calc(16.37 * var(--hd));
      box-shadow: 0 calc(3 * var(--hd)) calc(12 * var(--hd)) 0 rgba(0, 0, 0, 0.12);
      border-radius: calc(3 * var(--hd));

      .fc-popover-header {
        padding: 0 0 calc(4 * var(--hd)) 0;
        background: inherit;

        .fc-popover-title {
          font-size: var(--font-size-12);
        }
      }

      .fc-popover-weeklabel {
        line-height: 1;
        padding-bottom: calc(8 * var(--hd));
        font-family: Helvetica-Bold;
        font-size: calc(22 * var(--hd));
        color: var(--black-fc);
        font-weight: 700;
      }

      .fc-popover-body {
        padding: 0;
      }
    }

    .fc-daygrid-day-bottom {
      margin-top: calc(6 * var(--hd));
      margin-left: calc(4 * var(--hd));
      margin-right: calc(4 * var(--hd));
      font-size: var(--font-size-12);

      .fc-daygrid-more-link::after {
        content: "v";
        position: absolute;
        right: calc(-12 * var(--hd));
        top: 50%;
        transform: translateY(-50%) scaleX(1.4);
        font-size: var(--font-size-10);
      }
    }
  }

  .fc-day-today {
    .fc-daygrid-day-top {
      .fc-day-cnDate {
        color: var(--primary);
      }

      .fc-daygrid-day-number {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--medium-ff);
        font-size: var(--font-size-12);
        color: var(--base-white);
        font-weight: var(--font-weight-bold);
        width: calc(24 * var(--hd));
        height: calc(24 * var(--hd));
        background: var(--primary);
        border-radius: 100%;
      }
    }
  }

  .fc-scrollgrid-sync-table {
    .fc-timegrid-axis-frame {
      justify-content: center;
    }
  }

  .fc-dayWeek-panel.fc-daygrid-day-bottom {
    text-align: center;

    a {
      color: var(--secondary-fc);
    }

    .fc-daygrid-more-link {
      display: inline-block;
    }

    .fc-dayWeek-panel-count {
      display: flex;
      width: calc(16 * var(--hd));
      height: calc(16 * var(--hd));
      justify-content: center;
      align-items: center;
      border: var(--border-solid);
      border-color: var(--secondary-fc);
      border-radius: 100%;
    }
  }

  .fc-timegrid-col-frame {
    .fc-timegrid-now-indicator-container {
      overflow: inherit;

      .fc-timegrid-now-indicator-arrow {
        right: calc(-3 * var(--hd));
        margin-top: calc(-3 * var(--hd));
        left: auto;
        border-color: var(--fc-now-indicator-color, red);
        border-width: initial;
        border-radius: 100%;
      }
    }
  }

  .fc-scrollgrid-section-liquid {
    .fc-timegrid-slots {
      border-bottom: @borderEBEBEB;
    }
  }

  // icon
  @font-face {
    font-family: "iconfont";
    src: url("data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAAPwAAsAAAAACMwAAAOjAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCDKgqEeIREATYCJAMUCwwABCAFhG0HQRvBBxHVm5PIfiTYto7TB1iSS1ebocFMEPD9mfMQLIxTQf+kApIlC59Mn5MAAWOba45ATpioySe3tyOPvpC2j/lLM/rhfqaL0tkwn23t8mPVidGA4wENqK/bjqRADsjvAZ7hwgQ+DAG4Ey2TaNWmUy+saISjBCDGjxk1BKvLjo5hCqwmc8iFGlGBCasslNeAcv33xVdqZAWJSSGc2G1k6+E0+xD+ca9yuBz0VwkoyvMCt8tAAZlAgxgb6h+OMvOZFO6fak09YBU3SfgQ8aHRx70uF9HClX3xWnEnP8jc/3gahBlB74nUZjo9ZgpZMZIKtKK+hkYogICPe5UrRW9SD7sDXsBxEKlMTVSsmyod7ePj5SkjCiq8epp92aqVSzesC+xlW1q0yRFk9Frs2FwcYjeWFG91Bg95AAznQyn7/ffGvXdz7zEc97WG7yYPHvRfukUo59bizUjHJrtn0Rbb4uF1R3CELITRLGeIfVm3I7DXYqMo2LY0vy/IWPJOf7A9/Pgw9Xp4exctrcS6cf1FkfhGKzcGGevXbGq46OCBWYbNhcasAwfFqqvxK+KPHTTzxxIKEq76UGP9MVwd+rGhldPZasMhCdEQq6MfZ7SU+ox4gpSeNbt3obzBpEkNyteyZdIk6ujCRv38ud5IiCq/sdstPy+bl1+ke/VmQnBiRa6LjT7lF1Hw61dhRJxW+ft3wVu8VrjYyyPiw5avXREWj6vTWa2UPitalVLUdodny6Hb9YcPGjXbryiq+aMXX7v/8GjmXbbWu5nHj2NTLT0uTLvgtfbixansWs9shC8rKbPAtV9dVR314l51XXU+Wf6G7xN83Qb5NPpv9dAAPKtuvB6jrr2adAb2uCK14tRQpJzSX5XgMOl3r4y/AybqOZJIOBNUp0QZ/L0oxxiUuhkEVoJskFiIAoWVRKKmZoIJD2qBGStNwZ0MbS/3IMBUFEK7AelyAQS+doLEm5Og8HWdqKnPwUSw72DGNwXu2K92N9zaV22OjErQgv5DZah3kks2RusN/btV7FTp7BdyrFKQJ5m3dMUeuYxT4scXIg4cUwcXeBy2LcHAVKORJIgMZZq6sE9KDHWjjSOGFIEsoP2BikF6Tl0sNr6fv0HeW0vhmL4f+RfEomp1kEtkKYhXfZ+qb1s2Rx9eQQjHhxxGOuCCXtSKeQKG8F01ZIhEyJEdlFKa59Lqk/qN3TJeYjTaEG42TwoltDAJM/NbijjRuaQ72DHTjEYA") format("woff2");
  }

  .iconfont {
    font-family: "iconfont";
    // font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-clock:before {
    content: "\e7b3";
  }

  .icon-close:before {
    content: "\e63c";
  }

  .icon-b1:before {
    content: "\e61a";
  }

  .icon-b9:before {
    content: "\e61b";
  }

  // icon
}

.fc-timeGridWeek-view,
.fc-timeGridDay-view {
  background-color: #ffffff;

  .fc-scrollgrid-liquid {
    >tbody {
      >tr:first-child {
        >td {
          position: relative;
          z-index: 2;
          border-bottom: 0;
          box-shadow: 0 calc(3 * var(--hd)) calc(12 * var(--hd)) 0 rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  .fc-scrollgrid-section-liquid {
    .fc-timegrid-slots {
      border-bottom: 0;

      tr>td {
        border-width: 0;
      }

      tr.on-the-hour {
        >td {
          border-width: calc(1 * var(--hd));
        }

        .fc-timegrid-slot-label {
          border-width: 0;
        }
      }
    }

    .fc-timegrid-slot-label {
      width: calc(66 * var(--hd));
      border-top: 0;
    }
  }

  .fc-timegrid-cols {
    .fc-event-title-container {
      overflow: hidden;
      padding-top: calc(8 * var(--hd));
      padding-left: calc(10 * var(--hd));
      padding-right: calc(10 * var(--hd));
    }

    .fc-event-time {
      margin-top: calc(4 * var(--hd));
    }
  }

  .fc-event-title {
    line-height: calc(18 * var(--hd));
  }
}

.fc-timeGridWeek-view,
.fc-timeGridDay-view {
  .fc-scrollgrid-liquid {
    >tbody {
      >tr:first-child {
        // background-color: #F7FBFF;
      }
    }
  }

  .fc-day-tr {
    .fc-timegrid-axis {
      background-color: #f7fbff;
    }
  }

  .fc-timegrid-cols {
    .fc-timegrid-axis {
      background-color: #f7fbff;
    }
  }
}

.fc-dayGridMonth-view {
  background-color: #fff;

  .fc-scrollgrid-section.fc-scrollgrid-section-liquid {
    >td {
      border-bottom-color: #fff;
    }
  }

  .fc-event-time {
    display: none;
  }
}

@arrow: calc(-5 * var(--hd));
@arrowWidth: calc(10 * var(--hd)); // 2*@arrow
// #calendar-event-title-popver-conatiner {
//     .title-content {
//         font-family: PingFangSC-Regular;
//         max-width: calc(208 * var(--hd));
//         opacity: 0.7;
//         color: var(--regular-fc);
//         background: #f0f0f0;
//         padding: calc(4 * var(--hd)) calc(6 * var(--hd));
//         box-shadow: 0 calc(3 * var(--hd)) calc(6 * var(--hd)) calc(-4 * var(--hd)) rgba(0,0,0,0.12);
//         border-radius: calc(3 * var(--hd));
//         font-size: var(--font-size-12);
//         border: calc(1 * var(--hd)) solid #CECECE;
//         // .popver-arrow {
//         //     background: #f0f0f0;
//         //     position: absolute;
//         //     width: @arrowWidth;
//         //     height: @arrowWidth;
//         //     transform: translateY(-50%) rotate(45deg);
//         // }
//         .title {
//             display: inline-block;
//             font-size: var(--font-size-12);
//             line-height: calc(12 * var(--hd));
//         }

//     }
//     .title-content.hidden {
//         .popver-arrow {
//             display: none;
//         }
//     }
//     .title-content.left {
//         .popver-arrow {
//             right: @arrow;
//             top: 50%;
//         }
//     }
//     .title-content.top {
//         .popver-arrow {
//             bottom: calc(-9 * var(--hd));
//         }
//     }
//     .title-content.bottom {
//         .popver-arrow {
//             top: 0;
//         }
//     }
//     .title-content.fixed-top {
//         .popver-arrow {
//             bottom: calc(-9 * var(--hd));
//         }
//     }
//     .title-content.fixed-bottom {
//         .popver-arrow {
//             top: 0;
//         }
//     }
// }