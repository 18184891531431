//单个天数面板组件
.@{datePickerClsPrefix}-dayPanel,
.@{dateTimePickerClsPrefix}-dayPanel,
.@{dateQuickFilterClsPrefix}-dayPanel {
  width: calc(280 * var(--hd));
  font-size: var(--font-size-12);
  display: inline-flex;
  flex-direction: column;
  outline: none;
  z-index: 999;
  background: var(--bg-white);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius-xs);
  box-shadow: var(--box-shadow);
  margin-top: calc(5 * var(--hd));

  //周面板天数面板容器
  &-content {
    height: calc(230 * var(--hd));
    padding: calc(2 * var(--hd)) calc(10 * var(--hd)) calc(8 * var(--hd));
    display: flex;
    flex-direction: column;
  }

  //周面板
  &-weekWrap {
    display: flex;
    height: calc(30 * var(--hd));

    .cell {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--main-fc);
      font-weight: var(--font-weight-bold);
    }
  }

  //天数面板
  &-dayWrap {
    flex: 1;
    height: 100%;
    display: flex;
    flex-flow: row wrap;

    .cell {
      flex: 0 0 (1/7 * 100%);
      cursor: default;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: var(--secondary-fc);

      .isInSelectRange,
      .isInHoverRange {
        background-color: var(--hover-bc);
      }

      .left {
        height: calc(24 * var(--hd));
        line-height: calc(24 * var(--hd));
        flex: 1;
      }

      .right {
        height: calc(24 * var(--hd));
        line-height: calc(24 * var(--hd));
        flex: 1;
      }

      .middle {
        width: calc(24 * var(--hd));
        height: calc(24 * var(--hd));
        line-height: calc(24 * var(--hd));
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
          background-color: var(--hover-bc);
        }

        &-isCurrentMonth {
          color: var(--main-fc);
        }

        &-isToday {
          border: calc(2 * var(--hd)) solid var(--primary);
          border-radius: var(--border-radius-xs);
        }

        &-isSelect,
        &-isFirstSelect,
        &-isSecondHover {
          background-color: var(--primary);
          border-radius: var(--border-radius-xs);
          color: var(--base-white);

          &:hover {
            background-color: var(--primary);
          }
        }
      }

      .isBeforeFirstSelectDate {
        color: var(--secondary-fc);
        pointer-events: none;
      }

      .isDisabled {
        cursor: not-allowed;
        color: var(--secondary-fc);
        background-color: var(--label-bc);

        &:hover {
          cursor: not-allowed;
          background-color: var(--label-bc);
        }
      }
    }
  }

  //确认按钮
  &-footer {
    &-quick {
      height: calc(40 * var(--hd));
      padding: 0 calc(10 * var(--hd));
      border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
      display: flex;

      .item {
        flex: 1;
        border: 0;
        background: var(--bg-transparent);
        height: calc(40 * var(--hd));
        line-height: calc(40 * var(--hd));
        outline: none;
        font-size: var(--font-size-12);
        color: var(--main-fc);
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:hover {
          color: var(--primary);
        }

        &-disabled:hover {
          color: var(--invalid-fc);
        }
      }
    }

    &-confirm {
      box-sizing: border-box;
      height: calc(40 * var(--hd));
      padding: 0 calc(4 * var(--hd));
      border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      .cancel,
      .confirm {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        height: calc(40 * var(--hd));
        line-height: calc(40 * var(--hd));
        margin: 0 calc(10 * var(--hd));
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        background: var(--bg-transparent);
        cursor: pointer;
        outline: none;
        font-size: var(--font-size-12);
        color: var(--primary);
        cursor: pointer;
      }

      .cancel {
        color: var(--regular-fc);
      }
    }
  }
}

//月份面板组件
.@{datePickerClsPrefix}-monthPanel,
.@{dateTimePickerClsPrefix}-monthPanel,
.@{dateQuickFilterClsPrefix}-monthPanel {
  width: calc(280 * var(--hd));
  font-size: var(--font-size-12);
  display: inline-flex;
  flex-direction: column;
  outline: none;
  z-index: 999;
  background: var(--bg-white);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius-xs);
  box-shadow: var(--box-shadow);
  margin-top: calc(5 * var(--hd));

  //月份面板容器
  &-content {
    height: calc(230 * var(--hd));
    padding: calc(10 * var(--hd));
    display: flex;
    flex-direction: column;
  }

  //月份面板
  &-monthWrap {
    flex: 1;
    height: 100%;
    display: flex;
    flex-flow: row wrap;

    &-cell {
      flex: 0 0 (1/3 * 100%);
      cursor: default;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: var(--main-fc);

      .isInSelectRange,
      .isInHoverRange {
        background-color: var(--hover-bc);
      }

      .left {
        height: calc(24 * var(--hd));
        line-height: calc(24 * var(--hd));
        flex: 1;
      }

      .right {
        height: calc(24 * var(--hd));
        line-height: calc(24 * var(--hd));
        flex: 1;
      }

      .middle {
        height: calc(24 * var(--hd));
        line-height: calc(24 * var(--hd));
        // width: calc(40 * var(--hd));
        min-width: calc(60 * var(--hd));
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
          background-color: var(--hover-bc);
        }

        &-isThisMonth {
          color: var(--primary);
          font-weight: var(--font-weight-bold);
        }

        &-isSelect,
        &-isFirstSelect,
        &-isSecondHover {
          background-color: var(--primary);
          border-radius: var(--border-radius-xs);
          color: var(--base-white);
          font-weight: var(--font-weight-base);

          &:hover {
            background-color: var(--primary);
          }
        }
      }

      .isBeforeFirstSelectDate {
        color: var(--secondary-fc);
        pointer-events: none;
      }

      .isDisabled {
        cursor: not-allowed;
        color: var(--secondary-fc);
        background-color: var(--label-bc);

        &:hover {
          cursor: not-allowed;
          background-color: var(--label-bc);
        }
      }
    }
  }

  //确认按钮
  &-footer {
    &-quick {
      height: calc(40 * var(--hd));
      padding: 0 calc(10 * var(--hd));
      border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
      display: flex;

      .item {
        flex: 1;
        border: 0;
        background: var(--bg-transparent);
        height: calc(40 * var(--hd));
        line-height: calc(40 * var(--hd));
        outline: none;
        font-size: var(--font-size-12);
        color: var(--main-fc);
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:hover {
          color: var(--primary);
        }

        &-disabled:hover {
          color: var(--invalid-fc);
        }
      }
    }

    &-confirm {
      box-sizing: border-box;
      height: calc(40 * var(--hd));
      padding: 0 calc(4 * var(--hd));
      border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      .cancel,
      .confirm {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        height: calc(40 * var(--hd));
        line-height: calc(40 * var(--hd));
        margin: 0 calc(10 * var(--hd));
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        background: var(--bg-transparent);
        cursor: pointer;
        outline: none;
        font-size: var(--font-size-12);
        color: var(--primary);
        cursor: pointer;
      }

      .cancel {
        color: var(--regular-fc);
      }
    }
  }
}

//年份面板组件
.@{datePickerClsPrefix}-yearPanel,
.@{dateTimePickerClsPrefix}-yearPanel,
.@{dateQuickFilterClsPrefix}-yearPanel {
  width: calc(280 * var(--hd));
  font-size: var(--font-size-12);
  display: inline-flex;
  flex-direction: column;
  outline: none;
  z-index: 999;
  background: var(--bg-white);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius-xs);
  box-shadow: var(--box-shadow);
  margin-top: calc(5 * var(--hd));

  //年份面板容器
  &-content {
    height: calc(230 * var(--hd));
    padding: calc(10 * var(--hd));
    display: flex;
    flex-direction: column;
  }

  //年份面板
  &-yearWrap {
    flex: 1;
    height: 100%;
    display: flex;
    flex-flow: row wrap;

    &-cell {
      flex: 0 0 (1/3 * 100%);
      cursor: default;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: var(--main-fc);

      .isInSelectRange,
      .isInHoverRange {
        background-color: var(--hover-bc);
      }

      .left {
        height: calc(24 * var(--hd));
        line-height: calc(24 * var(--hd));
        flex: 1;
      }

      .right {
        height: calc(24 * var(--hd));
        line-height: calc(24 * var(--hd));
        flex: 1;
      }

      .middle {
        height: calc(24 * var(--hd));
        line-height: calc(24 * var(--hd));
        width: calc(40 * var(--hd));
        display: flex;
        align-items: center;
        justify-content: center;


        &:hover {
          background-color: var(--hover-bc);
          cursor: pointer;
        }

        &-isThisYear {
          color: var(--primary);
          font-weight: var(--font-weight-bold);
        }

        &-isSelect,
        &-isFirstSelect,
        &-isSecondHover {
          background-color: var(--primary);
          border-radius: var(--border-radius-xs);
          color: var(--base-white);
          font-weight: var(--font-weight-base);

          &:hover {
            background-color: var(--primary);
          }
        }
      }

      .isBeforeFirstSelectDate {
        color: var(--secondary-fc);
        pointer-events: none;
      }

      .isDisabled {
        cursor: not-allowed;
        color: var(--secondary-fc);
        background-color: var(--label-bc);

        &:hover {
          cursor: not-allowed;
          background-color: var(--label-bc);
        }
      }
    }
  }

  //确认按钮
  &-footer {
    &-quick {
      height: calc(40 * var(--hd));
      padding: 0 calc(10 * var(--hd));
      border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
      display: flex;

      .item {
        flex: 1;
        border: 0;
        background: var(--bg-transparent);
        height: calc(40 * var(--hd));
        line-height: calc(40 * var(--hd));
        outline: none;
        font-size: var(--font-size-12);
        color: var(--main-fc);
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:hover {
          color: var(--primary);
        }

        &-disabled:hover {
          color: var(--invalid-fc);
        }
      }
    }

    &-confirm {
      box-sizing: border-box;
      height: calc(40 * var(--hd));
      padding: 0 calc(4 * var(--hd));
      border-top: calc(1 * var(--hd)) solid var(--diviling-line-color);
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      .cancel,
      .confirm {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        height: calc(40 * var(--hd));
        line-height: calc(40 * var(--hd));
        margin: 0 calc(10 * var(--hd));
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        background: var(--bg-transparent);
        cursor: pointer;
        outline: none;
        font-size: var(--font-size-12);
        color: var(--primary);
        cursor: pointer;
      }

      .cancel {
        color: var(--regular-fc);
      }
    }
  }
}