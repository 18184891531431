@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes btns4idx1 {
  from {
    right: calc(44 * var(--hd));
    bottom: calc(100 * var(--hd));
  }

  to {
    right: calc(40 * var(--hd));
    bottom: calc(190 * var(--hd));
  }
}

@keyframes btns4idx2 {
  from {
    right: calc(44 * var(--hd));
    bottom: calc(100 * var(--hd));
  }

  to {
    right: calc(105 * var(--hd));
    bottom: calc(170 * var(--hd));
  }
}

@keyframes btns4idx3 {
  from {
    right: calc(44 * var(--hd));
    bottom: calc(100 * var(--hd));
  }

  to {
    right: calc(130 * var(--hd));
    bottom: calc(108 * var(--hd));
  }
}

@keyframes btns4idx4 {
  from {
    right: calc(44 * var(--hd));
    bottom: calc(100 * var(--hd));
  }

  to {
    right: calc(110 * var(--hd));
    bottom: calc(45 * var(--hd));
  }
}

@keyframes btns3idx1 {
  from {
    right: calc(44 * var(--hd));
    bottom: calc(100 * var(--hd));
  }

  to {
    right: calc(50 * var(--hd));
    bottom: calc(190 * var(--hd));
  }
}

@keyframes btns3idx2 {
  from {
    right: calc(44 * var(--hd));
    bottom: calc(100 * var(--hd));
  }

  to {
    right: calc(120 * var(--hd));
    bottom: calc(120 * var(--hd));
  }
}

@keyframes btns3idx3 {
  from {
    right: calc(44 * var(--hd));
    bottom: calc(100 * var(--hd));
  }

  to {
    right: calc(110 * var(--hd));
    bottom: calc(40 * var(--hd));
  }
}

@keyframes btns2idx1 {
  from {
    right: calc(44 * var(--hd));
    bottom: calc(100 * var(--hd));
  }

  to {
    right: calc(44 * var(--hd));
    bottom: calc(180 * var(--hd));
  }
}

@keyframes btns2idx2 {
  from {
    right: calc(44 * var(--hd));
    bottom: calc(100 * var(--hd));
  }

  to {
    right: calc(124 * var(--hd));
    bottom: calc(100 * var(--hd));
  }
}

@keyframes btns1idx1 {
  from {
    right: calc(44 * var(--hd));
    bottom: calc(100 * var(--hd));
  }

  to {
    right: calc(44 * var(--hd));
    bottom: calc(180 * var(--hd));
  }
}