.@{mSearchbarClsPrefix} {
  display: flex;
  align-items: center;

  .@{mSearchbarClsPrefix}-prepend,
  .@{mSearchbarClsPrefix}-append {
    flex: 0 0 auto;
  }

  .@{mSearchbarClsPrefix}-prepend {
    margin-right: var(--h-spacing-sm);
    position: relative;

    .@{mMenuClsPrefix} {
      width: calc(72 * var(--hd));
      min-height: calc(30 * var(--hd));

      &,
      &-wrap {
        border-radius: calc(15 * var(--hd));
      }

      &-content {
        position: fixed;
        margin-top: var(--v-spacing-md);
        left: 0;
        right: 0;
      }

      &-mask {
        top: auto;
        margin-top: var(--m-serach-bar-height);
      }

      &-top {
        height: calc(30 * var(--hd));
        line-height: calc(30 * var(--hd));
        border-bottom-color: transparent;

        &::after {
          background-color: var(--bg-transparent);
        }

        &-left-content {
          display: flex;
          align-items: center;
        }

        &-text {
          color: var(--main-fc);
        }
      }
    }
  }

  .@{mSearchbarClsPrefix}-append {
    margin-left: var(--h-spacing-sm);
  }

  .@{mSearchbarClsPrefix}-cancel {
    font-size: var(--font-size-14);
    color: var(--primary);
    padding: calc(5 * var(--hd)) calc(10 * var(--hd));
  }

  .@{mSearchbarClsPrefix}-input {
    flex: 1 1 auto;

    .@{inputClsPrefix}-wrap {
      border-radius: calc(15 * var(--hd));
      border: none;
      box-shadow: none;
      min-height: calc(30 * var(--hd));
      width: 100%;

      .ui-icon {
        color: var(--secondary-fc);
      }

      &,
      .ui-input {
        background: var(--m-actionsheet-bg-color);
      }

      .@{inputClsPrefix}-suffix {
        > .ui-icon {
          margin-left: calc(14 * var(--hd));
        }
      }
    }

    .@{inputClsPrefix} {
      font-size: var(--font-size-base);

      &::placeholder {
        font-size: var(--font-size-base);
        color: var(--invalid);
      }
    }
    .@{inputClsPrefix},
    .@{inputClsPrefix}-wrap {
      &:hover {
        border-color: transparent;
      }
    }
  }

  &.disabled {
    .@{mSearchbarClsPrefix}-input {
      .ui-input-wrap {
        .ui-icon {
          opacity: 0.7;
        }

        .ui-input {
          &:hover {
            &::placeholder {
              color: var(--invalid);
            }
          }
        }
      }
    }
  }
}

// .@{mSearchAdvancedClsPrefix} {
//   .@{mSearchbarClsPrefix} {
//     .@{mSearchbarClsPrefix}-prepend {
//       .@{mMenuClsPrefix} {
//         &-content {
//           margin-left: calc(0 - var(--h-spacing-md));
//         }
//       }
//     }
//   }
// }
