.@{mPhotoViewClsPrefix} {
  height: 100%;

  .PhotoView {

    &-PhotoSlider__custom {
      display: none;
    }


    &-PhotoSlider__ArrowLeft,
    &-PhotoSlider__ArrowRight {
      display: none;
    }

    &-PhotoSlider__Backdrop {
      opacity: 1;
    }

    &-PhotoSlider__BannerWrap {
      background-color: var(--bg-transparent);
    }
  }

  .PhotoView {
    &-MobileToolBar {
      z-index: 2000;
      position: absolute;
      height: 50px;
      bottom: 5%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 10px;

      &-iconWrap {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: #444444;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 6px;

        .toolBarBtn {
          color: #ffffff;
        }
      }
    }
  }

  &-rtl {
    transform: scaleX(-1);
  }
}

//详情信息弹框
.@{mPhotoViewClsPrefix}-infoDialog {
  .@{mDialogClsPrefix}-wrap {
    z-index: 2001;
  }

  .@{mDialogClsPrefix}-mask {
    z-index: 2000;
  }

  .@{mDialogClsPrefix}-content {
    border-radius: 16px 16px 0 0;
  }

  .@{mDialogClsPrefix}-top-bottom {
    padding: 0;
  }

  &-wrap {
    &-title {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .empty,
      .close {
        width: 60px;
        color: #999999;
      }

      .title {
        height: 100%;
        flex: 1;
        font-size: 18px;
        color: #333333;
      }
    }

    &-content {
      padding: 16px;
      padding-top: 8px;
      margin-bottom: 20px;

      .intro {
        font-size: var(--font-size-16);
        color: #111111;
        text-align: left;
      }

      .info {
        margin-top: 10px;
        font-size: var(--font-size-12);
        color: #666666;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .item:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

// 加载失败
.@{mPhotoViewClsPrefix}-broken {
  svg {
    width: 150px;
    height: 150px;
    color: #999999;
  }
}