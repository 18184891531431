/* 基础元素公共样式 */
.@{menuClsPrefix} {
  position: relative;
  font-size: var(--font-size-12);
  color: var(--main-fc);

  &-limit {
    // tab | card | secondTab限制平铺内容宽度
    .@{menuClsPrefix}-list-item-content {
      max-width: var(--menu-item-content-width);
    }
  }

  // nav相关
  &-bar,
  &-nav-container {
    position: relative;
    box-sizing: border-box;
    white-space: nowrap;
  }

  &-tab-prev,
  &-tab-next {
    position: absolute;
    cursor: pointer;
    color: var(--secondary-fc);
  }

  &-tab-btn-disabled {
    cursor: not-allowed;
    color: var(--invalid);
  }

  &-nav-wrap {
    overflow: hidden;
  }

  &-nav {
    box-sizing: border-box;
    padding-left: 0;
    position: relative;
    margin: 0;
    float: left;
    list-style: none;
    transform-origin: 0 0;

    &-animated {
      transition: transform 0.5s @easing-in-out;
    }
  }

  // list listItem listSubItem相关
  &-list {
    .@{menuClsPrefix}-parent-icon {

      .@{menuClsPrefix}-list-item-lefticon,
      .@{menuClsPrefix}-list-item-activeicon {
        .@{iconClsPrefix} {
          .@{iconClsPrefix}-svg {
            width: var(--font-size-16);
            height: var(--font-size-16);
          }
        }
      }

      .@{menuClsPrefix}-list-item-activeicon {
        width: var(--font-size-16);
        height: var(--font-size-16);
      }
    }

    .@{menuClsPrefix}-children-icon {
      .@{menuClsPrefix}-list-item-activeicon {
        width: var(--font-size-16);
        height: var(--font-size-16);
      }
    }

    .@{menuClsPrefix}-parent-margin {
      .@{menuClsPrefix}-list-item-content {
        margin-left: calc(22 * var(--hd));
      }
    }

    .@{menuClsPrefix}-children-margin {
      .@{menuClsPrefix}-list-item-content {
        margin-left: calc(19 * var(--hd));
      }
    }
  }

  // item subItem
  &-list-item,
  &-list-subitem-title {
    position: relative;
    display: flex;
    align-items: center;
    height: var(--menu-item-height);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;

    .@{iconClsPrefix} {
      color: var(--secondary-fc);
    }

    &:hover {
      color: var(--primary);

      .@{iconClsPrefix} {
        color: var(--primary);
      }
    }
  }



  &-list-item {

    &-lefticon,
    &-activeicon {
      // item左侧图标位置
      display: flex;
      align-items: center;
      margin-right: var(--h-spacing-sm);
    }

    &-righticon {
      display: flex;
      align-items: center;
      margin-left: calc(6 * var(--hd));
    }

    &-content-wrap {
      display: grid;
      display: -ms-grid;
      display: -moz-grid;
    }

    &-content {
      // item内容
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1 1 auto;
      line-height: initial;
    }

    &-indent-1{
      text-indent: calc(1 * var(--font-size-12));
    }

    &-indent-2{
      text-indent: calc(2 * var(--font-size-12));
    }

    &-indent-3{
      text-indent: calc(3 * var(--font-size-12));
    }

    &-indent-4{
      text-indent: calc(4 * var(--font-size-12));
    }

    &-indent-5{
      text-indent: calc(5 * var(--font-size-12));
    }

    &-indent-6{
      text-indent: calc(6 * var(--font-size-12));
    }

    &-indent-7{
      text-indent: calc(7 * var(--font-size-12));
    }

    &-indent-8{
      text-indent: calc(8 * var(--font-size-12));
    }

    &-indent-9{
      text-indent: calc(9 * var(--font-size-12));
    }

    &-indent-10{
      text-indent: calc(10 * var(--font-size-12));
    }

    &-disabled {
      // 禁用
      cursor: not-allowed !important;
      color: var(--invalid) !important;

      &:hover {
        background: var(--bg-transparent) !important;
      }

      .@{iconClsPrefix} {
        color: var(--invalid);
      }

      &:hover {
        color: var(--invalid);

        .@{iconClsPrefix} {
          color: var(--invalid);
        }
      }
    }

    &-active {
      // item的active效果
      color: var(--primary);

      .@{menuClsPrefix}-list-item-righticon {
        color: var(--primary);
      }

      .@{iconClsPrefix} {
        color: var(--primary);
      }

      &-hover {
        color: var(--primary);
        background-color: var(--menu-item-hover-bc);

        .@{iconClsPrefix} {
          color: var(--primary);
        }
      }
    }

    &-cutline {
      float: left;
      width: 100%;
      height: calc(1 * var(--hd));
      background: var(--border-color);
      margin: var(--v-spacing-md) 0;
    }
  }

  .@{menuClsPrefix}-parent-icon {
    .@{menuClsPrefix}-list-item-lefticon {
      .@{iconClsPrefix} {
        .@{iconClsPrefix}-svg {
          width: var(--font-size-16);
          height: var(--font-size-16);
        }
      }
    }
    &.copy {
      user-select: none;
      .@{menuClsPrefix}-list-item-lefticon, .@{menuClsPrefix}-list-item-content {
        user-select: none;
      }
    }
  }

  // 弹出层样式
  &-trigger {
    box-shadow: var(--box-shadow);
    min-width: var(--menu-trigger-width-min);
    max-width: var(--menu-trigger-width-max);
    padding: var(--v-spacing-md) 0;
    border-radius: var(--border-radius-xs);
    border: var(--border-width) solid var(--diviling-line-color);
    font-size: var(--font-size-12);
    color: var(--main-fc);
    background-color: var(--bg-white);

    .@{menuClsPrefix}-list {
      display: block;
    }

    .@{menuClsPrefix}-list-item,
    .@{menuClsPrefix}-list-subitem-title {
      width: 100%;
      height: auto;
      line-height: unset;
      height: calc(30 * var(--hd));
      padding-left: var(--v-spacing-lg);
      padding-right: var(--v-spacing-lg);
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        background: var(--menu-item-hover-bc);
      }
    }

    //弹层内的list设置最大高度
    .@{menuClsPrefix}-scroller {
      .@{scrollerClsPrefix}__wrap {
        max-height: var(--menu-trigger-max-height);
      }
    }
  }
}

/* tab card模式公共样式 */
.@{menuClsPrefix}-tab,
.@{menuClsPrefix}-card,
.@{menuClsPrefix}-secondtab {
  .@{menuClsPrefix}-list {
    box-sizing: border-box;
  }

  .@{menuClsPrefix}-list-item {
    box-sizing: border-box;
    float: left;
    &-editable:hover { // 可删除的标签。可编辑名字
      .@{menuClsPrefix}-list-item-content-wrap {
        cursor: text;
      }
    }
    &-closeicon,
    &-editicon {
      display: none;
      align-items: center;
      justify-content: center;
      padding-left: var(--h-spacing-xs);
      display: none;
      cursor: pointer;
      .@{iconClsPrefix} {
        cursor: pointer;
        color: var(--secondary-fc);
        &:hover {
          color: var(--primary);
        }
        &:hover {
          color: var(--primary)
        }
      }
    }
    &-editicon {
      padding-right: var(--h-spacing-xs);
    }
    &:hover {
      .@{menuClsPrefix}-list-item-closeicon,
      .@{menuClsPrefix}-list-item-editicon {
        display: flex;
      }
    }
  }

  .@{menuClsPrefix}-extra {
    // flex: 1;
    // height: 100%;
    display: flex;
    align-items: center;
    margin-left: calc(20 * var(--hd)); // 左右留一些间隙
    height: 100%;
    // position: absolute;
    // top: 50%;
    // right: 0;
    // transform: translateY(-50%);

    &-add {
      // display: inline-block;
      display: inline-flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      // width: var(--menu-icon-height);
      // height: var(--menu-icon-height);
      cursor: pointer;
      color: var(--primary);
      
      &:hover .@{iconClsPrefix} {
        opacity: var(--btn-hover-opacity);
      }
    }
  }
}

/* tab-top card模式公共样式 */
.@{menuClsPrefix}-tab-top,
.@{menuClsPrefix}-card,
.@{menuClsPrefix}-secondtab {
  .@{menuClsPrefix}-nav-container-scrolling {
    padding-left: var(--menu-arrow);
    padding-right: var(--menu-arrow);

    &-hasDropDownIcon {
      padding-right: var(--menu-arrow-right);
    }
  }

  .@{menuClsPrefix}-nav-scroll {
    width: var(--menu-scroll-wdith);
  }

  .@{menuClsPrefix}-nav-wrap {
    width: 100%;
  }

  .@{menuClsPrefix}-tab-prev,
  .@{menuClsPrefix}-tab-next {
    .@{iconClsPrefix} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  .@{menuClsPrefix}-tab-prev {
    left: 0;
  }

  .@{menuClsPrefix}-tab-next {
    right: 0;

    &-hasDropDownIcon {
      right: calc(34 * var(--hd));
    }

    .@{iconClsPrefix} {
      right: 0;
    }
  }

  .@{menuClsPrefix}-tab-btn-disabled {
    .@{iconClsPrefix} {
      color: var(--invalid);
    }
  }

  .@{menuClsPrefix}-tab-arrow-show {
    width: var(--menu-arrow);
    height: 100%;
  }
}

.@{menuClsPrefix}-inline,
.@{menuClsPrefix}-modal {
  .@{menuClsPrefix}-list-subitem {
    width: 100%;
  }
}

// 父菜单可选中下，弹层UI
.@{menuClsPrefix}-trigger-special {
  .@{menuClsPrefix}-list {
    padding-top: var(--v-spacing-sm);
    padding-left: calc(24 * var(--hd));
    >div {
      position: relative;
      padding-left: 0;
      border-left: var(--border-solid);
      >.@{menuClsPrefix}-list-subitem-title {
        padding-left: 0;
      }
      .@{menuClsPrefix}-list-item-content-wrap {
        padding-left: calc(10 * var(--hd));
      }
    }
    // .@{menuClsPrefix}-list-item,
    // .@{menuClsPrefix}-list-subitem-title {
    //   padding: 0;
    // }
    >.@{menuClsPrefix}-list-item-active::after,
    >.@{menuClsPrefix}-list-subitem .@{menuClsPrefix}-list-item-active::after {
      position: absolute;
      top: 0;
      left: calc(-2 * var(--hd));
      bottom: 0;
      content: '';
      width: calc(3 * var(--hd));
      background-color: var(--primary);
    }
  }
}
// 父菜单可选中下，弹层UI (tab secondtab card 容器宽度<=200 特殊处理)
.@{menuClsPrefix}-trigger-specialselect {
  .@{menuClsPrefix}-list {
      >.@{menuClsPrefix}-list-subitem .@{menuClsPrefix}-list-subitem-title,
      >.@{menuClsPrefix}-list-item {
        padding-left: calc(10 * var(--hd));
      }
  }
}