/**
* 内层容器样式
*/
/*******************************************************************************/
/*****************************  detail/dialog 样式  *****************************/
/*******************************************************************************/
.@{mUploadClsPrefix}-dialog {
  padding-bottom: calc(55 * var(--hd));
  .@{mDialogClsPrefix}-top-right {
    padding: 0;
  }

  .@{mListClsPrefix}-column
    .@{mListClsPrefix}-body
    .@{mListClsPrefix}-item
    .@{mListClsPrefix}-content::after {
    display: none;
  }
  .@{mUploadClsPrefix}-listB-listitem-cancel-icon {
    right: calc(16 * var(--hd));
  }
  // 列表间距调整
  .@{mUploadClsPrefix}-listT,
  .@{mUploadClsPrefix}-listB-listitem {
    // padding: 0 calc(16 * var(--hd));
  }
  .@{mUploadClsPrefix}-listB {
    padding-bottom: calc(50 * var(--hd));
  }
  /* 确认按钮 */
  .@{mUploadClsPrefix}-confirm {
    width: 100%;
    .@{mButtonGroupClsPrefix}-btn {
      color: var(--primary);
    }
  }
  /* 空附件提示上下居中 */
  &-isContentEmpty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-wrap: wrap;
  }
}
.@{mUploadClsPrefix}-mobile-empty {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
// header 区域
.@{mUploadClsPrefix}-detail-header {
  display: flex;
  height: calc(52 * var(--hd));
  width: 100%;
  flex-direction: row;
  align-items: center;
  .ui-m-upload-detail-input {
    width: 100%;
    margin: calc(10 * var(--hd));
  }
  &-icon {
    color: var(--secondary-fc);
  }
}

// content 区域
.@{mUploadClsPrefix}-detail-content {
  /*修改内容*/
  background-color: var(--m-bg-base);
  /*修改内容*/
  overflow: auto;
  height: 100%;
  padding-bottom: calc(54 * var(--hd));
  width: 100%;
}

// footer 区域
.@{mUploadClsPrefix}-detail-footer {
  display: flex;
  /*修改*/
  background-color: var(--bg-white);
  height: calc(50 * var(--hd));
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  .is-ok-btn::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    height: 50%;
    width: var(--hd);
    background-color: var(--border-color);
    transform: translateY(-50%) scaleX(0.3);
  }
  &-back {
    color: var(--secondary-fc);
  }
  &-add {
    color: var(--primary);
  }
}

//footer 批量操作的footer区域
.@{mUploadClsPrefix}-detail-footer__batch {
  /*修改*/
  position: fixed;
  background-color: var(--bg-white);

  width: 100%;
  left: 0;
  bottom: 0;
  /*修改*/
  font-size: var(--font-size-16);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &-text-noSel {
    color: var(--invalid);
  }
  &-right {
    margin-right: calc(16 * var(--hd));
    color: var(--primary);
  }
  &-left {
    margin-left: calc(9 * var(--hd));
  }
  &-text-noSel {
    color: var(--secondary-fc);
  }
}

//附件详情提示部分
.@{mUploadClsPrefix}-detail-tip {
  height: calc(44 * var(--hd));
  background: var(--m-bg-base);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: calc(0.39 * var(--hd));
  font-size: var(--font-size-14);
  &-left {
    margin: 0 auto;
    span {
      color: var(--primary);
      margin: 0 calc(4 * var(--hd));
    }
  }
  &-right {
    margin-right: calc(18 * var(--hd));
    color: var(--primary);
  }
}

// 批量操作 action 面板
.@{mUploadClsPrefix}-batch-action-sheet {
  .@{mDialogClsPrefix}-top-bottom {
    background: var(--bg-base);
  }
}
