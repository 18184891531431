.@{mCollapseClsPrefix} {
  box-sizing: border-box;
  overflow: hidden;
}

.@{mCollapseClsPrefix}-panel {
  box-sizing: border-box;
  margin-bottom: 10px;
  background-color: #fff;

  &__title {
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    padding: 15px 0;
    margin: 0 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;

    &--bg-default {
      background-color: #fff;
      //background: #F5F5F5;
    }

    &--bg-none {
      background-color: #fff;
    }
  }

  &__content-box {
    font-size: 14px;
    line-height: 20px;
    background-color: #fff;
    margin: 0 16px;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px transparent;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &__content {
    padding: 16px 0;
    color: #999999;
  }

  &__arrow {
    fill: #707070;
    margin-right: 10px;
    transform: rotate(-180deg);
    transform-origin: center;
    transition: transform 160ms cubic-bezier(0.5, 0, 0.5, 0.1);
    color: #999999;
  }

  &__arrow-right {
    position: absolute;
    right: 0;
    &.needRTL {
      right: unset;
      left: 0;
    }
  }

  &--inactive {
    .@{mCollapseClsPrefix}-panel__arrow {
      transform: rotate(0deg);
    }
  }

  &--disabled {
    .@{mCollapseClsPrefix}-panel__arrow {
      fill: #c8c9cc;
    }

    .@{mCollapseClsPrefix}-panel__title {
      color: #c8c9cc;
      cursor: not-allowed;
    }
  }

  &--animating {
    user-select: none;
  }

  &--active {
    .@{mCollapseClsPrefix}-panel__title {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border: 1px transparent;
      border-bottom: 1px solid #ebebeb8a;
    }
  }
}
