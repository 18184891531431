//移动pc共享样式

.@{stepsClsPrefix} {
  width: 100%;
  display: flex;

  &-item {
    &-head {
      .@{stepsClsPrefix}-hasIcon {
        .@{stepsClsPrefix}-icon {
          // color: var(--primary);
        }
      }

      .@{stepsClsPrefix}-icon {
        display: block;
        width: var(--steps-icon-width);
        height: var(--steps-icon-height);
        border: var(--steps-border);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-transition: background-color .3s, border-color .3s;
        transition: background-color .3s, border-color .3s;
        &[data-rtl='true'] {
          .ui-icon-svg.ui-rtl {
            transform: scaleX(1);
            direction: ltr;
          }
        }
      }
    }

    .@{stepsClsPrefix}-item-content {
      font-size: var(--font-size-14);
      color: var(--main-fc);
    }

    .@{stepsClsPrefix}-item-title {
      .@{stepsClsPrefix}-item-subtitle {
        margin-left: var(--steps-margin);
        font-weight: var(--font-weight-base);
        color: var(--secondary-fc);
      }
    }
  }

  // 状态样式
  &-process {
    .@{stepsClsPrefix}-icon {
      background-color: var(--primary);
      border-color: var(--primary);
      color: var(--base-white);
    }
  }

  &-wait {
    .@{stepsClsPrefix}-icon {
      color: var(--regular-fc);
    }

    .@{stepsClsPrefix}-item-content {
      color: var(--secondary-fc);
    }
  }

  &-wait+.@{stepsClsPrefix}-item-content {
    color: var(--secondary-fc);
  }

  &-finish {
    .@{stepsClsPrefix}-icon {
      background-color: var(--bg-white);
      border-color: var(--primary);
      color: var(--primary);
    }

    .@{stepsClsPrefix}-line {
      background-color: var(--primary) !important;
    }
  }

  &-error {
    .@{stepsClsPrefix}-icon {
      background-color: var(--bg-white);
      border-color: var(--danger);
      color: var(--danger);
    }

    .@{stepsClsPrefix}-item-content {
      .@{stepsClsPrefix}-item-title {
        color: var(--danger);

        .@{stepsClsPrefix}-item-subtitle {
          color: var(--m-btn-danger-disabled-bc);
        }
      }

      .@{stepsClsPrefix}-item-description {
        color: var(--danger);
      }
    }
  }
}

//水平方向
.@{stepsClsPrefix}-horizontal {


  .@{stepsClsPrefix}-item {
    flex: 1;
    /* 修复ie下step无法展开的问题 */

    //隐藏最后一条线
    &:last-child {
      //清除最后一个元素的占位
      flex: none;

      .@{stepsClsPrefix}-item-head {
        .@{stepsClsPrefix}-line-wrap {
          display: none;
        }
      }
    }


    &-head {
      display: flex;

      .@{stepsClsPrefix}-item-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .@{stepsClsPrefix}-line-wrap {
        flex: 1;
        padding: 0 var(--v-spacing-md);
      }

      .@{stepsClsPrefix}-line {
        width: 100%;
        height: calc(1 * var(--hd));
        background: var(--steps-line-color);
        margin-top: var(--steps-icon-width-half);

      }
    }

    //label方向垂直
    .@{stepsClsPrefix}-label-vertical {
      flex-direction: column;
      align-items: center;

      .@{stepsClsPrefix}-item-content {
        margin-top: var(--steps-content-lable-top);
      }
    }

    //label方向水平
    .@{stepsClsPrefix}-label-horizontal {
      .@{stepsClsPrefix}-item-content {
        margin-left: var(--h-spacing-md);
      }
    }
  }

  .@{stepsClsPrefix}-label-vertical.@{stepsClsPrefix}-center {
    position: relative;

    .@{stepsClsPrefix}-item-head {
      display: flex;
      justify-content: center;

      .@{stepsClsPrefix}-line-wrap {
        position: absolute;
        left: 50%;
        right: -50%;
        padding: 0 calc(22 * var(--hd));
        width: 100%;
      }
    }


    //隐藏最后一条线
    &:last-child {
      //清除最后一个元素的占位
      flex: 1;

      .@{stepsClsPrefix}-item-head {
        .@{stepsClsPrefix}-line-wrap {
          display: none;
        }
      }
    }

    .@{stepsClsPrefix}-label-vertical {
      .@{stepsClsPrefix}-item-content {
        text-align: center;
      }
    }
  }
}



//垂直方向
.@{stepsClsPrefix}-vertical {
  height: 100%;
  display: flex;
  flex-direction: column;

  .@{stepsClsPrefix}-item {
    flex: 1;
    position: relative;

    //隐藏最后一条线
    &:last-child {
      //清除最后一个元素的占位
      flex-grow: 0;

      .@{stepsClsPrefix}-item-container {
        position: relative;
        min-height: var(--steps-icon-height);
      }

      .@{stepsClsPrefix}-item-head {
        .@{stepsClsPrefix}-item-icon {
          .@{stepsClsPrefix}-line {
            display: none;
          }
        }
      }
    }

    &-container {
      display: flex;
      height: 100%;
      min-height: inherit;

      .@{stepsClsPrefix}-line {
        flex: 1;
        width: calc(1 * var(--hd));
        background: var(--steps-line-color);
        margin: var(--v-spacing-md) 0;
      }

      .@{stepsClsPrefix}-item-head {
        display: flex;
        min-height: inherit;
      }

      .@{stepsClsPrefix}-item-icon {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .@{stepsClsPrefix}-item-content {
        margin-left: var(--steps-cotent-label-spacing);
        display: flex;
        justify-content: center;
        flex-direction: column;
        transform: translateY(-50%) translateY(var(--steps-icon-height-half));
      }
    }
  }
}