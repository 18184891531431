.@{listViewClsPrefix} {
  thead {
    .ui-table-grid-th-operate {
      //background: #F5F5F5 !important;
      min-width: 50px;
    }
  }

  .ui-table-grid-th-operate {
    .@{listViewClsPrefix}-operate-menu-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .@{tableClsPrefix}-grid-tr {
    .ui-listView-operate-menu {
      visibility: hidden;
      transition: all .1s;
    }
  }
  .@{tableClsPrefix}-grid-tr:hover {
    .ui-listView-operate-menu {
      visibility: visible;
    }
  }

  &-warp {
    height: 100%;
    position: relative;
    .empty-loading-container{
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }

  &-dialog {
    .@{dialogClsPrefix}-content {
      .ui-title {
        border-bottom: none;
      }
      .@{dialogClsPrefix}-body-container {
        min-height: 194px;
        max-height: 355px;
        .@{dialogClsPrefix}-body {
          margin: 0 15px;
          padding: 0;
          .columns-set-warp {
            border: 1px solid #E5E5E5;
            background: white;
            //margin-top: 20px;
            &-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 8px 8px 8px 16px;
              .@{btnClsPrefix}.@{btnClsPrefix}-link {
                color: var(--primary);
              }
              &__team {
                font-size: var(--font-size-12);
                color: #333333;
                padding-top: 12px;
                padding-bottom: 12px;
              }
            }
            &-content {
              border-top: 1px solid #EBEBEB;
              //border-bottom: 1px solid #EBEBEB;
              padding: 5px 16px;
              display: flex;
              flex-wrap: wrap;
              min-height: 50px;

              &-tag {
                padding: 6.5px 10px;
                display: inline-block;
                font-size: var(--font-size-12);
                color: #666666;
                background: #FFFFFF;
                border: 1px solid #E5E5E5;
                border-radius: 4px;
                margin: 5px 10px 5px 0;
                cursor: pointer;
                position: relative;
                min-width: 35px;
                &__active {
                  color: var(--primary);
                  border: 1px solid var(--primary);
                  &:before {
                    background: var(--primary);
                    content: "";
                    transition: var(--transition-all);
                    opacity: .1;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    border: 0;
                    margin: auto;
                  }
                }
                &__disabled-permission {
                  &:after {
                    position: absolute;
                    top: 50%;
                    left: 10%;
                    transform: translateY(-50%);
                    width: 80%;
                    height: 1px;
                    opacity: 1;
                    content: "";
                    background-color: #666666;
                  }
                }
                &__disabled {
                  cursor: not-allowed;
                  color: #afafaf;
                }
              }
              &-label {
                padding: 5px 0;
                color: var(--secondary-fc);
                font-size: var(--font-size-12);
              }
              .columns-set-warp-content-tag__active.columns-set-warp-content-tag__disabled-permission {
                &:after {
                  background-color: var(--primary);
                }
              }
            }
            &-footer {

            }
          }
        }
      }
      .ui-dialog-footer {
        border-top: none;
        button:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &-sort-dialog {
    .ui-dialog-body {
      padding: 0;
      .ui-transfer-wrap {
        border: none;
        min-height: 380px;

        .ui-transfer-list-wrap-right {
          .ui-list {
            &-item {
              .ui-transfer-list-item {
                &-content {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 100%;

                  &-order {
                    cursor: pointer;
                    color: #5D9CEC;
                    padding-right: 10px;
                    display: inline-flex;
                    align-items: center;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ui-layout-footer {
      border-top: var(--border-solid);
      //background: white;
    }
  }
}

