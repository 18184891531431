//范围选择头部快捷按钮组件
.@{datePickerClsPrefix}-multiAreaWrap,
.@{dateTimePickerClsPrefix}-multiAreaWrap {
  box-sizing: border-box;
  border-bottom: calc(1 * var(--hd)) solid var(--diviling-line-color);
  justify-items: center;
  align-items: center;

  .multiArea {
    height: 100%;
    padding: calc(4 * var(--hd)) calc(10 * var(--hd));
    display: flex;
    flex-flow: row wrap;

    .multiBtn-wrap {
      flex: 0 0 (1/8 * 100%);
      margin: calc(4 * var(--hd)) 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      .multiBtn {
        border: none;
        border-radius: calc(12 * var(--hd));
        background-color: var(--label-bc);
        color: var(--regular-fc);
        width: calc(60 * var(--hd));
        height: calc(24 * var(--hd));
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &-isSelect {
          background-color: var(--primary);
          color: var(--base-white) !important;
        }
      }
    }
  }
}