@cell-size: calc(42 * var(--hd));
@dot-size: calc(4 * var(--hd));
@container-min-width: calc(7 * @cell-size);
@sideWeekBar-min-width: calc(30 * var(--hd));

//wrapper
.@{mCalendarClsPrefix}-container {
  color: var(--main-fc);

  // 基础样式
  .@{mCalendarClsPrefix}-wrapper {
    position: relative;
    transition: all 2s;
    overflow: hidden;
    overflow-x: scroll;
    border-top: var(--border-width) var(--border-color) solid;
    border-bottom: var(--border-width) var(--border-color) solid;

    .@{mCalendarClsPrefix}-monthTitleBar {
      margin: 0;
      padding: 0 calc(16 * var(--hd));
      margin-top: calc(7.5 * var(--hd));
      display: flex;
      font-family: PingFangSC-Regular;
      font-size: var(--font-size-14);
      color: var(--main-fc);
      line-height: calc(22 * var(--hd));
      font-weight: 400;

      .extra-title {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
      }

      .current-month {
        flex: 0 0 6em;
      }

      .today {
        flex: 0 0 4em;
        text-align: right;
      }
    }

    .@{mCalendarClsPrefix}-panelContainer {
      overflow: hidden;
    }

    .@{mCalendarClsPrefix}-monthsContent {
      position: relative;

      .@{mCalendarClsPrefix}-months {
        .@{mCalendarClsPrefix}-month {
          width: 100%;
          position: absolute;
        }
      }
    }
  }

  // 最小宽度 -> 普通模式
  .@{mCalendarClsPrefix}-wrapper {

    .@{mCalendarClsPrefix}-calendarPanel,
    >div {
      min-width: @container-min-width;
    }
  }

  // 最小宽度 -> 周导航栏模式 需要加上左侧周导航栏的最小宽度
  .@{mCalendarClsPrefix}-wrapper_isShowSideWeekBar {

    .@{mCalendarClsPrefix}-sideWeekBar {
      min-width: @sideWeekBar-min-width;
    }

    >div {
      min-width: calc(@container-min-width + @sideWeekBar-min-width);
    }
  }
}

// singleMonth
.@{mCalendarClsPrefix}-container {
  .@{mCalendarClsPrefix}-singleMonth {

    .@{mCalendarClsPrefix}-row {
      display: flex;
      align-items: baseline;
      justify-content: space-around;
      padding-top: calc(8 * var(--hd));
      padding-bottom: calc(8 * var(--hd));

      .@{mCalendarClsPrefix}-cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;

        .date-wrapper {
          display: flex;
          height: @cell-size;
          width: 100%;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .date {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: @cell-size;
            height: @cell-size;
            border-radius: 50%;
            flex-direction: column;
            color: var(--main-fc);
            font-size: var(--font-size-14);
            box-sizing: border-box;
            @dot-size: calc(4 * var(--hd));
            position: relative;
            display: flex;
            flex-direction: column;

            .sign {
              flex: 1;
              position: absolute;
              top: 0;
              right: -10%;
              font-size: var(--font-size-icontext);
              color: #ff5b05;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .day {
              flex: 1;
              font-family: PingFangSC-Medium;
              font-size: var(--font-size-16);
              color: var(--main-fc);
              font-weight: var(--font-weight-bold);
              // padding-top: calc(6 * var(--hd));
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .day-lunar {
              align-items: flex-end;
            }

            .info {
              flex: 1;
              font-family: PingFangSC-Regular;
              color: var(--secondary-fc);
              font-weight: var(--font-weight-base);
              font-size: calc(12 * var(--hd));
              display: flex;
              justify-content: center;
              // align-items: center;
              align-items: flex-start;
            }
          }

          .disable {
            color: var(--invalid);
            background: var(--invalid-bc);
            border: none;
            border-radius: 100%;
          }

          .grey {

            .day,
            .info {
              color: var(--placeholder-fc);
            }
          }

          .important {
            border: var(--border-width) var(--border-color) solid;
            border-radius: 100%;
          }

          .left,
          .right {
            border: none;
            width: 0;
            height: @cell-size;
          }

          .today {
            .bg {
              width: 100%;
              height: 100%;
              position: absolute;
              border-radius: 50%;
              background-color: var(--primary);
              opacity: 0.3;
            }
          }

          .date-selected {
            border: none;
            background: var(--primary);
            color: var(--base-white);
            font-size: var(--font-size-base);

            .dot {
              background-color: var(--bg-white);
            }

            .day,
            .info {
              color: var(--base-white);
            }
          }

          .selected-start {
            border-radius: 100% 0 0 100%;
          }

          .selected-single {
            border-radius: 100%;
          }

          .selected-middle {
            border-radius: 0;
          }

          .selected-end {
            border-radius: 0 100% 100% 0;
          }

          .selected-start_and {
            border-radius: 100%;
          }
        }

        .day {
          height: calc(20 * var(--hd));
          font-size: var(--font-size-16);
          font-weight: 500;
          font-family: PingFangSC-Medium;
        }

        .info {
          height: calc(12 * var(--hd));
          padding: 0;
          font-size: calc(11 * var(--hd));
          font-weight: var(--font-weight-base);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: center;
        }

        .date-selected {
          color: var(--primary);
        }
      }
    }

    .@{mCalendarClsPrefix}-row-xl {
      margin-top: var(--v-spacing-md);
    }
  }
}

// dot
.@{mCalendarClsPrefix}-container {

  .@{mCalendarClsPrefix}-singleMonth,
  .@{mCalendarClsPrefix}-weekCell {
    .dot {
      display: inline-block;
      width: @dot-size;
      height: @dot-size;
      border-radius: 50%;
      background-color: var(--primary);
      margin: calc(1 * var(--hd)) 0;

      &.dot-null {
        display: none;
      }
    }
  }

  // 隐藏农历
  &-hideLunar {
    .dot.dot-null {
      display: none;
    }
  }
}

// 范围选择
.@{mCalendarClsPrefix}-container {
  .@{mCalendarClsPrefix}-wrapper-range {
    .@{mCalendarClsPrefix}-weekStartBar {

      .left,
      .right {
        width: 100%;
      }
    }

    .@{mCalendarClsPrefix}-singleMonth {
      .@{mCalendarClsPrefix}-row {
        .@{mCalendarClsPrefix}-cell {
          .date-wrapper {
            .today {
              border: 0;
            }

            .date-selected {
              background-color: var(--primary);
            }

            .selected-start,
            .selected-end {
              .selected-content {
                border-radius: 100%;
                background: var(--primary);
              }
            }

            .left,
            .right {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// 顶部星期导航栏
.@{mCalendarClsPrefix}-container {
  .@{mCalendarClsPrefix}-weekStartBar {
    display: flex;
    padding: var(--v-spacing-md) 0;
    padding-bottom: 0;
    justify-content: space-around;
    font-weight: var(--font-weight-base);

    .@{mCalendarClsPrefix}-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--main-fc);
      font-size: var(--font-size-14);
      font-family: PingFangSC-Regular;
      font-size: var(--font-size-12);
      color: var(--main-fc);
      line-height: calc(20 * var(--hd));
      font-weight: 400;

      .date-wrapper {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;

        .date {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: @cell-size;
          height: 100%;
          position: relative;

          .sign {
            position: absolute;
            top: 0;
            right: -10%;
            font-size: var(--font-size-icontext);
            color: #ff5b05;
          }
        }

        .left,
        .right {
          border: none;
          width: 0;
          height: 100%;
        }
      }
    }

    .cell-grey {
      color: var(--placeholder-fc);
    }
  }
}

// 底部切换周/双周/月栏
.@{mCalendarClsPrefix}-container {
  .@{mCalendarClsPrefix}-footer {
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(16 * var(--hd));

    .ui-icon-svg {
      color: #E1E1E1;
      width: calc(50 * var(--hd));
      height: calc(50 * var(--hd));
    }
  }
}

// 开启左侧周/双周导航栏
.@{mCalendarClsPrefix}-container {
  .@{mCalendarClsPrefix}-sideWeekBarContainer {
    display: flex;

    // 左侧
    .@{mCalendarClsPrefix}-sideWeekBar {
      flex: 1;

      .@{mCalendarClsPrefix}-cell,
      .@{mCalendarClsPrefix}-weekCell {
        display: flex;
        width: 100%;
        align-items: center;
        color: var(--primary);
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-bold);
      }

      .week-label {
        padding: var(--v-spacing-md) calc(2 * var(--hd));
        padding-bottom: 0;

        .@{mCalendarClsPrefix}-cell {
          justify-content: center;
          height: calc(20 * var(--hd));
        }
      }

      .@{mCalendarClsPrefix}-weekCell {
        height: @cell-size;
        justify-content: center;
        flex-direction: column;
        box-sizing: content-box;

        .dot-null {
          display: none;
        }

        >span {
          display: block;
        }
      }

      .week-content {
        position: relative;
        overflow: hidden;

        .week-content-cell {
          height: 100%;
          display: flex;
          flex-direction: column;

          >div {
            flex: 1;
          }
        }
      }
    }

    // 右侧
    .@{mCalendarClsPrefix}-calendarPanel {
      flex: 9;
    }
  }
}

// 镜像
.@{mCalendarClsPrefix}-container {
  .@{mCalendarClsPrefix}-panelContainer {
    &.need-RTL {
      transform: scale(-1);
    }
  }

  .@{mCalendarClsPrefix}-wrapper {
    .@{mCalendarClsPrefix}-monthTitleBar {
      .today {
        &.need-mirror {
          text-align: left;
        }
      }
    }
  }

  .@{mCalendarClsPrefix}-singleMonth {
    .calendar-mirror-date {
      display: flex;
      flex-direction: column-reverse;

      .@{mCalendarClsPrefix}-row {
        display: flex;
        flex-direction: row-reverse;

        .@{mCalendarClsPrefix}-cell {
          transform: rotate(180deg);
        }
      }
    }
  }
}