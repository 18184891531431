/******************************* 本地标密上传 - 密级期限弹框 *******************************/
.@{uploadClsPrefix}-secret-validity-dialog {
  .ui-dialog-body {
    padding: 0;
  }
  .ui-dialog-footer {
    padding-top: 0;
  }
  .ui-dialog-body,
  .ui-dialog-footer {
    background-color: var(--bg-white);
  }
  .ui-title-icon {
    border: 0;
  }
  .ui-list-body {
    padding-bottom: calc(40 * var(--hd));
  }
  .ui-list-item,
  .ui-list-content {
    overflow: unset;
  }
  .secret-validity-panel {
    // padding-bottom: calc(25 * var(--hd));
    .tips {
      opacity: 0.64;
      background: #ECF5FF;
      border-radius: var(--hd);
      line-height: calc(40 * var(--hd));
      padding: 0 calc(16 * var(--hd));
      color: #5d9cec;
      text-align: justify;
      font-size: var(--font-size-12);
    }
  }
  .secret-validity-row {
    max-width: 100%;
    margin: calc(16 * var(--hd)) calc(16 * var(--hd)) 0 calc(16 * var(--hd));
    &:not(:last-child) {
      border-bottom: calc(1 * var(--hd)) solid var(--diviling-line-color);
    }

    .name {
      font-size: var(--font-size-14);
      color: var(--main-fc);
      line-height: calc(14 * var(--hd));
    }
  }
  .secret-validity-com {
    padding: calc(6 * var(--hd)) 0;
  }
  .@{secretSelectClsPrefix}-withLabel {
    .@{secretSelectClsPrefix}-label {
      padding: 0;
    }
    .@{secretSelectClsPrefix}-secretlevel {
      display: none;
    }
    .@{secretSelectClsPrefix}-secretvalidity {
      &-content {
        display: flex;
      }
      &-formSwitch {
        width: 100%;
        input {
          border-color: transparent;
          box-shadow: none;
          // height: calc(50 * var(--hd));
          &:focus {
            border: 0;
            box-shadow: none;
          }
        }
      }
    }
  }
  .ui-help {
    display: none;
  }
}

/******************************* 附件上传 - 密级确认框 *******************************/
.@{uploadClsPrefix}-secret-select-dialog {
  .@{secretSelectClsPrefix}-row {
    height: calc(50 * var(--hd));
  }
  .@{secretSelectClsPrefix}-secretlevel-select,
  .@{secretSelectClsPrefix}-secretvalidity-input {
    width: calc(150 * var(--hd));
  }
}
