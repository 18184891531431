.@{iconSelectionClsPrefix} {
  display: flex;
  align-items: center;
  &-custom-icon {
    width: var(--icon-selection-icon-selected-width);
    height: var(--icon-selection-icon-selected-width);
    margin-right: 12px;
    text-align: center;
    &:not(.need-mirror-RTL) {
      margin-right: calc(12 * var(--hd));
    }
    &.need-mirror-RTL {
      margin-left: calc(12 * var(--hd));
    }
  }
  &-arrow {
    color: var(--secondary-fc);
  }
  &-clear-btn {
    display: inline-block;
    font-size: var(--font-size-12);
    color: var(--secondary-fc);
    font-weight: var(--font-weight-base);
    margin-left: calc(6 * var(--hd));
    cursor: pointer;
    &:not(.need-mirror-RTL) {
      margin-left: calc(6 * var(--hd));
    }
    &.need-mirror-RTL {
      margin-right: calc(6 * var(--hd));
    }
    &:hover {
      color: var(--primary);
    }
  }
  &-selected {
    display: inline-block;
    height: var(--icon-selection-selected-height);
    // width: var(--icon-selection-selected-width);
    padding: 0 var(--icon-selection-padding);
    border-width: var(--hd) var(--hd) 0 var(--hd);
    border-style: solid;
    border-color: transparent;

    &-hover {
      border-color: var(--border-color);
      background-color: var(--bg-white);
      transition: all 0.2s;
    }

    &-dropdown {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }
  }

  &-arrow {
    transform: rotate(0);
    transition: all 0.2s;
    &-hover {
      transform: rotate(-90deg);
    }
  }

  &-panel {
    overflow: hidden;
    &-contain {
      position: relative;
      padding: var(--icon-selection-padding);
      background-color: var(--bg-white);
      border: var(--icon-selection-border);
      box-shadow: var(--icon-selection-contain-shadow);
      margin: var(--icon-selection-ne-margin) 0 0;
    }
    &-line {
      position: absolute;
      width: 100%;
      height: var(--hd);
      background: var(--border-color);
      top: 0;
      left: calc(var(--icon-selection-selected-width) - calc(2 * var(--hd)));
    }
  }

  &-icon {
    &-contain {
      // border: 1px solid #f0f0f0;
      width: var(--icon-selection-contain-width);
      overflow: hidden;
      // border: 1px solid #f0f0f0;

      .icons-inner {
        // font-size: 0;
        // width: 5px;
        margin-bottom: var(--icon-selection-ne-margin);
      }
    }
    &-box {
      display: inline-block;
      padding: var(--icon-selection-padding);
      &:hover {
        background: var(--bg-weak);
      }
    }
  }
}
