.@{cascaderClsPrefix}-city-picker {
  &-header {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    background: var(--bg-white);
    border-radius: var(--border-radius-xs) var(--border-radius-xs) 0 0;
    border: var(--border-solid);

    &-select {
      width: 100px;
      border: none;

      .@{inputClsPrefix}-wrap {
        border: none;
        background: none;
      }

      .@{inputClsPrefix}-dropdown {
        z-index: 1000;
      }
    }

    &-text {
      color: var(--main-fc);
      margin-left: 10px;
      font-size: var(--font-size-12);
    }
  }
}

.@{cascaderClsPrefix}-dropdown-city-picker-multiple {
  z-index: var(--progress-zindex);
  box-shadow: var(--box-shadow-storke);

  .@{cascaderInputClsPrefix}-menus {
    border: var(--border-solid);
    border-top: none;
    box-shadow: none;
    border-radius: 0 0 var(--border-radius-xs) var(--border-radius-xs);
  }
}