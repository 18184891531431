.ui-back-top {
  bottom: calc(50 * var(--hd));;
  box-sizing: border-box;
  cursor: pointer;
  font-size: var(--font-size-14);
  height: calc(40 * var(--hd));;
  line-height: 2.6;
  text-align: center;
  margin: 0;
  padding: 0;
  position: fixed;
  right: calc(100 * var(--hd));;
  width: calc(40 * var(--hd));;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  color: #000;
  transition: all .3s;
  border-radius: 50%;
}
.ui-back-top:hover {
  background-color: var(--primary);
  color: white;
}
.ui-back-top-fade {
  visibility: hidden;
  transition: all .3s;
}