.@{mQuickMenuClsPrefix} {

  &-popover-menu-container {
    min-width: calc(114 * var(--hd));
    max-width: calc(220 * var(--hd));
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
  }

  &-popover-menu-line {
    width: 100%;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    padding: 0 calc(12 * var(--hd));
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    &-PC {
      &:hover {
        background-color: var(--menu-item-hover-bc);

        .@{mQuickMenuClsPrefix}-popover-menu-text-item {
          color: var(--primary);
        }
      }
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: calc(44 * var(--hd));
      width: 100%;
      padding: calc(2 * var(--hd)) 0;
      border-bottom: 1px solid #EBEBEB;
    }

    &-icon {
      width: calc(24 * var(--hd));
      height: calc(24 * var(--hd));
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: var(--base-white);
      max-width: 100%;
      margin: 0 calc(5 * var(--hd));

      svg {
        width: calc(16 * var(--hd));
        height: calc(16 * var(--hd));
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &-popover-menu-text {
    flex: 1;
    color: var(--m-readonly-text);
    font-size: var(--font-size-12);
    padding: calc(6 * var(--hd));
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    overflow: hidden;

    &-item {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    }
  }
}

.@{mQuickMenuClsPrefix}-popover_popover {

  .@{popoverClsPrefix}-content {
    padding-left: 0;
    padding-right: 0;
  }
}