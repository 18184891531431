.@{cardDetailFormClsPrefix}-linkFormData-template {
  &-col {
    .@{formItemClsPrefix}-wrapper {
      height: auto;
      .@{formSwitchClsPrefix}-BROWSER-PC {
        line-height: unset;
      }
    }
    .@{browserClsPrefix}.box-selection.disabled-hidemore.is-multiple
      .@{browserClsPrefix}-associative:not(.is-empty).show-border {
      padding: 0;
      border: none;
    }
  }
  &-dataBrowser.box-selection.disabled-hidemore.is-multiple
    .@{browserClsPrefix}-associative:not(.is-empty).show-border {
    padding: 0;
    border: none;
  }
  &-dataBrowser {
    .@{browserClsPrefix}-associative-selected,
    .@{browserClsPrefix}-associative-selected.wrap-display {
      display: none;
    }
  }
  &-formBrowser .@{browserClsPrefix}-associative-search-input:not(.is-focus) .@{inputClsPrefix} {
    transition: none;
  }
  &-empty {
    display: none;
  }
  &-container {
    &-empty {
      border-color: transparent;
      .@{cardDetailFormClsPrefix}-linkFormData-template-col
        .@{formItemClsPrefix}-wrapper {
        padding: 0;
      }
      .@{cardDetailFormClsPrefix}-linkFormData-template-formBrowser
        .@{inputClsPrefix}-wrap:not(.is-focus) {
        border-color: var(--border-color);
      }
    }
    &-readOnly {
      .@{formItemClsPrefix}-wrapper {
        display: none;
      }
    }
    &-free {
      .@{formItemClsPrefix}-wrapper {
        display: flex;
      }
    }
    &:hover {
      .@{browserClsPrefix} .@{inputClsPrefix}-wrap {
        border-color: var(--border-color);
        &:hover {
          border-color: var(--input-hover-border-color);
        }
      }
      .@{browserClsPrefix} .@{inputClsPrefix}-wrap.is-focus {
        border-color: var(--input-focus-border-color);
      }
    }
  }
  /* 及时交互 */
  &-container.browser_useBoxSelection.hoverEdit:not(.ui-card-detail-form-linkFormData-template-container-empty) {
    border: var(--border-width) solid transparent;
    display: inline-block;
    .@{cardDetailFormClsPrefix}-linkFormData-template {
      transition: var(--dialog-scale-transition);
    }
    .@{cardDetailFormClsPrefix}-linkFormData-template-formBrowser {
      display: none;
    }
    .@{formItemClsPrefix}-wrapper {
      display: none;
    }
  }
  &-container.browser_useBoxSelection.hoverEdit.is-focus:not(.ui-card-detail-form-linkFormData-template-container-empty) {
    border-color: var(--border-color);
    .@{formItemClsPrefix}-wrapper {
      height: auto;
      display: flex;
      padding: var(--h-spacing-sm);
      padding-top: 0;
    }
    .@{cardDetailFormClsPrefix}-linkFormData-template {
      padding: var(--h-spacing-sm);
      padding-bottom: 0;
    }
    .@{cardDetailFormClsPrefix}-linkFormData-template-formBrowser {
      display: inline-block;
    }
  }
  &-col.browser_useBoxSelection.hoverEdit:hover, &-col.browser_useBoxSelection.hoverEdit.is-focus {
    .@{cardDetailFormClsPrefix}-linkFormData-template-container.browser_useBoxSelection.hoverEdit {
      border-color: var(--border-color);
      .@{formItemClsPrefix}-wrapper {
        display: flex;
        height: auto;
        padding: var(--h-spacing-sm);
        padding-top: 0;
      }
      .@{cardDetailFormClsPrefix}-linkFormData-template {
        padding: var(--h-spacing-sm);
        padding-bottom: 0;
      }
    }
    .@{cardDetailFormClsPrefix}-linkFormData-template-container-empty.browser_useBoxSelection.hoverEdit  {
      border-color: transparent;
      .@{formItemClsPrefix}-wrapper {
        padding: 0;
      }
    }
    .@{cardDetailFormClsPrefix}-linkFormData-template-formBrowser {
      display: inline-block;
    }
  }
  &-col.browser_useBoxSelection.hoverEdit {
    .@{cardDetailFormClsPrefix}-linkFormData-template-formBrowser .ui-input-wrap:not(.is-focus) {
      transition: none;
    }
  }

  /* 一次性提交 */
  &-container.browser_useBoxSelection.disabledHoverEdit:not(.ui-card-detail-form-linkFormData-template-container-empty) {
    border: var(--border-solid);
    padding: var(--h-spacing-sm);
    display: inline-block;
    .@{cardDetailFormClsPrefix}-linkFormData-template-formBrowser {
      .@{inputClsPrefix}-wrap {
        border-color: transparent;
      }
    }
  }
  &-col.browser_useBoxSelection.disabledHoverEdit:hover {
    .@{cardDetailFormClsPrefix}-linkFormData-template-formBrowser {
      .@{inputClsPrefix}-wrap {
        border-color: var(--input-border-color);
        &:hover {
          border-color: var(--input-hover-border-color);
        }
        &:focus {
          border-color: var(--input-focus-border-color);
        }
      }
    }
  }
}
