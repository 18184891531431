// zoom模式关闭
.@{zoomClsPrefix}-wrapper-zoomModeClose {
  position: unset !important;
  width: unset !important;
  height: 100% !important;
  display: inline-block !important;
  overflow: unset !important;
  -webkit-touch-callout: unset !important;
  -webkit-user-select: unset !important;
  margin: unset !important;
  padding: unset !important;
}

.@{zoomClsPrefix}-component-zoomModeClose {
  display: inline-block !important;
  flex-wrap: unset !important;
  width: unset !important;
  height: 100% !important;
  margin: unset !important;
  padding: unset !important;
  transform: unset !important;
  transform-origin: unset !important;
}

.@{zoomClsPrefix}-children {
  width: 100%;
}