.@{selectGroupClsPrefix} {
  background: var(--bg-white);
  border: var(--border-solid);
  padding: 0 var(--h-spacing-lg);
  font-size: var(--font-size-sm);

  &-single, &-single-row {
    border-bottom: var(--border-solid);
    padding: var(--v-spacing-xs) 0;
    position: relative;
    &:last-child {
      border-bottom: 0;
    }
  }

  &-single-row-left {
    display: inline-flex;
    align-items: center;
  }

  &-single-left, &-single-row-left {
    span {
      width: 100%;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  
  &-single {

    &-left {
      width: calc(100 * var(--hd));
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      color: var(--regular-fc);
      padding-top: calc(2 * var(--v-spacing-md));
    }

    &-right {
      width: 100%;
      padding-left: calc(100 * var(--hd));
    }
    
    &-content {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      color: var(--main-fc);

      div {
        padding: var(--v-spacing-md) var(--h-spacing-md);
        cursor: pointer;
        margin: var(--v-spacing-xs) var(--h-spacing-xs);
      }

      &-active {
        color: var(--base-white);
        background: var(--primary);
        border-radius: var(--border-radius-xs);
      }
    }
  }
}