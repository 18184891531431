.m-cascader-list {
  --prefix-width: 'auto';
  --align-items: center;
  --active-background-color: var(--diviling-line-color);
  --border-inner: solid 1px var(--diviling-line-color);
  --border-top: solid 1px var(--diviling-line-color);
  --border-bottom: solid 1px var(--diviling-line-color);
  --padding-left: 12px;

  background-color: #ffffff;
  overflow: hidden;
  font-size: 17px;

  &-inner {
    margin-bottom: -1px;
  }

  &-default {
    border-top: var(--border-top);
    border-bottom: var(--border-bottom);
  }

  &-card {
    margin: 12px;
    border-radius: 8px;
  }
}

.m-cascader-list-item {
  display: block;
  padding-left: var(--padding-left);

  &-title,
  &-description {
    color: var(--secondary-fc);
    font-size: 15px;
  }

  &-content {
    display: flex;
    align-items: var(--align-items);
    justify-content: flex-start;
    padding: 12px 12px 12px 0;
    border-bottom: var(--border-inner);

    &-prefix {
      width: var(--prefix-width);
      flex: none;
      padding-right: 12px;
    }

    &-main {
      flex: auto;
    }

    &-extra {
      flex: none;
      padding-left: 12px;
    }

    &-arrow {
      flex: none;
      display: flex;
      align-items: center;
      padding-left: 12px;
      color: var(--secondary-fc);
      font-size: 16px;
    }
  }

  &-disabled {
    &&>.m-cascader-list-item-content>* {
      cursor: not-allowed;
      // opacity: 0.6;
      pointer-events: none;
    }
  }
}

a.m-cascader-list-item {
  &:active {
    background-color: var(--active-background-color);
  }

  &.m-cascader-list-item-disabled {
    &:active {
      background-color: unset;
    }
  }
}