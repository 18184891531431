.@{mIconSelectionClsPrefix} {
  display: flex;
  align-items: center;
  height: var(--m-icon-selection-icon-width);
  padding: var(--m-icon-selection-line-padding) 0;
  &-selected-box {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
  }
  &-line {
    display: flex;
    justify-content: flex-end;
    // width: 100%;
    align-items: center;
    &-clear-text {
      font-size: var(--font-size-12);
      // color: var(--secondary-fc);
      color: var(--primary);
      font-weight: var(--font-weight-base);
      margin-right: calc(10 * var(--hd));
    }
  }

  &-selected {
    margin-right: var(--m-icon-selection-line-padding);
  }

  &-arrow {
    // margin-left: var(--m-icon-selection-line-padding);
    color: var(--secondary-fc);
  }

  &-content {
    background: var(--m-icon-selection-dialog-bg);
    padding: var(--m-icon-selection-icon-gap) 0;
    &-clear-text {
      font-size: var(--font-size-12);
      // color: var(--secondary-fc);
      color: var(--primary);
      font-weight: var(--font-weight-base);
      text-align: left;
      padding: 0 0 calc(10 * var(--hd)) calc(14 * var(--hd));
      &.need-mirror-RTL {
        text-align: right;
        padding: 0 calc(14 * var(--hd)) calc(10 * var(--hd)) 0;
      }
    }
    &-grid {
      display: grid;
      grid-template-columns: repeat(
        auto-fill,
        var(--m-icon-selection-icon-width)
      );
      grid-row-gap: var(--m-icon-selection-icon-gap);
      grid-column-gap: var(--m-icon-selection-icon-gap);
      justify-content: center;
    }
  }

  &-icon {
    &-box {
      width: var(--m-icon-selection-icon-width);
      height: var(--m-icon-selection-icon-width);
      background: var(--bg-white);
      border-radius: var(--m-icon-selection-border-radius);
      // margin: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
    }
    &-mask {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: var(--mask-bc);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-correct {
      color: var(--base-white);
    }
  }

  // m-dialog 样式复写
  &-dialog {
    .ui-m-dialog-top {
      padding: 0;
      border-radius: 0;
    }
    .ui-m-dialog-content {
      background: var(--m-icon-selection-dialog-bg);
    }
    .ui-m-dialog-top-right {
      height: 100%;
    }
  }
}

/** MRichIconSelection */
.@{mRichIconSelectionClsPrefix} {
  &-panel {
    padding: 0;
  }
  &-content-clear-text {
    background-color: var(--bg-white);
    padding: calc(10 * var(--hd)) 0 0 calc(14 * var(--hd));
  }
}
.@{mIconSelectionClsPrefix}-dialog {
  .@{mCollapseClsPrefix}-panel {
    margin-bottom: 0;
    &__content-box {
      margin: 0;
    }
  }
}
