.@{mAssociateListClsPrefix} {

  height: 100%;
  display: flex;
  flex-direction: column;

  &-table-rtl {
    transform: scaleX(1);
    direction: ltr;
    .ui-m-list-footer-loading {
      transform: scaleX(1);
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    padding: 0 calc(18 * var(--hd));
    background-color: #fff;

    &::before {
      z-index: 9;
      width: inherit;
      left: calc(52 * var(--hd));
      right: 0;
    }

    .@{appIconClsPrefix}, .@{iconClsPrefix}-wrapper {
      display: flex;
      align-items: center;
      margin-right: calc(10 * var(--hd));
    }

    .@{mBrowserClsPrefix}-associative-selected-list.is-associative.@{mListClsPrefix}-column .@{mListClsPrefix}-body {
      padding: 0;

      .@{mListClsPrefix}-item::before {
        content: none;
      }
      
      .@{mListClsPrefix}-content {
        margin: 0;
      }

    }

    .@{mBrowserClsPrefix}-associative-selected {

      .@{mBrowserClsPrefix}-associative-selected-item {
        max-width: initial;
      }

      .@{mListClsPrefix}-column .@{mListClsPrefix}-body .@{mListClsPrefix}-item {

        width: 100%;

        .@{mListClsPrefix}-item-row {
          color: var(--regular-fc);
          font-size: var(--font-size-14);
          white-space: pre-line;
          word-break: break-word;
          line-height: 1.5;
        }
      }
    }    

    .@{mBrowserClsPrefix}-associative.is-associative .@{mBrowserClsPrefix}-associative-selected {
      padding: 0;
    }

    // .@{mUploadClsPrefix}
    .@{mUploadListTItemClsPrefix} {
      margin-left: 0;

      &-fileinfo {
        font-size: var(--font-size-14);
        color: var(--regular-fc);
        width: 100%;

        &::after {
          content: none;
        }
      }

      &-more, &-icon, .@{iconClsPrefix}-svg {
        display: none;
      }
    }
  }

  &-item-rtl {
    .@{mAssociateListClsPrefix}-info {
      .@{iconClsPrefix}-wrapper {
        transform: scaleX(-1);
      }
    }
  }

  &-info {
    display: flex;
    align-items: center;
  }

  &-file {
    &-title {
      white-space: pre-line;
      word-break: break-word;
    }
  }

  &-btns {
    color: var(--secondary-fc);
  }

  &-file {
    overflow: hidden;
  }

  &-browser {    
    overflow: hidden;
  }
  
  &-empty {
    padding-top: calc(50 * var(--hd));
  }

  &-create {
    display: flex;
    color: var(--secondary-fc);
    padding: calc(6 * var(--hd)) 0 calc(14 * var(--hd)) calc(34 * var(--hd));
  }

  &-time {
    flex: auto;
    white-space: nowrap;
  }

  &-creator {
    flex: 0 1 auto;
    padding-right: calc(20 * var(--hd));
    .ellipsis();
  }

}