.@{secretSelectClsPrefix} {

  display: inline-flex;
  // 保密等级
  &-secretlevel {
    margin-right: var(--h-spacing-md);
  }
  // 保密期限
  &-secretvalidity {
    display: flex;
  }
  // 保密期限提示
  &-input-help-tip {
    margin-left: var(--h-spacing-md);
    display: flex;
    align-items: center;
  }
  /****************************** 换行展示 ******************************/
  &-wrapDisplay {
    display: block;
    .@{secretSelectClsPrefix} {
      // 保密等级
      &-secretlevel {
        margin-right: 0;
      }
      // 保密期限
      &-secretvalidity {
        margin-top: calc(5 * var(--hd));
      }
    }
  }
  /****************************** 展示 label ******************************/
  &-withLabel {
    .ui-layout-row {
      align-items: center;
    }
    .@{secretSelectClsPrefix} {
      // label 样式
      &-label {
        font-size: var(--font-size-12);
        display: flex;
        align-items: center;
        line-height: unset;
        padding: calc(13 * var(--hd)) 0;
        color: var(--regular-fc);
        &:not(.need-mirror-RTL) {
          text-align: left;
        }
        &.need-mirror-RTL {
          text-align: right;
        }
        // 如果没有宽度，直接隐藏
        &.ui-layout-col-0 {
          display: none;
        }
      }
      &-secretlevel,
      &-secretvalidity {
        display: block;
      }
      // 保密期限
      &-secretvalidity {
        margin-top: 0;
      }
    }
  }
  /****************************** 弹框模式 ******************************/
  &-dialog {
    .ui-title-icon {
      border: 0;
    }
    // 密级组件
    .@{secretSelectClsPrefix} {
      &-secretlevel,
      &-secretvalidity {
        padding: var(--form-item-padding-module);
        padding-top: 0;
        padding-bottom: 0;
        background: var(--bg-white);
        border-left: var(--border-solid);
        border-right: var(--border-solid);
      }
      &-secretlevel {
        border-bottom: var(--border-solid);
        border-top: var(--border-solid);
      }
      &-secretvalidity {
        border-bottom: var(--border-solid);
        margin-bottom: calc(var(--hd) * 40);
      }
    }
  }
  /****************************** 只读 ******************************/
  &-readOnly {
    align-items: center;
    line-height: 1;
    .@{secretSelectClsPrefix} {
      &-text-wrap {
        width: 100%;
      }
      &-secretlevel,
      &-secretvalidity,
      &-symbol {
        display: inline-block;
        color: var(--main-fc);
        height: var(--form-item-line-height);
        line-height: var(--form-item-line-height);
        font-size: var(--font-size-12);
      }
      &-secretlevel {
        margin-right: 0;
      }
      &-symbol {
        margin: 0 calc(4 * var(--hd));
      }
    }
    // 特殊样式，正对 withLabel 特殊处理
    &.@{secretSelectClsPrefix}-withLabel {
      .@{secretSelectClsPrefix}-comItem {
        display: flex;
        align-items: center;
        line-height: 1;
      }
    }
  }

  /****************************** form 中特殊样式处理 ******************************/
  // 必填标记显示位置为字段右侧时，隐藏 form 表单必填标记，由组件进行展示
  + .ui-formSwitch-requiredMark {
    display: none;
  }
}
